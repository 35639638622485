import React, { useState, forwardRef, useImperativeHandle } from 'react';
import * as yup from 'yup';
import { each } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import {
  useMakeInputOnlyAcceptRegexPassed,
  useMakeInputNotAcceptSpecialCharacters,
} from 'partial/hooks';
import FormInput from 'partial/form/FormInput';
import { bankAccountNumber } from 'partial/regex';
import { useActiveAccount } from 'modules/auth/hooks';
import Form, { ErrorLabel, ErrorOrDescription } from 'partial/form/Form';
import FormDatasetOptions from 'core-modules/dataset/components/FormDatasetOptions';

const validationSchema = yup.object().shape({
  bank_name: yup.string().required('Required'),
  bank_account_name: yup.string().required('Required'),
  bank_account_number: yup
    .string()
    .max(32, 'Max of 32 characters')
    .required('Required'),
});

const BankForm = forwardRef(({ readOnly, onSubmit, onSuccess }, ref) => {
  const [account] = useActiveAccount();

  const [validateOnChange, setValidateOnChange] = useState(false);
  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();
  const {
    registerOnSetFieldValue: registerOnSetAccountNumber,
  } = useMakeInputOnlyAcceptRegexPassed(bankAccountNumber);

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: account,
    validationSchema,

    onSubmit: async (form, { setFieldError }) => {
      onSubmit(form, onSuccess, (err) => {
        each(err?.response?.errors, (v, k) => {
          setFieldError(k, v);
        });
      });
    },
    validateOnChange,
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit,
      setValidateOnChange,
    }),
    [handleSubmit, setValidateOnChange]
  );

  return (
    <Form onSubmit={handleSubmit} error={errors} noValidate>
      <div className="space-y-4">
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
          <div className="col-span-2">
            <FormDatasetOptions
              code="BANKS"
              name="bank_name"
              onSetFieldValue={setFieldValue}
              value={values.bank_name}
              error={errors?.bank_name}
              label="Bank Name"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="bank_name" />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <FormInput
              name="bank_account_name"
              onSetFieldValue={registerOnSetFieldValue(
                'bank_account_name',
                setFieldValue
              )}
              value={values.bank_account_name}
              error={errors?.bank_account_name}
              label="Account Name"
              required
              readOnly={readOnly}
            />
            <ErrorOrDescription
              name="bank_account_name"
              label="This should be under the name of the registered business."
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <FormInput
              name="bank_account_number"
              onSetFieldValue={registerOnSetAccountNumber(
                'bank_account_number',
                setFieldValue
              )}
              value={values.bank_account_number}
              error={errors?.bank_account_number}
              label="Account Number"
              required
              readOnly={readOnly}
            />
            <ErrorOrDescription
              name="bank_account_number"
              label="This should be a Peso savings or current account."
            />
          </div>
        </div>
      </div>
    </Form>
  );
});

BankForm.defaultProps = {
  readOnly: false,
};

BankForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default BankForm;
