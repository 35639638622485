import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

const Unknown = () => <div>Tour not found</div>;

function Tour({ data, onNext, onSkip }) {
  const endFunc = React.useRef();
  const endFuncParams = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (data?.type !== 'tour') return;
    setIsOpen(true);
    // eslint-disable-next-line
  }, [data?.id]);

  if (data?.type !== 'tour') return null;
  const Component = data?.component || Unknown;

  const handleClose = () => {
    if (typeof endFunc.current === 'function')
      endFunc.current(endFuncParams.current);
  };

  const handleNext = (payload) => {
    endFunc.current = onNext;
    endFuncParams.current = payload;
    setIsOpen(false);
  };

  const handleSkip = () => {
    endFunc.current = onSkip;
    setIsOpen(false);
  };
  return (
    <div className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end pb-12 sm:pb-0">
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={handleClose}
        >
          <div className="sm:max-w-sm max-w-full w-full bg-gray-900/90 backdrop-blur-lg text-white shadow-lg rounded-xl pointer-events-auto ring-1 overflow-hidden ring-gray-900/80 tour-toast">
            <div className="p-4">
              <Component onNext={handleNext} onSkip={handleSkip} />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

Tour.defaultProps = {
  data: null,
};

Tour.propTypes = {
  data: PropTypes.instanceOf(Object),
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default Tour;
