import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

import { useInputId } from './hooks';

const FormPassword = React.forwardRef(
  (
    {
      id: defaultId,
      label,
      name,
      onChange,
      onSetFieldValue,
      value,
      error,
      required,
      noAutoFill,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [id] = useInputId(defaultId);
    const handleChange = ({ target }) => {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: target.value,
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, target.value);
      }
    };
    const isError = !!error;

    return (
      <div className="relative z-[1]">
        <input
          id={id}
          ref={ref}
          name="name"
          className={cn(
            'peer relative w-full rounded-md border bg-white px-4 pb-4 pt-8 text-base font-bold leading-4',
            'text-gray-900 placeholder:text-transparent read-only:bg-gray-100 focus-within:ring-2 disabled:bg-gray-100',
            isError
              ? 'border-red-300 focus:border-red-500 focus:ring-red-300'
              : 'border-gray-200 focus:border-primary-600 focus:ring-primary-400'
          )}
          onChange={handleChange}
          value={value || ''}
          {...props}
          placeholder="hidden"
          type={showPassword ? 'text' : 'password'}
          autoComplete={noAutoFill ? 'new-password' : 'on'}
        />
        <label
          htmlFor={id}
          className={cn(
            'no-highlight pointer-events-none absolute left-4 top-[1.625rem] -translate-y-1/2 text-xs text-gray-600 transition-all',
            'peer-placeholder-shown:top-1/2 peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400',
            'peer-focus:top-[1.625rem] peer-focus:text-xs peer-focus:text-gray-600'
          )}
        >
          <span>{label}</span>
          {!required && (
            <span className="absolute right-0 ml-1 rounded-full bg-gray-100 px-2 py-0.5 text-[8px] uppercase tracking-wider text-gray-500">
              optional
            </span>
          )}
        </label>
        {error ? (
          <div
            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            title={error || ''}
          >
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none"
            onClick={() => setShowPassword((val) => !val)}
            tabIndex="-1"
          >
            {showPassword ? (
              <HiOutlineEye className="h-5 w-5 text-gray-400" />
            ) : (
              <HiOutlineEyeOff className="h-5 w-5 text-gray-400" />
            )}
          </button>
        )}
      </div>
    );
  }
);

FormPassword.defaultProps = {
  id: '',
  type: 'text',
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  noAutoFill: false,
};

FormPassword.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  noAutoFill: PropTypes.bool,
};

export default React.memo(FormPassword);
