import React, { useEffect } from 'react';
import HistogramCard, {
  useHistogramCard,
} from 'partial/dashboard/HistogramCard';
import { useHistory, useLocation } from 'react-router-dom';

import DashboardSchemeCard, {
  useSchemeCard,
} from 'partial/dashboard/DashboardSchemeCard';
import ThumbIcon from 'partial/components/ThumbIcon';

import { useActiveAccount } from 'modules/auth/hooks';
import BreadCrumb from 'partial/components/BreadCrumb';
import PageWrapper from 'partial/components/PageWrapper';
import DelayDiv from 'modules/common/components/DelayDiv';
import VisibleDiv from 'modules/common/components/VisibleDiv';

import {
  useAmountHistogram,
  useTopChannelScheme,
  useTopMerchantScheme,
  useTransactionHistogram,
} from '../hooks';
import TransactionCompare, {
  TransactionCompareLoader,
} from '../components/TransactionCompare';
import CountCards from '../components/CountCards';
import GetStarted from '../components/GetStarted';

const txnHistogramOptions = [
  {
    id: 'transaction',
    label: 'By Transaction',
    hook: useTransactionHistogram,
    defaultChart: 'line',
  },
];

const amtHistogramOptions = [
  {
    id: 'amount',
    label: 'By Amount',
    hook: useAmountHistogram,
    defaultChart: 'line',
  },
];

const schemeOptionsA = [
  {
    id: 'top_merchants_by_amount',
    label: 'Top Merchants by Amount',
    hook: useTopMerchantScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Merchants',
        key: ({ icon, label }) => (
          <span className="flex items-center space-x-1">
            <ThumbIcon
              className="inline-block h-7 w-7 rounded-full"
              url={icon}
              alt={label}
            />
            <span>{label}</span>
          </span>
        ),
        id: 'label',
        className: 'font-semibold',
      },
      {
        label: 'Amount',
        key: 'amount',
        id: 'amount',
      },
    ],
    defaultSort: 'amount:desc',
    defaultChart: 'pie',
    meta: {
      dataLabel: 'Amount',
      dataAttribute: 'amount',
    },
  },
  {
    id: 'top_merchants_by_transaction',
    label: 'Top Merchants by Transaction',
    hook: useTopMerchantScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Merchants',
        key: ({ icon, label }) => (
          <span className="flex items-center space-x-1">
            <ThumbIcon
              className="inline-block h-7 w-7 rounded-full"
              url={icon}
              alt={label}
            />
            <span>{label}</span>
          </span>
        ),
        id: 'label',
        className: 'font-semibold',
      },
      {
        label: 'Txn Count',
        key: 'transaction',
        id: 'transaction',
      },
    ],
    defaultSort: 'transaction:desc',
    defaultChart: 'pie',
    meta: {
      dataLabel: 'Transactions',
      dataAttribute: 'transaction',
    },
  },
];

const schemeOptionsB = [
  {
    id: 'top_channels_by_amount',
    label: 'Top Channels by Amount',
    hook: useTopChannelScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Channels',
        key: 'label',
        id: 'label',
        className: 'font-semibold',
      },
      { label: 'Amount', key: 'amount', id: 'amount' },
    ],
    defaultSort: 'amount:desc',
    defaultChart: 'pie',
    meta: {
      dataLabel: 'Amount',
      dataAttribute: 'amount',
    },
  },
  {
    id: 'top_channels_by_transaction',
    label: 'Top Channels by Transaction',
    hook: useTopChannelScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Channels',
        key: 'label',
        id: 'label',
        className: 'font-semibold',
      },
      {
        label: 'Txn Count',
        key: 'transaction',
        id: 'transaction',
      },
    ],
    defaultSort: 'transaction:desc',
    defaultChart: 'pie',
    meta: {
      dataLabel: 'Transactions',
      dataAttribute: 'transaction',
    },
  },
];

function DashboardContainer() {
  const [activeAccount] = useActiveAccount();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { redirectTo = '' } = location?.state || {};
    if (!!redirectTo && activeAccount?.is_org_kyc_completed) {
      history.push(redirectTo);
    }
  }, [location, history, activeAccount]);

  const transactionHistogram = useHistogramCard(txnHistogramOptions);
  const amtHistogram = useHistogramCard(amtHistogramOptions);
  const schemePropsA = useSchemeCard(schemeOptionsA);
  const schemePropsB = useSchemeCard(schemeOptionsB);
  return (
    <>
      <BreadCrumb>Dashboard</BreadCrumb>
      <PageWrapper className="space-y-5">
        {!activeAccount?.is_org_kyc_completed && <GetStarted />}{' '}
        {(activeAccount?.is_org_kyc_completed ||
          activeAccount?.env_mode === 'TEST') && (
          <>
            <div className="flex items-center justify-between">
              <div className="uppercase tracking-wider text-gray-500">
                Overview
              </div>
            </div>
            <CountCards />
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3">
              <DelayDiv
                className="col-span-1 xl:col-span-2"
                delay={100}
                loader={
                  <div
                    className="col-span-1 animate-pulse rounded-lg bg-gray-200 xl:col-span-2"
                    style={{ height: 361 }}
                  />
                }
              >
                <HistogramCard {...transactionHistogram} />
              </DelayDiv>
              <DelayDiv
                delay={500}
                loader={
                  <div
                    className="animate-pulse rounded-lg bg-gray-200"
                    style={{ height: 361 }}
                  />
                }
              >
                <DashboardSchemeCard {...schemePropsA} />
              </DelayDiv>
            </div>
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-3">
              <DelayDiv
                className="col-span-1 xl:col-span-2"
                delay={1000}
                loader={
                  <div
                    className="col-span-1 animate-pulse rounded-lg bg-gray-200 xl:col-span-2"
                    style={{ height: 361 }}
                  />
                }
              >
                <HistogramCard {...amtHistogram} />
              </DelayDiv>
              <DelayDiv
                delay={1500}
                loader={
                  <div
                    className="animate-pulse rounded-lg bg-gray-200"
                    style={{ height: 361 }}
                  />
                }
              >
                <DashboardSchemeCard {...schemePropsB} />
              </DelayDiv>
            </div>
            <h4 className="text-sm uppercase leading-[1.125rem] tracking-wider text-gray-400">
              Comparative
            </h4>
            <VisibleDiv loader={<TransactionCompareLoader />}>
              <TransactionCompare hideTable />
            </VisibleDiv>
          </>
        )}
      </PageWrapper>
    </>
  );
}

DashboardContainer.propTypes = {};

export default DashboardContainer;
