import React from 'react';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import { ModalCard } from 'partial/components/Modal';

import PrivacyNotice from '../../legal/components/PrivacyNotice';

function PrivacyContainer({ onClose }) {
  return (
    <ModalCard>
      <PrivacyNotice />
      <div className="flex justify-end border-t p-2 sm:p-6 md:p-10">
        <Button size="lg" type="button" primary onClick={onClose}>
          Done
        </Button>
      </div>
    </ModalCard>
  );
}

PrivacyContainer.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default PrivacyContainer;
