import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'helper';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import { ModalCard } from 'partial/components/Modal';
import FormInputNumber from 'partial/form/FormInputNumber';
import FormReactSelect from 'partial/form/FormReactSelect';
import { useMakeInputAcceptValidAmountOnly } from 'partial/hooks';
import FormMultipleSelect from 'partial/form/FormMultipleSelect';

import { FEE_RATE_CLASSIFICATIONS } from '../constants';
import { usePaymentCategoryChannelList } from '../hooks';

const renderMoneyFormat = (key) => (raw) => formatCurrency(raw[key]);

export const FEE_SIMULATOR_TABLE_FORMAT = [
  {
    label: 'CHANNELS',
    key: 'name',
    sortKey: 'name',
  },
  {
    label: 'AMOUNT',
    key: renderMoneyFormat('amount'),
    sortKey: 'amount',
  },
  {
    label: 'CHANNEL FEE',
    key: renderMoneyFormat('channel_fee'),
    sortKey: 'channel_fee',
  },
  {
    label: 'SYSTEM FEE',
    key: renderMoneyFormat('system_fee'),
    sortKey: 'system_fee',
  },
  {
    label: 'PARTNER FEE',
    key: renderMoneyFormat('partner_fee'),
    sortKey: 'partner_fee',
  },
  {
    label: 'TOTAL AMOUNT',
    key: renderMoneyFormat('total'),
    sortKey: 'total',
  },
];

const sorterFunction = (a, b, method) => {
  if (typeof a === 'string') {
    const copyA = a?.toLowerCase();
    const copyB = b?.toLowerCase();
    switch (method) {
      case 'asc':
        if (copyA < copyB) return -1;
        if (copyA > copyB) return 1;
        return 0;
      default:
        if (copyA > copyB) return -1;
        if (copyA < copyB) return 1;
        return 0;
    }
  }
  switch (method) {
    case 'asc':
      return a - b;
    default:
      return b - a;
  }
};

const amountRateCalculator = (type, rates) => (amount) => {
  if (type === 'FIXED') return parseFloat(rates.rate);
  if (type === 'PERCENTAGE') return amount * (parseFloat(rates.rate, 10) / 100);
  if (type === 'MATRIX') {
    const ranges = rates.map((rate) => ({
      start: parseFloat(rate.amount_start, 10),
      end: parseFloat(rate.amount_end, 10),
      rate: parseFloat(rate.rate, 10),
    }));
    let rangeIndex = null;
    for (let x = 0; x < ranges.length; x += 1) {
      if (amount <= ranges[x].end) {
        rangeIndex = x;
        break;
      }
    }
    return rangeIndex !== null ? ranges[rangeIndex].rate : 0;
  }
  return 0;
};

function FeeSimulatorModal({ merchantId, attachedCategoryList, onClose }) {
  const [amount, setAmount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    sort: 'name:desc',
  });

  const { registerOnValueChange } = useMakeInputAcceptValidAmountOnly();

  const amountInt = useMemo(() => parseFloat(amount) || 0, [amount]);

  const list = useMemo(() => {
    return selectedChannels.map((channel) => {
      const [chn, stm, ptr] = FEE_RATE_CLASSIFICATIONS.map((c) =>
        channel.payment_channel_fees?.find((k) => k?.fee_rate_type === c)
      );

      const channel_fee = amountRateCalculator(
        chn.fee_type,
        chn.rates
      )(amountInt);
      const system_fee = amountRateCalculator(
        stm.fee_type,
        stm.rates
      )(amountInt);
      const partner_fee = amountRateCalculator(
        ptr.fee_type,
        ptr.rates
      )(amountInt);

      const total = channel_fee + system_fee + partner_fee + amountInt;

      return {
        name: channel.name,
        amount: amountInt,
        channel_fee,
        system_fee,
        partner_fee,
        total,
      };
    });
  }, [selectedChannels, amountInt]);

  const [, channelListData] = usePaymentCategoryChannelList(
    merchantId,
    selectedCategory?.value
  );

  const renderLabel = useCallback(
    (data) => (
      <div className="flex items-center space-x-2 py-2">
        <img alt="" src={data.logo} className="h-4 w-4 rounded object-cover" />
        <label>{data.name}</label>
      </div>
    ),
    []
  );

  return (
    <ModalCard title="Fee Simulator" size="lg" onClose={onClose}>
      <div>
        <div className="space-y-6 p-6">
          <form className="flex flex-col space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <div className="relative z-30 w-full flex-shrink-0 sm:w-52">
              <FormReactSelect
                name="category"
                label="Category"
                options={attachedCategoryList?.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                value={selectedCategory}
                onChange={(cb) => setSelectedCategory(cb().category)}
              />
            </div>
            <div className="relative z-10 w-full flex-grow sm:w-0">
              <FormMultipleSelect
                label="Category Channel"
                noOptionLabel="No available channels to compare.."
                renderOption={(val) => renderLabel(val.data)}
                renderPill={(val) => renderLabel(val.data)}
                selectedOptions={selectedChannels.map((channelData) => ({
                  label: channelData.name,
                  value: channelData.code,
                  data: channelData,
                }))}
                options={channelListData.map((option) => ({
                  label: option.name,
                  value: option.code,
                  data: option,
                }))}
                onChange={(selectedOptions) =>
                  setSelectedChannels(
                    selectedOptions.map((selectedOption) => selectedOption.data)
                  )
                }
              />
            </div>
            <div className="flex-shrink-0">
              <FormInputNumber
                name="amount"
                label="Amount"
                placeholder="Enter Amount"
                value={amount}
                onChange={(cb) => registerOnValueChange(cb().amount, setAmount)}
                required
              />
            </div>
          </form>
          <div className="flex h-72 flex-col">
            <Table
              data={[...list].sort((itemOne, itemTwo) => {
                const [sortKey, sortMethod] = tableFilter.sort.split(':');
                return sorterFunction(
                  itemOne[sortKey],
                  itemTwo[sortKey],
                  sortMethod
                );
              })}
              format={FEE_SIMULATOR_TABLE_FORMAT}
              sort={tableFilter.sort}
              onSort={setTableFilter}
            />
          </div>
        </div>
        <div className="relative flex w-full justify-end space-x-4 bg-gray-100 p-6">
          <Button type="submit" primary onClick={onClose}>
            Done
          </Button>
        </div>
      </div>
    </ModalCard>
  );
}

FeeSimulatorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  merchantId: PropTypes.string.isRequired,
  attachedCategoryList: PropTypes.instanceOf(Object).isRequired,
};

export default FeeSimulatorModal;
