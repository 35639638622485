import { req } from 'react-reqq-lite';
import { reqLoader, formatCurrency } from 'helper';
import { toastSuccess } from 'react-geek-toast';
import * as t from './transformers';
import * as c from './constants';

export const listInvoice = (params) => {
  req.get({
    key: c.INVOICE_LIST,
    url: '/merchant_invoice/v1/invoices',
    params,
    transform: (res) => (res?.data || []).map(t.transformInvoice),
    onSuccess: (res) => {
      req.set(c.INVOICE_PAGER, res?.response?.meta || {});
      req.set(c.INVOICE_FILTER, params);
    },
  });
};

export const appendListInvoice = (params) => {
  req.get({
    key: c.INVOICE_LIST,
    url: '/merchant_invoice/v1/invoices',
    params,
    transform: (res, state) =>
      (state[c.INVOICE_LIST] || []).concat(
        (res.data || []).map(t.transformInvoice)
      ),
    onSuccess: (res) => {
      req.set(c.INVOICE_PAGER, res?.response?.meta || {});
      req.set(c.INVOICE_FILTER, params);
    },
  });
};

export const selectInvoice = (id, onSuccess) => {
  req.get({
    key: c.INVOICE_ITEM,
    url: `/merchant_invoice/v1/invoices/${id}`,
    transform: (res) => t.transformInvoice(res?.data),
    onSuccess: (res) => {
      if (onSuccess) onSuccess(t.transformInvoice(res?.response?.data));
    },
  });
};

export const createInvoice = (payload, onSuccess, onError) => {
  req.post({
    key: c.INVOICE_ITEM,
    url: '/merchant_invoice/v1/invoices',
    payload: t.payloadInvoice(payload),
    onSuccess: (res, state) => {
      // toastSuccess('Created!');
      listInvoice(state[c.INVOICE_FILTER]);
      if (onSuccess) onSuccess(t.transformInvoice(res?.response?.data));
    },
    onError,
  });
};

export const updateInvoice = (id, payload, onSuccess, onError) => {
  req.put({
    key: c.INVOICE_ITEM,
    url: `/merchant_invoice/v1/invoices/${id}`,
    payload: t.payloadInvoice(payload),
    onSuccess: (res, state) => {
      // toastSuccess('Updated!');
      selectInvoice(id, (newData) => {
        if (onSuccess) onSuccess(newData);
      });
      listInvoice(state[c.INVOICE_FILTER]);
    },
    onError,
  });
};

export const removeInvoice = (id, onSuccess) => {
  req.remove({
    key: c.INVOICE_ITEM,
    url: `/merchant_invoice/v1/invoices/${id}`,
    onSuccess: (res, state) => {
      toastSuccess('Removed!');
      req.set(c.INVOICE_ITEM, null);
      listInvoice(state[c.INVOICE_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const submitInvoice = (id, onSuccess) => {
  req.put({
    key: c.INVOICE_ITEM,
    url: `/merchant_invoice/v1/invoices/${id}/submit`,
    onSuccess: (res) => {
      toastSuccess('Submitted!');
      setTimeout(() => {
        selectInvoice(id);
      }, 1500);
      // listInvoice(state[c.INVOICE_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const cancelInvoice = (id, onSuccess) => {
  req.put({
    key: c.INVOICE_ITEM,
    url: `/merchant_invoice/v1/invoices/${id}/cancel`,
    onSuccess: (res) => {
      toastSuccess('Cancelled!');
      // selectInvoice(id);
      // listInvoice(state[c.INVOICE_FILTER]);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const listInvoiceLogs = (id, params) => {
  req.get({
    key: c.INVOICE_LOG_LIST,
    url: `/merchant_invoice/v1/invoices/${id}/logs`,
    params,
    transform: (res) => (res?.data || []).map(t.transformInvoiceLog),
    onSuccess: (res) => {
      req.set(c.INVOICE_LOG_PAGER, res?.response?.meta || {});
      req.set(c.INVOICE_LOG_FILTER, params);
    },
  });
};

const MAP_PAYMENT_LINK_COUNT_CARD_SUB_THEME = {
  up: 'success',
  down: 'danger',
  middle: 'warning',
};

const MAP_PAYMENT_LINK_COUNT_CARD_SYMBOL = {
  up: '⌃ +',
  down: '⌄ -',
  middle: '_ +',
};

export const getCountCard = {
  total_invoices: (params) => {
    reqLoader(`${c.COUNT_CARD}/total_amount`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_invoices`,
      params,
      url: '/merchant_aggs/v1/aggs/total_invoices',
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_invoices`, {
          value: res?.response?.data?.total_invoices,
        });
        req.set(`${c.COUNT_CARD}/total_amount`, {
          value: formatCurrency(
            res?.response?.data?.total_amount?.replaceAll(',', '')
          ),
        });
        reqLoader(`${c.COUNT_CARD}/total_amount`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_amount`, false);
      },
    });
  },
  total_paid_payment_links: (params) => {
    reqLoader(`${c.COUNT_CARD}/total_paid_payment_links`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_paid_payment_links`,
      url: '/merchant-links/v1/aggs/total-payments',
      params,
      onSuccess: ({ response: res }) => {
        const data = t.transformPaymentLinkAggregation(res?.data);
        req.set(`${c.COUNT_CARD}/total_paid_payment_links`, {
          value: formatCurrency(data?.total_payments),
          meta: {
            sub_value: `${data?.payments_percentage} ${
              MAP_PAYMENT_LINK_COUNT_CARD_SYMBOL[data?.position]
            } ${formatCurrency(data?.payments_difference)} last month`,
            sub_value_theme:
              MAP_PAYMENT_LINK_COUNT_CARD_SUB_THEME[data?.position],
          },
        });
        reqLoader(`${c.COUNT_CARD}/total_paid_payment_links`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_paid_payment_links`, false);
      },
    });
  },
  total_unpaid_payment_links: (params) => {
    reqLoader(`${c.COUNT_CARD}/total_unpaid_payment_links`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_unpaid_payment_links`,
      url: '/merchant-links/v1/aggs/total-unpaid',
      params,
      onSuccess: ({ response: res }) => {
        const data = t.transformPaymentLinkAggregation(res?.data);
        req.set(`${c.COUNT_CARD}/total_unpaid_payment_links`, {
          value: formatCurrency(data?.total_payments),
          meta: {
            sub_value: `From ${data?.total_transactions ?? 0} Transactions`,
            sub_value_theme: 'default',
          },
        });
        reqLoader(`${c.COUNT_CARD}/total_unpaid_payment_links`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_unpaid_payment_links`, false);
      },
    });
  },
};

// Payment Links Stuffs
export const listPaymentLinks = (params) => {
  req.get({
    key: c.PAYMENT_LINKS_LIST,
    url: '/merchant-links/v1',
    params,
    transform: (res) => ({
      list: (res?.data || []).map(t.transformPaymentLink),
      pager: res.meta,
    }),
  });
};

export const appendPaymentLinkList = (params) => {
  req.get({
    key: c.PAYMENT_LINKS_LIST,
    url: '/merchant-links/v1',
    params,
    transform: (res, state) => ({
      list: (state[c.PAYMENT_LINKS_LIST] || {}).list.concat(
        (res.data || []).map(t.transformPaymentLink)
      ),
      pager: res.meta,
    }),
  });
};

export const createPaymentLink =
  ({ onSuccess, onError }) =>
  ({
    payload,
    hasExpirationDate,
    onError: onInlineError,
    onSuccess: onInlineSuccess,
  }) => {
    req.post({
      key: c.INVOICE_ITEM,
      url: '/merchant-links/v1',
      payload: t.payloadPaymentLink(payload, {
        hasExpirationDate,
      }),
      onSuccess: (res) => {
        const data = t.transformPaymentLink(res?.response?.data);
        onSuccess?.(data);
        onInlineSuccess?.(data);
      },
      onError: (err) => {
        onError?.(err);
        onInlineError?.(err);
      },
    });
  };

export const getPaymentLink = (payment_link_uuid) => {
  req.get({
    key: c.PAYMENT_LINKS_ITEM,
    url: `/merchant-links/v1/${payment_link_uuid}`,
    transform: (res) => t.transformPaymentLink(res?.data),
  });
};

export const togglePaymentLinkStatus =
  ({ onSuccess }) =>
  ({ link_uuid, status }) => {
    req.put({
      key: c.PAYMENT_LINK_STATUS,
      url: `/merchant-links/v1/${link_uuid}`,
      payload: {
        status,
      },
      onSuccess: () => {
        if (typeof onSuccess === 'function') onSuccess();
      },
    });
  };

export const listPaymentLinkTransaction = ({ link_uuid, params }) => {
  req.get({
    key: c.PAYMENT_LINK_TRANSACTIONS,
    url: `/merchant-links/v1/${link_uuid}/invoices`,
    params,
    transform: (res) => ({
      list: (res?.data || []).map(t.transformPaymentLinkTransaction),
      pager: res.meta,
    }),
  });
};

export const appendPaymentLinkTransactionList = ({ link_uuid, params }) => {
  req.get({
    key: c.PAYMENT_LINK_TRANSACTIONS,
    url: `/merchant-links/v1/${link_uuid}/invoices`,
    params,
    transform: (res, state) => ({
      list: (state[c.PAYMENT_LINK_TRANSACTIONS] || {}).list.concat(
        (res.data || []).map(t.transformPaymentLinkTransaction)
      ),
      pager: res.meta,
    }),
  });
};

export const listPaymentLinkTransactionLogs = ({ invoice_uuid }) => {
  req.get({
    key: c.PAYMENT_LINK_TRANSACTION_LOGS,
    url: `/merchant-links/v1/invoices/${invoice_uuid}/logs`,
    transform: (res) =>
      (res?.data ?? []).map(t.transformPaymentLinkTransactionLog),
  });
};

export const getPaymentLinkPaidAggregation = (link_uuid) => {
  req.get({
    key: `${c.PAYMENT_LINKS_PAID_AGGREGATION}?link_uuid=${link_uuid}`,
    url: `/merchant-links/v1/aggs/total-payments`,
    params: {
      merchant_link_uuid: link_uuid,
    },
    transform: (res) => t.transformPaymentLinkAggregation(res?.data),
  });
};

export const getTransaction = (transaction_uuid) => {
  req.get({
    key: `${c.PAYMENT_LINK_TRANSACTION}?transaction_uuid=${transaction_uuid}`,
    url: `/merchant_transaction/v1/transactions/${transaction_uuid}`,
    transform: (res) => t.transformTransaction(res?.data),
  });
};
