import React from 'react';
import PropTypes from 'prop-types';
import Button from 'partial/components/Button';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IoHelpBuoyOutline } from 'react-icons/io5';

function HelpEndTour({ onNext }) {
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <IoHelpBuoyOutline className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">Help</div>
          <div>
            You may click on the{' '}
            <HiQuestionMarkCircle className="h-5 w-5 inline-block" /> icon if
            you need help.
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button
          transparent
          className="text-white"
          size="sm"
          onClick={() => onNext()}
        >
          Got it
        </Button>
      </div>
    </div>
  );
}

HelpEndTour.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default HelpEndTour;
