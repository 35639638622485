import React from 'react';
import PropTypes from 'prop-types';

import countries from 'partial/form/countries.json';
import GenericDetails from 'partial/components/GenericDetails';

const countryOptions = countries.map((item) => ({
  label: item.name,
  value: item.iso2,
}));

const COMPANY_PROFILE_FORMAT = [
  {
    label: 'Business Store Name',
    key: 'name',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'Customer Service Number',
    key: 'customer_service_number',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'Email Address',
    key: 'business_email_address',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'Website / Social Media',
    key: 'business_website',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'Industry',
    key: 'industry',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'Business Address',
    key: (data) =>
      data?.business_country === 'PH'
        ? `Philippines, ${data?.business_state}, ${data?.business_city}, ${data?.business_zip_code}, ${data?.business_address}`
        : `${
            countryOptions?.find(
              (country) => country?.value === data?.business_country
            )?.label
          }, ${data?.business_address}`,
    span: 3,
    hasBorder: true,
  },
  {
    label: 'Product description and nature of business',
    key: 'business_description',
    span: 3,
    hasBorder: true,
  },
];

const BUSINESS_ADDRESS_FORMAT = [
  {
    label: 'Average Monthly Sales',
    key: 'business_monthly_sales',
    span: 1,
    hasBorder: true,
  },
  {
    label: 'No. of Customers',
    key: 'business_number_of_customers',
    span: 1,
    hasBorder: true,
  },
];

function MerchantGeneralInfo({ data, isLoading }) {
  return (
    <div className="data-container space-y-4">
      <GenericDetails
        data={data || {}}
        isLoading={isLoading}
        format={COMPANY_PROFILE_FORMAT}
        numberOfCols={3}
      />
      <GenericDetails
        data={data || {}}
        isLoading={isLoading}
        format={BUSINESS_ADDRESS_FORMAT}
        numberOfCols={2}
      />
    </div>
  );
}

MerchantGeneralInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool,
};

MerchantGeneralInfo.defaultProps = {
  isLoading: false,
};

export default MerchantGeneralInfo;
