import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const SpanLoader = ({
  isLoading,
  loaderClassName,
  width,
  children,
  ...props
}) => {
  return (
    <span {...props}>
      {isLoading ? (
        <span className="block relative" style={{ width }}>
          <span
            className={cn(
              'animate-pulse rounded w-full h-5/6 block absolute',
              loaderClassName
            )}
          />
          &nbsp;
        </span>
      ) : (
        children
      )}
    </span>
  );
};

SpanLoader.defaultProps = {
  width: '100%',
  loaderClassName: 'bg-gray-200',
};

SpanLoader.propTypes = {
  width: PropTypes.string,
  loaderClassName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default SpanLoader;
