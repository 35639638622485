import React, { useState } from 'react';

import Splash from 'partial/containers/Splash';
import { showDrawer } from 'partial/components/Modal';

export const openTestApiModal = () => {
  showDrawer({
    size: 'full',
    title: false,
    zIndex: 'z-[100]',
    content: (onClose) => <TestApiModal onClose={onClose} />,
  });
};

function TestApiModal() {
  const [isLoading, setIsloading] = useState(true);
  return (
    <div className="relative -mt-6 h-full w-full">
      {isLoading && (
        <div className="absolute inset-0 h-full w-full bg-white">
          <Splash />
        </div>
      )}
      <iframe
        className="h-full w-full"
        src="/developers"
        title="Documentation"
        onLoad={() => setIsloading(false)}
      />
    </div>
  );
}

export default TestApiModal;
