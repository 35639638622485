import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { HiPlus } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';
import { TIME_PRESET_VALUE } from 'modules/common/components/DateRangePicker';

import CountCards from './CountCards';
import { useInvoiceList } from '../hooks';
import { GET_INVOICE_TABLE_FORMAT } from '../constants';

const STATUS_OPTIONS = [
  { label: 'All Invoice', value: '' },
  { label: 'Draft', value: 'DRAFT' },
  {
    label: 'Waiting for payment channel',
    value: 'WAITING_FOR_PAYMENT_CHANNEL',
  },
  { label: 'Waiting for payment', value: 'WAITING_FOR_PAYMENT' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Expired', value: 'EXPIRED' },
];

const GET_MAP_CREATE_BILL_PATH_STATE = (merchant) => ({
  '/merchants/profiles/:merchant_id/invoice': {
    merchantLock: true,
    merchant: {
      label: merchant.name,
      value: merchant.id,
    },
  },
  '/get-paid/bill': {
    merchantLock: true,
    merchant: {
      label: merchant.name,
      value: merchant.id,
    },
  },
});

function Filter({ onSubmit, value }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [, currentMerchant] = useMerchantSelected();
  const handleCreate = useCallback(() => {
    const state =
      GET_MAP_CREATE_BILL_PATH_STATE(currentMerchant)[match.path] ?? {};

    history.push({ pathname: `${match.url}/create`, state });
  }, [currentMerchant, history, match]);

  useEffect(() => {
    const fromUrl = history.location.state?.from;
    if (fromUrl === 'dashboard') {
      handleCreate();
      history.replace({ state: null });
    }
  }, [history, handleCreate]);

  return (
    <ModuleListFilters
      filter={value}
      setFilter={onSubmit}
      actionElement={
        <>
          <Button
            primary
            icon={HiPlus}
            onClick={handleCreate}
            className="h-full @4xl:hidden"
          />
          <Button
            primary
            icon={HiPlus}
            onClick={handleCreate}
            className="hidden h-full @4xl:inline-flex"
          >
            Create Invoice
          </Button>
        </>
      }
      renderCustomFields={({ inlineFilter, setInlineFilter }) => (
        <FormInlineSelect
          name="status"
          label={
            <span className="mb-2 text-sm text-gray-800 sm:hidden">Status</span>
          }
          onChange={setInlineFilter}
          value={inlineFilter?.status}
          options={STATUS_OPTIONS}
        />
      )}
      activeFiltersFormat={[
        {
          label: 'Status',
          key: 'status',
          matrix: [
            ['DRAFT', 'Draft'],
            ['WAITING_FOR_PAYMENT_CHANNEL', 'Waiting for payment channel'],
            ['WAITING_FOR_PAYMENT', 'Waiting for payment'],
            ['PAID', 'Paid'],
            ['FAILED', 'Failed'],
            ['CANCELLED', 'Cancelled'],
            ['EXPIRED', 'Expired'],
          ],
        },
        {
          label: 'Date Range',
          key: 'from',
          matrix: (val) =>
            `${dayjs(val?.from).format('MMM DD, YYYY')} - ${dayjs(
              val?.to
            ).format('MMM DD, YYYY')}`,
          clearFilter: () =>
            onSubmit((state) => ({
              ...state,
              date_range_preset: '',
              from: '',
              to: '',
            })),
        },
      ]}
    />
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

const INIT_FILTER = {
  keyword: '',
  status: '',
  page: 1,
  date_range_preset: 'LAST_MONTH',
  ...TIME_PRESET_VALUE.LAST_MONTH,
};

function InvoiceTable({ filter: otherFilter }) {
  const match = useRouteMatch();
  const history = useHistory();

  const [raw, setFilter] = useState(INIT_FILTER);

  const filter = useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );

  const viewInvoice = (invoiceData) => {
    history.push(`${match.url}/${invoiceData.id}`);
  };

  const [isLoading, list, pager, appendList] = useInvoiceList(filter);

  return (
    <div className="hide-scroll relative flex h-full flex-col space-y-3 overflow-auto sm:max-h-full">
      <CountCards otherFilter={otherFilter} />
      <div className="flex flex-col space-y-3 sm:h-0 sm:flex-grow">
        <div className="z-[1]">
          <Filter onSubmit={setFilter} value={raw} />
        </div>
        <div className="flex flex-col rounded-lg border bg-white sm:h-0 sm:flex-grow">
          <Table
            isLoading={isLoading}
            data={list}
            format={GET_INVOICE_TABLE_FORMAT({ onViewEditClick: viewInvoice })}
            onSort={setFilter}
            sort={raw.sort}
            onLoadMore={appendList}
            pager={pager}
            onRowClick={(row) => history.push(`${match.url}/${row.id}`)}
          />
        </div>
      </div>
    </div>
  );
}

InvoiceTable.defaultProps = {
  filter: {},
};

InvoiceTable.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default InvoiceTable;
