import React from 'react';
import PropTypes from 'prop-types';
import AppTour from 'partial/app-tour';
import Splash from 'partial/containers/Splash';
import { ProfileContext } from '../constants';
import { useGetProfile } from '../hooks';

function ProfileWrapper({ children }) {
  const [profile, getProfile] = useGetProfile();
  if (!profile) return <Splash />;
  return (
    <ProfileContext.Provider value={{ profile, getProfile }}>
      <AppTour>{children}</AppTour>
    </ProfileContext.Provider>
  );
}

ProfileWrapper.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ProfileWrapper;
