import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'partial/components/Button';
import BreadCrumb from 'partial/components/BreadCrumb';
import logoIcon from 'assets/images/brandIconLogos/colored-icon.svg';

function PageNotFound() {
  const history = useHistory();
  return (
    <>
      <BreadCrumb>Users</BreadCrumb>
      <div className="flex h-screen flex-col bg-gray-50 pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img className="h-16 w-auto" src={logoIcon} alt="" />
            </a>
          </div>
          <div className="pb-16 pt-4">
            <div className="text-center">
              <p className="text-lg font-semibold uppercase tracking-wide text-primary-600">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6 text-center">
                <Button
                  className="mx-auto"
                  default
                  onClick={() => history.push('/')}
                >
                  Return<span aria-hidden="true"> &rarr;</span>
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default PageNotFound;
