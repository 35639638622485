import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const GoogleRecaptchaContext = createContext();

export const GoogleRecaptchaProvider = ({ children }) => {
  const recaptchaRef = useRef();
  const [bodyElement, setBodyElement] = useState();

  const showRecaptchaBadge = useCallback(() => {
    bodyElement?.classList.add('show_recaptcha_badge');
  }, [bodyElement]);

  const hideRecaptchaBadge = useCallback(() => {
    bodyElement?.classList.remove('show_recaptcha_badge');
  }, [bodyElement]);

  const executeAsync = useCallback(
    () => recaptchaRef.current?.executeAsync(),
    []
  );

  const reset = useCallback(() => recaptchaRef.current?.reset(), []);

  useEffect(() => {
    setBodyElement(document.querySelector('body'));
  }, [setBodyElement]);

  return (
    <GoogleRecaptchaContext.Provider
      value={{ executeAsync, reset, hideRecaptchaBadge, showRecaptchaBadge }}
    >
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={
          process.env.REACT_APP_RECAPTCHA ||
          '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        }
      />
    </GoogleRecaptchaContext.Provider>
  );
};

GoogleRecaptchaProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const useGoogleRecaptchaContext = () => {
  const ctx = useContext(GoogleRecaptchaContext);
  if (!ctx) throw new Error('Invalid use.');
  return ctx;
};

export const useGoogleRecaptcha = () => {
  const { executeAsync, reset, hideRecaptchaBadge, showRecaptchaBadge } =
    useGoogleRecaptchaContext();

  useEffect(() => {
    showRecaptchaBadge();
    return hideRecaptchaBadge;
  }, [hideRecaptchaBadge, showRecaptchaBadge]);

  return { executeAsync, reset };
};
