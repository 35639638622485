import React from 'react';
// import PropTypes from 'prop-types';
import PageWrapper from 'partial/components/PageWrapper';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AclUsers from './AclUsers';
// import UserProfile from './UserProfile';

function UserRoutes() {
  const match = useRouteMatch();
  return (
    <PageWrapper>
      <Switch>
        <Route path={`${match.path}/users`} component={AclUsers} />
        <Redirect to={`${match.url}/users`} />
      </Switch>
    </PageWrapper>
  );
}

UserRoutes.propTypes = {};

export default UserRoutes;
