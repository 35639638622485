import React, { useCallback } from 'react';
import * as yup from 'yup';
import AuthStore from 'auth-store';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'partial/components/Button';
import FormInput from 'partial/form/FormInput';
import { switchAccount } from 'modules/auth/actions';
import { useActiveAccount } from 'modules/auth/hooks';
import { MAP_SWITCH_CODE } from 'modules/auth/constants';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
import { useToggleLiveTestEnvironment } from 'modules/auth/modals/SwitchProfileModal';
import { showAlert, useCreateModal, ModalCard } from 'partial/components/Modal';

import { useCreateMerchant } from '../hooks';

export const useOpenCreateMerchantModal = () => {
  const createModal = useCreateModal();

  const openCreateMerchantModal = useCallback(() => {
    createModal({
      content: (close) => (
        <ModalCard title="Create New Merchant" onClose={close}>
          <CreateMerchantModal onClose={close} />
        </ModalCard>
      ),
    });
  }, [createModal]);

  return openCreateMerchantModal;
};

export const useOpenPromptSwitchToLiveMode = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeAccount] = useActiveAccount();
  const { toggleEnvironment: promptSwitchModal } =
    useToggleLiveTestEnvironment();
  const prevCode = `${MAP_SWITCH_CODE[activeAccount?.env_mode]}_session`;
  const token = AuthStore.get(`${prevCode}`, prevCode);
  const handleLive = () => {
    history.push('/dashboard', { redirectTo: location?.pathname });
    if (!token) {
      promptSwitchModal();
      return;
    }
    switchAccount(token);
  };
  const openModal = () => {
    showAlert({
      subTitle: 'Adding Merchant is only available in Live Mode',
      yesLabel: 'Switch to Live Mode',
      onYes: (onClose) => {
        handleLive();
        onClose();
      },
      noLabel: 'Cancel',
      color: 'primary',
    });
  };
  return [openModal];
};

const initialValues = {
  name: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

function CreateMerchantModal({ onClose }) {
  const inputRef = React.useRef();
  const [activeAccount] = useActiveAccount();
  const [isLoading, submit] = useCreateMerchant();
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      setValidateOnChange(true);
      submit(form, onClose, (err) => {
        setError422(err, setFieldError);
      });
    },
    validateOnChange,
  });
  React.useEffect(() => {
    setTimeout(() => {
      if (activeAccount?.env_mode === 'LIVE') {
        inputRef.current.focus();
      }
    }, 100);
  }, [activeAccount]);
  return (
    <Form
      onSubmit={handleSubmit}
      error={errors}
      noValidate
      className="space-y-6 p-6"
    >
      <div>
        <FormInput
          ref={inputRef}
          name="name"
          onSetFieldValue={setFieldValue}
          value={values.name}
          label="Merchant Name"
          error={errors.name}
          required
        />
        <ErrorLabel name="name" />
      </div>
      <div className="flex justify-end space-x-2">
        <Button className="mr-2" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button primary type="submit" disabled={isLoading}>
          Submit
        </Button>
      </div>
    </Form>
  );
}

CreateMerchantModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateMerchantModal;
