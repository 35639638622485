/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { isEmpty } from 'lodash';
import { HiOutlineFolderAdd } from 'react-icons/hi';

const TableContainer = SortableContainer(({ children }) => (
  <div className="relative flex h-full flex-col">{children}</div>
));

const TableRow = SortableElement(({ children, format }) => (
  <div
    className="noselect group mb-2 grid rounded-lg border bg-white hover:border-primary-400"
    style={{
      gridColumn: `1 / span ${format.length}`,
      gridTemplateColumns: format.map((f) => `${f?.span || 1}fr`).join(' '),
    }}
  >
    {children}
  </div>
));

const DragHandle = SortableHandle(({ children, disabled }) => (
  <div
    className={`grid place-items-center p-3 ${!disabled ? 'cursor-move' : ''}`}
  >
    {children}
  </div>
));

function SortableTable({
  format,
  data,
  onSortEnd,
  isLoading,
  emptyDataLabel,
  hideHeader,
  disabled,
}) {
  return (
    <TableContainer
      lockAxis="y"
      helperClass="z-50"
      format={format}
      onSortEnd={(info) => {
        if (info?.oldIndex === info?.newIndex) return;
        onSortEnd(info);
      }}
      useDragHandle
    >
      {!hideHeader && (
        <div
          className="sticky top-0 grid"
          style={{
            gridTemplateColumns: format
              .map((f) => `${f?.span || 1}fr`)
              .join(' '),
          }}
        >
          {format.map((col, i) => (
            <div key={i} className="z-10 border-b-2 bg-white p-3">
              <h4 className="font-medium">{col.label || ''}</h4>
            </div>
          ))}
        </div>
      )}
      {!isLoading && isEmpty(data) && (
        <div className="grid place-items-center border-b p-3">
          {emptyDataLabel}
        </div>
      )}
      {isLoading && isEmpty(data) && (
        <>
          {[0, 1, 2].map((i) => (
            <div
              key={i}
              className="mt-2 h-10 animate-pulse rounded-md border-b bg-gray-300"
            />
          ))}
        </>
      )}
      {isLoading && !isEmpty(data) && (
        <i className="fas fa-spinner absolute top-0 right-0 m-4 animate-spin text-primary-700" />
      )}
      {data.map((d, rowIndex) => (
        <TableRow
          key={rowIndex}
          index={rowIndex}
          format={format}
          disabled={disabled}
        >
          {format.map((f, colIndex) => {
            if (f.isDragHandle) {
              return (
                <DragHandle
                  key={`row${rowIndex}-col${colIndex}`}
                  disabled={disabled}
                >
                  {typeof f?.key === 'function'
                    ? f?.key(d, rowIndex, colIndex) || ''
                    : d[f?.key] || ''}
                </DragHandle>
              );
            }
            return (
              <div
                className="grid place-items-center px-1 py-2 sm:px-3 sm:py-3"
                key={`row${rowIndex}-col${colIndex}`}
              >
                {typeof f?.key === 'function'
                  ? f?.key(d, rowIndex, colIndex) || ''
                  : d[f?.key] || ''}
              </div>
            );
          })}
        </TableRow>
      ))}
    </TableContainer>
  );
}

SortableTable.defaultProps = {
  format: [],
  data: [],
  isLoading: false,
  emptyDataLabel: 'No Data Available',
  hideHeader: false,
  disabled: false,
};

SortableTable.propTypes = {
  format: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      span: PropTypes.number,
      isDragHandle: PropTypes.bool,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  emptyDataLabel: PropTypes.string,
  hideHeader: PropTypes.bool,
  disabled: PropTypes.bool,
  onSortEnd: PropTypes.func.isRequired,
};

export default SortableTable;
