import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

function CardMenu({ options }) {
  const handleClick =
    ({ onClick, ...rest }) =>
    (e) => {
      e.preventDefault();
      if (onClick) onClick(rest);
    };
  return (
    <Menu as="div" className="relative flex-shrink-0 pr-2">
      {({ open }) => (
        <>
          <Menu.Button className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items
              static
              className="absolute right-0 z-10 mx-3 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {options.map((item) => (
                  <Menu.Item key={item.id}>
                    {({ active }) => (
                      <a
                        href="/"
                        onClick={handleClick(item)}
                        className={cn(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {item.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

CardMenu.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
};

function Card({ className, label, actionOptions, renderAction, children }) {
  return (
    <div className={cn('rounded-lg bg-white sm:border', className)}>
      {label ? (
        <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2 flex items-center">
              <h3 className="text-sm leading-4 text-gray-900">{label}</h3>
              {Array.isArray(actionOptions) && actionOptions.length > 1 && (
                <CardMenu options={actionOptions} />
              )}
            </div>
            {renderAction && (
              <div className="ml-4 mt-2 flex-shrink-0">{renderAction}</div>
            )}
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
}

Card.defaultProps = {
  actionOptions: null,
  renderAction: null,
  children: false,
  className: '',
  label: '',
};

Card.propTypes = {
  className: PropTypes.string,
  renderAction: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
  actionOptions: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  label: PropTypes.string,
};

export default Card;
