import React from 'react';
import PropTypes from 'prop-types';

function DelayDiv({ children, loader, delay, ...props }) {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    const tmr = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(tmr);
    };
  }, [setShow, delay]);
  return show ? <div {...props}>{children}</div> : loader;
}

DelayDiv.propTypes = {
  delay: PropTypes.number.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  loader: PropTypes.instanceOf(Object).isRequired,
};

export default DelayDiv;
