import React, { Fragment, useState, useRef, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { toastInfo } from 'react-geek-toast';
import { arrayMoveImmutable } from 'array-move';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { formatCurrency } from 'helper';
import Card from 'partial/components/Card';
import Table from 'partial/components/Table';
import { useResizeObserver } from 'partial/hooks';
import SortableTable from 'partial/components/SortableTable';
import { useCreateModal, ModalCard } from 'partial/components/Modal';

import {
  usePaymentCategoryChannelList,
  useUpdatePaymentChannelSequence,
} from '../hooks';
import { FEE_RATE_CLASSIFICATIONS } from '../constants';
import AttachedChannelActions from './AttachedChannelActions';

export const CATEGORY_BOX_TABLE_FORMAT = ({
  merchantId,
  categoryId,
  onUpdate,
  onViewMatrix,
}) => [
  {
    label: '',
    key: () => <GiHamburgerMenu className="text-gray-300" size={20} />,
    isDragHandle: true,
  },
  {
    label: 'Sequence',
    key: (_, i) => <div className="w-5 text-center">{i + 1}</div>, // to be replace by sequence
  },
  {
    label: 'logo',
    key: (row) => (
      <div className="relative h-10 w-10 overflow-hidden rounded-md border sm:h-[2.625rem] sm:w-[2.625rem]">
        <img
          src={row?.logo}
          className="h-full w-full bg-white object-contain p-1"
          alt=""
        />
      </div>
    ),
  },
  {
    label: 'details',
    key: (row) => {
      const feeKinds = FEE_RATE_CLASSIFICATIONS.map(
        (c) =>
          row.payment_channel_fees?.find((k) => k?.fee_rate_type === c) || {}
      );
      return (
        <div className="space-y flex h-full w-full flex-col justify-center">
          <div className="flex flex-col items-center space-x-0 space-y-0 sm:flex-row sm:space-x-2 sm:space-y-0">
            <div className="w-36 truncate whitespace-nowrap text-left text-xs font-semibold text-gray-900 sm:w-auto sm:text-sm">
              {row?.name}
            </div>
            <div className="hidden w-full text-left text-gray-500 sm:block sm:w-auto">
              {row?.code}
            </div>
            {row?.visibility === 'NOT_TRANSACTABLE' && (
              <span className="w-36 truncate whitespace-nowrap text-xs text-red-500 sm:w-auto sm:text-sm">
                This channel is currently under maintenance.
              </span>
            )}
          </div>
          <div className="hidden flex-col items-center space-x-0 space-y-2 sm:flex sm:flex-row sm:space-x-2 sm:space-y-0">
            {feeKinds.map((kind, i) => (
              <Fragment key={kind.uuid}>
                <span className="capitalize text-gray-500">
                  {kind?.fee_rate_type?.toLowerCase()} Fee:{' '}
                  {kind?.fee_type === 'MATRIX' ? (
                    <button
                      type="button"
                      className="border-b border-dashed border-gray-300 font-normal text-primary-500 hover:text-primary-400"
                      onClick={() => onViewMatrix('Channel Fee', kind?.rates)}
                    >
                      View Matrix
                    </button>
                  ) : (
                    `${kind.fee_type?.toLowerCase()} ${kind?.rates?.rate}`
                  )}
                </span>
                {i < 2 && <span className="h-1 w-1 rounded-full bg-gray-500" />}
              </Fragment>
            ))}
          </div>
        </div>
      );
    },
    span: 20,
  },
  {
    label: 'Actions',
    key: (row) => (
      <AttachedChannelActions
        data={row}
        merchantId={merchantId}
        categoryId={categoryId}
        onUpdate={onUpdate}
      />
    ),
  },
];

function CategoryBox({ data, merchantId, searchChannelString }) {
  const containerRef = useRef();
  const attachedChannelContainerDimensions = useResizeObserver(containerRef);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const [
    isLoadingChannels,
    channelListUnfiltered,
    refetchChannelList,
    setChannelList,
  ] = usePaymentCategoryChannelList(merchantId, data?.id);

  const channelList = useMemo(() => {
    return channelListUnfiltered.filter(
      (ac) =>
        !searchChannelString?.trim() ||
        ac.name
          .toLowerCase()
          .includes(searchChannelString?.trim().toLowerCase())
    );
  }, [channelListUnfiltered, searchChannelString]);

  const firstFiveChannels = useMemo(
    () => channelList.slice(0, 5),
    [channelList]
  );

  const [, updateChannelSequence] = useUpdatePaymentChannelSequence(
    merchantId,
    data?.id,
    refetchChannelList,
    refetchChannelList
  );

  const createModal = useCreateModal();

  const handleViewMatrixFee = useCallback(
    (type, categoryChannelData) => {
      createModal({
        content: (close) => (
          <ModalCard title={type} onClose={close}>
            <div className="flex h-72 flex-col">
              <Table
                format={[
                  {
                    label: 'AMOUNT BRACKET',
                    key: (rowData) =>
                      `${formatCurrency(
                        rowData?.amount_start
                      )} - ${formatCurrency(rowData?.amount_end)}`,
                  },
                  {
                    label: 'Rate',
                    key: (rowData) => formatCurrency(rowData?.rate),
                  },
                ]}
                data={
                  Array.isArray(categoryChannelData) ? categoryChannelData : []
                }
              />
            </div>
          </ModalCard>
        ),
      });
    },
    [createModal]
  );

  return (
    <Card
      className={cn(
        'relative border border-gray-200 transition hover:border-gray-400',
        {
          hidden: !isEmpty(searchChannelString) && isEmpty(channelList),
        }
      )}
    >
      <div className="relative flex cursor-pointer items-center justify-between rounded-t-lg p-4">
        <button
          aria-label="toggle expand"
          type="button"
          className="absolute inset-0 h-full w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
        <div className="flex w-full space-x-2">
          <h4 className="noselect flex items-center text-base font-medium">
            {data?.name}
          </h4>
          {data?.parent_category_status === 'INACTIVE' && (
            <span className="text-red-500">
              This category is currently under maintenance.
            </span>
          )}
          {isCollapsed ? (
            <HiChevronDown className="!ml-auto h-5 w-5" />
          ) : (
            <HiChevronUp className="!ml-auto h-5 w-5" />
          )}
        </div>

        {data?.status === 'INACTIVE' && (
          <p className="badge badge-gray">Inactive</p>
        )}
      </div>
      <hr
        className={cn(
          'mx-4 w-[calc(100%-2rem)] transition-opacity',
          !isCollapsed ? 'opacity-0' : ''
        )}
      />
      <div
        className="overflow-hidden p-4 transition-all"
        style={{
          height: isCollapsed
            ? '65px'
            : `${(attachedChannelContainerDimensions?.height || 0) + 20}px`,
        }}
      >
        <button
          type="button"
          className={cn(
            'absolute z-10 flex items-center overflow-hidden',
            !isCollapsed && 'pointer-events-none'
          )}
          onClick={() => setIsCollapsed(false)}
        >
          {channelList.length === 0 && isCollapsed && isLoadingChannels && (
            <div className="w-full max-w-md text-center">
              <div className="mb-1 text-sm tracking-wide text-gray-500">
                Loading channels...
              </div>
              <div className="loaderBar w-full">
                <div className="loaderBarInner" />
              </div>
            </div>
          )}
          {channelList.length === 0 && isCollapsed && !isLoadingChannels ? (
            <p className="mx-4 my-3 text-gray-500">No channels available</p>
          ) : (
            <>
              {firstFiveChannels.map((item, i) => (
                <img
                  key={item.id}
                  src={item.logo}
                  className={cn(
                    'relative mr-2 h-10 w-10 rounded-md border object-cover p-1 transition duration-150 ease-out',
                    isCollapsed ? 'opacity-100' : 'opacity-0',
                    item?.status !== 'ACTIVE' ? 'grayscale' : ''
                  )}
                  style={{
                    transitionDelay: `${
                      !isCollapsed
                        ? 75 * i
                        : 75 * (firstFiveChannels.length + i)
                    }ms`,
                  }}
                  alt="logo"
                />
              ))}
              {channelList.length > 5 ? (
                <p
                  className={cn(
                    'pointer-events-none mr-8 truncate whitespace-nowrap text-gray-500 transition-opacity duration-500',
                    isCollapsed ? 'opacity-100' : 'opacity-0'
                  )}
                >{`+ ${channelList.length - 5} more channel${
                  channelList.length - 5 > 1 ? 's' : ''
                }`}</p>
              ) : null}
            </>
          )}
        </button>
        <div
          ref={containerRef}
          className={cn(
            'transition-all duration-500',
            isCollapsed ? 'opacity-0' : 'opacity-100'
          )}
        >
          <SortableTable
            hideHeader
            data={channelList}
            isLoading={isLoadingChannels}
            format={CATEGORY_BOX_TABLE_FORMAT({
              merchantId,
              categoryId: data.id,
              onUpdate: refetchChannelList,
              onViewMatrix: handleViewMatrixFee,
            })}
            disabled={isCollapsed}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (!isEmpty(searchChannelString)) {
                toastInfo(
                  'Sorting is disabled when you are searching for channels'
                );
                return;
              }
              const channelId = channelList[oldIndex]?.id;
              setChannelList(
                arrayMoveImmutable(channelList, oldIndex, newIndex)
              );
              updateChannelSequence(channelId, { sequence: newIndex + 1 });
            }}
          />
        </div>
      </div>
    </Card>
  );
}

CategoryBox.defaultProps = {
  searchChannelString: '',
};

CategoryBox.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  merchantId: PropTypes.string.isRequired,
  searchChannelString: PropTypes.string,
};

export default CategoryBox;
