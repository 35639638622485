import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { RiSecurePaymentLine } from 'react-icons/ri';

function SetChannelTour({ onNext }) {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/merchants/profile/channels');
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <RiSecurePaymentLine className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">
            Setup payment channels
          </div>
          <div>
            You may set which channels are enabled on your payment page.
          </div>
          <div className="text-gray-200 mt-2">
            For test accounts, all channels are enabled by default.
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext()}>
          Next
        </Button>
      </div>
    </div>
  );
}

SetChannelTour.propTypes = {
  // data: PropTypes.instanceOf(Object).isRequired,
  onNext: PropTypes.func.isRequired,
  // onSkip: PropTypes.func.isRequired,
};

export default SetChannelTour;
