import { formatSort } from 'helper';
import { req } from 'react-reqq-lite';
import { transformUser, transformRole } from './transformers';
import * as c from './constants';

export const listUsers = (params) => {
  req.get({
    key: c.USER_LIST,
    url: '/user_management/v1/sub_users',
    params: formatSort(params),
    transform: (res) => ({
      list: (res.data || []).map(transformUser),
      pager: res?.meta || {},
    }),
  });
};

export const selectUser = (id) => {
  req.get({
    key: c.USER_SELECT,
    url: `/user_management/v1/sub_users/${id}`,
    transform: (res) => transformUser(res.data),
  });
};

export const createUser = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.post({
    key: c.USER_CREATE,
    url: '/user_management/v1/sub_users',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function')
        onSuccess(transformUser(res?.response?.data));
      if (typeof onInlineSuccess === 'function')
        onInlineSuccess(transformUser(res?.response?.data));
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};

export const updateUser = (id, onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.USER_UPDATE,
    url: `/user_management/v1/sub_users/${id}`,
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function')
        onSuccess(transformUser(res?.response?.data));
      if (typeof onInlineSuccess === 'function')
        onInlineSuccess(transformUser(res?.response?.data));
    },
    onError: () => {
      if (typeof onError === 'function') onError();
      if (typeof onInlineError === 'function') onInlineError();
    },
  });
};

export const removeUser = (onSuccess, onError) => (
  id,
  onInlineSuccess,
  onInlineError
) => {
  req.remove({
    key: c.USER_DELETE,
    url: `/user_management/v1/sub_users/${id}`,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
      if (typeof onInlineSuccess === 'function') onInlineSuccess();
    },
    onError: () => {
      if (typeof onError === 'function') onError();
      if (typeof onInlineError === 'function') onInlineError();
    },
  });
};

export const resendEmailSetPasswordLink = (onSuccess, onError) => (
  id,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.USER_EMAIL_RESEND,
    url: `/user_management/v1/sub_users/${id}/resend-email`,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function')
        onSuccess(transformUser(res?.response?.data));
      if (typeof onInlineSuccess === 'function')
        onInlineSuccess(transformUser(res?.response?.data));
    },
    onError: () => {
      if (typeof onError === 'function') onError();
      if (typeof onInlineError === 'function') onInlineError();
    },
  });
};

export const listRoles = () => {
  req.get({
    key: c.ROLE_LIST,
    url: '/merchant_acl/v1/roles',
    transform: (res) => (res.data || []).map(transformRole),
  });
};

export const attachRoleToUser = (onSuccess, onError) => (
  user_id,
  role_id,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.ROLE_ATTACHMENT,
    url: `/merchant_acl/v1/sub_users/${user_id}/roles`,
    payload: {
      role_uuid: role_id,
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function')
        onSuccess(transformUser(res?.response?.data));
      if (typeof onInlineSuccess === 'function')
        onInlineSuccess(transformUser(res?.response?.data));
    },
    onError: () => {
      if (typeof onError === 'function') onError();
      if (typeof onInlineError === 'function') onInlineError();
    },
  });
};
