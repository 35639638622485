import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BiExport } from 'react-icons/bi';
import { HiRefresh, HiDownload } from 'react-icons/hi';
import dayjs from 'dayjs';

import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import BreadCrumb from 'partial/components/BreadCrumb';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { showGenerateReportModal } from '../modals/GenerateReportModal';
import { useDownloadReport, useListReport } from '../hooks';

export const ReportStatus = ({ status }) => {
  const render = useMemo(() => {
    const badge = {
      PROCESSING: (
        <div className="badge badge-warning inline-block">Processing</div>
      ),
      SUCCESS: <div className="badge badge-success inline-block">Success</div>,
      default: <div className="badge badge-gray inline-block">{status}</div>,
    };
    return badge[status] || badge.default;
  }, [status]);
  return render;
};

ReportStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

function ReportsContainer({ merchantId }) {
  const [raw, setFilter] = useState({
    page: 1,
    merchant_uuid: merchantId,
    from: dayjs().startOf('month').format('YYYY-MM-DD'),
    to: dayjs().endOf('month').format('YYYY-MM-DD'),
  });
  const filter = useMemo(
    () => ({
      ...raw,
      from: raw?.from,
      to: raw?.to,
    }),
    [raw]
  );
  const [isLoading, list, pager, appendList, reload] = useListReport(filter);
  const [isDownloading, downloadReport] = useDownloadReport();
  const renderAdd = useMemo(() => {
    const add = () => {
      showGenerateReportModal(
        !merchantId
          ? {}
          : {
              initData: { merchant: { value: merchantId } },
              hideMerchantOptions: true,
            }
      );
    };
    return (
      <div className="flex space-x-2">
        <Button
          size="sm"
          onClick={() => reload()}
          icon={HiRefresh}
          disabled={isLoading}
        >
          Refresh
        </Button>
        <Button icon={BiExport} primary onClick={add}>
          Generate Report
        </Button>
      </div>
    );
    // eslint-disable-next-line
  }, [isLoading, merchantId]);
  const tableFormat = useMemo(() => {
    const handleDownload = (row) => () => {
      downloadReport(row?.id, row?.file_name, reload);
    };
    return [
      {
        label: 'Date/Time',
        key: 'created_at',
        id: 'created_at',
        stackKey: true,
      },
      { label: 'Scope', key: 'label', id: 'label', stackKey: true },
      // { label: 'Download Count', key: 'download_count', id: 'download_count' },
      {
        label: 'Status',
        key: (row) => <ReportStatus status={row?.status} />,
        id: 'status',
        stackKey: true,
      },
      {
        id: 'action',
        label: <span className="sr-only">Action</span>,
        key: (row) => (
          <div className="flex items-center space-x-2 transition">
            <Button
              size="sm"
              disabled={row?.status !== 'SUCCESS' || isDownloading}
              onClick={handleDownload(row)}
            >
              Download
            </Button>
          </div>
        ),
        width: 100,
        stackKey: true,
      },
      {
        smRender: (row) => (
          <div className="py-3">
            <div className="float-right text-right">
              <ReportStatus status={row?.status} />
              <Button
                size="xs"
                transparent
                disabled={row?.status !== 'SUCCESS' || isDownloading}
                onClick={handleDownload(row)}
                icon={HiDownload}
              >
                Download
              </Button>
            </div>
            <div className="text-primary-500">{row?.label}</div>
            <div className="text-gray-500">{row?.created_at}</div>
          </div>
        ),
      },
    ];
    // eslint-disable-next-line
  }, [isDownloading]);
  return (
    <>
      <BreadCrumb>Reports</BreadCrumb>
      <div className="flex flex-col sm:h-full">
        <div className="mb-3 flex justify-end">{renderAdd}</div>
        <div className="z-[1] mb-3">
          <ModuleListFilters
            filter={raw}
            setFilter={setFilter}
            activeFiltersFormat={[
              {
                label: 'Date Range',
                key: 'from',
                matrix: (val) =>
                  `${dayjs(val?.from).format('MMM DD, YYYY')} - ${dayjs(
                    val?.to
                  ).format('MMM DD, YYYY')}`,
                clearFilter: () =>
                  setFilter((state) => ({
                    ...state,
                    date_range_preset: '',
                    from: '',
                    to: '',
                  })),
              },
            ]}
          />
        </div>
        <div className="flex flex-grow flex-col overflow-hidden rounded-md border sm:h-0">
          <Table
            isLoading={isLoading}
            data={list}
            format={tableFormat}
            onSort={setFilter}
            pager={pager}
            onLoadMore={appendList}
          />
        </div>
      </div>
    </>
  );
}

ReportsContainer.defaultProps = {
  merchantId: '',
};

ReportsContainer.propTypes = {
  merchantId: PropTypes.string,
};

export default ReportsContainer;
