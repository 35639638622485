import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import PhoneInput from 'react-phone-input-2';
import {
  isValidPhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { useOnClickOutside } from 'helper';

import { useInputId } from './hooks';

function FormMobileNumber({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  disabled,
  readOnly,
}) {
  const containerRef = useRef();

  const [countryCode, setCountryCode] = useState('ph');
  const countryCallingCode = useMemo(
    () => `+${getCountryCallingCode(countryCode?.toUpperCase())}`,
    [countryCode]
  );

  const [isFocused, setIsFocused] = useState(false);
  const valueIsEmpty = useMemo(
    () => isEmpty(value?.replace(countryCallingCode, '')),
    [value, countryCallingCode]
  );
  const labelIsOnTop = useMemo(
    () => isFocused || !valueIsEmpty,
    [isFocused, valueIsEmpty]
  );

  const [id] = useInputId(defaultId);
  const handleChange = (v, data) => {
    setCountryCode(data?.countryCode);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: `+${v}`,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, `+${v}` ?? '');
    }
  };
  const showIcon = Icon || error;

  const isPhoneValid = useMemo(
    () => !value || isValidPhoneNumber(value, countryCode?.toUpperCase()),
    [value, countryCode]
  );

  const isError = !!error || !isPhoneValid;

  useOnClickOutside(containerRef, () => setIsFocused(false));

  return (
    <div ref={containerRef} className="relative z-[1]">
      {!required && (
        <span className="absolute -top-7 right-0 z-[1] text-sm text-gray-400">
          Optional
        </span>
      )}
      <PhoneInput
        id={id}
        buttonClass={cn(
          'absolute left-4 h-[20px] !top-[32px]',
          !labelIsOnTop ? 'opacity-0' : ''
        )}
        inputClass={cn(
          '!h-auto !w-full rounded-md border bg-white px-4 pb-4 pt-[34px] !text-base font-bold !leading-4',
          'text-gray-900 read-only:bg-gray-100 focus-within:ring-2 disabled:bg-gray-100',
          isError
            ? '!border-red-300 focus:!border-red-500 focus:!ring-red-300'
            : '!border-gray-200 focus:!border-primary-600 focus:!ring-primary-400',
          !labelIsOnTop ? '!text-transparent' : ''
        )}
        buttonStyle={{
          border: 0,
        }}
        onFocus={() => setIsFocused(true)}
        country={countryCode}
        countryCodeEditable={false}
        value={value}
        onChange={handleChange}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        autoFormat={false}
        disableCountryGuess
        specialLabel="label"
      />
      <label
        htmlFor={id}
        className={cn(
          'no-highlight pointer-events-none absolute left-4 -translate-y-1/2 transition-all',
          labelIsOnTop
            ? 'top-[1.625rem] text-xs text-gray-600'
            : 'top-1/2 bg-transparent text-sm text-gray-400'
        )}
      >
        <span>{label}</span>
      </label>
      {showIcon && (
        <div
          className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          title={error || ''}
        >
          {error ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </div>
      )}
    </div>
  );
}

FormMobileNumber.defaultProps = {
  id: '',
  icon: false,
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  disabled: false,
  readOnly: false,
};

FormMobileNumber.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormMobileNumber);
