import React, { useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { HiCreditCard } from 'react-icons/hi';
import { Link, NavLink } from 'react-router-dom';
import { MdStore, MdStickyNote2, MdSpaceDashboard } from 'react-icons/md';

import { useActiveAccount } from 'modules/auth/hooks';
import { useAccessControl } from 'modules/common/hooks';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import { MERCHANT_SUB_USER_MODULES_MAP } from 'core-modules/users/constants';

const NavItem = ({
  label,
  icon: Icon,
  to,
  onClick,
  hasBadge,
  disabled,
  ...rest
}) => {
  return (
    <NavLink
      to={to}
      className={cn('bottom-nav-link', disabled ? 'disabled' : '')}
      activeClassName="active"
      onClick={onClick}
      {...rest}
    >
      {hasBadge && (
        <span className="absolute right-4 top-2 h-3 w-3 rounded-full bg-red-500" />
      )}
      <Icon className="icon" aria-hidden="true" />
      <span className="text-[0.625rem] leading-4">{label}</span>
    </NavLink>
  );
};

NavItem.defaultProps = {
  onClick: () => {},
  hasBadge: false,
  disabled: false,
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hasBadge: PropTypes.bool,
  disabled: PropTypes.bool,
};

const MAP_CREATE_BILL_ROUTES = {
  org_user: '/get-paid/invoice/create',
  sub_user: '/get-paid/bill/create',
};

const GET_MAP_CREATE_BILL_USER_TYPE_STATE = (merchant) => ({
  sub_user: {
    merchantLock: true,
    merchant: {
      label: merchant.name,
      value: merchant.id,
    },
  },
});

function Navigation({ showCta }) {
  const [activeAccount] = useActiveAccount();
  const { userType, canRead } = useAccessControl();
  const [, currentMerchant] = useMerchantSelected();

  const navigationRoutes = useMemo(() => {
    const navRoutes = [];
    if (userType === 'org_user') {
      navRoutes.push({
        to: '/dashboard',
        label: 'Dashboard',
        icon: MdSpaceDashboard,
      });
      navRoutes.push({
        to: '/transactions',
        label: 'Transactions',
        icon: MdStickyNote2,
      });
      navRoutes.push({
        to: '/merchants/profiles',
        label: 'Merchants',
        icon: MdStore,
        'data-tour': 'sidebar-merchant',
      });
      navRoutes.push({
        to: '/get-paid/invoice',
        label: 'Invoice',
        icon: HiCreditCard,
      });
    }
    if (userType === 'sub_user') {
      MERCHANT_SUB_USER_MODULES_MAP.forEach((module) => {
        if (canRead(module.code)) {
          navRoutes.push({
            to: module.url,
            label: module.label,
            icon: module.icon,
          });
        }
      });
    }
    return navRoutes;
  }, [canRead, userType]);

  if (!activeAccount?.id) {
    return (
      <div className="p-4 text-center text-sm italic text-gray-500">
        Organization not found.
      </div>
    );
  }

  return (
    <nav className="grid h-full w-full grid-cols-5 px-2">
      {navigationRoutes?.slice(0, 2)?.map((navRoute, i) => {
        return (
          <NavItem
            key={navRoute?.to}
            {...navRoute}
            disabled={
              i !== 0 &&
              !activeAccount?.is_org_kyc_completed &&
              activeAccount?.env_mode !== 'TEST'
            }
          />
        );
      })}
      <div className="relative flex items-end justify-center pb-2">
        <Link
          to={{
            pathname: MAP_CREATE_BILL_ROUTES[userType],
            state:
              GET_MAP_CREATE_BILL_USER_TYPE_STATE(currentMerchant)[userType],
          }}
          className={cn(
            'absolute left-1/2 top-2 grid h-16 w-16 -translate-x-1/2 -translate-y-1/2 place-items-center rounded-full bg-secondary-500 transition-transform duration-300 ease-bounce',
            {
              'pointer-events-none scale-0': !showCta,
            }
          )}
        >
          <FaPlus className="text-white" size={15} />
        </Link>
        <span className="text-[0.625rem] font-medium leading-4 text-secondary-500">
          Get Paid
        </span>
      </div>
      {navigationRoutes?.slice(2, 4)?.map((navRoute) => {
        return (
          <NavItem
            key={navRoute?.to}
            {...navRoute}
            disabled={
              !activeAccount?.is_org_kyc_completed &&
              activeAccount?.env_mode !== 'TEST'
            }
          />
        );
      })}
    </nav>
  );
}

Navigation.propTypes = {
  showCta: PropTypes.bool.isRequired,
};

function Bottombar({ show }) {
  return (
    <div className={cn('bottombar', !show && 'translate-y-20')}>
      <Navigation showCta={show} />
    </div>
  );
}

Bottombar.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Bottombar;
