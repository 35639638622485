import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TiWarning } from 'react-icons/ti';
import { HiCheck, HiX } from 'react-icons/hi';
import { BsRecordFill } from 'react-icons/bs';
import { MdOutlineReceipt, MdReviews } from 'react-icons/md';

import { useActiveAccount } from 'modules/auth/hooks';

import { BG_COLOR_CLASS } from '../constants';

const renderBadge = (origin, type) => {
  if (type === 'SUCCESS')
    return (
      <div className="rounded-full border-2 border-white bg-green-500 p-1 text-white">
        <HiCheck className="h-3 w-3 flex-shrink-0" />
      </div>
    );
  if (type === 'DANGER')
    return (
      <div className="rounded-full border-2 border-white bg-red-500 p-1 text-white">
        <HiX className="h-3 w-3 flex-shrink-0" />
      </div>
    );
  if (type === 'PRIMARY' && origin === 'KYC')
    return (
      <div className="rounded-full border-2 border-white bg-yellow-500 p-1 text-white">
        <MdReviews className="h-3 w-3 flex-shrink-0" />
      </div>
    );
  if (type === 'WARNING')
    return (
      <div className="rounded-full border-2 border-white bg-yellow-500 p-1 text-white">
        <TiWarning className="h-3 w-3 flex-shrink-0" />
      </div>
    );
  return null;
};

const renderIcon = (data) => {
  if (data?.origin === 'INVOICE')
    return (
      <div
        className={cn(
          'flex  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full text-center leading-[2.625rem] text-white',
          BG_COLOR_CLASS[data?.type]
        )}
      >
        <MdOutlineReceipt className="h-6 w-6 flex-shrink-0 text-white" />
      </div>
    );
  if (data?.origin === 'KYC')
    return (
      <div className="h-12 w-12 rounded-full bg-slate-500 text-center uppercase leading-[2.625rem] text-white">
        {data?.meta?.merchant_name
          ?.split(' ')
          .map((word) => word?.[0] || '')
          .slice(0, 2)
          .join('')}
      </div>
    );
  return null;
};

const renderContent = (data) => {
  if (data?.origin === 'INVOICE')
    return (
      <p>
        Invoice amounting <b>&#8369;{data?.meta?.amount}</b> has been
        {data?.meta?.status === 'PAID' ? (
          <>
            <b> paid</b> by <b>{data?.meta?.recipient_name || 'Unknown'}</b>
          </>
        ) : (
          <b className="lowercase"> {data?.meta?.status}</b>
        )}
      </p>
    );
  if (data?.origin === 'KYC')
    return (
      <>
        {data?.meta?.status === 'APPROVED' ? 'Congratulations! ' : ''}
        Application for <b>{data?.meta?.merchant_name} </b>
        {data?.meta?.status === 'FOR_APPROVAL' ? (
          <>
            is being <b>reviewed</b>
          </>
        ) : (
          <>
            has been <b className="lowercase">{data?.meta?.status}</b>
          </>
        )}
      </>
    );
  return null;
};

function NotificationItem({ data, onClick }) {
  const [activeAccount] = useActiveAccount();

  const destinationObject = useMemo(() => {
    if (data?.origin === 'INVOICE')
      return { pathname: `/get-paid/invoice/${data?.meta?.invoice_id}` };
    if (data?.origin === 'KYC') {
      if (!activeAccount.is_org_kyc_completed)
        return {
          pathname: `/onboarding`,
        };
      return {
        pathname: `/merchants/profiles/${data?.merchant_id}/details`,
        // state: {
        //   selected: {
        //     id: data?.merchant_id,
        //   },
        //   shouldManuallySelect:
        //     location?.pathname?.indexOf('/merchants/profile') === 0,
        // },
      };
    }
    return '/';
  }, [data, activeAccount]);

  return (
    <Link
      to={destinationObject}
      onClick={() => {
        if (typeof onClick === 'function') onClick();
      }}
    >
      <div className="block p-4 transition ease-in-out hover:bg-primary-50/50">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex space-x-4">
            <div className="relative">
              <div className="absolute -top-2 -right-2">
                {renderBadge(data?.origin, data?.type)}
              </div>
              {renderIcon(data)}
            </div>
            <div>
              <div className="font-normal text-slate-700">
                {renderContent(data)}
              </div>
              <div className="text-xs font-normal text-slate-500">
                {dayjs().to(data?.created_at_raw)}
              </div>
            </div>
          </div>
          <BsRecordFill
            className={cn(
              'inline-block h-2 w-2 flex-shrink-0 rounded-full bg-red-500 text-red-500',
              data?.is_read ? 'invisible' : ''
            )}
          />
        </div>
      </div>
    </Link>
  );
}

NotificationItem.defaultProps = {
  onClick: null,
};

NotificationItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
};

export default NotificationItem;
