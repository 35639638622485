import React, { useMemo } from 'react';
import cn from 'classnames';
import { BiMailSend } from 'react-icons/bi';
import { RiUserAddLine } from 'react-icons/ri';
import { toastSuccess } from 'react-geek-toast';

import PropTypes from 'prop-types';
import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import { showAlert } from 'partial/components/Modal';
import Table, { TableActions } from 'partial/components/Table';

import {
  useRoleList,
  useRemoveUser,
  useUserListData,
  useResendEmailSetPasswordLink,
} from '../hooks';
import { useOpenUserModal } from '../modals/UserModal';

function AclUsers({ merchantId }) {
  const userFilter = useMemo(() => ({ merchant_uuid: merchantId }), [
    merchantId,
  ]);

  const [isLoading, data, refetchData] = useUserListData({
    filter: userFilter,
  });
  const [, removeUser] = useRemoveUser({
    onSuccess: () => {
      toastSuccess('User Deleted');
      refetchData();
    },
  });
  const [, resendEmailSetPasswordLink] = useResendEmailSetPasswordLink({
    onSuccess: () => {
      toastSuccess('Email Sent');
    },
  });
  const openUserModal = useOpenUserModal({
    onSuccess: refetchData,
  });

  const renderAdd = React.useMemo(() => {
    return (
      <div>
        <Button
          primary
          icon={RiUserAddLine}
          onClick={() => openUserModal('Add User', { merchantId })}
        >
          Add User
        </Button>
      </div>
    );
  }, [merchantId, openUserModal]);

  const tableFormat = React.useMemo(() => {
    const handleEdit = (row) => openUserModal('Edit User', row);
    const handleResendEmail = (row) => {
      showAlert({
        title: 'Resend Email',
        yesLabel: 'Send',
        color: 'primary',
        onYes: (closeAlert) => {
          resendEmailSetPasswordLink(row?.id);
          closeAlert();
        },
      });
    };
    const handleDelete = (row) => {
      showAlert({
        title: 'Delete',
        yesLabel: 'Delete',
        onYes: (closeAlert) => {
          removeUser(row?.id);
          closeAlert();
        },
      });
    };
    return [
      {
        label: 'Name',
        key: (row) => `${row?.first_name} ${row?.last_name}`,
        id: 'name',
      },
      {
        label: 'Email',
        key: (row) => (
          <div>
            {row?.email_address}{' '}
            <span
              className={cn(
                'whitespace-nowrap badge',
                row?.is_email_verified ? 'badge-success' : 'badge-gray'
              )}
            >
              {row?.is_email_verified ? 'verified' : 'pending'}
            </span>
          </div>
        ),
        id: 'email',
      },
      { label: 'Mobile No.', key: 'mobile_number', id: 'mobile_number' },
      {
        label: 'Role',
        key: 'role',
      },
      {
        width: 100,
        id: 'actions',
        label: <span className="sr-only">Actions</span>,
        key: (row) => {
          const format = row?.is_email_verified
            ? []
            : [
                {
                  label: 'Resend',
                  icon: BiMailSend,
                  buttonClassName: 'link-primary',
                  onClick: () => handleResendEmail(row),
                },
              ];
          return (
            <TableActions
              onEdit={() => handleEdit(row)}
              onDelete={() => handleDelete(row)}
              otherActionsFormat={format}
            />
          );
        },
      },
    ];
  }, [removeUser, openUserModal, resendEmailSetPasswordLink]);

  useRoleList();

  return (
    <Card
      label="Users"
      renderAction={renderAdd}
      className="flex flex-col flex-1"
    >
      <Table
        data={data?.list || []}
        format={tableFormat}
        isLoading={isLoading}
      />
    </Card>
  );
}

AclUsers.defaultProps = {
  merchantId: '',
};

AclUsers.propTypes = {
  merchantId: PropTypes.string,
};

export default AclUsers;
