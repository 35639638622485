import React, { useState, useCallback } from 'react';

import analytics from 'utils/analytics';
import { useOpenBetaPromotionModal } from 'modules/common/hooks';

import LoginForm from '../forms/LoginForm';
import LandingWrapper from '../wrappers/LandingWrapper';
import MultiFactorAuthForm from '../forms/MultiFactorAuthForm';

const Login = () => {
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const openBetaPromotionModal = useOpenBetaPromotionModal();

  const handleOnLogin = useCallback(
    (res) => {
      const { is_mfa_enabled = false } = res?.response?.data || {};
      if (
        !is_mfa_enabled &&
        localStorage.getItem('always_hide_beta_promotion') !== '1'
      )
        openBetaPromotionModal();
      setIsMFAEnabled(is_mfa_enabled);
      analytics.done('login');
    },
    [setIsMFAEnabled, openBetaPromotionModal]
  );

  const handleOnSessionExpires = useCallback(
    () => setIsMFAEnabled(false),
    [setIsMFAEnabled]
  );
  return (
    <LandingWrapper
      title="Sign in"
      description="Welcome! Please enter your details"
    >
      {!isMFAEnabled ? (
        <LoginForm onLogin={handleOnLogin} />
      ) : (
        <MultiFactorAuthForm onSessionExpires={handleOnSessionExpires} />
      )}
    </LandingWrapper>
  );
};

export default Login;
