import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

function PieChart({ data, labelKey, valueKey, isLoading, limit }) {
  const options = {
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          borderRadius: 5,
        },
      },
    },
    labels: (data || [])
      .filter((x, i) => i < limit)
      .map((item) => item[labelKey] ?? null),
    colors: ['#10335f', '#184f92', '#216bc5', '#ACCBF1', '#e4ecf8'],
    dataLabels: {
      style: {
        colors: ['#fff', '#fff', '#fff', '#184f92', '#10335f'],
      },
    },
    legend: {
      // show: false,
      position: 'bottom',
    },
  };
  const series = (data || [])
    .filter((x, i) => i < limit)
    .map((item) => item[valueKey] ?? null);
  if (isLoading) {
    return (
      <div className="flex h-full w-full">
        <div className="m-auto">
          <div className="relative h-64 w-64 animate-pulse rounded-full bg-gray-200" />
          <div className="mt-6 grid grid-cols-3 gap-3">
            <div className="h-8 w-full animate-pulse rounded bg-gray-200" />
            <div className="h-8 w-full animate-pulse rounded bg-gray-200" />
            <div className="h-8 w-full animate-pulse rounded bg-gray-200" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <ReactApexChart
      height="100%"
      options={options}
      series={series}
      type="donut"
    />
  );
}

PieChart.defaultProps = {
  labelKey: 'title',
  valueKey: 'count',
  isLoading: false,
  data: [],
  limit: 10,
};

PieChart.propTypes = {
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  data: PropTypes.instanceOf(Array),
};

export default PieChart;
