/* eslint-disable */
import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import Form, { ErrorLabel } from 'partial/form/Form';
import FormUrl from 'partial/form/FormUrl';
import Button from 'partial/components/Button';
import FormToggle from 'partial/form/FormToggle';
import { showDrawer } from 'partial/components/Modal';

import { useCreateApiKey, useUpdateApiKey } from '../hooks';
import FormMultipleSelect from 'partial/form/FormMultipleSelect';

export const showApiKeyModal = (data, action) => {
  showDrawer({
    size: 'sm',
    title: action === 'add' ? 'Add API Key' : 'Update API Key',
    content: (closeModal) => (
      <ApiKeyModal closeModal={closeModal} data={data} action={action} />
    ),
  });
};

const required = 'Required';

const validationSchema = yup.object({
  callback_url: yup
    .string()
    .test({
      name: 'is_empty',
      test: (value) => {
        let testValue = '';
        if (value?.includes('http://'))
          testValue = value?.replace('http://', '');
        if (value?.includes('https://'))
          testValue = value?.replace('https://', '');
        return !!testValue;
      },
      message: required,
    })
    .matches(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/,
      'Invalid URL'
    ),
  api_type: yup.array().min(1, 'Choose at least 1 API Type').required(required),
});

const MAP_API_TYPE = {
  'payment-gateway': 'Payment Gateway',
  invoice: 'Invoice',
  transaction: 'Transaction',
};

function ApiKeyModal({ closeModal, data, action }) {
  const [isCreating, create] = useCreateApiKey();
  const [isUpdating, update] = useUpdateApiKey();
  const initialValues = React.useMemo(
    () => ({
      callback_url: data?.callback_url,
      api_type: data?.api_type?.map((item) => ({
        label: MAP_API_TYPE[item],
        value: item,
      })),
      status: data?.status === 'ACTIVE' ? true : false,
    }),
    [data]
  );
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({
    validateOnChange,
    initialValues: initialValues,
    validationSchema,
    onSubmit: (form) => {
      const payload = {
        ...form,
        status: form?.status ? 'ACTIVE' : 'INACTIVE',
        api_type: form?.api_type?.map((item) => item?.value),
      };
      if (action === 'add') {
        create(payload, closeModal);
        return;
      }
      update(data?.merchant_id, data?.api_key_id, payload, closeModal);
    },
  });

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Form
      className="flex flex-col space-y-3"
      onSubmit={handleSubmit}
      error={errors}
    >
      <div>
        <FormUrl
          name="callback_url"
          label="Callback Url"
          onSetFieldValue={setFieldValue}
          value={values.callback_url || ''}
          required
        />
        <ErrorLabel name="callback_url" />
      </div>
      <div className="relative z-[1]">
        <FormMultipleSelect
          name="api_type"
          label="API Type"
          onChange={(newValues) => setFieldValue('api_type', newValues)}
          options={[
            { label: 'Payment Gateway', value: 'payment-gateway' },
            { label: 'Invoice', value: 'invoice' },
            { label: 'Transaction', value: 'transaction' },
          ]}
          selectedOptions={values.api_type || []}
          isMulti
        />
        <ErrorLabel name="api_type" />
      </div>
      <div className="flex flex-col">
        <span className="mb-1 block text-xs font-medium text-primary-500">
          Status
        </span>
        <div className="flex space-x-3">
          <FormToggle
            name="status"
            onSetFieldValue={setFieldValue}
            checked={values.status || false}
          />
          <label className="text-center font-medium">
            {values.status ? 'Active' : 'Inactive'}
          </label>
        </div>
        <ErrorLabel name="status" />
      </div>
      <div className="flex justify-end space-x-2 border-t pt-4">
        <Button type="button" className="mr-2" onClick={closeModal}>
          Close
        </Button>
        <Button
          disabled={isCreating || isUpdating}
          type="submit"
          primary
          onClick={() => setValidateOnChange(true)}
        >
          {action === 'add' ? 'Add API Key' : 'Save API Key'}
        </Button>
      </div>
    </Form>
  );
}

ApiKeyModal.defaultProps = {
  data: {},
  action: 'add',
};

ApiKeyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
  action: PropTypes.string,
};

export default ApiKeyModal;
