import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Table from 'partial/components/Table';
import { useActiveAccount } from 'modules/auth/hooks';
import BreadCrumb from 'partial/components/BreadCrumb';
import NavTab, { NavTabItem } from 'partial/components/NavTab';
import { TIME_PRESET_VALUE } from 'modules/common/components/DateRangePicker';

import {
  useOpenPromptSwitchToLiveMode,
  useOpenCreateRefundRequestModal,
} from '../modals/RefundRequestModal';
import { useRefundList } from '../hooks';
import { REFUND_TABLE_FORMAT } from '../constants';
import RefundFilter from '../components/RefundFilter';
import { useOpenViewRefundModal } from '../modals/ViewRefundModal';

const INIT_FILTER = {
  page: 1,
  status: 'PENDING',
  sort: 'created_at:desc',
  date_range_preset: 'LAST_MONTH',
  ...TIME_PRESET_VALUE.LAST_MONTH,
};

function RefundContainer({ filter: otherFilter }) {
  const [activeAccount] = useActiveAccount();
  const [raw, setFilter] = useState(INIT_FILTER);
  const filter = useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );

  const [isLoading, list, pager, refetchRefundList, appendList] =
    useRefundList(filter);
  const openViewRefundModal = useOpenViewRefundModal();
  const openCreateRefundRequestModal =
    useOpenCreateRefundRequestModal(refetchRefundList);
  const openPromptSwitchToLiveMode = useOpenPromptSwitchToLiveMode();

  const handleCreateRefundRequest = () => {
    if (activeAccount?.env_mode === 'TEST') {
      openPromptSwitchToLiveMode();
      return;
    }
    openCreateRefundRequestModal();
  };

  const handleToggleNav = (status) => (e) => {
    e.preventDefault();
    setFilter((x) => ({ ...x, status }));
  };

  return (
    <>
      <BreadCrumb>Refund</BreadCrumb>
      <div className="flex items-center rounded-t-lg bg-white">
        <div className="flex-1">
          <NavTab>
            <NavTabItem
              to="/"
              isActive={() => raw?.status === 'PENDING'}
              onClick={handleToggleNav('PENDING')}
              label="For Approval"
            />
            <NavTabItem
              to="/"
              isActive={() => raw?.status === 'APPROVED'}
              onClick={handleToggleNav('APPROVED')}
              label="Approved"
            />
            <NavTabItem
              to="/"
              isActive={() => raw?.status === 'REJECTED'}
              onClick={handleToggleNav('REJECTED')}
              label="Rejected"
            />
          </NavTab>
        </div>
      </div>
      <div className="flex flex-grow flex-col rounded-lg border bg-white">
        <div className="z-[1] p-3">
          <RefundFilter
            value={filter}
            onSubmit={setFilter}
            action={handleCreateRefundRequest}
          />
        </div>
        <div className="flex flex-grow flex-col overflow-hidden rounded-md border sm:h-0">
          <Table
            isLoading={isLoading}
            data={list || []}
            format={REFUND_TABLE_FORMAT}
            onSort={setFilter}
            sort={raw.sort}
            onRowClick={openViewRefundModal}
            pager={pager}
            onLoadMore={appendList}
          />
        </div>
      </div>
    </>
  );
}

RefundContainer.defaultProps = {
  filter: {},
};

RefundContainer.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default RefundContainer;
