import React from 'react';
import { HiUser } from 'react-icons/hi';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ThumbIcon from 'partial/components/ThumbIcon';
import { useCurrentAccount } from 'modules/auth/hooks';
import DropdownMenu, {
  DropdownMenuItem,
} from 'partial/components/DropdownMenu';
import { useOpenTypeConfirmAlert } from 'partial/components/Modal';

import { useRemoveMerchant } from '../hooks';
import { MAP_BADGE_CLASS } from '../constants';

function MerchantHeader({ data }) {
  const history = useHistory();
  const openTypeConfirmAlert = useOpenTypeConfirmAlert();
  const [, activeAccount] = useCurrentAccount();
  const [, deleteMerchant] = useRemoveMerchant();
  const handleDelete = () => {
    openTypeConfirmAlert({
      onYes: (close) => {
        close();
        deleteMerchant(data.id, () => history.push('/merchants/profiles'));
      },
      onNo: (close) => close(),
      yesLabel: 'Delete',
      noLabel: 'Cancel',
      label: 'Delete Merchant',
      subLabel: '',
      stringToMatch: 'CONFIRM',
    });
  };

  return (
    <div
      className="flex items-center justify-between rounded-md border bg-gray-100 p-4"
      style={{
        backgroundImage: data?.banner ? `url(${data?.banner})` : null,
      }}
    >
      <div className="flex items-center space-x-3 sm:items-start">
        <div className="flex-shrink-0">
          <div className="relative">
            <ThumbIcon
              className="h-[2.625rem] w-[2.625rem]"
              url={data?.logo}
              alt={data?.name}
              icon={data?.name ? null : HiUser}
              iconClassName="h-12 w-12"
            />
            <span
              className="absolute inset-0  rounded-full"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="">
          <h1 className="break-all text-base font-bold leading-[1.125rem] text-gray-900 line-clamp-1">
            {data?.name}
          </h1>
          {activeAccount?.env_mode === 'LIVE' ? (
            <span
              className={cn(
                'badge text-xs leading-4',
                MAP_BADGE_CLASS[data.approval_status]
              )}
            >
              {data?.approval_status_label}
            </span>
          ) : (
            <span className="badge badge-warning text-xs leading-4">
              TEST MODE
            </span>
          )}
        </div>
      </div>
      <DropdownMenu>
        <DropdownMenuItem label="Delete Merchant" onClick={handleDelete} />
      </DropdownMenu>
    </div>
  );
}

MerchantHeader.defaultProps = {};

MerchantHeader.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MerchantHeader;
