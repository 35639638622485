import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { openTestApiModal } from 'core-modules/developers/modals/TestApiModal';
import { AiOutlineApi } from 'react-icons/ai';

function ApiIntegrationTour({ onNext }) {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/merchants/profile/integration');
    // eslint-disable-next-line
  }, []);
  const handleOpenDocs = (e) => {
    e.preventDefault();
    openTestApiModal();
  };
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <AiOutlineApi className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">
            Integrate via API
          </div>
          <ul>
            <li className="flex space-x-2 items-center flex-shrink-0">
              <span className="w-4 h-1 bg-gray-600 rounded-full" />
              <span>Manage your API keys</span>
            </li>
            <li className="flex space-x-2 items-center flex-shrink-0">
              <span className="w-4 h-1 bg-gray-600 rounded-full" />
              <span>
                <a
                  className="hover:underline"
                  href="/"
                  onClick={handleOpenDocs}
                >
                  Click here
                </a>{' '}
                to view API documentation
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext('test-payment')}>
          Next
        </Button>
      </div>
    </div>
  );
}

ApiIntegrationTour.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default ApiIntegrationTour;
