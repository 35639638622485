import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { formatNumber } from 'helper';

export const PagerTotal = ({ data }) => {
  if (!data?.total) return null;
  return (
    <div className="px-4 py-3">
      <div className="text-sm font-medium text-gray-700">
        Total: {data?.total ?? '-'}
      </div>
    </div>
  );
};

PagerTotal.defaultProps = {
  data: null,
};

PagerTotal.propTypes = {
  data: PropTypes.instanceOf(Object),
};

function PagerPage({ active, page }) {
  const handleChangePage = (e) => {
    e.preventDefault();
  };
  return (
    <a
      key={page}
      href="/"
      onClick={handleChangePage}
      aria-current="page"
      className={cn(
        active
          ? 'z-10 border-indigo-500 bg-indigo-50 text-indigo-600'
          : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50',
        'relative inline-flex items-center border px-4 py-2 text-sm font-medium'
      )}
    >
      {page}
    </a>
  );
}

PagerPage.defaultProps = {
  active: false,
};

PagerPage.propTypes = {
  active: PropTypes.bool,
  page: PropTypes.number.isRequired,
};

function Pagination({ value, isLoading, onChange }) {
  const [pageValue, setPageValue] = React.useState();
  const handlePrev = () => {
    const newPage = value.current_page - 1;
    if (newPage <= 0) return;
    onChange((state) => ({ ...state, page: newPage }));
  };

  const handleNext = () => {
    const newPage = value.current_page + 1;
    if (newPage > value.last_page) return;
    onChange((state) => ({ ...state, page: newPage }));
  };

  const handleSetPage = (newPage) => {
    onChange((state) => ({
      ...state,
      page: newPage,
    }));
  };

  React.useEffect(() => {
    if (!isLoading) {
      setPageValue(value);
    }
  }, [isLoading, value]);

  if (!value?.last_page > 2) return null;

  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6">
      <div className="flex w-full items-center justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">
              {formatNumber(pageValue?.last_page || '1', 0)}
            </span>{' '}
            page(s)
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md"
            aria-label="Pagination"
          >
            <button
              type="button"
              onClick={handlePrev}
              className={cn(
                'relative inline-flex items-center rounded-l-md bg-none px-2 py-2 text-sm font-medium',
                pageValue?.current_page === 1
                  ? 'cursor-not-allowed text-gray-400'
                  : 'text-gray-700 hover:bg-primary-500 hover:text-white'
              )}
            >
              <span className="sr-only">Previous</span>
              <HiOutlineChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {value?.last_page > 2 &&
              !(pageValue?.current_page + 1 > pageValue?.last_page) &&
              !(pageValue?.last_page - 1 <= pageValue?.current_page) && (
                <>
                  {[0, 1].map((i) => (
                    <button
                      key={i}
                      type="button"
                      onClick={() => handleSetPage(pageValue?.current_page + i)}
                      className={cn(
                        'relative inline-flex items-center bg-none py-2 text-sm font-medium',
                        !i
                          ? 'bg-primary-500 px-4 text-white'
                          : 'px-3 text-gray-700'
                      )}
                    >
                      {pageValue?.current_page + i}
                    </button>
                  ))}
                  {pageValue?.current_page + 1 < pageValue?.last_page && (
                    <>
                      <button
                        type="button"
                        className="relative inline-flex items-center bg-none px-3 py-2 text-sm font-medium"
                      >
                        ...
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSetPage(pageValue?.last_page)}
                        className="relative inline-flex items-center bg-none px-3 py-2 text-sm font-medium"
                      >
                        {pageValue?.last_page}
                      </button>
                    </>
                  )}
                </>
              )}
            {value?.last_page > 2 &&
              pageValue?.last_page - 1 <= pageValue?.current_page && (
                <>
                  {pageValue?.last_page - 2 > 1 && (
                    <>
                      <button
                        type="button"
                        onClick={() => handleSetPage(1)}
                        className="relative inline-flex items-center bg-none px-3 py-2 text-sm font-medium"
                      >
                        1
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex items-center bg-none px-3 py-2 text-sm font-medium"
                      >
                        ...
                      </button>
                    </>
                  )}
                  {[0, 1].map((i) => (
                    <button
                      key={i}
                      type="button"
                      onClick={() =>
                        handleSetPage(pageValue?.last_page - 1 + i)
                      }
                      className={cn(
                        'relative inline-flex items-center bg-none py-2 text-sm font-medium',
                        pageValue?.current_page === pageValue?.last_page - 1 + i
                          ? 'bg-primary-500 px-4 text-white'
                          : 'px-3 text-gray-700'
                      )}
                    >
                      {pageValue?.last_page - 1 + i}
                    </button>
                  ))}
                </>
              )}
            {/* <button
              type="button"
              disabled
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium"
            >
              <span>{formatNumber(pageValue?.current_page || '1', 0)}</span>
            </button> */}
            <button
              type="button"
              onClick={handleNext}
              className={cn(
                'relative inline-flex items-center rounded-r-md bg-none px-2 py-2 text-sm font-medium',
                pageValue?.current_page === pageValue?.last_page
                  ? 'cursor-not-allowed text-gray-400'
                  : 'text-gray-700 hover:bg-primary-500 hover:text-white'
              )}
            >
              <span className="sr-only">Next</span>
              <HiOutlineChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  value: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

Pagination.defaultProps = {
  onChange: () => {},
  value: {
    current_page: 1,
    last_page: 1,
    total: 1,
  },
  isLoading: false,
};

export default Pagination;
