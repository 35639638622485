import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

function LineChartSeries({ labels, series, colors }) {
  const options = React.useMemo(
    () => ({
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      legend: {
        position: 'top',
      },
      xaxis: {
        categories: labels,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors,
    }),
    [labels, colors]
  );
  const s = React.useRef();
  const mySeries = React.useMemo(() => {
    if (isEqual(s.current, series)) return s.current || [];
    s.current = series;
    return series;
  }, [series]);
  return (
    <ReactApexCharts
      height="100%"
      options={options}
      series={mySeries}
      type="area"
    />
  );
}
LineChartSeries.defaultProps = {
  labels: [],
  series: [],
  colors: [],
};

LineChartSeries.propTypes = {
  labels: PropTypes.instanceOf(Array),
  series: PropTypes.instanceOf(Array),
  colors: PropTypes.instanceOf(Array),
};

export default LineChartSeries;
