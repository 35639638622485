import React from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import FormDate from 'partial/form/FormDate';
import FormPhoto from 'partial/form/FormPhoto';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
// import FormPhotoDnd from 'partial/form/FormPhotoDnd';
import Form, { ErrorLabel } from 'partial/form/Form';
import { useCreateModal } from 'partial/components/Modal';
import {
  useCreateOnboarding,
  useActiveAccount,
  useAccountMeta,
} from 'modules/auth/hooks';
import FormSelect from 'partial/form/FormSelect';
import ValidIDs from 'core-modules/merchant-onboarding/components/ValidIDs';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';
import FormDatasetOptions from 'core-modules/dataset/components/FormDatasetOptions';
import AttachmentPreview from 'core-modules/merchant-onboarding/components/AttachmentPreview';

import { SUFFIXES } from '../constants';
import TestModeCard from '../components/TestModeCard';

const validationSchema = yup.object().shape({
  owner_first_name: yup.string().required('Required'),
  owner_last_name: yup.string().required('Required'),
  owner_valid_id_type: yup.string().required('Required'),
  owner_valid_id_url: yup.string().required('Required'),
  owner_birth_date: yup
    .string()
    .required('Required')
    .test({
      name: 'date must be in correct format',
      test: (value) => {
        return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value;
      },
      message: 'Invalid date format',
    })
    .test({
      name: 'date must be in the past',
      test: (value) => {
        return dayjs(value).isBefore(Date.now());
      },
      message: "Birthdate can't be in future",
    }),
  owner_photo_url: yup.string().required('Required'),
});
function ProfilePage({ onNext, readOnly }) {
  const history = useHistory();
  const { meta, update } = useAccountMeta();
  const [activeAccount] = useActiveAccount();
  const [isSubmitting, submit] = useCreateOnboarding();

  const [validateOnChange, setValidateOnchange] = React.useState(false);
  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: activeAccount,
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      submit(
        form,
        (v) => {
          onNext(v);
          if (!meta?.is_org_profile_submitted)
            update({ is_org_profile_submitted: true });
        },
        (err) => {
          each(err?.response?.errors, (v, k) => {
            setFieldError(k, v);
          });
        }
      );
    },
    validateOnChange,
  });

  // const handleSkipOnboarding = () => {
  //   history.push('/dashboard');
  //   AuthStore.set('_skip_onboarding', true);
  // };
  const modal = useCreateModal();
  const handleOpenValidIDModal = () => {
    modal({
      content: (onClose) => <ValidIDs onClose={onClose} />,
    });
  };

  const validIDs = React.useMemo(
    () => values?.owner_valid_id_url?.split(',').filter((v) => !isEmpty(v)),
    [values]
  );

  return (
    <Form onSubmit={handleSubmit} error={errors} noValidate>
      <div className="space-y-4">
        <div className="text-sm font-medium text-gray-700">
          Personal Information
        </div>
        <div className="flex flex-col items-center sm:flex-row sm:space-x-6">
          <div className="mx-auto mb-3">
            <FormPhoto
              className="h-48 w-48 rounded-lg"
              imgClassName="object-cover object-center rounded-full"
              name="owner_photo_url"
              onSetFieldValue={setFieldValue}
              value={values?.owner_photo_url}
              label=""
              crop="1:1, free"
              resize="256x"
              widget
              readOnly={readOnly}
            />
            <div className="mx-auto mt-2 w-32">
              <ErrorLabel name="owner_photo_url" />
            </div>
          </div>
          <div className="w-full">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
              <div>
                <FormInput
                  name="owner_first_name"
                  onSetFieldValue={registerOnSetFieldValue(
                    'owner_first_name',
                    setFieldValue
                  )}
                  value={values?.owner_first_name || ''}
                  label="First Name"
                  required
                  readOnly={readOnly}
                />
                <ErrorLabel name="owner_first_name" />
              </div>
              <div>
                <FormInput
                  name="owner_middle_name"
                  onSetFieldValue={registerOnSetFieldValue(
                    'owner_middle_name',
                    setFieldValue
                  )}
                  value={values?.owner_middle_name || ''}
                  label="Middle Name"
                  readOnly={readOnly}
                />
                <ErrorLabel name="owner_middle_name" />
              </div>
              <div>
                <FormInput
                  name="owner_last_name"
                  onSetFieldValue={registerOnSetFieldValue(
                    'owner_last_name',
                    setFieldValue
                  )}
                  value={values?.owner_last_name || ''}
                  label="Last Name"
                  required
                  readOnly={readOnly}
                />
                <ErrorLabel name="owner_last_name" />
              </div>
              <div>
                <FormSelect
                  name="owner_suffix"
                  onSetFieldValue={setFieldValue}
                  value={values?.owner_suffix || ''}
                  options={SUFFIXES.map((suffix) => ({
                    label: suffix,
                    value: suffix,
                  }))}
                  label="Suffix"
                  readOnly={readOnly}
                />
                <ErrorLabel name="owner_Suffix" />
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-4">
                <FormDate
                  name="owner_birth_date"
                  onSetFieldValue={setFieldValue}
                  value={values?.owner_birth_date || ''}
                  error={errors?.owner_birth_date}
                  label="Birth Date"
                  required
                  maxDate={Date.now()}
                  readOnly={readOnly}
                />
                <ErrorLabel name="owner_birth_date" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
          <div className="col-span-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="title-h2">Upload ID</div>
              <button
                type="button"
                className="text-primary-500"
                onClick={handleOpenValidIDModal}
              >
                See all valid ID
              </button>
            </div>
          </div>
          <div className="col-span-5">
            <FormDatasetOptions
              code="VALID_ID"
              name="owner_valid_id_type"
              onSetFieldValue={setFieldValue}
              value={values.owner_valid_id_type}
              error={errors?.owner_valid_id_type}
              label="ID Type"
              placeholder="- Select ID Type -"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="owner_valid_id_type" />
          </div>
          <div className="col-span-5">
            <div>
              <h1 className="pb-4 font-medium text-gray-500">
                Guidelines for uploading ID
              </h1>
              <div className="space-y-2 pb-4">
                <div className="flex space-x-4">
                  <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
                  <p className="font-light text-gray-500">
                    Do not submit expired ID.
                  </p>
                </div>
                <div className="flex space-x-4">
                  <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
                  <p className="font-light text-gray-500">
                    Make sure all information is legible. Do not censor, black
                    out or redact any data.
                  </p>
                </div>
                <div className="flex space-x-4">
                  <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
                  <p className="font-light text-gray-500">
                    Do not crop the ID. All corners of the ID should be visible
                    against the backdrop.
                  </p>
                </div>
                <div className="flex space-x-4">
                  <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
                  <p className="font-light text-gray-500">
                    Upload one (1) Primary ID or three (3) Secondary ID (only if
                    you cannot provide a primary ID).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5 min-h-[18.75rem] bg-gray-50 px-5 pb-7 pt-5">
            <AttachmentPreview
              label="Owner ID"
              name="owner_valid_id_url"
              onChange={(cb) => {
                setFieldValue('owner_valid_id_url', cb()?.owner_valid_id_url);
              }}
              values={validIDs}
              readOnly={readOnly}
            />
            <ErrorLabel name="owner_valid_id_url" />
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0">
        <div className="sm:hidden">
          <TestModeCard />
        </div>
        {!readOnly ? (
          <div className="flex w-full flex-col items-center justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={onNext}
              >
                <span className="whitespace-nowrap text-gray-600">
                  Skip, I&apos;ll do this later
                </span>
              </Button>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              disabled={isSubmitting}
              onClick={() => setValidateOnchange(true)}
              className="order-2 w-full whitespace-nowrap sm:order-1 sm:w-auto"
            >
              Save and Continue
            </Button>
          </div>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </Form>
  );
}

ProfilePage.defaultProps = {
  readOnly: false,
};

ProfilePage.propTypes = {
  onNext: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default ProfilePage;
