import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import shortid from 'shortid';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { acceptValidAmountOnly, rejectsSpecialCharacters } from './regex';
import twConfig from 'tailwindcss/defaultConfig';

export const useDelayedApiLoading = (constant, type, DELAY = 400) => {
  const isLoading = useApiLoading(constant, type);
  const [loading, setLoading] = useState(isLoading);
  const timeout = useRef();
  useEffect(() => {
    clearTimeout(timeout.current);
    if (isLoading) {
      timeout.current = setTimeout(() => {
        setLoading(true);
      }, DELAY);
      return () => {
        clearTimeout(timeout.current);
      };
    }
    setLoading(false);
    return () => {};
  }, [isLoading, setLoading, DELAY]);
  return loading;
};

export const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const elementToObserve = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        entries.forEach((entry) => setDimensions(entry.contentRect));
      });
    });
    if (elementToObserve) {
      resizeObserver.observe(elementToObserve);
    }
    return () => {
      if (elementToObserve) {
        resizeObserver.unobserve(elementToObserve);
      }
    };
  }, [ref]);

  return dimensions;
};

export const useScrollUpDownCallback = (
  options = {
    id: '',
    onScrollUp: () => {},
    onScrollDown: () => {},
    listenOnScrollUp: true,
    listenOnScrollDown: true,
    callbackPixelInterval: 20,
  }
) => {
  const scrollYPosRef = useRef(0);

  const id = useMemo(() => options.id ?? shortid.generate(), [options.id]);
  const onScroll = useCallback(
    (e) => {
      const { scrollTop = 0, scrollHeight = 0, clientHeight = 0 } = e?.target;
      const scrollableHeight = scrollHeight - clientHeight;
      if (e.target?.id !== id) return null; // this ensures that the event target is not coming from event bubbling.
      if (
        scrollTop < scrollYPosRef.current &&
        (options?.listenOnScrollUp ?? true)
      ) {
        if (typeof options?.onScrollUp === 'function') options.onScrollUp();
      }
      if (
        scrollTop > 0 &&
        scrollTop > scrollYPosRef.current &&
        (options?.listenOnScrollDown ?? true)
      ) {
        if (typeof options?.onScrollDown === 'function') options.onScrollDown();
      }
      if (scrollTop > scrollableHeight) {
        scrollYPosRef.current = scrollableHeight;
        if (typeof options?.onScrollDown === 'function') options.onScrollDown();
      }
      if (
        scrollTop - scrollYPosRef.current >
          (options?.callbackPixelInterval ?? 20) ||
        scrollYPosRef.current - scrollTop >
          (options?.callbackPixelInterval ?? 20)
      ) {
        scrollYPosRef.current = scrollTop;
      }
      return null;
    },
    [options, id]
  );

  const register = useMemo(
    () => ({
      id,
      onScroll,
      // add more features/listeners here
    }),
    [onScroll, id]
  );

  return { register };
};

export const useBottomBarIsOpenState = () => {
  const isOpen = useApiGet('BOTTTOM_BAR_STATE', true);
  const setIsOpen = useCallback(
    (newState) => req.set('BOTTTOM_BAR_STATE', newState),
    []
  );
  return [isOpen, setIsOpen];
};

export const useOnWindowFocusedEffect = (callback = () => {}) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const cb = callbackRef.current;
    window.addEventListener('focus', cb);
    return () => window.removeEventListener('focus', cb);
  }, []);
};

export const useMakeInputOnlyAcceptRegexPassed = (regex) => {
  const handleKeyPress = useCallback(
    (e) => {
      if (regex.test(e.key)) return;
      e.preventDefault();
    },
    [regex]
  );

  const handlePaste = useCallback(
    (e) => {
      const pastedValue = e?.clipboardData?.getData('Text');
      if (regex.test(pastedValue)) return;
      e.preventDefault();
    },
    [regex]
  );

  const registerOnChange = useCallback(
    (name, onChange) => (cb) => {
      const value = cb()?.[name];
      if (!regex.test(value)) return;
      onChange((prev) => ({ ...prev, [name]: value }));
    },
    [regex]
  );

  const registerOnSetFieldValue = useCallback(
    (name, onSetFieldValue) => (_, value) => {
      if (!regex.test(value)) return;
      onSetFieldValue(name, value);
    },
    [regex]
  );

  const registerOnValueChange = useCallback(
    (value, callbackFunc) => {
      if (!regex.test(value)) return;
      callbackFunc(value);
    },
    [regex]
  );

  const register = useMemo(
    () => ({
      onKeyPress: handleKeyPress,
      onPaste: handlePaste,
    }),
    [handleKeyPress, handlePaste]
  );

  return {
    register,
    registerOnChange,
    registerOnValueChange,
    registerOnSetFieldValue,
  };
};

export const useMakeInputAcceptValidAmountOnly = () =>
  useMakeInputOnlyAcceptRegexPassed(acceptValidAmountOnly);

export const useMakeInputNotAcceptSpecialCharacters = () =>
  useMakeInputOnlyAcceptRegexPassed(rejectsSpecialCharacters);

export const useOnIdleEffect = (callback, idleTimeInSeconds = 60) => {
  const timeoutRef = useRef();
  const callbackRef = useRef(callback);
  const callbackTimeInMs = useMemo(() => {
    return idleTimeInSeconds * 1000;
  }, [idleTimeInSeconds]);

  const startTimer = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (typeof callbackRef.current !== 'function') return;
      callbackRef.current();
    }, callbackTimeInMs);
  }, [callbackTimeInMs]);

  useEffect(() => {
    startTimer();
    window.addEventListener('mousemove', startTimer);
    window.addEventListener('keydown', startTimer);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('mousemove', startTimer);
      window.removeEventListener('keydown', startTimer);
    };
  }, [startTimer]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
};

export const useScrollPositionListener = (
  options = {
    id: '',
    listenScrollPercentage: 100,
    onHitScrollPercentage: () => {},
    listen: true,
  }
) => {
  const prevScrollPercentRef = useRef(null);
  const id = useMemo(() => options.id ?? shortid.generate(), [options.id]);

  const registerListener = useMemo(() => {
    return {
      id,
      onScroll: (e) => {
        if (e.target?.id !== id) return; // this ensures that the event target is not coming from event bubbling.
        if (!(options?.listen ?? true)) return;
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollPercentage = Math.ceil(
          (scrollTop / (scrollHeight - clientHeight)) * 100
        );
        if (prevScrollPercentRef.current >= options.listenScrollPercentage) {
          return;
        }
        if (scrollPercentage < options.listenScrollPercentage) {
          return;
        }
        prevScrollPercentRef.current = scrollPercentage;
        options.onHitScrollPercentage();
      },
    };
  }, [options, id]);

  const resetListener = useCallback(() => {
    prevScrollPercentRef.current = null;
  }, []);

  return { registerListener, resetListener };
};

const screens = twConfig?.theme?.screens ?? {};

export const useTailwindBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState(
    Object.keys(screens).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {})
  );

  const handleOnChange = useCallback(
    (breakpoint) => (e) => {
      setBreakpoints((prev) => ({
        ...prev,
        [breakpoint]: e.matches,
      }));
    },
    [setBreakpoints]
  );

  useEffect(() => {
    const initialOnMountValues = {};
    const breakpointsMatchMedia = Object.keys(screens).reduce(
      (acc, breakpoint) => {
        const matchMedia = window.matchMedia(
          `(min-width: ${screens[breakpoint]})`
        );
        initialOnMountValues[breakpoint] = matchMedia.matches;
        acc[breakpoint] = matchMedia;
        return acc;
      },
      {}
    );
    setBreakpoints(initialOnMountValues);

    Object.keys(screens).forEach((breakpoint) => {
      breakpointsMatchMedia[breakpoint].addEventListener(
        'change',
        handleOnChange(breakpoint)
      );
    });

    return () => {
      Object.keys(screens).forEach((breakpoint) => {
        breakpointsMatchMedia[breakpoint].removeEventListener(
          'change',
          handleOnChange(breakpoint)
        );
      });
    };
  }, [handleOnChange, setBreakpoints]);

  return breakpoints;
};
