import { useCallback, useEffect, useState } from 'react';
import { useApiGet, useApiLoading } from 'react-reqq-lite';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import * as actions from './actions';
import * as c from './constants';

export const useFormGeneralInfo = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    if (!data?.id) return;
    actions.promiseGetGeneralInfo(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [fetch];
};

export const useGeneralInfo = () => {
  const isLoading = useApiLoading(c.GENERAL_INFO, 'get');
  const data = useApiGet(c.GENERAL_INFO, {});
  return [isLoading, data];
};

export const useSaveGeneralInfo = () => {
  const isLoading = useApiLoading(c.GENERAL_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveGeneralInfo(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveBankDetails = () => {
  const isLoading = useApiLoading(c.BANK_DETAILS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveMerchantBank(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveBusinessSole = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessSole(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveBusinessIndividual = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessIndividual(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveBusinessPartner = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessPartner(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormAttachments = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    if (!data?.id) return;
    actions.promiseGetAttachments(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [fetch];
};

export const useAttachments = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'get');
  const data = useApiGet(c.ATTACHMENTS, {});
  return [isLoading, data];
};

export const useSaveAttachments = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveAttachments(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveRequirements = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveRequirements(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSubmitApplication = () => {
  const isLoading = useApiLoading(c.APPLICATION, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.submitApplication(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useDownloadSwornDeclarationTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const download = useCallback((onSuccess) => {
    setIsLoading(true);
    actions.downloadSwornDeclarationTemplate({
      onSuccess: () => {
        setIsLoading(false);
        if (typeof onSuccess === 'function') onSuccess();
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  }, []);
  return [isLoading, download];
};
