import React, { createContext, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ModalCard, useCreateModal } from 'partial/components/Modal';

import { useActiveAccount, useSwitchProfile } from '../hooks';

const EnvironmentContext = createContext();

export const EnvironmentWrapper = ({ children }) => {
  const toggleEnvironmentEffectsRef = useRef([]);
  return (
    <EnvironmentContext.Provider value={{ toggleEnvironmentEffectsRef }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

EnvironmentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);
  if (!context) throw new Error('Please use this inside the provider!');
  return context;
};

const MAP_CHANGE_TO = {
  TEST: 'LIVE',
  LIVE: 'TEST',
};

const SwitchAccount = ({ onClose }) => {
  const [, submit] = useSwitchProfile();
  const [account] = useActiveAccount();
  const [isSuccess, setIsSuccess] = React.useState(false);
  React.useEffect(() => {
    const payload = {
      environment_type: MAP_CHANGE_TO[account.env_mode],
    };
    submit(payload, () => setIsSuccess(true), onClose);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="rounded-2xl p-5 text-center shadow-primary-2xl">
      <div className="mb-3 text-gray-600">
        {!isSuccess ? 'Switching account...' : 'Please wait...'}
      </div>
      <div className="loaderBar">
        <div className="loaderBarInner" />
      </div>
    </div>
  );
};

SwitchAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export const useToggleLiveTestEnvironment = () => {
  const createModal = useCreateModal('SWITCH_PROFILE_MODAL');
  const { toggleEnvironmentEffectsRef } = useEnvironmentContext();

  const toggleEnvironment = React.useCallback(() => {
    toggleEnvironmentEffectsRef.current?.forEach((effect) => effect?.value?.());
    createModal({
      content: (onClose) => (
        <ModalCard size="sm">
          <SwitchAccount onClose={onClose} />
        </ModalCard>
      ),
    });
  }, [createModal, toggleEnvironmentEffectsRef]);
  return {
    toggleEnvironment,
  };
};

export const useOnToggleEnvironmentEffect = (callback = () => {}) => {
  const { toggleEnvironmentEffectsRef } = useEnvironmentContext();

  const callbackRef = useRef({ value: callback });
  useEffect(() => {
    callbackRef.current.value = callback;
  }, [callback]);

  useEffect(() => {
    toggleEnvironmentEffectsRef.current?.push(callbackRef.current);
    return () => {
      toggleEnvironmentEffectsRef.current =
        toggleEnvironmentEffectsRef.current?.filter(
          // eslint-disable-next-line
          (effect) => effect !== callbackRef.current
        );
    };
  }, [toggleEnvironmentEffectsRef]);
};
