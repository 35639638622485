import { useCallback, useEffect, useState } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { useDelayedApiLoading } from 'partial/hooks';
import * as actions from './actions';
import * as c from './constants';

export const useSettlementList = (params) => {
  const isLoading = useApiLoading(c.SETTLEMENT_LIST, 'get');
  const list = useApiGet(c.SETTLEMENT_LIST, []);
  const pager = useApiGet(c.SETTLEMENT_PAGER, {});
  useEffect(() => {
    actions.listSettlement(params);
  }, [params]);
  const appendList = useCallback(
    (page) => {
      actions.appendListSettlement({ ...params, page });
    },
    [params]
  );
  return [isLoading, list, pager, appendList];
};

export const useDownloadSettlement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const download = useCallback((id, filename) => {
    setIsLoading(true);
    actions.downloadSettlement(
      id,
      filename,
      () => {
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);
  return [isLoading, download];
};

export const useSettlementLogs = (id, params) => {
  const isLoading = useApiLoading(c.SETTLEMENT_LOGS_LIST, 'get');
  const list = useApiGet(c.SETTLEMENT_LOGS_LIST, []);
  const pager = useApiGet(c.SETTLEMENT_LOGS_PAGER, {});
  useEffect(() => {
    actions.listSettlementLogs(id, params);
  }, [id, params]);
  useEffect(() => {
    return () => {
      req.set(c.SETTLEMENT_LOGS_LIST, null);
      req.set(c.SETTLEMENT_LOGS_PAGER, null);
      req.set(c.SETTLEMENT_LOGS_FILTER, null);
    };
  }, []);
  return [isLoading, list, pager];
};

export const useCountCard = (name, params) => {
  const isLoading = useDelayedApiLoading(
    `${c.SETTLEMENT_COUNT_CARD}/${name}`,
    'get'
  );
  const { value } = useApiGet(`${c.SETTLEMENT_COUNT_CARD}/${name}`, {});
  useEffect(() => {
    actions.getCountCard(params);
  }, [name, params]);
  return [isLoading, value];
};

export const useLedgerList = (filter) => {
  const isLoading = useApiLoading(c.LEDGER_LIST, 'get');
  const list = useApiGet(c.LEDGER_LIST, []);
  const pager = useApiGet(c.LEDGER_PAGER, {});
  useEffect(() => {
    actions.listLedger(filter);
  }, [filter]);
  const appendList = useCallback(
    (page) => {
      actions.appendListLedger({ ...filter, page });
    },
    [filter]
  );
  return [isLoading, list, pager, appendList];
};

export const useRefundList = (filter) => {
  const isLoading = useApiLoading(c.REFUND_LIST, 'get');
  const list = useApiGet(c.REFUND_LIST, []);
  const pager = useApiGet(c.REFUND_PAGER, {});

  const fetchData = useCallback(() => actions?.listRefund(filter), [filter]);
  const appendList = useCallback(
    (page) => {
      actions.appendListRefund({ ...filter, page });
    },
    [filter]
  );

  useEffect(fetchData, [fetchData]);

  return [isLoading, list, pager, fetchData, appendList];
};

export const useCreateRefundRequest = (onSuccess = () => {}) => {
  const isLoading = useApiLoading(c.REFUND_REQUEST, 'post');

  // eslint-disable-next-line
  const createRefundRequest = useCallback(
    actions.createRefundRequest(onSuccess),
    []
  );

  return [isLoading, createRefundRequest];
};
