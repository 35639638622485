import React from 'react';
import PropTypes from 'prop-types';
import { LuClipboard } from 'react-icons/lu';
import { toastSuccess } from 'react-geek-toast';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Button from 'partial/components/Button';

// const wsBaseUrl = process.env.REACT_APP_END_POINT;
// const wsBaseUrlPgi = `https://pgi-${wsBaseUrl
//   .replace('https://', '')
//   .replace('.multipay.com.ph', '')
//   .split('-')
//   .reverse()
//   .join('-')}.multipay.com.ph`;

const formatCode = (str, value) => {
  let n = `${str}`;
  const pattern = new RegExp(`{{API_KEY}}`, 'g');
  n = n.replace(pattern, value?.token || '-API-TOKEN-HERE-');
  const pattern2 = new RegExp(`{{HOST}}`, 'g');
  n = n.replace(
    pattern2,
    value?.host ||
      (process.env.REACT_APP_END_POINT ?? '').replace('https://', '')
  );
  return n;
};

function CodeSnippet({ data, value }) {
  const [index, setIndex] = React.useState(0);
  const codeString = formatCode(data?.[index]?.code_string, value);

  const copyStarterCode = () => {
    navigator.clipboard.writeText(codeString);
    toastSuccess('Copied to clipboard!');
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-col text-xs">
        <div className="btn-group">
          {data.map((item, i) => (
            <Button
              primary={i === index}
              size="sm"
              key={item.label}
              onClick={() => setIndex(i)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      </div>
      <div className="c-sharp relative overflow-hidden rounded-lg bg-gray-800 p-4">
        <button
          type="button"
          className="absolute right-0 top-0 m-2 rounded p-2 text-white hover:bg-white/30"
          onClick={copyStarterCode}
        >
          <LuClipboard className="h-4 w-4" />
        </button>
        {!codeString ? (
          <div>n/a</div>
        ) : (
          <SyntaxHighlighter language="javascript" style={atomOneDark}>
            {codeString}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
}

CodeSnippet.defaultProps = {
  value: null,
};

CodeSnippet.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object),
};

export default CodeSnippet;
