/* eslint-disable */
import React, { useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import { useParams } from 'react-router-dom';
import {
  BsCircleFill,
  BsFillCheckCircleFill,
  BsRecordCircleFill,
} from 'react-icons/bs';

import InReviewCard from 'core-modules/onboarding/components/InReviewCard';
import RejectedCard from 'core-modules/onboarding/components/RejectedCard';

import * as c from './constants';
import { useGeneralInfo } from './hooks';
import DueDiligencePage from './containers/DueDiligencePage';
// import BankDetailsPage from './containers/BankDetailsPage';
import MerchantGeneralPage from './containers/MerchantGeneralPage';
import ReviewApplicationPage from './containers/ReviewApplicationPage';

function KycContainer({ merchant }) {
  const { merchant_id: merchantId } = useParams();
  const [rawStep, setStep] = React.useState(0);
  const [, generalInfo] = useGeneralInfo();
  const isReadOnly = useMemo(() => {
    if (isEmpty(merchant)) return false;
    return (
      merchant?.approval_status !== 'PENDING' &&
      merchant?.approval_status !== 'REJECTED'
    );
  }, [merchant]);

  React.useEffect(() => {
    req.set(c.GENERAL_INFO, null);
    req.set(c.BUSINESS_INFO, null);
    req.set(c.ATTACHMENTS, null);
  }, [merchantId]);
  const step = React.useMemo(() => {
    if (merchant.approval_status === 'IN_REVIEW') return 2;
    return rawStep;
  }, [merchant, rawStep]);
  const renderStep = React.useMemo(
    () =>
      ({
        0: (
          <MerchantGeneralPage
            readOnly={isReadOnly}
            id={merchantId}
            onNext={() => {
              setStep(1);
            }}
          />
        ),
        1: (
          <DueDiligencePage
            readOnly={isReadOnly}
            id={merchantId}
            onNext={() => {
              setStep(2);
            }}
          />
        ),
        2: <ReviewApplicationPage readOnly={isReadOnly} id={merchantId} />,
      }[step]),
    [step, setStep, isReadOnly, merchantId]
  );

  const NAV_LIST = [
    {
      label: 'General Information',
      is_submitted: !isEmpty(generalInfo?.business_address),
      step: 0,
    },
    {
      label: 'Due Diligence',
      is_submitted: !isEmpty(generalInfo?.business_type),
      step: 1,
    },
    {
      label: 'Review and Submit',
      is_submitted: isReadOnly,
      step: 2,
    },
  ];

  return (
    <div className="flex h-full flex-col">
      <div className="hide-scroll flex h-0 flex-grow flex-col space-y-6 overflow-auto sm:flex-row sm:space-x-6">
        {['PENDING', 'REJECTED'].indexOf(merchant?.approval_status) > -1 ? (
          <div className="sticky top-0 z-10 mb-2 w-full bg-white sm:mb-0 sm:w-72 sm:border-r">
            <div className="stepper">
              {NAV_LIST.map((item, i) => (
                <div className="stepper-layout">
                  {i !== NAV_LIST.length - 1 ? (
                    <span className="stepper-line" aria-hidden="true" />
                  ) : null}
                  <div key={item.label} className="stepper-content">
                    {item.is_submitted ? (
                      <BsFillCheckCircleFill
                        aria-hidden="true"
                        className="h-6 w-6 text-primary-500"
                      />
                    ) : (
                      <>
                        {item.step === step ? (
                          <BsRecordCircleFill
                            aria-hidden="true"
                            className="h-6 w-6 text-primary-500"
                          />
                        ) : (
                          <BsCircleFill
                            aria-hidden="true"
                            className="h-6 w-6 text-gray-100"
                          />
                        )}
                      </>
                    )}
                    <button
                      type="button"
                      className={cn(
                        'flex min-w-0 flex-1 rounded-lg py-2 text-xs font-normal sm:text-base',
                        item.is_submitted || item.step === step
                          ? 'text-gray-700'
                          : 'text-gray-300'
                      )}
                      onClick={() => setStep(item.step)}
                    >
                      {item.label}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="flex w-full flex-col">
          <div>
            <div id="kyc-top" />
            {merchant?.approval_status !== 'APPROVED' && (
              <div className="mb-4 space-y-6">
                {merchant?.approval_status === 'REJECTED' && (
                  <RejectedCard merchantData={merchant} />
                )}
                {merchant?.approval_status === 'IN_REVIEW' && <InReviewCard />}
              </div>
            )}
          </div>
          <div data-tour="merchant-kyc-form">{renderStep}</div>
        </div>
      </div>
    </div>
  );
}

KycContainer.propTypes = {
  merchant: PropTypes.instanceOf(Object).isRequired,
};

export default KycContainer;
