import React, { useState } from 'react';
import PropTypes from 'prop-types';

import betaPromotionImage from 'assets/images/banners/beta_promotion.png';

import { ModalCard } from 'partial/components/Modal';
import { FaTimes } from 'react-icons/fa';
import { ColoredLogoBeta } from './AppIconLogo';
import Button from 'partial/components/Button';

const BetaPromotionModal = ({ onClose }) => {
  const [dontShowThisAgain, setDontShowThisAgain] = useState(false);

  return (
    <ModalCard>
      <div className="relative bg-white">
        <button
          type="button"
          className="absolute right-0 top-0 m-4"
          onClick={onClose}
          aria-label="icon"
        >
          <FaTimes />
        </button>
        <img
          alt="beta"
          src={betaPromotionImage}
          className="aspect-[7/4] w-full bg-primary-300 object-cover object-center"
        />
        <div className="px-4 py-8 text-center md:px-12">
          <h2 className="text-xl leading-6 text-primary-500 md:text-2xl">
            Welcome to Multipay Beta!
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            We value your input in shaping our seamless payment gateway. Rest
            assured, your accounts will remain active after the beta period
            ends, ensuring continued access to the enhanced MultiPay. Your
            support is invaluable!
          </p>
          <ColoredLogoBeta className="mx-auto mt-6 w-32" />
          <div className="mt-6 flex items-center justify-between">
            <label className="flex items-center space-x-1 text-gray-600">
              <input
                type="checkbox"
                checked={dontShowThisAgain}
                onChange={(e) => setDontShowThisAgain(e.target.checked)}
              />{' '}
              <span>Don&apos;t show this again</span>
            </label>
            <Button
              secondary
              onClick={() => {
                onClose();
                if (dontShowThisAgain) {
                  localStorage.setItem('always_hide_beta_promotion', '1');
                }
              }}
            >
              Okay, Got it!
            </Button>
          </div>
        </div>
      </div>
    </ModalCard>
  );
};

BetaPromotionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BetaPromotionModal;
