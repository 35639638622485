import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import { HiPlusCircle } from 'react-icons/hi';
// import { MdReceipt } from 'react-icons/md';
// import { Link, Route, Switch } from 'react-router-dom';

// import { useActiveAccount } from 'modules/auth/hooks';
// import {
//   useBottomBarIsOpenState,
//   useScrollUpDownCallback,
// } from 'partial/hooks';
// import { useAccessControl } from 'modules/common/hooks';

// const MAP_BILL_ROUTES = {
//   org_user: '/invoice/create',
//   sub_user: '/bill/create',
// };

function PageWrapper({ className, children }) {
  // const [activeAccount] = useActiveAccount();
  // const [isBottomBarOpen, setIsBottombarOpen] = useBottomBarIsOpenState(true);
  // const { userType, canRead } = useAccessControl();

  // const isVerifiedOrTestMode = useMemo(
  //   () =>
  //     activeAccount?.is_org_kyc_completed || activeAccount?.env_mode === 'TEST',
  //   [activeAccount]
  // );
  // const isOrgUser = useMemo(() => userType === 'org_user', [userType]);
  // const isSubUser = useMemo(() => userType === 'sub_user', [userType]);

  // useScrollUpDownCallback(() => ({
  //   onScrollUp: () => setIsBottombarOpen(true),
  //   onScrollDown: () => setIsBottombarOpen(false),
  // }));

  return (
    <div className="flex flex-1 flex-col pt-6 pb-20 md:pb-6">
      <div
        className={cn(
          'mx-auto flex w-full max-w-7xl flex-1 flex-col px-4 sm:px-6 md:px-8',
          className
        )}
      >
        {children}
      </div>
      {/* {((isVerifiedOrTestMode && isOrgUser) ||
        (isSubUser && canRead('bills'))) && (
        <div
          className={cn(
            'fixed right-8 bottom-20 z-50 transform transition-all sm:bottom-8',
            isBottomBarOpen ? 'translate-y-0' : 'translate-y-[8.125rem]'
          )}
        >
          <Switch>
            <Route
              path={['/invoice/create', '/bill/create']}
              render={() => null}
            />
            <Route
              render={() => (
                <Link
                  to={MAP_BILL_ROUTES[userType] ?? MAP_BILL_ROUTES.sub_user}
                  className="relative block scale-100 transform rounded-full bg-secondary-500 p-5 text-white shadow-2xl shadow-secondary-600 ring-0 ring-secondary-500/40 transition duration-700 ease-in-out hover:scale-125 hover:bg-secondary-600 hover:ring-4"
                >
                  <MdReceipt className="relative -top-px -left-px z-0 h-6 w-6 flex-shrink-0" />
                  <span className="absolute bottom-3 right-3 z-10 rounded-full bg-secondary-500 p-0.5">
                    <HiPlusCircle className="h-4 w-4 flex-shrink-0 text-white" />
                  </span>
                </Link>
              )}
            />
          </Switch>
        </div>
      )} */}
    </div>
  );
}

PageWrapper.defaultProps = {
  className: '',
};

PageWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default PageWrapper;
