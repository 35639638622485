import React, {
  forwardRef,
  useRef,
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Combobox } from '@headlessui/react';
import { GrFormClose } from 'react-icons/gr';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import { useOnClickOutside } from 'helper';

const FormMultipleSelect = forwardRef(
  (
    {
      name,
      label,
      error,
      options,
      onChange,
      disabled,
      required,
      renderPill,
      placeholder,
      renderOption,
      noOptionLabel,
      onInputChange,
      selectedOptions,
      optionFilterFunc,
    },
    ref
  ) => {
    const inputRef = useRef();
    const containerRef = useRef();

    const [showOptions, setShowOptions] = useState(false);
    const [expandContainer, setExpandContainer] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const labelIsOnTop = useMemo(
      () => showOptions || !!selectedOptions?.length,
      [showOptions, selectedOptions]
    );

    const isError = !!error;

    const filteredOptions = useMemo(
      () =>
        options?.filter(
          typeof optionFilterFunc === 'function'
            ? optionFilterFunc
            : (option) => {
                return (
                  (searchValue
                    ? option?.label
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                    : true) &&
                  !selectedOptions?.some(
                    (selectedOption) => selectedOption?.value === option?.value
                  )
                );
              }
        ),
      [options, searchValue, optionFilterFunc, selectedOptions]
    );

    const handleSelectValue = useCallback(
      (selectedOption) => {
        setSearchValue('');
        const newValues = [...selectedOptions, selectedOption];
        return onChange(newValues);
      },
      [selectedOptions, onChange, setSearchValue]
    );

    const handleRemoveSelectedItem = useCallback(
      (selectedOption) => {
        const newValues = selectedOptions?.filter(
          (option) => option?.value !== selectedOption?.value
        );
        return onChange(newValues);
      },
      [selectedOptions, onChange]
    );

    const handleRemoveLastSelectedItem = useCallback(() => {
      const newValues = [...selectedOptions];
      newValues?.pop();
      return onChange(newValues);
    }, [selectedOptions, onChange]);

    useEffect(() => {
      if (typeof onInputChange === 'function') {
        onInputChange(searchValue);
      }
      // eslint-disable-next-line
    }, [searchValue]);

    useOnClickOutside(containerRef, () => {
      setShowOptions(false);
      setExpandContainer(false);
    });

    return (
      <div ref={containerRef} className="relative max-h-[4.55rem]">
        <div
          className={cn(
            'relative min-h-[4.55rem] w-full rounded-md border px-4 pb-1 pt-[2.125rem] focus-within:ring-2',
            isError
              ? '!border-red-300 focus-within:!border-red-500 focus:!ring-red-300'
              : '!border-gray-200 focus-within:!border-primary-600 focus-within:!ring-primary-400',
            disabled ? 'cursor-not-allowed bg-gray-50' : 'bg-white',
            !expandContainer ? 'max-h-[4.55rem]' : ''
          )}
        >
          <Combobox onChange={(option) => handleSelectValue(option)}>
            <button
              type="button"
              aria-label="focus button"
              className="absolute inset-0 h-full w-full"
              onClick={() => inputRef?.current?.focus()}
            />
            <div
              className={cn(
                'hide-scroll mr-8 flex flex-grow overflow-x-auto',
                expandContainer ? 'flex-wrap' : ''
              )}
            >
              {selectedOptions?.map((option) => {
                return (
                  <div
                    key={option?.value}
                    className="relative z-[1] mb-2 mr-2 flex max-h-6 min-w-max items-center space-x-2 rounded-md border px-2 py-1"
                  >
                    {typeof renderPill === 'function' ? (
                      renderPill(option)
                    ) : (
                      <p className="whitespace-nowrap">{option?.label}</p>
                    )}
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveSelectedItem(option);
                      }}
                      aria-label="icon"
                    >
                      <GrFormClose className="hover:bg-red-300" size={15} />
                    </button>
                  </div>
                );
              })}
              <Combobox.Input
                ref={(el) => {
                  inputRef.current = el;
                  if (!ref) return;
                  // eslint-disable-next-line
                  ref.current = el;
                }}
                type="text"
                name={name}
                className={cn(
                  'min-w-max border-0 bg-transparent p-0 text-base font-bold leading-4 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0',
                  !showOptions ? 'placeholder:text-transparent' : ''
                )}
                value={searchValue}
                placeholder={placeholder}
                onChange={(e) => {
                  const newSearchValue = e?.target?.value;
                  setSearchValue(newSearchValue);
                }}
                onKeyDown={(e) => {
                  if (e?.key !== 'Backspace') return;
                  if (e?.target?.value !== '') return;
                  handleRemoveLastSelectedItem();
                }}
                onFocus={() => {
                  setShowOptions(true);
                }}
              />
            </div>
            <div className="absolute bottom-0 right-0 top-0 z-10 flex h-16 flex-col justify-between p-1">
              <button
                type="button"
                className="rounded border bg-white p-1 hover:border-primary-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowOptions(!showOptions);
                }}
              >
                {!showOptions ? (
                  <IoChevronDownOutline
                    className="hover:text-primary-500"
                    size={12}
                  />
                ) : (
                  <IoChevronUpOutline
                    className="hover:text-primary-500"
                    size={12}
                  />
                )}
              </button>
              <button
                type="button"
                className="rounded border bg-white p-1 hover:border-primary-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandContainer(!expandContainer);
                }}
              >
                {!expandContainer ? (
                  <BsArrowsExpand
                    className="hover:text-primary-500"
                    size={12}
                  />
                ) : (
                  <BsArrowsCollapse
                    className="hover:text-primary-500"
                    size={12}
                  />
                )}
              </button>
            </div>
            {showOptions && (
              <Combobox.Options static>
                <div className="absolute left-0 top-full max-h-60 w-full translate-y-2 transform overflow-y-auto rounded-md border bg-white py-2">
                  {!filteredOptions?.length && (
                    <p className="px-3">{noOptionLabel}</p>
                  )}
                  {filteredOptions?.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      value={option}
                      as={Fragment}
                    >
                      {({ active }) => (
                        <div
                          className={cn(
                            'relative isolate px-3 hover:bg-gray-50',
                            active ? 'bg-gray-50' : ''
                          )}
                        >
                          {typeof renderOption === 'function' ? (
                            renderOption(option)
                          ) : (
                            <p className="py-2">{option?.label}</p>
                          )}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </div>
              </Combobox.Options>
            )}
          </Combobox>
          <label
            className={cn(
              'no-highlight pointer-events-none absolute left-4 -translate-y-1/2 transition-all',
              labelIsOnTop
                ? 'top-[1.625rem] text-xs text-gray-600'
                : 'top-1/2 bg-transparent text-sm text-gray-400'
            )}
          >
            <span>{label}</span>
            {required && (
              <span className="ml-1 font-light text-red-500">*</span>
            )}
          </label>
        </div>
      </div>
    );
  }
);

FormMultipleSelect.defaultProps = {
  disabled: false,
  required: false,
  renderOption: null,
  renderPill: null,
  optionFilterFunc: null,
  options: [],
  placeholder: '',
  selectedOptions: [],
  onInputChange: null,
  noOptionLabel: 'No Options...',
  error: null,
};

FormMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noOptionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  optionFilterFunc: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  renderOption: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  renderPill: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  error: PropTypes.instanceOf(Object),
};

export default FormMultipleSelect;
