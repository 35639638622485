import React from 'react';

import Splash from 'partial/containers/Splash';
import { useDestroyModal } from 'partial/components/Modal';

import * as actions from '../actions';

function Logout() {
  const destroyModal = useDestroyModal();

  React.useEffect(() => {
    actions.logout();
    destroyModal();
  }, [destroyModal]);
  return <Splash label="Logging out...please wait" />;
}

Logout.propTypes = {};

export default Logout;
