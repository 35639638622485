import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MdReviews } from 'react-icons/md';

import {
  useTransactionHistogram,
  useAmountHistogram,
  useTopChannelScheme,
} from 'core-modules/dashboard/hooks';
import HistogramCard, {
  useHistogramCard,
} from 'partial/dashboard/HistogramCard';
import Card from 'partial/components/Card';
import DashboardSchemeCard, {
  useSchemeCard,
} from 'partial/dashboard/DashboardSchemeCard';
import { HiExclamation } from 'react-icons/hi';
import ThumbIcon from 'partial/components/ThumbIcon';
import { useActiveAccount } from 'modules/auth/hooks';

import { useMerchantSelected } from '../hooks';
import RecentTransaction from '../components/RecentTransaction';
import MerchantOverview from '../components/MerchantOverview';

const txnHistogramOptions = [
  {
    id: 'transaction',
    label: 'Sales Summary',
    hook: useTransactionHistogram,
    defaultChart: 'line',
  },
];

const amtHistogramOptions = [
  {
    id: 'amount',
    label: 'Payment Amount',
    hook: useAmountHistogram,
    defaultChart: 'bar',
  },
];

const schemeOptionsA = [
  {
    id: 'top_merchant',
    label: 'Top Channels',
    hook: useTopChannelScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Label',
        // eslint-disable-next-line
        key: ({ icon, label }) => (
          <span className="flex items-center space-x-1">
            <ThumbIcon
              className="h-7 w-7 inline-block rounded-full"
              url={icon}
              alt={label}
            />
            <span>{label}</span>
          </span>
        ),
        id: 'label',
        className: 'font-bold',
      },
      {
        label: 'Txn',
        key: 'transaction',
        id: 'transaction',
        sortKey: 'transaction',
      },
      { label: 'Amount', key: 'amount', id: 'amount', sortKey: 'amount' },
    ],
    defaultChart: 'pie',
    defaultSort: 'transaction:asc',
  },
];

function MerchantDashboard() {
  const transactionHistogram = useHistogramCard(txnHistogramOptions);
  const amtHistogram = useHistogramCard(amtHistogramOptions);
  const schemePropsA = useSchemeCard(schemeOptionsA);

  const [activeAccount] = useActiveAccount();
  const [, merchant] = useMerchantSelected();

  const filter = React.useMemo(
    () => ({
      payment_status: 'PAID',
      merchant_uuid: merchant?.id,
    }),
    [merchant]
  );

  return (
    <div className="space-y-4">
      {activeAccount?.env_mode === 'LIVE' ? (
        <>
          {merchant.approval_status === 'PENDING' ? (
            <>
              <Card className="p-5 bg-blue-100 border-none">
                <HiExclamation className="h-6 w-6 inline-block flex-shrink-0 text-blue-500" />
                <Link
                  className="hover:underline"
                  to="/merchants/profile/details"
                >
                  Complete details and get verified.
                </Link>
              </Card>
              {/* <Card className="p-5">
                <span className="badge badge-gray">For Developers</span>
                <div className="grid grid-cols-2 border-t">
                  <div>
                    <div className="flex items-center justify-between space-x-2">
                      <div className="text-gray-900 text-base font-semibold mt-3 mb-1">
                        API Keys
                      </div>
                      <div className="flex items-center space-x-1 text-gray-500">
                        <HiInformationCircle className="h-4 w-4" />
                        <span>Test Data</span>
                      </div>
                    </div>
                    <a
                      href="/"
                      className="btn-link inline-flex items-center space-x-2"
                    >
                      <span>Start</span>
                      <HiArrowRight className="w-4 h-4" />
                    </a>
                  </div>
                  <div className="space-y-4 mt-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                      <div className="text-sm font-medium text-gray-500">
                        Publishable key
                      </div>
                      <div className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex items-center space-x-2 justify-end">
                          <span className="">c3af***************</span>
                          <a
                            href="/"
                            className="hover:bg-gray-200 transition p-1 rounded-full text-primary-400"
                          >
                            <HiClipboardCopy className="w-4 h-4 flex-shrink-0" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                      <div className="text-sm font-medium text-gray-500">
                        Secret key
                      </div>
                      <div className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex items-center space-x-2 justify-end">
                          <span className="truncate">
                            sk_test_51JrFvrCGsk_test_51JrFvrCGsk_test_51JrFvrCGsk_test_51JrFvrCGsk_test_51JrFvrCG
                          </span>
                          <a
                            href="/"
                            className="hover:bg-gray-200 transition p-1 rounded-full text-primary-400"
                          >
                            <HiEyeOff className="w-4 h-4 flex-shrink-0" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      href="/"
                      className="btn-link inline-flex items-center space-x-2"
                    >
                      <span>View Docs</span>
                      <HiArrowRight className="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </Card> */}
            </>
          ) : null}
          {merchant.approval_status === 'IN_REVIEW' ? (
            <Card className="rounded-lg border shadow-primary-sm p-5 bg-yellow-100 border-none flex items-center space-x-2">
              <MdReviews className="w-6 h-6 flex-shrink-0 text-yellow-500" />
              <p>
                The submitted details are being reviewed. You will be notified
                within <b>1-5 business days</b>.
              </p>
            </Card>
          ) : null}
        </>
      ) : null}
      <MerchantOverview key={`overview-${merchant?.id}`} filter={filter} />
      {/* Counts Here */}
      <div
        key={`recent-${merchant?.id}`}
        className="grid grid-cols-1 xl:grid-cols-1 gap-5"
      >
        <div className="grid-cols-1">
          <HistogramCard {...transactionHistogram} filter={filter} />
        </div>
        <div className="grid-cols-1">
          <RecentTransaction filter={filter} />
        </div>
      </div>

      <div
        key={`chart-${merchant?.id}`}
        className="grid grid-cols-1 xl:grid-cols-3 gap-5"
      >
        <div className="col-span-1 xl:col-span-2">
          <HistogramCard {...amtHistogram} filter={filter} />
        </div>
        <div>
          <DashboardSchemeCard {...schemePropsA} filter={filter} />
        </div>
      </div>
    </div>
  );
}

MerchantDashboard.propTypes = {};

export default MerchantDashboard;
