export const transformGeneralInfo = (raw) => ({
  logo: raw?.logo || '',
  name: raw?.name || '',
  industry: raw?.industry || '',
  customer_service_number: raw?.customer_service_number || '',
  business_website: raw?.business_website || '',
  business_email_address: raw?.business_email_address || '',
  business_country: raw?.business_country || 'PH',
  business_state: raw?.business_state || '',
  business_city: raw?.business_city || '',
  business_zip_code: raw?.business_zip_code || '',
  business_address: raw?.business_address || '',
  business_geoloc: raw?.business_geoloc || '',
  business_description: raw?.business_description || '',
  business_number_of_customers: raw?.business_number_of_customers || '',
  business_monthly_sales: raw?.business_monthly_sales || '',
  business_type: raw?.business_type || '',
  contact_first_name: raw?.contact_first_name || '',
  contact_last_name: raw?.contact_last_name || '',
  contact_designation: raw?.contact_designation || '',
  contact_mobile_number: raw?.contact_mobile_number || '',
  contact_email_address: raw?.contact_email_address || '',
  requirements: raw?.requirements || [],
});

const parseAttachment = (v) => (v === '-' || !v ? '' : v);

export const transformAttachments = (raw) => ({
  dti_registration: parseAttachment(raw?.dti_registration || ''),
  sec_certificate: parseAttachment(raw?.sec_certificate || ''),
  by_laws: parseAttachment(raw?.by_laws || ''),
  articles_of_incorporation: parseAttachment(
    raw?.articles_of_incorporation || ''
  ),
  mayors_permit: parseAttachment(raw?.mayors_permit || ''),
  bir: parseAttachment(raw?.bir || ''),
  secretarys_certificate: parseAttachment(raw?.secretarys_certificate || ''),
  audit_tax_return: parseAttachment(raw?.audit_tax_return || ''),
  gis: parseAttachment(raw?.gis || ''),
});

export const payloadBusinessCorporation = (payload) => ({
  ...payload,
  is_beneficial_owner: payload?.is_beneficial_owner ? 1 : 0,
});
