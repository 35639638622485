import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const BG = (backgroundColor) => ({
  default: (
    <div className="absolute inset-0 z-0 h-full w-full bg-gradient-to-bl from-theme-preview-brand-500 via-theme-preview-brand-300 to-theme-preview-brand-100 bg-cover bg-center" />
  ),
  bg2: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/9d254fa3-868a-4bc0-8ddf-fbc136318740/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg3: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/45e6540c-96ab-4ac2-92c4-d2de50580cab//-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg4: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/71065eb7-d518-4411-b824-43a7611d101f/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg5: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/9a8c1be3-e36e-41a9-b90b-4548343a015a/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg6: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/5b3511e0-717d-4e06-9196-c0e184237f1d/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg7: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/dfb574e8-c2df-43d8-8b98-20840c095618/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
  bg8: (
    <>
      <div
        className="absolute inset-0 z-0 h-full w-full bg-cover bg-center"
        style={{
          backgroundImage:
            'url(https://ucarecdn.com/3caf20f8-7e62-466f-be50-7c552fc4201c/-/resize/1024x/)',
        }}
      />
      <div
        className="absolute inset-0 block h-full w-full mix-blend-color"
        style={{ backgroundColor }}
      />
    </>
  ),
});

function Background({ children, className, code, color }) {
  return (
    <div className={cn('relative', className)}>
      <div className="relative z-10">{children}</div>
      {BG(color)[code] ? BG(color)[code] : null}
    </div>
  );
}

Background.defaultProps = {
  className: '',
  code: null,
  color: null,
};

Background.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default Background;
