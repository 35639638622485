import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import BreadCrumb from 'partial/components/BreadCrumb';

import InvoiceTable from '../components/InvoiceTable';
import CreateInvoice from '../components/CreateInvoice';
import SelectedInvoice from '../components/SelectedInvoice';

function InvoiceContainer({ filter }) {
  const match = useRouteMatch();
  const history = useHistory();
  const handleBack = () => {
    history.push(match.url);
  };
  return (
    <>
      <BreadCrumb>Invoice</BreadCrumb>
      <Switch>
        <Route exact path={`${match.path}`}>
          <InvoiceTable filter={filter} />
        </Route>
        <Route path={`${match.path}/create`}>
          <CreateInvoice onGoBack={handleBack} />
        </Route>
        <Route path={`${match.path}/:id`}>
          <SelectedInvoice
            onGoBack={!filter?.merchant_uuid ? handleBack : undefined}
          />
        </Route>
      </Switch>
    </>
  );
}

InvoiceContainer.defaultProps = {
  filter: {},
};

InvoiceContainer.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default InvoiceContainer;
