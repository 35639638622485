import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'modules/auth/containers/Login';
import SignUp from 'modules/auth/containers/SignUp';
import PageNotFound from 'partial/containers/PageNotFound';
import SetPassword from 'modules/auth/containers/SetPassword';
import ForgotPassword from 'modules/auth/containers/ForgotPassword';
import PrivacyContainer from 'modules/legal/containers/PrivacyContainer';
import TermsContainer from 'modules/legal/containers/TermsContainer';

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/password_reset" component={ForgotPassword} />
      <Route
        path={['/forgot_password', '/set_password', '/update_password']}
        component={SetPassword}
      />
      <Route path="/privacy-policy" component={PrivacyContainer} />
      <Route path="/terms-and-conditions" component={TermsContainer} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
