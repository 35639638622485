/* eslint-disable */
import React, { useRef, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import Splash from 'partial/containers/Splash';
import { setError422 } from 'partial/form/Form';
import AttachmentsForm from 'core-modules/onboarding/forms/AttachmentsForm';

import {
  useGeneralInfo,
  useSaveGeneralInfo,
  useSaveRequirements,
} from '../hooks';
import { promiseGetGeneralInfo } from '../actions';

function DueDiligencePage({ id, onNext, readOnly }) {
  const formRef = useRef();
  const [isLoading, submitGeneralInfo] = useSaveGeneralInfo();
  const [isSubmittingRequirements, submitRequirements] = useSaveRequirements();

  useEffect(() => {
    promiseGetGeneralInfo(id);
  }, [id]);
  const [isLoadingInfo, data] = useGeneralInfo();

  const handleSubmit = useCallback(
    async (form, onSuccess, onError) => {
      try {
        await new Promise((res, rej) => {
          submitGeneralInfo(
            { business_type: form.business_type || '' },
            res,
            (err) => {
              rej(err);
              setError422(err, setFieldError);
            }
          );
        });
        const saveBusinessAttachments = await new Promise((res, rej) => {
          submitRequirements(form, res, (err) => {
            rej(err);
            setError422(err, setFieldError);
          });
        });
        onSuccess(saveBusinessAttachments);
      } catch (err) {
        onError(err);
        toastError('Something went wrong...');
      }
    },
    [submitGeneralInfo, submitRequirements]
  );

  if (isEmpty(data) || isLoadingInfo) return <Splash />;

  return (
    <div>
      <AttachmentsForm
        ref={formRef}
        readOnly={readOnly}
        onSubmit={handleSubmit}
        onSuccess={() => {
          onNext();
        }}
        containerClassName="grid-cols-1 sm:grid-cols-2"
      />
      <div className="mt-4 flex flex-col justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
        {!readOnly ? (
          <>
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={onNext}
              >
                Skip
              </Button>
            </div>
            <Button
              primary
              size="lg"
              disabled={isLoading || isSubmittingRequirements}
              className="order-2 whitespace-nowrap sm:order-1"
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
            >
              Save and Continue
            </Button>
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={onNext}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}

DueDiligencePage.defaultProps = {
  readOnly: false,
};

DueDiligencePage.propTypes = {
  onNext: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default DueDiligencePage;
