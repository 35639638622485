import React from 'react';
import PropTypes from 'prop-types';
import Card from 'partial/components/Card';
import { HiRefresh } from 'react-icons/hi';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import { listTransaction } from 'core-modules/dashboard/actions';
import { useTransactionList } from 'core-modules/dashboard/hooks';
import { paymentStatusTableKey } from 'core-modules/dashboard/components/PaymentStatus';

function RecentTransaction({ filter: rawFilter }) {
  const filter = React.useMemo(
    () => ({
      ...rawFilter,
      per_page: 10,
    }),
    [rawFilter]
  );
  const [isLoading, data] = useTransactionList(filter);
  const tableFormat = React.useMemo(
    () => [
      { label: 'Date/Time', key: 'created_at', width: '140px' },
      { label: 'Reference no.', key: 'refno' },
      { label: 'Merchant', key: 'merchant_name' },
      { label: 'Channel', key: 'channel_name' },
      { label: 'Amount', key: 'amount' },
      {
        label: 'Payment Status',
        id: 'payment_status_label',
        key: paymentStatusTableKey,
        headerClassName: 'justify-center',
        className: 'text-center',
        width: 146,
      },
    ],
    []
  );
  const renderTry = React.useMemo(() => {
    const handleReload = () => {
      listTransaction(filter);
    };
    return (
      <div>
        <Button size="sm" onClick={handleReload} icon={HiRefresh}>
          Reload
        </Button>
      </div>
    );
  }, [filter]);
  return (
    <Card label="Recent Transactions" renderAction={renderTry}>
      <div className="flex flex-col" style={{ height: 300 }}>
        <Table isLoading={isLoading} data={data} format={tableFormat} />
      </div>
    </Card>
  );
}

RecentTransaction.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
};

export default RecentTransaction;
