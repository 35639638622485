import { toastError, toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import promiseDownload from 'utils/promiseDownload';
import { removeEmpty } from 'helper';
import * as c from './constants';
import * as t from './transformers';

export const listReport = (params) => {
  req.get({
    key: c.REPORT_LIST,
    url: '/merchant_report/v1/reports',
    params: removeEmpty(params),
    transform: (res) => (res?.data || []).map(t.transformReport),
    onSuccess: (res) => {
      req.set(c.REPORT_PAGER, res?.response?.meta || {});
      req.set(c.REPORT_FILTER, params);
    },
  });
};

export const appendListReport = (params) => {
  req.get({
    key: c.REPORT_LIST,
    url: '/merchant_report/v1/reports',
    params: removeEmpty(params),
    transform: (res, state) =>
      (state[c.REPORT_LIST] || []).concat(
        (res.data || []).map(t.transformReport)
      ),
    onSuccess: (res) => {
      req.set(c.REPORT_PAGER, res?.response?.meta || {});
      req.set(c.REPORT_FILTER, params);
    },
  });
};

export const generateReport = (payload, onSuccess, onError) => {
  req.post({
    key: c.REPORT_GENERATE,
    url: '/merchant_report/v1/reports',
    payload: t.payloadGenerateReport(payload),
    onSuccess: (res, state) => {
      toastSuccess(res?.response?.message || 'Processing report...');
      listReport(state[c.REPORT_FILTER]);
      if (onSuccess) onSuccess();
    },
    onError,
  });
};

export const downloadReport = async (id, filename, onSuccess, onError) => {
  try {
    await promiseDownload(
      `/merchant_report/v1/reports/${id}/download`,
      filename
    );
    toastSuccess('Downloading...');
    onSuccess();
  } catch (err) {
    onError();
    toastError(err?.error_description || err?.message || 'Unable to download!');
  }
};
