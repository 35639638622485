import React from 'react';

import { paymentStatusTableKey } from 'core-modules/dashboard/components/PaymentStatus';
import { postingStatusTableKey } from 'core-modules/dashboard/components/PostingStatus';
import { refundRequestStatusTableKey } from './components/RefundApprovalStatus';

export const SETTLEMENT_LIST = 'SETTLEMENT/list';
export const SETTLEMENT_PAGER = 'SETTLEMENT/pager';
export const SETTLEMENT_FILTER = 'SETTLEMENT/filter';
export const SETTLEMENT_COUNT_CARD = 'SETTLEMENT/count_card';

export const SETTLEMENT_LOGS_LIST = 'SETTLEMENT_LOGS/list';
export const SETTLEMENT_LOGS_PAGER = 'SETTLEMENT_LOGS/pager';
export const SETTLEMENT_LOGS_FILTER = 'SETTLEMENT_LOGS/filter';

export const LEDGER_LIST = 'LEDGER/list';
export const LEDGER_PAGER = 'LEDGER/pager';
export const LEDGER_FILTER = 'LEDGER/filter';

export const REFUND_LIST = 'REFUND/list';
export const REFUND_PAGER = 'REFUND/pager';
export const REFUND_FILTER = 'REFUND/filter';
export const REFUND_REQUEST = 'REFUND/create_request';

const BADGE_CLASS = {
  SETTLED:
    'rounded px-2 pt-[1px] pb-[2px] text-[.6rem] font-semibold uppercase leading-none tracking-wider bg-green-200 text-green-600',
  UNSETTLED:
    'rounded px-2 pt-[1px] pb-[2px] text-[.6rem] font-semibold uppercase leading-none tracking-wider bg-red-200 text-red-600',
  default:
    'rounded px-2 pt-[1px] pb-[2px] text-[.6rem] font-semibold uppercase leading-none tracking-wider bg-gray-200 text-gray-600',
};

export const renderBadge = (status, label) => (
  <span className={BADGE_CLASS[status] || BADGE_CLASS.default}>{label}</span>
);

export const REFUND_TABLE_FORMAT = [
  {
    label: 'Date/Time',
    key: 'created_at',
    stackKey: true,
  },
  { label: 'Reference No.', id: 'refno', key: 'refno', stackKey: true },
  { label: 'Merchant', id: 'merchant', key: 'merchant_name', stackKey: true },
  { label: 'Channel', id: 'channel', key: 'channel_name', stackKey: true },
  { label: 'Amount', id: 'amount', key: 'amount', stackKey: true },
  {
    label: 'Status',
    id: 'status',
    key: refundRequestStatusTableKey,
    stackKey: true,
  },
  {
    smRender: (row) => (
      <div>
        <div className="float-right text-right">
          <div>{row?.amount}</div>
          {refundRequestStatusTableKey({ status: row?.status })}
        </div>
        <div className="text-primary-500">{row?.merchant_name}</div>
        <div className="text-gray-500">{row?.channel_name}</div>
        <div className="text-gray-500">{row?.created_at}</div>
        <div className="text-gray-500">{row?.refno}</div>
      </div>
    ),
  },
];

export const REFUND_DETAILS_FORMAT = [
  {
    label: 'Date/Time',
    key: 'created_at',
  },
  {
    label: 'Reference No.',
    key: 'refno',
  },
  {
    label: 'Merchant',
    key: 'merchant_name',
  },
  {
    label: 'Channel',
    key: 'channel_name',
  },
  {
    label: 'Amount',
    key: 'amount',
  },
  {
    label: 'Payment Status',
    key: ({ payment_status }) =>
      paymentStatusTableKey({
        payment_status: payment_status || '',
      }),
  },
  {
    label: 'Posting Status',
    key: ({ posting_status }) =>
      postingStatusTableKey({
        posting_status: posting_status || '',
      }),
  },
];
