import React from 'react';

import { showLightbox } from 'partial/components/Modal';
import { NoAttachmentFound } from 'partial/components/Placeholders';

export const MERCHANT_LIST = 'MERCHANTS/list';
export const MERCHANT_PAGER = 'MERCHANTS/pager';
export const MERCHANT_FILTER = 'MERCHANTS/filter';
export const MERCHANT_ITEM = 'MERCHANTS/item';
export const MERCHANT_ITEM_THEME = 'MERCHANTS/theme';
export const MERCHANT_ATTACHMENTS = 'MERCHANTS/attachments';
export const MERCHANT_CHANNELS_SWITCH = 'MERCHANTS/channels_switch';

export const MERCHANT_SETTLEMENT_LIST = 'MERCHANTS/settlement_list';
export const MERCHANT_SETTLEMENT_PAGER = 'MERCHANTS/settlement_pager';
export const MERCHANT_SETTLEMENT_FILTER = 'MERCHANTS/settlement_filter';

export const MERCHANT_GENERAL_INFO = 'MERCHANTS/general_info';
export const MERCHANT_BUSINESS_INFO = 'MERCHANTS/business_info';

export const ORG_MERCHANT = 'MERCHANTS/org_merchant';

export const COUNT_CARD = 'MERCHANT/count_card';

export const imagePreview = ({
  label,
  key,
  className = 'grid grid-cols-1',
  span = 1,
}) => ({
  label,
  key: (row) => (
    <div className={className}>
      <div className="group aspect-h-1 aspect-w-1 mt-4 w-full overflow-hidden rounded-md bg-gray-100">
        {row?.[key] ? (
          <>
            <button
              className="absolute inset-0 z-20 h-full w-full transition focus:ring-1
            focus:ring-primary-500 group-hover:bg-black group-hover:bg-opacity-30"
              type="button"
              onClick={() =>
                showLightbox({
                  title: label,
                  content: row?.[key],
                })
              }
            >
              <p className="hidden p-3 text-sm text-gray-100 group-hover:block">
                Click to view
              </p>
            </button>
            <div className="absolute h-full w-full">
              <img
                className="h-full w-full object-contain object-center"
                src={row?.[key]}
                alt="Preview"
              />
            </div>
          </>
        ) : (
          <NoAttachmentFound />
        )}
      </div>
    </div>
  ),
  span,
});

/* MERCHANT PROFILE */
export const BUSINESS_TYPE_LABEL = {
  CORPORATION: 'Corporation',
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  INDIVIDUAL: 'Individual',
  PARTNERSHIP: 'Partnership',
};

export const REPRESENTATIVE_INFORMATION_FORMAT = [
  {
    label: 'Full Name',
    key: (row) => (
      <span>
        {row?.first_name} {row?.last_name}
      </span>
    ),
  },
];

export const HOME_ADDRESS_FORMAT = [
  {
    label: 'Location',
    key: 'home_address',
  },
  {
    label: 'Beneficial Owner',
    key: (row) => <span>{row?.is_beneficial_owner ? 'Yes' : 'No'}</span>,
  },
  imagePreview({
    label: 'Valid indentification documents',
    key: 'owner_id_image_url',
    className: 'grid grid-cols-4',
  }),
];

export const BANK_DETAILS_FORMAT = [
  {
    label: 'Bank',
    key: 'bank_name',
    span: 2,
  },
  {
    label: 'Account Name',
    key: 'bank_account_name',
  },
  {
    label: 'Account Number',
    key: 'bank_account_number',
  },
];

const BUSINESS_INFO_CORPORATION = [
  {
    label: 'Business Legal Name',
    key: 'business_legal_name',
    span: 3,
  },
  {
    label: 'Business Registration Number',
    key: 'business_registration_number',
    span: 1,
  },
  {
    label: 'Date of Incorporation',
    key: 'business_incorporation_date',
    span: 2,
  },
  // imagePreview({
  //   label: 'SEC Certificate',
  //   key: 'sec_cert_image_url',
  // }),
  // imagePreview({
  //   label: 'By-Laws',
  //   key: 'by_laws_image_url',
  // }),
  // imagePreview({
  //   label: 'Articles of Incorporation',
  //   key: 'art_of_incor_image_url',
  // }),
];

const BUSINESS_INFO_SOLE_PROPRIETORHSIP = [
  {
    label: 'Business Legal Name',
    key: 'business_legal_name',
    span: 3,
  },
  {
    label: 'Business Registration Number',
    key: 'business_registration_number',
  },
  {
    label: 'Business Duration',
    key: 'business_duration',
    span: 2,
  },
  // imagePreview({
  //   label: 'DTI Registration Certificate',
  //   key: 'business_dti_reg_cert_image_url',
  // }),
];

const BUSINESS_INFO_INDIVIDUAL = [
  {
    label: 'Business Duration',
    key: 'business_duration',
  },
];

const BUSINESS_INFO_PARTNER = [
  {
    label: 'Business Legal Name',
    key: 'business_legal_name',
    span: 3,
  },
  {
    label: 'Business Registration Number',
    key: 'business_registration_number',
    span: 1,
  },
  {
    label: 'Date of Incorporation',
    key: 'business_incorporation_date',
    span: 2,
  },
  // imagePreview({
  //   label: 'SEC Certificate of Partnership',
  //   key: 'sec_cert_of_partnership_image_url',
  // }),
  // imagePreview({
  //   label: 'Articles of Incorporation',
  //   key: 'art_of_partnership_image_url',
  // }),
];

export const BUSINESS_INFO_FORMAT = {
  CORPORATION: BUSINESS_INFO_CORPORATION,
  SOLE_PROPRIETORSHIP: BUSINESS_INFO_SOLE_PROPRIETORHSIP,
  INDIVIDUAL: BUSINESS_INFO_INDIVIDUAL,
  PARTNERSHIP: BUSINESS_INFO_PARTNER,
};
/* MERCHANT PROFILE END */

/* MERCHANT PAYMENT ATTACHMENTS START */
export const LIST_PAYMENT_CATEGORIES = (id) =>
  `MERCHANT/get_attached_categories?id=${id}`;
export const LIST_PAYMENT_CATEGORY_CHANNELS = (category_uuid) =>
  `MERCHANT/get_category_channels?key=${category_uuid}`;
export const TOGGLE_CHANNEL_STATUS = 'MERCHANT/toggle_attached_channel_status';
export const UPDATE_CHANNEL_SEQUENCE =
  'MERCHANT/update_attached_channel_sequence';

export const TOGGLE_CATEGORY_STATUS =
  'MERCHANT/toggle_attached_cateogry_status';

export const LIST_SELECT_PAYMENT_CHANNELS =
  'MERCHANT/list_select_channels_options';

export const FEE_RATE_CLASSIFICATIONS = ['CHANNEL', 'SYSTEM', 'PARTNER'];
/* MERCHANT PAYMENT ATTACHMENTS END */

export const MAP_BADGE_CLASS = {
  APPROVED: 'badge-success',
  IN_REVIEW: 'badge-warning',
  PENDING: 'badge-gray',
  REJECTED: 'badge-danger',
};
