import React from 'react';
import PropTypes from 'prop-types';

const CLASS_MAPPER = {
  PAID: 'badge badge-success',
  PENDING: 'badge badge-warning',
  FAILED: 'badge badge-danger',
  INITIAL: 'badge badge-gray',
  REFUNDED: 'badge badge-gray',
  VOIDED: 'badge badge-gray',
  EXPIRED: 'badge badge-danger',
};

const LABEL_MAPPER = {
  PAID: 'Paid',
  PENDING: 'Pending',
  FAILED: 'Failed',
  INITIAL: 'Initial',
  REFUNDED: 'Refunded',
  VOIDED: 'Voided',
  EXPIRED: 'Expired',
};

export const paymentStatusTableKey = ({ payment_status }) => (
  <PaymentStatus status={payment_status} />
);

function PaymentStatus({ status }) {
  return (
    <span className={CLASS_MAPPER[status]}>
      {LABEL_MAPPER[status] || status}
    </span>
  );
}

PaymentStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PaymentStatus;
