import React from 'react';
import PropTypes from 'prop-types';
import NavLink from 'partial/components/NavLink';

export const NavTabItem = ({ to, label, count, ...props }) => (
  <NavLink
    to={to}
    className="inline-flex flex-auto sm:flex-none justify-center items-center px-1 pt-1 space-x-2 border-b-2 text-sm font-bold h-14"
    inactiveClassName="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
    activeClassName="border-primary-500 text-primary-500"
    {...props}
  >
    <span className="whitespace-nowrap">{label}</span>
    {count ? <span className="badge badge-gray">{count}</span> : null}
  </NavLink>
);

NavTabItem.defaultProps = {
  count: '',
};

NavTabItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.string,
};

function NavTab({ children }) {
  return (
    <div className="bg-white">
      <div className="sm:mx-0 mx-auto sm:max-w-7xl w-full px-4 sm:px-6 md:px-8 flex items-center space-x-2 lg:space-x-4">
        {children}
      </div>
    </div>
  );
}

NavTab.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default NavTab;
