export const transformMerchantOptions = (raw) => ({
  value: raw?.uuid,
  label: raw?.name,
  photo_url: raw?.logo,
});

const MAP_APPROVAL_STATUS = {
  APPROVED: 'Approved',
  IN_REVIEW: 'In Review',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const transformMerchant = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || 'Untitled',
  logo: raw?.logo,
  env_mode: raw?.status,
  is_org_merchant: raw?.is_org_merchant === 1,

  approval_status: raw?.approval_status,
  approval_status_label: MAP_APPROVAL_STATUS[raw?.approval_status],
  approval_remarks: raw?.approval_status_remarks,

  is_payment_channel_switchable: Boolean(raw?.payment_channel_switch_allowed),

  theme: {
    theme: raw?.theme_code || '',
    ...(raw?.theme_meta || {}),
  },
});

export const transformMerchantSettlement = (raw) => ({
  id: raw?.id || '',
  name: raw?.name || '',
});

export const transformMerchantApiKey = (raw) => ({
  id: raw?.id || '',
  name: raw?.name || '',
});

export const transformGeneralInfo = (raw) => ({
  id: raw?.uuid || '',
  logo: raw?.logo || '',
  name: raw?.name || '',
  industry: raw?.industry || '',
  customer_service_number: raw?.customer_service_number || '',
  business_country: raw?.business_country || '',
  business_state: raw?.business_state || '',
  business_website: raw?.business_website,
  business_email_address: raw?.business_email_address,
  business_city: raw?.business_city || '',
  business_address: raw?.business_address || '',
  business_zip_code: raw?.business_zip_code || '',
  business_geoloc: raw?.business_geoloc || null,
  business_description: raw?.business_description || '',
  business_number_of_customers: raw?.business_number_of_customers || '',
  business_monthly_sales: raw?.business_monthly_sales || '',
  business_type: raw?.business_type || '',

  contact_first_name: raw?.contact_first_name || '',
  contact_last_name: raw?.contact_last_name || '',
  contact_designation: raw?.contact_designation || '',
  contact_mobile_number: raw?.contact_mobile_number || '',
  contact_email_address: raw?.contact_email_address || '',
  requirements: raw?.requirements || [],
});

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const transformAttachedPaymentCategory = (raw) => ({
  id: raw?.uuid,
  logo: raw?.logo,
  name: raw?.name,
  status: raw?.status,
  code: raw?.code,
  parent_category_status: raw?.parent_category_status,
});

export const transformAttachedPaymentChannel = (raw) => ({
  id: raw?.uuid,
  code: raw?.code,
  logo: raw?.logo,
  name: raw?.name,
  status: raw?.status,
  sequence: raw?.sequence,
  visibility: raw?.visibility,
  payment_channel_fees: raw?.payment_channel_fees,
});

export const transformChannelOptions = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || '',
  logo: raw?.logo || '',
  code: raw?.code || '',
});
/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */

export const payloadTheme = ({ theme: theme_code, ...theme_meta }) => ({
  theme_code,
  theme_meta,
});
