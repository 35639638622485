import React from 'react';

import Button from 'partial/components/Button';
import { useToggleLiveTestEnvironment } from 'modules/auth/modals/SwitchProfileModal';

function TestModeCard() {
  const { toggleEnvironment: promptSwitchProfile } =
    useToggleLiveTestEnvironment();
  return (
    <div className="group relative">
      <div className="relative my-1 flex items-center rounded-lg bg-gray-50 p-5">
        <div className="flex items-center justify-between space-x-5">
          <div>
            <div className="text-primary-500">Try Test Environment</div>
            <div className="text-sm text-gray-500">
              Use the merchant&apos;s test account to try our API and apply our
              solution.
            </div>
          </div>
          <span className="flex-shrink-0 transition duration-200 group-hover:text-gray-100">
            <Button onClick={promptSwitchProfile} size="sm" outline>
              Test Mode
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default TestModeCard;
