/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'partial/components/Button';
import img1 from '../assets/tutorial-4.1.svg';
import img2 from '../assets/tutorial-4.2.svg';
import TourDots from '../TourDots';

function CreateTransactionModal({ onNext }) {
  const handleInvoice = () => {
    onNext('create-invoice');
  };
  const handleApi = () => {
    onNext('integrate-api');
  };
  return (
    <div className="">
      <div className="rounded-b-xl bg-gray-100 p-5">
        <div className="flex items-center justify-center space-x-3">
          <div className="w-full max-w-lg space-y-3 text-center">
            <div className="space-y-1 ">
              <div className="text-lg font-bold text-gray-900 sm:text-xl">
                Create Payment!
              </div>
              <div>
                You can create transaction using our invoice module or via API
                Integration.
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <div className="relative rounded-xl bg-white p-5 transition hover:bg-gray-50">
                <button
                  className="absolute inset-0 z-10 h-full w-full"
                  type="button"
                  onClick={handleInvoice}
                />
                <img className="mx-auto mb-3 h-36" src={img1} alt="" />
                <div className="font-semibold text-primary-500">
                  via Invoice
                </div>
              </div>

              <div className="relative rounded-xl bg-white p-5 transition hover:bg-gray-50">
                <button
                  className="absolute inset-0 z-10 h-full w-full"
                  type="button"
                  onClick={handleApi}
                />
                <img className="mx-auto mb-3 h-36" src={img2} alt="" />
                <div className="font-semibold text-primary-500">
                  via API Integration
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between space-x-3 bg-white py-3 px-5">
        <TourDots />
        <Button onClick={() => onNext(null)} link>
          Skip for now
        </Button>
      </div>
    </div>
  );
}

CreateTransactionModal.propTypes = {
  onNext: PropTypes.func.isRequired,
  // onSkip: PropTypes.func.isRequired,
};

export default CreateTransactionModal;
