import React, { useCallback } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { SlClose } from 'react-icons/sl';
import { IoCopyOutline } from 'react-icons/io5';

import Button from 'partial/components/Button';
import { ErrorLabel } from 'partial/form/Form';
import { formatCurrency, parseNumber } from 'helper';
import placeholder from 'assets/images/placeholder.svg';
import { HiPlus, HiUpload } from 'react-icons/hi';
import { useMakeInputAcceptValidAmountOnly } from 'partial/hooks';
import { uploadPhoto } from 'partial/form/FormPhotoDnd';

const UPLOAD_OPTIONS = {
  crop: '1:1',
};

const InvoiceItem = ({ data, onEdit, onDuplicate, onRemove, readOnly }) => {
  const dataRef = React.useRef(data);

  const { registerOnValueChange } = useMakeInputAcceptValidAmountOnly();

  React.useEffect(() => {
    dataRef.current = data;
  }, [data]);
  const handleChangePhoto = async () => {
    const photo_url = await uploadPhoto(UPLOAD_OPTIONS);
    onEdit({ ...dataRef.current, photo_url });
  };
  return (
    <div className="grid grid-cols-11 gap-2">
      <div className="col-span-2">
        <div className="group relative ml-auto grid h-14 w-14 place-items-center overflow-hidden rounded-md border border-dashed border-primary-500 sm:h-20 sm:w-20">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            className="absolute inset-0 z-10 h-full w-full"
            onClick={handleChangePhoto}
            type="button"
            disabled={readOnly}
          />
          {!readOnly && !data?.photo_url && (
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="group inline-block scale-100 transform rounded-full bg-white p-1 text-primary-500 transition ease-in-out group-hover:scale-150 group-hover:rounded-[4px] group-hover:bg-primary-500 group-hover:text-white">
                <HiUpload className="h-7 w-7 flex-shrink-0 sm:h-10 sm:w-10" />
              </span>
            </div>
          )}
          <img
            className="h-14 w-14 object-cover object-center sm:h-20 sm:w-20"
            src={data?.photo_url || placeholder}
            alt="thumb"
          />
        </div>
      </div>
      <input
        className="col-span-4 block rounded-md border px-2 py-3 text-sm text-gray-700 placeholder:text-transparent focus:outline-none sm:px-4 sm:py-7 sm:text-base"
        onChange={({ target }) => {
          onEdit({ ...data, name: target?.value });
        }}
        placeholder="Item Name"
        readOnly={readOnly}
        tabIndex={readOnly ? '-1' : null}
        value={data?.name || ''}
      />
      <input
        className="col-span-2 block rounded-md border px-2 py-3 text-sm text-gray-700 placeholder:text-transparent focus:outline-none sm:px-4 sm:py-7 sm:text-base"
        onChange={(e) => {
          registerOnValueChange(e?.target?.value, (value) => {
            onEdit({ ...data, qty: value });
          });
        }}
        placeholder="Qty"
        readOnly={readOnly}
        tabIndex={readOnly ? '-1' : null}
        value={data?.qty}
      />
      <input
        className="col-span-2 block rounded-md border px-2 py-3 text-right text-sm text-gray-700 placeholder:text-transparent focus:outline-none sm:px-4 sm:py-7 sm:text-base"
        onChange={(e) => {
          registerOnValueChange(e?.target?.value, (value) => {
            onEdit({ ...data, amount: value });
          });
        }}
        placeholder="Amount"
        readOnly={readOnly}
        tabIndex={readOnly ? '-1' : null}
        value={data?.amount}
      />
      {!readOnly ? (
        <div className="col-span-1 flex flex-col-reverse items-center justify-center sm:flex-row sm:justify-end">
          <button
            type="button"
            className="grid h-7 w-7 flex-shrink-0 place-items-center rounded-full hover:ring-1"
            onClick={() => onDuplicate(data)}
            aria-label="icon"
          >
            <IoCopyOutline className="h-5 w-5 flex-shrink-0 text-primary-500" />
          </button>
          <button
            type="button"
            className="grid h-7 w-7 flex-shrink-0 place-items-center rounded-full hover:ring-1"
            onClick={onRemove}
            aria-label="icon"
          >
            <SlClose className="h-5 w-5 flex-shrink-0 text-red-600" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

InvoiceItem.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

function InvoiceItems({ name, onChange, onSetFieldValue, value, readOnly }) {
  const handleAdd = () => {
    const newItemDefaultValue = {
      id: shortid.generate(),
      photo_url: '',
      name: `Item ${(value || []).length + 1}`,
      qty: 1,
      amount: '',
    };
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: value.concat([newItemDefaultValue]),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, value.concat([newItemDefaultValue]));
    }
  };
  const handleEdit = (index) => (row) => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: value?.map((x, i) => (index === i ? row : x)),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(
        name,
        value?.map((x, i) => (index === i ? row : x))
      );
    }
  };
  const handleRemove = (index) => () => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: value?.filter((x, i) => index !== i),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(
        name,
        value?.filter((x, i) => index !== i)
      );
    }
  };
  const handleDuplicate = useCallback(
    (index) => (row) => {
      const clonedValue = [...value];
      clonedValue.splice(index + 1, 0, {
        ...row,
        id: shortid.generate(),
      });
      onSetFieldValue(name, clonedValue);
    },
    [name, value, onSetFieldValue]
  );
  const totalAmount = formatCurrency(
    value.reduce(
      (acc, cur) =>
        acc + parseNumber(cur?.amount, 0) * parseNumber(cur?.qty, 0),
      0
    )
  );
  return (
    <div className="space-y-4">
      <div className="title-h2">Items</div>
      <div className="bg-white pb-2">
        <div className="grid grid-cols-11 gap-2 py-3 font-light text-slate-900">
          <p className="col-span-4 col-start-3">Description</p>
          <p className="col-span-2">Qty</p>
          <p className="2">Amount</p>
        </div>
        <div className="mt-2 space-y-2">
          {value.length < 1 ? (
            <div className="flex min-h-[10rem] flex-col items-center pt-6 text-center">
              <div className="mb-4 text-gray-500">There are no items.</div>
              <Button primary onClick={handleAdd} icon={HiPlus} size="sm">
                Add an item
              </Button>
            </div>
          ) : (
            <>
              {value.map((row, i) => (
                <div key={row?.id}>
                  <InvoiceItem
                    data={row}
                    readOnly={readOnly}
                    onEdit={handleEdit(i)}
                    onRemove={handleRemove(i)}
                    onDuplicate={handleDuplicate(i)}
                  />
                  <div className="flex justify-center">
                    <ErrorLabel name={`items[${i}].name`} />
                    <ErrorLabel name={`items[${i}].qty`} />
                    <ErrorLabel name={`items[${i}].amount`} />
                  </div>
                </div>
              ))}
              <div className="grid grid-cols-11 gap-2 py-3">
                {!readOnly && (
                  <Button
                    link
                    icon={HiPlus}
                    onClick={handleAdd}
                    className="col-span-5 sm:col-span-3"
                  >
                    Add an item
                  </Button>
                )}
                <p className="col-span-2 col-start-7 col-end-12 flex items-center justify-between sm:col-end-11">
                  <span className="whitespace-nowrap">Total Amount:</span>
                  <span className="ml-2">{totalAmount}</span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

InvoiceItems.defaultProps = {
  readOnly: false,
  onChange: false,
  onSetFieldValue: false,
};

InvoiceItems.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  value: PropTypes.instanceOf(Array).isRequired,
};

export default InvoiceItems;
