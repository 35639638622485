export const LIST_NOTIFICATION = 'NOTIFICATIONS/list';
export const SHOW_NOTIFICATION = 'NOTIFICATIONS/show';
export const SHOW_HAS_UNREAD = 'NOTIFICATIONS/unread';
export const READ_ALL_NOTIFICATION = 'NOTIFICATIONS/read_all';

export const BG_COLOR_CLASS = {
  SUCCESS: 'bg-green-500',
  DANGER: 'bg-red-500',
  WARNING: 'bg-yellow-500',
};
