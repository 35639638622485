import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import Form, {
  ErrorLabel,
  // , setError422
} from 'partial/form/Form';
import Button from 'partial/components/Button';
import FormPassword from 'partial/form/FormPassword';

// import { useForgotPassword } from '../hooks';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Required')
    .min(12, 'At least 12 characters')
    .matches(/[A-Z]/, 'Must contain 1 uppercase letter')
    .matches(/[a-z]/, 'Must contain 1 lowercase letter')
    .matches(/[0-9]/, 'Must contain 1 numeric digit')
    .matches(
      /^(?=.*[!@#$%^&*])/,
      'Must contain at least 1 special character (!@#$%&*)'
    ),
  password_confirmation: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Does not match'),
});

const MAP_TYPE_BUTTON_LABEL = {
  set: 'Set Password',
  forgot: 'Reset Password',
  update: 'Update Password',
};

function NewPasswordForm({ onSubmit, isLoading, type }) {
  const [validateOnChange, setValidateOnChange] = useState(false);

  const {
    values,
    handleSubmit,
    setFieldValue,
    // setFieldError,
    errors,
  } = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit,
    validateOnChange,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      error={errors}
      noValidate
      className="w-full space-y-3"
    >
      <div>
        <FormPassword
          name="password"
          label="New Password"
          onSetFieldValue={setFieldValue}
          value={values.password}
          error={errors.password}
          noAutoFill
          required
        />
        <ErrorLabel name="password" />
      </div>
      <div>
        <FormPassword
          name="password_confirmation"
          label="Confirm New Password"
          onSetFieldValue={setFieldValue}
          value={values.password_confirmation}
          error={errors.password_confirmation}
          noAutoFill
          required
        />
        <ErrorLabel name="password_confirmation" />
      </div>
      <Button
        size="xl"
        secondary
        type="submit"
        className="w-full"
        disabled={isLoading}
        onClick={() => setValidateOnChange(true)}
      >
        {MAP_TYPE_BUTTON_LABEL[type]}
      </Button>
    </Form>
  );
}

NewPasswordForm.defaultProps = {
  isLoading: false,
};

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(['set', 'forgot', 'update']).isRequired,
};

export default NewPasswordForm;
