import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import InvoiceContainer from './InvoiceContainer';
import PaymentLinksContainer from './PaymentLinksContainer';

function InvoiceRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.path}/invoice`}>
          <InvoiceContainer />
        </Route>
        <Route path={`${match.path}/payment-links`}>
          <PaymentLinksContainer />
        </Route>
        <Route render={() => <Redirect to={`${match.path}/invoice`} />} />
      </Switch>
    </>
  );
}

export default InvoiceRoutes;
