import React from 'react';
import { each, get } from 'lodash';
import PropTypes from 'prop-types';

// import { MAP_FORM_DESC_THEME_CLASSNAMES } from './constants';

export const FormContext = React.createContext();

export const setError422 = (err, setFieldError) => {
  if (err?.response?.error === 'unprocessable_entity') {
    each(err?.response?.errors, (v, k) => {
      setFieldError(k, v);
    });
  }
};

export const ErrorLabel = ({ name, hideIfArray }) => {
  const { error } = React.useContext(FormContext);
  if (!get(error, name) || (hideIfArray && Array.isArray(get(error, name))))
    return null;
  const label = (() => {
    const err = get(error, name);
    if (typeof err === 'string') return err;
    if (Array.isArray(err) && typeof err[0] === 'string') return err[0];
    return 'Invalid';
  })();
  return (
    <div className="relative z-0 -mt-1 rounded-b px-2 pb-2 pt-3 text-xs text-red-500">
      {label}
    </div>
  );
};

ErrorLabel.defaultProps = {
  hideIfArray: false,
};

ErrorLabel.propTypes = {
  name: PropTypes.string.isRequired,
  hideIfArray: PropTypes.bool,
};

export const ErrorOrDescription = ({ name, label }) => {
  const { error } = React.useContext(FormContext);

  // const themeClass =
  //   MAP_FORM_DESC_THEME_CLASSNAMES[theme] ||
  //   MAP_FORM_DESC_THEME_CLASSNAMES.default;

  if (!error[name])
    return (
      <div className="relative z-0 -mt-1 rounded-b px-2 pb-2 pt-3 text-xs text-gray-400">
        {label}
      </div>
    );
  return (
    <div className="relative z-0 -mt-1 rounded-b px-2 pb-2 pt-3 text-xs text-red-500">
      {error[name]}
    </div>
  );
};

ErrorOrDescription.defaultProps = {};

ErrorOrDescription.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function Form({ onSubmit, children, error, ...props }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <FormContext.Provider value={{ error }}>
      <form onSubmit={handleSubmit} {...props}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  error: {},
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  error: PropTypes.instanceOf(Object),
};

export default Form;
