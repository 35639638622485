/* eslint-disable */
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { AiFillBell } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import invoice from 'assets/images/invoice.png';
import paymentLinks from 'assets/images/payment-links.png';
import testMode from 'assets/images/test-mode.png';
import payout from 'assets/images/payout.png';
import Button from 'partial/components/Button';
import merchants from 'assets/images/merchants.png';
import accounting from 'assets/images/accounting.png';
import { useActiveAccount } from 'modules/auth/hooks';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';

import { useOpenRejectionRemarksModal } from '../modals/RejectionRemarksModal';

function GetStarted() {
  const history = useHistory();

  const createModal = useCreateModal();
  const [activeAccount] = useActiveAccount();
  const [, orgMerchant] = useOrgFirstMerchantData();
  const openRejectionRemarlsModal = useOpenRejectionRemarksModal({
    onContinue: () => history.push('/onboarding'),
  });

  const completed = useMemo(() => {
    let doneStepCount = 0;
    if (activeAccount?.meta?.is_org_profile_submitted) doneStepCount += 1;
    if (activeAccount?.meta?.is_first_merchant_general_submitted)
      doneStepCount += 1;
    if (activeAccount?.meta?.is_first_merchant_attachment_submitted)
      doneStepCount += 1;
    if (activeAccount?.meta?.is_org_bank_details_submitted) doneStepCount += 1;
    if (
      !isEmpty(orgMerchant) &&
      orgMerchant?.approval_status !== 'PENDING' &&
      orgMerchant?.approval_status !== 'REJECTED'
    )
      doneStepCount += 1;

    return doneStepCount;
  }, [activeAccount, orgMerchant]);

  const openKYCPromptModal = useCallback(() => {
    createModal({
      content: (onClose) => (
        <ModalCard title="Activate your Account" onClose={onClose}>
          <div className="p-5">
            <p>You need to activate your account to access this feature.</p>
          </div>
          <div className="relative flex w-full justify-end border-t py-4 px-6">
            <Button transparent type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              type="button"
              onClick={() => {
                history.push('/onboarding');
                onClose();
              }}
            >
              Complete KYC
            </Button>
          </div>
        </ModalCard>
      ),
    });
  }, [createModal, history]);

  if (activeAccount?.env_mode === 'TEST') return null;

  return (
    <div className="rounded-xl border bg-white px-5 py-5">
      <div className="mb-4 flex flex-col justify-between space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
        <div className="space-y-1">
          <div className="flex items-center space-x-3">
            <h1 className="text-xl font-medium sm:text-2xl">
              Hi, {activeAccount?.representative_name}!
            </h1>
            <div className="flex items-center space-x-1 rounded-full bg-gray-200 py-0.5 px-2">
              <div
                className={cn(
                  'h-3 w-3 rounded-full border border-white',
                  completed === 5 ? 'bg-green-500' : 'bg-orange-400'
                )}
              />
              {completed === 5 ? (
                <span className="text-xs">completed</span>
              ) : (
                <span className="text-xs">
                  {completed}/5 steps to activate your account
                </span>
              )}
            </div>
          </div>
          <p className="font-light text-slate-500">
            Before you can access all features of our online platform,
            you&apos;ll need to set up an account first.
          </p>
        </div>
      </div>
      {orgMerchant?.approval_status === 'PENDING' && (
        <div className="flex flex-col space-y-4 rounded-lg border border-orange-400 bg-orange-50 p-5 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <div className="space-y-1">
            <div className="flex items-center space-x-1 text-orange-400">
              <AiFillBell className="h-5 w-5" />
              <h1 className="font-medium">Activate your account</h1>
            </div>
            <p className="font-light text-slate-500">
              To start accepting payments and payouts in your account,
              you&apos;ll need to provide your business requirements first. All
              the information, documents, and IDs you submit are kept
              confidential and protected by privacy laws.
            </p>
          </div>
          <div className="border-orange-400 sm:ml-6 sm:border-l sm:pl-6">
            <Button
              outline
              type="button"
              onClick={() => history.push('/onboarding')}
              className="w-auto border-orange-400 bg-orange-400 focus:ring-orange-400 sm:w-44"
            >
              <span className="text-white">Activate Account</span>
            </Button>
          </div>
        </div>
      )}
      {orgMerchant?.approval_status === 'IN_REVIEW' && (
        <div className="flex flex-col space-y-4 rounded-lg border border-gray-300 bg-gray-50 p-5 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <div className="space-y-1">
            <h1 className="font-medium text-gray-700">Waiting for Approval</h1>
            <p className="font-light text-gray-500">
              Please wait for 2-3 days while our team is carefully reviewing
              your submitted documents.
            </p>
          </div>
          <div className="border-black sm:ml-6 sm:border-l sm:pl-6">
            <Button
              outline
              type="button"
              onClick={() => history.push('/onboarding')}
              className="w-auto border-black bg-black focus:ring-black sm:w-48"
            >
              <span className="text-white">View Submitted Files</span>
            </Button>
          </div>
        </div>
      )}
      {orgMerchant?.approval_status === 'REJECTED' && (
        <div className="flex flex-col space-y-4 rounded-lg border border-red-500 bg-red-50 p-5 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <div className="space-y-1">
            <h1 className="font-medium text-red-500">Rejected</h1>
            <p className="font-light text-gray-500">
              We&apos;re sorry to inform you that your application was rejected.
              Change the details that needs to be corrected and re-submit your
              application.
            </p>
          </div>
          <div className="border-red-500 sm:ml-6 sm:border-l sm:pl-6">
            <Button
              danger
              type="button"
              className="w-auto sm:w-44"
              onClick={() =>
                openRejectionRemarlsModal(orgMerchant?.approval_remarks || '')
              }
            >
              View Reason
            </Button>
          </div>
        </div>
      )}
      <div className="mt-8">
        <div className="space-y-1">
          <h1 className="text-lg font-medium">Explore our services</h1>
          <p className="font-light text-slate-500">
            Activate your account to start collecting payments using MultiPay.
          </p>
        </div>
        <div className="mt-4 sm:overflow-x-scroll">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {SERVICES.map((service) => (
              <div
                key={service.label}
                className="relative h-[18rem] cursor-pointer rounded-xl border px-4 pt-0 hover:border-primary-500 sm:pt-4"
              >
                <img alt="merchants" src={service.imgSrc} />
                <h1 className="text-base font-medium">{service.label}</h1>
                <div className="absolute inset-0 h-full w-full">
                  {/* eslint-disable-next-line */}
                  <button
                    type="button"
                    onClick={openKYCPromptModal}
                    className="h-full w-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

GetStarted.propTypes = {};

export default GetStarted;

const SERVICES = [
  {
    label: 'Merchants',
    imgSrc: merchants,
  },
  {
    label: 'Payout',
    imgSrc: payout,
  },
  {
    label: 'Accounting',
    imgSrc: accounting,
  },
  {
    label: 'Payment Links',
    imgSrc: paymentLinks,
  },
  {
    label: 'Invoice',
    imgSrc: invoice,
  },
  {
    label: 'Test Mode',
    imgSrc: testMode,
  },
];
