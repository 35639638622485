import React, { useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { debounce, isEqual } from 'lodash';
import { Popover, Transition } from '@headlessui/react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

const BadgeButton = ({ name, onClick, active, children }) => {
  const handleSelect = (e) => {
    e.preventDefault();
    onClick(name);
  };
  return (
    <a
      className={cn(
        'flex-1 rounded-md px-2.5 py-1 text-center text-sm transition hover:bg-gray-200',
        active
          ? 'bg-gray-900 text-white hover:bg-gray-900'
          : 'bg-gray-100 text-gray-800'
      )}
      href="/"
      onClick={handleSelect}
    >
      {children}
    </a>
  );
};

BadgeButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

const FORMAT_BY_MODE = {
  day: 'MM/DD/YYYY',
  month: 'MMM YYYY',
  year: 'YYYY',
};

const MAP_TYPE = {
  day: 'hour',
  month: 'day',
  year: 'month',
};
// eslint-disable-next-line react/prop-types
const Callback = ({ trigger, onChange }) => {
  React.useEffect(() => {
    onChange(trigger);
    // eslint-disable-next-line
  }, [trigger]);
  return null;
};

Callback.propTypes = {
  onChange: PropTypes.func.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]).isRequired,
};

function HistogramRange({
  onChange,
  defaultValue,
  value: staticValue,
  defaultMode,
  mode: staticMode,
  switchMode,
  panelClassName,
  onClick,
  small,
  minDate,
  maxDate,
}) {
  const [watchMode, setMode] = React.useState(defaultMode);
  const [date, setDate] = React.useState(new Date(defaultValue));
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const mode = useMemo(() => staticMode ?? watchMode, [staticMode, watchMode]);

  useEffect(() => {
    if (!staticValue) return;
    setDate(new Date(staticValue));
  }, [staticValue, setDate]);

  const containerRef = React.useRef();
  const popOverRef = React.useRef();
  const handleSet = (newValue) => {
    setMode(newValue);
  };
  const isOpenedOnce = React.useRef(false);
  const fetch = useMemo(
    () =>
      debounce((d, m) => {
        const payload = {};
        payload.type = MAP_TYPE[m];
        payload.from = dayjs(d).startOf(m).format('YYYY-MM-DD');
        payload.to = dayjs(d).endOf(m).format('YYYY-MM-DD');
        onChangeRef.current((state) => {
          const newState = {
            ...state,
            ...payload,
          };
          return isEqual(state, newState) ? state : newState;
        });
      }, 300),
    []
  );
  const handleCallback = (e) => {
    if (e || !isOpenedOnce.current) {
      isOpenedOnce.current = true;
      return;
    }
    fetch(date, mode);
  };
  const handlePrev = () => {
    const newDate = dayjs(date).subtract(1, mode).toDate();
    fetch(newDate, mode);
    setDate(newDate);
  };
  const handleNext = () => {
    const newDate = dayjs(date).add(1, mode).toDate();
    fetch(newDate, mode);
    setDate(newDate);
  };
  const label = React.useMemo(
    () => dayjs(date).format(FORMAT_BY_MODE[mode]),
    [mode, date]
  );

  useEffect(() => {
    if (!popOverRef?.current) return;
    const el = popOverRef?.current;
    const handleOnClick = () => onClick(containerRef.current);
    el.addEventListener('click', handleOnClick);
    // eslint-disable-next-line
    return () => {
      el.removeEventListener('click', handleOnClick);
    };
  }, [popOverRef, onClick]);

  return (
    <Popover className="relative z-10">
      {({ open }) => (
        <>
          <Callback trigger={open} onChange={handleCallback} />
          <div
            ref={containerRef}
            className={cn(
              'flex w-full items-center justify-center space-x-3 rounded border border-gray-300 bg-white',
              small ? 'px-2 py-1' : 'px-3 py-4'
            )}
          >
            <button
              type="button"
              onClick={handlePrev}
              className="disabled:cursor-not-allowed"
              disabled={
                !!minDate &&
                (dayjs(minDate).isSame(dayjs(date), mode) ||
                  dayjs(minDate).isAfter(dayjs(date), mode))
              }
              aria-label="icon"
            >
              <HiChevronLeft className="h-5 w-5 text-gray-500" />
            </button>
            <Popover.Button
              ref={popOverRef}
              className="-mx-1 w-full whitespace-normal"
            >
              {label}
            </Popover.Button>
            <button
              type="button"
              onClick={handleNext}
              className="disabled:cursor-not-allowed"
              disabled={
                !!maxDate &&
                (dayjs(maxDate).isSame(dayjs(date), mode) ||
                  dayjs(maxDate).isBefore(dayjs(date), mode))
              }
              aria-label="icon"
            >
              <HiChevronRight className="h-5 w-5 text-gray-500" />
            </button>
          </div>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className={cn('absolute', panelClassName)}>
              <div className="mt-2 rounded-lg border-gray-300 bg-white p-1 pt-3 shadow-lg">
                {switchMode && (
                  <div className="mb-3 flex w-auto justify-center space-x-2 rounded-md bg-gray-100 p-2">
                    <BadgeButton
                      name="day"
                      onClick={handleSet}
                      active={mode === 'day'}
                    >
                      Day
                    </BadgeButton>
                    <BadgeButton
                      name="month"
                      onClick={handleSet}
                      active={mode === 'month'}
                    >
                      Month
                    </BadgeButton>
                    <BadgeButton
                      name="year"
                      onClick={handleSet}
                      active={mode === 'year'}
                    >
                      Year
                    </BadgeButton>
                  </div>
                )}
                <div>
                  <DatePicker
                    selected={date}
                    onChange={setDate}
                    inline
                    showMonthYearPicker={mode === 'month'}
                    showYearPicker={mode === 'year'}
                    disabledKeyboardNavigation
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

HistogramRange.defaultProps = {
  defaultMode: 'year',
  panelClassName: '',
  switchMode: true,
  onClick: () => {},
  small: false,
  mode: undefined,
  value: undefined,
  minDate: undefined,
  maxDate: undefined,
};

HistogramRange.propTypes = {
  switchMode: PropTypes.bool,
  defaultMode: PropTypes.string,
  mode: PropTypes.oneOf(['year', 'month', 'day', undefined]),
  defaultValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  panelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default HistogramRange;
