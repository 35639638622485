import { useCallback, useEffect, useState } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { useDelayedApiLoading } from 'partial/hooks';
import * as actions from './actions';
import * as c from './constants';

export const usePayoutList = (params) => {
  const isLoading = useApiLoading(c.PAYOUT_LIST, 'get');
  const list = useApiGet(c.PAYOUT_LIST, []);
  const pager = useApiGet(c.PAYOUT_PAGER, {});
  useEffect(() => {
    actions.listPayout(params);
  }, [params]);
  const appendList = useCallback(
    (page) => {
      actions.appendListPayout({ ...params, page });
    },
    [params]
  );
  return [isLoading, list, pager, appendList];
};

export const useDownloadPayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const download = useCallback((id, filename) => {
    setIsLoading(true);
    actions.downloadPayout(
      id,
      filename,
      () => {
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);
  return [isLoading, download];
};

export const usePayoutLogs = (id, params) => {
  const isLoading = useApiLoading(c.PAYOUT_LOGS_LIST, 'get');
  const list = useApiGet(c.PAYOUT_LOGS_LIST, []);
  const pager = useApiGet(c.PAYOUT_LOGS_PAGER, {});
  useEffect(() => {
    actions.listPayoutLogs(id, params);
  }, [id, params]);
  useEffect(() => {
    return () => {
      req.set(c.PAYOUT_LOGS_LIST, null);
      req.set(c.PAYOUT_LOGS_PAGER, null);
      req.set(c.PAYOUT_LOGS_FILTER, null);
    };
  }, []);
  return [isLoading, list, pager];
};

export const useCountCard = (name, params) => {
  const isLoading = useDelayedApiLoading(
    `${c.PAYOUT_COUNT_CARD}/${name}`,
    'get'
  );
  const { value } = useApiGet(`${c.PAYOUT_COUNT_CARD}/${name}`, {});
  useEffect(() => {
    actions.getCountCard(params);
  }, [name, params]);
  return [isLoading, value];
};
