/* eslint-disable */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { NavGroup, NavItem } from './SideNavComponents';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import {
  useAccessControl,
  useOnForbbidenUrlEffect,
  useRedirectToFirstAccessibleModule,
} from 'modules/common/hooks';
import { MERCHANT_SUB_USER_MODULES_MAP } from 'core-modules/users/constants';

function SubUserNavigation({ onSelect }) {
  const [isLoading, data] = useMerchantSelected();
  const { canRead } = useAccessControl();

  const redirectToFirstAccessibleModule = useRedirectToFirstAccessibleModule();
  useOnForbbidenUrlEffect(redirectToFirstAccessibleModule);

  const navItems = useMemo(
    () =>
      MERCHANT_SUB_USER_MODULES_MAP.map((module) => (
        <Fragment key={module.code}>
          {canRead(module.code) && (
            <NavItem
              to={module.url}
              label={module.label}
              icon={module.icon}
              onClick={onSelect}
            />
          )}
        </Fragment>
      )),
    [canRead]
  );

  return (
    <nav className="flex-1 space-y-3 pt-5">
      <NavGroup label={data?.name} labelIsLoading={isLoading}>
        {navItems}
      </NavGroup>
    </nav>
  );
}

SubUserNavigation.defaultProps = {
  onSelect: () => {},
};

SubUserNavigation.propTypes = {
  onSelect: PropTypes.func,
};

export default SubUserNavigation;
