import {
  MdStore,
  MdReceipt,
  MdStickyNote2,
  MdOutlineClose,
  MdSpaceDashboard,
  MdDataExploration,
  MdAccountBalanceWallet,
} from 'react-icons/md';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { HiPlus } from 'react-icons/hi';
import { GiReceiveMoney } from 'react-icons/gi';
import { Link, useHistory } from 'react-router-dom';

import { useActiveAccount } from 'modules/auth/hooks';
import { useAccessControl } from 'modules/common/hooks';
import FormInlineToggle from 'partial/form/FormInlineToggle';
import { ColoredLogoBeta } from 'modules/common/components/AppIconLogo';
import { useToggleLiveTestEnvironment } from 'modules/auth/modals/SwitchProfileModal';

import Button from './Button';
import HeaderProfile from './HeaderProfile';
import SubUserNavigation from './SubUserNavigation';
import { NavItem, NavSubItem } from './SideNavComponents';
import { useMerchantSelected } from 'core-modules/merchants/hooks';

function Navigation({ onSelect }) {
  const [activeAccount] = useActiveAccount();

  const { toggleEnvironment: promptSwitchProfile } =
    useToggleLiveTestEnvironment();
  const toggleEnvMode = (newMode) => {
    if (activeAccount.env_mode === newMode) return;
    promptSwitchProfile();
  };

  // const isWIP = useMemo(() => Boolean(localStorage.getItem('wip')), []);

  if (!activeAccount?.id) {
    return (
      <div className="p-4 text-center text-sm italic text-gray-500">
        Organization not found.
      </div>
    );
  }
  return (
    <nav className="flex-1 space-y-2 pt-5">
      <NavItem
        to="/dashboard"
        label="Dashboard"
        icon={MdSpaceDashboard}
        onClick={onSelect}
      />
      {(activeAccount?.is_org_kyc_completed ||
        activeAccount?.env_mode === 'TEST') && (
        <>
          <NavItem
            to="/get-paid"
            label="Invoices and Links"
            icon={MdReceipt}
            onClick={onSelect}
          >
            <NavSubItem
              label="Invoice"
              to="/get-paid/invoice"
              onClick={onSelect}
            />
            <NavSubItem
              label="Payment Links"
              to="/get-paid/payment-links"
              onClick={onSelect}
            />
          </NavItem>

          <NavItem
            to="/transactions"
            label="Transactions"
            icon={MdStickyNote2}
            onClick={onSelect}
          />
          <NavItem
            to="/merchants/profiles"
            label="Merchants"
            icon={MdStore}
            onClick={onSelect}
            data-tour="sidebar-merchant"
          />
        </>
      )}

      {(activeAccount?.is_org_kyc_completed ||
        activeAccount?.env_mode === 'TEST') && (
        <>
          <NavItem to="/payout" label="Payout" icon={GiReceiveMoney} />
          <NavItem
            to="/accounting"
            label="Accounting"
            icon={MdAccountBalanceWallet}
          >
            {/* <NavSubItem
              label="Ledger"
              to="/accounting/ledger"
              onClick={onSelect}
            /> */}
            <NavSubItem
              label="Refund"
              to="/accounting/refund"
              onClick={onSelect}
            />
          </NavItem>
          <NavItem
            to="/report"
            label="Reports"
            icon={MdDataExploration}
            onClick={onSelect}
          />
        </>
      )}
      <div className="!mt-8 ml-[24px] flex items-center space-x-2">
        <FormInlineToggle
          name="mode"
          theme="secondary"
          checked={activeAccount.env_mode === 'TEST'}
          onChange={(cb) => {
            const newMode = cb()?.mode;
            toggleEnvMode(newMode);
          }}
        />
        <p className="font-normal text-gray-600 sm:text-sm">View Test Mode</p>
      </div>
    </nav>
  );
}

Navigation.defaultProps = {
  onSelect: () => {},
};

Navigation.propTypes = {
  onSelect: PropTypes.func,
};

const MAP_BILL_ROUTES = {
  org_user: '/get-paid/invoice/create',
  sub_user: '/get-paid/bill/create',
};

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const history = useHistory();
  const [activeAccount] = useActiveAccount();
  const { userType, canRead } = useAccessControl();
  const [, currentMerchant] = useMerchantSelected();

  const isVerifiedOrTestMode = useMemo(
    () =>
      activeAccount?.is_org_kyc_completed || activeAccount?.env_mode === 'TEST',
    [activeAccount]
  );
  const isOrgUser = useMemo(() => userType === 'org_user', [userType]);
  const isSubUser = useMemo(() => userType === 'sub_user', [userType]);

  return (
    <>
      <button
        aria-label="bacddrop"
        type="button"
        className={cn(
          sidebarOpen
            ? 'fixed inset-0 !z-[49] h-full w-full bg-gray-900/50 backdrop-blur-md md:hidden'
            : 'hidden'
        )}
        onClick={() => setSidebarOpen(false)}
      />

      <div className={cn('sidebar', sidebarOpen && 'show-in-below-md')}>
        <button
          type="button"
          className={cn(
            'grid place-items-center rounded-full bg-white p-1 shadow-md md:hidden',
            'absolute left-full top-0 m-4 transition-transform hover:scale-110',
            !sidebarOpen && 'hidden'
          )}
          onClick={() => setSidebarOpen(false)}
          aria-label="icon"
        >
          <MdOutlineClose className="text-slate-500" size={25} />
        </button>
        <div className="flex flex-grow flex-col overflow-y-auto border-gray-200">
          <Link to="/">
            <ColoredLogoBeta className="ml-6 mt-10" />
          </Link>
          <div className="mt-4 flex flex-grow flex-col">
            {(isVerifiedOrTestMode && isOrgUser) ||
            (isSubUser && canRead('bills')) ? (
              <div className="ml-4">
                <Button
                  primary
                  icon={HiPlus}
                  onClick={() =>
                    history.push(
                      MAP_BILL_ROUTES[userType] ?? MAP_BILL_ROUTES.sub_user,
                      isSubUser
                        ? {
                            merchantLock: true,
                            merchant: {
                              label: currentMerchant.name,
                              value: currentMerchant.id,
                            },
                          }
                        : undefined
                    )
                  }
                >
                  Create Invoice
                </Button>
              </div>
            ) : null}
            {userType === 'org_user' && <Navigation />}
            {userType === 'sub_user' && <SubUserNavigation />}
          </div>
        </div>
        <div className="mb-4 border-t pt-2">
          <HeaderProfile />
        </div>
      </div>
    </>
  );
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};

export default Sidebar;
