import React from 'react';
import { BiHome } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Button from 'partial/components/Button';
import PrivacyNotice from 'modules/legal/components/PrivacyNotice';
import { ColoredLogo } from 'modules/common/components/AppIconLogo';

function PrivacyContainer() {
  const history = useHistory();

  return (
    <div className="relative">
      <div className="absolute z-[1] flex w-full items-center justify-between border-b px-5 py-4 shadow">
        <div>
          <Button primary icon={BiHome} onClick={() => history.push('/')}>
            Back to login page
          </Button>
        </div>
        <ColoredLogo />
      </div>
      <div className="mx-5 mt-28 mb-10 flex justify-center">
        <PrivacyNotice />
      </div>
    </div>
  );
}

export default PrivacyContainer;
