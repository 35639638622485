import React from 'react';
import * as yup from 'yup';
import { each } from 'lodash';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { HiOutlineMail } from 'react-icons/hi';
// import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useLocation } from 'react-router-dom';

import analytics from 'utils/analytics';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import FormPassword from 'partial/form/FormPassword';
import Form, { ErrorLabel } from 'partial/form/Form';

import { useLogin } from '../hooks';
import { useGoogleRecaptcha } from 'partial/components/GoogleRecaptcha';

const initialValues = (location) => ({
  email_address: location?.state?.email_address || '',
  password: '',
});

const validationSchema = yup.object().shape({
  email_address: yup.string().email('Invalid').required('Required'),
  password: yup.string().required('Required'),
});

function LoginForm({ onLogin }) {
  const location = useLocation();
  const [isLoading, login] = useLogin();
  const [validateOnChange, setValidateOnChange] = React.useState(false);

  const { executeAsync } = useGoogleRecaptcha();

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: initialValues(location),
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      setValidateOnChange(true);
      const captcha = await executeAsync();
      const payload = {
        ...form,
        captcha,
      };
      login(payload, onLogin, (err) => {
        each(err, (v, k) => {
          setFieldError(k, v);
        });
      });
    },
    validateOnChange,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      error={errors}
      noValidate
      className="space-y-3"
    >
      <div>
        <FormInput
          type="email"
          icon={HiOutlineMail}
          name="email_address"
          onSetFieldValue={setFieldValue}
          value={values.email_address}
          label="Email Address"
          error={errors.email_address}
          required
        />
        <ErrorLabel name="email_address" />
      </div>
      <div>
        <FormPassword
          name="password"
          label="Password"
          onSetFieldValue={setFieldValue}
          value={values.password}
          error={errors.password}
          noAutoFill
          required
        />
        <ErrorLabel name="password" />
      </div>
      <Link
        type="button"
        className="inline-block border-gray-400 font-normal text-gray-600"
        to="/password_reset"
        onClick={() => analytics.navigate('/password_reset')}
      >
        Forgot Password?
      </Link>
      <Button
        size="xxl"
        primary
        type="submit"
        className="!mt-8 w-full"
        disabled={isLoading}
      >
        Sign in
      </Button>
      <div className="!mt-8 space-x-1">
        <span>Don&apos;t have an account?</span>
        <Link
          className="inline-block"
          to="/signup"
          onClick={() => analytics.navigate('/signup')}
        >
          Sign Up Now
        </Link>
      </div>
    </Form>
  );
}

LoginForm.defaultProps = {
  onLogin: () => {},
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
};

export default LoginForm;
