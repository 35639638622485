import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import BreadCrumb from 'partial/components/BreadCrumb';
import PageWrapper from 'partial/components/PageWrapper';

import MerchantProfiles from './MerchantProfiles';
import MerchantDashboard from './MerchantDashboard';

function MerchantRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <BreadCrumb>Merchants</BreadCrumb>
      <PageWrapper>
        <Switch>
          <Route
            path={`${match.path}/dashboard`}
            component={MerchantDashboard}
          />
          <Route path={`${match.path}/profiles`} component={MerchantProfiles} />
          <Redirect to={`${match.url}/dashboard`} />
        </Switch>
      </PageWrapper>
    </>
  );
}

export default MerchantRoutes;
