import React from 'react';
import PropTypes from 'prop-types';
import CountCard from 'partial/dashboard/CountCard';

import TotalPaymentIconImg from 'assets/images/icons/icon-get-paid.svg';
// import UnpaidAmountIconImg from 'assets/images/icons/icon-unpaid.svg';

import { useCountCard } from '../hooks';
import { createIconFromImage } from 'helper';

const TotalPaymentIcon = createIconFromImage({
  url: TotalPaymentIconImg,
  alt: 'total payment',
});
// const UnpaidAmountIcon = createIconFromImage({
//   url: UnpaidAmountIconImg,
//   alt: 'total payment',
// });

function PaymentLinksCountCards({ otherFilter: filter }) {
  return (
    <div className="grid grid-cols-1 gap-2 overflow-hidden sm:grid-cols-2">
      <div className="rounded-xl border bg-white">
        <CountCard
          name="total_paid_payment_links"
          label="Total Payments"
          icon={TotalPaymentIcon}
          hook={useCountCard}
          filter={filter}
          defaultValue={{
            value: '--',
            meta: {
              sub_value: '--',
              sub_value_theme: 'default',
            },
          }}
        />
      </div>
      {/* <div className="rounded-xl border bg-white">
        <CountCard
          name="total_unpaid_payment_links"
          label="Unpaid Amount"
          icon={UnpaidAmountIcon}
          hook={useCountCard}
          filter={filter}
          defaultValue={{
            value: '--',
            meta: {
              sub_value: '--',
              sub_value_theme: 'default',
            },
          }}
        />
      </div> */}
    </div>
  );
}

PaymentLinksCountCards.propTypes = {
  otherFilter: PropTypes.instanceOf(Object),
};

PaymentLinksCountCards.defaultProps = {
  otherFilter: {},
};

export default PaymentLinksCountCards;
