import React from 'react';
// import PropTypes from 'prop-types';
import FormAsyncSelect from 'partial/form/FormAsyncSelect';
import { loadMerchantOptions } from '../actions';

function FormMerchantOptions(props) {
  return (
    <FormAsyncSelect loadOptions={loadMerchantOptions} showIcon {...props} />
  );
}

FormMerchantOptions.propTypes = {};

export default FormMerchantOptions;
