import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { MAP_FORM_DESC_THEME_CLASSNAMES } from './constants';

function FormDescription({ label, theme }) {
  const themeClass =
    MAP_FORM_DESC_THEME_CLASSNAMES[theme] ||
    MAP_FORM_DESC_THEME_CLASSNAMES.default;
  return (
    <div
      className={cn(
        'text-xs -mt-1 relative z-0 rounded-b px-2 pb-2 pt-3',
        themeClass
      )}
    >
      {label}
    </div>
  );
}

FormDescription.defaultProps = {
  theme: 'default',
};

FormDescription.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['default', 'info']),
};

export default FormDescription;
