import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { isEmpty } from 'lodash';
import { useOnClickOutside } from 'helper';
import { useInputId } from './hooks';

function FormReactSelect({
  name,
  label,
  value,
  onChange,
  onSetFieldValue,
  options,
  isMulti,
  isLoadingOptions,
}) {
  const containerRef = useRef();
  const selectRef = useRef();
  const [id] = useInputId();

  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const valueIsEmpty = useMemo(() => isEmpty(value), [value]);
  const labelIsOnTop = useMemo(
    () => isFocused || !valueIsEmpty,
    [isFocused, valueIsEmpty]
  );

  const handleChange = (val) => {
    if (typeof onChange === 'function') {
      onChange((prev) => ({ ...prev, [name]: val }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, val);
    }
  };

  const isError = false;

  useOnClickOutside(containerRef, () => {
    setIsMenuOpen(false);
    setIsFocused(false);
  });
  return (
    <div ref={containerRef} className="relative isolate z-[1]">
      {!isMenuOpen && (
        <button
          aria-label="focus"
          type="button"
          className="absolute inset-0 z-10 h-full w-full"
          onClick={() => {
            selectRef?.current?.focus();
            setIsMenuOpen(true);
          }}
        />
      )}
      {isLoadingOptions && (
        <AiOutlineLoading3Quarters className="absolute top-1 left-1 z-10 h-3 w-3 animate-spin text-primary-400" />
      )}
      <div
        className={cn(
          'w-full rounded-md border px-4 pb-4 pt-[2.125rem] focus-within:ring-2',
          isError
            ? '!border-red-300 focus-within:!border-red-500 focus:!ring-red-300'
            : '!border-gray-200 focus-within:!border-primary-600 focus-within:!ring-primary-400'
        )}
      >
        <Select
          ref={selectRef}
          id={id}
          name={name}
          value={value}
          options={options}
          isMulti={isMulti}
          menuIsOpen={isMenuOpen}
          placeholder=""
          onChange={(val) => {
            handleChange(val);
            setIsMenuOpen(false);
          }}
          onFocus={() => setIsFocused(true)}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            input: (provided) => ({
              ...provided,
              fontSize: '1rem',
              lineHeight: '1rem',
              height: '1.25rem',
              fontWeight: 'bold',
              padding: 0,
              margin: 0,
            }),
            singleValue: (provided) => ({
              ...provided,
              margin: 0,
              height: '1.25rem',
              overflow: 'visible',
              fontSize: '1rem',
            }),
            control: () => ({}),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
              margin: 0,
              fontWeight: 'bold',
              fontSize: '1rem',
              height: '1.25rem',
              overflow: 'visible',
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              position: 'absolute',
              top: '-1.4rem',
              right: '-1rem',
              cursor: 'pointer',
            }),
          }}
        />
      </div>
      <label
        htmlFor={id}
        className={cn(
          'no-highlight pointer-events-none absolute left-4 -translate-y-1/2 transition-all',
          labelIsOnTop
            ? 'top-[1.625rem] text-xs text-gray-600'
            : 'top-1/2 bg-transparent text-sm text-gray-400'
        )}
      >
        <span>{label}</span>
      </label>
    </div>
  );
}

FormReactSelect.defaultProps = {
  isLoadingOptions: false,
  isMulti: false,
  onChange: false,
  onSetFieldValue: false,
};

FormReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  options: PropTypes.instanceOf(Object).isRequired,
  isLoadingOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default FormReactSelect;
