import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Button from 'partial/components/Button';
import { toastSuccess } from 'react-geek-toast';
import FormPassword from 'partial/form/FormPassword';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { showDrawer } from 'partial/components/Modal';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
// eslint-disable-next-line import/no-cycle
import { useChangePassword } from '../hooks';

export const openChangePassword = (
  defaultValue = {},
  onSuccess = () => {},
  onCancel = () => {}
) => {
  showDrawer({
    size: 'xs',
    title: defaultValue?.old_password ? 'Set Password' : 'Change Password',
    onClose: onCancel,
    content: (onClose) => (
      <ChangePasswordModal
        defaultValue={defaultValue}
        onSuccess={onSuccess}
        onClose={onClose}
      />
    ),
  });
};

const initialValues = {
  old_password: '',
  password: '',
  password_confirmation: '',
};

const validationSchema = yup.object().shape({
  old_password: yup.string().required('Required'),
  password: yup.string().required('Required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Does not match')
    .required('Required'),
});

function ChangePasswordModal({ defaultValue, onSuccess, onClose }) {
  const [isLoading, submit] = useChangePassword();
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: { ...initialValues, ...defaultValue },
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      setValidateOnChange(true);
      submit(
        form,
        () => {
          toastSuccess('Password has been updated!');
          onClose();
          onSuccess();
        },
        (err) => {
          setError422(err, setFieldError);
        }
      );
    },
    validateOnChange,
  });
  return (
    <Form
      error={errors}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="space-y-3"
    >
      {!defaultValue?.old_password ? (
        <div>
          <FormPassword
            name="old_password"
            label="Current Password"
            required
            onSetFieldValue={setFieldValue}
            value={values?.old_password}
            error={errors?.old_password}
          />
          <ErrorLabel name="old_password" />
        </div>
      ) : (
        <div className="bg-blue-100 p-4 rounded-md text-blue-600 flex space-x-2">
          <HiOutlineInformationCircle className="w-6 h-6 flex-shrink-0" />
          <div className="">
            <p>
              Create a strong base password that will be used for{' '}
              <b>PRODUCTION MODE</b> for additional security on your account.
            </p>
          </div>
        </div>
      )}
      <div>
        <FormPassword
          name="password"
          label="New Password"
          required
          onSetFieldValue={setFieldValue}
          value={values?.password}
          error={errors?.password}
        />
        <ErrorLabel name="password" />
      </div>
      <div>
        <FormPassword
          name="password_confirmation"
          label="Confirm Password"
          required
          onSetFieldValue={setFieldValue}
          value={values?.password_confirmation}
          error={errors?.password_confirmation}
        />
        <ErrorLabel name="password_confirmation" />
      </div>
      <div className="mt-2 pt-2 border-t flex justify-end space-x-2">
        {!defaultValue?.old_password ? (
          <Button className="mr-2" onClick={onClose} disabled={isLoading}>
            Close
          </Button>
        ) : null}
        <Button primary type="submit" disabled={isLoading}>
          Save Password
        </Button>
      </div>
    </Form>
  );
}

ChangePasswordModal.propTypes = {
  defaultValue: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
