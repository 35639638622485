import GetStartedModal from './components/GetStartedModal';
import CreateMerchantTour from './components/CreateMerchantTour';
import HelpEndTour from './components/HelpEndTour';
import SetThemeTour from './components/SetThemeTour';
import SetChannelTour from './components/SetChannelTour';
import CreateTransactionModal from './components/CreateTransactionModal';
import MerchantDashboardTour from './components/MerchantDashboardTour';
import InvoiceTour from './components/InvoiceTour';
import ApiIntegrationTour from './components/ApiIntegrationTour';
import TestPaymentTour from './components/TestPaymentTour';
import CreateMerchantModal from './components/CreateMerchantModal';
import SetThemeModal from './components/SetThemeModal';

export const DETAILS = {
  'get-started-modal': {
    id: 'get-started-modal',
    label: 'Get started',
    type: 'modal',
    component: GetStartedModal,
    onSkip: 'help-end',
  },
  'merchant-modal': {
    id: 'merchant-modal',
    label: 'Intro Merchant',
    type: 'modal',
    component: CreateMerchantModal,
    onSkip: 'help-end',
  },
  'merchant-tour': {
    id: 'merchant-tour',
    label: 'How to create merchant',
    type: 'tour',
    component: CreateMerchantTour,
    onSkip: 'help-end',
  },
  'theme-modal': {
    id: 'theme-modal',
    label: 'Intro Theme',
    type: 'modal',
    component: SetThemeModal,
    onSkip: 'help-end',
  },
  'theme-tour': {
    id: 'theme-tour',
    label: 'How to change themes',
    type: 'tour',
    component: SetThemeTour,
    onSkip: 'help-end',
  },
  'set-channels': {
    id: 'set-channels',
    label: 'How to set which channels are shown',
    type: 'tour',
    component: SetChannelTour,
    onSkip: 'help-end',
  },
  'create-transaction': {
    id: 'create-transaction',
    label: 'Get started',
    type: 'modal',
    component: CreateTransactionModal,
    onSkip: 'help-end',
  },
  'merchant-dashboard': {
    id: 'merchant-dashboard',
    label: 'View merchant performance',
    type: 'tour',
    component: MerchantDashboardTour,
    onSkip: 'help-end',
  },
  'create-invoice': {
    id: 'create-invoice',
    label: 'How to send Invoice',
    type: 'tour',
    component: InvoiceTour,
    onSkip: 'help-end',
  },
  'integrate-api': {
    id: 'integrate-api',
    label: 'How to integrate via API',
    type: 'tour',
    component: ApiIntegrationTour,
    onSkip: 'help-end',
  },
  'test-payment': {
    id: 'test-payment',
    label: 'How to test payment',
    type: 'tour',
    component: TestPaymentTour,
    onSkip: 'help-end',
  },
  'help-end': {
    id: 'help-end',
    label: 'Open help',
    type: 'tour',
    component: HelpEndTour,
    onSkip: null,
  },
};

export const TOUR_STEPS = {
  'get-started-modal': [
    DETAILS['get-started-modal'],
    DETAILS['merchant-modal'],
    DETAILS['theme-modal'],
    DETAILS['set-channels'],
    DETAILS['create-transaction'],
    DETAILS['merchant-dashboard'],
    DETAILS['help-end'],
  ],
};

export const HELP_OPTIONS = ['get-started-modal'].map((k) => DETAILS[k]);
