import React from 'react';
// import PropTypes from 'prop-types';
import { BsShieldLock } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { HiCheck, HiLockClosed } from 'react-icons/hi';
import placeholder from 'assets/images/placeholder.svg';
import Background from '../components/Background';
import { useThemePreview } from '../hooks';

function Preview() {
  const location = useLocation();
  const theme = useThemePreview(location.hash);
  return (
    <Background
      className="bg-gradient-to-tr from-theme-preview-brand-100 to-theme-preview-brand-500 p-10"
      code={theme?.bg}
      color={theme?.color_bg}
    >
      <div style={{ zoom: '80%' }}>
        <div className="relative mx-auto max-w-4xl">
          <div className="absolute inset-0" />
          <div className="mx-auto grid grid-cols-1 gap-0 sm:grid-cols-8 sm:gap-5">
            <div className="col-span-8 bg-gray-100 sm:col-span-5">
              <div className="mb-6 grid grid-cols-3 bg-white text-center">
                <div className="flex items-center justify-center space-x-2 border-b-2 border-t-2 py-3">
                  <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-theme-preview-accent-500 text-center">
                    <HiCheck className="mx-auto h-4 w-4 flex-shrink-0 text-white" />
                  </div>
                  <div className="truncate text-left text-xs font-bold uppercase tracking-wider text-gray-500">
                    Cart
                  </div>
                </div>
                <div className="flex items-center justify-center space-x-2 border-b-2 border-theme-preview-accent-500 py-3">
                  <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-theme-preview-accent-500 text-center">
                    <span className="mx-auto font-bold text-white">2</span>
                  </div>
                  <div className="truncate text-left text-xs font-bold uppercase tracking-wider text-gray-500">
                    Cash Payments
                  </div>
                </div>
                <div className="flex items-center justify-center space-x-2 border-b-2 border-t-2 py-3">
                  <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-white text-center">
                    <span className="mx-auto font-bold text-theme-preview-accent-500">
                      3
                    </span>
                  </div>
                  <div className="truncate text-left text-xs font-bold uppercase tracking-wider text-gray-300">
                    Confirmation
                  </div>
                </div>
              </div>
              <div className="mx-4 flex space-x-1">
                <BsShieldLock className="h-5 w-5 flex-shrink-0 text-gray-300" />
                <div className="">
                  <div className="text-sm font-bold text-gray-500">
                    Secure Checkout Payment
                  </div>
                  <div className="text-xs text-gray-500">
                    Transactions are secured and encrypted
                  </div>
                </div>
              </div>
              <div className="mt-4 rounded-lg p-5">
                <div className="mb-3 text-xs font-bold text-gray-500">
                  Select Payment Method
                </div>
                <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-theme-preview-accent-500 bg-theme-preview-accent-50 p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-500">Cash Payments</div>
                  </div>
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Banks</div>
                  </div>
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">eWallet</div>
                  </div>
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">
                      Visa / Mastercard
                    </div>
                  </div>
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Online Banking</div>
                  </div>
                  <div className="flex flex-row items-center space-x-3 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-8 w-8 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Kiosk</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-8 bg-white sm:col-span-3">
              <div className="bg-theme-preview-accent-600 p-4 text-white">
                <div className="text-sm font-bold">Your Transaction</div>
                <div className="text-xs">
                  Please verify all information below:
                </div>
              </div>
              <div className="px-5 pb-5">
                <div className="my-3 space-y-2 py-3 text-center">
                  <div className="mx-auto flex h-12 w-12 items-center overflow-hidden rounded-lg bg-white">
                    <img
                      src={theme?.source_photo || placeholder}
                      alt=""
                      className="object-contain"
                    />
                  </div>
                  <div className="text-xs font-semibold tracking-wide text-gray-500">
                    {theme?.source || 'Merchant'}
                  </div>
                </div>
                <div className="space-y-5">
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Amount</div>
                    <div className="text-xs font-bold text-gray-500">
                      ₱{theme?.amount || '0.00'}
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Status</div>
                    <div className="badge badge-gray py-2">For Payment</div>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Total Amount</div>
                    <div className="text-md font-bold text-theme-preview-accent-500">
                      ₱{theme?.amount || '0.00'}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="my-3 pt-3">
                    <div className="text-center text-xs text-gray-500">
                      By clicking <b>Pay Now</b>, you agree with{' '}
                      <span className="underline">Terms and Conditions</span>{' '}
                      and <span className="underline">Data Privacy</span>
                    </div>
                  </div>
                  <div className="shadow-theme-preview-accent-md mt-4 flex items-center justify-center space-x-2 rounded-md bg-theme-preview-accent-500 py-3 text-center text-xs font-semibold text-white">
                    <span>Pay Now</span>
                    <HiLockClosed className="h-4 w-4 flex-shrink-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-2xs mx-auto mb-3 mt-4 max-w-4xl text-center sm:text-left">
          <div className="text-xs text-black/40">
            Powered by [Merchant Name]
          </div>
          <div className="text-xs text-black/40">
            &copy; 2021 All rights reserved.
          </div>
        </div>
      </div>
    </Background>
  );
}

Preview.propTypes = {};

export default Preview;
