import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
  useParams,
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useActiveAccount } from 'modules/auth/hooks';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import NavTab, { NavTabItem } from 'partial/components/NavTab';

import BreadCrumb from 'partial/components/BreadCrumb';
import KycContainer from 'core-modules/merchant-onboarding';
import AclUsers from 'core-modules/users/containers/AclUsers';
import RedirectToRoot from 'modules/common/components/RedirectToRoot';
// import InvoiceRoutes from 'core-modules/invoice/containers/InvoiceRoutes';
import InvoiceContainer from 'core-modules/invoice/containers/InvoiceContainer';
import ReportsContainer from 'core-modules/reports/containers/ReportsContainer';
import TransactionTable from 'core-modules/dashboard/components/TransactionTable';
import ApiKeysContainer from 'core-modules/developers/containers/ApiKeysContainer';
import { useOnToggleEnvironmentEffect } from 'modules/auth/modals/SwitchProfileModal';
import IntegrationDashboard from 'core-modules/developers/containers/IntegrationDashboard';

import MerchantCms from './MerchantCms';
import MerchantMisc from './MerchantMisc';
import { useMerchantSelected, useSelectMerchant } from '../hooks';
import MerchantDashboard from './MerchantDashboard';
import MerchantHeader from '../components/MerchantHeader';
import MerchantDetail from '../components/MerchantDetail';
import MerchantChannels from '../components/MerchantChannels';

function MerchantProfile({ parentUrl }) {
  const history = useHistory();
  const match = useRouteMatch();
  const { merchant_id } = useParams();

  const [activeAccount] = useActiveAccount();
  const [, data] = useMerchantSelected();

  const invoiceFilter = React.useMemo(
    () => ({
      merchant_uuid: data.id,
    }),
    [data]
  );
  const transactionFilter = React.useMemo(
    () => ({
      merchant_uuid: data.id,
    }),
    [data]
  );

  useSelectMerchant(merchant_id);

  useEffect(() => {
    if (!data?.id) return;
    if (
      data.approval_status !== 'APPROVED' &&
      activeAccount?.env_mode === 'LIVE'
    ) {
      history.push(`${match?.url}/details`);
    }
  }, [data, history, match.url, activeAccount]);

  useOnToggleEnvironmentEffect(() => {
    history.push(parentUrl);
  });

  return (
    <>
      <BreadCrumb>{data?.name ?? '-'}</BreadCrumb>
      <div className="flex h-full flex-col">
        <Link
          to={parentUrl}
          className="flex items-center text-sm font-normal text-black hover:text-primary-500"
        >
          <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
          Go Back
        </Link>
        <div className="mt-4 flex h-0 flex-grow flex-col rounded-md border">
          <div className="sticky left-0 right-0 top-0 z-10 bg-white px-2 pt-2 md:px-6 md:pt-6">
            <MerchantHeader data={data} />
            {data?.approval_status === 'APPROVED' ||
            activeAccount?.env_mode === 'TEST' ? (
              <div className="mb-2 overflow-y-auto border-b border-gray-200 bg-white">
                <NavTab>
                  <NavTabItem to={`${match.url}/dashboard`} label="Dashboard" />
                  {activeAccount?.env_mode === 'LIVE' ? (
                    <NavTabItem to={`${match.url}/details`} label="Profile" />
                  ) : null}
                  <NavTabItem
                    to={`${match.url}/transactions`}
                    label="Transactions"
                  />
                  <NavTabItem to={`${match.url}/reports`} label="Reports" />
                  <NavTabItem to={`${match.url}/channels`} label="Channels" />
                  <NavTabItem to={`${match.url}/invoice`} label="Invoice" />
                  <NavTabItem
                    to={`${match.url}/integration`}
                    label="API Integration"
                  />
                  <NavTabItem
                    to={`${match.url}/cms`}
                    label="Themes"
                    count={!data?.theme?.theme ? '!' : ''}
                    data-tour="merchant-tab-theme"
                  />
                  <NavTabItem to={`${match.url}/users`} label="Users" />
                </NavTab>
              </div>
            ) : null}
          </div>
          {data?.id ? (
            <div className="relative isolate mt-4 flex flex-1 flex-col overflow-auto px-2 pb-2 md:px-6 md:pb-6">
              <Switch>
                <Route
                  path={`${match.path}/dashboard`}
                  component={MerchantDashboard}
                />
                {activeAccount?.env_mode === 'LIVE' ? (
                  <Route path={`${match.path}/details`}>
                    {data.approval_status !== 'APPROVED' ? (
                      <KycContainer key={data?.id} merchant={data} />
                    ) : (
                      <MerchantDetail data={data} />
                    )}
                  </Route>
                ) : null}
                <Route path={`${match.path}/integration`}>
                  <div className="space-y-4">
                    <ApiKeysContainer />
                    {data?.id ? <IntegrationDashboard merchant={data} /> : null}
                  </div>
                </Route>
                <Route path={`${match.path}/transactions`}>
                  <div className="h-0 flex-grow">
                    <div className="flex h-full flex-col">
                      <TransactionTable
                        filterMerchant={false}
                        filter={transactionFilter}
                      />
                    </div>
                  </div>
                </Route>
                <Route path={`${match.path}/reports`}>
                  <ReportsContainer merchantId={data?.id} />
                </Route>
                <Route path={`${match.path}/channels`}>
                  <MerchantChannels merchantId={data?.id} />
                </Route>
                <Route path={`${match.path}/invoice`}>
                  <InvoiceContainer filter={invoiceFilter} />
                </Route>
                <Route path={`${match.path}/users`}>
                  <AclUsers merchantId={data?.id} />
                </Route>
                <Route path={`${match.path}/cms`}>
                  <MerchantCms key={data?.id} data={data} />
                </Route>
                {data?.approval_status !== 'APPROVED' &&
                  activeAccount?.env_mode === 'LIVE' && (
                    <Route path={`${match.path}/misc`}>
                      <MerchantMisc data={data} />
                    </Route>
                  )}
                <Redirect to={`${match.url}/dashboard`} />
              </Switch>
              {/* redirect control */}
              <Switch>
                <Route path={`${match.path}/invoice/:id`}>
                  <RedirectToRoot
                    reference={data?.id}
                    root={`${match.path}/invoice`}
                  />
                </Route>
              </Switch>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

MerchantProfile.propTypes = {
  parentUrl: PropTypes.string.isRequired,
};

export default MerchantProfile;
