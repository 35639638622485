import React from 'react';
import PropTypes from 'prop-types';
import { abbrNum, formatNumber } from 'helper';
import ReactApexCharts from 'react-apexcharts';
import { truncate } from 'lodash';

function BarChart({
  label: name,
  data,
  valueKey,
  horizontal,
  isLoading,
  decimalValue,
}) {
  const options = {
    xaxis: {
      categories: (data || []).map((item, i) => item?.title || `Data ${i + 1}`),
      labels: {
        formatter: (value) => abbrNum(value, 2),
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => truncate(value, { length: 15 }),
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal,
      },
    },
    dataLabels: {
      enabled: false,
      formatter(val) {
        return [`${formatNumber(val, decimalValue)}`];
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#10335f', '#184f92', '#216bc5', '#ACCBF1', '#e4ecf8'],
  };

  const series = [
    {
      name,
      data: (data || []).map((item) => item[valueKey] ?? null),
    },
  ];
  if (isLoading) {
    if (!horizontal) {
      return (
        <div className="flex h-full w-full items-end space-x-3 p-4">
          <div className="h-full w-full animate-pulse rounded bg-gray-200" />
          <div className="h-5/6 w-full animate-pulse rounded bg-gray-200" />
          <div className="h-3/6 w-full animate-pulse rounded bg-gray-200" />
          <div className="h-2/5 w-full animate-pulse rounded bg-gray-200" />
          <div className="h-1/6 w-full animate-pulse rounded bg-gray-200" />
        </div>
      );
    }
    return (
      <div className="space-y-3 p-4">
        <div className="h-10 w-full animate-pulse rounded bg-gray-200" />
        <div className="h-10 w-5/6 animate-pulse rounded bg-gray-200" />
        <div className="h-10 w-3/6 animate-pulse rounded bg-gray-200" />
        <div className="h-10 w-2/5 animate-pulse rounded bg-gray-200" />
        <div className="h-10 w-1/6 animate-pulse rounded bg-gray-200" />
      </div>
    );
  }
  return (
    <ReactApexCharts
      height="100%"
      options={options}
      series={series}
      type="bar"
    />
  );
}
BarChart.defaultProps = {
  label: 'Count',
  valueKey: 'count',
  data: {
    labels: [],
    series: [],
  },
  horizontal: true,
  isLoading: false,
  decimalValue: 0,
};

BarChart.propTypes = {
  label: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  valueKey: PropTypes.string,
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  decimalValue: PropTypes.number,
};

export default BarChart;
