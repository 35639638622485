export const getUserTypeFromToken = (token) => {
  if (!token) return null;
  return 'ADMINISTRATOR';
  // return 'ADMIN_USER';
};

export const transformProfile = (raw) => ({
  id: raw.uuid,
  email_address: raw.email_address || '- no email -',
  env_mode: raw?.environment_type,
  is_first_time: +raw?.user?.is_first_time_login === 1,
  first_name: raw?.contact_first_name,
  last_name: raw?.contact_last_name,
});

const MAP_ENVIRONMENT_TYPE = {
  TEST: 'Test',
  PROD: 'Production',
};

export const transformAccount = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || 'Untitled',
  label: `${raw?.name} (${
    MAP_ENVIRONMENT_TYPE[raw?.environment_type] || raw?.environment_type
  })`,
  logo: raw?.logo,
  env_mode: raw?.environment_type,
  is_active: raw?.status === 'ACTIVE',
  is_org_kyc_completed: Boolean(parseInt(raw?.is_org_kyc_completed, 10)),

  address: raw?.address || '',
  representative_name: raw?.representative_name || '',
  representative_first_name: raw?.representative_first_name || '',
  representative_last_name: raw?.representative_last_name || '',
  representative_contact_number: raw?.representative_contact_number || '',

  owner_photo_url: raw?.owner_photo_url || '',
  owner_first_name: raw?.owner_first_name || '',
  owner_middle_name: raw?.owner_middle_name || '',
  owner_last_name: raw?.owner_last_name || '',
  owner_suffix: raw?.owner_suffix || '',
  owner_name: `${raw?.owner_first_name} ${
    raw?.owner_middle_name ? raw?.owner_middle_name : ' '
  } ${raw?.owner_last_name} ${raw?.owner_suffix ? raw?.owner_suffix : ''}`,
  owner_birth_date: raw?.owner_birth_date || '',
  owner_valid_id_type: raw?.owner_valid_id_type || '',
  owner_valid_id_url: raw?.owner_valid_id_url || '',

  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',

  is_org_all_kyc_submitted:
    !!raw?.meta_data?.is_org_profile_submitted &&
    !!raw?.meta_data?.is_first_merchant_general_submitted &&
    !!raw?.meta_data?.is_first_merchant_attachment_submitted &&
    !!raw?.meta_data?.is_org_bank_details_submitted,
  meta: {
    is_first_live_data: !!raw?.meta_data?.is_first_live_data,
    is_org_profile_submitted: !!raw?.meta_data?.is_org_profile_submitted,
    is_first_merchant_general_submitted:
      !!raw?.meta_data?.is_first_merchant_general_submitted,
    is_first_merchant_attachment_submitted:
      !!raw?.meta_data?.is_first_merchant_attachment_submitted,
    is_org_bank_details_submitted:
      !!raw?.meta_data?.is_org_bank_details_submitted,
  },
});

export const payloadAccount = (payload) => ({
  name: payload.name,
  logo: payload.logo,

  // owner_photo_url: payload.owner_photo_url,
  // owner_first_name: payload.owner_first_name,
  // owner_middle_name: payload.owner_middle_name,
  // owner_last_name: payload.owner_last_name,
  // owner_birth_date: payload.owner_birth_date,
  // owner_valid_id_url: payload.owner_valid_id_url,

  address: payload.address,
  representative_first_name: payload.representative_first_name,
  representative_last_name: payload.representative_last_name,
  representative_contact_number: payload.representative_contact_number,

  bank_name: payload.bank_name,
  bank_account_number: payload.bank_account_number,
  bank_account_name: payload.bank_account_name,
});

export const payloadAccountBank = (payload) => ({
  bank_name: payload.bank_name,
  bank_account_number: payload.bank_account_number,
  bank_account_name: payload.bank_account_name,
});
