import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { AlertCard, useCreateModal } from 'partial/components/Modal';

export const useOpenIdleTimoutModal = (
  options = { countdownTimeInSeconds: 20 }
) => {
  const createModal = useCreateModal();

  const openIdleTimeoutModal = useCallback(() => {
    createModal({
      content: (close) => (
        <IdleTimeOutModal
          countdownTimeInSeconds={options?.countdownTimeInSeconds ?? 20}
          onStaySignedIn={close}
        />
      ),
    });
  }, [createModal, options.countdownTimeInSeconds]);

  return openIdleTimeoutModal;
};

function IdleTimeOutModal({ countdownTimeInSeconds, onStaySignedIn }) {
  const history = useHistory();
  const [remainingTime, setRemainingTime] = useState(countdownTimeInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, [1000]);
    return () => {
      clearInterval(interval);
    };
  }, [setRemainingTime]);

  useEffect(() => {
    if (!remainingTime) history.push('/logout');
  }, [remainingTime, history]);

  return (
    <AlertCard
      noLabel="Logout"
      onNo={() => history.push('/logout')}
      yesLabel={`Stay Logged In (${remainingTime})`}
      onYes={onStaySignedIn}
      focusTarget="yes"
      primaryYesBtn
    >
      <div className="p-2 space-y-2">
        <h1 className="text-lg font-medium text-gray-900">Session Timeout</h1>
        <p className="text-gray-500">
          You&apos;re being timed out due to inactivity. Please choose to stay
          signed in or logout. Otherwise, you will be logged out automatically
        </p>
      </div>
    </AlertCard>
  );
}

IdleTimeOutModal.propTypes = {
  countdownTimeInSeconds: PropTypes.number.isRequired,
  onStaySignedIn: PropTypes.func.isRequired,
};
