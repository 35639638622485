import { removeEmpty } from 'helper';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';

import { useAccountMeta } from 'modules/auth/hooks';
import { showAlert } from 'partial/components/Modal';

import * as c from './constants';
import * as actions from './actions';

export const useInvoiceList = (params) => {
  const isLoading = useApiLoading(c.INVOICE_LIST, 'get');
  const list = useApiGet(c.INVOICE_LIST, []);
  const pager = useApiGet(c.INVOICE_PAGER, {});
  useEffect(() => {
    actions.listInvoice(removeEmpty(params));
    return () => {
      req.set(c.INVOICE_LIST, null);
      req.set(c.INVOICE_PAGER, null);
      req.set(c.INVOICE_FILTER, null);
    };
  }, [params]);
  const appendList = useCallback(
    (page) => {
      actions.appendListInvoice({ ...removeEmpty(params), page });
    },
    [params]
  );
  return [isLoading, list, pager, appendList];
};

export const useSelectInvoice = (id, onSuccess) => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'get');
  const data = useApiGet(c.INVOICE_ITEM, {});
  const onSuccessRef = useRef(onSuccess);
  useEffect(() => {
    onSuccessRef.current = onSuccess;
  }, [onSuccess]);
  const fetchData = useCallback(() => {
    actions.selectInvoice(id, onSuccessRef?.current);
  }, [id]);
  useEffect(fetchData, [fetchData]);
  return [isLoading, data, fetchData];
};

export const useInvoiceLogsList = (id, params) => {
  const isLoading = useApiLoading(c.INVOICE_LOG_LIST, 'get');
  const list = useApiGet(c.INVOICE_LOG_LIST, []);
  const pager = useApiGet(c.INVOICE_LOG_PAGER, {});
  useEffect(() => {
    actions.listInvoiceLogs(id, params);
  }, [id, params]);
  useEffect(() => {
    return () => {
      req.set(c.INVOICE_LOG_LIST, null);
      req.set(c.INVOICE_LOG_PAGER, null);
      req.set(c.INVOICE_LOG_FILTER, null);
    };
  }, []);
  return [isLoading, list, pager];
};

export const useCreateInvoice = () => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'post');
  const { meta, update } = useAccountMeta();
  const submit = useCallback(
    (payload, onSuccess) => {
      actions.createInvoice(payload, (v) => {
        if (!meta?.is_first_invoice_created)
          update({ is_first_invoice_created: true });
        if (onSuccess) onSuccess(v);
      });
    },
    [meta, update]
  );
  return [isLoading, submit];
};

export const useUpdateInvoice = () => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'put');
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateInvoice(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useRemoveInvoice = () => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'remove');
  const submit = useCallback((id, onSuccess) => {
    showAlert({
      title: 'Delete Invoice',
      yesLabel: 'Delete',
      isLoadingKey: c.INVOICE_ITEM,
      isLoadingMethod: 'remove',
      onYes: (closeAlert) => {
        actions.removeInvoice(id, (res) => {
          closeAlert();
          onSuccess(res);
        });
      },
    });
  }, []);
  return [isLoading, submit];
};

export const useSubmitInvoice = () => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'put');
  const submit = useCallback((id, onSuccess) => {
    actions.submitInvoice(id, onSuccess);
    // showAlert({
    //   title: 'Confirmation',
    //   yesLabel: 'Send Invoice',
    //   isLoadingKey: c.INVOICE_ITEM,
    //   isLoadingMethod: 'put',
    //   color: 'primary',
    //   onYes: (closeAlert) => {
    //     actions.submitInvoice(id, (res) => {
    //       closeAlert();
    //       onSuccess(res);
    //     });
    //   },
    // });
  }, []);
  return [isLoading, submit];
};

export const useCancelInvoice = () => {
  const isLoading = useApiLoading(c.INVOICE_ITEM, 'put');
  const submit = useCallback((id, onSuccess) => {
    showAlert({
      title: 'Cancel Invoice',
      yesLabel: 'Cancel',
      noLabel: 'Close',
      isLoadingKey: c.INVOICE_ITEM,
      isLoadingMethod: 'put',
      onYes: (closeAlert) => {
        actions.cancelInvoice(id, (res) => {
          closeAlert();
          if (typeof onSuccess === 'function') onSuccess(res);
        });
      },
    });
  }, []);
  return [isLoading, submit];
};

export const useCountCard = (name, filter, defaultValue = {}) => {
  const isLoading = useApiLoading(`${c.COUNT_CARD}/${name}`, 'get');
  const { value, meta } = useApiGet(`${c.COUNT_CARD}/${name}`, defaultValue);
  useEffect(() => {
    const action = actions.getCountCard[name];
    if (typeof action === 'function') action(filter);
  }, [filter, name]);
  return [isLoading, value, meta];
};

// Payment Links Stuffs
export const usePaymentLinksListData = (params) => {
  const pageRef = useRef(1);
  const isLoading = useApiLoading(c.PAYMENT_LINKS_LIST, 'get');
  const data = useApiGet(c.PAYMENT_LINKS_LIST, {});

  const fetchData = useCallback(() => {
    pageRef.current = 1;
    actions.listPaymentLinks(removeEmpty(params));
  }, [params]);

  useEffect(fetchData, [fetchData]);

  const loadMoreData = useCallback(() => {
    pageRef.current += 1;
    actions.appendPaymentLinkList(
      removeEmpty({ ...params, page: pageRef.current })
    );
  }, [params]);

  return { data, isLoading, loadMoreData };
};

export const useCreatePaymentLink = ({ onError, onSuccess }) => {
  const isLoading = useApiLoading(c.PAYMENT_LINK_CREATE, 'post');
  const createPaymentLink = useMemo(
    () => actions.createPaymentLink({ onSuccess, onError }),
    [onSuccess, onError]
  );
  return { isLoading, createPaymentLink };
};

export const usePaymentLinkData = ({ link_uuid }) => {
  const isLoading = useApiLoading(c.PAYMENT_LINKS_ITEM, 'get');
  const data = useApiGet(c.PAYMENT_LINKS_ITEM, {});

  const fetchData = useCallback(() => {
    if (!link_uuid) return;
    actions.getPaymentLink(link_uuid);
  }, [link_uuid]);

  useEffect(fetchData, [fetchData]);

  return { data, isLoading, fetchData };
};

export const useTogglePaymentLinkStatus = () => {
  const isLoading = useApiLoading(c.PAYMENT_LINK_STATUS, 'put');

  const toggle = useCallback(
    (status) =>
      (link_uuid, { onSuccess = () => {} }) => {
        if (!link_uuid) return;
        actions.togglePaymentLinkStatus({ onSuccess })({ link_uuid, status });
      },
    []
  );

  const activate = useMemo(() => toggle('active'), [toggle]);
  const deactivate = useMemo(() => toggle('inactive'), [toggle]);
  const archive = useMemo(() => toggle('archived'), [toggle]);

  return { isLoading, activate, deactivate, archive };
};

export const usePaymentLinkTransactionListData = ({
  params,
  link_uuid,
  resetOnUnmount = false,
}) => {
  const pageRef = useRef(1);
  const isLoading = useApiLoading(c.PAYMENT_LINK_TRANSACTIONS, 'get');
  const data = useApiGet(c.PAYMENT_LINK_TRANSACTIONS, {});

  const fetchData = useCallback(() => {
    pageRef.current = 1;
    actions.listPaymentLinkTransaction({
      link_uuid,
      params: removeEmpty(params),
    });
  }, [params, link_uuid]);

  useEffect(fetchData, [fetchData]);

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        req.set(c.PAYMENT_LINK_TRANSACTIONS, []);
      }
    };
  }, [resetOnUnmount]);

  const loadMoreData = useCallback(() => {
    pageRef.current += 1;
    actions.appendPaymentLinkTransactionList({
      link_uuid,
      params: removeEmpty({ ...params, page: pageRef.current }),
    });
  }, [params, link_uuid]);

  return { data, isLoading, loadMoreData };
};

export const usePaymentLinkTransactionLogList = ({ invoice_uuid }) => {
  const isLoading = useApiLoading(c.PAYMENT_LINK_TRANSACTION_LOGS, 'get');
  const data = useApiGet(c.PAYMENT_LINK_TRANSACTION_LOGS, []);

  const fetchData = useCallback(() => {
    actions.listPaymentLinkTransactionLogs({
      invoice_uuid,
    });
  }, [invoice_uuid]);

  useEffect(fetchData, [fetchData]);

  return { data, isLoading, fetchData };
};

export const usePaymentLinkPaidAggregationData = ({ link_uuid }) => {
  const isLoading = useApiLoading(
    `${c.PAYMENT_LINKS_PAID_AGGREGATION}?link_uuid=${link_uuid}`,
    'get'
  );
  const data = useApiGet(
    `${c.PAYMENT_LINKS_PAID_AGGREGATION}?link_uuid=${link_uuid}`,
    {}
  );

  const fetchData = useCallback(() => {
    if (!link_uuid) return;
    actions.getPaymentLinkPaidAggregation(link_uuid);
  }, [link_uuid]);

  useEffect(fetchData, [fetchData]);

  return { data, isLoading, fetchData };
};

export const useTransactionData = ({ transaction_uuid }) => {
  const isLoading = useApiLoading(
    `${c.PAYMENT_LINK_TRANSACTION}?transaction_uuid=${transaction_uuid}`,
    'get'
  );
  const data = useApiGet(
    `${c.PAYMENT_LINK_TRANSACTION}?transaction_uuid=${transaction_uuid}`,
    {}
  );

  const fetchData = useCallback(() => {
    if (!transaction_uuid) return;
    actions.getTransaction(transaction_uuid);
  }, [transaction_uuid]);

  useEffect(fetchData, [fetchData]);

  return { data, isLoading, fetchData };
};
