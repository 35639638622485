import PropTypes from 'prop-types';
import { HiPlus } from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { usePaymentLinksListData } from '../hooks';
import { GET_PAYMENT_LINKS_TABLE_FORMAT } from '../constants';
import PaymentLinksCountCards from './PaymentLinksCountCards';

const STATUS_OPTIONS = [
  { label: 'All Status', value: 'active|inactive' },
  { label: 'Active', value: 'active' },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

const GET_MAP_CREATE_BILL_PATH_STATE = (merchant) => ({
  '/merchants/profiles/:merchant_id/invoice': {
    merchantLock: true,
    merchant: {
      label: merchant.name,
      value: merchant.id,
    },
  },
});

function Filter({ onSubmit, value }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [, currentMerchant] = useMerchantSelected();
  const handleCreate = useCallback(() => {
    const state =
      GET_MAP_CREATE_BILL_PATH_STATE(currentMerchant)[match.path] ?? {};

    history.push({ pathname: `${match.url}/create`, state });
  }, [currentMerchant, history, match]);

  useEffect(() => {
    const fromUrl = history.location.state?.from;
    if (fromUrl === 'dashboard') {
      handleCreate();
      history.replace({ state: null });
    }
  }, [history, handleCreate]);

  return (
    <ModuleListFilters
      searchName="q"
      filter={value}
      setFilter={onSubmit}
      hideDatePicker
      searchPlaceholder="Search payment link name"
      renderCustomFields={({ inlineFilter, setInlineFilter }) => (
        <FormInlineSelect
          name="status"
          onChange={(v) => {
            const status = v(value)?.status;
            setInlineFilter((x) => ({ ...x, status }));
          }}
          value={inlineFilter?.status}
          options={STATUS_OPTIONS}
        />
      )}
      actionElement={
        <>
          <Button
            primary
            icon={HiPlus}
            onClick={handleCreate}
            className="h-full @4xl:hidden"
          />
          <Button
            primary
            icon={HiPlus}
            onClick={handleCreate}
            className="hidden h-full @4xl:inline-flex"
          >
            Create Payment Link
          </Button>
        </>
      }
      activeFiltersFormat={[
        {
          label: 'Status',
          key: 'status',
          matrix: [
            ['active', 'Active'],
            ['inactive', 'Inactive'],
          ],
          defaultValue: 'active|inactive',
        },
      ]}
    />
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

function PaymentLinksTable({ filter: otherFilter }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [raw, setFilter] = useState({
    keyword: '',
    status: 'active|inactive',
  });

  const filter = useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );

  const { data, isLoading, loadMoreData } = usePaymentLinksListData(filter);

  return (
    <div className="hide-scroll relative flex h-full flex-col overflow-auto sm:max-h-full">
      <PaymentLinksCountCards otherFilter={otherFilter} />
      <div className="flex flex-col sm:h-0 sm:flex-grow">
        <div className="pb-3">
          <div className="pt-3">
            <Filter onSubmit={setFilter} value={raw} />
          </div>
        </div>
        <div className="flex flex-col rounded-lg border bg-white sm:h-0 sm:flex-grow">
          <Table
            isLoading={isLoading}
            data={data.list ?? []}
            format={GET_PAYMENT_LINKS_TABLE_FORMAT}
            onSort={setFilter}
            sort={raw.sort}
            onLoadMore={loadMoreData}
            pager={data?.pager}
            onRowClick={(row) => history.push(`${match.url}/${row.uuid}`)}
          />
        </div>
      </div>
    </div>
  );
}

PaymentLinksTable.defaultProps = {
  filter: {},
};

PaymentLinksTable.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default PaymentLinksTable;
