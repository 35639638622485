// import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiX } from 'react-icons/hi';
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Unknown = () => <div>Tour not found</div>;

function TourModal({ data, onNext, onSkip }) {
  const endFunc = React.useRef();
  const endFuncParams = React.useRef(null);
  const refDiv = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (data?.type !== 'modal') return;
    setIsOpen(true);
    // eslint-disable-next-line
  }, [data?.id]);

  if (data?.type !== 'modal') return null;
  const Component = data?.component || Unknown;

  const handleClose = () => {
    if (typeof endFunc.current === 'function')
      endFunc.current(endFuncParams.current);
  };

  const handleNext = (payload) => {
    endFunc.current = onNext;
    endFuncParams.current = payload;
    setIsOpen(false);
  };

  const handleSkip = () => {
    endFunc.current = onSkip;
    setIsOpen(false);
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        open
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={handleClose}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div ref={refDiv} className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative w-full max-w-2xl">
                <div className="absolute right-0 top-0 z-10">
                  <button
                    className="flex h-8 w-8 translate-x-[50%] translate-y-[-50%] items-center justify-center rounded-full bg-white shadow hover:bg-gray-50"
                    type="button"
                    onClick={() => onSkip()}
                    aria-label="icon"
                  >
                    <HiX className="h-5 w-5 text-gray-500" />
                  </button>
                </div>
                <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full">
                  <Component onNext={handleNext} onSkip={handleSkip} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

TourModal.defaultProps = {
  data: null,
};

TourModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default TourModal;
