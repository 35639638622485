import { each } from 'lodash';
import { formatNumber } from 'helper';

export const transformApiKeyList = (raw) => ({
  merchant_id: raw?.merchant_uuid || '',
  api_key_id: raw?.uuid || '',
  callback_url: raw?.callback_url || '',
  queue_code: raw?.queue_code || '',
  status: raw?.status || '',
  token: raw?.token || '',
  api_type: raw?.api_type || [],
  environment_type: raw?.environment_type || '',
});

export const transformApiKeyOptions = (raw) => ({
  value: raw?.uuid,
  label: raw?.queue_code,
  data: transformApiKeyList(raw),
});

export const transformHistogramWebhook = (res) => {
  const newData = [];

  each(res?.data, (row) => {
    let total = 0;
    const counts = {
      PENDING: 0,
      FAILED: 0,
      PAID: 0,
      REFUNDED: 0,
      VOIDED: 0,
      EXPIRED: 0,
    };

    each(row?.event_types, (e) => {
      total += e?.total;
      counts[e?.type] += e?.total;
    });

    newData.push({
      date_human: row?.date_human,
      total,
      total_human: formatNumber(total, 0),
      pending: counts.PENDING,
      pending_human: formatNumber(counts.PENDING, 0),
      paid: counts.PAID,
      paid_human: formatNumber(counts.PAID, 0),
      failed: counts.FAILED,
      failed_human: formatNumber(counts.FAILED, 0),
      refunded: counts.REFUNDED,
      refunded_human: formatNumber(counts.REFUNDED, 0),
      voided: counts.VOIDED,
      voided_human: formatNumber(counts.VOIDED, 0),
      expired: counts.EXPIRED,
      expired_human: formatNumber(counts.EXPIRED),
    });
  });

  const formatted = {
    label: newData.map((x) => x?.date_human),
    series: [
      {
        name: 'Paid',
        data: newData.map((x) => x?.paid),
      },
      {
        name: 'Pending',
        data: newData.map((x) => x?.pending),
      },
      {
        name: 'Failed',
        data: newData.map((x) => x?.failed),
      },
      {
        name: 'Refunded',
        data: newData.map((x) => x?.refunded),
      },
      {
        name: 'Voided',
        data: newData.map((x) => x?.voided),
      },
      {
        name: 'Expired',
        data: newData.map((x) => x?.expired),
      },
    ],
  };

  return {
    data: newData,
    graph: formatted,
  };
};

export const transformWebhookLogs = (raw) => ({
  uuid: raw?.uuid,
  merchant_uuid: raw?.merchant_uuid,
  transaction_uuid: raw?.transaction_uuid,
  refno: raw?.request?.refno,
  created_at: raw?.created_at,
  callback_url: raw?.callback_url,
  event_type: raw?.event_type,
  status: raw?.status,
  request: raw?.request,
  response: raw?.response,
  elapsed_time: raw?.elapsed_time,
});

export const transformTransaction = (raw) => ({
  uuid: raw?.uuid,
  txnid: raw?.txnid,
  refno: raw?.refno,
  amount: formatNumber(raw?.amount, 2),
});
