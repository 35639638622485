import React, { Fragment, useEffect, useRef } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { HiSearch } from 'react-icons/hi';
import { useOnClickOutside } from 'helper';
import { CgSpinner } from 'react-icons/cg';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';

import ThumbIcon from 'partial/components/ThumbIcon';

import { useInputId } from './hooks';

function FormAsyncSelect({
  id: defaultId,
  label: formLabel,
  name,
  onChange,
  onSetFieldValue,
  value,
  placeholder,
  loadOptions,
  filterParams,
  required,
  disabled,
  error,
  showIcon,
  clearable,
  readOnly,
}) {
  const isMountedRef = useRef(true);
  const didAutoSelectRef = useRef(!!value);
  const [id] = useInputId(defaultId);
  const [label, setLabel] = React.useState(value?.label || '');

  const [showOptions, setShowOptions] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const myRef = React.useRef();
  useOnClickOutside(myRef, setShowOptions);
  const [options, setOptions] = React.useState([]);
  const handleChange = ({ target }) => {
    setLabel(target.value);
    setIsLoading(true);
    loadOptions(
      target.value,
      (res) => {
        setIsLoading(false);
        setOptions(res);
      },
      filterParams
    );
  };

  const handleFocus = () => {
    if (readOnly || disabled) return;
    if (isEmpty(label)) {
      setIsLoading(true);
      loadOptions(
        '',
        (res) => {
          setIsLoading(false);
          setOptions(res);
        },
        filterParams
      );
    }
    setShowOptions(true);
  };

  // eslint-disable-next-line
  const handleSelect = (v) => (e) => {
    e?.preventDefault();
    setShowOptions(false);
    setLabel(v.label);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: v,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, v);
    }
  };
  const handleClear = () => {
    setShowOptions(false);
    setLabel('');
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: null,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, null);
    }
  };
  const isError = !!error;

  useEffect(() => {
    if (value) return;
    if (didAutoSelectRef?.current) return;
    didAutoSelectRef.current = true;
    setIsLoading(true);
    loadOptions(
      '',
      (res) => {
        if (!isMountedRef?.current) return;
        setIsLoading(false);
        setOptions(res);
        if (res?.length !== 1) return;
        handleSelect(res?.[0])();
      },
      filterParams
    );
  }, [
    value,
    setOptions,
    loadOptions,
    filterParams,
    setIsLoading,
    handleSelect,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (value) return;
      setLabel('');
    }, 500);
    return clearTimeout();
  }, [value]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <div ref={myRef} className="relative">
      <div className="relative z-[1]">
        {!required && (
          <span className="absolute -top-7 right-0 z-[1] text-sm text-gray-400">
            Optional
          </span>
        )}
        <input
          type="text"
          className={cn(
            'peer relative w-full rounded-md border bg-white px-4 pb-4 pt-8 text-base font-bold leading-4',
            'text-gray-900 placeholder:text-transparent read-only:bg-gray-100 focus-within:ring-2 disabled:bg-gray-100',
            isError
              ? 'border-red-300 focus:border-red-500 focus:ring-red-300'
              : 'border-gray-200 focus:border-primary-600 focus:ring-primary-400',
            disabled || readOnly ? 'bg-gray-100' : 'bg-white',
            showIcon ? 'pl-12' : 'pl-10'
          )}
          style={{ textOverflow: 'ellipsis' }}
          onFocus={handleFocus}
          placeholder={placeholder}
          onChange={handleChange}
          value={label}
          disabled={disabled}
          readOnly={readOnly}
        />
        <div
          className={cn(
            'pointer-events-none absolute top-11 left-4 flex -translate-y-1/2 items-center peer-focus:z-[1]',
            label && !showIcon ? 'z-[1]' : '-z-[1]'
          )}
        >
          {isLoading ? (
            <CgSpinner
              className="h-5 w-5 animate-spin text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <HiSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </div>
        {!!value && showIcon && (
          <div className="absolute top-[2.1rem] left-4 z-[1]">
            <ThumbIcon
              className="h-6 w-6 rounded object-contain object-center text-[0.5rem]"
              url={value?.photo_url}
              alt={value?.label}
            />
          </div>
        )}
        <label
          htmlFor={id}
          className={cn(
            'no-highlight pointer-events-none absolute left-4 top-[1.625rem] -translate-y-1/2 text-xs text-gray-600 transition-all',
            'peer-placeholder-shown:top-1/2 peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400',
            'peer-focus:top-[1.625rem] peer-focus:text-xs peer-focus:text-gray-600'
          )}
        >
          <span>{formLabel}</span>
        </label>
        {value?.value && clearable && !disabled && !readOnly ? (
          <button
            className="absolute top-9 right-4 -translate-y-1/2 font-semibold text-primary-500 hover:underline"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
        ) : null}
      </div>
      <Menu>
        {() => (
          <Transition
            show={showOptions}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items
              className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              static
            >
              {options.length < 1 ? (
                <div className="relative block cursor-default select-none py-1 pl-3 pr-9 text-center text-gray-400">
                  {isLoading ? 'Loading...' : 'No items.'}
                </div>
              ) : (
                options.map((option) => (
                  <Menu.Item key={option.value}>
                    {({ selected, active }) => (
                      <a
                        href="/"
                        className={cn(
                          active ? 'bg-primary-50' : 'text-gray-900',
                          'relative block cursor-pointer select-none py-2 pl-3 pr-9'
                        )}
                        onClick={handleSelect(option)}
                      >
                        <div className="flex items-center">
                          {showIcon ? (
                            <span className="mr-2 flex-shrink-0">
                              <ThumbIcon
                                className="object-cecnter h-8 w-8 rounded object-contain"
                                url={option?.photo_url}
                                alt={option.label}
                              />
                            </span>
                          ) : null}
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={cn(
                              active ? 'text-primary-800' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </a>
                    )}
                  </Menu.Item>
                ))
              )}
            </Menu.Items>
          </Transition>
        )}
      </Menu>
    </div>
  );
}

FormAsyncSelect.defaultProps = {
  id: '',
  label: '',
  value: null,
  error: null,
  placeholder: 'Type to search...',
  onChange: false,
  disabled: false,
  onSetFieldValue: false,
  required: false,
  showIcon: false,
  clearable: true,
  filterParams: {},
  readOnly: false,
};

FormAsyncSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Object),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  loadOptions: PropTypes.func.isRequired,
  filterParams: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
};

export default FormAsyncSelect;
