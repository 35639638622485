/* eslint-disable */
import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import Form, { ErrorLabel } from 'partial/form/Form';
import { useUpdateAccount } from 'modules/auth/hooks';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';
import { isValidPhoneNumber } from 'react-phone-number-input';

const validationSchema = yup.object().shape({
  representative_first_name: yup.string().required('Required'),
  representative_last_name: yup.string().required('Required'),
  representative_contact_number: yup
    .string()
    .required('Required')
    .test({
      name: 'must be valid',
      test: (value) => isValidPhoneNumber(value || ''),
      message: 'Invalid',
    }),
  name: yup.string().required('Required'),
  address: yup.string().max(64, 'Max of 64 characters'),
});

function UpdateProfileModal({ onClose, data, profile }) {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [isUpdating, update] = useUpdateAccount();

  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();
  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validateOnChange,
    validationSchema,
    initialValues: data,
    onSubmit: (form) => {
      update(form, () => {
        onClose();
      });
    },
  });

  return (
    <Form onSubmit={handleSubmit} error={errors}>
      <div className="mb-3">
        <FormInput
          name="name"
          onSetFieldValue={registerOnSetFieldValue('name', setFieldValue)}
          value={values.name}
          label="Organization"
          type="text"
          required
        />
        <ErrorLabel name="name" />
      </div>
      <div className="mb-3 sm:mb-8">
        <FormInput
          name="email"
          onChange={() => {}}
          value={profile.email_address}
          label="Email Address"
          type="text"
          required
          disabled
        />
        <ErrorLabel name="email" />
      </div>
      <div className="mb-3 sm:mb-8">
        <FormInput
          name="address"
          value={values.address}
          onSetFieldValue={setFieldValue}
          label="Address"
          type="text"
        />
        <ErrorLabel name="address" />
      </div>
      <div className="mb-3">
        <FormInput
          name="representative_first_name"
          onSetFieldValue={registerOnSetFieldValue(
            'representative_first_name',
            setFieldValue
          )}
          value={values.representative_first_name}
          label="Representative's First Name"
          type="text"
          required
        />
        <ErrorLabel name="representative_first_name" />
      </div>
      <div className="mb-3">
        <FormInput
          name="representative_last_name"
          onSetFieldValue={registerOnSetFieldValue(
            'representative_last_name',
            setFieldValue
          )}
          value={values.representative_last_name}
          label="Representative's Last Name"
          type="text"
          required
        />
        <ErrorLabel name="representative_last_name" />
      </div>
      <div className="mb-3">
        <FormMobileNumber
          name="representative_contact_number"
          onSetFieldValue={registerOnSetFieldValue(
            'representative_contact_number',
            setFieldValue
          )}
          value={values.representative_contact_number}
          label="Contact Number"
          required
        />
        <ErrorLabel name="representative_contact_number" />
      </div>
      <div className="flex justify-end space-x-2 border-t pt-4">
        <Button className="mr-2" onClick={onClose} disabled={isUpdating}>
          Close
        </Button>
        <Button
          primary
          type="submit"
          disabled={isUpdating}
          onClick={() => setValidateOnChange(true)}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

UpdateProfileModal.defaultProps = {
  data: { name: '' },
  profile: {},
};

UpdateProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
  profile: PropTypes.instanceOf(Object),
};

export default UpdateProfileModal;
