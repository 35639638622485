import React, { forwardRef } from 'react';
import {
  HiDownload,
  HiOutlineTrash,
  HiSortAscending,
  HiOutlinePencil,
  HiSortDescending,
  HiOutlineEye,
} from 'react-icons/hi';
import cn from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { downloadArrayToCsv, formatNumber } from 'helper';

import noData from 'assets/images/no-data.svg';

import Button from './Button';

const parser = (v, path) => {
  const val = get(v, path, 0);
  if (typeof val === 'string') return val.toLowerCase();
  return val;
};

export const sortList = (list, sortBy) => {
  const sort = sortBy.split(':');
  const x = [sort[1] === 'asc' ? 1 : -1, sort[1] === 'asc' ? -1 : 1];
  const newSorted = [...list];
  newSorted.sort((a, b) =>
    parser(a, sort[0]) > parser(b, sort[0]) ? x[0] : x[1]
  );
  return newSorted;
};

export const tableAction = (label, onClick) => {
  const handleClick = (row) => (e) => {
    e.preventDefault();
    onClick(row);
  };
  return {
    id: 'action',
    label: <span className="sr-only">Action</span>,
    key: (row) => (
      <a
        href="/"
        className="text-primary-600 hover:text-primary-900"
        onClick={handleClick(row)}
      >
        {label}
      </a>
    ),
    width: 100,
  };
};

export const tableEditDelete = (onEdit, onDelete) => {
  const handleEditClick = (row) => (e) => {
    e.preventDefault();
    onEdit(row);
  };
  const handleDeleteClick = (row) => (e) => {
    e.preventDefault();
    onDelete(row);
  };
  return {
    id: 'action',
    label: <span className="sr-only">Action</span>,
    key: (row) => (
      <div className="flex items-center space-x-2 opacity-100 transition group-hover:opacity-100 md:opacity-0">
        <a href="/" className="link-default" onClick={handleEditClick(row)}>
          <HiOutlinePencil className="h-3 w-3" />
          <span>Edit</span>
        </a>
        <a href="/" className="link-danger" onClick={handleDeleteClick(row)}>
          <HiOutlineTrash className="h-3 w-3" />
          <span>Delete</span>
        </a>
      </div>
    ),
    width: 100,
  };
};

export function TableActions({ onView, onEdit, onDelete, otherActionsFormat }) {
  return (
    <div className="flex items-center space-x-2 opacity-100 transition group-hover:opacity-100 md:opacity-0">
      {otherActionsFormat?.map(
        ({ label, icon: Icon, buttonClassName, onClick }) => (
          <button type="button" className={buttonClassName} onClick={onClick}>
            <Icon className="h-3 w-3" />
            <span>{label}</span>
          </button>
        )
      )}
      {typeof onView === 'function' && (
        <button type="button" className="link-primary" onClick={onView}>
          <HiOutlineEye className="h-3 w-3" />
          <span>View</span>
        </button>
      )}
      {typeof onEdit === 'function' && (
        <button type="button" className="link-default" onClick={onEdit}>
          <HiOutlinePencil className="h-3 w-3" />
          <span>Edit</span>
        </button>
      )}
      {typeof onDelete === 'function' && (
        <button type="button" className="link-danger" onClick={onDelete}>
          <HiOutlineTrash className="h-3 w-3" />
          <span>Delete</span>
        </button>
      )}
    </div>
  );
}

TableActions.defaultProps = {
  onView: null,
  onEdit: null,
  onDelete: null,
  otherActionsFormat: [],
};

TableActions.propTypes = {
  onView: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]),
  onEdit: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]),
  onDelete: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]),
  otherActionsFormat: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.instanceOf(Object).isRequired,
      buttonClassName: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

const Table = forwardRef(
  (
    {
      format,
      data,
      downloadCsv,
      downloadFilename,
      isLoading,
      subLabel,
      sortKey,
      onSort,
      sort,
      pager,
      onRowClick,
      onLoadMore,
    },
    ref
  ) => {
    const [sortBy, orderBy] = React.useMemo(
      () => (sort || '').split(':'),
      [sort]
    );
    const handleDownload = () => {
      const downloadData =
        typeof downloadCsv === 'function' ? data.map(downloadCsv) : data;
      downloadArrayToCsv(downloadData, downloadFilename);
    };
    const handleSort = (col) => (e) => {
      e.preventDefault();
      const switchOrder = orderBy === 'asc' ? 'desc' : 'asc';
      onSort((state) => ({
        ...state,
        [sortKey]: `${col.sortKey}:${
          sortBy !== col.sortKey ? 'asc' : switchOrder
        }`,
      }));
    };

    const handleNextPage = () => {
      onLoadMore(pager?.current_page + 1);
    };

    return (
      <div className="flex flex-col sm:flex-grow sm:overflow-hidden">
        <div className="relative isolate sm:flex sm:flex-grow">
          <div className="h-full w-full sm:absolute sm:overflow-auto">
            <table ref={ref} className="min-w-full divide-y divide-gray-200">
              {data.length < 1 ? (
                <>
                  {isLoading ? (
                    <>
                      <thead className="sticky top-0 z-10 bg-gray-50">
                        <tr>
                          {React.Children.toArray(
                            format.map((col) => {
                              return (
                                <th
                                  aria-label={col.label}
                                  className={
                                    col?.stackKey ? 'hidden sm:table-cell' : ''
                                  }
                                  scope="col"
                                  style={{
                                    width: col?.width || 'auto',
                                  }}
                                >
                                  <div className="m-1 h-6 animate-pulse rounded bg-gray-300" />
                                </th>
                              );
                            })
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {[0, 1, 2].map((r) => (
                          <tr key={r}>
                            {React.Children.toArray(
                              format.map((col) => {
                                return (
                                  <td
                                    aria-label={`${col.label} value`}
                                    className={
                                      col?.stackKey
                                        ? 'hidden sm:table-cell'
                                        : ''
                                    }
                                    style={{
                                      width: col?.width || 'auto',
                                    }}
                                  >
                                    <div className="m-1 h-6 animate-pulse rounded-full bg-gray-200" />
                                  </td>
                                );
                              })
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tbody className="relative flex flex-1 items-center">
                      <tr className="mx-auto max-w-xl py-10 text-center">
                        <td>
                          <img
                            src={noData}
                            className="mx-auto mb-4 w-40"
                            alt=""
                          />
                          <div className="text-base text-gray-700">
                            There is no data yet.
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              ) : (
                <>
                  <thead className="sticky top-0 z-10 bg-gray-50">
                    <tr>
                      {React.Children.toArray(
                        format.map((col) => {
                          return (
                            <th
                              className={
                                col?.stackKey ? 'hidden sm:table-cell' : ''
                              }
                              scope="col"
                              style={{
                                width: col?.width || 'auto',
                              }}
                            >
                              {onSort && col.sortKey ? (
                                <a
                                  href="/"
                                  onClick={handleSort(col)}
                                  className={twMerge(
                                    cn(
                                      'flex items-center space-x-2 whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider transition hover:bg-gray-100',
                                      sortBy === col.sortKey
                                        ? 'text-primary-500'
                                        : 'text-gray-500'
                                    ),
                                    col.headerClassName
                                  )}
                                >
                                  <span>{col.label}</span>
                                  {sortBy === col.sortKey &&
                                  orderBy === 'desc' ? (
                                    <HiSortAscending className="h-4 w-4" />
                                  ) : (
                                    <HiSortDescending className="h-4 w-4" />
                                  )}
                                </a>
                              ) : (
                                <span
                                  className={twMerge(
                                    'flex flex-shrink-0 items-center space-x-2 whitespace-nowrap px-6 py-3 text-left font-medium uppercase tracking-wider text-gray-500',
                                    col.headerClassName
                                  )}
                                >
                                  {col.label}
                                </span>
                              )}
                            </th>
                          );
                        })
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      data.map((row) => (
                        <tr
                          className={cn(
                            'group relative isolate cursor-pointer border-b border-t border-gray-100 transition hover:bg-gray-50'
                          )}
                        >
                          {React.Children.toArray(
                            format.map((col) => {
                              const content = (() => {
                                if (typeof col.key === 'function')
                                  return col.key(row);
                                return get(row, col.key);
                              })();
                              const smContent = (() => {
                                if (typeof col.smRender === 'function')
                                  return col.smRender(row);
                                return false;
                              })();
                              return (
                                <td
                                  className={cn('relative', col.className, {
                                    'hidden sm:table-cell': col?.stackKey,
                                  })}
                                  width={col.width || 'initial'}
                                >
                                  {typeof onRowClick === 'function' && (
                                    <div className="absolute inset-0 z-10 h-full w-full">
                                      <button
                                        aria-label="select"
                                        type="button"
                                        className="h-full w-full"
                                        onClick={() => onRowClick(row)}
                                      />
                                    </div>
                                  )}
                                  <div className="hidden whitespace-nowrap px-6 py-3 text-left text-sm font-light leading-4 text-gray-600 sm:block">
                                    {content}
                                  </div>
                                  {smContent ? (
                                    <div className="relative block px-6 py-3 text-sm text-gray-600 sm:hidden">
                                      {smContent}
                                    </div>
                                  ) : (
                                    <div className="block whitespace-nowrap px-6 py-3 text-left text-gray-600 sm:hidden">
                                      {content}
                                    </div>
                                  )}
                                </td>
                              );
                            })
                          )}
                        </tr>
                      ))
                    )}
                    {pager?.last_page > 1 &&
                    typeof onLoadMore === 'function' ? (
                      <tr>
                        <td
                          className="px-6 py-3 text-xs font-medium text-gray-500"
                          colSpan={format.length}
                        >
                          <div className="flex items-center justify-between">
                            <div className="w-20">
                              {formatNumber(pager?.current_page, 0)} of{' '}
                              {formatNumber(pager?.last_page, 0)}
                            </div>
                            {pager?.current_page >= pager?.last_page ? (
                              <Button size="sm" disabled>
                                No more items.
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                disabled={isLoading}
                                onClick={handleNextPage}
                              >
                                {isLoading ? 'Loading...' : 'Load more'}
                              </Button>
                            )}
                            <div className="w-20 text-right">
                              Total: {formatNumber(pager?.total, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
        {(downloadCsv || subLabel) && (
          <div className="flex items-center justify-between bg-gray-50">
            {downloadCsv ? (
              <div className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                <Button size="sm" icon={HiDownload} onClick={handleDownload}>
                  Download CSV
                </Button>
              </div>
            ) : (
              <span />
            )}
            {subLabel ? (
              <div className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                {subLabel}
              </div>
            ) : (
              <span />
            )}
          </div>
        )}
      </div>
    );
  }
);

Table.defaultProps = {
  downloadFilename: 'download.csv',
  downloadCsv: false,
  isLoading: false,
  sortKey: 'sort',
  onSort: false,
  subLabel: '',
  sort: '',
  data: [],
  pager: null,
  onRowClick: null,
  onLoadMore: null,
};

Table.propTypes = {
  onSort: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  format: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      smRender: PropTypes.func,
      stackKey: PropTypes.bool,
      sortKey: PropTypes.string,
      className: PropTypes.string,
      headerClassName: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  data: PropTypes.instanceOf(Array),
  downloadFilename: PropTypes.string,
  downloadCsv: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  subLabel: PropTypes.string,
  sortKey: PropTypes.string,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  pager: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
  onLoadMore: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
};

export default Table;
