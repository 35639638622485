/* eslint-disable jsx-a11y/control-has-associated-label, react/no-array-index-key */
import React from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FaSpinner } from 'react-icons/fa';
import { HiPhotograph, HiTrash } from 'react-icons/hi';

import { usePersistState } from 'helper';
import DropZone from 'partial/components/DropZone';
import { uploadPhotosAndPdfs } from 'partial/form/FormPhotoDnd';
import dragAndDropFile from 'assets/images/drag-n-drop-file.png';
import { showAlert, showLightbox } from 'partial/components/Modal';

function AttachmentPreview({
  name,
  onChange,
  values,
  label,
  readOnly,
  isMultiple,
}) {
  const [fileNames, setFileNames] = usePersistState(
    'attachment-file-names',
    {}
  );
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleUpload = async ({ target }) => {
    const rawName = [];
    const filteredFiles = [];
    for (let i = 0; i < target.files.length; i += 1) {
      const file = target.files[i];
      const type = file.type || '';
      rawName.push(file?.name);
      if (type.indexOf('image/') > -1 || type.indexOf('application/pdf') > -1) {
        filteredFiles.push(file);
      }
    }
    setFileNames((state) => ({
      ...state,
      [label]: [...(fileNames?.[label] || []), ...rawName],
    }));
    const prevValue = values.filter((x) => !isEmpty(x));
    onChange((state) => ({
      ...state,
      [name]: `${prevValue
        .concat(filteredFiles)
        .map(() => 'uploading')
        .join(',')}`,
    }));
    const files = await uploadPhotosAndPdfs(filteredFiles);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue.concat(files).join(',')}`,
    }));
  };
  const handleDrop = async (filteredFiles, onIsUploading, getFileName) => {
    setFileNames((state) => ({
      ...state,
      [label]: [...(fileNames?.[label] || []), ...(getFileName || [])],
    }));
    onIsUploading(true);
    const prevValue = values.filter((x) => !isEmpty(x));
    onIsUploading(false);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue
        .concat(filteredFiles)
        .map(() => 'uploading')
        .join(',')}`,
    }));
    const files = await uploadPhotosAndPdfs(filteredFiles);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue.concat(files).join(',')}`,
    }));
  };

  const handleDelete = (index) => async () => {
    showAlert({
      title: 'Are you sure you want to delete this attachment?',
      yesLabel: 'Delete',
      onYes: (closeAlert) => {
        const newFileNames = (fileNames?.[label] || []).filter(
          (x, i) => index !== i
        );
        onChange((state) => ({
          ...state,
          [name]: values.filter((x, i) => index !== i).join(','),
        }));
        setFileNames((state) => ({
          ...state,
          [label]: newFileNames,
        }));
        if (selectedIndex !== 0) {
          setSelectedIndex(selectedIndex - 1);
        }
        closeAlert();
      },
    });
  };
  // const [imageLoaded, setImageLoaded] = React.useState(false);
  // React.useEffect(() => setImageLoaded(false), [setImageLoaded, selected]);

  React.useEffect(() => {
    if (!values?.length) return;
    if (selectedIndex >= values?.length) {
      setSelectedIndex(0);
    }
  }, [setSelectedIndex, selectedIndex, values]);

  return (
    <div className="flex h-full max-h-full w-full flex-col overflow-auto">
      {!isEmpty(values) ? (
        <div
          className={cn(
            'mb-2 grid gap-2',
            isMultiple || readOnly ? 'grid-cols-2' : 'grid-cols-1'
          )}
        >
          {(values || []).map((v, i) => (
            <div
              key={`${v}-${i}`}
              className={cn(
                'group relative',
                isMultiple || readOnly ? 'h-[6.875rem]' : 'h-64'
              )}
            >
              <button
                type="button"
                className="absolute inset-0 h-full w-full bg-black/40 transition-all sm:bg-transparent sm:group-hover:bg-black/40"
                onClick={() =>
                  showLightbox({
                    title: 'Attachment',
                    content: v,
                  })
                }
              />
              <span className="absolute left-2 top-2 inline-flex space-x-1 text-white sm:hidden sm:group-hover:inline-flex">
                <HiPhotograph className="h-4 w-4" />
                <span className="text-xs">Attachment</span>
              </span>
              {!readOnly ? (
                <button
                  type="button"
                  onClick={handleDelete(i)}
                  className="absolute bottom-2 left-2 isolate z-10 block space-x-1 text-white sm:hidden sm:group-hover:block"
                >
                  <HiTrash className="h-4 w-4" />
                </button>
              ) : null}
              {v !== 'uploading' ? (
                <>
                  {queryString.parseUrl(v)?.query?.type === 'pdf' ? (
                    <iframe
                      src={v}
                      frameBorder="0"
                      title="File Preview"
                      allowTransparency="true"
                      style={{
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                      }}
                      height="100%"
                      width="100%"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    />
                  ) : (
                    <img
                      alt=""
                      src={v}
                      loading="lazy"
                      className="m-auto h-full w-full object-contain"
                    />
                  )}
                </>
              ) : (
                <FaSpinner className="absolute left-[45%] top-[45%] animate-spin text-primary-500" />
              )}
            </div>
          ))}
        </div>
      ) : null}
      {!readOnly ? (
        <DropZone
          onDrop={handleDrop}
          className={cn(
            'mt-auto flex w-full bg-white transition-all delay-75 duration-200',
            !isEmpty(values) ? 'h-20 min-h-[5rem]' : 'h-full',
            !isMultiple && !isEmpty(values) ? 'hidden' : ''
          )}
        >
          <div className="m-auto flex flex-col items-center">
            <img
              alt="drag-n-drop"
              src={dragAndDropFile}
              className={cn(
                'w-[4.375rem] transition-all',
                !isEmpty(values) ? 'hidden' : ''
              )}
            />
            <label className="block cursor-pointer">
              Drop your files here or{' '}
              <span className="text-primary-500">browse</span>
              <input
                className="absolute h-0 w-0 opacity-0"
                type="file"
                onChange={handleUpload}
                accept="image/*,application/pdf"
                multiple={isMultiple}
              />
            </label>
            <span className="block text-gray-300">Maximum size: 50MB</span>
          </div>
        </DropZone>
      ) : null}
    </div>
  );
}

AttachmentPreview.defaultProps = {
  readOnly: false,
  isMultiple: false,
};

AttachmentPreview.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
};

export default AttachmentPreview;
