import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { CirclePicker } from 'react-color';
import { Popover } from '@headlessui/react';
import Button from 'partial/components/Button';
import ToolTip from 'partial/components/ToolTip';
import { BiInfoCircle, BiReset } from 'react-icons/bi';

const isHexValid = (val) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(val);
};

function FormInlineColorPicker({
  name,
  onChange,
  value,
  label,
  description,
  onReset,
}) {
  const [inputValue, setInputValue] = React.useState(value);
  const handleBlur = () => {
    const newValue = isHexValid(inputValue) ? inputValue : '#000000';
    onChange((state) => ({
      ...state,
      [name]: newValue,
    }));
    setInputValue(newValue);
  };
  React.useEffect(() => {
    if (value) setInputValue(value);
  }, [value, setInputValue]);
  const handleChange = (color) => {
    onChange((state) => ({
      ...state,
      [name]: color?.hex,
    }));
  };
  return (
    <div className="flex items-center space-x-2 justify-between">
      <ToolTip tip={description}>
        <div className="flex relative">
          <div
            className={cn(
              'rounded border-dashed border-b border-white flex items-center space-x-2 cursor-pointer text-gray-600 font-semibold',
              description ? 'group-hover:bg-gray-100 hover:border-gray-300' : ''
            )}
          >
            <div className="">{label}</div>
            {description ? <BiInfoCircle /> : null}
          </div>
        </div>
      </ToolTip>
      <Popover className="flex items-center space-x-2 relative">
        {typeof onReset === 'function' ? (
          <Button icon={BiReset} size="sm" defaultShow onClick={onReset} />
        ) : null}
        <div className="flex items-center border rounded-md shadow-sm">
          <div
            className="w-7 h-7 rounded-l-md border-r relative"
            style={{ backgroundColor: value }}
          >
            <Popover.Button
              className="absolute inset-0 h-full w-full z-10"
              type="button"
            />
          </div>
          <div className="w-[65px]">
            <input
              className="w-full h-7 uppercase pl-1 focus:outline-none font-mono font-semibold text-gray-500 border-b-2 border-gray-100 focus:border-gray-500 transition"
              onChange={({ target }) => setInputValue(target.value)}
              value={inputValue}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <Popover.Panel className="absolute z-30 top-10 right-0 text-right bg-white border p-3 rounded-lg shadow-primary-lg">
          <CirclePicker
            triangle="hide"
            width="210px"
            onChange={handleChange}
            colors={[
              '#F44336',
              '#673AB7',
              '#3F51B5',
              '#00BCD4',
              '#4CAF50',
              '#FFEB3B',
              '#FF9800',
              '#607D8B',
              '#CFD8DC',
              '#f1f1f1',
            ]}
            disableAlpha
          />
        </Popover.Panel>
      </Popover>
    </div>
  );
}

FormInlineColorPicker.defaultProps = {
  onReset: null,
  description: '',
};

FormInlineColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  onReset: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]),
};

export default FormInlineColorPicker;
