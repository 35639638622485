import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

/* This component is used to force redirect to the root if the reference is changed */
function RedirectToRoot({ reference, root }) {
  const currentRef = React.useRef(reference);
  const history = useHistory();
  React.useEffect(() => {
    if (!reference) return;
    if (currentRef.current !== reference) {
      history.push(root);
      currentRef.current = reference;
    }
    // eslint-disable-next-line
  }, [reference]);
  return null;
}

RedirectToRoot.defaultProps = {
  reference: null,
};

RedirectToRoot.propTypes = {
  reference: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  root: PropTypes.string.isRequired,
};

export default RedirectToRoot;
