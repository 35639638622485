/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';
// import PropTypes from 'prop-types';
import useAppTour from './useAppTour';

function TourDots() {
  const { activeIndex, tourSteps, setSelected } = useAppTour();
  const handleSet = (row) => () => {
    setSelected(row);
  };
  return (
    <div className="mr-auto flex space-x-1">
      {tourSteps.map((item, i) => (
        <div
          key={item.id}
          className={cn(
            'block h-2.5 w-2.5 rounded-full cursor-pointer',
            i === activeIndex ? 'bg-primary-500' : 'bg-gray-200'
          )}
          onClick={handleSet(item)}
        />
      ))}
    </div>
  );
}

TourDots.propTypes = {};

export default TourDots;
