import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toastSuccess } from 'react-geek-toast';
import { isValidPhoneNumber } from 'react-phone-number-input';

import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import FormSelect from 'partial/form/FormSelect';
import { withReqHookDefaultOptions } from 'helper';
import { showDrawer } from 'partial/components/Modal';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';

import { transformUser } from '../transformers';
import { useCreateUser, useRoleList, useUpdateUser } from '../hooks';

export const useOpenUserModal = withReqHookDefaultOptions((options) => {
  const openUserModal = useCallback(
    (title, data) => {
      showDrawer({
        size: 'xs',
        title,
        content: (onClose) => (
          <UserModal
            data={data}
            onClose={onClose}
            onSuccess={options.onSuccess}
          />
        ),
      });
    },
    [options.onSuccess]
  );

  return openUserModal;
});

const validationSchema = yup.object({
  contact_first_name: yup.string().required('Required'),
  contact_last_name: yup.string().required('Required'),
  email_address: yup.string().email('Invalid').required('Required'),
  contact_mobile_number: yup
    .string()
    .required('Required')
    .test({
      name: 'must be valid',
      test: (value) => isValidPhoneNumber(value || ''),
      message: 'Invalid',
    }),
  role: yup.string().required('Required'),
});

const INIT_VALUES = {
  merchant_uuid: '',
  contact_first_name: '',
  contact_last_name: '',
  email_address: '',
  contact_mobile_number: '',
  role: '',
};

function UserModal({ data, onClose, onSuccess }) {
  const isNew = !data?.id;

  const [validateOnChange, setValidateOnChange] = useState(false);

  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const [, roleList] = useRoleList({
    autoFetch: false,
  });

  const [isCreate, create] = useCreateUser({
    onSuccess: () => {
      toastSuccess('User Created');
      onSuccess();
      onClose();
    },
  });
  const [isUpdate, update] = useUpdateUser({
    id: data?.id,
    onSuccess: () => {
      toastSuccess('User Updated');
      onSuccess();
      onClose();
    },
  });

  const { values, setFieldValue, handleSubmit, errors, setFieldError } =
    useFormik({
      initialValues: isNew
        ? { ...INIT_VALUES, merchant_uuid: data?.merchantId }
        : {
            ...data,
            role: roleList?.find((role) => role?.name === data?.role)?.id || '',
            contact_first_name: data?.first_name,
            contact_last_name: data?.last_name,
            contact_mobile_number: data?.mobile_number,
          },
      validateOnChange,
      validationSchema,
      onSubmit: (form) => {
        if (isNew) {
          create(form, onSuccess, (err) => {
            setError422(err, setFieldError);
          });
          return;
        }
        update(form);
      },
    });

  return (
    <Form
      className="divide-y divide-gray-200"
      onSubmit={handleSubmit}
      error={errors}
    >
      <div>
        <div className="mb-3">
          <FormInput
            name="contact_first_name"
            onSetFieldValue={registerOnSetFieldValue(
              'contact_first_name',
              setFieldValue
            )}
            value={values.contact_first_name}
            label="First Name"
            required
          />
          <ErrorLabel name="contact_first_name" />
        </div>
        <div className="mb-3">
          <FormInput
            name="contact_last_name"
            onSetFieldValue={registerOnSetFieldValue(
              'contact_last_name',
              setFieldValue
            )}
            value={values.contact_last_name}
            label="Last Name"
            required
          />
          <ErrorLabel name="contact_last_name" />
        </div>
        <div className="mb-3">
          <FormInput
            name="email_address"
            onSetFieldValue={setFieldValue}
            value={values.email_address}
            label="Email"
            required
            disabled={!isNew}
          />
          <ErrorLabel name="email_address" />
        </div>
        <div className="mb-3">
          <FormMobileNumber
            name="contact_mobile_number"
            onSetFieldValue={setFieldValue}
            value={values.contact_mobile_number}
            label="Mobile No."
            required
          />
          <ErrorLabel name="contact_mobile_number" />
        </div>
        <div className="mb-3">
          <FormSelect
            name="role"
            onSetFieldValue={setFieldValue}
            value={values.role}
            label="Role"
            options={roleList.map((role) => ({
              label: role?.name,
              value: role?.id,
            }))}
            required
          />
          <ErrorLabel name="role" />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            size="lg"
            disabled={isCreate || isUpdate}
            onClick={() => setValidateOnChange(true)}
          >
            {isNew ? 'Add' : 'Save'}
          </Button>
        </div>
      </div>
    </Form>
  );
}

UserModal.defaultProps = {
  data: transformUser(),
  onSuccess: false,
};

UserModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClose: PropTypes.func.isRequired,
};

export default UserModal;
