import cn from 'classnames';
import { Link } from 'react-router-dom';
import React, { Fragment, useMemo } from 'react';
import { HiChevronRight } from 'react-icons/hi';
import { Menu, Transition } from '@headlessui/react';

import { useAccessControl } from 'modules/common/hooks';
import { useActiveAccount, useProfile } from 'modules/auth/hooks';

import ThumbIcon from './ThumbIcon';

const userNavigation = [
  { name: 'Account Settings', href: '/profile' },
  { name: 'Logout', href: '/logout' },
];

const MAP_USER_TYPE_TITLE = {
  org_user: 'Organization',
  sub_user: 'Merchant',
};

function HeaderProfile() {
  const [profile] = useProfile();
  const { userType } = useAccessControl();
  const [activeAccount] = useActiveAccount();

  const accountName = useMemo(
    () =>
      ({
        org_user: `${activeAccount?.owner_first_name} ${activeAccount?.owner_last_name}`,
        sub_user: `${profile?.first_name} ${profile?.last_name}`,
      }[userType]),
    [userType, activeAccount, profile]
  );

  const accountPhoto = useMemo(
    () =>
      ({
        org_user: activeAccount?.owner_photo_url,
        sub_user: '',
      }[userType]),
    [userType, activeAccount]
  );

  return (
    <Menu as="div" className="relative w-full">
      {({ open }) => (
        <>
          <Menu.Button className="flex w-full items-center space-x-3 rounded bg-white py-4 text-sm">
            <span className="sr-only">Open user menu</span>
            <ThumbIcon
              className="h-8 w-8 rounded-full !border !border-gray-300"
              url={accountPhoto}
              alt={accountName}
            />
            <div className="text flex flex-col text-left">
              <h6 className="text-xs font-medium text-primary-500">
                {accountName}
              </h6>
              <p className="text-xs text-gray-500">
                {activeAccount?.name ?? MAP_USER_TYPE_TITLE[userType]}
              </p>
            </div>
            <div className="!ml-auto pr-4">
              <div className="grid place-items-center rounded border bg-gray-50">
                <HiChevronRight className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items className="absolute left-1/2 bottom-20 z-50 w-11/12 -translate-x-1/2 rounded bg-white shadow">
              {/* <div className="flex space-x-2 border-b border-gray-100 p-5 text-gray-500">
                Signed in as
              </div>
              <div className="flex items-center space-x-2 border-b border-gray-100 px-5 py-5">
                <ThumbIcon
                  className="h-12 w-12 rounded-full"
                  url={accountPhoto}
                  alt={accountName}
                />
                <div>
                  <div className="">{accountName}</div>
                  <div className="break-all text-sm text-gray-500">
                    {profile.email_address}
                  </div>
                </div>
              </div> */}
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={cn(
                        active
                          ? 'bg-secondary-100 text-secondary-400'
                          : 'text-gray-500',
                        'block px-3 py-3 font-light'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

HeaderProfile.propTypes = {};

export default HeaderProfile;
