import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { HiEye } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { BiInfoCircle } from 'react-icons/bi';
import { BsCheckCircle } from 'react-icons/bs';
import { toastWarning } from 'react-geek-toast';

import Card from 'partial/components/Card';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import { useActiveAccount } from 'modules/auth/hooks';
import FormInlineSelect from 'partial/form/FormInlineSelect';

import LineChartSeries from 'modules/common/chart/LineChartSeries';
import { ModalCard, useCreateModal } from 'partial/components/Modal';

import * as c from '../constants';
import { openWebhookModal } from '../modals/WebhookModal';
import PaymentSimulatorModal from '../modals/PaymentSimulatorModal';
import { useTotalWebhook, useHistogramWebhook, useLogsWebhook } from '../hooks';

// import { openTestApiModal } from '../modals/TestApiModal';

const Label = ({ value }) => <div className="font-bold">{value}</div>;

Label.propTypes = {
  value: PropTypes.string.isRequired,
};

const Count = ({ label, value }) => (
  <div>
    <div className="flex items-center space-x-1 text-gray-500">
      <span>{label}</span>
      <BiInfoCircle />
    </div>
    <div className="text-lg font-bold">{value}</div>
  </div>
);

Count.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const Info = ({ label, value }) => (
  <div className="flex justify-between">
    <div>{label}</div>
    <div className="flex-shrink-0">{value}</div>
  </div>
);

Info.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
    .isRequired,
};

function IntegrationDashboard({ merchant }) {
  const [rawFilter, setFilter] = useState({
    date_column: 'created_at',
    event_type: '',
    status: 'POSTED',
    type: 'day',
  });
  const filter = useMemo(
    () => ({
      ...rawFilter,
      merchant_uuid: merchant?.id,
    }),
    [rawFilter, merchant]
  );
  const [activeAccount] = useActiveAccount();
  const [, totalWebhook] = useTotalWebhook(filter);
  const [isLogsLoading, logsWebhook] = useLogsWebhook(filter);
  const [, histogramWebhook] = useHistogramWebhook(filter);
  const createModal = useCreateModal('PAYMENT_SIMULATOR');
  const handleOpenSimulator = () => {
    createModal({
      content: (onClose) => (
        <ModalCard size="lg">
          <PaymentSimulatorModal onClose={onClose} />
        </ModalCard>
      ),
    });
    // openTestApiModal();
  };
  const handleOpenInstructions = () => {
    toastWarning('Disabled by admistrator!');
  };
  const tableFormat = React.useMemo(() => {
    const logsAction = (row) => (
      <a
        href="/"
        className="link-default flex items-center"
        onClick={(e) => {
          e.preventDefault();
          openWebhookModal(row);
        }}
      >
        <HiEye className="mr-1" />
        View
      </a>
    );
    const cols = [
      {
        label: 'Created At',
        id: 'created_at',
        key: (row) => <span>{row?.created_at}</span>,
      },
      {
        label: 'Ref No.',
        id: 'refno',
        key: 'refno',
      },
      {
        label: 'Callback URL',
        id: 'callback_url',
        key: (row) => (
          <a href={row?.callback_url} className="link-primkary">
            {row?.callback_url}
          </a>
        ),
      },
      {
        label: 'Elapsed Time',
        id: 'elapsed_time',
        key: 'elapsed_time',
      },
      {
        label: 'Event Type',
        id: 'event_type',
        key: (row) => (
          <span className={`${c.MAP_EVENT_TYPE_CLASS[row?.event_type]}`}>
            {c.MAP_EVENT_TYPE[row?.event_type]}
          </span>
        ),
      },
      {
        label: 'Status',
        id: 'status',
        key: (row) => (
          <span className={`${c.MAP_STATUS_CLASS[row?.status]}`}>
            {c.MAP_STATUS[row?.status]}
          </span>
        ),
      },
      {
        label: 'Action',
        id: 'action',
        key: logsAction,
      },
    ];
    return cols;
  }, []);
  // useFetchData(filter);
  return (
    <>
      <Card label="Your Integration">
        <div className="grid grid-cols-1 divide-x md:grid-cols-4">
          <div className="col-span-3 flex flex-col divide-y">
            <div className="space-y-2 p-5">
              <Label value="Merchant Webhook URL" />
              <div className="flex justify-between">
                <Count label="Total" value={`${totalWebhook?.total || 0}`} />
                <div className="flex space-x-2">
                  <FormInlineSelect
                    sm
                    name="event_type"
                    onChange={setFilter}
                    value={filter?.event_type}
                    options={c.EVENT_TYPE_OPTIONS}
                  />
                  <FormInlineSelect
                    sm
                    name="status"
                    onChange={setFilter}
                    value={filter?.status}
                    options={c.STATUS_OPTIONS}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 p-5">
              <LineChartSeries
                labels={histogramWebhook?.graph?.label}
                series={histogramWebhook?.graph?.series}
                colors={[
                  '#11b981',
                  '#f59e0b',
                  '#ef4444',
                  '#808080',
                  '#808080',
                  '#808080',
                  '#808080',
                ]}
              />
            </div>
          </div>
          <div className="divide-y">
            {/* <div className="p-5 space-y-2">
              <Label value="System Status" />
              <Info
                label="Payment Gateway"
                value={
                  <span className="text-green-500 font-semibold">Normal</span>
                }
              />
              <Info
                label="Invoice"
                value={
                  <span className="text-yellow-500 font-semibold">Slow</span>
                }
              />
              <Info
                label="Merchant Webhook URL"
                value={<span className="text-red-500 font-semibold">Down</span>}
              />
            </div> */}
            {activeAccount?.env_mode !== 'LIVE' ? (
              <div className="space-y-2 p-5">
                <Label value="Payment Simulator" />
                <div className="text-gray-500">
                  Use these test cards to test your integration.{' '}
                  {merchant?.approval_status === 'APPROVED' ? null : (
                    <>
                      Once you are ready,{' '}
                      <Link
                        className="text-primary hover:underline"
                        to="/merchants/profile/details"
                      >
                        activate your account
                      </Link>{' '}
                      to access live API Keys.
                    </>
                  )}
                </div>
                <div>
                  <Button secondary onClick={handleOpenSimulator}>
                    Open Payment Simulator
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-2 p-5">
                <Label value="Payment" />
                <div className="text-gray-500">
                  Follow payment instructions.
                </div>
                <div>
                  <Button secondary onClick={handleOpenInstructions}>
                    View Payment Instructions
                  </Button>
                </div>
              </div>
            )}

            <div className="space-y-2 p-5">
              <Label value="Recent Errors" />
              <div className="flex flex-col items-center justify-center space-y-3 p-5">
                <div className="text-green-500">
                  <BsCheckCircle className="h-8 w-8" />
                </div>
                <div className="font-semibold">Everything looks good</div>
                <div className="text-gray-500">
                  We found no errors in the last 7 days.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card label="Webhook Logs">
        <div className="flex flex-col" style={{ height: 400 }}>
          <Table
            isLoading={isLogsLoading}
            data={logsWebhook}
            format={tableFormat}
          />
        </div>
      </Card>
    </>
  );
}

IntegrationDashboard.propTypes = {
  merchant: PropTypes.instanceOf(Object).isRequired,
};

export default IntegrationDashboard;
