import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { BsReceipt } from 'react-icons/bs';

function InvoiceTour({ onNext }) {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/merchants/profile/invoice');
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <BsReceipt className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">
            Create payment via invoice
          </div>
          <div>Send invoice to email address</div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext('test-payment')}>
          Next
        </Button>
      </div>
    </div>
  );
}

InvoiceTour.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default InvoiceTour;
