import { toastError, toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import promiseDownload from 'utils/promiseDownload';
import { removeEmpty } from 'helper';
import * as c from './constants';
import * as t from './transformers';

export const listPayout = (params) => {
  req.get({
    key: c.PAYOUT_LIST,
    url: '/merchant_settlement/v1/my/settlements',
    params: removeEmpty(params),
    transform: (res) => (res.data || []).map(t.transformPayout),
    onSuccess: (res) => {
      req.set(c.PAYOUT_PAGER, res?.response?.meta || {});
      req.set(c.PAYOUT_FILTER, params);
    },
  });
};

export const appendListPayout = (params) => {
  req.get({
    key: c.PAYOUT_LIST,
    url: '/merchant_settlement/v1/my/settlements',
    params: removeEmpty(params),
    transform: (res, state) =>
      (state[c.PAYOUT_LIST] || []).concat(
        (res.data || []).map(t.transformPayout)
      ),
    onSuccess: (res) => {
      req.set(c.PAYOUT_PAGER, res?.response?.meta || {});
      req.set(c.PAYOUT_FILTER, params);
    },
  });
};

export const downloadPayout = async (id, filename, onSuccess, onError) => {
  try {
    await promiseDownload(
      `/merchant_settlement/v1/my/settlements/${id}/download`,
      filename
    );
    toastSuccess('Downloading...');
    onSuccess();
  } catch (err) {
    onError();
    toastError(err?.error_description || err?.message || 'Unable to download!');
  }
};

export const listPayoutLogs = (id, params) => {
  req.get({
    key: c.PAYOUT_LOGS_LIST,
    url: `/merchant_settlement/v1/my/settlements/${id}/logs`,
    params,
    transform: (res) => (res.data || []).map(t.transformPayoutLogs),
    onSuccess: (res) => {
      req.set(c.PAYOUT_LOGS_PAGER, res?.response?.meta || {});
      req.set(c.PAYOUT_LOGS_FILTER, params);
    },
  });
};

export const getCountCard = (params) => {
  req.get({
    key: `${c.PAYOUT_COUNT_CARD}/total_payout`,
    params,
    url: '/merchant_aggs/v1/aggs/total_settlements',
    transform: ({ data }) => ({
      value: data?.total_payout,
    }),
    onSuccess: (res) => {
      req.set(`${c.PAYOUT_COUNT_CARD}/total_received`, {
        value: res?.response?.data?.total_received,
      });
    },
  });
};
