import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormik } from 'formik';

import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import Form, { ErrorLabel } from 'partial/form/Form';
import { useUpdateAccount } from 'modules/auth/hooks';
import FormPhoto from 'partial/form/FormPhoto';
import FormDate from 'partial/form/FormDate';
import FormPhotoDnd from 'partial/form/FormPhotoDnd';

function UpdateOwnerModal({ onClose, data }) {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [isUpdating, update] = useUpdateAccount();

  const { handleSubmit, values, setFieldValue } = useFormik({
    validateOnChange,
    initialValues: data,
    onSubmit: (form) => {
      update(form, () => {
        onClose();
      });
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">
        <FormPhoto
          className="h-32 w-32 rounded-lg"
          imgClassName="object-cover object-center rounded-full"
          name="owner_photo_url"
          onSetFieldValue={setFieldValue}
          value={values?.owner_photo_url}
          label="Profile Photo"
          crop="free"
          resize="256x"
        />
      </div>
      <div className="mb-3 sm:mb-8">
        <FormInput
          name="owner_first_name"
          onSetFieldValue={setFieldValue}
          value={values.owner_first_name}
          label="First Name"
          type="text"
          required
        />
        <ErrorLabel name="owner_first_name" />
      </div>
      <div className="mb-3">
        <FormInput
          name="owner_middle_name"
          onSetFieldValue={setFieldValue}
          value={values.owner_middle_name}
          label="Middle Name"
          type="text"
        />
        <ErrorLabel name="owner_middle_name" />
      </div>
      <div className="mb-3">
        <FormInput
          name="owner_last_name"
          onSetFieldValue={setFieldValue}
          value={values.owner_last_name}
          label="Last Name"
          type="text"
          required
        />
        <ErrorLabel name="owner_last_name" />
      </div>
      <div className="mb-3">
        <FormDate
          name="owner_birth_date"
          onSetFieldValue={setFieldValue}
          value={values.owner_birth_date}
          label="Birth Date"
          type="text"
          required
        />
        <ErrorLabel name="owner_birth_date" />
      </div>
      <div className="mb-3">
        <FormPhotoDnd
          className="h-[176px]"
          name="owner_valid_id_url"
          onSetFieldValue={setFieldValue}
          value={values?.owner_valid_id_url}
          label="ID Photo"
          required
        />
        <ErrorLabel name="owner_valid_id_url" />
      </div>
      <div className="flex justify-end space-x-2 border-t pt-4">
        <Button className="mr-2" onClick={onClose} disabled={isUpdating}>
          Close
        </Button>
        <Button
          primary
          type="submit"
          disabled={isUpdating}
          onClick={() => setValidateOnChange(true)}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

UpdateOwnerModal.defaultProps = {
  data: { name: '' },
};

UpdateOwnerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
};

export default UpdateOwnerModal;
