import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
// import { BiTrendingUp } from 'react-icons/bi';
import SpanLoader from 'partial/components/SpanLoader';

const SUB_VALUE_THEME_MAP = {
  danger: 'text-red-500',
  success: 'text-green-500',
  warning: 'text-yellow-400',
  default: 'text-gray-400',
};

function CountCard({
  name,
  label,
  icon: Icon,
  defaultValue,
  iconClassName,
  hook: useCountCard,
  filter,
}) {
  const [isLoading, value, meta] = useCountCard(name, filter, defaultValue);

  return (
    <div className="relative rounded-lg border p-4">
      <dt>
        <p className="truncate text-xs leading-4 text-gray-400">
          <SpanLoader isLoading={isLoading} width="80px">
            {label}
          </SpanLoader>
        </p>
      </dt>
      <dd className="mt-2 flex items-baseline space-x-2 md:block lg:flex">
        <div className="flex items-baseline text-lg font-medium text-gray-800 sm:text-2xl">
          <SpanLoader isLoading={isLoading} width="120px">
            {value ?? <span>-</span>}
          </SpanLoader>
        </div>
        {!!Icon && (
          <div
            className={cn(
              'absolute right-0 top-1/2 -translate-y-1/2 transform rounded-lg p-2 sm:p-3',
              { 'animate-pulse': isLoading },
              iconClassName
            )}
          >
            <Icon
              className="h-12 w-12 text-white sm:h-16 sm:w-16"
              aria-hidden="true"
            />
          </div>
        )}
      </dd>
      {meta?.sub_value && (
        <dt>
          <p
            className={cn(
              'mt-1 truncate text-xs',
              SUB_VALUE_THEME_MAP[meta?.sub_value_theme] ??
                SUB_VALUE_THEME_MAP.default
            )}
          >
            <SpanLoader isLoading={isLoading} width="80px">
              {meta?.sub_value}
            </SpanLoader>
          </p>
        </dt>
      )}
    </div>
  );
}

CountCard.defaultProps = {
  iconClassName: '',
  hook: () => [false, null],
  filter: {},
  icon: null,
  defaultValue: {},
};

CountCard.propTypes = {
  hook: PropTypes.func,
  iconClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    meta: PropTypes.shape({
      sub_label: PropTypes.string,
      sub_label_theme: PropTypes.oneOf(['default', 'success']),
    }),
  }),
  filter: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
};

export default CountCard;
