import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import { BiArrowBack } from 'react-icons/bi';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'partial/components/Button';
import Form, { setError422 } from 'partial/form/Form';
import BreadCrumb from 'partial/components/BreadCrumb';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { useActiveAccount, useProfile } from 'modules/auth/hooks';

import InvoiceForm from './InvoiceForm';
import { useCreateInvoice } from '../hooks';
import { getValidationSchema } from '../constants';
import { useOpenPreviewAndSendModal } from '../modals/InvoicePreviewModal';
// import MerchantCard from './MerchantCard';
// import InvoicePreview from './InvoicePreview';

const initialValues = {
  attachments: [],
  items: [],
  merchant: null,
  recipient_name: '',
  contact_mobile_number: '',
  email: '',
  remarks: '',
  due_date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
  status: 'NEW',
};

function CreateInvoice({ onGoBack }) {
  const location = useLocation();
  const history = useHistory();

  const willSubmitAndCreateAnotheRef = useRef(false);

  const [profile] = useProfile();
  const [activeAccount] = useActiveAccount();

  useEffect(() => {
    return () => {
      req.set(`${MERCHANT_ITEM}/quick`, {});
    };
  }, []);

  const [isSaving, create] = useCreateInvoice();
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const openPreviewAndSendModal = useOpenPreviewAndSendModal();

  const { values, setFieldValue, handleSubmit, errors, setValues, resetForm } =
    useFormik({
      initialValues: {
        ...initialValues,
        ...(location?.state || {}),
      },
      validationSchema: getValidationSchema(activeAccount?.env_mode),
      onSubmit: async (form, { setFieldError }) => {
        create(
          form,
          (newData) => {
            if (willSubmitAndCreateAnotheRef?.current) {
              const { email = '', merchant } = form;
              const nextInitValues =
                activeAccount?.env_mode === 'LIVE'
                  ? {
                      ...initialValues,
                      ...(location?.state || {}),
                      merchant,
                    }
                  : {
                      ...initialValues,
                      ...(location?.state || {}),
                      merchant,
                      email,
                    };
              resetForm({
                values: nextInitValues,
              });
              setValidateOnChange(false);
              openPreviewAndSendModal(newData);
              willSubmitAndCreateAnotheRef.current = false;
              return;
            }
            setValues(newData);
            history.push({
              pathname: location.pathname.replace('/create', `/${newData?.id}`),
              state: { preview: true },
            });
          },
          (err) => {
            setError422(err, setFieldError);
          }
        );
      },
      validateOnChange,
    });

  const clearForm = () => {
    resetForm({ values: null });
    setFieldValue('contact_mobile_number', '63');
  };

  const actionButton = () => {
    return (
      <div className="flex w-full flex-col space-y-2 sm:w-auto sm:flex-row sm:space-x-2 sm:space-y-0">
        <Button
          className="w-full sm:w-auto"
          type="button"
          disabled={isSaving}
          onClick={clearForm}
        >
          <span className="whitespace-nowrap">Clear</span>
        </Button>
        <Button
          className="w-full sm:w-auto"
          type="submit"
          disabled={isSaving}
          primary
          onClick={() => {
            willSubmitAndCreateAnotheRef.current = false;
            setValidateOnChange(true);
          }}
        >
          <span className="whitespace-nowrap">Submit Invoice</span>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (activeAccount.env_mode === 'TEST') {
      setFieldValue(
        'contact_mobile_number',
        activeAccount?.representative_contact_number
      );
      setFieldValue('email', profile?.email_address);
      setFieldValue('recipient_name', activeAccount?.representative_name);
    }
  }, [profile, activeAccount, setFieldValue]);

  if (!values?.status)
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between space-x-2">
          <Button
            transparent
            icon={BiArrowBack}
            onClick={onGoBack}
            className="flex items-center text-sm font-normal text-black hover:text-primary-500"
          >
            Go Back
          </Button>
        </div>
        <div className="text-center text-gray-500">Loading invoice...</div>
      </div>
    );

  return (
    <>
      <BreadCrumb>Create Invoice</BreadCrumb>
      <Form
        onSubmit={handleSubmit}
        error={errors}
        noValidate
        className="space-y-4"
      >
        <div>
          <button
            type="button"
            onClick={onGoBack}
            className="flex items-center text-sm font-normal text-black hover:text-primary-500"
          >
            <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
            Go Back
          </button>
        </div>
        <div className="col-span-3 w-full max-w-3xl rounded-xl border bg-white px-4 pt-5 pb-8 sm:px-7">
          <div className="flex flex-col justify-center divide-x-0 sm:divide-x lg:flex-row lg:space-x-8">
            <div className="max-w-4xl flex-1 space-y-4">
              <div className="flex items-center justify-between">
                <div className="title-h2">Create Invoice</div>
              </div>
              <div>
                <InvoiceForm
                  setFieldValue={setFieldValue}
                  values={values}
                  canEditMerchant
                />
              </div>
              <div className="flex sm:justify-end">{actionButton()}</div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

CreateInvoice.propTypes = {
  onGoBack: PropTypes.func.isRequired,
};

export default CreateInvoice;
