/* eslint-disable jsx-a11y/control-has-associated-label */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IoImageOutline } from 'react-icons/io5';
import ToolTip from 'partial/components/ToolTip';
import { Popover, Transition } from '@headlessui/react';

export const BACKGROUND = {
  default:
    'https://ucarecdn.com/23c38ef6-75d8-4a82-9b2e-ad81f4483461/-/resize/128x/',
  bg2:
    'https://ucarecdn.com/9d254fa3-868a-4bc0-8ddf-fbc136318740/-/resize/128x/',
  bg3:
    'https://ucarecdn.com/45e6540c-96ab-4ac2-92c4-d2de50580cab//-/resize/128x/',
  bg4:
    'https://ucarecdn.com/71065eb7-d518-4411-b824-43a7611d101f/-/resize/128x/',
  bg5:
    'https://ucarecdn.com/9a8c1be3-e36e-41a9-b90b-4548343a015a/-/resize/128x/',
  bg6:
    'https://ucarecdn.com/5b3511e0-717d-4e06-9196-c0e184237f1d/-/resize/128x/',
  bg7:
    'https://ucarecdn.com/dfb574e8-c2df-43d8-8b98-20840c095618/-/resize/128x/',
  bg8:
    'https://ucarecdn.com/3caf20f8-7e62-466f-be50-7c552fc4201c/-/resize/128x/',
};

const Backgrounds = ({ name, onChange, value, backgroundColor }) => {
  const handleSelect = (code) => {
    onChange((state) => ({ ...state, [name]: code }));
  };
  return (
    <div className="grid grid-cols-4 gap-3">
      {Object.entries(BACKGROUND).map(([code, row]) => (
        <BackgroundItem
          key={code}
          code={code}
          onClick={handleSelect}
          isActive={value === code}
          thumbSrc={row}
          backgroundColor={backgroundColor}
        />
      ))}
    </div>
  );
};

Backgrounds.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

const BackgroundItem = ({
  code,
  onClick,
  isActive,
  thumbSrc,
  backgroundColor,
}) => {
  return (
    <div
      className={cn(
        'rounded-lg overflow-hidden border relative ring-2 ',
        isActive ? 'ring-primary-500' : 'ring-transparent'
      )}
    >
      <div className="aspect-w-1 aspect-h-1">
        <div
          className="absolute mix-blend-color inset-0 h-full w-full z-10 opacity-80"
          style={{ backgroundColor }}
        />
        <img
          className="h-full w-full object-cover object-center bg-gray-300 grayscale"
          src={thumbSrc}
          alt=""
        />
      </div>
      <button
        className="absolute z-10 inset-0 h-full w-full"
        type="button"
        onClick={() => onClick(code)}
      />
    </div>
  );
};

BackgroundItem.propTypes = {
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  thumbSrc: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

function FormInlineBackgroundPattern({
  name,
  onChange,
  value,
  label,
  description,
  backgroundColor,
}) {
  return (
    <div className="flex items-center space-x-2 justify-between relative">
      <ToolTip tip={description}>
        <div className="flex relative">
          <div
            className={cn(
              'rounded border-dashed  border-b border-white  flex items-center space-x-2 cursor-pointer text-gray-600 font-semibold',
              description ? 'group-hover:bg-gray-100 hover:border-gray-300' : ''
            )}
          >
            <div className="">{label}</div>
            {description ? <BiInfoCircle /> : null}
          </div>
        </div>
      </ToolTip>
      <Popover>
        {({ open }) => (
          <>
            <div className="border p-px rounded-lg hover:border-gray-400 transition">
              <Popover.Button className="flex items-center group">
                {BACKGROUND[value] ? (
                  <div className="w-20 h-14 relative rounded-md bg-gray-300">
                    <div
                      className="absolute mix-blend-color inset-0 h-full w-full z-10"
                      style={{ backgroundColor }}
                    />
                    <div
                      className="absolute h-full w-full inset-0 bg-center bg-cover rounded-md"
                      style={{ backgroundImage: `url(${BACKGROUND[value]})` }}
                    />
                  </div>
                ) : (
                  <IoImageOutline className="w-20 h-14 text-gray-200 group-hover:text-gray-400 transition p-4 flex-shrink-0" />
                )}
              </Popover.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
              show={open}
            >
              <Popover.Panel className="absolute z-30 inset-x-0 transform">
                <div className="relative top-1 rounded-lg overflow-hidden bg-white w-[340px] right-1 ring-0 shadow-primary-lg border">
                  <div className="p-4">
                    <Backgrounds
                      name={name}
                      onChange={onChange}
                      value={value}
                      backgroundColor={backgroundColor}
                    />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

FormInlineBackgroundPattern.defaultProps = {
  description: '',
  backgroundColor: '',
};

FormInlineBackgroundPattern.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

export default FormInlineBackgroundPattern;
