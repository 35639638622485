import qs from 'query-string';
import { each, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { generateShades } from 'utils/color';

export const useThemePreview = (params) => {
  const [code, setCode] = useState({});
  useEffect(() => {
    const query = qs.parse(params);
    const brandColors = generateShades(query?.color_bg, '--color-theme-brand');
    const accentColors = generateShades(
      query?.color_accent,
      '--color-theme-accent'
    );
    if (query.color_bg !== 'undefined') {
      each(brandColors, (v, k) => {
        document.documentElement.style.setProperty(k, v);
      });
      each(accentColors, (v, k) => {
        document.documentElement.style.setProperty(k, v);
      });
    }
    setCode(
      pick(query, [
        'bg',
        'color_bg',
        'color_accent',

        'source',
        'source_photo',
        'amount',
      ])
    );
  }, [params, setCode]);
  return code;
};
