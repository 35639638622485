/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from 'partial/components/Button';
import { useAccountMeta, useUpdateAccountBank } from 'modules/auth/hooks';

import BankForm from '../forms/BankForm';
import TestModeCard from '../components/TestModeCard';

const BankPage = (props) => {
  const history = useHistory();
  const formRef = useRef();

  const { meta, update } = useAccountMeta();
  const [isSubmitting, submit] = useUpdateAccountBank();

  return (
    <div>
      <BankForm
        {...props}
        ref={formRef}
        onSubmit={submit}
        onSuccess={(v) => {
          props?.onNext(v);
          if (!meta?.is_org_bank_details_submitted)
            update({ is_org_bank_details_submitted: true });
        }}
      />
      <div className="mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0">
        <div className="sm:hidden">
          <TestModeCard />
        </div>
        <Button
          size="lg"
          transparent
          className="hidden w-full sm:block sm:w-auto"
          onClick={() => props?.onBack()}
        >
          <div className="hover:border-b-1 flex cursor-pointer items-center space-x-2 border-b-0 border-dashed text-gray-700 hover:border-gray-300">
            Back
          </div>
        </Button>
        {!props?.readOnly ? (
          <div className="flex w-full flex-col items-center justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onNext}
              >
                <span className="whitespace-nowrap text-gray-600">
                  Skip, I&apos;ll do this later
                </span>
              </Button>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
              className="order-2 w-full whitespace-nowrap sm:order-1 sm:w-auto"
            >
              Save and Continue
            </Button>
          </div>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

BankPage.defaultProps = {
  readOnly: false,
};

BankPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default BankPage;
