/* eslint-disable react/prop-types, no-shadow */
import React from 'react';
import { Route, Link } from 'react-router-dom';

const NavLink = ({
  to,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  inactiveClassName,
  style,
  isActive: getIsActive,
  children,
  ...rest
}) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    location={location}
  >
    {({ location, match }) => {
      const isActive = !!(getIsActive ? getIsActive(match, location) : match);
      const moddedClassName = `${className || ''} ${
        isActive ? activeClassName : inactiveClassName
      }`;
      return (
        <Link
          to={to}
          className={moddedClassName}
          style={isActive ? { ...style, ...activeStyle } : style}
          {...rest}
        >
          {typeof children === 'function' ? children({ isActive }) : children}
        </Link>
      );
    }}
  </Route>
);

NavLink.defaultProps = {
  activeClassName: 'active',
};

export default NavLink;
