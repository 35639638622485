import React from 'react';
import PropTypes from 'prop-types';
import Button from 'partial/components/Button';
import img from '../assets/tutorial-3.svg';
import TourDots from '../TourDots';

function SetThemeModal({ onNext }) {
  return (
    <div className="">
      <div className="bg-gray-100 p-5 rounded-b-xl">
        <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row items-center">
          <div className="flex-shrink-0 h-60 w-60 sm:ml-8 ml-0 sm:mr-4 mr-0">
            <img className="w-60" src={img} alt="" />
          </div>
          <div className="space-y-1 text-gray-700 text-center sm:text-left">
            <div className="text-lg sm:text-xl font-bold text-gray-900">
              Customize Themes
            </div>
            <div>
              Setup your theme for your invoice payments to engage more with
              your customers using our curated custom theme designs.
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end bg-white py-3 px-5 space-x-3">
        <div className="mr-auto">
          <TourDots />
        </div>
        <Button onClick={() => onNext()} link>
          Skip for now
        </Button>
        <Button onClick={() => onNext('theme-tour')} primary>
          Continue
        </Button>
      </div>
    </div>
  );
}

SetThemeModal.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default SetThemeModal;
