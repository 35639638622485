import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { req } from 'react-reqq-lite';
import { formatNumber, formatSort, removeEmpty, reqLoader } from 'helper';
import * as c from './constants';
import {
  transformInvoiceChartAggregation,
  transformChartAggregation,
  transformInvoiceAggregations,
  transformDashboardHistogram,
  transformDashboardScheme,
  transformDashboardTop,
  transformTransaction,
  transformTransactionFilter,
  transformTransactionOptions,
} from './transformers';

export const getCountCard = {
  total_merchants: () => {
    req.get({
      key: `${c.COUNT_CARD}/total_merchants`,
      url: '/profile/v1/my/merchants',
      params: {
        per_page: 1,
      },
      transform: (res) => ({
        value: formatNumber(res?.meta?.total ?? 0, 0),
      }),
    });
  },
  total_transactions: () => {
    reqLoader(`${c.COUNT_CARD}/total_fee`, true);
    reqLoader(`${c.COUNT_CARD}/total_amount`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_transactions`,
      url: '/merchant_aggs/v1/aggs/total_transactions',
      params: {
        payment_status: 'PAID',
        per_page: 1,
        from: '2019-01-01',
        to: dayjs().format('YYYY-MM-DD'),
      },
      transform: (res) => ({
        value: res?.data?.total_transaction,
      }),
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_fee`, {
          value: res?.response?.data?.total_partner_fee,
        });
        req.set(`${c.COUNT_CARD}/total_amount`, {
          value: res?.response?.data?.total_amount,
        });
        reqLoader(`${c.COUNT_CARD}/total_fee`, false);
        reqLoader(`${c.COUNT_CARD}/total_amount`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_fee`, false);
        reqLoader(`${c.COUNT_CARD}/total_amount`, false);
      },
    });
  },
};

export const getChartAggregations = (params) => {
  req.get({
    key: c.DASHBOARD_CHART_AGGREGATIONS,
    url: '/merchant_aggs/v1/aggs/histogram/transactions',
    params,
    transform: (res) => (res?.data || []).map(transformChartAggregation),
  });
};

const paramsInvoiceAggs = ({ payment_status, ...rest }) =>
  removeEmpty({
    ...rest,
    status: payment_status || '',
  });

export const getInvoiceChartAggregations = (params) => {
  req.get({
    key: `${c.DASHBOARD_CHART_AGGREGATIONS}/invoice`,
    url: '/merchant_aggs/v1/aggs/histogram/invoices',
    params: paramsInvoiceAggs(params),
    transform: (res) => (res?.data || []).map(transformInvoiceChartAggregation),
  });
};

export const getInvoiceAggregations = (params) => {
  req.get({
    key: `${c.DASHBOARD_TOTAL_AGGREGATIONS}/invoice`,
    url: '/merchant_aggs/v1/aggs/total_invoices',
    params: paramsInvoiceAggs(params),
    transform: (res) => transformInvoiceAggregations(res?.data),
  });
};

export const getTotalAggregations = (params) => {
  req.get({
    key: c.DASHBOARD_TOTAL_AGGREGATIONS,
    url: '/merchant_aggs/v1/aggs/total_transactions',
    params,
    transform: (res) => ({
      total_amount: res?.data?.total_amount,
      total_channel_fee: res?.data?.total_channel_fee,
      total_partner_fee: res?.data?.total_partner_fee,
      total_system_fee: res?.data?.total_system_fee,
      total_transaction: res?.data?.total_transaction,
    }),
  });
};

export const getDashboardHistogram = (params, id = 'default') => {
  req.get({
    key: `${c.DASHBOARD_HISTOGRAM}/${id}`,
    url: '/merchant_aggs/v1/aggs/histogram/transactions',
    params,
    transform: transformDashboardHistogram(params),
  });
};

export const getDashboardScheme = (params, id) => {
  req.get({
    key: `${c.DASHBOARD_SCHEME}/${id}`,
    url: '/merchant_aggs/v1/aggs/histogram/transactions',
    params,
    transform: transformDashboardScheme(params),
  });
};

export const getDashboardTop = (params, id) => {
  req.get({
    key: `${c.DASHBOARD_TOP}/${id}`,
    url: '/merchant_aggs/v1/aggs/top/transactions',
    params,
    transform: transformDashboardTop(params),
  });
};

export const listTransaction = (params) => {
  req.get({
    key: c.TRANSACTION_LIST,
    url: '/merchant_transaction/v1/transactions',
    params: transformTransactionFilter(formatSort(params)),
    transform: (res) => (res.data || []).map(transformTransaction),
    onSuccess: (res) => {
      req.set(c.TRANSACTION_PAGER, res?.response?.meta || {});
      req.set(c.TRANSACTION_FILTER, params);
    },
  });
};

export const loadTransactionOptions = debounce((keyword, callback) => {
  req.get({
    key: `${c.TRANSACTION_LIST}/options`,
    url: '/merchant_transaction/v1/transactions',
    params: { keyword, per_page: 10 },
    transform: () => null,
    onSuccess: (res) => {
      callback((res?.response?.data || []).map(transformTransactionOptions));
    },
    onError: () => {
      callback([]);
    },
  });
}, 500);

export const appendListTransaction = (params) => {
  req.get({
    key: c.TRANSACTION_LIST,
    url: '/merchant_transaction/v1/transactions',
    params: transformTransactionFilter(formatSort(params)),
    transform: (res, state) =>
      (state[c.TRANSACTION_LIST] || []).concat(
        (res.data || []).map(transformTransaction)
      ),
    onSuccess: (res) => {
      req.set(c.TRANSACTION_PAGER, res?.response?.meta || {});
      req.set(c.TRANSACTION_FILTER, params);
    },
  });
};
