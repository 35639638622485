import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import GenericDetails from 'partial/components/GenericDetails';
import { ModalCard, useCreateModal } from 'partial/components/Modal';

import { REFUND_DETAILS_FORMAT } from '../constants';
import { refundRequestStatusTableKey } from '../components/RefundApprovalStatus';

export const useOpenViewRefundModal = () => {
  const createModal = useCreateModal();

  const openViewRefundModal = useCallback(
    (data) => {
      createModal({
        content: (close) => <ViewRefundModal data={data} onClose={close} />,
      });
    },
    [createModal]
  );

  return openViewRefundModal;
};

function ViewRefundModal({ onClose, data }) {
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);

  return (
    <ModalCard size="lg" title={data?.refno} onClose={onClose}>
      <div className="p-4 flex flex-col space-y-4 sm:space-y-0 sm:space-x-4 h-full sm:divide-x-2 sm:flex-row  max-h-[75vh] overflow-y-auto">
        <div className="w-72 flex-shrink-0">
          <GenericDetails
            numberOfCols={1}
            data={data}
            format={[
              ...REFUND_DETAILS_FORMAT,
              {
                label: 'Refund Status',
                key: refundRequestStatusTableKey,
              },
            ]}
          />
        </div>
        <div className="space-y-4 flex flex-col flex-grow sm:pl-4">
          <div className="p-4 border rounded-lg h-36 overflow-auto break-words">
            {data?.reason}
          </div>
          <div className="grid sm:grid-cols-3 space-y-2 sm:space-y-0 sm:space-x-4 flex-grow sm:h-0 sm:overflow-y-auto">
            <div className="sm:col-span-1 space-y-4 flex flex-col h-full">
              <div className="text-gray-500 font-semibold">Attachments</div>
              <div className="flex flex-col space-y-2 flex-grow sm:h-0 overflow-auto">
                {data?.attachments?.map((_, i) => (
                  <div
                    className={cn(
                      'rounded-lg border bg-gray-100 px-4 py-2 hover:border-primary-200 relative flex items-center justify-center',
                      selectedAttachmentIndex === i && 'border-primary-200'
                    )}
                  >
                    <button
                      aria-label="select attachment"
                      type="button"
                      className="absolute inset-0 w-full h-full"
                      onClick={() => setSelectedAttachmentIndex(i)}
                    />
                    Attachment {i + 1}
                  </div>
                ))}
              </div>
            </div>
            <div className="sm:col-span-2">
              <img
                alt="attachments"
                className="w-full border rounded-lg"
                src={data?.attachments[selectedAttachmentIndex]}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalCard>
  );
}

ViewRefundModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewRefundModal;
