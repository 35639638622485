/* eslint-disable no-useless-escape */
export const GENERATE_PAYMENT = {
  body: [
    {
      key: 'items',
      type: 'Array',
      description: 'Contains a list of item information',
      required: true,
      children: [
        {
          key: 'name',
          type: 'String',
          description: 'Name of the item',
          required: true,
        },
        {
          key: 'amount',
          type: 'Double',
          description: 'Amount of the item',
          required: true,
        },
      ],
    },
    {
      key: 'redirect_url',
      type: 'URL',
      description: 'URL to redirect when the payment has been processed',
      required: true,
    },
    {
      key: 'txnid',
      type: 'String',
      description: 'The transaction ID generated by the biller',
      required: true,
    },
    {
      key: 'callback_url',
      type: 'URL',
      description:
        'The biller URL that will be communicated for every transaction',
      required: true,
    },
    {
      key: 'digest',
      type: 'String',
      description:
        'The computed hash of the transaction that includes the token. hash_hmac(\'sha256\', "$amount|$txnid", $token)',
      required: true,
    },
    {
      key: 'mobile',
      type: 'String',
      description: 'Mobile number of the customer (for ereceipt)',
      required: false,
    },
    {
      key: 'expires_at',
      type: 'Date Time',
      description:
        'The expiration of the transaction. E.g format (YYYY-MM-DD HH:MM:SS)',
      required: false,
    },
    {
      key: 'description',
      type: 'JSON Object',
      description: 'Additional information of the transaction',
      required: false,
    },
  ],
  snippet: [
    {
      label: 'Curl',
      code_string: `
## Generate Payment Gateway Link
curl -X "POST" "https://{{HOST}}/api/v1/transaction" \
      -H 'X-MultiPay-Token: {{API_KEY}}' \
      -H 'Content-Type: application/json; charset=utf-8' \
      -H 'Cookie: cross-site-cookie=bar' \
      -d $'{
  "amount": "1000",
  "digest": "9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986",
  "mobile": "09165523448",
  "expires_at": "2023-01-01 23:59:59",
  "callback_url": "https://mpayv2.free.beeceptor.com/500",
  "txnid": "MP1234567",
  "link_expires_at": "2023-01-01 23:59:59",
  "email": "test@email.com",
  "name": "Test",
  "items": [
    {
      "name": "Item # 1",
      "amount": "1000"
    }
  ]
}'
      `,
    },
    {
      label: 'C#',
      code_string: `
using System;
using System.Threading.Tasks;
using System.Net;
using System.IO;
using System.Text;

namespace MyNamespace {
  public class MyActivity {

    private async Task<bool> GeneratePaymentGatewayLink () {

      string url = "https://{{HOST}}/api/v1/transaction";

      HttpWebRequest request = (HttpWebRequest)WebRequest.Create (new Uri(url));
      request.ContentType = "application/json; charset=utf-8";
      request.Headers.Add("X-MultiPay-Token", "{{API_KEY}}");
      request.Headers.Add("Cookie", "cross-site-cookie=bar");

      request.Method = "POST";

      string postData = "{\"txnid\":\"MP1234567\",\"amount\":\"1000\",\"items\":[{\"name\":\"Item # 1\",\"amount\":\"1000\"}],\"callback_url\":\"https://mpayv2.free.beeceptor.com/500\",\"digest\":\"9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986\",\"expires_at\":\"2023-01-01 23:59:59\",\"link_expires_at\":\"2023-01-01 23:59:59\",\"name\":\"Test\",\"mobile\":\"09165523448\",\"email\":\"test@email.com\"}";
      ASCIIEncoding encoding = new ASCIIEncoding ();
      byte[] byte1 = encoding.GetBytes (postData);
      request.ContentLength = byte1.Length;
      Stream newStream = request.GetRequestStream ();
      newStream.Write (byte1, 0, byte1.Length);
      newStream.Close ();

      using (WebResponse response = await request.GetResponseAsync ()) {
        using (Stream stream = response.GetResponseStream ()) {
          return true;
          //process the response
        }
      }
    }
  }
}
    `,
    },
    {
      label: 'Javascript (NodeJS)',
      code_string: `
// request Generate Payment Gateway Link
(function(callback) {
    'use strict';

    const httpTransport = require('https');
    const responseEncoding = 'utf8';
    const httpOptions = {
        hostname: 'https://{{HOST}}',
        port: '443',
        path: '/api/v1/transaction',
        method: 'POST',
        headers: {"X-MultiPay-Token":"{{API_KEY}}","Content-Type":"application/json; charset=utf-8","Cookie":"cross-site-cookie=bar"}
    };
    httpOptions.headers['User-Agent'] = 'node ' + process.version;

    // Paw Store Cookies option is not supported

    const request = httpTransport.request(httpOptions, (res) => {
        let responseBufs = [];
        let responseStr = '';

        res.on('data', (chunk) => {
            if (Buffer.isBuffer(chunk)) {
                responseBufs.push(chunk);
            }
            else {
                responseStr = responseStr + chunk;
            }
        }).on('end', () => {
            responseStr = responseBufs.length > 0 ?
                Buffer.concat(responseBufs).toString(responseEncoding) : responseStr;

            callback(null, res.statusCode, res.headers, responseStr);
        });

    })
    .setTimeout(0)
    .on('error', (error) => {
        callback(error);
    });
    request.write("{\"txnid\":\"MP1234567\",\"amount\":\"1000\",\"items\":[{\"name\":\"Item # 1\",\"amount\":\"1000\"}],\"callback_url\":\"https://mpayv2.free.beeceptor.com/500\",\"digest\":\"9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986\",\"expires_at\":\"2023-01-01 23:59:59\",\"link_expires_at\":\"2023-01-01 23:59:59\",\"name\":\"Test\",\"mobile\":\"09165523448\",\"email\":\"test@email.com\"}")
    request.end();


})((error, statusCode, headers, body) => {
    console.log('ERROR:', error);
    console.log('STATUS:', statusCode);
    console.log('HEADERS:', JSON.stringify(headers));
    console.log('BODY:', body);
});
      `,
    },
    {
      label: 'PHP (Guzzle)',
      code_string: `
<?php

// Include Guzzle. If using Composer:
// require 'vendor/autoload.php';

use GuzzleHttp\Pool;
use GuzzleHttp\Client;
use GuzzleHttp\Psr7\Request;

$client = new Client();

$request = new Request(
        "POST",
        "https://{{HOST}}/api/v1/transaction",
        [
            "X-MultiPay-Token" => "{{API_KEY}}",
            "Content-Type" => "application/json; charset=utf-8",
            "Cookie" => "cross-site-cookie=bar"
        ],
        "{\"txnid\":\"MP1234567\",\"amount\":\"1000\",\"items\":[{\"name\":\"Item # 1\",\"amount\":\"1000\"}],\"callback_url\":\"https://mpayv2.free.beeceptor.com/500\",\"digest\":\"9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986\",\"expires_at\":\"2023-01-01 23:59:59\",\"link_expires_at\":\"2023-01-01 23:59:59\",\"name\":\"Test\",\"mobile\":\"09165523448\",\"email\":\"test@email.com\"}");

$response = $client->send($request);
echo "Response HTTP : " . $response->getStatusCode() . "
";
      `,
    },
    {
      label: 'Python',
      code_string: `
# Install the Python Requests library:
# 'pip install requests'

import requests
import json


def send_request():
    # Generate Payment Gateway Link
    # POST https://{{HOST}}/api/v1/transaction

    try:
        response = requests.post(
            url="https://{{HOST}}/api/v1/transaction",
            headers={
                "X-MultiPay-Token": "{{API_KEY}}",
                "Content-Type": "application/json; charset=utf-8",
                "Cookie": "cross-site-cookie=bar",
            },
            data=json.dumps({
                "amount": "1000",
                "digest": "9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986",
                "mobile": "09165523448",
                "expires_at": "2023-01-01 23:59:59",
                "callback_url": "https://mpayv2.free.beeceptor.com/500",
                "txnid": "MP1234567",
                "link_expires_at": "2023-01-01 23:59:59",
                "email": "test@email.com",
                "name": "Test",
                "items": [
                    {
                        "name": "Item # 1",
                        "amount": "1000"
                    }
                ]
            })
        )
        print('Response HTTP Status Code: {status_code}'.format(
            status_code=response.status_code))
        print('Response HTTP Response Body: {content}'.format(
            content=response.content))
    except requests.exceptions.RequestException:
        print('HTTP Request failed')
      `,
    },
    {
      label: 'Ruby',
      code_string: `
require 'net/http'
require 'net/https'
require 'json'

# Generate Payment Gateway Link (POST )
def send_request
  uri = URI('https://{{HOST}}/api/v1/transaction')

  # Create client
  http = Net::HTTP.new(uri.host, uri.port)
  http.use_ssl = true
  http.verify_mode = OpenSSL::SSL::VERIFY_PEER
  dict = {
            "amount" => "1000",
            "digest" => "9681ca78aa09a422d9135c1e32b3cb886f1728172e25a4c01161fc5dc34cf986",
            "mobile" => "09165523448",
            "expires_at" => "2023-01-01 23:59:59",
            "callback_url" => "https://mpayv2.free.beeceptor.com/500",
            "txnid" => "MP1234567",
            "link_expires_at" => "2023-01-01 23:59:59",
            "email" => "test@email.com",
            "name" => "Test",
            "items" => [
                {
                    "name" => "Item # 1",
                    "amount" => "1000"
                }
            ]
        }
  body = JSON.dump(dict)

  # Create Request
  req =  Net::HTTP::Post.new(uri)
  # Add headers
  req.add_field "X-MultiPay-Token", "{{API_KEY}}"
  # Add headers
  req.add_field "Content-Type", "application/json; charset=utf-8"
  # Add headers
  req.add_field "Cookie", "cross-site-cookie=bar"
  # Set body
  req.body = body

  # Fetch Request
  res = http.request(req)
  puts "Response HTTP Status Code: #{res.code}"
  puts "Response HTTP Response Body: #{res.body}"
rescue StandardError => e
  puts "HTTP Request failed (#{e.message})"
end
      `,
    },
  ],
};
