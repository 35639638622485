import React from 'react';

export const PAYOUT_LIST = 'PAYOUT/payout_list';
export const PAYOUT_PAGER = 'PAYOUT/pager';
export const PAYOUT_FILTER = 'PAYOUT/filter';
export const PAYOUT_COUNT_CARD = 'PAYOUT/count_card';

export const PAYOUT_LOGS_LIST = 'PAYOUT_LOGS/list';
export const PAYOUT_LOGS_PAGER = 'PAYOUT_LOGS/pager';
export const PAYOUT_LOGS_FILTER = 'PAYOUT_LOGS/filter';

const BADGE_CLASS = {
  SETTLED: 'badge badge-success',
  UNSETTLED: 'badge badge-danger',
  default: 'badge badge-gray',
};

export const renderBadge = (status, label) => (
  <span className={BADGE_CLASS[status] || BADGE_CLASS.default}>{label}</span>
);
