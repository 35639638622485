import React from 'react';
import PropTypes from 'prop-types';
import { HiMenuAlt2 } from 'react-icons/hi';

// import { HelpButton } from 'partial/app-tour';
import AccountModeStatus from 'modules/auth/components/AccountModeStatus';
import { NotificationButton } from 'core-modules/notifications/components/NotificationButton';

import { BreadCrumbPortal } from './BreadCrumb';

const SHOW_NOTIFICATIONS = true;

const MainHeader = ({ onBelowMdMenuBtnClick }) => {
  return (
    <>
      <div className="relative z-20 mx-auto flex h-14 w-full max-w-7xl flex-shrink-0 border-b bg-white pr-4 sm:h-16 sm:px-6 md:px-8">
        <button
          className="mr-2 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
          onClick={onBelowMdMenuBtnClick}
          type="button"
          id="btn-sidebar"
        >
          <span className="sr-only">Open sidebar</span>
          <HiMenuAlt2 className="h-4 w-4" aria-hidden="true" />
        </button>
        <div className="flex flex-grow justify-between">
          <div className="relative flex flex-1 items-center">
            <div className="line-clamp-1 absolute w-full">
              <BreadCrumbPortal />
            </div>
          </div>
          <div className="ml-4 flex items-center space-x-3 md:ml-6">
            {/* <HelpButton /> */}
            {SHOW_NOTIFICATIONS ? <NotificationButton /> : null}
          </div>
        </div>
      </div>
      <div className="block border-b bg-white">
        <AccountModeStatus />
      </div>
    </>
  );
};

MainHeader.propTypes = {
  onBelowMdMenuBtnClick: PropTypes.func.isRequired,
};

export default MainHeader;
