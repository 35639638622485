import { formatDate, formatCurrency } from 'helper';

const STATUS_LABEL = {
  SETTLED: 'Settled',
  UNSETTLED: 'Unsettled',
};

export const transformSettlement = (raw) => ({
  id: raw?.uuid || '',
  created_at: raw?.created_at,
  settled_at: raw?.settled_at,
  report_date: formatDate(raw?.date_of_report, 'MMM DD YYYY'),
  amount: raw?.total_amount,
  transactions: raw?.total_transactions,
  status: raw?.status,
  status_label: STATUS_LABEL[raw?.status] || raw?.status,
});

export const transformSettlementLogs = (raw) => ({
  id: raw?.uuid || '',
  paid_at: raw?.paid_at || '',
  channel: raw?.payment_channel || '',
  channel_branch: raw?.payment_channel_branch || '',
  payment_status: raw?.payment_status || '',
  refno: raw?.refno || '',
  txnid: raw?.transaction_uuid || '',
});

export const transformLedger = (raw) => ({
  id: raw?.uuid || '',
  merchant_name: raw?.merchant_name || '--',
  created_at: raw?.created_at || '',
  updated_at: raw?.updated_at || '',
  type: raw?.type || '',
  refno: raw?.refno || '',
  remarks: raw?.description || '',
  credit: formatCurrency(raw?.credit) || '',
  debit: formatCurrency(raw?.debit) || '',
  balance: formatCurrency(raw?.balance) || '',
});

export const transformRefund = (raw) => ({
  id: raw?.id || '',
  name: raw?.name || '',
  refno: raw?.refno,
  txnid: raw?.txnid,
  created_at: raw?.created_at,
  merchant_name: raw?.merchant_name || '--',
  channel_name: raw?.payment_channel,
  amount: formatCurrency(raw?.amount),
  payment_status: raw?.payment_status,
  posting_status: raw?.posting_status,
  reason: raw?.reason,
  status: raw?.status,
  attachments: raw?.attachments,
});
