import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const FormInlineToggle = ({
  name,
  theme,
  onChange,
  checked,
  disabled,
  isCheckedValue,
  isNotCheckedValue,
}) => {
  const handleToggleChange = useCallback(
    (e) => {
      onChange((state) => ({
        ...state,
        [name]: e.target?.checked ? isCheckedValue : isNotCheckedValue,
      }));
    },
    [name, onChange, isCheckedValue, isNotCheckedValue]
  );

  return (
    <label className={cn('form-inline-toggle', theme)}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={handleToggleChange}
        disabled={disabled}
      />
      <span aria-label="toggle" />
    </label>
  );
};

FormInlineToggle.defaultProps = {
  checked: false,
  disabled: false,
  isCheckedValue: true,
  isNotCheckedValue: false,
  theme: 'default',
};

FormInlineToggle.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'primary', 'secondary']),
  isCheckedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  isNotCheckedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default FormInlineToggle;
