/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';

import { useTogglePaymentChannelStatus } from '../hooks';

function AttachedChannelActions({ data, merchantId, categoryId, onUpdate }) {
  const { status, activateChannel, deactivateChannel, isLoadingStatus } =
    useTogglePaymentChannelStatus(
      data.status,
      merchantId,
      categoryId,
      data.id,
      onUpdate
    );

  return (
    <div className="relative flex h-full w-full items-center justify-end divide-x">
      <div className="flex items-center space-x-2 pl-4">
        <Switch
          checked={status === 'ACTIVE'}
          onChange={(checked) => {
            if (checked) return activateChannel();
            return deactivateChannel();
          }}
          className={`${
            status === 'ACTIVE' ? 'bg-green-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          disabled={isLoadingStatus}
        >
          <span
            className={`${
              status === 'ACTIVE' ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white`}
          />
        </Switch>
      </div>
    </div>
  );
}

AttachedChannelActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    payment_channel_fees: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  merchantId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AttachedChannelActions;
