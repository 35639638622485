import React from 'react';
import PropTypes from 'prop-types';
import Table from 'partial/components/Table';
import { HiOutlineSearch } from 'react-icons/hi';
import Pagination from 'partial/components/Pagination';
import FormSearchInput from 'partial/form/FormSearchInput';
import dayjs from 'dayjs';

// const STATUS_OPTIONS = [
//   { label: 'PENDING', value: 'PENDING' },
//   { label: 'PROCESSED', value: 'PROCESSED' },
// ];

function Filter({ onSubmit, value }) {
  // const handleChange = (e) => (v) => {
  //   const val = v();
  //   setForm(v);
  //   onSubmit((state) => ({
  //     ...state,
  //     [e]: val[e],
  //   }));
  // };
  return (
    <div className="flex items-center justify-between">
      <FormSearchInput
        name="keyword"
        onChange={onSubmit}
        value={value.keyword}
        placeholder="Search..."
        icon={HiOutlineSearch}
        withClearBtn
      />
      <div className="inline-field flex space-x-1">
        {/* <FormInlineSelect
          name="status"
          onChange={handleChange("status")}
          value={form.status}
          placeholder="All Status"
          options={STATUS_OPTIONS}
        /> */}
      </div>
    </div>
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

const useApiLogs = () => [
  false,
  [
    {
      id: '1',
      created_at_human: dayjs().format('MMM DD, YYYY hh:mmA'),
      user_agent: 'Chrome',
      ip_address: '127.0.0.1',
    },
    {
      id: '2',
      created_at_human: dayjs().format('MMM DD, YYYY hh:mmA'),
      user_agent: 'Chrome',
      ip_address: '127.0.0.1',
    },
    {
      id: '3',
      created_at_human: dayjs().format('MMM DD, YYYY hh:mmA'),
      user_agent: 'Chrome',
      ip_address: '127.0.0.1',
    },
  ],
  {},
];

function ApiLogsContainer({
  filter: otherFilter,
  filterMerchant,
  filterChannel,
}) {
  const [raw, setFilter] = React.useState({
    keyword: '',
    merchant: '',
    channel: '',
    page: 1,
    sort: 'created_at:desc',
  });
  const filter = React.useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );
  const [isLoading, list, pager] = useApiLogs(filter);
  const tableFormat = React.useMemo(() => {
    const cols = [
      {
        label: 'Date/Time',
        id: 'created_at_human',
        key: 'created_at_human',
        sortKey: 'created_at',
      },
      { label: 'User Agent', id: 'user_agent', key: 'user_agent' },
      { label: 'IP Address', id: 'ip_address', key: 'ip_address' },
    ];
    return cols;
  }, []);
  return (
    <div className="rounded-t-lg rounded-b-lg bg-white shadow-primary-md">
      <div className="p-3">
        <Filter
          onSubmit={setFilter}
          value={raw}
          merchant={filterMerchant}
          channel={filterChannel}
        />
      </div>
      <div className="flex flex-col" style={{ height: 400 }}>
        <Table
          isLoading={isLoading}
          data={list}
          format={tableFormat}
          onSort={setFilter}
          sort={raw.sort}
        />
      </div>
      <Pagination onChange={setFilter} value={pager} />
    </div>
  );
}

ApiLogsContainer.defaultProps = {
  filter: {},
  filterMerchant: true,
  filterChannel: true,
};

ApiLogsContainer.propTypes = {
  filter: PropTypes.instanceOf(Object),
  filterMerchant: PropTypes.bool,
  filterChannel: PropTypes.bool,
};

export default ApiLogsContainer;
