import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useCallback, useRef, useState } from 'react';
import { HiArrowNarrowLeft, HiOutlineMail } from 'react-icons/hi';

import Button from 'partial/components/Button';
import FormInput from 'partial/form/FormInput';
import PageWrapper from 'partial/components/PageWrapper';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
import { ColoredLogo } from 'modules/common/components/AppIconLogo';
import { ModalCard, useCreateModal } from 'partial/components/Modal';

import logo from 'assets/images/brandIconLogos/colored-logo.svg';

import { useForgotPassword } from '../hooks';

const initialValues = {
  email_address: '',
};

const validationSchema = yup.object({
  email_address: yup.string().email('Invalid').required('Required'),
});

const useOpenForgotPasswordEmailSentModal = () => {
  const createModal = useCreateModal();
  const history = useHistory();

  const openForgotPasswordEmailSentModal = useCallback(
    (email_address) => {
      createModal({
        content: (close) => (
          <ModalCard size="sm">
            <div className="p-6">
              <div className="space-y-2">
                <img src={logo} alt="verified" className="mb-8 w-36" />
                <div className="text-lg font-semibold">Check your email</div>
                <div className="font-light">
                  Please click the link that was sent to{' '}
                  <span className="font-semibold text-gray-600">
                    {email_address || 'you email'}
                  </span>{' '}
                  to continue resetting your password.
                </div>
              </div>
              <Button
                className="my-5 uppercase"
                default
                onClick={() => {
                  close();
                  history.push('/');
                }}
              >
                Close
              </Button>
            </div>
          </ModalCard>
        ),
      });
    },
    [createModal, history]
  );

  return openForgotPasswordEmailSentModal;
};

function ForgotPassword() {
  const recaptchaRef = useRef();
  const [validateOnChange, setValidateOnChange] = useState(false);

  const openEmailSentModal = useOpenForgotPasswordEmailSentModal();

  const { isLoadingSendEmail: isLoading, sendEmailInstruction } =
    useForgotPassword(null, () => recaptchaRef.current?.reset());

  const { values, errors, handleSubmit, setFieldValue, setFieldError } =
    useFormik({
      initialValues,
      validateOnChange,
      validationSchema,
      onSubmit: async (form) => {
        const captcha = await recaptchaRef.current.executeAsync();
        sendEmailInstruction(
          { ...form, captcha },
          () => openEmailSentModal(form?.email_address),
          (err) => {
            setError422(err, setFieldError);
          }
        );
      },
    });

  return (
    <PageWrapper className="relative grid place-items-center">
      <Link
        to="/"
        className="absolute left-0 top-0 m-2 grid h-8 w-8 place-items-center rounded-full border text-gray-400 hover:border-primary-500 hover:text-primary-500"
      >
        <HiArrowNarrowLeft size={14} />
      </Link>
      <Form
        onSubmit={handleSubmit}
        error={errors}
        className="mx-auto w-full max-w-md space-y-4 text-center"
      >
        <ColoredLogo className="mx-auto w-36" />
        <h1 className="text-2xl font-semibold text-gray-900">
          Forgot Password
        </h1>
        <p className="text-sm text-gray-500">
          Enter your registered email below to receive password reset
          instructions.
        </p>
        <div>
          <FormInput
            name="email_address"
            label="Email Address"
            onSetFieldValue={setFieldValue}
            value={values?.email_address}
            icon={HiOutlineMail}
            error={errors?.email_address}
            required
          />
          <ErrorLabel name="email_address" />
        </div>
        <Button
          primary
          size="xxl"
          type="submit"
          className="w-full"
          disabled={isLoading}
          onClick={() => setValidateOnChange(true)}
        >
          Send Instructions
        </Button>
      </Form>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={
          process.env.REACT_APP_RECAPTCHA ||
          '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        }
      />
    </PageWrapper>
  );
}

export default ForgotPassword;
