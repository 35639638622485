/* eslint-disable no-shadow */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';

export const NavItem = ({
  label,
  icon: Icon,
  to,
  onClick,
  children,
  ...rest
}) => {
  const isParent = !!children;

  return (
    <>
      <NavLink
        to={to}
        className={cn('sidenav-link group', isParent ? 'is-parent' : '')}
        activeClassName="active"
        onClick={onClick}
        {...rest}
      >
        <Icon className="icon" aria-hidden="true" />
        <span>{label}</span>
      </NavLink>
      {!!isParent && <Route path={to}>{children}</Route>}
    </>
  );
};

NavItem.defaultProps = {
  onClick: () => {},
  children: undefined,
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const NavSubItem = ({ label, icon: Icon, to, onClick }) => {
  return (
    <NavLink
      to={to}
      className="sidenav-sublink group"
      activeClassName="active"
      onClick={onClick}
    >
      {Icon && (
        <Icon className="icon group-hover:text-gray-500" aria-hidden="true" />
      )}
      <span>{label}</span>
    </NavLink>
  );
};

NavSubItem.defaultProps = {
  icon: false,
  onClick: () => {},
};

NavSubItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export const NavGroup = ({ label, children, labelIsLoading }) => (
  <div className="">
    <div className="ml-[24px] mb-2">
      {labelIsLoading && !label ? (
        <div className="my-3 h-4 w-32 animate-pulse rounded bg-gray-300" />
      ) : (
        <h3 className="text-xs font-bold uppercase tracking-wider text-gray-500">
          {label}
        </h3>
      )}
    </div>
    {children}
  </div>
);

NavGroup.defaultProps = {
  label: false,
  labelIsLoading: false,
};

NavGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.instanceOf(Object).isRequired,
  labelIsLoading: PropTypes.bool,
};
