import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

export const DropdownMenuItem = ({ name, label, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(name);
  };
  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href="/"
          onClick={handleClick}
          className={cn(
            active ? 'bg-secondary-100 text-secondary-400' : 'text-gray-500',
            'block px-3 py-3 font-light'
          )}
        >
          {label}
        </a>
      )}
    </Menu.Item>
  );
};

DropdownMenuItem.defaultProps = {
  name: '',
};

DropdownMenuItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function DropdownMenu({ children, customButtonElement = null }) {
  return (
    <Menu as="div" className="relative flex-shrink-0">
      {({ open, close }) => (
        <>
          {!customButtonElement ? (
            <Menu.Button className="inline-flex h-9 w-9 items-center justify-center rounded border text-gray-900">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          ) : (
            <Menu.Button>{customButtonElement}</Menu.Button>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items
              static
              className="absolute right-0 z-10 mr-2 mt-1 w-48 origin-top-right divide-gray-200 rounded-md border bg-white"
            >
              <div className="divide-y">
                {typeof children === 'function'
                  ? children({ open, close })
                  : children}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

DropdownMenu.defaultProps = {
  customButtonElement: null,
};

DropdownMenu.propTypes = {
  customButtonElement: PropTypes.element,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default DropdownMenu;
