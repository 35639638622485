import {
  Route,
  Switch,
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { toastInfo, toastSuccess } from 'react-geek-toast';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

import noData from 'assets/images/no-data.svg';
import Button from 'partial/components/Button';
import { useTailwindBreakpoints } from 'partial/hooks';
import BreadCrumb from 'partial/components/BreadCrumb';
import { useOnToggleEnvironmentEffect } from 'modules/auth/modals/SwitchProfileModal';

import DropdownMenu, {
  DropdownMenuItem,
} from 'partial/components/DropdownMenu';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { renderStatus } from '../constants';
import { useShowInvoiceLinkQr } from '../modals/InvoiceLinkQrModal';

import {
  usePaymentLinkData,
  usePaymentLinkPaidAggregationData,
  usePaymentLinkTransactionListData,
  useTogglePaymentLinkStatus,
} from '../hooks';
import SelectedPaymentLinkTransaction from './SelectedPaymentLinkTransaction';

export const STATUS_OPTIONS = [
  { label: 'All Payment Status', value: '' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Expired', value: 'EXPIRED' },
];

function SelectedPaymentLink({ onGoBack }) {
  const { link_uuid } = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const [filter, setFilter] = useState({
    q: '',
    status: 'PAID',
    'sort[created_at]': 'desc',
  });

  const showInvoiceLinkQr = useShowInvoiceLinkQr();

  const { data: paymentLinkData, fetchData } = usePaymentLinkData({
    link_uuid,
  });

  const { data: aggregationData } = usePaymentLinkPaidAggregationData({
    link_uuid,
  });

  const {
    data: transactionListData,
    isLoading,
    loadMoreData: loadMoreTransactionData,
  } = usePaymentLinkTransactionListData({
    link_uuid,
    params: filter,
    resetOnUnmount: true,
  });

  const {
    archive,
    activate,
    deactivate,
    isLoading: isLoadingStatus,
  } = useTogglePaymentLinkStatus();

  useOnToggleEnvironmentEffect(onGoBack);

  const { lg } = useTailwindBreakpoints();

  return (
    <>
      <BreadCrumb>
        <div className="space-x-2">
          <span>{paymentLinkData?.name ?? '-'}</span>
          {paymentLinkData?.status !== 'ACTIVE' &&
            renderStatus(
              paymentLinkData?.status,
              paymentLinkData?.status_label
            )}
        </div>
      </BreadCrumb>
      <div className="relative sm:h-full lg:grid lg:grid-cols-12">
        {(lg || match.isExact) && (
          <div className="flex flex-col space-y-6 sm:h-full lg:col-span-5">
            <div className="flex items-center justify-between lg:pr-4">
              <button
                type="button"
                onClick={onGoBack}
                className="mb-4 flex items-center text-sm font-normal text-black hover:text-primary-500"
              >
                <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
                Go Back
              </button>

              <DropdownMenu
                customButtonElement={
                  <div className="grid h-7 w-7 place-items-center rounded border bg-gray-50">
                    <FaEllipsisH className="text-gray-400" />
                  </div>
                }
              >
                {({ close }) => (
                  <>
                    <DropdownMenuItem
                      name="copy_link"
                      label="Copy Link"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          paymentLinkData?.page_url
                        );
                        toastSuccess('Copied to clipboard!');
                      }}
                    />
                    <DropdownMenuItem
                      name="show_qr"
                      label="Show QR"
                      onClick={() => {
                        showInvoiceLinkQr(paymentLinkData?.page_url);
                      }}
                    />
                    {paymentLinkData?.status === 'ACTIVE' && (
                      <DropdownMenuItem
                        name="toggle_status"
                        label="Deactivate Link"
                        onClick={() => {
                          close();
                          deactivate(link_uuid, {
                            onSuccess: () => {
                              fetchData();
                              toastSuccess('Link deactivated');
                            },
                          });
                        }}
                        disabled={isLoadingStatus}
                      />
                    )}
                    {paymentLinkData?.status === 'INACTIVE' && (
                      <DropdownMenuItem
                        name="toggle_status"
                        label="Activate Link"
                        onClick={() => {
                          close();
                          activate(link_uuid, {
                            onSuccess: () => {
                              fetchData();
                              toastSuccess('Link activated');
                            },
                          });
                        }}
                        disabled={isLoadingStatus}
                      />
                    )}
                    {paymentLinkData?.status !== 'ARCHIVED' ? (
                      <DropdownMenuItem
                        name="archive_link"
                        label="Archive Link"
                        onClick={() => {
                          close();
                          archive(link_uuid, {
                            onSuccess: () => {
                              fetchData();
                              toastSuccess('Link archived');
                              if (typeof onGoBack === 'function') onGoBack();
                            },
                          });
                        }}
                      />
                    ) : (
                      <DropdownMenuItem
                        name="unarchive_link"
                        label="Unarchive Link"
                        onClick={() => {
                          close();
                          deactivate(link_uuid, {
                            onSuccess: () => {
                              fetchData();
                              toastSuccess('Link unarchived');
                            },
                          });
                        }}
                      />
                    )}
                    <DropdownMenuItem
                      name="sort_by_date"
                      label="Sort by Date"
                      onClick={() => {
                        toastInfo('Coming soon...');
                      }}
                    />
                  </>
                )}
              </DropdownMenu>
            </div>
            <div className="space-y-2 lg:pr-4">
              <ModuleListFilters
                searchName="q"
                hideDatePicker
                filter={filter}
                setFilter={setFilter}
                renderCustomFields={({ inlineFilter, setInlineFilter }) => (
                  <FormInlineSelect
                    name="status"
                    label="Status"
                    onChange={setInlineFilter}
                    value={inlineFilter?.status}
                    options={STATUS_OPTIONS}
                  />
                )}
                activeFiltersFormat={[
                  {
                    label: 'Status',
                    key: 'status',
                    matrix: [
                      ['PAID', 'Paid'],
                      ['PENDING', 'Pending'],
                      ['EXPIRED', 'Expired'],
                    ],
                  },
                ]}
              />
            </div>
            <div className="flex flex-col sm:h-0 sm:flex-grow">
              <div className="flex justify-between text-xs lg:pr-4">
                <p>
                  Transactions ({paymentLinkData?.transaction_count ?? '0'})
                </p>
                <p>
                  Total Paid Amount:{' '}
                  <span className="font-bold">
                    {aggregationData?.total_payments_human}
                  </span>
                </p>
              </div>
              <Switch>
                <Route exact path={match.path}>
                  <PaymentLinkTransactionList
                    parentUrl={match.url}
                    isLoading={isLoading}
                    onLoadMore={loadMoreTransactionData}
                    transactionListData={transactionListData}
                  />
                </Route>
                <Route path={`${match.path}/:invoice_uuid`}>
                  <PaymentLinkTransactionList
                    parentUrl={match.url}
                    isLoading={isLoading}
                    onLoadMore={loadMoreTransactionData}
                    transactionListData={transactionListData}
                  />
                </Route>
              </Switch>
            </div>
          </div>
        )}
        <Switch>
          <Route
            exact
            path={match.url}
            render={() => (
              <>
                {!(transactionListData?.list || []).length < 1 && lg ? (
                  <Redirect
                    to={`${match.url}/${transactionListData?.list?.[0]?.uuid}`}
                  />
                ) : (
                  <div className="absolute inset-0 hidden h-full w-full rounded border bg-gray-50 p-6 lg:static lg:col-span-7 lg:block">
                    <div className="h-full rounded-lg bg-white">
                      <div className="bg-image-banner-right grid h-full place-items-center bg-right-bottom bg-no-repeat">
                        <p className="text-2xl font-bold text-gray-300">
                          No selected transaction.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          />
          <Route
            path={`${match.path}/:invoice_uuid`}
            render={() => (
              <div className="w-full bg-white sm:h-full lg:col-span-7">
                <button
                  type="button"
                  onClick={() => history.push(match.url)}
                  className="mb-4 flex items-center text-sm font-normal text-black hover:text-primary-500 lg:hidden"
                >
                  <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
                  Go Back
                </button>
                <div className="w-full rounded border bg-gray-50 sm:h-full sm:p-6">
                  <div className="rounded-lg bg-white sm:h-full">
                    <SelectedPaymentLinkTransaction />
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
      </div>
    </>
  );
}

SelectedPaymentLink.defaultProps = {
  onGoBack: undefined,
};

SelectedPaymentLink.propTypes = {
  onGoBack: PropTypes.func,
};

export default SelectedPaymentLink;

const PaymentLinkTransactionList = ({
  parentUrl,
  transactionListData,
  isLoading,
  onLoadMore,
}) => {
  const { invoice_uuid } = useParams();
  const history = useHistory();

  return (
    <div className="mt-2 flex flex-col divide-y overflow-auto sm:flex-grow">
      {!transactionListData?.list?.length && (
        <>
          {isLoading ? (
            <div />
          ) : (
            <div className="mx-auto mt-2 max-w-xl py-10 text-center">
              <img src={noData} className="mx-auto mb-4 w-40" alt="" />
              <div className="text-base text-gray-500">
                There is no transaction yet.
              </div>
            </div>
          )}
        </>
      )}
      {(transactionListData?.list ?? []).map((transaction) => (
        <button
          key={transaction?.uuid}
          type="button"
          onClick={() => history.push(`${parentUrl}/${transaction?.uuid}`)}
          className={cn(invoice_uuid === transaction.uuid ? 'bg-gray-50' : '')}
        >
          <div className="relative px-2 py-4 lg:px-4">
            <div className="float-right">
              <p className="text-right font-bold">
                {transaction?.total_amount_human}
              </p>
              <p className="text-right">{transaction?.created_at_human}</p>
            </div>
            <p className="text-left font-medium text-primary-500">
              {transaction?.refno ?? '--'}
            </p>
            <p className="text-left text-gray-500">
              {transaction?.recipient_name}
            </p>
          </div>
        </button>
      ))}

      {transactionListData?.pager?.last_page > 1 && (
        <div className="flex justify-center py-2">
          {transactionListData?.pager?.current_page >=
          transactionListData?.pager?.last_page ? (
            <Button size="sm" disabled>
              No more items.
            </Button>
          ) : (
            <Button size="sm" disabled={isLoading} onClick={onLoadMore}>
              {isLoading ? 'Loading...' : 'Load more'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

PaymentLinkTransactionList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  parentUrl: PropTypes.string.isRequired,
  transactionListData: PropTypes.instanceOf(Object).isRequired,
};
