import { useEffect, useCallback, useRef } from 'react';
import { useApiGet, useApiLoading } from 'react-reqq-lite';

import { withReqHookDefaultOptions } from 'helper';

import * as c from './constants';
import * as actions from './actions';

export const useNotificationListData = withReqHookDefaultOptions((options) => {
  const data = useApiGet(c.LIST_NOTIFICATION, {});
  const isLoading = useApiLoading(c.LIST_NOTIFICATION, 'get');
  const onSuccessRef = useRef(options?.onSuccess);

  useEffect(() => {
    onSuccessRef.current = options.onSuccess;
  }, [options.onSuccess]);

  // eslint-disable-next-line
  const fetchData = useCallback(() => {
    actions.getNotificationList(onSuccessRef.current)(options.filter);
    actions.getHasUnreadNotification(options.filter);
  }, [options.filter]);

  useEffect(() => {
    if (!options.autoFetch) return;
    fetchData();
  }, [fetchData, options.autoFetch]);

  return [isLoading, data, fetchData];
});

export const useInlineMarkNotificationAsRead = withReqHookDefaultOptions(
  (options) => {
    const isLoading = useApiLoading(c.SHOW_NOTIFICATION, 'get');

    // eslint-disable-next-line
    const inlineMarkNotificationAsRead = useCallback(
      actions.getNotificationItem(options.onSuccess, options.onError),
      [options.onSuccess, options.onError]
    );

    return [isLoading, inlineMarkNotificationAsRead];
  }
);

export const useHasUnreadNotification = () => {
  const hasUnreadNotification = useApiGet(c.SHOW_HAS_UNREAD, false);
  return hasUnreadNotification;
};

export const useeMarkAllNotificationAsRead = withReqHookDefaultOptions(
  (options) => {
    const isLoading = useApiLoading(c.READ_ALL_NOTIFICATION, 'post');

    // eslint-disable-next-line
    const markAllNotificationAsRead = useCallback(
      actions.markAllNotificationAsRead(options.onSuccess, options.onError),
      [options.onSuccess, options.onError]
    );

    return [isLoading, markAllNotificationAsRead];
  }
);
