import React from 'react';
import PropTypes from 'prop-types';

import landing from 'assets/images/banners/landing.png';
import { ColoredLogoBeta } from 'modules/common/components/AppIconLogo';

function LandingWrapper({ children, title, description }) {
  return (
    <div className="relative flex h-full max-h-full flex-col overflow-auto lg:flex-row-reverse">
      <div className="sticky top-0 h-2/5 w-full flex-shrink-0 bg-gradient-to-tr from-primary-700 to-primary-500 lg:relative lg:h-full lg:w-1/2">
        <img alt="" src={landing} className="landing-banner" />
        <div className="absolute top-[calc(50%-5rem)] z-[1] w-full text-center text-white lg:relative lg:top-0 lg:mt-40">
          <h2 className="text-2xl font-medium">Welcome to Multipay</h2>
          <p className="mt-2 font-light">
            Your secured payment gateway <br />
            Make it easy for your customers with our one-
            <br />
            stop Epayment integration
          </p>
        </div>
      </div>
      <div className="relative z-10 -mt-20 w-full rounded-t-3xl rounded-b-none bg-white lg:mt-0 lg:h-full lg:max-h-full lg:w-1/2 lg:overflow-auto lg:rounded-t-none">
        <div className="mx-auto w-full max-w-2xl px-10 pt-10 pb-10 lg:pt-20">
          <ColoredLogoBeta />
          <h1 className="mt-10 text-2xl font-semibold text-gray-900">
            {title}
          </h1>
          <p className="text-base text-gray-600">{description}</p>
          <div className="mt-6">{children}</div>
        </div>
      </div>
    </div>
  );
}

LandingWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default LandingWrapper;
