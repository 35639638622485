/* eslint-disable no-param-reassign */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, Route, Switch, useRouteMatch } from 'react-router-dom';
import { HiInformationCircle, HiPlus } from 'react-icons/hi';

import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import { useActiveAccount } from 'modules/auth/hooks';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { useMerchantList } from '../hooks';
import { MAP_BADGE_CLASS } from '../constants';
import MerchantProfile from './MerchantProfile';
import {
  useOpenCreateMerchantModal,
  useOpenPromptSwitchToLiveMode,
} from '../modals/CreateMerchantModal';

const APPROVAL_STATUS_OPTIONS = [
  { label: 'Approved', value: 'APPROVED' },
  { label: 'In Review', value: 'IN_REVIEW' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Pending', value: 'PENDING' },
];

const GET_MERCHANT_LIST_TABLE_FORMAT = [
  {
    label: 'MERCHANT',
    id: 'name',
    key: 'name',
    stackKey: true,
  },
  {
    label: 'REPRESENTATIVE',
    id: 'representative_name',
    key: 'representative_name',
    stackKey: true,
  },
  {
    label: 'MOBILE NO',
    id: 'representative_contact_number',
    key: 'representative_contact_number',
    stackKey: true,
  },
  {
    label: 'STATUS',
    id: 'status',
    key: (row) => (
      <span className={cn('badge', MAP_BADGE_CLASS[row.approval_status])}>
        {row?.approval_status_label}
      </span>
    ),
    stackKey: true,
  },
  {
    label: '',
    id: 'actions',
    key: () => (
      <button
        type="button"
        className="flex items-center font-normal text-primary-500"
      >
        <HiInformationCircle className="mr-1 inline" /> View Info
      </button>
    ),
    stackKey: true,
  },
  {
    smRender: (row) => (
      <>
        <div className="float-right">
          <span className={cn('badge', MAP_BADGE_CLASS[row.approval_status])}>
            {row?.approval_status_label}
          </span>
        </div>
        <div className="text-primary-500">{row?.name}</div>
        <div className="text-gray-500">{row?.representative_name}</div>
        <div className="text-gray-500">
          {row?.representative_contact_number}
        </div>
      </>
    ),
  },
];

function Filter({ onSubmit, value, onAddMerchantClick }) {
  return (
    <ModuleListFilters
      filter={{
        approval_status: value?.approval_status,
      }}
      setFilter={onSubmit}
      actionElement={
        <>
          <Button
            primary
            icon={HiPlus}
            onClick={onAddMerchantClick}
            className="h-full @4xl:hidden"
          />
          <Button
            primary
            icon={HiPlus}
            onClick={onAddMerchantClick}
            className="hidden h-full @4xl:inline-flex"
          >
            Add Merchant
          </Button>
        </>
      }
      renderCustomFields={({ inlineFilter, setInlineFilter }) => (
        <FormInlineSelect
          name="approval_status"
          label={
            <span className="text-sm text-gray-800 sm:hidden">
              Approval Status
            </span>
          }
          onChange={setInlineFilter}
          value={inlineFilter?.approval_status}
          placeholder="All Status"
          options={APPROVAL_STATUS_OPTIONS}
        />
      )}
      activeFiltersFormat={[
        {
          label: 'Status',
          key: 'approval_status',
          matrix: [
            ['APPROVED', 'Approved'],
            ['IN_REVIEW', 'In Review'],
            ['REJECTED', 'Rejected'],
            ['PENDING', 'Pending'],
          ],
        },
      ]}
      hideDatePicker
    />
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onAddMerchantClick: PropTypes.func.isRequired,
};

function MerchantProfiles() {
  const match = useRouteMatch();
  const history = useHistory();
  const [activeAccount] = useActiveAccount();
  const [openPromptSwitchToLiveMode] = useOpenPromptSwitchToLiveMode();

  const [filter, setFilter] = useState({
    keyword: '',
    approval_status: '',
    page: 1,
    sort: 'name:asc',
  });

  const [isLoading, rawList, pager, appendList] = useMerchantList(filter);

  const openCreateMerchantModal = useOpenCreateMerchantModal();

  const showAddMerchantDrawer = useCallback(() => {
    if (activeAccount?.env_mode === 'TEST') {
      openPromptSwitchToLiveMode();
      return;
    }
    openCreateMerchantModal();
  }, [activeAccount, openPromptSwitchToLiveMode, openCreateMerchantModal]);
  const list = useMemo(
    () =>
      rawList.map((merchant) => {
        merchant.representative_name = activeAccount.representative_name;
        merchant.representative_contact_number =
          activeAccount.representative_contact_number;
        return merchant;
      }),
    [rawList, activeAccount]
  );

  return (
    <Switch>
      <Route exact path={match.path}>
        <div className="flex flex-col sm:h-full">
          <div className="mb-4">
            <Filter
              onSubmit={setFilter}
              value={filter}
              onAddMerchantClick={showAddMerchantDrawer}
            />
          </div>
          <div className="flex flex-grow flex-col overflow-hidden rounded-md border sm:h-0">
            <Table
              isLoading={isLoading}
              data={list}
              format={GET_MERCHANT_LIST_TABLE_FORMAT}
              onSort={setFilter}
              sort={filter.sort}
              pager={pager}
              onLoadMore={appendList}
              onRowClick={(row) => history.push(`${match.url}/${row?.id}`)}
            />
          </div>
          {/* <Pagination onChange={setFilter} value={pager} /> */}
        </div>
      </Route>
      <Route path={`${match.path}/:merchant_id`}>
        <MerchantProfile parentUrl={match.url} />
      </Route>
    </Switch>
  );
}

export default MerchantProfiles;
