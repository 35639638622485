import React from 'react';
import CountCard from 'partial/dashboard/CountCard';

import { useCountCard } from '../hooks';

function CountCards() {
  return (
    <dl className="mt-5 grid grid-cols-2 gap-3 md:grid-cols-4">
      <CountCard name="total_merchants" label="Merchants" hook={useCountCard} />
      <CountCard name="total_fee" label="Total Fee" hook={useCountCard} />
      <CountCard
        name="total_transactions"
        label="Transactions"
        hook={useCountCard}
      />
      <CountCard name="total_amount" label="Gross Amount" hook={useCountCard} />
    </dl>
  );
}

export default CountCards;
