import React, { useState, useEffect, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { BiHome } from 'react-icons/bi';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Button from 'partial/components/Button';
import Version from 'partial/components/Version';
import { SolidLogo } from 'modules/common/components/AppIconLogo';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import IconAccountVerified from 'assets/images/icons/icon-account-verified.svg';
import IconInvalidActivation from 'assets/images/icons/icon-invalid-activation.svg';

import {
  useForgotPassword,
  useSetPassword,
  useUpdateExpiredPassword,
} from '../hooks';
import NewPasswordForm from '../forms/NewPasswordForm';

const MAP_TYPE_SUCCESS_LABEL = {
  set: 'Set',
  forgot: 'Change',
  update: 'Update',
};

const useOpenSetPasswordSuccessModal = () => {
  const history = useHistory();
  const createModal = useCreateModal();

  const openSetPasswordSuccessModal = useCallback(
    ({ type = 'set' }) => {
      createModal({
        content: (close) => (
          <ModalCard size="sm">
            <div className="flex flex-col items-center p-6">
              <img src={IconAccountVerified} alt="verified" className="w-36" />
              <h1 className="mt-5 text-lg font-semibold tracking-wide text-gray-800">
                Password {MAP_TYPE_SUCCESS_LABEL[type]} Successfully
              </h1>
              <p className="mt-2 text-sm text-gray-600">
                Please login to your account using the new password you&apos;ve
                just entered.
              </p>
              <Button
                default
                className="mt-10 uppercase"
                onClick={() => {
                  close();
                  history.push('/');
                }}
              >
                Login to your account now
              </Button>
            </div>
          </ModalCard>
        ),
      });
    },
    [createModal, history]
  );

  return openSetPasswordSuccessModal;
};

const MAP_TYPE_LABEL = {
  set: 'Set Password',
  forgot: 'Reset Password',
  update: 'Update Password',
};

function SetPassword() {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const type = useMemo(() => {
    const { path = '' } = match;
    if (path.includes('/set_password')) return 'set';
    if (path.includes('/update_password')) return 'update';
    if (path.includes('/forgot_password')) return 'forgot';
    return '';
  }, [match]);

  const openSetPasswordSuccessModal = useOpenSetPasswordSuccessModal();

  const [isValidUuid, setIsValidUuid] = useState(true);
  const [additionalPayload, setAdditionalPayload] = useState({});

  const {
    confirmForgotPassword,
    isLoadingConfirmation: isLoadingForgotConfirmation,
    setNewPassword: setForgottenPassword,
    isLoadingSetForgottenPassword,
  } = useForgotPassword({
    onConfirmation: ({ response: { data } }) => {
      setAdditionalPayload(data);
    },
    onConfirmationError: () => {
      setIsValidUuid(false);
    },
    onSetNewPassword: () => openSetPasswordSuccessModal({ type }),
  });

  const {
    setPassword,
    confirmSetPassword,
    isLoadingSetPassword,
    isLoadingConfirmation,
  } = useSetPassword({
    onConfirmation: ({ response: { data } }) => {
      setAdditionalPayload(data);
    },
    onConfirmationError: () => {
      setIsValidUuid(false);
    },
    onSetPassword: () => openSetPasswordSuccessModal({ type }),
  });

  const {
    confirmUpdatePassword,
    setUpdatePassword,
    isLoadingUpdateConfirmation,
    isLoadingUpdatePassword,
  } = useUpdateExpiredPassword({
    onConfirmation: ({ response: { data } }) => {
      setAdditionalPayload(data);
    },
    onConfirmationError: () => {
      setIsValidUuid(false);
    },
    onSetPassword: () => openSetPasswordSuccessModal({ type }),
  });

  const confirm = useMemo(
    () =>
      ({
        set: confirmSetPassword,
        forgot: confirmForgotPassword,
        update: confirmUpdatePassword,
      }[type]),
    [type, confirmSetPassword, confirmForgotPassword, confirmUpdatePassword]
  );

  const set = useMemo(
    () =>
      ({
        set: setPassword,
        forgot: setForgottenPassword,
        update: setUpdatePassword,
      }[type]),
    [type, setPassword, setForgottenPassword, setUpdatePassword]
  );

  const handleBackToHomePage = () => history.push('/');

  useEffect(() => {
    const uuid = queryString.parse(location?.search)?.uuid || '';
    confirm({ uuid });
  }, [location, history, confirm]);

  return (
    <div className="bg-login flex h-full flex-col bg-primary-600 bg-center">
      <div className="absolute top-5 left-5 uppercase">
        <Button icon={BiHome} primary onClick={handleBackToHomePage}>
          Back to login page
        </Button>
      </div>
      <div className="m-auto w-full max-w-lg">
        <div className="mb-6 flex justify-center">
          <SolidLogo className="h-12 sm:h-14" />
        </div>
        <div className="m-auto w-full rounded-none bg-white px-14 py-10 sm:rounded-3xl">
          {isLoadingForgotConfirmation ||
          isLoadingConfirmation ||
          isLoadingUpdateConfirmation ? (
            <>
              <h1 className="mb-6 text-center text-lg">Confirming Session</h1>
              <div className="loaderBar w-full">
                <div className="loaderBarInner" />
              </div>
            </>
          ) : (
            <div>
              {isValidUuid ? (
                <>
                  <h1 className="mb-6 text-center text-2xl">
                    {MAP_TYPE_LABEL[type]}
                  </h1>
                  <NewPasswordForm
                    type={type}
                    isLoading={
                      isLoadingSetForgottenPassword ||
                      isLoadingSetPassword ||
                      isLoadingUpdatePassword
                    }
                    onSubmit={(form) => {
                      set(
                        { ...form, ...additionalPayload },
                        null
                        // (err) => setError422(err, setFieldError)
                      );
                    }}
                  />
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <img
                    src={IconInvalidActivation}
                    alt="invalid"
                    className="w-36"
                  />
                  <h1 className="mt-5 text-lg font-semibold tracking-wide text-gray-800">
                    Invalid {MAP_TYPE_LABEL[type]} Link
                  </h1>
                  <p className="mt-2 text-sm text-gray-600">
                    The link you followed is no longer valid. This is probably
                    the link expired.
                  </p>
                  {type === 'forgot' && (
                    <p className="mt-2 text-sm text-gray-600">
                      You can request a new forgot password link in the login
                      page.
                    </p>
                  )}
                  <Button
                    default
                    className="mt-10 mb-5 uppercase"
                    onClick={() => history.push('/')}
                  >
                    back to login page
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <p className="mt-6 text-center text-xs text-white/60">
          &copy; 2022 MultiPay Merchant <Version />
        </p>
      </div>
    </div>
  );
}

export default SetPassword;
