import { pick } from 'lodash';
import { removeEmpty } from 'helper';

export const transformReport = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  file_name: raw?.file_name,
  label: raw?.scope,
  status: raw?.status,
  download_count: raw?.download_count,
  can_download: raw?.status === 'SUCCESS',
});

export const payloadGenerateReport = (payload) =>
  removeEmpty({
    merchant_uuid: payload?.merchant?.value || '',
    date_from: payload?.from,
    date_to: payload?.to,
    ...pick(payload, ['payment_status', 'posting_status']),
  });
