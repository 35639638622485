/* eslint-disable */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import Form, { ErrorLabel } from 'partial/form/Form';
import { useDatasetOptions } from 'core-modules/dataset/hooks';
import { useGeneralInfo } from 'core-modules/merchant-onboarding/hooks';
import RequirementItem from 'core-modules/merchant-onboarding/components/RequirementItem';
import AttachmentPreview from 'core-modules/merchant-onboarding/components/AttachmentPreview';
import BusinessTypeOptions from 'core-modules/merchant-onboarding/components/BusinessTypeOptions';
import AttachmentGuidelines from 'core-modules/merchant-onboarding/components/AttachmentGuidelines';

import { MAP_BUSINESS_TYPE_DATA_SET_CODE } from '../constants';

const transformFormValues = (form) => {
  return {
    business_type: form?.business_type,
    requirements: Object.keys(form?.attachments || {}).map((key) => ({
      label: key,
      value: form?.attachments?.[key],
    })),
  };
};

export const AttachmentsForm = forwardRef(
  ({ readOnly, onSubmit, onSuccess }, ref) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const requiredAttachmentFieldsRef = useRef();

    const [, generalInfo] = useGeneralInfo();

    const {
      errors,
      values,
      handleSubmit,
      setFieldError,
      setFieldValue,
      validateForm,
    } = useFormik({
      validationSchema: yup.object().shape({
        business_type: yup.string().required('Required'),
        attachments: yup.object().shape(
          requiredAttachmentFieldsRef?.current?.reduce(
            (acc, cur) => ({
              ...acc,
              [cur?.label]: yup.string().required(`${cur?.label} is required`),
            }),
            {}
          ) || {}
        ),
      }),
      validateOnChange,
      initialValues: {
        business_type: generalInfo?.business_type,
        attachments: {
          ...generalInfo?.requirements?.reduce(
            (acc, cur) => ({
              ...acc,
              [cur?.label]: cur?.value,
            }),
            {}
          ),
        },
      },
      onSubmit: async (form) => {
        onSubmit(transformFormValues(form), onSuccess, (err) => {
          each(err?.response?.errors, (v, k) => {
            setFieldError(k, v);
          });
        });
      },
    });

    const [_, requiredAttachmentFieldsUnordered] = useDatasetOptions(
      MAP_BUSINESS_TYPE_DATA_SET_CODE?.[values?.business_type] ||
        'KYC_REQUIREMENT_CORPORATION',
      true
    );

    const requiredAttachmentFields = useMemo(
      () =>
        requiredAttachmentFieldsUnordered?.sort((a, b) => {
          if (a?.label > b?.label) return 1;
          if (b?.label > a?.label) return -1;
          return 0;
        }),
      [requiredAttachmentFieldsUnordered]
    );

    useEffect(() => {
      requiredAttachmentFieldsRef.current = requiredAttachmentFields;
      if (validateOnChange) {
        validateForm();
      }
    }, [requiredAttachmentFields, validateOnChange]);

    const [selected, setSelected] = React.useState('');

    const selectedValues = React.useMemo(
      () =>
        (values?.attachments?.[selected] || '')
          .split(',')
          .filter((v) => !isEmpty(v)),
      [selected, values]
    );

    useEffect(() => {
      setSelected(requiredAttachmentFields?.[0]?.label);
    }, [requiredAttachmentFields, setSelected]);

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit,
        setValidateOnChange,
      }),
      [handleSubmit, setValidateOnChange]
    );

    return (
      <Form className="space-y-4 @container" error={errors} onSubmit={() => {}}>
        <div className="title-h2">Choose your type of business</div>
        <div className="space-y-2">
          <BusinessTypeOptions
            name="business_type"
            onChange={(cb) =>
              setFieldValue('business_type', cb()?.business_type)
            }
            value={values?.business_type || 'CORPORATION'}
            readOnly={readOnly}
          />
          <ErrorLabel name="business_type" />
        </div>
        <AttachmentGuidelines />
        <div className={cn('grid overflow-hidden bg-white @xl:grid-cols-2')}>
          <div className="flex flex-col overflow-y-auto @xl:max-h-96">
            {requiredAttachmentFields.map((field) => (
              <RequirementItem
                key={field?.label}
                name={`attachments.${field?.label}`}
                label={field?.label}
                onChange={(cb) =>
                  setFieldValue(
                    `attachments[${field?.label}]`,
                    cb()?.[`attachments.${field?.label}`]
                  )
                }
                value={values?.attachments?.[field?.label] || ''}
                isSelected={selected === field?.label}
                onSelect={() => setSelected(field?.label)}
                readOnly={readOnly}
              />
            ))}
          </div>
          <div className="hidden min-h-[18.75rem] bg-gray-50 p-5 @xl:block">
            <AttachmentPreview
              name={`attachments.${selected}`}
              onChange={(cb) => {
                setFieldValue(
                  `attachments.${selected}`,
                  cb()?.[`attachments.${selected}`]
                );
              }}
              values={selectedValues}
              label={selected || ''}
              readOnly={readOnly}
              isMultiple
            />
          </div>
        </div>
      </Form>
    );
  }
);

AttachmentsForm.defaultProps = {
  readOnly: false,
};

AttachmentsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default AttachmentsForm;
