import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import Form, { ErrorLabel } from 'partial/form/Form';
import FormDatasetOptions from 'core-modules/dataset/components/FormDatasetOptions';
import { useUpdateAccountBank } from 'modules/auth/hooks';

function BankDetailsModal({ onClose, data }) {
  const [form, setForm] = useState(data);
  const [isUpdating, update] = useUpdateAccountBank();

  const handleSubmit = () => {
    update(form, () => {
      onClose();
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">
        <FormDatasetOptions
          code="BANKS"
          name="bank_name"
          onChange={setForm}
          value={form.bank_name}
          label="Bank"
          type="text"
          placeholder="- Select Bank -"
          required
        />
        <ErrorLabel name="bank_name" />
      </div>
      <div className="mb-3">
        <FormInput
          name="bank_account_name"
          onChange={setForm}
          value={form.bank_account_name}
          label="Account Name"
          type="text"
          required
        />
        <ErrorLabel name="bank_account_name" />
      </div>
      <div className="mb-3">
        <FormInput
          name="bank_account_number"
          onChange={setForm}
          value={form.bank_account_number}
          label="Account Number"
          type="number"
          required
        />
        <ErrorLabel name="bank_account_number" />
      </div>
      <div className="flex justify-end space-x-2 border-t pt-4">
        <Button className="mr-2" onClick={onClose} disabled={isUpdating}>
          Close
        </Button>
        <Button primary type="submit" disabled={isUpdating}>
          Save
        </Button>
      </div>
    </Form>
  );
}

BankDetailsModal.defaultProps = {
  data: { name: '' },
};

BankDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
};

export default BankDetailsModal;
