import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { BsBuilding } from 'react-icons/bs';
import { useTour } from '@reactour/tour';

function CreateMerchantTour({ onNext }) {
  const history = useHistory();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  React.useEffect(() => {
    setSteps([
      {
        selector: '[data-tour="sidebar-merchant"]',
        content: 'Go to merchant module',
      },
      {
        selector: '[data-tour="merchant-list"]',
        content: 'Select a merchant',
      },
      {
        selector: '[data-tour="merchant-tab-kyc"]',
        content: 'Click KYC tab',
      },
      {
        selector: '[data-tour="merchant-kyc-steps"]',
        content: 'Complete the following steps',
      },
      {
        selector: '[data-tour="merchant-kyc-form"]',
        content: 'Fill up the forms and submit for approval',
      },
    ]);
    history.push('/merchants/profile/details');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <BsBuilding className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">Merchant Setup</div>
          <div>
            Complete your details and submit for approval. You can do this later
            <br />
            <button
              className="text-secondary-500 hover:underline"
              type="button"
              onClick={() => {
                setIsOpen(true);
                setCurrentStep(0);
              }}
            >
              Teach me how
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext('theme-modal')}>
          Skip and do this later
        </Button>
      </div>
    </div>
  );
}

CreateMerchantTour.propTypes = {
  // data: PropTypes.instanceOf(Object).isRequired,
  onNext: PropTypes.func.isRequired,
  // onSkip: PropTypes.func.isRequired,
};

export default CreateMerchantTour;
