import React, { useState, useMemo } from 'react';
import Sidebar from 'partial/components/Sidebar';
import Profile from 'partial/containers/Profile';
import Logout from 'modules/auth/containers/Logout';
import PageWrapper from 'partial/components/PageWrapper';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageNotFound from 'partial/containers/PageNotFound';
import UserRoutes from 'core-modules/users/containers/UserRoutes';
import MerchantRoutes from 'core-modules/merchants/containers/MerchantRoutes';
import DeveloperRoutes from 'core-modules/developers/containers/DeveloperRoutes';
import DashboardContainer from 'core-modules/dashboard/containers/DashboardContainer';
import DashboardTransactions from 'core-modules/dashboard/containers/DashboardTransactions';

import Bottombar from 'partial/components/Bottombar';
import {
  useOnIdleEffect,
  useBottomBarIsOpenState,
  useScrollUpDownCallback,
} from 'partial/hooks';
import ProfileWrapper from 'modules/auth/containers/ProfileWrapper';
import MyAccountWrapper from 'modules/auth/containers/MyAccountWrapper';
import InvoiceRoutes from 'core-modules/invoice/containers/InvoiceRoutes';
import InvoiceContainer from 'core-modules/invoice/containers/InvoiceContainer';
import { useOpenIdleTimoutModal } from 'modules/auth/modals/IdleTimeoutModal';
import ReportsContainer from 'core-modules/reports/containers/ReportsContainer';
import AccountingRoutes from 'core-modules/accounting/containers/AccountingRoutes';
import MerchantDashboard from 'core-modules/merchants/containers/MerchantDashboard';
import { useAccessControl } from 'modules/common/hooks';
import MerchantDetail from 'core-modules/merchants/components/MerchantDetail';
import {
  useMerchantSelected,
  useSelectMerchant,
} from 'core-modules/merchants/hooks';
import TransactionTable from 'core-modules/dashboard/components/TransactionTable';
import IntegrationDashboard from 'core-modules/developers/containers/IntegrationDashboard';
import MerchantCms from 'core-modules/merchants/containers/MerchantCms';
import MerchantChannels from 'core-modules/merchants/components/MerchantChannels';
import ApiKeysContainer from 'core-modules/developers/containers/ApiKeysContainer';
import AclUsers from 'core-modules/users/containers/AclUsers';
// import SettlementContainer from 'core-modules/accounting/containers/SettlementContainer';
import PayoutContainer from 'core-modules/payout/container/PayoutContainer';
import MainHeader from 'partial/components/MainHeader';
import BreadCrumb from 'partial/components/BreadCrumb';
// import { usePersistState } from 'helper';

const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route path="/dashboard" component={DashboardContainer} />
      <Route path="/developers" component={DeveloperRoutes} />
      <Route path="/transactions" component={DashboardTransactions} />
      <Route path="/merchants" component={MerchantRoutes} />
      <Route path="/payout" component={PayoutContainer} />
      <Route path="/accounting" component={AccountingRoutes} />
      <Route path="/get-paid">
        <PageWrapper>
          <InvoiceRoutes />
        </PageWrapper>
      </Route>
      <Route path="/report">
        <PageWrapper>
          <ReportsContainer />
        </PageWrapper>
      </Route>
      <Route path="/acl" component={UserRoutes} />
      <Route path="/profile" component={Profile} />
      {/* <Route
        title="Complete Profile"
        path="/onboarding"
        component={OnboardingRoutes}
      /> */}
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
};

const SubRoutes = () => {
  const { decodedToken, canRead } = useAccessControl();
  useSelectMerchant(decodedToken?.merchant_uuid);
  const [, data] = useMerchantSelected();

  const merchantFilter = React.useMemo(
    () => ({
      merchant_uuid: data.id,
    }),
    [data]
  );

  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route path="/dashboard">
        <>
          <BreadCrumb>Dashboard</BreadCrumb>
          <PageWrapper>
            <MerchantDashboard />
          </PageWrapper>
        </>
      </Route>
      <Route path="/info">
        <>
          <BreadCrumb>Profile</BreadCrumb>
          <PageWrapper>
            <MerchantDetail data={data} />
          </PageWrapper>
        </>
      </Route>
      <Route path="/transactions">
        <>
          <BreadCrumb>Transactions</BreadCrumb>
          <PageWrapper>
            <TransactionTable filterMerchant={false} filter={merchantFilter} />
          </PageWrapper>
        </>
      </Route>
      <Route path="/reports">
        <>
          <BreadCrumb>Reports</BreadCrumb>
          <PageWrapper>
            <ReportsContainer merchantId={data?.id} />
          </PageWrapper>
        </>
      </Route>
      <Route path="/channels">
        <>
          <BreadCrumb>Channels</BreadCrumb>
          <PageWrapper>
            <MerchantChannels merchantId={data?.id} />
          </PageWrapper>
        </>
      </Route>
      <Route path="/get-paid/bill">
        <PageWrapper>
          <InvoiceContainer filter={merchantFilter} />
        </PageWrapper>
      </Route>
      <Route path="/integration">
        <>
          <BreadCrumb>API Integration</BreadCrumb>
          <PageWrapper>
            <div className="space-y-4">
              <ApiKeysContainer />
              {data?.id ? <IntegrationDashboard merchant={data} /> : null}
            </div>
          </PageWrapper>
        </>
      </Route>
      <Route path="/themes">
        <>
          <BreadCrumb>Themes</BreadCrumb>
          <PageWrapper>
            <MerchantCms key={data?.id} data={data} />
          </PageWrapper>
        </>
      </Route>
      <Route path="/profile" component={Profile} />
      <Route path="/users">
        <>
          <BreadCrumb>Users</BreadCrumb>
          <PageWrapper>
            <AclUsers merchantId={data?.id} />
          </PageWrapper>
        </>
      </Route>
      <Route exact path="/">
        {canRead('dashboard') && <Redirect to="/dashboard" />}
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
};

function Private() {
  const { userType } = useAccessControl();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isBottombarOpen, setIsBottombarOpen] = useBottomBarIsOpenState(true);

  const scrollUpDownOptions = useMemo(
    () => ({
      onScrollUp: () => setIsBottombarOpen(true),
      onScrollDown: () => setIsBottombarOpen(false),
      listenOnScrollUp: !isBottombarOpen,
      listenOnScrollDown: isBottombarOpen,
    }),
    [isBottombarOpen, setIsBottombarOpen]
  );

  const { register } = useScrollUpDownCallback(scrollUpDownOptions);

  const openIdleTimeoutModal = useOpenIdleTimoutModal();
  useOnIdleEffect(openIdleTimeoutModal, 600);

  return (
    <ProfileWrapper>
      <MyAccountWrapper>
        <div className="isolate flex h-screen overflow-hidden bg-slate-50/20">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="flex h-full w-0 flex-grow flex-col md:overflow-hidden">
            <MainHeader onBelowMdMenuBtnClick={() => setSidebarOpen(true)} />
            <main
              {...register}
              className="relative z-10 flex h-0 flex-grow flex-col overflow-y-auto bg-white focus:outline-none"
            >
              {userType === 'org_user' ? <MainRoutes /> : <SubRoutes />}
            </main>
          </div>
          <div className="sm:hidden">
            <Bottombar
              show={isBottombarOpen}
              setSidebarOpen={() => setSidebarOpen(true)}
            />
          </div>
        </div>
      </MyAccountWrapper>
    </ProfileWrapper>
  );
}

export default Private;
