import { useCallback, useEffect, useState } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import * as actions from './actions';
import * as c from './constants';

export const useListReport = (params) => {
  const isLoading = useApiLoading(c.REPORT_LIST, 'get');
  const list = useApiGet(c.REPORT_LIST, []);
  const pager = useApiGet(c.REPORT_PAGER, {});
  const appendList = useCallback(
    (page) => {
      actions.appendListReport({ ...params, page });
    },
    [params]
  );
  const fetchData = useCallback(() => actions.listReport(params), [params]);
  useEffect(fetchData, [fetchData]);
  useEffect(() => {
    return () => {
      req.set(c.REPORT_LIST, null);
      req.set(c.REPORT_PAGER, null);
      req.set(c.REPORT_FILTER, null);
    };
  }, []);
  return [isLoading, list, pager, appendList, fetchData];
};

export const useGenerateReport = () => {
  const isLoading = useApiLoading(c.REPORT_GENERATE, 'post');
  return [isLoading, actions.generateReport];
};

export const useDownloadReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const download = useCallback((id, filename, onSuccess) => {
    setIsLoading(true);
    actions.downloadReport(
      id,
      filename,
      () => {
        setIsLoading(false);
        if (onSuccess) onSuccess();
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);
  return [isLoading, download];
};
