export const MERCHANT_FILTER = 'MERCHANTS/filter';
export const MERCHANT_API_KEY = 'MERCHANTS/api_key';
export const MERCHANT_WEBHOOK = 'MERHCNATS/webhook';
export const MERCHANT_TRANSACTION = 'MERCHANTS/transaction';
export const MERCHANT_API_KEY_LIST = 'MERCHANTS/api_key_list';
export const MERCHANT_API_KEY_ITEM = 'MERCHANTS/api_key_item';
export const MERCHANT_API_KEY_REGENERATE = 'MERCHANTS/api_key_item_regenerate';

export const SIMULATE_INQUIRE = 'MERCHANTS/simulate_inquire';

export const STATUS_OPTIONS = [
  {
    label: 'Posted',
    value: 'POSTED',
  },
  {
    label: 'Unposted',
    value: 'UNPOSTED',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
];

export const EVENT_TYPE_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Paid',
    value: 'PAID',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Voided',
    value: 'VOIDED',
  },
  {
    label: 'Refunded',
    value: 'REFUNDED',
  },
  {
    label: 'Expired',
    value: 'EXPIRED',
  },
];

export const MAP_STATUS = {
  POSTED: 'Posted',
  IN_PROGRESS: 'In Progress',
  UNPOSTED: 'Unposted',
};

export const MAP_STATUS_CLASS = {
  POSTED: 'badge badge-success',
  IN_PROGRESS: 'badge badge-warning',
  UNPOSTED: 'badge badge-danger',
};

export const MAP_EVENT_TYPE = {
  PAID: 'Paid',
  PENDING: 'Pending',
  FAILED: 'Failed',
  VOIDED: 'Voided',
  REFUNDED: 'Refunded',
  EXPIRED: 'Expired',
  WAITING_FOR_PAYMENT: 'Waiting For Payment',
};

export const MAP_EVENT_TYPE_CLASS = {
  PAID: 'badge badge-success',
  PENDING: 'badge badge-warning',
  FAILED: 'badge badge-danger',
  VOIDED: 'badge badge-gray',
  REFUNDED: 'badge badge-gray',
  EXPIRED: 'badge badge-gray',
  WAITING_FOR_PAYMENT: 'badge badge-warning',
};
