import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import PropTypes from 'prop-types';
import Theme1 from '../theme1/Preview';
import Theme2 from '../theme2/Preview';
import Theme3 from '../theme3/Preview';
import Theme4 from '../theme4/Preview';

function PgiPreviewContainer() {
  return (
    <Switch>
      <Route path="/pgi-preview/theme1" component={Theme1} />
      <Route path="/pgi-preview/theme2" component={Theme2} />
      <Route path="/pgi-preview/theme3" component={Theme3} />
      <Route path="/pgi-preview/theme4" component={Theme4} />
    </Switch>
  );
}

PgiPreviewContainer.propTypes = {};

export default PgiPreviewContainer;
