import AuthStore from 'auth-store';

const promiseDownload = async (url, fileName, options = { method: 'POST' }) => {
  const token = AuthStore.get('_token');
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  return new Promise((resolve, reject) => {
    (async () => {
      const newUrl =
        typeof url === 'function'
          ? url()
          : `${process.env.REACT_APP_END_POINT}${url}`;
      let raw;
      try {
        raw = await fetch(newUrl, {
          method: options?.method ?? 'POST',
          headers,
        });
      } catch (err) {
        reject(err);
        return;
      }
      try {
        if (raw.status >= 300) {
          const err = await raw.json();
          reject(err);
          return;
        }
        const blob = await raw.blob();
        blob.fileName = fileName || 'download';
        const output_url = await URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = output_url;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        resolve(raw);
      } catch (err) {
        reject(err);
      }
    })();
  });
};

export default promiseDownload;
