import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { XIcon } from '@heroicons/react/outline';

import Button from 'partial/components/Button';
import FormPhotoDnd from 'partial/form/FormPhotoDnd';
import FormTextArea from 'partial/form/FormTextArea';
import Form, { ErrorLabel } from 'partial/form/Form';
import GenericDetails from 'partial/components/GenericDetails';

import { useCreateRefundRequest } from '../hooks';
import { REFUND_DETAILS_FORMAT } from '../constants';

const validationSchema = yup.object({
  reason: yup.string().required('Required'),
  attachments: yup.array(yup.string()).min(1, 'Required'),
});

function RefundForm({ data, onCancel, onSuccess }) {
  const [isLoading, createRefundRequest] = useCreateRefundRequest(onSuccess);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);

  const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik({
    validateOnChange,
    validationSchema,
    initialValues: {
      reason: '',
      attachments: [],
    },
    onSubmit: createRefundRequest,
  });

  useEffect(() => {
    resetForm();
    setValidateOnChange(false);
    setFieldValue('transaction_uuid', data?.id);
  }, [data, resetForm, setValidateOnChange, setFieldValue]);

  return (
    <Form
      className="flex max-h-[75vh] flex-col overflow-y-auto"
      onSubmit={handleSubmit}
      error={errors}
    >
      <div className="flex h-full flex-col space-y-4 p-4 sm:flex-row sm:space-x-4 sm:space-y-0 sm:divide-x-2">
        <div className="w-72">
          <GenericDetails
            numberOfCols={1}
            data={data}
            format={REFUND_DETAILS_FORMAT}
          />
        </div>
        <div className="flex flex-col space-y-4 sm:pl-4">
          <div>
            <FormTextArea
              label="Reason"
              name="reason"
              value={values.reason}
              onSetFieldValue={setFieldValue}
              error={errors?.reason}
              required
              style={{ resize: 'none' }}
            />
            <ErrorLabel name="reason" />
          </div>
          <div className="grid flex-grow space-y-2 overflow-y-auto sm:h-0 sm:grid-cols-2 sm:space-x-4 sm:space-y-0">
            <div className="col-span-1 flex h-full flex-col space-y-4">
              <div className="font-semibold text-gray-500">
                Upload attachments
              </div>
              <p className="text-gray-500">Upload proofs/screenshots.</p>
              <p className="text-gray-500">
                Accepted files are .jpg, .png with a file size lower than 2MB.
              </p>
              <div className="flex flex-grow flex-col space-y-2 overflow-auto sm:h-0">
                {values?.attachments?.map((_, i) => (
                  <div
                    className={cn(
                      'relative flex items-center justify-center rounded-lg border bg-gray-100 px-4 py-2 hover:border-primary-200',
                      selectedAttachmentIndex === i && 'border-primary-200'
                    )}
                  >
                    <button
                      aria-label="select attachment"
                      type="button"
                      className="absolute inset-0 h-full w-full"
                      onClick={() => setSelectedAttachmentIndex(i)}
                    />
                    Attachment {i + 1}
                    <button
                      type="button"
                      className="absolute right-4 my-auto"
                      onClick={() => {
                        setSelectedAttachmentIndex(
                          values?.attachments.length - 1
                        );
                        setFieldValue(
                          'attachments',
                          values?.attachments?.filter(
                            (url, index) => index !== i
                          )
                        );
                      }}
                      aria-label="icon"
                    >
                      <XIcon className="z-10 h-4 w-4 text-gray-800" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className={cn(
                    'relative flex items-center justify-center rounded-lg border border-dashed bg-gray-100 px-4 py-2',
                    selectedAttachmentIndex === values?.attachments?.length &&
                      'border-primary-200'
                  )}
                  onClick={() =>
                    setSelectedAttachmentIndex(values?.attachments?.length)
                  }
                >
                  {values?.attachments?.length > 0
                    ? 'Add More'
                    : 'No Attachment'}
                </button>
              </div>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <FormPhotoDnd
                className="h-[176px]"
                name={`attachments[${selectedAttachmentIndex}]`}
                onChange={(cb) => {
                  const url = cb()?.[`attachments[${selectedAttachmentIndex}]`];
                  if (!url) return;
                  setFieldValue(`attachments[${selectedAttachmentIndex}]`, url);
                }}
                value={values?.attachments?.[selectedAttachmentIndex]}
                error={errors?.attachments}
                label="Attachments"
                disableRemove
                required
              />
              <ErrorLabel name="attachments" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-auto flex h-20 w-full justify-end space-x-4 bg-gray-100 p-6">
        <Button onClick={onCancel} disabled={isLoading}>
          Discard
        </Button>
        <Button
          type="submit"
          primary
          onClick={() => setValidateOnChange(true)}
          disabled={isLoading}
        >
          Submit Request
        </Button>
      </div>
    </Form>
  );
}

RefundForm.defaultProps = {
  data: {},
};

RefundForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default RefundForm;
