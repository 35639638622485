import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { BiBarChartSquare } from 'react-icons/bi';

function MerchantDashboardTour({ onNext }) {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/merchants/profile/dashboard');
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <BiBarChartSquare className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">
            Merchant Performance
          </div>
          <div>See how your merchant performs on the dashboard.</div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext()}>
          Done
        </Button>
      </div>
    </div>
  );
}

MerchantDashboardTour.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default MerchantDashboardTour;
