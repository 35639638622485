import React from 'react';
import dayjs from 'dayjs';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import HistogramCard, {
  useHistogramCard,
} from 'partial/dashboard/HistogramCard';
import DashboardSchemeCard, {
  useSchemeCard,
} from 'partial/dashboard/DashboardSchemeCard';
import BreadCrumb from 'partial/components/BreadCrumb';
import PageWrapper from 'partial/components/PageWrapper';
import HistogramRange from 'partial/components/HistogramRange';
import NavTab, { NavTabItem } from 'partial/components/NavTab';

import { useTransactionHistogram, useTransactionScheme } from '../hooks';
import TransactionCompare from '../components/TransactionCompare';
import TransactionTable from '../components/TransactionTable';

const txnHistogramOptions = [
  {
    id: 'transaction',
    label: 'Chart',
    hook: useTransactionHistogram,
    defaultChart: 'line',
    dateRange: false,
  },
];

const schemeOptions = [
  {
    id: 'transactions',
    label: 'Table',
    hook: useTransactionScheme,
    downloadCsv: (row) => ({
      Label: row.label,
      Txn: row.transaction,
      Amount: row.amount,
    }),
    tableFormat: [
      {
        label: 'Date/Time',
        key: 'label',
        id: 'label',
        className: 'font-bold',
      },
      {
        label: 'Txn',
        key: 'transaction',
        id: 'transaction',
      },
      { label: 'Amount', key: 'amount', id: 'amount' },
    ],
    defaultSort: 'id:desc',
    table: true,
    pie: false,
    bar: false,
  },
];

const Day = () => {
  const txnHistogramProps = useHistogramCard(txnHistogramOptions);
  const schemeProps = useSchemeCard(schemeOptions);
  const [filter, setFilter] = React.useState({
    type: 'hour',
    from: dayjs().format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    payment_status: 'PAID',
    sort: 'date:desc',
  });
  return (
    <div className="isolate space-y-5">
      <div className="relative z-[1] flex items-center justify-between">
        <div className="uppercase tracking-wider text-gray-500">By Day</div>
        <HistogramRange
          onChange={setFilter}
          defaultValue={dayjs().format('YYYY-MM-DD')}
          defaultMode="day"
          filter={filter}
          switchMode={false}
          panelClassName="right-0"
        />
      </div>
      <HistogramCard
        {...txnHistogramProps}
        defaultMode="day"
        filter={filter}
        table={false}
      />
      <DashboardSchemeCard {...schemeProps} filter={filter} />
    </div>
  );
};

const Month = () => {
  const txnHistogramProps = useHistogramCard(txnHistogramOptions);
  const schemeProps = useSchemeCard(schemeOptions);
  const [filter, setFilter] = React.useState({
    type: 'day',
    from: dayjs().startOf('month').format('YYYY-MM-DD'),
    to: dayjs().endOf('month').format('YYYY-MM-DD'),
    payment_status: 'PAID',
    sort: 'date:desc',
  });
  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <div className="uppercase tracking-wider text-gray-500">By Month</div>
        <HistogramRange
          onChange={setFilter}
          defaultValue={dayjs().format('YYYY-MM-DD')}
          defaultMode="month"
          filter={filter}
          switchMode={false}
          panelClassName="right-0"
        />
      </div>
      <HistogramCard
        {...txnHistogramProps}
        defaultMode="day"
        filter={filter}
        table={false}
      />
      <DashboardSchemeCard
        {...schemeProps}
        filter={filter}
        defaultSort="date:asc"
      />
    </div>
  );
};

const Year = () => {
  const txnHistogramProps = useHistogramCard(txnHistogramOptions);
  const schemeProps = useSchemeCard(schemeOptions);
  const [filter, setFilter] = React.useState({
    type: 'month',
    from: dayjs().startOf('year').format('YYYY-MM-DD'),
    to: dayjs().endOf('year').format('YYYY-MM-DD'),
    payment_status: 'PAID',
    sort: 'date:desc',
  });
  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <div className="uppercase tracking-wider text-gray-500">By Year</div>
        <HistogramRange
          onChange={setFilter}
          defaultValue={dayjs().format('YYYY-MM-DD')}
          defaultMode="year"
          filter={filter}
          switchMode={false}
          panelClassName="right-0"
        />
      </div>
      <HistogramCard
        {...txnHistogramProps}
        defaultMode="day"
        filter={filter}
        table={false}
      />
      <DashboardSchemeCard {...schemeProps} filter={filter} />
    </div>
  );
};

function DashboardTransactions() {
  const match = useRouteMatch();
  return (
    <>
      <BreadCrumb>Transactions</BreadCrumb>
      <div className="flex flex-1 flex-grow flex-col">
        <NavTab>
          <NavTabItem exact to={match.url} label="Logs" />
          <NavTabItem to={`${match.url}/day`} label="Day" />
          <NavTabItem to={`${match.url}/month`} label="Month" />
          <NavTabItem to={`${match.url}/year`} label="Year" />
          <NavTabItem to={`${match.url}/compare`} label="Compare" />
        </NavTab>
        <PageWrapper>
          <Switch>
            <Route exact path={match.path} component={TransactionTable} />
            <Route path={`${match.path}/day`}>
              <Day key="day" />
            </Route>
            <Route path={`${match.path}/month`}>
              <Month key="month" />
            </Route>
            <Route path={`${match.path}/year`}>
              <Year key="year" />
            </Route>
            <Route path={`${match.path}/compare`}>
              <TransactionCompare />
            </Route>
          </Switch>
        </PageWrapper>
      </div>
    </>
  );
}

DashboardTransactions.propTypes = {};

export default DashboardTransactions;
