import React from 'react';
import { HiPencilAlt, HiPhotograph } from 'react-icons/hi';
import { toastInfo } from 'react-geek-toast';
import { QrcodeIcon } from '@heroicons/react/outline';

import {
  useActiveAccount,
  useEnableMFA,
  useMfaSetupData,
  useProfile,
  // useUpdateAccount,
} from 'modules/auth/hooks';
import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import {
  useOpenConnectGAuthModal,
  useOpenDisableMFAModal,
} from 'modules/auth/modals/GAuthenticatorModal';
import DropdownState, {
  DropdownStateItem,
} from 'partial/components/DropdownState';
import ThumbIcon from 'partial/components/ThumbIcon';
import BreadCrumb from 'partial/components/BreadCrumb';
import GenericDetails from 'partial/components/GenericDetails';
import { showDrawer, showLightbox } from 'partial/components/Modal';
import placeholder from 'assets/images/placeholder.svg';
// import { uploadPhoto } from 'partial/form/FormPhotoDnd';
import PageWrapper from 'partial/components/PageWrapper';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';
import UpdateOwnerModal from 'core-modules/profile/modals/UpdateOwnerModal';
import BankDetailsModal from 'core-modules/profile/modals/BankDetailsModal';
import { openChangePassword } from 'modules/auth/modals/ChangePasswordModal';
import UpdateProfileModal from 'core-modules/profile/modals/UpdateProfileModal';

const MAP_PASSWORD_LABEL = {
  LIVE: 'Production Mode Password',
  TEST: 'Test Data Password',
};

function Profile() {
  // const [list] = React.useState([
  //   {
  //     id: '1',
  //     location: 'Philippines (13)',
  //     device: 'Chrome - Mac OS',
  //     ip_address: '120.29.111.80',
  //     time: '1 minute ago',
  //     session: 'Current Session',
  //   },
  //   {
  //     id: '2',
  //     location: 'Philippines (13)',
  //     device: 'Chrome - Mac OS',
  //     ip_address: '120.29.108.48',
  //     time: 'last year',
  //     session: 'Expired',
  //   },
  // ]);
  // const tableFormat = React.useMemo(() => {
  //   return [
  //     { label: 'Location', key: 'location', id: 'location' },
  //     { label: 'Device', key: 'device', id: 'device' },
  //     { label: 'IP Address', key: 'ip_address', id: 'ip_address' },
  //     { label: 'Time', key: 'time', id: 'time' },
  //     { label: '', key: 'session', id: 'session' },
  //   ];
  // }, []);
  const [profile] = useProfile();
  const [account] = useActiveAccount();
  // const [, updateAccount] = useUpdateAccount();
  const [isOrgMerchantLoading, orgMerchant] = useOrgFirstMerchantData();

  const showQrModal = useOpenConnectGAuthModal();
  const showDisableMFAModal = useOpenDisableMFAModal();
  const [isLoading, mfaSetupData, fetchMfaSetupData] = useMfaSetupData();
  const [, enableMFA] = useEnableMFA({
    onSuccess: (res) => {
      const qrUrl = res?.response?.qr_url;
      showQrModal(qrUrl);
      setTimeout(() => {
        fetchMfaSetupData();
      }, 500);
    },
  });

  const handleEnableMFA = () => enableMFA();
  const handleDisableMFA = () => showDisableMFAModal();

  const handleChangePassword = () => {
    openChangePassword();
  };
  // const [, setIsUploading] = React.useState(false);
  // const handleChangeLogo = async () => {
  //   setIsUploading(true);
  //   const photo = await uploadPhoto();
  //   setIsUploading(false);
  //   if (!photo) return;
  //   updateAccount({
  //     ...account,
  //     logo: photo,
  //   });
  // };

  const isEditBankDisabled = React.useMemo(() => {
    if (isOrgMerchantLoading) return true;
    if (['APPROVED', 'IN_REVIEW'].indexOf(orgMerchant?.approval_status) > -1)
      return true;
    return false;
  }, [isOrgMerchantLoading, orgMerchant]);

  const EditBankDetails = React.useCallback(() => {
    const edit = () => {
      showDrawer({
        size: 'xs',
        title: 'Edit Bank Details',
        content: (onClose) => (
          <BankDetailsModal data={account} onClose={onClose} />
        ),
      });
    };
    if (isEditBankDisabled) return null;
    return (
      <div>
        <Button transparent icon={HiPencilAlt} size="sm" onClick={edit} />
      </div>
    );
  }, [account, isEditBankDisabled]);

  const EditProfile = React.useCallback(() => {
    const DISABLED_EDIT_PROFILE = true;
    if (DISABLED_EDIT_PROFILE) return null;
    const edit = () => {
      showDrawer({
        size: 'xs',
        title: 'Edit Account Details',
        content: (onClose) => (
          <UpdateProfileModal
            data={account}
            profile={profile}
            onClose={onClose}
          />
        ),
      });
    };
    return (
      <div>
        <Button transparent icon={HiPencilAlt} size="sm" onClick={edit} />
      </div>
    );
  }, [account, profile]);

  const EditOwner = React.useCallback(() => {
    const DISABLED_EDIT_OWNER = true;
    if (DISABLED_EDIT_OWNER) return null;
    const edit = () => {
      showDrawer({
        size: 'xs',
        title: 'Edit Owner Details',
        content: (onClose) => (
          <UpdateOwnerModal
            data={account}
            profile={profile}
            onClose={onClose}
          />
        ),
      });
    };
    return (
      <div>
        <Button transparent icon={HiPencilAlt} size="sm" onClick={edit} />
      </div>
    );
  }, [account, profile]);

  // const actionSignOut = React.useMemo(() => {
  //   const edit = () => {
  //     showDrawer({
  //       size: 'xs',
  //       title: 'Edit Bank Details',
  //       content: (onClose) => (
  //         <BankDetailsModal data={account} onClose={onClose} />
  //       ),
  //     });
  //   };
  //   return (
  //     <div>
  //       <Button defaultShow icon={HiPencil} size="sm" onClick={edit}>
  //         Sign out all other sessions
  //       </Button>
  //     </div>
  //   );
  // }, [account]);

  return (
    <>
      <BreadCrumb>Settings</BreadCrumb>
      <PageWrapper>
        <div className="w-full max-w-5xl sm:rounded-xl sm:border sm:px-5 sm:pb-5 sm:pt-2">
          <div className="mx-4 sm:mx-0">
            <div className="mt-4 space-y-6 sm:ml-4">
              <div id="account-details">
                <div className="flex justify-between">
                  <div className="title-h2">Account Details</div>
                  <EditProfile />
                </div>
                <div className="mt-4 flex flex-col items-center space-y-5 sm:flex-row sm:items-start sm:space-x-5">
                  <div className="relative mx-auto sm:mt-4">
                    {/* <div className="absolute bottom-2 right-2 z-[1]">
                      <button
                        type="button"
                        onClick={handleChangeLogo}
                        className="rounded-full bg-primary-500 p-[0.5rem] text-white"
                      >
                        <HiOutlinePencilAlt className="text-lg" />
                      </button>
                    </div> */}
                    <button
                      className="mx-auto"
                      onClick={() => {
                        showLightbox({
                          title: 'Preview',
                          content: account?.logo || placeholder,
                        });
                      }}
                      type="button"
                      aria-label="icon"
                    >
                      <ThumbIcon
                        className="h-48 w-48"
                        imgClassName="object-cover object-center rounded-full"
                        iconClassName="w-6 h-6"
                        url={account?.logo || placeholder}
                        icon={HiPhotograph}
                        alt=""
                      />
                    </button>
                  </div>
                  <dl className="w-full">
                    <GenericDetails
                      data={{
                        ...account,
                        email_address: profile?.email_address,
                      }}
                      numberOfCols={2}
                      format={[
                        {
                          label: 'Organization',
                          key: 'name',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Email',
                          key: 'email_address',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Business Address',
                          key: 'address',
                          hasBorder: true,
                          span: 2,
                          className: 'bg-white',
                        },
                        {
                          label: 'Representative Name',
                          key: 'representative_name',
                          hasBorder: true,
                          span: 2,
                          className: 'bg-white',
                        },
                        {
                          label: 'Contact No.',
                          key: 'representative_contact_number',
                          hasBorder: true,
                          span: 2,
                          className: 'bg-white',
                        },
                      ]}
                    />
                  </dl>
                </div>
              </div>
              <div id="owner-info">
                <div className="flex justify-between">
                  <div className="title-h2">Owner&apos;s Information</div>
                  <EditOwner />
                </div>
                <div className="mt-4 flex flex-col items-center space-y-5 sm:flex-row sm:items-start sm:space-x-5">
                  <button
                    className="mx-auto"
                    onClick={() => {
                      showLightbox({
                        title: 'Preview',
                        content: account?.owner_photo_url || placeholder,
                      });
                    }}
                    type="button"
                    aria-label="icon"
                  >
                    <ThumbIcon
                      className="h-48 w-48"
                      imgClassName="object-cover object-center rounded-full"
                      iconClassName="w-6 h-6"
                      url={account?.owner_photo_url || placeholder}
                      alt=""
                    />
                  </button>
                  <dl className="w-full">
                    <GenericDetails
                      data={account}
                      numberOfCols={4}
                      format={[
                        {
                          label: 'First Name',
                          key: 'owner_first_name',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Middle Name',
                          key: 'owner_middle_name',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Last Name',
                          key: 'owner_last_name',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Suffix',
                          key: 'owner_suffix',
                          hasBorder: true,
                          className: 'bg-white',
                        },
                        {
                          label: 'Birth Date',
                          key: 'owner_birth_date',
                          hasBorder: true,
                          span: 2,
                          className: 'bg-white',
                        },
                        {
                          label: 'ID Type',
                          key: 'owner_valid_id_type',
                          hasBorder: true,
                          span: 2,
                          className: 'bg-white',
                        },
                        {
                          label: '',
                          key: (row) => (
                            <button
                              onClick={() => {
                                showLightbox({
                                  title: 'Preview',
                                  content:
                                    row?.owner_valid_id_url || placeholder,
                                });
                              }}
                              type="button"
                            >
                              <img
                                className="rounded-lg border-2 border-dashed bg-white p-5"
                                src={row?.owner_valid_id_url || placeholder}
                                alt="Owner Id"
                              />
                            </button>
                          ),
                          span: 3,
                        },
                      ]}
                    />
                  </dl>
                </div>
              </div>
            </div>
            <div className="ml-0 mt-6 space-y-6 sm:ml-4">
              <div id="security">
                <div className="title-h2">Security</div>
                <Card className="mt-4 border">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="flex flex-col items-start justify-start px-6 py-3 sm:flex-row sm:items-center sm:justify-between">
                      <div className="text-sm font-medium text-gray-500">
                        Multi-Factor
                      </div>
                      <div className="mt-2 flex w-full justify-center space-x-2 sm:mt-0 sm:w-auto">
                        <Button
                          className="w-full whitespace-nowrap sm:w-auto"
                          secondary
                          icon={QrcodeIcon}
                          onClick={() => {
                            if (!mfaSetupData?.is_mfa_enabled) {
                              toastInfo(
                                'Please enable MFA to generate QR code.'
                              );
                              return;
                            }
                            showQrModal(mfaSetupData?.qr_url);
                          }}
                          disabled={isLoading}
                        >
                          Show QR
                        </Button>
                        <DropdownState
                          onChange={(value) => {
                            if (value === '1') return handleEnableMFA();
                            return handleDisableMFA();
                          }}
                          value={`${mfaSetupData?.is_mfa_enabled}`}
                          canChangeState
                        >
                          <DropdownStateItem
                            label="Enable"
                            description="Enable Two-factor Authentication"
                            value="1"
                            color="green"
                          />
                          <DropdownStateItem
                            label="Disable"
                            description="Disable Two-factor Authentication"
                            value="0"
                          />
                        </DropdownState>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start px-6 py-3 sm:flex-row sm:justify-between">
                      <div className="text-sm font-medium text-gray-500">
                        {MAP_PASSWORD_LABEL[account?.env_mode] || 'Password'}
                      </div>
                      <div className="mt-2 sm:mt-0 sm:w-auto">
                        <Button
                          primary
                          type="button"
                          className="w-full sm:w-auto"
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </Button>
                      </div>
                    </div>
                  </dl>
                </Card>
              </div>
            </div>
            <div id="bank-details" className="ml-0 mt-4 sm:ml-4">
              <div className="flex justify-between">
                <div className="title-h2">Bank Details</div>
                <EditBankDetails />
              </div>
              <div className="mt-4 flex flex-col items-center space-y-5 sm:flex-row sm:items-start sm:space-x-5">
                <dl className="w-full">
                  <GenericDetails
                    data={account}
                    format={[
                      {
                        label: 'Bank Name',
                        key: 'bank_name',
                        className: 'bg-white',
                        hasBorder: true,
                      },
                      {
                        label: 'Account Name',
                        key: 'bank_account_name',
                        className: 'bg-white',
                        hasBorder: true,
                      },
                      {
                        label: 'Account No.',
                        key: 'bank_account_number',
                        className: 'bg-white',
                        hasBorder: true,
                      },
                    ]}
                  />
                </dl>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
}

Profile.propTypes = {};

export default Profile;
