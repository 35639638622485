import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';

function FormToggle({
  name,
  onChange,
  onSetFieldValue,
  checked,
  label,
  secondary,
}) {
  const handleChange = (newVal) => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: newVal,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, newVal);
    }
  };
  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      className={cn(
        {
          [!secondary ? 'bg-primary-600' : 'bg-secondary-600']: checked,
          'bg-gray-200': !checked,
        },
        secondary ? 'focus:ring-secondary-500' : 'focus:ring-primary-500',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className={cn(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
}

FormToggle.defaultProps = {
  label: 'Toggle',
  secondary: false,
  onChange: null,
  onSetFieldValue: null,
};

FormToggle.propTypes = {
  label: PropTypes.string,
  secondary: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onSetFieldValue: PropTypes.func,
};

export default FormToggle;
