import { useContext } from 'react';
import { AppTourContext } from './context';

const useAppTour = () => {
  const context = useContext(AppTourContext);
  const activeIndex = (context?.tourSteps || []).findIndex(
    (x) => x === context?.selected
  );
  return { ...context, activeIndex };
};

export default useAppTour;
