import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CountCard from 'partial/dashboard/CountCard';

import {
  CashPaymentIcon,
  CardPaymentIcon,
} from 'modules/common/components/AppIconLogo';

import { useCountCard } from '../hooks';

function CountCards({ otherFilter }) {
  const filter = useMemo(
    () => ({
      status: 'PAID',
      ...otherFilter,
    }),
    [otherFilter]
  );
  return (
    <div className="grid grid-cols-1 gap-2 overflow-hidden sm:grid-cols-2">
      <div className="rounded-xl border bg-white">
        <CountCard
          name="total_invoices"
          label="Total Receivables"
          icon={CashPaymentIcon}
          hook={useCountCard}
          filter={filter}
        />
      </div>
      <div className="rounded-xl border bg-white">
        <CountCard
          name="total_amount"
          label="Total Customer Payments"
          icon={CardPaymentIcon}
          hook={useCountCard}
          filter={filter}
        />
      </div>
    </div>
  );
}

CountCards.propTypes = {
  otherFilter: PropTypes.instanceOf(Object),
};

CountCards.defaultProps = {
  otherFilter: {},
};

export default CountCards;
