/* eslint-disable */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import * as yup from 'yup';
import { each } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';

import * as regex from 'partial/regex';
import FormInput from 'partial/form/FormInput';
import FormPhoto from 'partial/form/FormPhoto';
import FormAddress from 'partial/form/FormAddress';
import FormTextArea from 'partial/form/FormTextArea';
import FormAddressInput from 'partial/form/FormAddressInput';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import { useGeneralInfo } from 'core-modules/merchant-onboarding/hooks';
import Form, { ErrorLabel, ErrorOrDescription } from 'partial/form/Form';
import FormDatasetOptions from 'core-modules/dataset/components/FormDatasetOptions';

const testIfCountryIsPH = {
  name: 'is_ph',
  message: 'Required',
  test: function (value) {
    if (this?.parent?.business_country !== 'PH') return true;
    return !!value;
  },
};

const validationSchema = yup.object().shape({
  logo: yup.string().required('Required'),
  name: yup.string().required('Required'),
  industry: yup.string().required('Required'),
  business_email_address: yup
    .string()
    .email('Invalid Format')
    .required('Required'),
  business_website: yup
    .string()
    .matches(regex.url, 'Invalid Format')
    .required('Required'),
  customer_service_number: yup
    .string()
    .required('Required')
    .test({
      name: 'must be valid',
      test: (value) => isValidPhoneNumber(value || ''),
      message: 'Invalid',
    }),
  business_country: yup.string().required('Required'),
  business_state: yup.string().test(testIfCountryIsPH),
  business_zip_code: yup
    .string()
    .test(testIfCountryIsPH)
    .when('business_country', {
      is: (value) => value === 'PH',
      then: (schema) => schema.length(4, 'Must be 4 digits'),
      otherwise: (schema) => schema,
    }),
  business_city: yup.string().test(testIfCountryIsPH),
  business_address: yup.string().required('Required'),
  // business_geoloc: yup.string().required('Required'),
  business_description: yup.string().required('Required'),
  business_number_of_customers: yup.string().required('Required'),
  business_monthly_sales: yup.string().required('Required'),
  business_type: yup.string(),
});

const SIMPLE_ADDRESS = true;

export const MerchantForm = forwardRef(
  ({ readOnly, onSubmit, onSuccess }, ref) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [, initialValues] = useGeneralInfo();
    const { values, setFieldValue, setFieldError, handleSubmit, errors } =
      useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (form) => {
          onSubmit(form, onSuccess, (err) => {
            each(err?.response?.errors, (v, k) => {
              setFieldError(k, v);
            });
          });
        },
        validateOnChange,
      });

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit,
        setValidateOnChange,
      }),
      [handleSubmit, setValidateOnChange]
    );

    return (
      <Form onSubmit={() => {}} error={errors} noValidate>
        <div>
          <div className="mb-4 flex flex-col items-center space-y-3 sm:flex-row sm:space-x-6">
            <div>
              <FormPhoto
                className="h-48 w-48 rounded-lg"
                imgClassName="object-cover object-center rounded-full"
                name="logo"
                onSetFieldValue={setFieldValue}
                value={values?.logo}
                label="Your Company Logo"
                crop="1:1, free"
                resize="256x"
                widget
                readOnly={readOnly}
              />
              <ErrorLabel name="logo" />
            </div>
            <div className="grid w-full grid-cols-1 gap-3 sm:w-auto sm:grid-cols-2">
              <div>
                <FormInput
                  name="name"
                  onSetFieldValue={setFieldValue}
                  value={values.name}
                  error={errors?.name}
                  label="Your Business Name"
                  required
                  readOnly={readOnly}
                />
                <ErrorOrDescription
                  name="name"
                  label="The name of your business or organization that will be recognized by your customers. This may be different from your legal or entity name."
                />
              </div>
              <div>
                <FormMobileNumber
                  name="customer_service_number"
                  onSetFieldValue={setFieldValue}
                  value={values.customer_service_number}
                  error={errors?.customer_service_number}
                  label="Customer Service Number"
                  readOnly={readOnly}
                  required
                />
                <ErrorOrDescription
                  name="customer_service_number"
                  label="Mobile number where they can contact your business"
                />
              </div>
              <div>
                <FormInput
                  name="business_email_address"
                  onSetFieldValue={setFieldValue}
                  value={values.business_email_address}
                  error={errors?.business_email_address}
                  label="Your Business Email"
                  required
                  readOnly={readOnly}
                />
                <ErrorOrDescription
                  name="business_email_address"
                  label="Email address where they can contact your business"
                />
              </div>
              <div>
                <FormInput
                  name="business_website"
                  onSetFieldValue={setFieldValue}
                  value={values.business_website}
                  error={errors?.business_website}
                  label="Website or Social Media Page"
                  required
                  readOnly={readOnly}
                />
                <ErrorOrDescription
                  name="business_website"
                  label="Website or social media page of your business"
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <FormDatasetOptions
              code="INDUSTRY"
              name="industry"
              onSetFieldValue={setFieldValue}
              value={values.industry}
              error={errors?.industry}
              label="Industry"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="industry" />
          </div>
          {SIMPLE_ADDRESS ? (
            <div className="mb-8">
              <FormAddress
                onSetFieldValue={setFieldValue}
                value={values}
                label="Business Address"
                placeholder=""
                required
                rows="2"
                readOnly={readOnly}
                disabledCountry
                errors={errors}
              />
              {/* <ErrorOrDescription
                name="business_address"
                label="Street/Home/Building No, City, State/Province, Country Zip Code"
              /> */}
            </div>
          ) : (
            <div className="relative z-20">
              <FormAddressInput
                name="business_address"
                geolocName="business_geoloc"
                onSetFieldValue={setFieldValue}
                value={values.business_address || ''}
                geolocValue={values.business_geoloc || ''}
                label="Business Address"
                error={errors?.business_address}
                required
                readOnly={readOnly}
              />
              <ErrorLabel name="business_address" />
            </div>
          )}
          <div className="mb-4">
            <FormTextArea
              name="business_description"
              onSetFieldValue={setFieldValue}
              value={values.business_description}
              error={errors?.business_description}
              label="Tell us about your business"
              readOnly={readOnly}
              required
            />
            <ErrorOrDescription
              name="business_description"
              label="Describe the products and services that you offer to your customers and how you collect payments from them"
            />
          </div>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <div>
              <FormDatasetOptions
                code="AVE_MONTHLY_SALES"
                name="business_monthly_sales"
                onSetFieldValue={setFieldValue}
                value={values.business_monthly_sales}
                error={errors?.business_monthly_sales}
                label="Average Monthly Sales"
                required
                readOnly={readOnly}
              />
              <ErrorLabel name="business_monthly_sales" />
            </div>
            <div>
              <FormDatasetOptions
                code="NO_OF_CUSTOMERS"
                name="business_number_of_customers"
                onSetFieldValue={setFieldValue}
                value={values.business_number_of_customers}
                error={errors?.business_number_of_customers}
                label="No. of Customers"
                required
                readOnly={readOnly}
              />
              <ErrorLabel name="business_number_of_customers" />
            </div>
          </div>
        </div>
      </Form>
    );
  }
);

MerchantForm.defaultProps = {
  readOnly: false,
};

MerchantForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default MerchantForm;
