export const THEMES = {
  theme1: {
    label: 'Cosmic Cash',
    value: 'theme1',
    thumbnail: 'https://ucarecdn.com/aaf0c213-9891-4ba4-b5c7-eb47b127a7ea/',
    defaultValue: {
      color_bg: '#1E5DE6',
      color_accent: '#525F7F',
    },
  },
  theme2: {
    label: 'Fusion Pay',
    value: 'theme2',
    thumbnail: 'https://ucarecdn.com/78b3a53a-78c5-46e7-b12c-bc8a58539ec4/',
    defaultValue: {
      color_bg: '#32908F',
      color_accent: '#525F7F',
    },
  },
  theme3: {
    label: 'Metronic',
    value: 'theme3',
    thumbnail: 'https://ucarecdn.com/9b0fed57-b20c-49f4-9800-9631a672e87d/',
    defaultValue: {
      color_bg: '#DA1367',
      color_accent: '#525F7F',
    },
  },
  theme4: {
    label: 'Smartly',
    value: 'theme4',
    thumbnail: 'https://ucarecdn.com/bfe7fc07-0ad9-402a-88af-8a70d031f021/',
    defaultValue: {
      color_bg: '#E68928',
      color_accent: '#525F7F',
    },
  },
};
