import React, { useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import ThumbIcon from 'partial/components/ThumbIcon';

import { formatCurrency } from 'helper';
import { useResizeObserver } from 'partial/hooks';

const PaymentLinkContents = ({ paymentLinkData: data }) => {
  const itemsContainerRef = useRef();
  const itemsContainerDimensions = useResizeObserver(itemsContainerRef);
  const [isContentContainerExpanded, setIsContentContainerExpanded] =
    useState(false);

  return (
    <div className="rounded-[0.25rem] border px-4 pt-4">
      <button
        type="button"
        className="flex w-full justify-between"
        onClick={() => setIsContentContainerExpanded((prev) => !prev)}
      >
        <p>Order Items</p>
        <div className="grid h-4 w-4 place-items-center rounded-[0.25rem] border bg-gray-50">
          <FaChevronDown
            className={cn(
              'h-3 w-3 text-gray-400 transition-transform',
              isContentContainerExpanded ? 'rotate-180' : ''
            )}
          />
        </div>
      </button>
      <div
        className="relative mt-4 flex flex-col overflow-hidden transition-[height] ease-bounce"
        style={{
          height: isContentContainerExpanded
            ? `${itemsContainerDimensions?.height + 20}px`
            : '80px',
        }}
      >
        <div ref={itemsContainerRef} className="flex flex-col space-y-2">
          {data?.items?.map((item) => (
            <div
              key={item.id}
              className="flex h-20 overflow-hidden rounded border"
            >
              <ThumbIcon
                className="h-20 w-20 !rounded-none !border-none"
                url={item.photo_url}
                alt={item.name}
                square
              />
              <div className="flex-grow p-4">
                <p className="flex justify-between text-sm font-bold leading-4">
                  <span>{`${item.qty}x ${item?.name}`}</span>
                  <span>
                    {formatCurrency(
                      parseInt(item?.qty, 10) * parseFloat(item.amount)
                    )}
                  </span>
                </p>
              </div>
            </div>
          ))}
          <div className="border-t pt-4">
            <p className="flex justify-between text-sm font-bold leading-4">
              <span>Total</span>
              <span>{data?.total_amount_human}</span>
            </p>
          </div>
        </div>
        {!isContentContainerExpanded && (
          <div className="absolute inset-0 h-full w-full bg-opacity-25 bg-gradient-to-b from-transparent to-white" />
        )}
      </div>
    </div>
  );
};

PaymentLinkContents.defaultProps = {
  paymentLinkData: {},
};

PaymentLinkContents.propTypes = {
  paymentLinkData: PropTypes.instanceOf(Object),
};

export default PaymentLinkContents;
