import React from 'react';
import dayjs from 'dayjs';
import {
  TableIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import Card from 'partial/components/Card';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import BarChart from 'modules/common/chart/BarChart';
import LineChart from 'modules/common/chart/LineChart';
import HistogramRange from 'partial/components/HistogramRange';

export const useHistogramCard = (array, default_index = 0) => {
  const [selectedIndex, setSelectedIndex] = React.useState(default_index);
  const output = React.useMemo(
    () => ({
      ...array?.[selectedIndex],
      key: array?.[selectedIndex]?.id,
      actionOptions: array.map((x, i) => ({
        id: x.id,
        label: x.label,
        onClick: () => {
          setSelectedIndex(i);
        },
      })),
    }),
    [array, selectedIndex, setSelectedIndex]
  );

  return output;
};

const MAP_TYPE = {
  day: 'hour',
  month: 'day',
  year: 'month',
};

function HistogramCard({
  hook,
  label,
  dateRange,
  switchMode,
  defaultSort,
  defaultMode,
  tableFormat,
  defaultChart,
  actionOptions,
  filter: otherFilter,
  bar: showBar,
  pie: showPie,
  table: showTable,
  meta: additionalMeta,
  chartDecimalValue,
}) {
  const [chartType, setChartType] = React.useState(defaultChart);
  const [meta, setMeta] = React.useState({
    sort: defaultSort,
    ...additionalMeta,
  });
  const [filter, setFilter] = React.useState({
    type: MAP_TYPE[defaultMode],
    from: dayjs().startOf(defaultMode).format('YYYY-MM-DD'),
    to: dayjs().endOf(defaultMode).format('YYYY-MM-DD'),
  });
  const newFilter = React.useMemo(
    () => ({
      ...filter,
      ...otherFilter,
    }),
    [otherFilter, filter]
  );
  const [isLoading, table, chart, subLabel, dataLabel] = hook(newFilter, meta);
  const toggleChart = React.useMemo(
    () => (
      <div className="flex items-center gap-1">
        {dateRange && (
          <HistogramRange
            onChange={setFilter}
            defaultValue={dayjs().format('YYYY-MM-DD')}
            defaultMode={defaultMode}
            switchMode={switchMode}
            panelClassName="right-0"
            small
          />
        )}
        <div className="flex justify-end space-x-1">
          {showTable ? (
            <Button
              size="xs"
              primary={chartType === 'table'}
              icon={TableIcon}
              onClick={() => setChartType('table')}
            />
          ) : null}
          {showPie ? (
            <Button
              size="xs"
              primary={chartType === 'line'}
              icon={PresentationChartLineIcon}
              onClick={() => setChartType('line')}
            />
          ) : null}
          {showBar ? (
            <Button
              size="xs"
              primary={chartType === 'bar'}
              icon={PresentationChartBarIcon}
              onClick={() => setChartType('bar')}
            />
          ) : null}
        </div>
      </div>
    ),
    [
      setFilter,
      chartType,
      setChartType,
      defaultMode,
      switchMode,
      dateRange,
      showBar,
      showPie,
      showTable,
    ]
  );

  return (
    <Card
      label={label}
      actionOptions={actionOptions}
      renderAction={toggleChart}
    >
      <div className="flex flex-col pr-3" style={{ height: 300 }}>
        {chartType === 'table' && (
          <Table
            isLoading={isLoading}
            data={table}
            format={tableFormat}
            downloadCsv={(row) => ({
              Label: row.label,
              Txn: row.transaction,
              Amount: row.amount,
            })}
            downloadFilename={label}
            onSort={meta.sort.indexOf('id') > -1 ? false : setMeta}
            sort={meta.sort}
            subLabel={subLabel}
          />
        )}
        {chartType === 'line' && (
          <LineChart
            label={dataLabel || undefined}
            data={chart}
            isLoading={isLoading}
            decimalValue={chartDecimalValue}
          />
        )}
        {chartType === 'bar' && (
          <BarChart
            label={dataLabel || undefined}
            data={chart}
            horizontal={false}
            isLoading={isLoading}
            decimalValue={chartDecimalValue}
          />
        )}
      </div>
    </Card>
  );
}

HistogramCard.defaultProps = {
  meta: {},
  filter: {},
  label: 'Chart',
  dateRange: true,
  switchMode: true,
  actionOptions: [],
  defaultMode: 'year',
  defaultChart: 'line',
  defaultSort: 'id:asc',
  table: true,
  pie: true,
  bar: true,
  chartDecimalValue: 0,
  tableFormat: [
    { key: 'name', label: 'Label' },
    { key: 'transaction', label: 'Transaction', width: 120 },
    { key: 'amount', label: 'Amount', width: 120 },
  ],
};

HistogramCard.propTypes = {
  bar: PropTypes.bool,
  pie: PropTypes.bool,
  table: PropTypes.bool,
  label: PropTypes.string,
  dateRange: PropTypes.bool,
  switchMode: PropTypes.bool,
  defaultMode: PropTypes.string,
  defaultSort: PropTypes.string,
  defaultChart: PropTypes.string,
  hook: PropTypes.func.isRequired,
  meta: PropTypes.instanceOf(Object),
  chartDecimalValue: PropTypes.number,
  filter: PropTypes.instanceOf(Object),
  tableFormat: PropTypes.instanceOf(Array),
  actionOptions: PropTypes.instanceOf(Array),
};

export default HistogramCard;
