import React from 'react';
import PropTypes from 'prop-types';
import { BiCopy } from 'react-icons/bi';
import { toastSuccess } from 'react-geek-toast';

function CopyToClipboard({ value, copyValue }) {
  const handleCopyToClipboard = (e) => {
    e.preventDefault();
    const toCopy = copyValue || value;
    navigator.clipboard.writeText(toCopy);
    toastSuccess('Copied to clipboard!');
  };
  return (
    <a
      className="flex items-center space-x-1 text-gray-900 hover:text-primary-500"
      href="/"
      onClick={handleCopyToClipboard}
    >
      <span className="w-48 truncate">{value}</span>
      <BiCopy className="w-7 h-7 hover:bg-gray-100 bg-white p-1.5 rounded-full transition" />
    </a>
  );
}

CopyToClipboard.defaultProps = {
  copyValue: '',
};

CopyToClipboard.propTypes = {
  value: PropTypes.string.isRequired,
  copyValue: PropTypes.string,
};

export default CopyToClipboard;
