import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { debounce } from 'lodash';

function FormSearchInput({
  icon: Icon,
  name,
  onChange,
  value,
  className,
  withClearBtn,
  ...props
}) {
  const inputRef = useRef();
  const [watchValue, setWatchValue] = useState(value ?? '');

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleChange = ({ target }) => {
    setWatchValue(target.value);
    debouncedOnChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };

  const clearValue = () => {
    setWatchValue('');
    onChange((state) => ({
      ...state,
      [name]: '',
    }));
    if (typeof value === 'undefined') {
      inputRef.current.value = '';
    }
  };
  return (
    <div className="relative">
      {Icon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
      <input
        ref={inputRef}
        type="text"
        className={cn(
          'form-input block w-full rounded-md border-gray-300 py-4 text-sm focus:border-primary-500 focus:ring-primary-500',
          Icon ? 'pl-10' : '', // with icon
          className
        )}
        onChange={handleChange}
        value={watchValue}
        {...props}
      />
      {withClearBtn && watchValue ? (
        <button
          type="button"
          onClick={clearValue}
          className="absolute right-0 top-1/2 -translate-y-1/2 px-3 font-semibold text-primary-500 hover:underline"
        >
          Clear
        </button>
      ) : null}
    </div>
  );
}

FormSearchInput.defaultProps = {
  icon: false,
  className: '',
  value: undefined,
  withClearBtn: false,
};

FormSearchInput.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.exact(undefined)]),
  className: PropTypes.string,
  withClearBtn: PropTypes.bool,
};

export default FormSearchInput;
