import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from 'partial/components/Card';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import { showDrawer } from 'partial/components/Modal';
import GenericInfo, {
  InfoItem,
  InfoDivider,
} from 'partial/components/GenericInfo';

import * as c from '../constants';
import { useGetTransactionDetails } from '../hooks';

export const openWebhookModal = (data) => {
  showDrawer({
    size: 'lg',
    closeOutside: true,
    title: 'Webhook Log',
    content: (onClose) => <WebhookModal data={data} onClose={onClose} />,
  });
};

function WebhookModal({ data, onClose }) {
  const [, transaction, fetch] = useGetTransactionDetails();
  useEffect(() => {
    fetch(data?.transaction_uuid);
  }, [fetch, data]);
  return (
    <div className="flex flex-col space-y-2">
      <Card>
        <GenericInfo data={transaction}>
          <InfoDivider label="Transaction Details" />
          <InfoItem
            label="ID"
            value={(item) => <span>{item?.uuid || '--'}</span>}
          />
          <InfoItem
            label="Transaction ID"
            value={(item) => <span>{item?.txnid || '--'}</span>}
          />
          <InfoItem
            label="Ref No."
            value={(item) => <span>{item?.refno || '--'}</span>}
          />
          <InfoItem
            label="Amount"
            value={(item) => <span>{item?.amount || '--'}</span>}
          />
        </GenericInfo>
        <GenericInfo data={data}>
          <InfoDivider label="Webhook Details" />
          <InfoItem label="Created At" value="created_at" />
          <InfoItem
            label="Callback Url"
            value={(item) => (
              <a href={item?.callback_url} className="link-primary">
                {item?.callback_url}
              </a>
            )}
          />
          <InfoItem label="Elapsed Time" value="elapsed_time" />
          <InfoItem
            label="Event Type"
            value={(item) => (
              <span className={c.MAP_EVENT_TYPE_CLASS[item?.event_type]}>
                {c.MAP_EVENT_TYPE[item?.event_type]}
              </span>
            )}
          />
          <InfoItem
            label="Posting Status"
            value={(item) => (
              <span className={c.MAP_STATUS_CLASS[item?.status]}>
                {c.MAP_STATUS[item?.status]}
              </span>
            )}
          />
          <InfoDivider label="Webhook Request and Response" />
          <div className="flex flex-col min-h-[300px]">
            <Table
              data={[
                {
                  id: 1,
                  request: data?.request,
                  response: data?.response,
                },
              ]}
              format={[
                {
                  label: 'Request',
                  id: 'request',
                  key: (row) => (
                    <pre className="whitespace-pre-wrap">
                      {JSON.stringify(row?.request, null, 2)}
                    </pre>
                  ),
                  width: '60%',
                },
                {
                  label: 'Response',
                  id: 'response',
                  key: (row) => (
                    <pre className="whitespace-pre-wrap">
                      {JSON.stringify(row?.response, null, 2)}
                    </pre>
                  ),
                  width: '40%',
                },
              ]}
            />
          </div>
        </GenericInfo>
      </Card>
      <div>
        <Button primary onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
}

WebhookModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default WebhookModal;
