import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import PropTypes from 'prop-types';
import { formatNumber } from 'helper';

function SimpleLineChart({ data, isLoading }) {
  const options = {
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: data?.map((item, i) => item?.title ?? `Data ${i + 1}`),
    },
    tooltip: {
      enabled: false,
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      formatter(val) {
        if (!val) return '';
        return [`${formatNumber(val, 0)}`];
      },
    },
    colors: ['#2486bc'],
  };

  const series = [
    {
      name: 'Count',
      data: data?.map((item) => item?.count ?? null),
    },
  ];
  if (isLoading) {
    return (
      <div className="h-full w-full pl-10 py-8 pr-6">
        <div
          className="animate-pulse bg-gray-200 h-full w-full rounded"
          style={{
            clipPath:
              'polygon(0 80%, 13% 71%, 25% 55%, 39% 44%, 54% 51%, 66% 46%, 78% 34%, 90% 22%, 100% 33%, 100% 43%, 100% 100%, 0% 100%)',
          }}
        />
      </div>
    );
  }
  return (
    <ReactApexCharts
      height="100%"
      options={options}
      series={series}
      type="area"
    />
  );
}
SimpleLineChart.defaultProps = {
  data: {
    labels: [],
    series: [],
  },
  isLoading: false,
};

SimpleLineChart.propTypes = {
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

export default SimpleLineChart;
