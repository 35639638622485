import React, { useState, useCallback, useMemo, useRef } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import AuthStore from 'auth-store';
import { toastSuccess } from 'react-geek-toast';
import { useHistory, useLocation } from 'react-router-dom';

import { useResizeObserver } from 'partial/hooks';
import { switchAccount } from 'modules/auth/actions';
import { useActiveAccount } from 'modules/auth/hooks';
import { MAP_SWITCH_CODE } from 'modules/auth/constants';
import { useToggleLiveTestEnvironment } from 'modules/auth/modals/SwitchProfileModal';
import { ModalCard, showAlert, useCreateModal } from 'partial/components/Modal';
import TransactionTable from 'core-modules/dashboard/components/TransactionTable';

import RefundForm from '../forms/RefundForm';

export const useOpenPromptSwitchToLiveMode = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeAccount] = useActiveAccount();
  const { toggleEnvironment: promptSwitchModal } =
    useToggleLiveTestEnvironment();
  const prevCode = `${MAP_SWITCH_CODE[activeAccount?.env_mode]}_session`;
  const token = AuthStore.get(`${prevCode}`, prevCode);
  const handleLive = () => {
    history.push('/dashboard', { redirectTo: location?.pathname });
    if (!token) {
      promptSwitchModal();
      return;
    }
    switchAccount(token);
  };
  const openPromptSwitchToLiveMode = () => {
    showAlert({
      subTitle: 'Creation of refund request is only available in Live Mode',
      yesLabel: 'Switch to Live Mode',
      onYes: (onClose) => {
        handleLive();
        onClose();
      },
      noLabel: 'Cancel',
      color: 'primary',
    });
  };
  return openPromptSwitchToLiveMode;
};

export const useOpenCreateRefundRequestModal = (onSuccess = () => {}) => {
  const createModal = useCreateModal();

  const openCreateRefundRequestModal = useCallback(() => {
    createModal({
      content: (close) => (
        <RefundRequestModal onClose={close} onSuccess={onSuccess} />
      ),
    });
  }, [createModal, onSuccess]);

  return openCreateRefundRequestModal;
};

const TRANSACTION_FILTER = {
  payment_status: 'PAID',
};

function RefundRequestModal({ onClose, onSuccess }) {
  const selectedConatinerRef = useRef();
  const selectedContainerDimensions = useResizeObserver(selectedConatinerRef);

  const [selectedTransactionData, setSelectedTransactionData] = useState({});
  const hasSelected = useMemo(
    () => !isEmpty(selectedTransactionData),
    [selectedTransactionData]
  );

  const handleSelectTransactionToRequest = (transaction) =>
    setSelectedTransactionData(transaction);

  const handleCreateRequestSuccess = () => {
    toastSuccess('Refund request created.');
    onClose();
    onSuccess();
  };

  return (
    <ModalCard
      size={hasSelected ? 'lg' : 'xl'}
      title={
        hasSelected
          ? `Refund Request - ${selectedTransactionData?.refno || ''}`
          : 'Select Transaction'
      }
      onClose={onClose}
    >
      <div
        className="w-full transition-all duration-200 ease-in-out"
        style={{
          height: hasSelected
            ? `${selectedContainerDimensions?.height || 0}px`
            : '715px',
        }}
      >
        <div className="h-full overflow-hidden">
          <div
            className={cn(
              'flex h-full transform transition-transform duration-200 ease-in-out',
              hasSelected ? 'translate-x-0' : '-translate-x-full'
            )}
          >
            <div
              ref={selectedConatinerRef}
              className="h-fit w-full flex-shrink-0"
            >
              <RefundForm
                data={selectedTransactionData}
                onCancel={() => setSelectedTransactionData({})}
                onSuccess={handleCreateRequestSuccess}
              />
            </div>
            <div
              className={cn(
                'flex h-full w-full flex-shrink-0 flex-col overflow-y-auto p-4 transition-opacity sm:overflow-y-hidden'
              )}
            >
              <TransactionTable
                onRowClick={handleSelectTransactionToRequest}
                filter={TRANSACTION_FILTER}
                hideFilterPaymentStatus
                hideFilterPostingStatus
              />
            </div>
          </div>
        </div>
      </div>
    </ModalCard>
  );
}

RefundRequestModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default RefundRequestModal;
