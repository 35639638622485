import React from 'react';
import PropTypes from 'prop-types';

const DocItem = ({ data }) => {
  return (
    <>
      <div className="space-y-2 p-3">
        <div className="flex items-center space-x-2 justify-between">
          <div className="flex items-center space-x-2">
            <div className="font-bold text-gray-600">{data?.key}</div>
            <div className="italic text-gray-500">{data?.type}</div>
          </div>
          {data?.required ? (
            <div className="badge badge-danger">required</div>
          ) : null}
        </div>
        <div className="text-gray-600">{data?.description}</div>
      </div>
      {Array.isArray(data?.children)
        ? data?.children.map((item) => (
            <div className="pl-5" key={item.key}>
              <DocItem data={item} />
            </div>
          ))
        : null}
    </>
  );
};

DocItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

function DocParams({ data }) {
  return (
    <div className="border rounded-md mx-4 mb-4 bg-gray-50">
      <div className="divide-y">
        {data?.map((item) => (
          <DocItem data={item} key={item.key} />
        ))}
      </div>
    </div>
  );
}

DocParams.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default DocParams;
