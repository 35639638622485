import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

const ErrorState = ({ onRefresh }) => {
  return (
    <section className="relative z-10 bg-primary px-4 py-[7.5rem]">
      <div className="container mx-auto">
        <div className="-mx-4 flex">
          <div className="w-full px-4 text-center">
            <h2 className="mb-2 text-3xl font-bold leading-none text-white">
              Something wen&apos;t wrong
            </h2>
            <h4 className="mb-3 text-xl font-semibold leading-tight text-white">
              Oops! There&apos;s an error while loading the content
            </h4>
            <p className="mb-8 text-white">
              Try clicking the button below to refresh.
            </p>
            <button
              type="button"
              className="inline-block rounded-lg border border-white px-8 py-3 text-center text-base font-semibold text-white transition hover:bg-white hover:text-primary"
              onClick={onRefresh}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className="absolute left-0 top-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
        <div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]" />
        <div className="flex h-full w-1/3">
          <div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]" />
          <div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]" />
        </div>
        <div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]" />
      </div>
    </section>
  );
};

ErrorState.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

const SkeletonLoader = () => {
  return (
    <div role="status" className="animate-pulse">
      <div className="mb-4 h-8 w-48 rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 w-full rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[20.625rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[18.75rem] rounded-full bg-gray-300" />
      <div className="mb-2 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 w-full rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[20.625rem] rounded-full bg-gray-300" />
      <div className="mb-4 h-4 max-w-[18.75rem] rounded-full bg-gray-300" />
      <div className="mb-6 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-4 h-8 w-48 rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 w-full rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[20.625rem] rounded-full bg-gray-300" />
      <div className="mb-2.5 h-4 max-w-[18.75rem] rounded-full bg-gray-300" />
      <div className="h-4 max-w-[22.5rem] rounded-full bg-gray-300" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

function Terms() {
  const [termsAndConditionsMd, setTermsAndConditionsMd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const termsAndConditionsHtml = useMemo(
    () => DOMPurify.sanitize(marked.parse(termsAndConditionsMd)),
    [termsAndConditionsMd]
  );

  const fetchData = useCallback(() => {
    try {
      (async () => {
        setIsError(false);
        setIsLoading(true);
        const response = await fetch('/terms-and-conditions.md');
        const text = await response?.text();
        setTermsAndConditionsMd(text);
      })();
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setIsError, setTermsAndConditionsMd]);

  useEffect(fetchData, [fetchData]);

  return (
    <div className="max-w-4xl p-4 sm:p-6 md:p-10">
      {isLoading && isEmpty(termsAndConditionsMd) && <SkeletonLoader />}
      {!isLoading && isError && (
        <div className="-m-4 sm:-m-6 md:-m-10">
          <ErrorState onRefresh={fetchData} />
        </div>
      )}
      {!isLoading && !isEmpty(termsAndConditionsMd) && (
        <div
          className="prose-base w-full prose-headings:text-center prose-h2:text-xl prose-ol:list-decimal prose-ul:list-disc prose-li:my-0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: termsAndConditionsHtml,
          }}
        />
      )}
    </div>
  );
}

export default Terms;
