import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { HiOutlineDocumentSearch, HiDownload } from 'react-icons/hi';

import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import BreadCrumb from 'partial/components/BreadCrumb';
import PageWrapper from 'partial/components/PageWrapper';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { showPayoutLogsModal } from '../modals/PayoutLogsModal';
import { useDownloadPayout, usePayoutList } from '../hooks';
import { renderBadge } from '../constants';
import CountCards from '../components/CountCards';
import { TIME_PRESET_VALUE } from 'modules/common/components/DateRangePicker';

const STATUS_OPTIONS = [
  { label: 'All Status', value: '' },
  { label: 'Settled', value: 'SETTLED' },
  { label: 'Unsettled', value: 'UNSETTLED' },
];

function Filter({ onSubmit, value }) {
  return (
    <ModuleListFilters
      filter={value}
      setFilter={onSubmit}
      renderCustomFields={({ inlineFilter, setInlineFilter }) => (
        <FormInlineSelect
          name="payment_status"
          label={
            <span className="text-sm text-gray-800 sm:hidden">
              Payment Status
            </span>
          }
          onChange={setInlineFilter}
          value={inlineFilter?.payment_status}
          options={STATUS_OPTIONS}
        />
      )}
      activeFiltersFormat={[
        {
          label: 'Payment Status',
          key: 'payment_status',
          matrix: [
            ['SETTLED', 'Settled'],
            ['UNSETTLED', 'Unsettled'],
          ],
        },
        {
          label: 'Date Range',
          key: 'from',
          matrix: (val) =>
            `${dayjs(val?.from).format('MMM DD, YYYY')} - ${dayjs(
              val?.to
            ).format('MMM DD, YYYY')}`,
          clearFilter: () =>
            onSubmit((state) => ({
              ...state,
              date_range_preset: '',
              from: '',
              to: '',
            })),
        },
      ]}
    />
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

const INIT_FILTER = {
  page: 1,
  type: 'day',
  keyword: '',
  order: 'DESC',
  payment_status: '',
  date_range_preset: 'LAST_MONTH',
  ...TIME_PRESET_VALUE.LAST_MONTH,
};

function PayoutContainer({ filter: otherFilter }) {
  const [raw, setFilter] = useState(INIT_FILTER);

  const filter = React.useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );

  const [isLoading, list, pager, appendList] = usePayoutList(filter);
  const [isDownloading, downloadPayout] = useDownloadPayout();

  const tableFormat = useMemo(() => {
    const logsAction = (row) => (
      <div className="flex justify-center space-x-1">
        <div>
          <Button
            icon={HiOutlineDocumentSearch}
            onClick={() => showPayoutLogsModal(row)}
            transparent
          >
            Logs
          </Button>
        </div>
        <div>
          <Button
            icon={HiDownload}
            onClick={() =>
              downloadPayout(row.id, `Payout - ${row?.report_date}.csv`)
            }
            disabled={isDownloading}
            transparent
          >
            Download
          </Button>
        </div>
      </div>
    );
    const cols = [
      {
        label: 'Created At',
        id: 'created_at',
        key: 'created_at',
        stackKey: true,
      },
      {
        smRender: (item) => (
          <div className="py-3">
            <div className="float-right text-right">
              <div>Total Amount: {item?.amount}</div>
              <div>Payout Fee: {item?.payout_fee_human}</div>
              <div>{renderBadge(item?.status, item?.status_label)}</div>
            </div>
            <div className="text-primary-500">{item?.merchant_name}</div>
            <div className="text-gray-500">
              {`(${item?.transactions})`} Transactions
            </div>
            <div className="text-gray-500">{item?.report_date}</div>
            {logsAction(item)}
          </div>
        ),
      },
    ];
    cols.push({
      label: 'Report Date',
      id: 'report_date',
      key: 'report_date',
      stackKey: true,
    });
    cols.push({
      label: 'Merchant',
      id: 'merchant_name',
      key: 'merchant_name',
      stackKey: true,
    });
    cols.push({
      label: 'Payout Fee',
      id: 'payout_fee',
      key: 'payout_fee_human',
      stackKey: true,
    });
    cols.push({
      label: 'Amount',
      id: 'amount',
      key: 'amount',
      stackKey: true,
    });
    cols.push({
      label: 'Transactions',
      id: 'transactions',
      key: 'transactions',
      stackKey: true,
    });
    cols.push({
      label: 'Payment Status',
      id: 'status',
      key: ({ status, status_label }) => renderBadge(status, status_label),
      stackKey: true,
    });
    cols.push({
      label: '',
      id: 'action',
      width: '200px',
      key: logsAction,
      stackKey: true,
    });
    return cols;
  }, [isDownloading, downloadPayout]);

  return (
    <>
      <BreadCrumb>Payout</BreadCrumb>
      <PageWrapper>
        <CountCards filter={filter} />
        <div className="z-[1] py-3">
          <Filter onSubmit={setFilter} value={raw} />
        </div>
        <div className="flex flex-col rounded-lg border bg-white sm:flex-grow">
          <Table
            isLoading={isLoading}
            data={list}
            format={tableFormat}
            onSort={setFilter}
            sort={raw.sort}
            pager={pager}
            onLoadMore={appendList}
          />
        </div>
      </PageWrapper>
    </>
  );
}

PayoutContainer.defaultProps = {
  filter: {},
};

PayoutContainer.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default PayoutContainer;
