export const transformUser = (raw) => ({
  id: raw?.uuid,
  organization_uuid: raw?.organization_uuid,
  merchant_uuid: raw?.merchant_uuid,
  email_address: raw?.email_address,
  first_name: raw?.first_name,
  last_name: raw?.last_name,
  mobile_number: raw?.mobile_number,
  role: raw?.role || '',
  created_at: raw?.created_at,
  is_email_verified: raw?.is_email_verified === 1,
});

export const transformRole = (raw) => ({
  id: raw?.uuid,
  name: raw?.name,
  code: raw?.code,
  created_at: raw?.created_at,
});
