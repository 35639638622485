/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { HiPlus } from 'react-icons/hi';
import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import { VscDebugDisconnect } from 'react-icons/vsc';

import DropdownState, {
  DropdownStateItem,
} from 'partial/components/DropdownState';
import CopyToClipboard from 'partial/components/CopyToClipboard';
import DropdownMenu, {
  DropdownMenuItem,
} from 'partial/components/DropdownMenu';

import { openTestApiModal } from '../modals/TestApiModal';
import { showApiKeyModal } from '../modals/ApiKeyModal';
import {
  useGetApiKeyList,
  useApiKeyActiveInactive,
  useRegenerateApiKey,
} from '../hooks';

function ApiKeysContainer() {
  const [isLoading, data] = useGetApiKeyList();
  const renderTry = React.useMemo(() => {
    const openTest = () => {
      openTestApiModal();
    };
    const openApiKeyModal = () => {
      showApiKeyModal({}, 'add');
    };
    return (
      <div className="flex items-center space-x-1">
        <Button primary onClick={openApiKeyModal} icon={HiPlus}>
          Add
        </Button>
        <Button onClick={openTest}>Open Documentation</Button>
      </div>
    );
  }, []);
  return (
    <Card label="API Tokens" renderAction={renderTry}>
      <div className="flex flex-grow flex-col divide-y">
        {data.length < 1 ? (
          <div className="py-6 px-10 text-center text-gray-500">
            {isLoading ? 'Loading...' : 'There are no items.'}
          </div>
        ) : (
          data?.map((item) => (
            <div key={item?.api_key_id}>
              <ApiKeyItem item={item} />
            </div>
          ))
        )}
      </div>
    </Card>
  );
}

ApiKeysContainer.propTypes = {};

export default ApiKeysContainer;

const maskApiKey = (str) => {
  if (str) return `${str.substr(0, 4)}******************`;
  return '';
};

const MAP_API_TYPE = {
  'payment-gateway': 'Payment Gateway',
  invoice: 'Invoice',
  transaction: 'Transaction',
};

const ENV_TYPE_CLASS = {
  TEST: 'badge badge-warning',
  LIVE: 'badge badge-success',
};

function ApiKeyItem({ item }) {
  const [isLoading, activeInactive] = useApiKeyActiveInactive();
  const [isRegenerating, regenerate] = useRegenerateApiKey();

  const handleActiveInactive = (type) => {
    if (type === 'ACTIVE') {
      activeInactive(item?.merchant_id, item?.api_key_id, 'Active');
      return;
    }
    activeInactive(item?.merchant_id, item?.api_key_id, 'Inactive');
  };

  const handleRegenerate = () =>
    regenerate(item?.merchant_id, item?.api_key_id);

  const handleUpdate = () => showApiKeyModal(item, 'update');

  return (
    <div className="flex items-start justify-between py-6 px-5">
      <div className="flex w-full flex-col items-start space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
        <div className="flex w-full items-center justify-between sm:w-auto">
          <VscDebugDisconnect className="h-12 w-12 flex-shrink-0 rounded-lg bg-gray-100 p-2" />
          <div className="relative -right-4 ml-auto flex items-center space-x-2 sm:hidden">
            <DropdownState
              onChange={handleActiveInactive}
              isLoading={isLoading || isRegenerating}
              value={item?.status}
            >
              <DropdownStateItem
                label="Active"
                description="Users can manage and access portal sites."
                value="ACTIVE"
                color="green"
              />
              <DropdownStateItem
                label="Inactive"
                description="Disables managing portal site."
                value="INACTIVE"
              />
            </DropdownState>
            <DropdownMenu>
              <DropdownMenuItem
                name="regenerate_token"
                label="Regenerate Token"
                onClick={handleRegenerate}
              />
              <DropdownMenuItem
                name="update"
                label="Update"
                onClick={handleUpdate}
              />
            </DropdownMenu>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center divide-x divide-gray-400">
            {item?.api_type?.map((x, i) => (
              <h1 key={`${x}_${i}`} className="mb-2 px-2 text-center text-lg">
                {MAP_API_TYPE[x]}
              </h1>
            ))}
          </div>
          <div className="ml-2 flex items-center">
            <span className="w-28 text-sm font-medium text-gray-500">
              Token Key:
            </span>
            <span className="font-medium">
              <CopyToClipboard
                value={maskApiKey(item?.token)}
                copyValue={item?.token}
              />
            </span>
          </div>
          <div className="ml-2 flex">
            <span className="w-36 text-sm font-medium text-gray-500">
              Environment Type:
            </span>
            <span
              className={`text-xs ${ENV_TYPE_CLASS[item?.environment_type]}`}
            >
              {item?.environment_type}
            </span>
          </div>
          <div className="ml-2 flex">
            <span className="w-28 text-sm font-medium text-gray-500">
              Queue Code:
            </span>
            <span>{item?.queue_code}</span>
          </div>
          <div className="ml-2 flex">
            <span className="w-28 text-sm font-medium text-gray-500">
              Callback URL:
            </span>
            <span>{item?.callback_url}</span>
          </div>
        </div>
      </div>
      <div className="ml-auto hidden items-center space-x-2 sm:flex">
        <DropdownState
          onChange={handleActiveInactive}
          isLoading={isLoading || isRegenerating}
          value={item?.status}
        >
          <DropdownStateItem
            label="Active"
            description="Users can manage and access portal sites."
            value="ACTIVE"
            color="green"
          />
          <DropdownStateItem
            label="Inactive"
            description="Disables managing portal site."
            value="INACTIVE"
          />
        </DropdownState>
        <DropdownMenu>
          <DropdownMenuItem
            name="regenerate_token"
            label="Regenerate Token"
            onClick={handleRegenerate}
          />
          <DropdownMenuItem
            name="update"
            label="Update"
            onClick={handleUpdate}
          />
        </DropdownMenu>
      </div>
    </div>
  );
}

ApiKeyItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};
