import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiCheck, HiChevronDown } from 'react-icons/hi';

const COLORS = {
  green: [
    'divide-green-500 hover:divide-green-600',
    'bg-green-500',
    'bg-green-600',
    'focus:ring-green-500',
    'text-green-500',
  ],
  yellow: [
    'divide-yellow-500 hover:divide-yellow-600',
    'bg-yellow-500',
    'bg-yellow-600',
    'focus:ring-yellow-500',
    'text-yellow-500',
  ],
  red: [
    'divide-red-500 hover:divide-red-600',
    'bg-red-500',
    'bg-red-600',
    'focus:ring-red-500',
    'text-red-500',
  ],
  default: [
    'divide-gray-500 hover:divide-gray-600',
    'bg-gray-500',
    'bg-gray-600',
    'focus:ring-gray-500',
    'text-gray-500',
  ],
};

export const DropdownStateItem = ({
  label,
  description,
  value,
  color,
  disabled,
  children,
}) => (
  <Listbox.Option
    disabled={disabled}
    className={({ active }) =>
      cn(
        active ? 'text-white bg-primary-500' : 'text-gray-900',
        'cursor-default select-none relative p-4 text-sm'
      )
    }
    value={value}
  >
    {({ selected, active }) => (
      <div className="flex flex-col">
        <div className="flex justify-between">
          <p className={selected ? 'font-semibold' : 'font-normal'}>{label}</p>
          {selected ? (
            <span className={active ? 'text-white' : COLORS[color][4]}>
              <HiCheck className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </div>
        <p
          className={cn(active ? 'text-primary-200 mr-6' : 'text-gray-500', '')}
        >
          {description}
        </p>
        {children}
      </div>
    )}
  </Listbox.Option>
);

DropdownStateItem.defaultProps = {
  color: 'default',
  children: null,
  disabled: false,
};

DropdownStateItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  description: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['green', 'yellow', 'red', 'default']),
};

function DropdownState({
  onChange,
  value,
  children,
  disabled,
  readOnly,
  isLoading,
}) {
  const data =
    (Array.isArray(children)
      ? children.find((x) => x?.props?.value === value)
      : null
    )?.props || {};
  const [divide600, bg500, hoverBg600, focusRing500] =
    COLORS[data.color] || COLORS.default;
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change status</Listbox.Label>
          <div className="relative">
            <div
              className={cn(
                'inline-flex shadow-sm rounded-md divide-x',
                divide600
              )}
            >
              <div
                className={cn(
                  'relative z-0 inline-flex shadow-sm rounded-md divide-x',
                  divide600
                )}
              >
                {isLoading && (
                  <div className="absolute z-10 inset-0 w-full h-full bg-white bg-opacity-50" />
                )}
                <div
                  className={cn(
                    'relative inline-flex items-center py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white',
                    readOnly ? 'rounded-r-md' : '',
                    bg500
                  )}
                >
                  <HiCheck className="h-5 w-5" aria-hidden="true" />
                  <p className="ml-2.5 text-sm font-medium">
                    {data.label || 'Unknown'}
                  </p>
                </div>
                {!readOnly ? (
                  <Listbox.Button
                    className={cn(
                      'relative inline-flex items-center  p-2 rounded-l-none rounded-r-md text-sm font-medium text-white focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50',
                      bg500,
                      hoverBg600,
                      focusRing500
                    )}
                  >
                    <span className="sr-only">Change status</span>
                    <HiChevronDown
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                ) : null}
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

DropdownState.defaultProps = {
  disabled: false,
  readOnly: false,
  isLoading: false,
};

DropdownState.propTypes = {
  // isLoading: PropTypes.bool // TODO apply this later
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DropdownState;
