import React from 'react';
import PropTypes from 'prop-types';

const CLASS_MAPPER = {
  POSTED: 'badge badge-success',
  UNPOSTED: 'badge badge-danger',
  IN_PROGRESS: 'badge badge-warning',
};

const LABEL_MAPPER = {
  POSTED: 'Posted',
  UNPOSTED: 'Unposted',
  IN_PROGRESS: 'In Progress',
};

export const postingStatusTableKey = ({ posting_status }) => (
  <PostingStatus status={posting_status || ''} />
);

function PostingStatus({ status }) {
  return (
    <span className={CLASS_MAPPER[status]}>
      {LABEL_MAPPER[status] || status}
    </span>
  );
}

PostingStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PostingStatus;
