import { useEffect, useCallback, useMemo } from 'react';
import { omit } from 'lodash';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { removeEmpty } from 'helper';

import { useMerchantSelected } from 'core-modules/merchants/hooks';

import * as c from './constants';
import * as actions from './actions';

export const useGetApiKeyList = () => {
  const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_API_KEY_LIST, 'get');
  const data = useApiGet(c.MERCHANT_API_KEY_LIST, []);
  useEffect(() => {
    actions.getApiKeyList(merchant?.id);
  }, [merchant]);
  return [isLoading, data];
};

export const useApiKeyOptions = () => {
  const data = useApiGet(c.MERCHANT_API_KEY_LIST, []);
  const options = useMemo(
    () =>
      data.map((x) => ({
        label: x.api_type,
        value: x.api_key_id,
        data: x,
      })),
    [data]
  );
  return [options];
};

export const useApiKeyActiveInactive = () => {
  const isLoading = useApiLoading(c.MERCHANT_API_KEY, 'put');
  const activeInactive = useCallback(
    (merchantId, apiKeyId, status, onSuccess) => {
      actions.apiKeyActiveInactive(merchantId, apiKeyId, status, onSuccess);
    },
    []
  );
  return [isLoading, activeInactive];
};

export const useRegenerateApiKey = () => {
  const isLoading = useApiLoading(c.MERCHANT_API_KEY_REGENERATE, 'put');
  const regenerate = useCallback((merchantId, apiKeyId, onSuccess) => {
    actions.regenerateApiKey(merchantId, apiKeyId, onSuccess);
  }, []);
  return [isLoading, regenerate];
};

export const useCreateApiKey = () => {
  const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_API_KEY_ITEM, 'post');
  const create = useCallback(
    (payload, onSuccess) => {
      actions.createApiKey(merchant?.id, payload, onSuccess);
    },
    [merchant]
  );
  return [isLoading, create];
};

export const useUpdateApiKey = () => {
  const isLoading = useApiLoading(c.MERCHANT_API_KEY_ITEM, 'put');
  const update = useCallback((merchantId, apiKeyId, payload, onSuccess) => {
    actions.updateApiKey(merchantId, apiKeyId, payload, onSuccess);
  }, []);
  return [isLoading, update];
};

export const useSimulateInquire = () => {
  const isLoading = useApiLoading(c.SIMULATE_INQUIRE, 'get');
  const data = useApiGet(c.SIMULATE_INQUIRE, {});
  const fetch = useCallback((refno) => {
    actions.simulateInquire(refno);
  }, []);
  useEffect(() => {
    return () => {
      req.set(c.SIMULATE_INQUIRE, null);
    };
  }, []);
  return [isLoading, data, fetch];
};

export const useSimulateProcess = () => {
  const isLoading = useApiLoading(c.SIMULATE_INQUIRE, 'post');
  const pay = useCallback((refno) => {
    actions.simulatePay(refno);
  }, []);
  const fail = useCallback((refno) => {
    actions.simulateFail(refno);
  }, []);
  const clear = useCallback(() => {
    req.set(c.SIMULATE_INQUIRE, null);
  }, []);
  return [isLoading, { pay, fail, clear }];
};

export const useTotalWebhook = (params) => {
  const isLoading = useApiLoading(`${c.MERCHANT_WEBHOOK}/total`, 'get');
  const data = useApiGet(`${c.MERCHANT_WEBHOOK}/total`, {});
  useEffect(() => {
    actions.totalWebhook(removeEmpty(params));
  }, [params]);
  return [isLoading, data];
};

export const useHistogramWebhook = (params) => {
  const isLoading = useApiLoading(`${c.MERCHANT_WEBHOOK}/histogram`, 'get');
  const data = useApiGet(`${c.MERCHANT_WEBHOOK}/histogram`, []);
  useEffect(() => {
    actions.histogramWebhook(removeEmpty(params));
  }, [params]);
  return [isLoading, data];
};

export const useLogsWebhook = (params) => {
  const isLoading = useApiLoading(`${c.MERCHANT_WEBHOOK}/logs`, 'get');
  const data = useApiGet(`${c.MERCHANT_WEBHOOK}/logs`, []);
  useEffect(() => {
    actions.logsWebhook(omit(removeEmpty(params), ['date_column', 'type']));
  }, [params]);
  return [isLoading, data];
};

export const useGetTransactionDetails = () => {
  const isLoading = useApiLoading(c.MERCHANT_TRANSACTION, 'get');
  const data = useApiGet(c.MERCHANT_TRANSACTION, {});
  const fetch = useCallback((id) => {
    actions.getTransactionDetails(id);
  }, []);
  return [isLoading, data, fetch];
};
