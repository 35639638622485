import React from 'react';
import PropTypes from 'prop-types';
import Table from 'partial/components/Table';
import { showDrawer } from 'partial/components/Modal';
import GenericInfo, { InfoItemSm } from 'partial/components/GenericInfo';
import { usePayoutLogs } from '../hooks';
import { renderBadge } from '../constants';

export const showPayoutLogsModal = (data) => {
  showDrawer({
    title: 'Logs',
    size: 'xl',
    content: (onClose) => <PayoutLogsModal data={data} onClose={onClose} />,
  });
};

function PayoutLogsModal({ data }) {
  const [isLoading, list, pager] = usePayoutLogs(data?.id);
  const tableFormat = React.useMemo(() => {
    const cols = [
      { label: 'Date/Time', id: 'paid_at', key: 'paid_at', stackKey: true },
      { label: 'Channel', id: 'channel', key: 'channel', stackKey: true },
      {
        label: 'Channel Branch',
        id: 'channel_branch',
        key: 'channel_branch',
        stackKey: true,
      },
      {
        label: 'Payment Status',
        id: 'payment_status',
        key: 'payment_status',
        stackKey: true,
      },
      { label: 'Ref No.', id: 'refno', key: 'refno', stackKey: true },
      { label: 'Txn No.', id: 'txnid', key: 'txnid', stackKey: true },
      {
        smRender: (item) => (
          <div>
            <div className="text-primary-500">{item?.paid_at}</div>
            <div className="text-gray-500">{item?.channel_branch}</div>
            <div className="text-gray-500">{item?.payment_status}</div>
            <div className="text-gray-500">{item?.refno}</div>
            <div className="text-gray-500">{item?.txnid}</div>
          </div>
        ),
      },
    ];
    return cols;
  }, []);
  return (
    <div className="flex h-full flex-col space-y-3">
      <div className="grid grid-cols-2">
        <GenericInfo data={data}>
          <InfoItemSm value="created_at" label="Created At" />
          <InfoItemSm value="report_date" label="Report Date" />
          <InfoItemSm
            value={({ status, status_label }) =>
              renderBadge(status, status_label)
            }
            label="Status"
          />
          <InfoItemSm value="settled_at" label="Settled At" />
        </GenericInfo>
        <GenericInfo data={data}>
          <InfoItemSm value="amount" label="Amount" />
          <InfoItemSm value="payout_fee_human" label="Payout Fee" />
          <InfoItemSm value="transactions" label="Transactions" />
        </GenericInfo>
      </div>
      <div className="font-bold">History</div>
      <div className="flex-grow">
        <div className="flex h-full flex-col">
          <Table
            isLoading={isLoading}
            data={list}
            format={tableFormat}
            pager={pager}
          />
        </div>
      </div>
    </div>
  );
}

PayoutLogsModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default PayoutLogsModal;
