import { req } from 'react-reqq-lite';

import * as c from './constants';
import * as t from './transformers';

export const getNotificationList = (onSuccess, onError) => (params) => {
  req.get({
    key: c.LIST_NOTIFICATION,
    url: '/notification/v1/notifications',
    params,
    transform: (res) => ({
      list: (res?.data || []).map(t.transformNotification),
      pager: res?.meta || {},
    }),
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: () => {
      if (typeof onError === 'function') onError();
    },
  });
};

export const getNotificationItem = (onSuccess, onError) => (
  notification_id
) => {
  req.get({
    key: c.SHOW_NOTIFICATION,
    url: `/notification/v1/notifications/${notification_id}`,
    transform: (res) => t.transformNotification(res?.data),
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: () => {
      if (typeof onError === 'function') onError();
    },
  });
};

export const getHasUnreadNotification = (params) => {
  req.get({
    key: c.SHOW_HAS_UNREAD,
    url: '/notification/v1/notifications/has-unread',
    params,
    transform: (res) => !!res?.has_unread,
  });
};

export const markAllNotificationAsRead = (onSuccess, onError) => () => {
  req.post({
    key: c.READ_ALL_NOTIFICATION,
    url: '/notification/v1/notifications/read-all',
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: () => {
      if (typeof onError === 'function') onError();
    },
  });
};
