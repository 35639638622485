/* eslint-disable */
import React, {
  useMemo,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { HiPhotograph, HiPencilAlt } from 'react-icons/hi';
import { toastError } from 'react-geek-toast';
import { useHistory } from 'react-router-dom';

import {
  useGeneralInfo,
  useFormAttachments,
} from 'core-modules/merchant-onboarding/hooks';
import countries from 'partial/form/countries.json';
import { showAlert } from 'partial/components/Modal';
import ThumbIcon from 'partial/components/ThumbIcon';
import { useCurrentAccount } from 'modules/auth/hooks';
import GenericDetails from 'partial/components/GenericDetails';
import { imagePreview } from 'core-modules/merchants/constants';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';
import MerchantAttachments from 'core-modules/merchants/components/MerchantAttachments';

const countryOptions = countries.map((item) => ({
  label: item.name,
  value: item.iso2,
}));

const KYC_DATA_FORMAT = [
  {
    label: '',
    key: (data) => (
      <ThumbIcon
        className="h-48 w-48"
        imgClassName="object-cover object-center rounded-full"
        iconClassName="w-6 h-6"
        url={data?.owner_photo_url}
        icon={HiPhotograph}
        alt=""
      />
    ),
  },
  {
    label: 'First Name',
    key: 'owner_first_name',
    hasBorder: true,
  },
  {
    label: 'Middle Name',
    key: 'owner_middle_name',
    hasBorder: true,
  },
  {
    label: 'Last Name',
    key: 'owner_last_name',
    hasBorder: true,
  },
  {
    label: 'Suffix',
    key: 'owner_suffix',
    hasBorder: true,
  },
  {
    label: 'Birth Date',
    key: 'owner_birth_date',
    span: 2,
    hasBorder: true,
  },
  {
    label: 'ID Type',
    key: 'owner_valid_id_type',
    span: 2,
    hasBorder: true,
  },
  imagePreview({
    label: 'ID Photo',
    key: 'owner_valid_id_url',
    span: 2,
  }),
  {
    label: '',
    key: (data) => (
      <ThumbIcon
        className="h-48 w-48"
        imgClassName="object-cover object-center rounded-full"
        iconClassName="w-6 h-6"
        url={data?.logo}
        icon={HiPhotograph}
        alt=""
      />
    ),
  },
  {
    label: 'Business Type',
    key: (data) => (
      <span className="capitalize">
        {data?.business_type?.replace(/_/g, ' ')?.toLowerCase() ?? '--'}
      </span>
    ),
  },
  {
    label: 'Business Name',
    key: 'name',
    hasBorder: true,
  },
  {
    label: 'Customer Service No.',
    key: (data) => (
      <span className="break-all">{data?.customer_service_number}</span>
    ),
    hasBorder: true,
  },
  {
    label: 'Business Industry',
    key: 'industry',
    hasBorder: true,
  },
  {
    label: 'Business Address',
    key: (data) =>
      data?.business_country === 'PH'
        ? `Philippines, ${data?.business_state}, ${data?.business_city}, ${data?.business_zip_code}, ${data?.business_address}`
        : `${
            countryOptions?.find(
              (country) => country?.value === data?.business_country
            )?.label
          }, ${data?.business_address}`,
    span: 3,
    hasBorder: true,
  },
  {
    label: 'Business Description',
    key: 'business_description',
    span: 3,
    hasBorder: true,
  },
  {
    label: 'Average Monthly Sales',
    key: 'business_monthly_sales',
    span: 3,
    hasBorder: true,
  },
  {
    label: 'No. of Customers',
    key: 'business_number_of_customers',
    span: 3,
    hasBorder: true,
  },
  {
    label: 'Bank Name',
    key: 'bank_name',
    hasBorder: true,
  },
  {
    label: 'Account Name',
    key: 'bank_account_name',
    hasBorder: true,
  },
  {
    label: 'Account Number',
    key: 'bank_account_number',
    hasBorder: true,
  },
];

export const ReviewForm = forwardRef(
  ({ noProfile, noBank, noEdit, onSubmit, onSuccess }, ref) => {
    const history = useHistory();
    const [, orgProfileData] = useCurrentAccount();
    const [, orgMerchant] = useOrgFirstMerchantData();
    const [, orgMerchantGeneralInfo] = useGeneralInfo();
    const [orgMerchantAttachment, setOrgMerchantAttachment] = useState({});
    const onboardingKycData = useMemo(
      () => ({
        ...orgProfileData,
        ...orgMerchantGeneralInfo,
        ...orgMerchantAttachment,
      }),
      [orgProfileData, orgMerchantGeneralInfo, orgMerchantAttachment]
    );

    const showConfirmationAlert = useCallback(() => {
      showAlert({
        title: 'You are about to submit your application.',
        subTitle:
          'Once you submit your application, you will no longer be able to edit your entered details. Do you want to proceed?',
        yesLabel: 'Yes',
        noLabel: 'Cancel',
        onNo: (closeAlert) => closeAlert(),
        onYes: (closeAlert) => {
          closeAlert();
          onSubmit({}, onSuccess, () => toastError('Something went wrong.'));
        },
        color: 'primary',
      });
    }, [onSubmit, onSuccess]);

    useFormAttachments(setOrgMerchantAttachment);

    useImperativeHandle(
      ref,
      () => ({
        submit: showConfirmationAlert,
      }),
      [showConfirmationAlert]
    );

    return (
      <div>
        {!noProfile && (
          <>
            <div className="relative mb-5 mt-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-between">
                <div className="title-h2 bg-white py-1 pr-2 font-medium text-gray-700">
                  Profile
                </div>
                {orgMerchant?.approval_status !== 'IN_REVIEW' ? (
                  <button
                    onClick={() => history.push('/onboarding')}
                    className="flex items-center space-x-2 bg-white py-1 pl-2 text-base text-primary-500"
                  >
                    <HiPencilAlt />
                    <span>Edit</span>
                  </button>
                ) : null}
              </div>
            </div>
            <div className="flex w-full flex-col items-center sm:flex-row sm:items-start">
              <GenericDetails
                data={onboardingKycData || {}}
                format={KYC_DATA_FORMAT.slice(0, 1)}
                numberOfCols={1}
              />
              <div className="mt-6 w-full sm:ml-14">
                <GenericDetails
                  data={onboardingKycData || {}}
                  format={KYC_DATA_FORMAT.slice(1, 8)}
                  numberOfCols={4}
                />
              </div>
            </div>
          </>
        )}
        <div className="relative mb-5 mt-8">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-between">
            <div className="title-h2 bg-white py-1 pr-2 font-medium text-gray-700">
              Merchant Information
            </div>
            {orgMerchant?.approval_status !== 'IN_REVIEW' && !noEdit ? (
              <button
                onClick={() => history.push('/onboarding/merchant')}
                className="flex items-center space-x-2 bg-white py-1 pl-2 text-base text-primary-500"
              >
                <HiPencilAlt />
                <span>Edit</span>
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-center sm:flex-row sm:items-start">
          <GenericDetails
            data={onboardingKycData || {}}
            format={KYC_DATA_FORMAT.slice(8, 9)}
            numberOfCols={1}
          />
          <div className="mt-6 w-full sm:ml-14">
            <GenericDetails
              data={onboardingKycData || {}}
              format={KYC_DATA_FORMAT.slice(10, 17)}
              numberOfCols={3}
            />
          </div>
        </div>
        <div className="relative mb-5 mt-8">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-between">
            <div className="title-h2 bg-white py-1 pr-2 font-medium text-gray-700">
              Due Diligence{' '}
              {onboardingKycData?.business_type &&
                `(${onboardingKycData?.business_type?.replace(/_/g, ' ')})`}
            </div>
            {orgMerchant?.approval_status !== 'IN_REVIEW' && !noEdit ? (
              <button
                onClick={() => history.push('/onboarding/documents')}
                className="flex items-center space-x-2 bg-white py-1 pl-2 text-base text-primary-500"
              >
                <HiPencilAlt />
                <span>Edit</span>
              </button>
            ) : null}
          </div>
        </div>
        <MerchantAttachments />
        {!noBank && (
          <>
            <div className="relative mb-5 mt-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-between">
                <div className="title-h2 bg-white py-1 pr-2 font-medium text-gray-700">
                  Bank Details
                </div>
                {orgMerchant?.approval_status !== 'IN_REVIEW' ? (
                  <button
                    onClick={() => history.push('/onboarding/bank')}
                    className="flex items-center space-x-2 bg-white py-1 pl-2 text-base text-primary-500"
                  >
                    <HiPencilAlt />
                    <span>Edit</span>
                  </button>
                ) : null}
              </div>
            </div>
            <div className="space-y-2">
              <GenericDetails
                data={onboardingKycData || {}}
                format={KYC_DATA_FORMAT.slice(17)}
                numberOfCols={3}
              />
            </div>
          </>
        )}
      </div>
    );
  }
);

ReviewForm.defaultProps = {
  noProfile: false,
  noBank: false,
  noEdit: false,
};

ReviewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  noProfile: PropTypes.bool,
  noBank: PropTypes.bool,
  noEdit: PropTypes.bool,
};

export default ReviewForm;
