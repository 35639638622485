import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IoIosSend } from 'react-icons/io';

import Button from 'partial/components/Button';
import { showDrawer } from 'partial/components/Modal';
import { useQuickMerchant } from 'core-modules/merchants/hooks';

import { useSubmitInvoice } from '../hooks';

export const useOpenPreviewAndSendModal = (
  options = {
    onSent: null,
    onClose: null,
  }
) => {
  const openPreviewAndSendModal = useCallback(
    (form, onInlineSent) => {
      showDrawer({
        title: 'Review Invoice',
        zIndex: 'z-[100]',
        content: (close) => (
          <InvoicePreviewModal
            form={form}
            onClose={(data) => {
              if (typeof options?.onClose === 'function')
                options?.onClose(data);
              close();
            }}
            onSuccess={(data) => {
              if (typeof options?.onSent === 'function') options?.onSent(data);
              if (typeof onInlineSent === 'function') onInlineSent(data);
              close();
            }}
          />
        ),
      });
    },
    [options]
  );

  return openPreviewAndSendModal;
};

const InvoicePreviewModal = ({ form, onClose, onSuccess }) => {
  const [merchant] = useQuickMerchant();
  // const totalAmount = React.useMemo(
  //   () =>
  //     formatCurrency(
  //       (form?.items || []).reduce((a, x) => a + parseNumber(x?.amount, 0), 0)
  //     ),
  //   [form?.items]
  // );

  const [isUpdating, submit] = useSubmitInvoice();
  const handleSubmit = () => {
    submit(form?.id, (data) => {
      // ADD DELAY
      setTimeout(() => {
        onClose(data);
        onSuccess(data);
      }, 500);
    });
  };

  const previewUrl = React.useMemo(() => {
    const color_bg = form?.color_bg || merchant?.theme?.color_bg;
    const color_accent = form?.color_accent || merchant?.theme?.color_accent;
    const search = new URLSearchParams({
      color_bg,
      color_accent,
      bg: form?.bg || '',

      source: merchant?.name || 'Merchant',
      source_photo: merchant?.logo || '',
      amount: form?.total_amount || '0.00',
    }).toString();
    return `/pgi-preview/${merchant?.theme?.theme}#${search}`;
  }, [merchant, form]);
  return (
    <div className="-mt-5 flex min-h-full flex-col">
      <div className="mb-4 flex flex-col space-x-0 space-y-3 sm:flex-row sm:items-center sm:space-x-3 sm:space-y-0">
        <div className="flex-1">
          Change how this page looks in{' '}
          <Link
            to={{
              pathname: `/merchants/profiles/${merchant?.id}/cms`,
              state: { selected: merchant },
            }}
            onClick={() => onClose()}
          >
            branding settings
          </Link>
          .
        </div>
        <div className="ml-auto flex justify-end space-x-1">
          <Button onClick={onClose}>Go Back</Button>
          <Button
            primary
            icon={IoIosSend}
            onClick={handleSubmit}
            disabled={isUpdating}
          >
            Send Invoice
          </Button>
        </div>
      </div>
      <div className="pointer-events-none relative flex min-h-[750px] flex-1 justify-center overflow-auto rounded-lg bg-gray-300 shadow-inner">
        <iframe
          className="relative z-10 m-auto flex min-h-[700px]"
          width="320"
          height="100%"
          seamless
          src={previewUrl}
          title="Preview"
        />
        <div className="absolute inset-0 z-0 flex items-center justify-center">
          <div className="text-sm font-light text-gray-600">
            Loading preview...
          </div>
        </div>
      </div>
      {/* <InvoicePreview
        data={form}
        merchant={merchant}
        totalAmount={totalAmount}
      /> */}
    </div>
  );
};

InvoicePreviewModal.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default InvoicePreviewModal;
