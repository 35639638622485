/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

import Button from 'partial/components/Button';

import { useInputId } from './hooks';

// const parseUrl = (url) => (url || '').replace(/(^\w+:|^)\/\//, '');

const splitUrl = (str) => {
  const splitStr = str.split('://');
  return [splitStr[0], splitStr[1]];
};

function FormUrl({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  ...props
}) {
  const [initialPrefix, initialHostname] = splitUrl(value);
  const [urlPrefix, setUrlPrefix] = React.useState(initialPrefix || 'https');
  const [hostname, setHostname] = React.useState(initialHostname || '');
  const [id] = useInputId(defaultId);
  const showIcon = Icon || error;
  const isError = !!error;

  const handleToggleUrlPrefix = React.useCallback(() => {
    if (urlPrefix === 'https') {
      setUrlPrefix('http');
      return;
    }
    setUrlPrefix('https');
  }, [urlPrefix]);

  React.useEffect(() => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: `${urlPrefix}://${hostname}`,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, `${urlPrefix}://${hostname}`);
    }
  }, [onChange, onSetFieldValue, name, urlPrefix, hostname]);

  return (
    <div
      className={cn(
        'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1 z-10',
        isError
          ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500'
          : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600'
      )}
    >
      <label
        htmlFor={id}
        className="w-full text-xs font-medium text-primary-500 flex justify-between"
      >
        <span>{label}</span>
        {!required && (
          <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
            optional
          </span>
        )}
      </label>
      <div className="absolute inset-y-0 left-0 top-5 pl-3 flex items-center">
        <Button
          onClick={handleToggleUrlPrefix}
          primary
          size="xxs"
          tabIndex={-1}
        >
          {urlPrefix}://
        </Button>
      </div>
      <input
        id={id}
        type="text"
        name="name"
        className={cn(
          'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base',
          'focus:outline-none',
          urlPrefix === 'https' ? 'pl-16' : 'pl-14',
          'pr-10' // with icon
        )}
        onChange={({ target }) => setHostname(target.value)}
        value={hostname || ''}
        {...props}
      />
      {showIcon && (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          title={error || ''}
        >
          {error ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </div>
      )}
    </div>
  );
}

FormUrl.defaultProps = {
  id: '',
  icon: false,
  type: 'text',
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
};

FormUrl.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default React.memo(FormUrl);
