import React from 'react';
import PropTypes from 'prop-types';
import { useIsVisible } from 'utils/useIsVisible';

function VisibleDiv({ loader, children }) {
  const [ref, observer] = useIsVisible();
  const isDataLoaded = React.useRef(false);
  const show = React.useMemo(() => {
    if (!isDataLoaded.current && observer?.isIntersecting) {
      isDataLoaded.current = true;
      return true;
    }
    return isDataLoaded.current;
  }, [observer?.isIntersecting]);
  return <div ref={ref}>{show ? children : loader}</div>;
}

VisibleDiv.propTypes = {
  loader: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default VisibleDiv;
