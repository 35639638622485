import React from 'react';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import FormPassword from 'partial/form/FormPassword';
import placeholder from 'assets/images/placeholder.svg';

import { useDisableMFA, useMfaSetupData } from 'modules/auth/hooks';
import { AlertCard, useCreateModal, ModalCard } from 'partial/components/Modal';

export const useOpenConnectGAuthModal = () => {
  const createModal = useCreateModal();
  const openShowQrModal = React.useCallback(
    (qrUrl) => {
      createModal({
        content: (onClose) => <QRModal onClose={onClose} qrUrl={qrUrl} />,
      });
    },
    [createModal]
  );
  return openShowQrModal;
};

export const useOpenDisableMFAModal = () => {
  const createModal = useCreateModal();
  const openDisableMFAModal = React.useCallback(() => {
    createModal({
      content: (close) => <DisableMFAModal onClose={close} />,
    });
  }, [createModal]);
  return openDisableMFAModal;
};

function QRModal({ onClose, qrUrl }) {
  return (
    <ModalCard title="Two-factor Authentication" size="sm" onClose={onClose}>
      <div className="p-6">
        <div className="flex flex-col items-center space-y-4 ">
          <div>
            <h1 className="block text-left font-semibold text-xl">
              Set up via third party authentication
            </h1>
            <p className="mt-2 text-left">
              Please use your authentication app &#40;Google Authenticator&#41;
              to scan this QR code.
            </p>
          </div>
          <div className="border border-gray-500">
            <img
              alt=""
              loading="lazy"
              className="w-60 h-60"
              src={qrUrl || placeholder}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button primary onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </ModalCard>
  );
}

QRModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  qrUrl: PropTypes.string,
};

QRModal.defaultProps = {
  qrUrl: '',
};

function DisableMFAModal({ onClose }) {
  const [, , fetchMfaSetupData] = useMfaSetupData();
  const [form, setForm] = React.useState();
  const [isLoading, disableMFA] = useDisableMFA({
    onSuccess: () => {
      onClose();
      setTimeout(() => {
        fetchMfaSetupData();
      }, 500);
    },
  });
  return (
    <AlertCard
      isLoading={isLoading}
      yesLabel="Confirm"
      noLabel="Cancel"
      onYes={() => disableMFA(form)}
      onNo={onClose}
    >
      <div className="flex flex-col space-y-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Disable MFA
        </h3>
        <p className="text-sm text-gray-500">
          Are you sure you want to proceed? Please type your password to
          continue.
        </p>
        <FormPassword
          label="Enter Password"
          name="password"
          onChange={setForm}
          value={form?.password || ''}
          required
        />
      </div>
    </AlertCard>
  );
}

DisableMFAModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};
