/* eslint-disable */
import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import AuthStore from 'auth-store';
import { ColoredLogo } from 'modules/common/components/AppIconLogo';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

import Splash from 'partial/containers/Splash';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';
import { useAccountMeta, useCurrentAccount } from 'modules/auth/hooks';

import BankPage from './containers/BankPage';
import ProfilePage from './containers/ProfilePage';
import InReviewCard from './components/InReviewCard';
import RejectedCard from './components/RejectedCard';
import MerchantPage from './containers/MerchantPage';
import OnboardingNav from './components/OnboardingNav';
import AttachmentsPage from './containers/AttachmentsPage';
import ReviewApplication from './containers/ReviewApplication';

function OnboardingContainer() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const shouldRedirectRef = useRef(location?.state?.from !== 'dashboard');

  const [, orgMerchant] = useOrgFirstMerchantData();

  const [, data] = useCurrentAccount();
  const { meta } = useAccountMeta();

  const isReadOnly = useMemo(() => {
    if (isEmpty(orgMerchant)) return false;
    return (
      orgMerchant?.approval_status !== 'PENDING' &&
      orgMerchant?.approval_status !== 'REJECTED'
    );
  }, [orgMerchant]);

  useEffect(() => {
    if (!data?.id) return;
    if (data?.is_org_kyc_completed || data?.env_mode === 'TEST') {
      history.push('/dashboard');
      return;
    }
    if (!meta?.is_org_profile_submitted) return;
    if (!shouldRedirectRef.current) return;
    if (!match.isExact) {
      shouldRedirectRef.current = false;
      return;
    }
    const uncompletedStepRoutes = [];
    if (!meta?.is_first_merchant_general_submitted)
      uncompletedStepRoutes.push(`${match.path}/merchant`);
    if (!meta?.is_first_merchant_attachment_submitted)
      uncompletedStepRoutes.push(`${match.path}/documents`);
    if (!meta?.is_org_bank_details_submitted)
      uncompletedStepRoutes.push(`${match.path}/bank`);
    history.push(uncompletedStepRoutes[0]);
  }, [data, meta, history, match.path, match.isExact]);

  useEffect(() => {
    if (orgMerchant?.approval_status === 'IN_REVIEW')
      history.push('/onboarding/review');
  }, [orgMerchant, history]);

  if (!data?.id) return <Splash />;
  return (
    <div
      className={cn(
        'flex w-full flex-1 flex-col bg-white',
        orgMerchant?.approval_status !== 'IN_REVIEW'
          ? 'sm:h-screen sm:flex-row'
          : ''
      )}
    >
      <div
        className={cn(
          'w-full justify-center bg-white sm:border-r',
          orgMerchant?.approval_status !== 'IN_REVIEW' ? 'sm:w-[30rem]' : ''
        )}
      >
        <div className="space-y-10 p-5 sm:p-10">
          <div
            className={cn(
              'flex flex-shrink-0 items-center',
              orgMerchant?.approval_status === 'IN_REVIEW'
                ? 'justify-center'
                : ''
            )}
          >
            <ColoredLogo />
          </div>
          {orgMerchant?.approval_status !== 'IN_REVIEW' ? (
            <>
              <button
                type="button"
                onClick={history.goBack}
                className={cn(
                  'my-10 items-center text-sm font-normal text-black hover:text-primary-500',
                  location.pathname === '/onboarding'
                    ? 'hidden'
                    : 'flex sm:hidden'
                )}
              >
                <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
                Back
              </button>
              <OnboardingNav />
            </>
          ) : null}
        </div>
      </div>
      <div className="flex-grow overflow-auto sm:h-full">
        <div className="mx-auto w-full p-5 sm:p-10">
          <div
            className={cn(
              orgMerchant?.approval_status === 'IN_REVIEW'
                ? 'flex flex-col items-center'
                : ''
            )}
          >
            <div className="mb-6 w-full max-w-4xl space-y-5">
              {orgMerchant?.approval_status === 'REJECTED' && (
                <RejectedCard merchantData={orgMerchant} />
              )}
              {orgMerchant?.approval_status === 'IN_REVIEW' && (
                <>
                  <InReviewCard />
                </>
              )}
            </div>

            <Switch>
              <Route exact path="/onboarding">
                <div className="w-full max-w-4xl">
                  <div className="mb-4 space-y-1 pb-4">
                    <h1 className="title-h2">Verify your personal details</h1>
                    <p className="text-base text-gray-500">
                      MultiPay collects this data in order to validate your
                      identity and secure your account.
                    </p>
                  </div>
                  <ProfilePage
                    onNext={() => history.push('/onboarding/merchant')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/merchant">
                <div className="w-full max-w-4xl">
                  <div className="mb-4 space-y-2 pb-4">
                    <h1 className="title-h2">Setup your first merchant</h1>
                    <p className="text-base text-gray-500">
                      Fill up the basic information about your merchant. You can
                      add more merchants later.
                    </p>
                  </div>
                  <MerchantPage
                    onBack={() => history.push('/onboarding')}
                    onNext={() => history.push('/onboarding/documents')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/documents">
                <div className="w-full max-w-4xl">
                  <div className="mb-4 space-y-2 pb-4">
                    <h1 className="title-h2">Due Diligence Requirements</h1>
                    <p className="text-base text-gray-500">
                      We need to verify your business to protect consumers from
                      fraudulent businesses and for your protection as a
                      business.
                    </p>
                  </div>
                  <AttachmentsPage
                    onBack={() => history.push('/onboarding/merchant')}
                    onNext={() => history.push('/onboarding/bank')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/bank">
                <div className="w-full max-w-4xl">
                  <div className="mb-4 space-y-2 pb-4">
                    <h1 className="title-h2">
                      Add your bank to receive payouts
                    </h1>
                    <p className="text-base text-gray-500">
                      A payout is when MultiPay sends money to your bank
                      account. Connect your accounts to accept payments more
                      quickly and to help us better understand your business.
                    </p>
                  </div>
                  <BankPage
                    onBack={() => history.push('/onboarding/documents')}
                    onNext={() => {
                      history.push('/onboarding/review');
                    }}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/review">
                <div className="w-full max-w-4xl">
                  <div className="space-y-2">
                    <h1 className="title-h2">Review your application</h1>
                    {/* <p className="text-gray-500 text-base">
                      A payout is when MultiPay sends money to your bank
                      account. Connect your accounts to accept payments more
                      quickly and to help us better understand your business.
                    </p> */}
                  </div>
                  <ReviewApplication
                    onBack={() => {
                      if (orgMerchant?.approval_status === 'IN_REVIEW') {
                        history.push('/dashboard');
                        return;
                      }
                      history.push('/onboarding/bank');
                    }}
                    onNext={() => {
                      history.push('/dashboard');
                    }}
                    onSkipped={() => {
                      AuthStore.set('_skip_onboarding', '1');
                      history.push('/dashboard');
                    }}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

OnboardingContainer.propTypes = {};

export default OnboardingContainer;
