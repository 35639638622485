import { useCallback } from 'react';
import QRCode from 'qrcode';

import { showLightbox } from 'partial/components/Modal';

export const useShowInvoiceLinkQr = () => {
  const showInvoiceLinkQr = useCallback(async (link) => {
    const content = await QRCode.toDataURL(link, {
      scale: 10,
    });
    showLightbox({
      content,
      title: '',
      description: link || '',
    });
  }, []);

  return showInvoiceLinkQr;
};
