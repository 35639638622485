/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import Card from 'partial/components/Card';

import { useGetGeneralInfo } from '../hooks';
import MerchantGeneralInfo from './MerchantGeneralInfo';
import MerchantBusinessInfo from './MerchantBusinessInfo';
import MerchantAttachments from './MerchantAttachments';

function MerchantDetail() {
  const [isGeneralInfoLoading, generalInfo] = useGetGeneralInfo();

  return (
    <div className="space-y-4">
      <Card label="Merchant Information">
        <div className="divide-y-4 divide-gray-100 p-6">
          <MerchantGeneralInfo
            data={generalInfo}
            isLoading={isGeneralInfoLoading}
          />
        </div>
      </Card>
      {/* {generalInfo?.business_type ? (
        <> */}
      <Card label="Business Information">
        <div className="divide-y-4 divide-gray-100 p-6">
          <MerchantBusinessInfo type={generalInfo?.business_type} />
        </div>
      </Card>
      <Card label="Due Diligence">
        <MerchantAttachments />
      </Card>
      {/* </>
      ) : null} */}
    </div>
  );
}

MerchantDetail.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MerchantDetail;
