export const transformNotification = (raw) => ({
  id: raw?.uuid,
  type: raw?.type,
  origin: raw?.origin,
  is_read: !!raw?.is_read,
  created_at: raw?.created_at,
  merchant_id: raw?.merchant_uuid,
  created_at_raw: raw?.created_at_raw,
  organization_id: raw?.organization_uuid,
  meta: {
    amount: raw?.meta?.amount,
    status: raw?.meta?.status,
    invoice_id: raw?.meta?.invoice_uuid,
    merchant_name: raw?.meta?.merchant_name,
    recipient_name: raw?.meta?.recipient_name,
  },
});
