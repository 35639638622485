import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import FormDate from 'partial/form/FormDate';
import Button from 'partial/components/Button';
import FormSelect from 'partial/form/FormSelect';
import { showDrawer } from 'partial/components/Modal';
import { useActiveAccount } from 'modules/auth/hooks';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
import FormMerchantOptions from 'core-modules/merchants/components/FormMerchantOptions';

import {
  POSTING_OPTIONS,
  STATUS_OPTIONS,
} from 'core-modules/dashboard/constants';
import { useGenerateReport } from '../hooks';

export const showGenerateReportModal = (
  options = { initData: {}, hideMerchantOptions: false }
) => {
  showDrawer({
    size: 'xs',
    title: 'Generate Report',
    content: (onClose) => (
      <GenerateReportModal
        data={options?.initData ?? {}}
        hideMerchantOptions={options?.hideMerchantOptions ?? false}
        onClose={onClose}
      />
    ),
  });
};

const validationSchema = yup.object().shape({
  merchant: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('Required'),
  from: yup
    .string()
    .required('Required')
    .test({
      name: 'date must be in correct format',
      test: (value) => {
        return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value;
      },
      message: 'Invalid date format',
    })
    .test({
      name: 'date must be in the past',
      test: (value) => {
        return dayjs(value).isBefore(Date.now());
      },
      message: "Value can't be in future",
    }),
  to: yup
    .string()
    .required('Required')
    .test({
      name: 'date must be in correct format',
      test: (value) => {
        return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value;
      },
      message: 'Invalid date format',
    })
    .test({
      name: 'date must be in the past',
      test: (value) => {
        return dayjs(value).isBefore(Date.now());
      },
      message: "Value can't be in future",
    })
    .test({
      name: 'date must be be greater than "from date"',
      // eslint-disable-next-line
      test: function (value) {
        const date_from = this.parent?.from;
        return !dayjs(value).isBefore(dayjs(date_from));
      },
      message: "Can't be less than 'Date From'",
    }),
  payment_status: yup.string().required('Required'),
  posting_status: yup.string().required('Required'),
});

const initialValues = {
  merchant: null,
  from: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
  payment_status: 'All',
  posting_status: 'All',
};

function GenerateReportModal({ data, onClose, hideMerchantOptions }) {
  const [activeAccount] = useActiveAccount();

  const isTestMode = useMemo(
    () => activeAccount?.env_mode === 'TEST',
    [activeAccount]
  );

  const [isLoading, generate] = useGenerateReport();
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: { ...initialValues, ...data },
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      const payload = {
        ...form,
        payment_status:
          form?.payment_status === 'All' ? '' : form?.payment_status,
        posting_status:
          form?.posting_status === 'All' ? '' : form?.posting_status,
      };
      generate(payload, onClose, (err) => {
        setError422(err, setFieldError);
      });
    },
    validateOnChange,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      error={errors}
      noValidate
      className="space-y-3"
    >
      {!hideMerchantOptions && (
        <div>
          <FormMerchantOptions
            name="merchant"
            onSetFieldValue={setFieldValue}
            value={values?.merchant}
            label="Merchant"
            placeholder="Select Merchant"
            required
            filterParams={{
              approval_status: isTestMode ? '' : 'APPROVED',
            }}
          />
          <ErrorLabel name="merchant" />
        </div>
      )}
      <div className="relative z-20">
        <FormDate
          name="from"
          onSetFieldValue={setFieldValue}
          value={values?.from}
          label="Date From"
          maxDate={Date.now()}
          required
        />
        <ErrorLabel name="from" />
      </div>
      <div className="relative z-10">
        <FormDate
          name="to"
          onSetFieldValue={setFieldValue}
          value={values?.to}
          label="Date To"
          maxDate={Date.now()}
          required
        />
        <ErrorLabel name="to" />
      </div>
      <div>
        <FormSelect
          name="payment_status"
          onSetFieldValue={setFieldValue}
          value={values?.payment_status}
          label="Payment Status"
          noPlaceholder
          options={[
            {
              label: 'All Payment Status',
              value: 'All',
            },
            ...STATUS_OPTIONS.slice(1),
          ]}
          required
        />
        <ErrorLabel name="payment_status" />
      </div>
      <div>
        <FormSelect
          name="posting_status"
          onSetFieldValue={setFieldValue}
          value={values?.posting_status}
          label="Posting Status"
          noPlaceholder
          options={[
            {
              label: 'All Posting Status',
              value: 'All',
            },
            ...POSTING_OPTIONS.slice(1),
          ]}
          required
        />
        <ErrorLabel name="posting_status" />
      </div>
      <div className="flex space-x-2">
        <Button
          primary
          type="submit"
          disabled={isLoading}
          onClick={() => setValidateOnChange(true)}
        >
          Generate Report
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </div>
    </Form>
  );
}

GenerateReportModal.defaultProps = {
  data: {},
  hideMerchantOptions: false,
};

GenerateReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
  hideMerchantOptions: PropTypes.bool,
};

export default GenerateReportModal;
