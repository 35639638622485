import { toastError, toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import promiseDownload from 'utils/promiseDownload';
import { removeEmpty } from 'helper';
import * as c from './constants';
import * as t from './transformers';

export const listSettlement = (params) => {
  req.get({
    key: c.SETTLEMENT_LIST,
    url: '/merchant_settlement/v1/my/settlements',
    params,
    transform: (res) => (res.data || []).map(t.transformSettlement),
    onSuccess: (res) => {
      req.set(c.SETTLEMENT_PAGER, res?.response?.meta || {});
      req.set(c.SETTLEMENT_FILTER, params);
    },
  });
};

export const appendListSettlement = (params) => {
  req.get({
    key: c.SETTLEMENT_LIST,
    url: '/merchant_settlement/v1/my/settlements',
    params,
    transform: (res, state) =>
      (state[c.SETTLEMENT_LIST] || []).concat(
        (res.data || []).map(t.transformSettlement)
      ),
    onSuccess: (res) => {
      req.set(c.SETTLEMENT_PAGER, res?.response?.meta || {});
      req.set(c.SETTLEMENT_FILTER, params);
    },
  });
};

export const downloadSettlement = async (id, filename, onSuccess, onError) => {
  try {
    await promiseDownload(
      `/merchant_settlement/v1/my/settlements/${id}/download`,
      filename
    );
    toastSuccess('Downloading...');
    onSuccess();
  } catch (err) {
    onError();
    toastError(err?.error_description || err?.message || 'Unable to download!');
  }
};

export const getCountCard = (params) => {
  req.get({
    key: `${c.SETTLEMENT_COUNT_CARD}/total_transaction`,
    params,
    url: '/merchant_aggs/v1/aggs/total_settlements',
    transform: ({ data }) => ({
      value: data?.total_transaction,
    }),
    onSuccess: (res) => {
      req.set(`${c.SETTLEMENT_COUNT_CARD}/total_amount`, {
        value: res?.response?.data?.total_amount,
      });
    },
  });
};

export const listSettlementLogs = (id, params) => {
  req.get({
    key: c.SETTLEMENT_LOGS_LIST,
    url: `/merchant_settlement/v1/my/settlements/${id}/logs`,
    params,
    transform: (res) => (res.data || []).map(t.transformSettlementLogs),
    onSuccess: (res) => {
      req.set(c.SETTLEMENT_LOGS_PAGER, res?.response?.meta || {});
      req.set(c.SETTLEMENT_LOGS_FILTER, params);
    },
  });
};

export const listLedger = (params) => {
  req.get({
    key: c.LEDGER_LIST,
    url: '/merchant_ledger/v1/my/ledgers',
    params,
    transform: (res) => (res.data || []).map(t.transformLedger),
    onSuccess: (res) => {
      req.set(c.LEDGER_PAGER, res?.response?.meta || {});
      req.set(c.LEDGER_FILTER, params);
    },
  });
};

export const appendListLedger = (params) => {
  req.get({
    key: c.LEDGER_LIST,
    url: '/merchant_ledger/v1/my/ledgers',
    params,
    transform: (res, state) =>
      (state[c.LEDGER_LIST] || []).concat(
        (res.data || []).map(t.transformLedger)
      ),
    onSuccess: (res) => {
      req.set(c.LEDGER_PAGER, res?.response?.meta || {});
      req.set(c.LEDGER_FILTER, params);
    },
  });
};

export const listRefund = (params) => {
  req.get({
    key: c.REFUND_LIST,
    url: '/merchant_refund/v1/transaction/refunds',
    params: removeEmpty(params),
    transform: (res) => (res.data || []).map(t.transformRefund),
    onSuccess: (res) => {
      req.set(c.REFUND_PAGER, res?.response?.meta || {});
      req.set(c.REFUND_FILTER, params);
    },
  });
};

export const appendListRefund = (params) => {
  req.get({
    key: c.REFUND_LIST,
    url: '/merchant_refund/v1/transaction/refunds',
    params: removeEmpty(params),
    transform: (res, state) =>
      (state[c.REFUND_LIST] || []).concat(
        (res.data || []).map(t.transformRefund)
      ),
    onSuccess: (res) => {
      req.set(c.REFUND_PAGER, res?.response?.meta || {});
      req.set(c.REFUND_FILTER, params);
    },
  });
};

export const createRefundRequest =
  (onSuccess) => (payload, onInlineSuccess) => {
    req.post({
      key: c.REFUND_REQUEST,
      url: '/merchant_refund/v1/transaction/refunds/process',
      payload,
      onSuccess: () => {
        if (typeof onSuccess === 'function') onSuccess();
        if (typeof onInlineSuccess === 'function') onInlineSuccess();
      },
    });
  };
