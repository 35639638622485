import { each, omit } from 'lodash';
import { formatDate, parseNumber, removeEmpty, formatCurrency } from 'helper';
import { MAP_ROWS } from './constants';

// const MAP_HISTOGRAM_FORMAT = {
//   hour: 'HH:mm A',
//   day: 'MM/DD/YYYY',
//   month: 'MMM YYYY',
//   year: 'YYYY',
// };

// const histogramLabel = (key, value) =>
//   dayjs(new Date(value)).format(MAP_HISTOGRAM_FORMAT[key]);

export const transformTransactionOptions = (raw) => ({
  value: raw?.uuid,
  label: `${raw?.refno} (${raw?.status})`,
});

export const transformChartAggregation = (raw) => ({
  total_amount_human: raw?.total_amount,
  total_amount: parseNumber(raw?.total_amount),
  total_channel_fee_human: raw?.total_channel_fee,
  total_channel_fee: parseNumber(raw?.total_channel_fee),
  total_partner_fee_human: raw?.total_partner_fee,
  total_partner_fee: parseNumber(raw?.total_partner_fee),
  total_system_fee_human: raw?.total_system_fee,
  total_system_fee: parseNumber(raw?.total_system_fee),
  total_transaction_human: raw?.total_transaction,
  total_transaction: parseNumber(raw?.total_transaction),
});

export const transformInvoiceChartAggregation = (raw) => ({
  total_invoice_human: raw?.total_amount,
  total_invoice: parseNumber(raw?.total_amount),
});

export const transformInvoiceAggregations = (raw) => ({
  total_invoice: raw?.total_invoices,
});

export const transformDashboardHistogram = (/* params */) => (raw) => {
  const list = (raw?.data || []).map((x) => ({
    label: x?.date_human,
    transaction: parseNumber(x?.total_transaction),
    transaction_human: x?.total_transaction || '-',
    amount: parseNumber(x?.total_amount),
    amount_human: x?.total_amount || '-',
  }));
  return { list };
};

export const transformDashboardScheme = (/* params */) => (raw) => {
  const list = (raw?.data || []).map((x, i) => ({
    id: `${i}_${x?.date}`,
    label: x?.date_human,
    transaction: parseNumber(x?.total_transaction),
    transaction_human: x?.total_transaction || '-',
    amount: parseNumber(x?.total_amount),
    amount_human: x?.total_amount || '-',
  }));
  return { list };
};

export const transformDashboardTop = (/* params */) => (raw) => {
  const list = (raw?.data || []).map((x, i) => ({
    id: `${i}_${x?.name}`,
    label: x?.name,
    transaction: parseNumber(x?.total_transaction),
    transaction_human: x?.total_transaction || '-',
    amount: parseNumber(x?.total_amount),
    amount_human: x?.total_amount || '-',
  }));
  return { list };
};

const formatRowKey = (key, value) =>
  ({
    hour: formatDate(value?.date, 'hh:mm A'),
    day: formatDate(value?.date, 'D'),
    month: formatDate(value?.date, 'MMM'),
  }[key]);

export const transformCompareTable = (params) => (raw) => {
  const column = {};
  const key = params?.type;
  each(raw?.data, (row, id) => {
    const formatted = {
      id,
      label: row?.date_human,
      transaction: parseNumber(row?.total_transaction),
      transaction_human: row?.total_transaction,
      amount: parseNumber(row?.total_amount),
      amount_human: row?.total_amount,
    };

    const rowKey = formatRowKey(key, row);
    // eslint-disable-next-line
    if (!rowKey) console.warn('Unregistered row key!', key);
    column[rowKey] = formatted;
  });
  return column;
};

const ID_BY_MODE = {
  day: 'hh:mm A',
  month: 'D',
  year: 'MMM',
};

export const transformCompareChart = (mode /* params */) => (raw) => {
  const list = [];
  const rows = MAP_ROWS[mode] || [];
  const data = {};
  each(raw?.data, (row, id) => {
    const formatted = {
      id,
      label: row?.date_human,
      transaction: parseNumber(row?.total_transaction),
      transaction_human: row?.total_transaction,
      amount: parseNumber(row?.total_amount),
      amount_human: row?.total_amount,
    };
    data[formatDate(row?.date, ID_BY_MODE[mode])] = formatted;
  });

  each(rows, (label, id) => {
    list.push({
      amount: 0,
      amount_human: '-',
      transaction: 0,
      transaction_human: '-',
      ...(data[label] || {}),
      label,
      id,
    });
  });
  return list;
};

export const transformTransactionFilter = (params) =>
  removeEmpty({
    ...omit(params, ['organization', 'merchant', 'channel']),
    organization_uuid:
      params?.organization_uuid || params?.organization?.value || '',
    merchant_uuid: params?.merchant_uuid || params?.merchant?.value || '',
    payment_channel: params?.payment_channel || params?.channel?.value || '',
  });

export const transformTransaction = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  refno: raw?.refno,
  txnid: raw?.txnid,
  merchant_name: `${raw?.merchant?.name} (${raw?.organization?.name})`,
  channel_name: raw?.payment_channel,
  amount: formatCurrency(raw?.amount),
  payment_status: raw?.payment_status,
  posting_status: raw?.posting_status,
});
