import dayjs from 'dayjs';

export const DASHBOARD_HISTOGRAM = 'DASHBOARD/histogram';
export const DASHBOARD_SCHEME = 'DASHBOARD/scheme';
export const DASHBOARD_TOP = 'DASHBOARD/top';
export const COUNT_CARD = 'DASHBOARD/count_card';
export const DASHBOARD_TOTAL_AGGREGATIONS = 'DASHBOARD/total_aggregations';
export const DASHBOARD_CHART_AGGREGATIONS = 'DASHBOARD/chart_aggregations';

export const TRANSACTION_LIST = 'DASHBOARD/transaction_list';
export const TRANSACTION_PAGER = 'DASHBOARD/transaction_pager';
export const TRANSACTION_FILTER = 'DASHBOARD/transaction_filter';

export const TRANSACTION_COMPARE = 'DASHBOARD/compare';

export const MAP_ROWS = {
  day: [
    '12:00 AM',
    '01:00 AM',
    '02:00 AM',
    '03:00 AM',
    '04:00 AM',
    '05:00 AM',
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
  ],
  month: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
  year: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

// COMPARE MODULE
const MAP_KEY = {
  day: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  year: 'YYYY',
};

export const buildId = (date, mode) => {
  if (!date) return null;
  return dayjs(date).format(MAP_KEY[mode]);
};

export const STATUS_OPTIONS = [
  { label: 'All Payment Status', value: '' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Initial', value: 'INITIAL' },
  { label: 'Refunded', value: 'REFUNDED' },
  { label: 'Voided', value: 'VOIDED' },
  { label: 'Expired', value: 'EXPIRED' },
];

export const POSTING_OPTIONS = [
  { label: 'All Posting Status', value: '' },
  { label: 'Posted', value: 'POSTED' },
  { label: 'Unposted', value: 'UNPOSTED' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
];
