/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import Splash from 'partial/containers/Splash';
import MerchantForm from 'core-modules/onboarding/forms/MerchantForm';

import { useGeneralInfo, useSaveGeneralInfo } from '../hooks';
import { promiseGetGeneralInfo } from '../actions';

function MerchantGeneralPage({ id, onNext, readOnly }) {
  const [isLoading, submit] = useSaveGeneralInfo();
  const formRef = useRef();

  useEffect(() => {
    promiseGetGeneralInfo(id);
  }, [id]);
  const [, data] = useGeneralInfo();

  if (isEmpty(data)) return <Splash />;

  return (
    <div>
      <MerchantForm
        ref={formRef}
        readOnly={readOnly}
        onSubmit={submit}
        onSuccess={() => {
          onNext();
        }}
      />
      <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row justify-end mt-4">
        {!readOnly ? (
          <>
            <div className="mt-3 sm:mt-0 order-last sm:order-first">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={onNext}
              >
                Skip
              </Button>
            </div>
            <Button
              primary
              size="lg"
              disabled={isLoading}
              className="whitespace-nowrap order-2 sm:order-1"
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
            >
              Save and Continue
            </Button>
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={onNext}
            className="whitespace-nowrap order-2 sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}

MerchantGeneralPage.defaultProps = {
  readOnly: false,
};

MerchantGeneralPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default MerchantGeneralPage;
