/* eslint-disable */
import { useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import {
  BsFillCheckCircleFill,
  BsCircleFill,
  BsFillRecordCircleFill,
} from 'react-icons/bs';

import { useAccountMeta } from 'modules/auth/hooks';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';

import TestModeCard from './TestModeCard';

function OnboardingNav() {
  const location = useLocation();
  const { meta } = useAccountMeta();
  const [, orgMerchant] = useOrgFirstMerchantData();

  const NAV_LIST = useMemo(
    () => [
      {
        label: 'Profile',
        to: '/onboarding',
        is_submitted: meta?.is_org_profile_submitted,
      },
      {
        label: 'Merchant Setup',
        to: '/onboarding/merchant',
        is_submitted: meta?.is_first_merchant_general_submitted,
      },
      {
        label: 'Due Diligence',
        to: '/onboarding/documents',
        is_submitted: meta?.is_first_merchant_attachment_submitted,
      },
      {
        label: 'Bank Details',
        to: '/onboarding/bank',
        is_submitted: meta?.is_org_bank_details_submitted,
      },
      {
        label: 'Review and Submit',
        to: '/onboarding/review',
        orgMerchant: orgMerchant,
        is_submitted:
          !isEmpty(orgMerchant) &&
          orgMerchant?.approval_status !== 'PENDING' &&
          orgMerchant?.approval_status !== 'REJECTED',
      },
    ],
    [meta, orgMerchant]
  );

  return (
    <>
      <div
        className={cn(
          location.pathname !== '/onboarding' ? 'hidden sm:block' : 'block'
        )}
      >
        <h1 className="text-xl font-medium text-gray-700">
          You&apos;re almost there!
        </h1>
        <p className="font-light text-gray-700">
          Just a few more steps and we are good to go!
        </p>
        <p className="mt-4 text-sm font-light text-gray-700">
          To start accepting and sending payment in{' '}
          <span className="font-bold">Live mode</span>. We need you to complete
          the account activation process
        </p>
      </div>
      <div className="stepper">
        {NAV_LIST.map((item, i) => (
          <div key={item.label} className="stepper-layout">
            {i !== NAV_LIST.length - 1 ? (
              <span className="stepper-line" aria-hidden="true" />
            ) : null}
            <div className="stepper-content">
              {item.is_submitted ? (
                <BsFillCheckCircleFill
                  aria-hidden="true"
                  className="h-6 w-6 text-primary-500"
                />
              ) : (
                <>
                  {item.to === location.pathname ? (
                    <BsFillRecordCircleFill
                      aria-hidden="true"
                      className="h-6 w-6 text-primary-500"
                    />
                  ) : (
                    <BsCircleFill
                      aria-hidden="true"
                      className="h-6 w-6 text-gray-100"
                    />
                  )}
                </>
              )}
              <NavLink
                to={item.to}
                exact={item.to === '/onboarding'}
                className={cn(
                  'flex min-w-0 flex-1 rounded-lg py-2 text-center text-xs font-normal sm:text-base',
                  item.is_submitted || item.to === location.pathname
                    ? 'text-gray-700'
                    : 'text-gray-300'
                )}
              >
                {item.label}
              </NavLink>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden sm:block">
        <TestModeCard />
      </div>
    </>
  );
}

export default OnboardingNav;
