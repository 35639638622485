import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FaAngleDown } from 'react-icons/fa';
import { HiOutlineCalendar } from 'react-icons/hi';
import { Popover, Transition } from '@headlessui/react';

import { useOnClickOutside } from 'helper';
import FormDate from 'partial/form/FormDate';
import Button from 'partial/components/Button';
import FormSelect from 'partial/form/FormSelect';
import FormInlineSelect from 'partial/form/FormInlineSelect';

function DateRangePicker({ value, onSubmitValue, inline }) {
  return inline ? (
    <InlineDateRange value={value} onSubmitValue={onSubmitValue} />
  ) : (
    <DateRange value={value} onSubmitValue={onSubmitValue} />
  );
}

DateRangePicker.propTypes = {
  inline: PropTypes.bool,
  value: PropTypes.instanceOf(Object),
  onSubmitValue: PropTypes.instanceOf(Function),
};

DateRangePicker.defaultProps = {
  value: {},
  inline: false,
  onSubmitValue: () => {},
};

export default DateRangePicker;

const TIME_PRESETS = [
  {
    label: 'Custom',
    value: 'CUSTOM',
  },
  {
    label: 'Last 7 Days',
    value: 'LAST_7_DAYS',
  },
  {
    label: 'Last Month',
    value: 'LAST_MONTH',
  },
  {
    label: 'Last 3 Months',
    value: 'LAST_3_MONTHS',
  },
  {
    label: 'Last 6 Months',
    value: 'LAST_6_MONTHS',
  },
  {
    label: 'Last Year',
    value: 'LAST_YEAR',
  },
];

export const TIME_PRESET_VALUE = {
  CUSTOM: {
    from: '',
    to: '',
  },
  LAST_7_DAYS: {
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
  LAST_MONTH: {
    from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
  LAST_3_MONTHS: {
    from: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
  LAST_6_MONTHS: {
    from: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
  LAST_YEAR: {
    from: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  },
};

const DateRange = ({ value, onSubmitValue }) => {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-sm text-gray-800">Select a date range</span>
      <FormSelect
        placeholder=""
        name="date_range_preset"
        label="Select a date range"
        onChange={(cb) => {
          const { date_range_preset = '' } = cb();
          if (date_range_preset === 'CUSTOM') {
            onSubmitValue(cb);
            return;
          }
          onSubmitValue((prev) => ({
            ...prev,
            date_range_preset,
            ...(TIME_PRESET_VALUE[date_range_preset] ?? {}),
          }));
        }}
        options={TIME_PRESETS}
        value={value.date_range_preset ?? ''}
      />
      <div className="z-10">
        <FormDate
          required
          name="from"
          label="From"
          onChange={onSubmitValue}
          value={value?.from}
          maxDate={new Date(dayjs().endOf('month').format('YYYY-MM-DD'))}
          disabled={
            !!value?.date_range_preset && value?.date_range_preset !== 'CUSTOM'
          }
        />
      </div>
      <div>
        <FormDate
          required
          name="to"
          label="To"
          onChange={onSubmitValue}
          value={value?.to}
          minDate={new Date(value?.from)}
          maxDate={new Date(dayjs().endOf('month').format('YYYY-MM-DD'))}
          disabled={
            !!value?.date_range_preset && value?.date_range_preset !== 'CUSTOM'
          }
        />
      </div>
    </div>
  );
};

DateRange.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  onSubmitValue: PropTypes.instanceOf(Function).isRequired,
};

const InlineDateRange = ({ value: outerValue, onSubmitValue }) => {
  const containerRef = useRef();
  const [showPicker, setShowPicker] = useState(false);
  const [innerValue, setInnerValue] = useState(outerValue);

  const innerValueRef = useRef(innerValue);
  useEffect(() => {
    innerValueRef.current = innerValue;
  }, [innerValue]);

  const handleChange = useCallback(
    (date) => {
      setInnerValue({
        from: dayjs(date[0]).format('YYYY-MM-DD'),
        to: dayjs(date[1]).format('YYYY-MM-DD'),
      });
    },
    [setInnerValue]
  );

  const applyChange = useCallback(() => {
    onSubmitValue((state) => ({
      ...state,
      ...innerValue,
    }));
    setShowPicker(false);
  }, [onSubmitValue, innerValue]);

  const clearValue = useCallback(() => {
    onSubmitValue((state) => ({
      ...state,
      date_range_preset: '',
      from: '',
      to: '',
    }));
    setShowPicker(false);
  }, [onSubmitValue, setShowPicker]);

  const renderLabel = useMemo(() => {
    const startDate = dayjs(innerValue.from).format('MMM DD, YYYY');
    const endDate = dayjs(innerValue.to).format('MMM DD, YYYY');
    if (!innerValue.from || !innerValue.to) {
      return <div className="text-sm text-gray-500">Select date range</div>;
    }
    if (startDate !== dayjs().format('MMM DD, YYYY') && endDate === startDate) {
      return <div className="text-sm">{startDate}</div>;
    }
    return (
      <div className="text-sm">
        {startDate === dayjs().format('MMM DD, YYYY')
          ? 'Today'
          : `${startDate} - ${endDate}`}
      </div>
    );
  }, [innerValue]);

  useOnClickOutside(containerRef, () => {
    setShowPicker(false);
    if (!isEqual(innerValue, outerValue)) {
      setInnerValue(outerValue);
    }
  });

  useEffect(() => {
    if (!isEqual(innerValueRef.current, outerValue)) {
      setInnerValue(outerValue);
    }
  }, [outerValue, setInnerValue]);

  return (
    <Popover className="relative">
      <div ref={containerRef}>
        <div className="flex -space-x-1">
          <div className="inline-flex w-full whitespace-nowrap rounded-l-md border border-gray-300 px-3 py-4 text-sm">
            <HiOutlineCalendar className="mr-2 h-5 w-5 text-gray-500" />{' '}
            {renderLabel}
          </div>
          <button
            type="button"
            onClick={() => setShowPicker(true)}
            className="rounded-r-md border border-gray-300 bg-gray-50 px-3 py-4 text-gray-500"
            aria-label="toggle expand"
          >
            <FaAngleDown />
          </button>
        </div>
        <Transition
          show={showPicker}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={React.Fragment}
        >
          <Popover.Panel className="absolute right-0 z-10 w-screen max-w-[55rem]">
            <div className="mt-2 flex overflow-hidden rounded-lg border bg-white p-4 shadow-lg">
              <DatePicker
                inline
                selectsRange
                monthsShown={2}
                onChange={handleChange}
                selected={
                  dayjs(innerValue?.from).isValid()
                    ? dayjs(innerValue.from).toDate()
                    : null
                }
                startDate={
                  dayjs(innerValue?.from).isValid()
                    ? dayjs(innerValue.from).toDate()
                    : null
                }
                endDate={
                  dayjs(innerValue?.to).isValid()
                    ? dayjs(innerValue.to).toDate()
                    : null
                }
                // endDate={innerValue?.to}
                maxDate={dayjs().endOf('month').toDate()}
                showYearDropdown={false}
                showMonthDropdown={false}
                disabledKeyboardNavigation
                calendarClassName="w-full"
              />
              <div className="flex w-full flex-col justify-between">
                <FormInlineSelect
                  name="date_range_preset"
                  label="Quick Filters"
                  options={TIME_PRESETS}
                  onChange={(cb) => {
                    const { date_range_preset = '' } = cb();
                    if (date_range_preset === 'CUSTOM') {
                      setInnerValue(cb);
                      return;
                    }
                    setInnerValue((prev) => ({
                      ...prev,
                      date_range_preset,
                      ...(TIME_PRESET_VALUE[date_range_preset] ?? {}),
                    }));
                  }}
                  value={innerValue.date_range_preset ?? ''}
                />
                <div className="flex space-x-2">
                  <Button
                    type="button"
                    outline
                    className="w-full"
                    onClick={clearValue}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    primary
                    className="w-full"
                    onClick={applyChange}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

InlineDateRange.defaultProps = {
  value: {},
  onSubmitValue: null,
};

InlineDateRange.propTypes = {
  value: PropTypes.instanceOf(Object),
  onSubmitValue: PropTypes.instanceOf(Function),
};
