import React from 'react';
import PropTypes from 'prop-types';

// import GenericDetails from 'partial/components/GenericDetails';
import bizCorporation from 'assets/images/icons/biz-corporation.svg';

import {
  // REPRESENTATIVE_INFORMATION_FORMAT,
  // HOME_ADDRESS_FORMAT,
  // BANK_DETAILS_FORMAT,
  // BUSINESS_INFO_FORMAT,
  BUSINESS_TYPE_LABEL,
} from '../constants';

function MerchantBusinessInfo({
  type,
  //  data, isLoading
}) {
  return (
    <div className="space-y-4">
      <div className="data-container">
        <div className="flex items-center space-x-2">
          <img src={bizCorporation} alt="" />
          <div className="title-h2 capitalize">{BUSINESS_TYPE_LABEL[type]}</div>
        </div>
      </div>
      {/* <div className="data-container">
        <div className="title-h2 mb-5">Representative&apos;s information</div>
        <GenericDetails
          data={data || {}}
          isLoading={isLoading}
          format={REPRESENTATIVE_INFORMATION_FORMAT}
          numberOfCols={2}
        />
      </div>
      <div className="data-container">
        <div className="title-h2 mb-5">Home Address</div>
        <GenericDetails
          data={data || {}}
          isLoading={isLoading}
          format={HOME_ADDRESS_FORMAT}
          numberOfCols={1}
        />
      </div>
      <div className="data-container">
        <div className="title-h2 mb-5">Business Information</div>
        <GenericDetails
          data={data || {}}
          isLoading={isLoading}
          format={BUSINESS_INFO_FORMAT[type]}
          numberOfCols={3}
        />
      </div>
      <div className="data-container">
        <div className="title-h2 mb-5">Bank Details</div>
        <GenericDetails
          data={data || {}}
          isLoading={isLoading}
          format={BANK_DETAILS_FORMAT}
          numberOfCols={2}
        />
      </div> */}
    </div>
  );
}

MerchantBusinessInfo.propTypes = {
  type: PropTypes.string,
  // data: PropTypes.instanceOf(Object).isRequired,
  // isLoading: PropTypes.bool,
};

MerchantBusinessInfo.defaultProps = {
  type: '',
  // isLoading: false,
};

export default MerchantBusinessInfo;
