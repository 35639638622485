import React from 'react';
import ReactApexCharts from 'react-apexcharts';
import PropTypes from 'prop-types';
import { formatNumber } from 'helper';

function LineChart({ label: name, data, isLoading, valueKey, decimalValue }) {
  const options = {
    stroke: {
      width: 2,
      curve: 'smooth',
      colors: ['#216BC5'],
    },
    xaxis: {
      categories: data?.map((item, i) => item?.title ?? `Data ${i + 1}`),
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: ['#216BC5'],
      type: 'solid',
    },
    markers: {
      size: 5,
      strokeWidth: 2,
      colors: ['#fff'],
      strokeColors: ['#216BC5'],
    },
    dataLabels: {
      enabled: false,
      formatter(val) {
        if (!val) return '';
        return [`${formatNumber(val, decimalValue)}`];
      },
    },
  };

  const series = [
    {
      name,
      data: (data || []).map((item) => item[valueKey] ?? null),
    },
  ];
  if (isLoading) {
    return (
      <div className="h-full w-full py-8 pl-10 pr-6">
        <div
          className="h-full w-full animate-pulse rounded bg-gray-200"
          style={{
            clipPath:
              'polygon(0 80%, 13% 71%, 25% 55%, 39% 44%, 54% 51%, 66% 46%, 78% 34%, 90% 22%, 100% 33%, 100% 43%, 100% 100%, 0% 100%)',
          }}
        />
      </div>
    );
  }
  return (
    <ReactApexCharts
      height="100%"
      options={options}
      series={series}
      type="line"
    />
  );
}
LineChart.defaultProps = {
  label: 'Count',
  valueKey: 'count',
  data: {
    labels: [],
    series: [],
  },
  isLoading: false,
  decimalValue: 0,
};

LineChart.propTypes = {
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  valueKey: PropTypes.string,
  decimalValue: PropTypes.number,
};

export default LineChart;
