import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export function BreadCrumbPortal() {
  const pageNameRef = useRef();

  return (
    <div
      ref={pageNameRef}
      id="page_name_portal"
      className="bread-crumb-portal"
    />
  );
}

function BreadCrumb({ children }) {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const portalOutRef = useRef(document.getElementById('page_name_portal'));

  useEffect(() => {
    portalOutRef.current = document.getElementById('page_name_portal');
  }, [location]);

  if (portalOutRef?.current) {
    return ReactDOM.createPortal(
      <div className="bread-crumb">
        <button
          type="button"
          className="hover:text-primary-500"
          onClick={() => history.push(match.url)}
        >
          {children}
        </button>
      </div>,
      portalOutRef.current
    );
  }
  return null;
}

BreadCrumb.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BreadCrumb;
