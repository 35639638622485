import cn from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { ImDownload3 } from 'react-icons/im';
import React, { useMemo, useState } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import Button from 'partial/components/Button';
import noData from 'assets/images/no-data.svg';
import { useCreateModal } from 'partial/components/Modal';
import FormSearchInput from 'partial/form/FormSearchInput';

import {
  useMerchantSelected,
  useMerchantAttachedCategories,
  useToggleInvoiceChannelSwitchable,
} from '../hooks';
import CategoryBox from './CategoryBox';
import FeeSimulatorModal from '../modals/FeeSimulatorModal';

function NoAttachedPaymentCategory() {
  return (
    <div className="relative flex flex-1 items-center">
      <div className="mx-auto max-w-xl py-20 text-center">
        <img src={noData} className="mx-auto mb-4 w-48" alt="" />
        <div className="text-base text-gray-700">No Channels yet.</div>
        <div className="text-sm text-gray-500">
          Contact your support team below:
          <div className="flex items-center justify-center space-x-2">
            <a href="tel:+63XXX XXX XXXX">+63XXX XXX XXXX</a>
            <span className="text-gray-400">&bull;</span>
            <a href="tel:+63X XXX XXXX">+63X XXX XXXX</a>
            <span className="text-gray-400">&bull;</span>
            <a href="mailto:support@admin.com">support@admin.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

function MerchantChannels({ merchantId }) {
  const [searchChannelString, setSearchChannelString] = useState('');
  const [isLoadingAttachedCategoryList, attachedCategoryList] =
    useMerchantAttachedCategories(merchantId);

  const [isLoading, data] = useMerchantSelected();

  const {
    isSwitchable,
    enableSwitching,
    disableSwitching,
    isLoading: isLoadingToggle,
  } = useToggleInvoiceChannelSwitchable({
    merchantId,
    initIsSwitchableValue: data?.is_payment_channel_switchable,
  });

  const createModal = useCreateModal('MERCHANT_CHANNELS', {
    attachedCategoryList,
  });

  const handleFeeSimulator = () => {
    createModal({
      content:
        (close) =>
        // eslint-disable-next-line
        ({ attachedCategoryList: attachedList }) =>
          (
            <FeeSimulatorModal
              onClose={close}
              attachedCategoryList={attachedList}
              merchantId={merchantId}
            />
          ),
    });
  };

  const debounceSetSearchChannelString = useMemo(
    () => debounce(setSearchChannelString, 300),
    [setSearchChannelString]
  );

  return (
    <div className="flex-grow space-y-4">
      <div className="grid grid-cols-12 gap-2">
        <div className="relative col-span-12 lg:col-span-5">
          <FormSearchInput
            name="q"
            withClearBtn
            icon={HiOutlineSearch}
            placeholder="Search Payment Channel"
            onChange={(cb) => debounceSetSearchChannelString(cb()?.q)}
          />
        </div>
        <div className="col-span-12 flex justify-end lg:col-span-5">
          <label
            className={cn(
              'flex h-full cursor-pointer items-center space-x-2 rounded-[0.25rem] border px-4 py-2 lg:w-min lg:max-w-full',
              isLoading ? 'cursor-wait opacity-80' : ''
            )}
          >
            <input
              type="checkbox"
              checked={isSwitchable}
              className="accent-primary-500"
              disabled={isLoading || isLoadingToggle}
              onChange={(e) => {
                if (e.target.checked) {
                  enableSwitching();
                  return;
                }
                disableSwitching();
              }}
            />
            <span className="whitespace-nowrap text-sm leading-4 text-gray-500">
              Allow users to switch payment channels
            </span>
          </label>
        </div>
        <div className="col-span-12 lg:col-span-2">
          <Button
            primary
            icon={ImDownload3}
            className="h-full w-full"
            onClick={handleFeeSimulator}
          >
            Fee Simulator
          </Button>
        </div>
      </div>
      <div className="relative space-y-4">
        {!attachedCategoryList.length && isLoadingAttachedCategoryList && (
          <>
            {[0, 1, 2].map((i) => (
              <div
                key={i}
                className="h-32 w-full animate-pulse rounded-md bg-gray-300"
              />
            ))}
          </>
        )}
        {!!attachedCategoryList.length && isLoadingAttachedCategoryList && (
          <AiOutlineLoading3Quarters className="absolute right-2 top-2 h-4 w-4 animate-spin text-primary-500" />
        )}
        {!attachedCategoryList.length && !isLoadingAttachedCategoryList && (
          <NoAttachedPaymentCategory />
        )}

        {attachedCategoryList
          // .filter(
          //   (ac) =>
          //     !categoryString.trim() ||
          //     ac.name
          //       .toLowerCase()
          //       .includes(categoryString.trim().toLowerCase())
          // )
          .map((category) => (
            <CategoryBox
              key={category?.id}
              data={category}
              merchantId={merchantId}
              searchChannelString={searchChannelString}
            />
          ))}
      </div>
    </div>
  );
}

MerchantChannels.defaultProps = {
  merchantId: '',
};

MerchantChannels.propTypes = {
  merchantId: PropTypes.string,
};

export default MerchantChannels;
