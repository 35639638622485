import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';

import {
  promiseGetMerchant,
  loadMerchantOptions,
} from 'core-modules/merchants/actions';
import FormDate from 'partial/form/FormDate';
import FormInput from 'partial/form/FormInput';
import { ErrorLabel } from 'partial/form/Form';
import FormReadOnly from 'partial/form/FormReadOnly';
import FormTextArea from 'partial/form/FormTextArea';
import { useActiveAccount } from 'modules/auth/hooks';
import FormAsyncSelect from 'partial/form/FormAsyncSelect';
import FormDescription from 'partial/form/FormDescription';

import PaymentLinkItems from './PaymentLinkItems';

function PaymentLinkForm({
  setFieldValue,
  values,
  readOnly,
  canEditMerchant,
  hasNoExpiration,
  onToggleHasExpiration,
}) {
  const [activeAccount] = useActiveAccount();

  const isTestMode = useMemo(
    () => activeAccount?.env_mode === 'TEST',
    [activeAccount]
  );

  useEffect(() => {
    if (!values?.merchant?.value) return;
    promiseGetMerchant(values?.merchant?.value);
  }, [values?.merchant?.value]);

  return (
    <>
      <div className="border-t-[0.375rem] border-slate-200 pt-4">
        {!readOnly && values?.status !== 'DRAFT' && (
          <h3 className="title-h3 mb-4">Payment Link Details</h3>
        )}
        <div
          className={cn(
            'grid gap-2',
            readOnly ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1'
          )}
        >
          {/* <div>
            <FormReadOnly value="20223-000321" label="Payment Link No" />
          </div> */}
          <div>
            {!readOnly ? (
              <>
                <FormInput
                  name="name"
                  onSetFieldValue={setFieldValue}
                  value={values?.name}
                  label="Payment Link Name"
                  required
                />
                <ErrorLabel name="name" />
              </>
            ) : (
              <FormReadOnly value={values?.name} label="Payment Link Nmae" />
            )}
          </div>
          <div>
            {!readOnly && canEditMerchant && !values?.merchantLock ? (
              <>
                <FormAsyncSelect
                  name="merchant"
                  onSetFieldValue={setFieldValue}
                  value={values?.merchant}
                  placeholder="Select Merchant"
                  label="Merchant"
                  loadOptions={loadMerchantOptions}
                  filterParams={{
                    approval_status: isTestMode ? '' : 'APPROVED',
                  }}
                  readOnly={isTestMode}
                  required
                />
                {isTestMode && (
                  <FormDescription
                    label="You can edit this field in Live mode."
                    theme="info"
                  />
                )}
              </>
            ) : (
              <FormReadOnly
                value={values?.merchant?.label || '-'}
                label="Merchant"
              />
            )}
            <ErrorLabel name="merchant" />
          </div>
          {!hasNoExpiration && (
            <div className="z-[2] space-y-2">
              <div>
                {!readOnly ? (
                  <FormDate
                    name="expires_at"
                    onSetFieldValue={setFieldValue}
                    value={values?.expires_at}
                    label="Link Expiration Date"
                    minDate={new Date()}
                    required
                  />
                ) : (
                  <FormReadOnly
                    value={values?.expires_at || ''}
                    label="Link Expiration Date"
                  />
                )}
                <ErrorLabel name="expires_at" />
              </div>
            </div>
          )}
          {typeof onToggleHasExpiration === 'function' && (
            <div className="relative mt-1 flex items-center">
              <input
                type="checkbox"
                id="has_expiration_date"
                className="form-checkbox h-5 w-5 rounded accent-primary-500"
                onChange={({ target }) =>
                  onToggleHasExpiration?.(target.checked)
                }
                checked={hasNoExpiration}
              />
              <label
                className="ml-3 text-gray-600"
                htmlFor="has_expiration_date"
              >
                No expiration date
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 border-t-[0.375rem] border-slate-200 pt-5">
        <PaymentLinkItems
          name="items"
          onSetFieldValue={setFieldValue}
          value={values?.items || []}
          readOnly={readOnly}
        />
        <ErrorLabel name="items" hideIfArray />
      </div>
      <div className="mt-6 space-y-4 border-t pt-5">
        <div className="title-h2">{!readOnly ? 'Add ' : ''}Memo/Note</div>
        <div>
          {!readOnly ? (
            <FormTextArea
              name="remarks"
              onSetFieldValue={setFieldValue}
              value={values?.remarks}
              label="Remarks"
            />
          ) : (
            <FormReadOnly value={values?.remarks} label="Remarks" />
          )}
          <ErrorLabel name="remarks" />
        </div>
      </div>
    </>
  );
}

PaymentLinkForm.defaultProps = {
  canEditMerchant: false,
  readOnly: false,
  onToggleHasExpiration: undefined,
};

PaymentLinkForm.propTypes = {
  canEditMerchant: PropTypes.bool,
  readOnly: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  hasNoExpiration: PropTypes.bool.isRequired,
  onToggleHasExpiration: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.exact(undefined),
  ]),
};

export default PaymentLinkForm;
