/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
// import { IoIosAdd } from 'react-icons/io';
import Card from 'partial/components/Card';
// import { BiInfoCircle } from 'react-icons/bi';
import Button from 'partial/components/Button';
import { useLocation } from 'react-router-dom';
// import ToolTip from 'partial/components/ToolTip';
import FormStyledSelect from 'partial/form/FormStyledSelect';
import FormInlineColorPicker from 'partial/form/FormInlineColorPicker';
import ToggleViewMode from 'core-modules/pgi-themes/components/ToggleViewMode';
import FormInlineBackgroundPattern from 'partial/form/FormInlineBackgroundPattern';
import { THEMES } from 'core-modules/pgi-themes/constants';
import { useUpdateMerchantTheme } from '../hooks';

const DEFAULT_VALUE = {
  theme: '',
};

const FocusMount = () => {
  const myRef = React.useRef();
  React.useEffect(() => {
    myRef.current.scrollIntoView();
  }, [myRef]);
  return (
    <div
      ref={myRef}
      className="absolute top-[-182px] left-0 z-[9999] h-0 w-24 opacity-0"
    />
  );
};

function MerchantCms({ data }) {
  const [init, setInit] = React.useState(data?.theme || DEFAULT_VALUE);
  const [form, setForm] = React.useState(data?.theme || DEFAULT_VALUE);
  const isChanged = !isEqual(init, form);

  const [viewMode, setViewMode] = React.useState('desktop');
  const [isPreviewLoaded, setIsPreviewLoaded] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    if (location?.state?.init) setForm((state) => ({ ...state, theme: '' }));
  }, [location?.state]);

  const selectedTheme = React.useMemo(() => {
    if (!form?.color_accent || !form?.color_bg) return null;
    return THEMES[form?.theme];
  }, [form?.theme, form?.color_accent, form?.color_bg]);
  const handleViewMore = (e) => {
    e.preventDefault();
    setForm((state) => ({ ...state, theme: '' }));
  };

  const handleSetTheme = (row) => () => {
    setForm((state) => ({ ...state, theme: row.value, ...row?.defaultValue }));
  };

  const handleUndo = (e) => {
    e.preventDefault();
    setForm(init);
  };
  const [isSaving, save] = useUpdateMerchantTheme();
  const handleSave = () => {
    save(data?.id, form, () => {
      setInit(form);
    });
  };

  const previewUrl = React.useMemo(() => {
    const color_bg =
      form?.color_bg || selectedTheme?.defaultValue?.color_bg || '#303030';
    const color_accent =
      form?.color_accent ||
      selectedTheme?.defaultValue?.color_accent ||
      '#525F7F';
    const search = new URLSearchParams({
      color_bg,
      color_accent,
      bg: form?.bg || '',
    }).toString();
    return `/pgi-preview/${selectedTheme?.value}#${search}`;
  }, [selectedTheme, form]);
  React.useEffect(() => {
    setIsPreviewLoaded(false);
  }, [form?.theme, viewMode, setIsPreviewLoaded]);

  if (!selectedTheme) {
    return (
      <Card className="space-y-4 p-5">
        <div className="mx-2 mb-4 space-y-2 pb-4">
          <div className="title-h1 mt-4 text-center">Themes Management</div>
          <div className="mx-auto max-w-xl text-center text-base text-gray-500">
            Get started by selecting your themes and customize now your brand
            appears globally across the transactions your customers use.
          </div>
        </div>
        <div className="mx-auto grid max-w-6xl grid-cols-2 gap-4">
          {Object.values(THEMES).map((item) => (
            <div
              className="group relative rounded-lg border border-gray-100 p-4 shadow-none ring-2 ring-transparent transition duration-1000 ease-in-out hover:shadow-primary-xl hover:ring-gray-200"
              key={item.value}
            >
              <button
                className="absolute inset-0 z-20 h-full w-full"
                onClick={handleSetTheme(item)}
                type="button"
              />
              <div className="overflow-hidden rounded-lg">
                <img
                  className="h-full w-full scale-100 bg-gray-100 object-contain object-center transition duration-1000 ease-in-out group-hover:scale-110"
                  src={item.thumbnail}
                  alt=""
                />
              </div>
              <div className="mt-3 text-center font-semibold text-gray-500">
                {item.label}
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }
  return (
    <div className="relative">
      <FocusMount />
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-7">
        <Card className="relative z-20 col-span-1 p-5 xl:col-span-2">
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="title-h2">Brand elements</div>
              <p>
                Customize now your brand appears globally across the
                transactions your customers use.
              </p>
            </div>
            <div data-tour="merchant-theme-choose">
              <div className="mb-2 flex justify-between font-semibold text-gray-600">
                <span>Select Theme</span>
                <a
                  className="hover:underline"
                  href="/"
                  onClick={handleViewMore}
                >
                  More
                </a>
              </div>
              <FormStyledSelect
                label="Select Theme"
                name="theme"
                onChange={setForm}
                value={THEMES[form?.theme]}
                options={Object.values(THEMES)}
              />
            </div>
            {/* <hr />
            <div className="flex items-center space-x-10 justify-between">
              <ToolTip tip="A smaller representation of your logo (like a favicon)">
                <div className="flex relative">
                  <div className="rounded border-dashed group-hover:bg-gray-100 border-b border-white hover:border-gray-300 flex items-center space-x-2 cursor-pointer text-gray-600 font-semibold">
                    <div className="">Icon</div>
                    <BiInfoCircle />
                  </div>
                </div>
              </ToolTip>
              <div className="border rounded-full hover:border-gray-400 transition">
                <button type="button" className="flex items-center group">
                  <IoIosAdd className="w-14 h-14 text-gray-200 group-hover:text-gray-400 transition p-4 flex-shrink-0" />
                </button>
              </div>
            </div>
            <div className="flex items-center space-x-2 justify-between">
              <div>
                <ToolTip tip="The full-sized version of your logo">
                  <div className="flex relative">
                    <div className="rounded border-dashed group-hover:bg-gray-100 border-b border-white hover:border-gray-300 flex items-center space-x-2 cursor-pointer text-gray-600 font-semibold">
                      <div className="">Logo</div>
                      <BiInfoCircle />
                    </div>
                  </div>
                </ToolTip>
              </div>
              <div className="border rounded-lg hover:border-gray-400 transition">
                <button type="button" className="flex items-center group">
                  <IoIosAdd className="w-14 h-14 text-gray-200 group-hover:text-gray-400 transition p-4 flex-shrink-0" />
                </button>
              </div>
            </div>
            <div className="relative flex items-start py-4">
              <div className="min-w-0 flex-1 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  Use logo instead of icon
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                />
              </div>
            </div> */}
            <hr />
            <div className="space-y-2" data-tour="merchant-theme-cms">
              <FormInlineBackgroundPattern
                name="bg"
                onChange={setForm}
                value={form?.bg || ''}
                label="Background"
                description="Background image of your full page"
                backgroundColor={form?.color_bg || ''}
              />
              <FormInlineColorPicker
                name="color_bg"
                onChange={setForm}
                value={form.color_bg || '#000'}
                label="Background Color"
                description="Add a splash of color to your branded pages"
                onReset={
                  selectedTheme?.defaultValue?.color_bg
                    ? () =>
                        setForm((state) => ({
                          ...state,
                          color_bg: selectedTheme?.defaultValue?.color_bg,
                        }))
                    : null
                }
              />
              <hr />
              <FormInlineColorPicker
                name="color_accent"
                onChange={setForm}
                value={form.color_accent || '#000'}
                label="Branding"
                description="Used for emphasis color across pages"
                onReset={
                  selectedTheme?.defaultValue?.color_accent
                    ? () =>
                        setForm((state) => ({
                          ...state,
                          color_accent:
                            selectedTheme?.defaultValue?.color_accent,
                        }))
                    : null
                }
              />
            </div>
          </div>
        </Card>
        <Card className="relative z-10 col-span-1 flex flex-col xl:col-span-5">
          <div className="border-b border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex items-center justify-between space-x-2">
              <div className="title-h2">Preview</div>
              <div className="flex items-center space-x-2">
                <div className="hidden sm:block">
                  {isChanged ? (
                    <div className="space-x-2 text-gray-500">
                      <span>There are unsaved changes.</span>
                      <a
                        href="/"
                        onClick={handleUndo}
                        className="hover:underline"
                      >
                        Undo
                      </a>
                    </div>
                  ) : null}
                </div>
                <ToggleViewMode onChange={setViewMode} value={viewMode} />
                <Button
                  primary
                  onClick={handleSave}
                  disabled={isSaving || !isChanged}
                  data-tour="merchant-theme-save"
                >
                  <span className="whitespace-nowrap">Save Changes</span>
                </Button>
              </div>
            </div>
            <div className="mt-3 block border-t pt-3 sm:hidden">
              {isChanged ? (
                <div className="space-x-2 text-gray-500">
                  <span>There are unsaved changes.</span>
                  <a href="/" onClick={handleUndo} className="hover:underline">
                    Undo
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative h-[750px] overflow-auto">
            <div className="flex h-full overflow-auto">
              {viewMode === 'desktop' ? (
                <iframe
                  className="theme-wrapper relative z-10 mx-auto min-w-[640px]"
                  width="1000"
                  height="100%"
                  onLoad={() => setIsPreviewLoaded(true)}
                  seamless
                  src={previewUrl}
                  title={selectedTheme?.label}
                />
              ) : null}
              {viewMode === 'mobile' ? (
                <iframe
                  className="relative z-10 mx-auto"
                  width="375"
                  height="100%"
                  onLoad={() => setIsPreviewLoaded(true)}
                  seamless
                  src={previewUrl}
                  title={selectedTheme?.label}
                />
              ) : null}
            </div>
            <div className="absolute inset-0 flex h-full w-full">
              <div className="m-auto text-gray-400">
                {!isPreviewLoaded
                  ? 'Loading preview...'
                  : 'Unable to open preview.'}
              </div>
            </div>
          </div>
        </Card>
        {/* <Card className="p-5 col-span-5">
          <div className="grid grid-cols-4 gap-3">
            <a href='/' className="p-2 text-center relative bg-gray-100 border-2 rounded-xl hover:border-gray-200 transition">
              <HiCheckCircle className="w-8 h-8 flex-shrink-0 text-green-500 absolute -top-3 -right-3 bg-white p-px rounded-full"/>
              <img src="https://stg.multipay.asia/_next/static/images/payment-page-1-4c4e2a8d56c622a1cc205f88821a8a81.png" alt="" className='mx-auto' />
              <div className="text-gray-500 text-sm">Cash Blue</div>
            </a>
            <a href='/' className="p-2 text-center relative bg-white border-2 border-white rounded-xl hover:border-gray-100 transition">
              <img src="https://stg.multipay.asia/_next/static/images/payment-page-1-4c4e2a8d56c622a1cc205f88821a8a81.png" alt="" className='mx-auto' />
              <div className="text-gray-500 text-sm">Cash Blue</div>
            </a>
            <a href='/' className="p-2 text-center relative bg-white border-2 border-white rounded-xl hover:border-gray-100 transition">
              <img src="https://stg.multipay.asia/_next/static/images/payment-page-1-4c4e2a8d56c622a1cc205f88821a8a81.png" alt="" className='mx-auto' />
              <div className="text-gray-500 text-sm">Cash Blue</div>
            </a>
            <a href='/' className="p-2 text-center relative bg-white border-2 border-white rounded-xl hover:border-gray-100 transition">
              <img src="https://stg.multipay.asia/_next/static/images/payment-page-1-4c4e2a8d56c622a1cc205f88821a8a81.png" alt="" className='mx-auto' />
              <div className="text-gray-500 text-sm">Cash Blue</div>
            </a>
          </div>
        </Card> */}
      </div>
    </div>
  );
}

MerchantCms.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MerchantCms;
