import React from 'react';
// import PropTypes from 'prop-types';
import { BsShieldLock } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { HiCheck, HiLockClosed } from 'react-icons/hi';
import placeholder from 'assets/images/placeholder.svg';
import Background from '../components/Background';
import { useThemePreview } from '../hooks';

function Preview() {
  const location = useLocation();
  const theme = useThemePreview(location.hash);

  return (
    <Background
      className="relative bg-gradient-to-tr from-theme-preview-brand-100 to-theme-preview-brand-500 p-5 sm:p-10"
      code={theme?.bg}
      color={theme?.color_bg}
    >
      <div style={{ zoom: '80%' }}>
        <div className="relative mx-auto max-w-4xl overflow-hidden rounded-md bg-gray-100 shadow-2xl">
          <div className="absolute inset-0" />
          <div className="grid grid-cols-3 justify-center space-x-2 bg-white px-5 py-2 text-center shadow sm:px-10">
            <div className="flex items-center justify-center space-x-2">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-white text-center">
                <HiCheck className="mx-auto h-4 w-4 flex-shrink-0 text-theme-preview-accent-500" />
              </div>
              <div className="truncate text-xs font-semibold uppercase tracking-wider text-gray-500">
                Cart
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-theme-preview-accent-500 text-center">
                <span className="mx-auto text-white">2</span>
              </div>
              <div className="block truncate text-xs font-semibold uppercase tracking-wider text-gray-500">
                Cash Payments
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-gray-200 bg-white text-center">
                <span className="mx-auto text-theme-preview-accent-500">3</span>
              </div>
              <div className="truncate text-xs font-semibold uppercase tracking-wider text-gray-300">
                Confirmation
              </div>
            </div>
          </div>
          <div className="mx-auto grid max-w-3xl grid-cols-1 gap-0 sm:grid-cols-8 sm:gap-5">
            <div className="col-span-8 mx-5 my-5 sm:col-span-3 sm:mx-0 sm:my-10">
              <div>
                <div className="text-sm font-bold text-gray-500">
                  Your Transaction
                </div>
                <div className="text-xs text-gray-500">
                  Please verify all information below:
                </div>
              </div>
              <div className="my-3 space-y-2 border-b border-t py-3 text-center">
                <div className="mx-auto flex h-12 w-12 items-center overflow-hidden rounded-lg bg-white">
                  <img
                    src={theme?.source_photo || placeholder}
                    alt=""
                    className="object-contain"
                  />
                </div>
                <div className="text-xs font-semibold tracking-wide text-gray-500">
                  {theme?.source || 'Merchant'}
                </div>
              </div>
              <div className="space-y-5">
                <div className="flex items-center justify-between space-x-2">
                  <div className="text-xs text-gray-500">Amount</div>
                  <div className="text-xs font-bold text-gray-500">
                    ₱{theme?.amount || '0.00'}
                  </div>
                </div>
                <div className="flex items-center justify-between space-x-2">
                  <div className="text-xs text-gray-500">Status</div>
                  <div className="badge badge-gray py-2">For Payment</div>
                </div>
                <div className="flex items-center justify-between space-x-2">
                  <div className="text-xs text-gray-500">Total Amount</div>
                  <div className="text-xs font-bold text-gray-500">
                    ₱{theme?.amount || '0.00'}
                  </div>
                </div>
              </div>
              <div>
                <div className="my-3 pt-3">
                  <div className="text-center text-xs text-gray-500">
                    By clicking <b>Pay Now</b>, you agree with{' '}
                    <span className="underline">Terms and Conditions</span> and{' '}
                    <span className="underline">Data Privacy</span>
                  </div>
                </div>
                <div className="shadow-theme-preview-accent-md mt-4 flex items-center justify-center space-x-2 rounded-md bg-theme-preview-accent-500 py-3 text-center text-xs font-semibold text-white">
                  <span>Pay Now</span>
                  <HiLockClosed className="h-4 w-4 flex-shrink-0" />
                </div>
              </div>
            </div>
            <div className="col-span-8 mx-5 my-5 sm:col-span-5 sm:mx-0 sm:my-10">
              <div className="flex space-x-2">
                <BsShieldLock className="h-6 w-6 flex-shrink-0 text-gray-300" />
                <div className="">
                  <div className="text-sm font-bold text-gray-500">
                    Secure Checkout Payment
                  </div>
                  <div className="text-xs text-gray-500">
                    Transactions are secured and encrypted
                  </div>
                </div>
              </div>
              <div className="mt-4 rounded-lg bg-white p-5">
                <div className="mb-3 text-xs font-bold text-gray-500">
                  Select Payment Method
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-400 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-500">Cash Payments</div>
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Banks</div>
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">eWallet</div>
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">
                      Visa / Mastercard
                    </div>
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Online Banking</div>
                  </div>
                  <div className="flex flex-col space-y-1 rounded-md border-2 border-gray-200 bg-white p-2">
                    <div className="h-6 w-6 flex-shrink-0">
                      <img
                        src={placeholder}
                        className="rounded-md object-contain"
                        alt=""
                      />
                    </div>
                    <div className="text-xs text-gray-400">Kiosk</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-2xs mb-3 mt-4 text-center">
          <div className="text-xs text-black/40">
            Powered by [Merchant Name]
          </div>
          <div className="text-xs text-black/40">
            &copy; 2021 All rights reserved.
          </div>
        </div>
      </div>
    </Background>
  );
}

Preview.propTypes = {};

export default Preview;
