import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Transition } from '@headlessui/react';
import { TourProvider } from '@reactour/tour';
import { HELP_OPTIONS, TOUR_STEPS, DETAILS } from './constants';
import { AppTourContext } from './context';
import TourModal from './TourModal';
import Tour from './Tour';

export const HelpButton = () => {
  const { setSelected, setTourSteps } = React.useContext(AppTourContext);
  const btnRef = React.useRef();
  const handleOpen = (row) => (e) => {
    e.preventDefault();
    const steps = TOUR_STEPS[row?.id];
    setSelected(steps[0]);
    setTourSteps(steps);
    btnRef.current.click();
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={btnRef}
            className="text-sm leading-4 text-gray-500 focus:outline-none"
            type="button"
          >
            How to use
          </Popover.Button>
          <Transition
            className="absolute top-10 right-0"
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className=" overflow-hidden rounded-xl border bg-white shadow-primary-xl">
              <div className="w-64 divide-y">
                {HELP_OPTIONS.map((item) => (
                  <a
                    key={item.id}
                    className="block cursor-pointer truncate p-3 font-semibold text-primary-600 hover:bg-gray-50"
                    href={`#${item.id}`}
                    onClick={handleOpen(item)}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

function AppTour({ children }) {
  const [selected, setSelected] = React.useState(null);
  const [tourSteps, setTourSteps] = React.useState([]);

  const handleNext = (nextId) => {
    const next =
      DETAILS[nextId] ||
      tourSteps[tourSteps.findIndex((x) => x === selected) + 1];
    setSelected(next);
  };
  const handleSkip = () => {
    const next = DETAILS[selected?.onSkip];
    setSelected(next);
  };

  return (
    <AppTourContext.Provider
      value={{ selected, setSelected, tourSteps, setTourSteps }}
    >
      {children}
      <TourProvider>
        <TourModal data={selected} onNext={handleNext} onSkip={handleSkip} />
        <Tour data={selected} onNext={handleNext} onSkip={handleSkip} />
      </TourProvider>
    </AppTourContext.Provider>
  );
}

AppTour.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default AppTour;
