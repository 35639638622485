import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { RiLayoutMasonryLine } from 'react-icons/ri';
import { useTour } from '@reactour/tour';

function SetThemeTour({ onNext }) {
  const history = useHistory();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  React.useEffect(() => {
    setSteps([
      {
        selector: '[data-tour="merchant-tab-theme"]',
        content: 'Click Themes tab',
      },
      {
        selector: '[data-tour="merchant-theme-choose"]',
        content: 'Select the theme you want',
      },
      {
        selector: '[data-tour="merchant-theme-cms"]',
        content: 'Customize your background and colors',
      },
      {
        selector: '[data-tour="merchant-theme-save"]',
        content: 'Save your changes',
      },
    ]);
    history.push('/merchants/profile/cms');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <RiLayoutMasonryLine className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">
            Customize your themes
          </div>
          <div>
            Choose the design of your payment page.
            <br />
            <button
              className="text-secondary-500 hover:underline"
              type="button"
              onClick={() => {
                setIsOpen(true);
                setCurrentStep(0);
              }}
            >
              Teach me how
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext('set-channels')}>
          Next
        </Button>
      </div>
    </div>
  );
}

SetThemeTour.propTypes = {
  onNext: PropTypes.func.isRequired,
  // onSkip: PropTypes.func.isRequired,
};

export default SetThemeTour;
