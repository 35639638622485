import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import BreadCrumb from 'partial/components/BreadCrumb';
import PageWrapper from 'partial/components/PageWrapper';

import RefundContainer from './RefundContainer';
// import LedgerContainer from './LedgerContainer';
// import SettlementContainer from './SettlementContainer';

function AccountingRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <BreadCrumb>Accounting</BreadCrumb>
      <PageWrapper>
        <Switch>
          {/* <Route path={`${match.path}/settlement`} title="Settlement">
        <SettlementContainer />
      </Route> */}
          {/* <Route path={`${match.path}/ledger`} title="Ledger">
            <LedgerContainer />
          </Route> */}
          <Route path={`${match.path}/refund`} title="Refund">
            <RefundContainer />
          </Route>
          <Redirect to={`${match.path}/refund`} />
        </Switch>
      </PageWrapper>
    </>
  );
}

export default AccountingRoutes;
