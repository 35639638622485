import { req } from 'react-reqq-lite';
import { toastError, toastSuccess, toastInfo } from 'react-geek-toast';
import { formatNumber } from 'helper';

import * as c from './constants';
import {
  transformApiKeyList,
  transformApiKeyOptions,
  transformHistogramWebhook,
  transformTransaction,
  transformWebhookLogs,
} from './transformers';

export const promiseApiKeyOptions = (id) =>
  new Promise((resolve) => {
    req.get({
      key: `${c.MERCHANT_API_KEY_LIST}/promise`,
      url: `/merchant_api_key/v1/my/merchants/${id}/api_keys`,
      onSuccess: (res) => {
        const list = (res?.response?.data || []).map(transformApiKeyOptions);
        resolve(list);
      },
      onError: () => {
        resolve([]);
      },
    });
  });

export const getApiKeyList = (id) => {
  req.get({
    key: c.MERCHANT_API_KEY_LIST,
    url: `/merchant_api_key/v1/my/merchants/${id}/api_keys`,
    transform: ({ data }) => (data || []).map(transformApiKeyList),
  });
};

export const apiKeyActiveInactive = (
  merchantId,
  apiKeyId,
  status,
  onSuccess
) => {
  req.put({
    key: c.MERCHANT_API_KEY,
    url: `/merchant_api_key/v1/my/merchants/${merchantId}/api_keys/${apiKeyId}/enable_disable`,
    payload: {
      status: status?.toUpperCase(),
    },
    onSuccess: ({ response }, state) => {
      setTimeout(() => {
        getApiKeyList(response?.data?.merchant_uuid, state[c.MERCHANT_FILTER]);
      }, 1000);
      toastSuccess(`API Token is ${status}!`);
      if (typeof onSuccess === 'function') onSuccess();
    },
  });
};

export const regenerateApiKey = (merchantId, apiKeyId, onSuccess) => {
  req.put({
    key: c.MERCHANT_API_KEY_REGENERATE,
    url: `/merchant_api_key/v1/my/merchants/${merchantId}/api_keys/${apiKeyId}/refresh_token`,
    onSuccess: ({ response }, state) => {
      toastSuccess(`API Key successfully regenerated!`);
      setTimeout(() => {
        getApiKeyList(response?.data?.merchant_uuid, state[c.MERCHANT_FILTER]);
      }, 1000);
      if (typeof onSuccess === 'function') onSuccess();
    },
  });
};

export const createApiKey = (merchantId, payload, onSuccess, onError) => {
  req.post({
    key: c.MERCHANT_API_KEY_ITEM,
    payload,
    url: `/merchant_api_key/v1/my/merchants/${merchantId}/api_keys`,
    onSuccess: ({ response }, state) => {
      setTimeout(() => {
        getApiKeyList(response?.data?.merchant_uuid, state[c.MERCHANT_FILTER]);
      }, 1000);
      toastSuccess('API Key Created!');
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError,
  });
};

export const updateApiKey = (
  merchantId,
  apiKeyId,
  payload,
  onSuccess,
  onError
) => {
  req.put({
    key: c.MERCHANT_API_KEY_ITEM,
    payload,
    url: `/merchant_api_key/v1/my/merchants/${merchantId}/api_keys/${apiKeyId}`,
    onSuccess: ({ response }, state) => {
      setTimeout(() => {
        getApiKeyList(response?.data?.merchant_uuid, state[c.MERCHANT_FILTER]);
      }, 1000);
      toastSuccess('API Key Updated!');
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError,
  });
};

export const simulateInquire = (refno) => {
  if (!refno) return;
  req.get({
    key: c.SIMULATE_INQUIRE,
    url: `/merchant_transaction/v1/transactions/simulation/${refno}`,
    transform: (res) => ({
      source: res?.data?.merchant?.name,
      amount: formatNumber(res?.data?.amount),
      status: res?.data?.payment_status,
    }),
    onError: (err) => {
      toastError(err?.response?.message || 'Unable to load!');
      req.set(c.SIMULATE_INQUIRE, null);
    },
  });
};

export const simulatePay = (refno) => {
  req.post({
    key: c.SIMULATE_INQUIRE,
    url: `/merchant_transaction/v1/transactions/simulation/${refno}/process`,
    payload: { status: 'SUCCESS' },
    onSuccess: () => {
      toastSuccess('Updated to Paid!');
      simulateInquire(refno);
    },
    onError: (err) => {
      toastError(err?.response?.message || 'Unable to update!');
    },
  });
};

export const simulateFail = (refno) => {
  req.post({
    key: c.SIMULATE_INQUIRE,
    url: `/merchant_transaction/v1/transactions/simulation/${refno}/process`,
    payload: { status: 'FAILED' },
    onSuccess: () => {
      toastSuccess('Updated to Failed!');
      simulateInquire(refno);
    },
    onError: (err) => {
      toastError(err?.response?.message || 'Unable to update!');
    },
  });
};

export const totalWebhook = (params) => {
  req.get({
    key: `${c.MERCHANT_WEBHOOK}/total`,
    params,
    url: '/merchant_aggs/v1/aggs/total_webhooks',
    transform: ({ data }) => data,
  });
};

export const histogramWebhook = (params) => {
  req.get({
    key: `${c.MERCHANT_WEBHOOK}/histogram`,
    params,
    url: '/merchant_aggs/v1/aggs/histogram/webhooks',
    transform: (data) => transformHistogramWebhook(data),
  });
};

export const logsWebhook = (params) => {
  req.get({
    key: `${c.MERCHANT_WEBHOOK}/logs`,
    params,
    url: '/merchant_webhook/v1/webhook_logs',
    transform: ({ data }) => (data || []).map(transformWebhookLogs),
  });
};

export const getTransactionDetails = (id) => {
  req.get({
    key: c.MERCHANT_TRANSACTION,
    url: `/merchant_transaction/v1/transactions/${id}`,
    transform: ({ data }) => transformTransaction(data),
    onError: (err) => {
      const message = err?.response?.message;
      toastInfo(message);
      req.set(c.MERCHANT_TRANSACTION, {});
    },
  });
};
