import React from 'react';
import cn from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const DropZone = ({ className, children, onDrop, disabled }) => {
  const [isUploading, setUploading] = React.useState(false);
  const [showDropzone, setShowDropzone] = React.useState(false);
  // eslint-disable-next-line
  const debounceSetDropzone = React.useCallback(
    debounce((val) => {
      setShowDropzone(val);
    }, 100),
    [setShowDropzone]
  );
  const handleDragEnter = () => {
    debounceSetDropzone(true);
  };
  const handleDragLeave = () => {
    setShowDropzone(false);
  };
  const handleDrop = (e) => {
    if (disabled) return;
    e.preventDefault();
    const fileName = [];
    const { files } = e.dataTransfer;
    const filteredFiles = [];
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      fileName.push(file?.name);
      if ((file.type || '').indexOf('image/') > -1) filteredFiles.push(file);
    }
    setShowDropzone(false);
    onDrop(filteredFiles, setUploading, fileName);
  };
  return (
    <div className={cn('relative', className)} onDragEnter={handleDragEnter}>
      {showDropzone || isUploading ? (
        <div
          className="absolute inset-0 z-30 flex h-full w-full bg-gray-50 p-3"
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className="pointer-events-none flex w-full rounded-lg border-4 border-dashed">
            <div className="m-auto">
              {isUploading ? 'Uploading' : 'Drop image here'}
            </div>
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
};

DropZone.defaultProps = {
  className: '',
  children: null,
  disabled: false,
};

DropZone.propTypes = {
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  onDrop: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DropZone;
