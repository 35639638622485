import React from 'react';
import {
  TableIcon,
  ChartPieIcon,
  ChartSquareBarIcon,
} from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import Card from 'partial/components/Card';
import Table from 'partial/components/Table';
import Button from 'partial/components/Button';
import PieChart from 'modules/common/chart/PieChart';
import BarChart from 'modules/common/chart/BarChart';

export const useSchemeCard = (array, default_index = 0) => {
  const [props, setProps] = React.useState(array[default_index]);
  const output = React.useMemo(
    () => ({
      key: props.id,
      ...props,
      actionOptions: array.map((x) => ({
        id: x.id,
        label: x.label,
        onClick: () => {
          setProps(x);
        },
      })),
    }),
    [array, props, setProps]
  );
  return output;
};

// const MAP_DECIMAL_PLACE = {
//   transaction: 0,
//   amount: 2,
// };

function DashboardSchemeCard({
  label,
  hook,
  actionOptions,
  tableFormat,
  filter,
  defaultSort,
  defaultChart,
  bar: showBar,
  pie: showPie,
  table: showTable,
  meta: additionalMeta,
}) {
  const [chartType, setChartType] = React.useState(defaultChart);
  const [meta, setMeta] = React.useState({
    sort: defaultSort,
    ...additionalMeta,
  });

  const [isLoading, table, chart, subLabel, dataLabel] = hook(filter, meta);

  const tableSource = React.useMemo(
    () => (
      <div className="flex items-center space-x-1">
        {showTable && (
          <Button
            size="xs"
            primary={chartType === 'table'}
            icon={TableIcon}
            onClick={() => setChartType('table')}
          />
        )}
        {showPie && (
          <Button
            size="xs"
            primary={chartType === 'pie'}
            icon={ChartPieIcon}
            onClick={() => setChartType('pie')}
          />
        )}
        {showBar && (
          <Button
            size="xs"
            primary={chartType === 'bar'}
            icon={ChartSquareBarIcon}
            onClick={() => setChartType('bar')}
          />
        )}
      </div>
    ),
    [chartType, setChartType, showBar, showPie, showTable]
  );
  return (
    <Card
      label={label}
      actionOptions={actionOptions}
      renderAction={tableSource}
    >
      <div className="flex flex-col" style={{ height: 300 }}>
        {chartType === 'table' && (
          <Table
            isLoading={isLoading}
            data={table}
            format={tableFormat}
            downloadCsv={(row) => ({
              Label: row.label,
              Txn: row.transaction,
              Amount: row.amount,
            })}
            downloadFilename={label}
            onSort={meta.sort.indexOf('id') > -1 ? false : setMeta}
            sort={meta.sort}
            subLabel={subLabel}
          />
        )}
        {chartType === 'pie' && <PieChart data={chart} isLoading={isLoading} />}
        {chartType === 'bar' && (
          <BarChart data={chart} isLoading={isLoading} label={dataLabel} />
        )}
      </div>
    </Card>
  );
}

DashboardSchemeCard.defaultProps = {
  label: 'Data',
  actionOptions: [],
  meta: {},
  filter: {},
  defaultSort: 'id:asc',
  defaultChart: 'table',
  table: true,
  pie: true,
  bar: true,
};

DashboardSchemeCard.propTypes = {
  bar: PropTypes.bool,
  pie: PropTypes.bool,
  table: PropTypes.bool,
  label: PropTypes.string,
  defaultSort: PropTypes.string,
  defaultChart: PropTypes.string,
  hook: PropTypes.func.isRequired,
  meta: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
  actionOptions: PropTypes.instanceOf(Array),
  tableFormat: PropTypes.instanceOf(Array).isRequired,
};

export default DashboardSchemeCard;
