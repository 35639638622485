import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

function FormInlineSelect({
  icon: Icon,
  name,
  onChange,
  value,
  placeholder,
  options,
  sm,
  label,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  return (
    <div>
      {label ? <>{label}</> : null}
      <div className="flex rounded-md shadow-primary-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          {Icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          )}
          <select
            autoComplete="none"
            className={cn(
              'form-select block w-full rounded-md border-gray-300 pl-3 pr-10 text-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500',
              sm ? 'py-1' : 'py-4'
            )}
            onChange={handleChange}
            value={value}
            {...props}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((x) => (
              <option value={x.value} key={x.value}>
                {x.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

FormInlineSelect.defaultProps = {
  placeholder: '',
  icon: false,
  options: [],
  sm: false,
  label: null,
};

FormInlineSelect.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sm: PropTypes.bool,
  label: PropTypes.node,
};

export default FormInlineSelect;
