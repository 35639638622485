import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiCheck, HiPencil } from 'react-icons/hi';

import { useInvoiceLogsList } from '../hooks';

function InvoiceLogs({ data }) {
  const [filter] = React.useState({ page: 1 });
  const [isLoading, list] = useInvoiceLogsList(data?.id, filter);
  return (
    <div className="mt-4 flow-root rounded-xl border p-5 sm:border-none sm:p-0">
      <div className="title-h2 mb-6 border-b pb-3">History</div>
      <ul className="-mb-8">
        {list.length < 1 ? (
          <div className="py-4 text-center text-gray-500">
            {isLoading ? 'Loading...' : 'There are no items'}
          </div>
        ) : (
          list.map((item, i) => (
            <li key={item.id}>
              <div className="relative pb-8">
                {i !== list.length - 1 ? (
                  <span
                    className="absolute top-4 left-[0.75rem] -ml-[0.063rem] h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  <div>
                    <span
                      className={cn(
                        i === 0 ? 'bg-black' : 'bg-gray-400',
                        'flex h-6 w-6 items-center justify-center rounded-full ring-8 ring-white'
                      )}
                    >
                      {i === 0 ? (
                        <HiCheck
                          className="h-4 w-4 text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <HiPencil
                          className="h-4 w-4 text-white"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 flex-col">
                    <div>
                      <p className="text-sm text-gray-700">{item.remarks}</p>
                    </div>
                    <div className="whitespace-nowrap text-sm text-gray-500">
                      <time className="" dateTime={item.created_at}>
                        {item.created_at_human}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

InvoiceLogs.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default InvoiceLogs;
