import React from 'react';
import ReactGA from 'react-ga4';

import { useAuth } from 'modules/auth/hooks';
import { Route, Switch } from 'react-router-dom';
import { ModalProvider } from 'partial/components/Modal';
import OnboardingContainer from 'core-modules/onboarding';
import ErrorBoundary from 'partial/containers/ErrorBoundary';
import DevelopersContainer from 'documentation/DevelopersContainer';
import { EnvironmentWrapper } from 'modules/auth/modals/SwitchProfileModal';
import { GoogleRecaptchaProvider } from 'partial/components/GoogleRecaptcha';
import EmailActivationRoutes from 'modules/auth/containers/EmailActivationRoutes';
import PgiPreviewContainer from 'core-modules/pgi-themes/ containers/PgiPreviewContainer';

import Public from './Public';
import Private from './Private';

export const PURGE_IGNORE = () => <input type="tel" />;

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const PrivateRoutes = React.memo(() => {
  return (
    <EnvironmentWrapper>
      <Switch>
        <Route path="/onboarding" component={OnboardingContainer} />
        <Route component={Private} />
      </Switch>
    </EnvironmentWrapper>
  );
});

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <ErrorBoundary>
      <GoogleRecaptchaProvider>
        <ModalProvider>
          <Switch>
            <Route path="/developers" component={DevelopersContainer} />
            <Route path="/pgi-preview" component={PgiPreviewContainer} />
            {/* <Route path="/p/print-sample/:doc_id" component={PrintContainer} /> */}
            <Route path="/email-activation" component={EmailActivationRoutes} />
            <Route>{isAuthenticated ? <PrivateRoutes /> : <Public />}</Route>
          </Switch>
        </ModalProvider>
      </GoogleRecaptchaProvider>
    </ErrorBoundary>
  );
}

export default App;
