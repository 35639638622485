import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Button from './Button';

export const MAP_DATE_BY_RANGE = {
  '4H': {
    from: dayjs().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  '12H': {
    from: dayjs().subtract(12, 'hours').format('YYYY-MM-DD HH:mm:ss'),
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  '1D': {
    from: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  '1M': {
    from: dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  '1Y': {
    from: dayjs().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  ALL: {
    from: '2019-01-01 00:00:00',
    to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
};

export const MAP_TYPE_BY_RANGE = {
  '4H': 'hour',
  '12H': 'hour',
  '1D': 'hour',
  '1M': 'day',
  '1Y': 'month',
  ALL: 'month',
};

function DateRangeGroup({ name, onChange, value }) {
  const handleChange = (newValue) => () => {
    onChange((state) => ({
      ...state,
      [name]: newValue,
    }));
  };
  return (
    <div className="flex items-center pt-3 sm:pt-0">
      <div className="btn-group">
        <Button primary={value === '4H'} size="sm" onClick={handleChange('4H')}>
          4H
        </Button>
        <Button
          primary={value === '12H'}
          size="sm"
          onClick={handleChange('12H')}
        >
          12H
        </Button>
        <Button primary={value === '1D'} size="sm" onClick={handleChange('1D')}>
          1D
        </Button>
        <Button primary={value === '1M'} size="sm" onClick={handleChange('1M')}>
          1M
        </Button>
        <Button primary={value === '1Y'} size="sm" onClick={handleChange('1Y')}>
          1Y
        </Button>
        <Button
          primary={value === 'ALL'}
          size="sm"
          onClick={handleChange('ALL')}
        >
          ALL
        </Button>
        {/* <Button size="sm" icon={BiCalendar} /> */}
      </div>
    </div>
  );
}

DateRangeGroup.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DateRangeGroup;
