import { isEmpty } from 'lodash';
import { toastError } from 'react-geek-toast';
import React, { useCallback, useEffect, useRef } from 'react';

import RefundForm from 'core-modules/accounting/forms/RefundForm';
import { ModalCard, useCreateModal } from 'partial/components/Modal';

export const useRefundTransaction = (
  options = { onSubmitSuccess: () => {} }
) => {
  const optionsRef = useRef(options ?? {});
  useEffect(() => {
    optionsRef.current = options ?? {};
  }, [options]);
  const createModal = useCreateModal();
  const openRefundTransactionModal = useCallback(
    (transactionData) => {
      if (isEmpty(transactionData)) {
        toastError('Transaction not found!');
        return;
      }
      createModal({
        content: (close) => (
          <ModalCard
            title={`Refund Request - ${transactionData?.refno || ''}`}
            onClose={close}
          >
            <RefundForm
              data={transactionData}
              onSuccess={() => {
                close();
                if (typeof optionsRef.current?.onSubmitSuccess === 'function')
                  optionsRef.current.onSubmitSuccess();
              }}
              onCancel={close}
            />
          </ModalCard>
        ),
      });
    },
    [createModal]
  );

  return {
    openRefundTransactionModal,
  };
};
