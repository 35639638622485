import ReactGA from 'react-ga4';

const navigate = (to) =>
  ReactGA.event({
    action: 'navigate',
    category: 'verb',
    label: to,
  });

const click = (what) =>
  ReactGA.event({
    action: 'click',
    category: 'verb',
    label: what,
  });

const done = (what) =>
  ReactGA.event({
    action: 'done',
    category: 'verb',
    label: what,
  });

export default {
  navigate,
  click,
  done,
};
