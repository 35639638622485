import { req } from 'react-reqq-lite';
import { toastError, toastSuccess } from 'react-geek-toast';

import promiseDownload from 'utils/promiseDownload';
import { MERCHANT_FILTER } from 'core-modules/merchants/constants';
import { listMerchants, selectMerchant } from 'core-modules/merchants/actions';

import * as t from './transformers';
import * as c from './constants';

export const promiseGetGeneralInfo = (account_id) =>
  new Promise((resolve) => {
    req.get({
      key: c.GENERAL_INFO,
      url: `/profile/v1/my/onboarding/${account_id}/general`,
      transform: (res) => t.transformGeneralInfo(res?.data || {}),
      onSuccess: (res) =>
        resolve(t.transformGeneralInfo(res?.response?.data || {})),
      onError: () => {
        req.set(c.GENERAL_INFO, t.transformGeneralInfo({}));
      },
    });
  });

export const saveGeneralInfo = (account_id, payload, onSuccess, onError) => {
  req.put({
    key: c.GENERAL_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/general`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.GENERAL_INFO, { ...state[c.GENERAL_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const saveMerchantBank = (account_id, payload, onSuccess, onError) => {
  req.put({
    key: c.BANK_DETAILS,
    url: `/profile/v1/my/onboarding/${account_id}/bank`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.GENERAL_INFO, { ...state[c.GENERAL_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const saveBusinessCorporation = (
  account_id,
  payload,
  onSuccess,
  onError
) => {
  req.put({
    key: c.BUSINESS_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/corporate`,
    payload: t.payloadBusinessCorporation(payload),
    onSuccess: (res, state) => {
      req.set(c.BUSINESS_INFO, { ...state[c.BUSINESS_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const saveBusinessSole = (account_id, payload, onSuccess, onError) => {
  req.put({
    key: c.BUSINESS_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/sole`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.BUSINESS_INFO, { ...state[c.BUSINESS_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const saveBusinessIndividual = (
  account_id,
  payload,
  onSuccess,
  onError
) => {
  req.put({
    key: c.BUSINESS_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/individual`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.BUSINESS_INFO, { ...state[c.BUSINESS_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const saveBusinessPartner = (
  account_id,
  payload,
  onSuccess,
  onError
) => {
  req.put({
    key: c.BUSINESS_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/partnership`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.BUSINESS_INFO, { ...state[c.BUSINESS_INFO], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const promiseGetAttachments = (account_id) =>
  new Promise((resolve) => {
    req.get({
      key: c.ATTACHMENTS,
      url: `/profile/v1/my/onboarding/${account_id}/due_diligence`,
      transform: (res) => t.transformAttachments(res?.data || {}),
      onSuccess: (res) =>
        resolve(t.transformAttachments(res?.response?.data || {})),
      onError: () => {
        req.set(c.ATTACHMENTS, t.transformAttachments({}));
      },
    });
  });

export const saveAttachments = (account_id, payload, onSuccess, onError) => {
  req.put({
    key: c.ATTACHMENTS,
    url: `/profile/v1/my/onboarding/${account_id}/due_diligence`,
    payload,
    onSuccess: (res, state) => {
      req.set(c.ATTACHMENTS, { ...state[c.ATTACHMENTS], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const promiseGetRequirements = (merchantId) =>
  new Promise((resolve) => {
    req.get({
      key: c.REQUIREMENTS,
      url: `/profile/v1/my/onboarding/${merchantId}/requirements`,
      transform: (res) => res?.data || {},
      onSuccess: (res) => resolve(res?.response?.data || {}),
      onError: () => {
        req.set(c.REQUIREMENTS, {});
      },
    });
  });

export const saveRequirements = (merchantId, payload, onSuccess, onError) => {
  req.put({
    key: c.REQUIREMENTS,
    url: `/profile/v1/my/onboarding/${merchantId}/requirements`,
    payload,
    onSuccess: (res) => {
      // req.set(c.ATTACHMENTS, { ...state[c.ATTACHMENTS], ...payload });
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const submitApplication = (account_id, payload, onSuccess, onError) => {
  req.put({
    key: c.APPLICATION,
    url: `/profile/v1/my/onboarding/${account_id}/submit_application`,
    payload,
    onSuccess: (res, state) => {
      selectMerchant(account_id);
      listMerchants(state[MERCHANT_FILTER]);
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const downloadSwornDeclarationTemplate = async ({
  onSuccess,
  onError,
}) => {
  try {
    await promiseDownload(
      '/core_dataset/v1/docs/RMC_No_8-2024_Annex_A',
      'RMC_No_8-2024_Annex_A.pdf',
      {
        method: 'GET',
      }
    );
    toastSuccess('Downloading...');
    onSuccess();
  } catch (err) {
    onError();
    toastError(err?.error_description || err?.message || 'Unable to download!');
  }
};
