import { useState, useEffect, useCallback } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { useAccountMeta, useActiveAccount } from 'modules/auth/hooks';

import * as c from './constants';
import * as actions from './actions';

export const useMerchantList = (params, onSuccess) => {
  const isLoading = useApiLoading(c.MERCHANT_LIST, 'get');
  const data = useApiGet(c.MERCHANT_LIST, []);
  const pager = useApiGet(c.MERCHANT_PAGER, {});
  const appendList = useCallback(
    (page) => {
      actions.appendListMerchants({ ...params, page });
    },
    [params]
  );

  useEffect(() => {
    actions.listMerchants(params, onSuccess);
    // eslint-disable-next-line
  }, [params]);
  return [isLoading, data, pager, appendList];
};

export const useMerchantListSelected = () => {
  const isLoading = useApiLoading(c.MERCHANT_LIST, 'get');
  const data = useApiGet(c.MERCHANT_LIST, []);
  const pager = useApiGet(c.MERCHANT_PAGER, {});
  return [isLoading, data, pager];
};

export const useMerchantSelected = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'get');
  const data = useApiGet(c.MERCHANT_ITEM, {});
  return [isLoading, data];
};

export const useQuickMerchant = () => {
  // const isLoading = useApiLoading(`${c.MERCHANT_ITEM}/quick`, 'get');
  const data = useApiGet(`${c.MERCHANT_ITEM}/quick`, {});
  return [data];
};

export const useSelectMerchant = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.MERCHANT_ITEM, null);
      return;
    }
    actions.selectMerchant(id);
  }, [id]);
};

export const useToggleInvoiceChannelSwitchable = ({
  merchantId,
  initIsSwitchableValue,
}) => {
  const [isSwitchable, setIsSwitchable] = useState(initIsSwitchableValue);
  const isLoading = useApiLoading(c.MERCHANT_CHANNELS_SWITCH, 'put');
  const enableSwitching = useCallback(() => {
    setIsSwitchable(true);
    actions.toggleMerchantInvoiceChannelSwitchable({
      merchant_id: merchantId,
      onError: () => {
        setIsSwitchable(false);
      },
    })({
      payload: {
        payment_channel_switch_allowed: 1,
      },
    });
  }, [merchantId, setIsSwitchable]);

  const disableSwitching = useCallback(() => {
    setIsSwitchable(false);
    actions.toggleMerchantInvoiceChannelSwitchable({
      merchant_id: merchantId,
      onError: () => {
        setIsSwitchable(true);
      },
    })({
      payload: {
        payment_channel_switch_allowed: 0,
      },
    });
  }, [merchantId, setIsSwitchable]);

  useEffect(() => {
    setIsSwitchable(initIsSwitchableValue);
  }, [initIsSwitchableValue, setIsSwitchable]);

  return { isSwitchable, isLoading, enableSwitching, disableSwitching };
};

export const useOrgFirstMerchantData = () => {
  const isLoading = useApiLoading(c.ORG_MERCHANT, 'get');
  const data = useApiGet(c.ORG_MERCHANT, {});

  const fetchData = useCallback(() => actions.getOrgMerchant(), []);

  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
};

export const useCreateMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'post');
  const [activeAccount] = useActiveAccount();
  const submit = useCallback(
    (raw, onSuccess, onError) => {
      const payload = {
        organization_uuid: activeAccount.id,
        ...raw,
      };
      actions.createMerchant(payload, onSuccess, onError);
    },
    [activeAccount]
  );
  return [isLoading, submit];
};

export const useUpdateMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'put');
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateMerchant(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMerchantTheme = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM_THEME, 'put');
  const { meta, update } = useAccountMeta();
  const submit = useCallback(
    (id, payload, onSuccess) => {
      actions.updateMerchantTheme(id, payload, (r) => {
        if (!meta?.is_theme_customized) update({ is_theme_customized: true });
        if (onSuccess) onSuccess(r);
      });
    },
    [meta, update]
  );
  return [isLoading, submit];
};

export const useRemoveMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'remove');
  const submit = useCallback((id, onSuccess) => {
    actions.removeMerchant(id, onSuccess);
  }, []);
  return [isLoading, submit];
};

/* MERCHANT PROFILE */
export const useGetGeneralInfo = () => {
  const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_GENERAL_INFO, 'get');
  const data = useApiGet(c.MERCHANT_GENERAL_INFO, {});
  useEffect(() => {
    actions.getGeneralInfo(merchant?.id);
  }, [merchant]);
  return [isLoading, data];
};

/* MERCHANT PROFILE END */

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const useMerchantAttachedCategories = (id) => {
  const isLoading = useApiLoading(c.LIST_PAYMENT_CATEGORIES(id), 'get');
  const data = useApiGet(c.LIST_PAYMENT_CATEGORIES(id), []);

  const fetchData = useCallback(() => {
    actions.listMerchantAttachedCategories(id);
  }, [id]);

  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
};

export const usePaymentCategoryChannelList = (merchantId, categoryId) => {
  const isLoading = useApiLoading(
    c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId),
    'get'
  );
  const list = useApiGet(c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId), []);

  const fetchData = useCallback(() => {
    if (!merchantId || !categoryId) return;
    actions.listPaymentCategoryChannels(merchantId, categoryId);
  }, [merchantId, categoryId]);

  const setList = useCallback(
    (newList) => {
      req.set(c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId), newList);
    },
    [categoryId]
  );

  useEffect(fetchData, [fetchData]);

  return [isLoading, list, fetchData, setList];
};

export const useTogglePaymentChannelStatus = (
  initStatus,
  merchantId,
  categoryId,
  channelId,
  callback
) => {
  const [status, setStatus] = useState(initStatus);
  const isLoadingStatus = useApiLoading(c.TOGGLE_CATEGORY_STATUS, 'put');
  const activateChannel = useCallback(() => {
    setStatus('ACTIVE');
    actions.toggleAttachedChannelStatus(
      merchantId,
      categoryId,
      channelId,
      { status: 'ACTIVE' },
      callback,
      () => setStatus('INACTIVE')
    );
  }, [merchantId, categoryId, channelId, callback, setStatus]);
  const deactivateChannel = useCallback(() => {
    setStatus('INACTIVE');
    actions.toggleAttachedChannelStatus(
      merchantId,
      categoryId,
      channelId,
      { status: 'INACTIVE' },
      callback,
      () => setStatus('ACTIVE')
    );
  }, [merchantId, categoryId, channelId, callback, setStatus]);

  useEffect(() => {
    setStatus(initStatus);
  }, [initStatus]);

  return { status, isLoadingStatus, activateChannel, deactivateChannel };
};

export const useUpdatePaymentChannelSequence = (
  merchantId,
  categoryId,
  onSuccess = () => {},
  onError = () => {}
) => {
  const isLoading = useApiLoading(c.UPDATE_CHANNEL_SEQUENCE, 'put');
  // eslint-disable-next-line
  const updateChannelSequence = useCallback(
    actions.updateAttachedChannelSequence(
      merchantId,
      categoryId,
      onSuccess,
      onError
    ),
    [merchantId, categoryId, onSuccess, onError]
  );

  return [isLoading, updateChannelSequence];
};
/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */
