import React from 'react';
import PropTypes from 'prop-types';
import { BiInfoCircle } from 'react-icons/bi';
import { useOpenTypeConfirmAlert } from 'partial/components/Modal';

import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import ToolTip from 'partial/components/ToolTip';
import { useActiveAccount } from 'modules/auth/hooks';

import { useRemoveMerchant } from '../hooks';

function MerchantMisc({ data }) {
  const openTypeConfirmAlert = useOpenTypeConfirmAlert();
  const [activeAccount] = useActiveAccount();
  const canBeDeleted =
    data?.id &&
    data?.approval_status !== 'APPROVED' &&
    activeAccount?.env_mode !== 'TEST';
  const [isDeleteLoading, deleteMerchant] = useRemoveMerchant();
  const handleDelete = () => {
    openTypeConfirmAlert({
      onYes: (close) => {
        close();
        deleteMerchant(data.id);
      },
      onNo: (close) => close(),
      yesLabel: 'Delete',
      noLabel: 'Cancel',
      label: 'Delete Merchant',
      subLabel: '',
      stringToMatch: 'CONFIRM',
    });
  };
  return (
    <div className="space-y-4">
      <Card label="Danger Zone">
        <div className="p-6 flex items-start sm:items-center flex-col sm:flex-row space-x-2">
          <Button
            danger
            onClick={handleDelete}
            disabled={isDeleteLoading || !canBeDeleted}
          >
            {!canBeDeleted ? (
              <ToolTip
                className="text-left font-normal"
                tip="You cannot delete Approved and Test merchants"
              >
                <div className="flex relative">
                  <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                    <div className="whitespace-nowrap">Delete Merchant</div>
                    <BiInfoCircle className="sm:block hidden" />
                  </div>
                </div>
              </ToolTip>
            ) : (
              'Delete Merchant'
            )}
          </Button>
          {!canBeDeleted && (
            <p className="text-sm text-gray-500 mt-1 sm:hidden flex space-x-2 items-center">
              <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
              <span>You cannot delete Approved and Test merchants</span>
            </p>
          )}
        </div>
      </Card>
    </div>
  );
}

MerchantMisc.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default MerchantMisc;
