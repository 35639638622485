import React, { useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Table from 'partial/components/Table';
import { HiHashtag, HiX } from 'react-icons/hi';
import Button from 'partial/components/Button';
import GenericInfo, { InfoItem } from 'partial/components/GenericInfo';
import FormSearchInput from 'partial/form/FormSearchInput';
import { useSimulateInquire, useSimulateProcess } from '../hooks';

const Label = ({ value }) => (
  <div className="truncate text-lg font-medium leading-6 text-gray-900">
    {value}
  </div>
);

Label.propTypes = {
  value: PropTypes.string.isRequired,
};

const MAP_STATUS = {
  PAID: <span className="badge badge-success">Paid</span>,
  PENDING: <span className="badge badge-warning">Pending</span>,
  FAILED: <span className="badge badge-danger">Failed</span>,
  INITIAL: <span className="badge badge-gray">Initial</span>,
  REFUNDED: <span className="badge badge-gray">Refunded</span>,
  VOIDED: <span className="badge badge-gray">Voided</span>,
  EXPIRED: <span className="badge badge-gray">Expired</span>,
};

function TestPartner() {
  const [form, setForm] = React.useState({
    refno: '', // MP6GHKN6FV
  });
  const [isLoading, data, fetch] = useSimulateInquire();
  const [isPaying, { pay, fail, clear }] = useSimulateProcess();
  const handlePay = () => {
    pay(form?.refno);
  };
  const handleFail = () => {
    fail(form?.refno);
  };
  const handleClear = () => {
    setForm({ refno: '' });
    clear();
  };
  useEffect(() => {
    fetch(form?.refno);
  }, [fetch, form]);
  return (
    <div className="space-y-3">
      <div className="flex items-center space-x-1 px-5">
        <div className="relative">
          <FormSearchInput
            name="refno"
            onChange={setForm}
            value={form?.refno || ''}
            icon={HiHashtag}
            placeholder="Enter reference number"
            label=""
          />
        </div>
        {data?.status ? (
          <Button onClick={handleClear} icon={HiX}>
            Clear
          </Button>
        ) : (
          <Button outline type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Inquire'}
          </Button>
        )}
      </div>
      <div className="mx-auto border-b">
        <GenericInfo data={data}>
          <InfoItem value="source" label="Source" />
          <InfoItem value="amount" label="Amount" />
          <InfoItem value={() => MAP_STATUS[data?.status]} label="Status" />
        </GenericInfo>
      </div>
      <div className="mx-auto flex justify-end space-x-3 px-4">
        <Button
          success
          disabled={data?.status !== 'PENDING' || isPaying}
          onClick={handlePay}
        >
          Mark as Paid
        </Button>
        <Button
          danger
          disabled={data?.status !== 'PENDING' || isPaying}
          onClick={handleFail}
        >
          Mark as Failed
        </Button>
      </div>
    </div>
  );
}

function PaymentSimulatorModal({ onClose }) {
  const [active, setActive] = React.useState('otc');
  const handleSet = (v) => (e) => {
    e.preventDefault();
    setActive(v);
  };
  return (
    <div className="p-5">
      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0">
        <div className="-mr-px w-full divide-y rounded-md border sm:w-44 lg:rounded-r-none">
          <a
            className={cn(
              'block p-3 hover:bg-gray-50',
              active === 'otc' ? 'bg-gray-100 hover:bg-gray-100' : ''
            )}
            href="/"
            onClick={handleSet('otc')}
          >
            <div className="font-semibold">Over-the-counter</div>
          </a>
          <a
            className={cn(
              'block p-3 hover:bg-gray-50',
              active === 'cc' ? 'bg-gray-100 hover:bg-gray-100' : ''
            )}
            href="/"
            onClick={handleSet('cc')}
          >
            <div className="font-semibold">Credit Card</div>
          </a>
          <a
            className={cn(
              'block p-3 hover:bg-gray-50',
              active === 'gcash' ? 'bg-gray-100 hover:bg-gray-100' : ''
            )}
            href="/"
            onClick={handleSet('gcash')}
          >
            <div className="font-semibold">GCash</div>
          </a>
          <a
            className={cn(
              'block p-3 hover:bg-gray-50',
              active === 'paymaya' ? 'bg-gray-100 hover:bg-gray-100' : ''
            )}
            href="/"
            onClick={handleSet('paymaya')}
          >
            <div className="font-semibold">Paymaya</div>
          </a>
        </div>
        <div className="min-h-[350px] flex-1 divide-y rounded-md border lg:rounded-l-none">
          {active === 'otc' ? (
            <div className="space-y-2 p-5">
              <Label value="Over-the-Counter" />
              <div className="-mx-5">
                <TestPartner />
              </div>
            </div>
          ) : null}
          {active === 'cc' ? (
            <div className="space-y-2 p-5">
              <Label value="Credit Cards" />
              <div className="-mx-5 flex min-h-[280px]">
                <Table
                  data={[
                    {
                      type: 'MASTERCARD',
                      refno: '5123456789012346',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '111',
                      security: 'Not enabled',
                    },
                    {
                      type: 'MASTERCARD',
                      refno: '5453010000064154',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '111',
                      security: 'secbarry1',
                    },
                    {
                      type: 'VISA',
                      refno: '4123450131001381',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '123',
                      security: 'mctest1',
                    },
                    {
                      type: 'VISA',
                      refno: '4123450131001522',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '123',
                      security: 'mctest1',
                    },
                    {
                      type: 'VISA',
                      refno: '4123450131004443',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '123',
                      security: 'mctest1',
                    },
                    {
                      type: 'VISA',
                      refno: '4123450131000508',
                      expiry_m: '12',
                      expiry_y: '2025',
                      cvv: '111',
                      security: 'Not enabled',
                    },
                  ]}
                  format={[
                    { label: 'Card Type', key: 'type', id: 'type' },
                    { label: 'Number', key: 'refno', id: 'refno' },
                    { label: 'Expiry Month', key: 'expiry_m', id: 'expiry_m' },
                    { label: 'Expiry Year', key: 'expiry_y', id: 'expiry_y' },
                    { label: 'CVV', key: 'cvv', id: 'cvv' },
                    {
                      label: '3-D Secure Password',
                      key: 'security',
                      id: 'security',
                    },
                  ]}
                />
              </div>
              <div>
                For a full list of cards and additional test cases please refer{' '}
                <a
                  className="underline"
                  href="https://developers.maya.ph/page/full-list-of-mock-cards"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </div>
            </div>
          ) : null}
          {active === 'gcash' ? (
            <div className="space-y-2 p-5">
              <Label value="GCash" />
              <div className="-mx-5">
                <GenericInfo
                  data={{
                    account_number: '+639165523448',
                    otp: '888888',
                    mpin: '1111',
                  }}
                >
                  <InfoItem value="account_number" label="Mobile Number" />
                  <InfoItem value="otp" label="OTP" />
                  <InfoItem value="mpin" label="MPIN" />
                </GenericInfo>
              </div>
            </div>
          ) : null}
          {active === 'paymaya' ? (
            <div className="space-y-2 p-5">
              <Label value="Paymaya" />
              <div className="-mx-5">
                <GenericInfo
                  data={{
                    account_number: '09193890579',
                    password: 'Password123',
                    otp: '123456',
                  }}
                >
                  <InfoItem value="account_number" label="Username" />
                  <InfoItem value="password" label="Password" />
                  <InfoItem value="otp" label="OTP" />
                </GenericInfo>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  );
}

PaymentSimulatorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PaymentSimulatorModal;
