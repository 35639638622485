import React, { useMemo, useState, useRef } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { CgOptions } from 'react-icons/cg';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { formatNumber, useOnClickOutside, usePersistState } from 'helper';

import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import HistogramRange from 'partial/components/HistogramRange';
import BarChartSeries from 'modules/common/chart/BarChartSeries';
import LineChartSeries from 'modules/common/chart/LineChartSeries';
import {
  PresentationChartBarIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/outline';
import { buildId, MAP_ROWS } from '../constants';
import { useCompare } from '../hooks';

const KEY_OPTIONS = [
  { label: 'Transaction', value: 'transaction' },
  { label: 'Amount', value: 'amount' },
];

const COMPARE_OPTIONS = [
  { label: 'By Day', value: 'day' },
  { label: 'By Month', value: 'month' },
  { label: 'By Year', value: 'year' },
];

const COLORS = [
  '#2ecc71',
  '#3498db',
  '#f1c40f',
  '#e74c3c',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#d35400',
];

// eslint-disable-next-line
const Th = ({ children, ...props }) => (
  <th
    className="sticky top-0 px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-900"
    {...props}
  >
    {children}
  </th>
);
// eslint-disable-next-line
const Td = ({ children, ...props }) => (
  <th
    className="whitespace-nowrap px-6 py-2 text-sm font-medium text-gray-900"
    {...props}
  >
    {children}
  </th>
);

const renderCount = (
  row,
  c,
  data,
  mode,
  display_key,
  postfix = '',
  defaultValue = null
) => {
  const raw = get(data, `${buildId(c, mode)}.table.${row}`) || {};
  return raw[`${display_key}${postfix}`] || defaultValue;
};

const renderCompare = (curr, prev, compare) => {
  if (!prev || !curr) return null;
  const difference = ((curr - prev) / prev) * 100;
  const percentage = formatNumber(difference, 1);
  if (difference === 0) return null;
  const getClassName = () => {
    if (compare && compare === dayjs().format('YYYY-MM-DD'))
      return 'py-0 px-2 badge badge-warning ml-1';
    if (difference > 0) return 'py-0 px-2 badge badge-success ml-1';
    if (difference < 0) return 'py-0 px-2 badge badge-danger ml-1';
    return 'py-0 px-2 badge badge-secondary ml-1';
  };
  const getIcon = () => {
    if (difference > 0) return <HiChevronUp className="inline-block h-4 w-4" />;
    if (difference < 0)
      return <HiChevronDown className="inline-block h-4 w-4" />;
    return null;
  };
  return (
    <span className={getClassName()}>
      {getIcon()}
      {percentage}%
    </span>
  );
};

const CompareValue = ({
  row,
  col,
  prevCol,
  data,
  meta,
  prefix,
  defaultValue,
}) => {
  return (
    <>
      {renderCount(
        row,
        col,
        data,
        meta.compare,
        meta.key,
        prefix,
        defaultValue
      )}
      {renderCompare(
        renderCount(row, col, data, meta.compare, meta.key),
        renderCount(row, prevCol, data, meta.compare, meta.key)
      )}
    </>
  );
};

CompareValue.defaultProps = {
  prevCol: null,
  prefix: '',
  defaultValue: null,
};

CompareValue.propTypes = {
  row: PropTypes.string.isRequired,
  col: PropTypes.string.isRequired,
  prevCol: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  data: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  prefix: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
};

export const TransactionCompareLoader = () => (
  <div className="space-y-6">
    <div
      className="animate-pulse rounded-lg bg-gray-200"
      style={{ height: 54.5 }}
    />
    <div
      className="animate-pulse rounded-lg bg-gray-200"
      style={{ height: 311 }}
    />
  </div>
);

const MAP_DATE_TYPE_FORMAT = {
  year: 'YYYY',
  month: 'YYYY-MM',
  day: 'YYYY-MM-DD',
};

const DEFAULT_YEAR_RANGE = {
  from: dayjs().subtract(2, 'year').format('YYYY'),
  to: dayjs().format('YYYY'),
};
const DEFAULT_MONTH_RANGE = {
  from: dayjs().subtract(2, 'month').format('YYYY-MM'),
  to: dayjs().format('YYYY-MM'),
};

const DEFAULT_DAY_RANGE = {
  from: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
};

const MAX_COMPARE_ITEM_COUNT = 7;

function TransactionCompare({ hideTable }) {
  const [chartType, setChartType] = useState('bar');
  const [showSmFilter, setShowSmFilter] = useState(false);

  const [meta, setMeta] = usePersistState(
    'comparative-meta',
    { compare: 'year', key: 'transaction' },
    {
      keepOnUnmount: false,
    }
  );

  const [dateRange, setDateRange] = usePersistState(
    'comparative-items',
    DEFAULT_YEAR_RANGE,
    {
      keepOnUnmount: false,
    }
  );

  const dateRangeItems = useMemo(() => {
    const dateItems = [dateRange.from];
    while (
      dayjs(dateRange.to).isAfter(dayjs(dateItems[dateItems.length - 1]))
    ) {
      dateItems.push(
        dayjs(dateItems[dateItems.length - 1])
          .add(1, meta.compare)
          .format(MAP_DATE_TYPE_FORMAT[meta.compare])
      );
    }
    return dateItems;
  }, [dateRange, meta.compare]);

  const [, data, chart] = useCompare(meta.compare, dateRangeItems, meta.key);

  const rows = useMemo(() => MAP_ROWS[meta.compare], [meta.compare]);

  const filterContainerRef = useRef();
  useOnClickOutside(filterContainerRef, () => setShowSmFilter(false));

  const toggleChart = useMemo(
    () => (
      <div className="grid grid-cols-1 items-center gap-1 sm:flex">
        <div className="relative flex items-center divide-x">
          <div className="relative px-2 lg:px-0">
            <Button
              size="xs"
              icon={CgOptions}
              className={cn(
                showSmFilter ? 'pointer-events-none' : '',
                'lg:hidden'
              )}
              onClick={() => setShowSmFilter(true)}
            />
            <div
              ref={filterContainerRef}
              className={cn(
                !showSmFilter ? 'hidden' : '',
                'absolute right-0 top-full z-10 mt-2 space-y-1 rounded-lg border bg-white p-3 lg:static lg:mt-0 lg:flex lg:space-y-0 lg:divide-x lg:rounded-none lg:border-none lg:p-0'
              )}
            >
              <div className="space-y-1 lg:flex lg:space-x-1 lg:space-y-0 lg:px-2">
                <FormInlineSelect
                  sm
                  name="key"
                  onChange={setMeta}
                  value={meta.key}
                  options={KEY_OPTIONS}
                />
                <FormInlineSelect
                  sm
                  name="compare"
                  onChange={(cb) => {
                    const { compare = '' } = cb();
                    setMeta((prev) => ({ ...prev, compare }));
                    switch (compare) {
                      case 'year':
                        return setDateRange(DEFAULT_YEAR_RANGE);
                      case 'month':
                        return setDateRange(DEFAULT_MONTH_RANGE);
                      case 'day':
                        return setDateRange(DEFAULT_DAY_RANGE);
                      default:
                        return null;
                    }
                  }}
                  value={meta.compare}
                  options={COMPARE_OPTIONS}
                />
              </div>
              <div
                className={cn(
                  meta.compare === 'day' ? 'flex-col space-y-1' : 'space-x-1',
                  'z-10 flex items-center lg:flex-row lg:space-x-1 lg:space-y-0 lg:px-2'
                )}
              >
                <HistogramRange
                  small
                  value={dateRange.from}
                  defaultValue={dateRange.from}
                  mode={meta.compare}
                  switchMode={false}
                  onChange={(cb) => {
                    const { from } = cb();
                    setDateRange((prev) => ({
                      ...prev,
                      from: dayjs(from).format(
                        MAP_DATE_TYPE_FORMAT[meta.compare]
                      ),
                    }));
                  }}
                  minDate={dayjs(dateRange.to)
                    .subtract(MAX_COMPARE_ITEM_COUNT - 1, meta.compare)
                    .toDate()}
                  maxDate={dayjs(dateRange.to)
                    .subtract(1, meta.compare)
                    .toDate()}
                />
                <span>to</span>
                <HistogramRange
                  small
                  value={dateRange.to}
                  defaultValue={dateRange.to}
                  mode={meta.compare}
                  switchMode={false}
                  onChange={(cb) => {
                    const { to } = cb();
                    setDateRange((prev) => ({
                      ...prev,
                      to: dayjs(to).format(MAP_DATE_TYPE_FORMAT[meta.compare]),
                    }));
                  }}
                  minDate={dayjs(dateRange.from).add(1, meta.compare).toDate()}
                  maxDate={dayjs
                    .min(
                      dayjs(dateRange.from).add(
                        MAX_COMPARE_ITEM_COUNT - 1,
                        meta.compare
                      ),
                      dayjs(dayjs().format(MAP_DATE_TYPE_FORMAT[meta.compare]))
                    )
                    .toDate()}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-1 pl-2">
            <Button
              size="xs"
              primary={chartType === 'line'}
              icon={PresentationChartLineIcon}
              onClick={() => setChartType('line')}
            />
            <Button
              size="xs"
              primary={chartType === 'bar'}
              icon={PresentationChartBarIcon}
              onClick={() => setChartType('bar')}
            />
          </div>
        </div>
      </div>
    ),
    [
      meta,
      setMeta,
      dateRange,
      chartType,
      showSmFilter,
      setDateRange,
      setChartType,
      setShowSmFilter,
      // expandItemsRangeTo,
      // expandItemsRangeFrom,
    ]
  );

  return (
    <div className="space-y-6">
      <Card label="Comparative" renderAction={toggleChart}>
        {chartType === 'line' && (
          <div className="flex flex-col pr-[0.875rem]" style={{ height: 300 }}>
            <LineChartSeries labels={rows} series={chart} colors={COLORS} />
          </div>
        )}
        {chartType === 'bar' && (
          <div className="flex flex-col" style={{ height: 300 }}>
            <BarChartSeries labels={rows} series={chart} colors={COLORS} />
          </div>
        )}
      </Card>
      {!hideTable ? (
        <div className="flex flex-col">
          <div className="-my-2 overflow-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {rows.map((row) => (
                      <tr key={row}>
                        <Td width="100px">{row}</Td>
                        {dateRangeItems.map((c, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Td key={`${row}-${i}`}>
                            <CompareValue
                              row={row}
                              col={c}
                              prevCol={dateRangeItems[i - 1]}
                              data={data}
                              meta={meta}
                              prefix="_human"
                              defaultValue="-"
                            />
                          </Td>
                        ))}
                        <Td />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

TransactionCompare.defaultProps = {
  hideTable: false,
};

TransactionCompare.propTypes = {
  hideTable: PropTypes.bool,
};

export default TransactionCompare;
