import React from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { TbCopy } from 'react-icons/tb';
import { toastSuccess } from 'react-geek-toast';
import { HiInformationCircle } from 'react-icons/hi';
import { isValidPhoneNumber } from 'react-phone-number-input';

import ThumbIcon from 'partial/components/ThumbIcon';
import { truncate } from 'lodash';

export const INVOICE_LIST = 'INVOICE/list';
export const INVOICE_PAGER = 'INVOICE/pager';
export const INVOICE_FILTER = 'INVOICE/filter';
export const INVOICE_ITEM = 'INVOICE/item';

export const INVOICE_LOG_LIST = 'INVOICE_LOG/list';
export const INVOICE_LOG_PAGER = 'INVOICE_LOG/pager';
export const INVOICE_LOG_FILTER = 'INVOICE_LOG/filter';

export const COUNT_CARD = 'INVOICE/count_card';

const STATUS_CLASS = {
  DRAFT: 'whitespace-nowrap badge badge-gray',
  PENDING: 'whitespace-nowrap badge badge-warning',
  WAITING_FOR_PAYMENT_CHANNEL: 'whitespace-nowrap badge badge-warning',
  WAITING_FOR_PAYMENT: 'whitespace-nowrap badge badge-warning',
  PAID: 'whitespace-nowrap badge badge-success',
  ACTIVE: 'whitespace-nowrap badge badge-success',
  FAILED: 'whitespace-nowrap badge badge-danger',
  INACTIVE: 'whitespace-nowrap badge badge-danger',
  EXPIRED: 'whitespace-nowrap badge badge-danger',
  CANCELLED: 'whitespace-nowrap badge badge-danger',
  ARCHIVED: 'whitespace-nowrap badge badge-gray',
  REFUNDED: 'whitespace-nowrap badge badge-gray',
  default: 'whitespace-nowrap badge badge-gray',
};

export const renderStatus = (status, label) => (
  <span className={STATUS_CLASS[status] || STATUS_CLASS.default}>{label}</span>
);

export const getValidationSchema = (env_mode = 'LIVE') =>
  yup.object().shape({
    recipient_name: yup
      .string()
      .max(140, 'Maximum of 140 characters')
      .required('Required'),
    ...(() =>
      env_mode === 'LIVE'
        ? {
            contact_mobile_number: yup
              .string()
              .required('Required')
              .test({
                name: 'must be valid',
                test: (value) => isValidPhoneNumber(value || ''),
                message: 'Invalid',
              }),
          }
        : {})(),
    email: yup.string().email('Invalid Format').required('Required'),
    merchant: yup
      .object()
      .shape({
        label: yup.string().nullable(),
        value: yup.string(),
      })
      .nullable()
      .required('Required'),
    due_date: yup
      .string()
      .required('Required')
      .test({
        name: 'date must be in correct format',
        test: (value) => {
          return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value;
        },
        message: 'Invalid date format',
      })
      .test({
        name: 'date must be today or in future',
        test: (value) => {
          return (
            dayjs(value).isSame(dayjs().format('YYYY-MM-DD')) ||
            dayjs(value).isAfter(Date.now())
          );
        },
        message: "Due date can't be in the past",
      }),
    items: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .max(64, 'Max of 64 characters only')
            .required('Item name is required'),
          qty: yup
            .number()
            .typeError('Qty is invalid')
            .min(1, 'Qty must be greater than 0')
            .required('Qty is required'),
          amount: yup
            .number()
            .typeError('Amount is invalid')
            .min(1, 'Must be greater than 0')
            .required('Amount is required'),
        })
      )
      .min(1, 'Required')
      .required('Required'),
  });

export const GET_PAYMENT_LINK_VALIDATION_SCHEMA = ({ hasExpirationDate }) =>
  yup.object().shape({
    merchant: yup
      .object()
      .shape({
        label: yup.string().nullable(),
        value: yup.string(),
      })
      .nullable()
      .required('Required'),
    ...(() =>
      hasExpirationDate
        ? {
            expires_at: yup
              .string()
              .required('Required')
              .test({
                name: 'date must be in correct format',
                test: (value) => {
                  return (
                    dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value
                  );
                },
                message: 'Invalid date format',
              })
              .test({
                name: 'date must be today or in future',
                test: (value) => {
                  return (
                    dayjs(value).isSame(dayjs().format('YYYY-MM-DD')) ||
                    dayjs(value).isAfter(Date.now())
                  );
                },
                message: "Due date can't be in the past",
              }),
          }
        : {})(),
    items: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .max(64, 'Max of 64 characters only')
            .required('Item name is required'),
          qty: yup
            .number()
            .typeError('Qty is invalid')
            .min(1, 'Qty must be greater than 0')
            .required('Qty is required'),
          amount: yup
            .number()
            .typeError('Amount is invalid')
            .min(1, 'Amount must be greater than 0')
            .required('Amount is required'),
        })
      )
      .min(1, 'Required')
      .required('Required'),
  });

export const GET_INVOICE_TABLE_FORMAT = ({ onViewEditClick }) => [
  {
    label: 'Date/Time',
    id: 'created_at',
    key: 'created_at',
    stackKey: true,
  },
  {
    label: 'Invoice No',
    id: 'invoice_number',
    key: 'invoice_number',
    stackKey: true,
  },
  {
    label: 'Merchant',
    id: 'merchant',
    key: (row) => row?.merchant?.label,
    stackKey: true,
  },
  {
    label: 'Reference No',
    id: 'refno',
    key: 'refno',
    stackKey: true,
  },
  {
    label: 'Recipient',
    id: 'recipient_name',
    key: (row) => (
      <div className="flex items-center space-x-2">
        <ThumbIcon
          alt={row?.recipient_name}
          url=""
          className="h-6 w-6 border-none !bg-gray-200 !text-[0.625rem] !font-thin !text-gray-600"
        />
        <p>{row?.recipient_name}</p>
      </div>
    ),
    stackKey: true,
  },
  {
    label: 'Status',
    id: 'status',
    key: ({ status, status_label }) => renderStatus(status, status_label),
    stackKey: true,
  },
  {
    label: 'Due Date',
    id: 'due_date_human',
    key: 'due_date_human',
    stackKey: true,
  },
  {
    label: 'Amount',
    id: 'amount',
    key: 'total_amount',
    stackKey: true,
  },
  {
    label: '',
    id: 'action',
    key: (row) => (
      <button
        type="button"
        className="link-primary inline-flex"
        onClick={() => onViewEditClick(row)}
      >
        <HiInformationCircle className="h-4 w-4" />
        <span className="font-light">View Info</span>
      </button>
    ),
    stackKey: true,
  },
  {
    smRender: (item) => (
      <div className="py-2">
        <button
          className="absolute inset-0 z-10 h-full w-full"
          onClick={() => onViewEditClick(item)}
          type="button"
          aria-label="icon"
        >
          &nbsp;
        </button>
        <div className="float-right space-y-1 text-right">
          <div className="font-bold">{item?.total_amount}</div>
          <div>{renderStatus(item?.status, item?.status_label)}</div>
        </div>
        <div className="mb-1 text-primary-500">{item?.merchant?.label}</div>
        <div className="mb-1 font-light text-gray-500">
          {item?.refno ?? '--'}
        </div>
        <div className="font-light text-gray-500">{item?.created_at}</div>
      </div>
    ),
  },
];

// Payment Links Stuffs
export const GET_PAYMENT_LINKS_TABLE_FORMAT = [
  {
    label: 'Payment Link Name',
    id: 'name',
    key: 'name',
    stackKey: true,
  },
  {
    label: 'Page Url',
    id: 'page_url',
    key: (row) => (
      <div className="z-10 flex space-x-2">
        <p className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4">
          {row?.page_url}
        </p>
        <button
          type="button"
          className="relative z-10 flex space-x-1 rounded bg-primary-50 px-1 py-0.5 text-primary-500 ring-primary-500 hover:opacity-75 active:ring-1"
          onClick={() => {
            navigator.clipboard.writeText(row?.page_url);
            toastSuccess('Copied to clipboard!');
          }}
        >
          <TbCopy className="h-4 w-4" /> <span className="text-xs">Copy</span>
        </button>
      </div>
    ),
    stackKey: true,
  },
  {
    label: 'Txn Count',
    id: 'transaction_count',
    key: 'transaction_count',
    stackKey: true,
  },
  {
    label: 'Amount',
    id: 'amount',
    key: 'total_amount_human',
    stackKey: true,
  },
  {
    label: 'Status',
    id: 'status',
    key: ({ status, status_label }) => renderStatus(status, status_label),
    stackKey: true,
  },
  {
    label: 'Created Date',
    id: 'created_at_human',
    key: 'created_at_human',
    stackKey: true,
  },
  {
    smRender: (item) => (
      <div className="relative">
        <div className="float-right">
          <div className="text-right font-bold">{item?.total_amount_human}</div>
          <div className="text-right font-bold">{item?.transaction_count}</div>
          <div className="text-right">
            {renderStatus(item?.status, item?.status_label)}
          </div>
        </div>
        <div className="text-primary-500">{item?.name}</div>
        <div className="text-gray-500">
          {truncate(item?.page_url, { length: 35 })}
        </div>
        <div className="text-gray-500">{item?.created_at_human}</div>
      </div>
    ),
  },
];

export const PAYMENT_LINKS_LIST = 'PAYMENT_LINKS/list';
export const PAYMENT_LINK_CREATE = 'PAYMENT_LINKS/create';
export const PAYMENT_LINKS_ITEM = 'PAYMENT_LINKS/item';
export const PAYMENT_LINK_STATUS = 'PAYMENT_LINKS/status';
export const PAYMENT_LINK_TRANSACTION = 'PAYMENT_LINKS/transaction';
export const PAYMENT_LINK_TRANSACTIONS = 'PAYMENT_LINKS/transactions';
export const PAYMENT_LINK_TRANSACTION_LOGS = 'PAYMENT_LINKS/transaction_logs';
export const PAYMENT_LINKS_PAID_AGGREGATION = 'PAYMENT_LINKS/paid_aggregation';
export const PAYMENT_LINKS_UNPAID_AGGREGATION =
  'PAYMENT_LINKS/unpaid_aggregation';
