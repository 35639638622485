import React from 'react';
import { BiHome } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Button from 'partial/components/Button';
import TermsAndConditions from 'modules/legal/components/TermsAndConditions';
import { ColoredLogo } from 'modules/common/components/AppIconLogo';

function TermsContainer() {
  const history = useHistory();

  return (
    <div className="relative">
      <div className="absolute z-[1] flex w-full items-center justify-between border-b px-5 py-4 shadow">
        <div>
          <Button primary icon={BiHome} onClick={() => history.push('/')}>
            Back to login page
          </Button>
        </div>
        <ColoredLogo />
      </div>
      <div className="mx-5 mb-10 mt-28 flex justify-center">
        <TermsAndConditions />
      </div>
    </div>
  );
}

export default TermsContainer;
