/* eslint-disable */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import AuthStore from 'auth-store';
import { debounce } from 'lodash';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';

import {
  persistStorage,
  withReqHookDefaultOptions,
  // formatNumber
} from 'helper';

import { showDrawer } from 'partial/components/Modal';
// import { useSwitchProfileModal } from 'modules/auth/modals/SwitchProfileModal';

import * as c from './constants';
import * as actions from './actions';
import OverrideModal from './modals/OverrideModal';
// import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';

const getStoredToken = () => AuthStore.get('_token');

export const useAuth = () => {
  const { isAuthenticated } = useApiGet(c.AUTHENTICATION, {
    isAuthenticated: Boolean(getStoredToken()),
  });
  useEffect(() => {
    const token = getStoredToken();
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
    });
  }, []);
  return { isAuthenticated };
};

export const withOverride = (func) => {
  showDrawer({
    title: 'Override',
    className: 'modal-sm',
    content: (close) => <OverrideModal onSubmit={func} onClose={close} />,
  });
};

export const useLoginViaSso = () => {
  const isLoading = useApiLoading(c.LOGIN_SSO, 'get');
  const submit = useCallback(() => {
    const token = '-temp-';
    AuthStore.set('_token', token);
    req.set(c.AUTHENTICATION, {
      isAuthenticated: true,
    });
  }, []);
  return [isLoading, submit];
};

export const useLogin = () => {
  const isLoading = useApiLoading(c.LOGIN, 'post');
  return [isLoading, actions.login];
};

export const useRegister = () => {
  const isLoading = useApiLoading(c.REGISTER, 'post');
  return [isLoading, actions.register];
};

const usePersistState = (key, defaultState = null) => {
  const [state, setState] = useState(persistStorage.get(key, defaultState));
  // eslint-disable-next-line
  const debounceSet = useCallback(
    debounce((newValue) => {
      persistStorage.set(key, newValue);
    }, 500),
    [key]
  );
  const set = useCallback(
    (newValue) => {
      persistStorage.set(key, newValue);
    },
    [key]
  );
  const stateRef = useRef();
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  const handleSet = useCallback(
    (v) => {
      const newState = typeof v === 'function' ? v(stateRef.current) : v;
      setState(newState);
      debounceSet(newState);
    },
    [setState, debounceSet]
  );
  return [state, handleSet, set];
};

/* PROFILE START */
export const useGetProfile = () => {
  const [profile, debounceSet] = usePersistState('profile', null);
  useEffect(() => {
    actions.getProfile(debounceSet);
  }, [debounceSet]);
  const get = useCallback(() => {
    actions.getProfile(debounceSet);
  }, [debounceSet]);
  return [profile, get];
};

export const useProfile = () => {
  const contx = useContext(c.ProfileContext);
  return [contx?.profile, contx?.getProfile];
};

export const useChangePassword = () => {
  const isLoading = useApiLoading(c.CHANGE_PASSWORD, 'put');
  return [isLoading, actions.changePassword];
};

/* PROFILE END */

/* MY ACCOUNT START */
export const useCreateAccount = () => {
  const isLoading = useApiLoading(c.CREATE_ACCOUNT, 'post');
  return [isLoading, actions.createAccount];
};

export const useCurrentAccount = () => {
  const isLoading = useApiLoading(c.CURRENT_ACCOUNT, 'get');
  const data = useApiGet(c.CURRENT_ACCOUNT, {});
  useEffect(() => {
    actions.getCurrentAccount();
  }, []);
  return [isLoading, data];
};

export const useUpdateAccount = () => {
  const isLoading = useApiLoading(c.CURRENT_ACCOUNT, 'put');
  return [isLoading, actions.updateAccount];
};

export const useUpdateAccountBank = () => {
  const isLoading = useApiLoading(c.CURRENT_ACCOUNT_BANK, 'put');
  return [isLoading, actions.updateOrgAccountBank];
};

export const useActiveAccount = () => {
  const data = useApiGet(c.CURRENT_ACCOUNT, {});
  return [data];
};

/* MY ACCOUNT END */

export const useForgotPassword = (
  options = {
    onSendInstructions: () => {},
    onConfirmation: () => {},
    onConfirmationError: () => {},
    onSetNewPassword: () => {},
  }
) => {
  const isLoadingSendEmail = useApiLoading(c.FORGOT_PASSWORD_SEND, 'post');
  const isLoadingConfirmation = useApiLoading(c.FORGOT_PASSWORD_CONFIRM, 'put');
  const isLoadingSetPassword = useApiLoading(c.FORGOT_PASSWORD_SET, 'put');

  // eslint-disable-next-line
  const sendEmailInstruction = useCallback(
    actions.forgotPasswordSendInstruction(options?.onSendInstructions),
    []
  );

  // eslint-disable-next-line
  const confirmForgotPassword = useCallback(
    actions.forgotPasswordConfirm(
      options?.onConfirmation,
      options?.onConfirmationError
    ),
    []
  );

  // eslint-disable-next-line
  const setNewPassword = useCallback(
    actions.forgotPasswordSetNewPassword(options?.onSetNewPassword),
    []
  );

  return {
    sendEmailInstruction,
    confirmForgotPassword,
    setNewPassword,
    isLoadingSendEmail,
    isLoadingConfirmation,
    isLoadingSetPassword,
  };
};

export const useSetPassword = (
  options = {
    onConfirmation: () => {},
    onConfirmationError: () => {},
    onSetPassword: () => {},
  }
) => {
  const isLoadingConfirmation = useApiLoading(c.USER_PASSWORD_CONFIRM, 'put');
  const isLoadingSetPassword = useApiLoading(c.USER_PASSWORD_SET, 'put');

  // eslint-disable-next-line
  const confirmSetPassword = useCallback(
    actions.setPasswordConfirm(
      options?.onConfirmation,
      options?.onConfirmationError
    ),
    []
  );

  // eslint-disable-next-line
  const setPassword = useCallback(
    actions.setPassword(options?.onSetPassword),
    []
  );

  return {
    confirmSetPassword,
    setPassword,
    isLoadingConfirmation,
    isLoadingSetPassword,
  };
};

// UPDATE EXPIRED PASSWORD
export const useUpdateExpiredPassword = (
  options = {
    onConfirmation: () => {},
    onConfirmationError: () => {},
    onSetPassword: () => {},
  }
) => {
  const isLoadingUpdateConfirmation = useApiLoading(
    c.UPDATE_EXPIRED_PASSWORD_CONFIRM,
    'put'
  );
  const isLoadingUpdatePassword = useApiLoading(
    c.UPDATE_EXPIRED_PASSWORD_SET,
    'put'
  );

  // eslint-disable-next-line
  const confirmUpdatePassword = useCallback(
    actions.confirmExpiredPasswordUpdate(
      options?.onConfirmation,
      options?.onConfirmationError
    ),
    []
  );

  // eslint-disable-next-line
  const setUpdatePassword = useCallback(
    actions.updateExpiredPassword(options?.onSetPassword),
    []
  );

  return {
    confirmUpdatePassword,
    setUpdatePassword,
    isLoadingUpdateConfirmation,
    isLoadingUpdatePassword,
  };
};

export const useCreateOnboarding = () => {
  const isLoading = useApiLoading(c.CREATE_ONBOARDING, 'put');
  return [isLoading, actions.createOnboarding];
};

export const useAccountMeta = () => {
  const isLoading = useApiLoading(c.CURRENT_ACCOUNT, 'get');
  const activeAccount = useApiGet(c.CURRENT_ACCOUNT, {});
  const update = useCallback(
    (params) =>
      new Promise((resolve) => {
        const payload = {
          meta_data: {
            ...activeAccount?.meta,
            ...params,
          },
        };
        actions.updateMeta(payload, resolve);
      }),
    [activeAccount]
  );
  return { isLoading, meta: activeAccount?.meta, update };
};

export const useSwitchProfile = () => {
  const isLoading = useApiLoading(c.PROFILE, 'post');
  const { meta, update } = useAccountMeta();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.switchProfile(
        payload,
        async (r) => {
          if (onSuccess) onSuccess(r);
          if (!meta?.is_first_live_data)
            await update({ is_first_live_data: true });
        },
        onError
      );
    },
    [meta, update]
  );
  return [isLoading, submit];
};

// MFA

export const useEnableMFA = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.MFA_ENABLE, 'post');
  const enableMFA = useCallback(() => {
    actions.enableMFA(options.onSuccess, options.onError);
  }, [options.onSuccess, options.onError]);
  return [isLoading, enableMFA];
});

export const useDisableMFA = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.MFA_ENABLE, 'post');
  const disableMFA = useCallback(
    actions.disableMFA(options.onSuccess, options.onError),
    [options.onSuccess, options.onError]
  );
  return [isLoading, disableMFA];
});

export const useVerifyMFA = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.MFA_VERIFY, 'post');
  const verifyMFA = useCallback(
    actions.verifyMFA(options.onSuccess, options.onError),
    [options.onSuccess, options.onError]
  );
  return [isLoading, verifyMFA];
});

export const useMfaSetupData = () => {
  const isLoading = useApiLoading(c.MFA_QR, 'get');
  const mfaSetupData = useApiGet(c.MFA_QR, {});
  const fetchData = useCallback(actions.getQr, []);
  useEffect(fetchData, [fetchData]);

  return [isLoading, mfaSetupData, fetchData];
};
