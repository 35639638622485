import dayjs from 'dayjs';
import cn from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import { IoEye } from 'react-icons/io5';
import React, { useEffect } from 'react';
import { isEqual, isEmpty } from 'lodash';
import { toastError, toastSuccess } from 'react-geek-toast';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HiTrash, HiLink, HiOutlineArrowNarrowLeft } from 'react-icons/hi';

import Card from 'partial/components/Card';
import Button from 'partial/components/Button';
import Form, { setError422 } from 'partial/form/Form';
import { useActiveAccount } from 'modules/auth/hooks';
import BreadCrumb from 'partial/components/BreadCrumb';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import { useOnToggleEnvironmentEffect } from 'modules/auth/modals/SwitchProfileModal';
import PaymentSimulatorModal from 'core-modules/developers/modals/PaymentSimulatorModal';

import {
  useCancelInvoice,
  useRemoveInvoice,
  useSelectInvoice,
  useUpdateInvoice,
} from '../hooks';
import InvoiceLogs from './InvoiceLogs';
import InvoiceForm from './InvoiceForm';
import { getValidationSchema, renderStatus } from '../constants';
import { useShowInvoiceLinkQr } from '../modals/InvoiceLinkQrModal';
import { useOpenPreviewAndSendModal } from '../modals/InvoicePreviewModal';
// import MerchantCard from './MerchantCard';
// import InvoicePreview from './InvoicePreview';

const initialValues = {
  attachments: [],
  items: [],
  merchant: null,
  recipient_name: '',
  email: '',
  remarks: '',
  due_date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
};

function SelectedInvoice({ onGoBack }) {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const [activeAccount] = useActiveAccount();

  useEffect(() => {
    return () => {
      req.set(`${MERCHANT_ITEM}/quick`, {});
    };
  }, []);

  const [isSaving, update] = useUpdateInvoice();

  const callbackAfterSubmit = React.useRef(null);
  const openReviewRef = React.useRef(null);
  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    setValues,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(activeAccount?.env_mode),
    onSubmit: async (form, { setFieldError }) => {
      update(
        id,
        form,
        (newData) => {
          setValues(newData);
          if (typeof callbackAfterSubmit.current === 'function')
            callbackAfterSubmit.current();
          if (typeof callbackAfterSubmit.current !== 'function')
            toastSuccess('Updated!');
          callbackAfterSubmit.current = null;
        },
        (err) => {
          setError422(err, setFieldError);
          callbackAfterSubmit.current = null;
        }
      );
    },
    validateOnChange,
  });

  const updatedValues = React.useRef(values);

  const [, data, reload] = useSelectInvoice(id, (v) => {
    setValues(v);
    setTimeout(() => {
      // eslint-disable-next-line
      if (location?.state?.preview) {
        openReviewRef?.current();
        history.push(location?.pathname, { preview: false });
      }
    }, 10);
    if (v?.status === 'PENDING' || v?.status === 'CANCELLING') {
      setTimeout(reload, 1000);
    }
  });

  const openPreviewAndSendModal = useOpenPreviewAndSendModal({
    onSent: reload,
  });

  function openReview() {
    if ((updatedValues?.current?.items || []).length < 1) {
      toastError('Invoice total amount must be greater than 0');
      return;
    }
    openPreviewAndSendModal(updatedValues?.current);
  }

  openReviewRef.current = openReview;

  const dirty = !isEqual(data, values);

  const handleUndo = (e) => {
    e.preventDefault();
    setValues(data);
  };
  useEffect(() => {
    updatedValues.current = values;
  }, [values]);

  const handleReview = async () => {
    if (!dirty) {
      const validationErrors = await validateForm();
      if (!isEmpty(validationErrors)) return;
      openReview();
      return;
    }
    callbackAfterSubmit.current = openReview;
    handleSubmit();
  };

  const readOnly = values?.status !== 'DRAFT';

  const [isRemove, removeInvoice] = useRemoveInvoice();
  const handleRemove = () => {
    removeInvoice(values?.id, onGoBack);
  };

  const [isCancel, cancelInvoice] = useCancelInvoice();
  const showInvoiceLinkQr = useShowInvoiceLinkQr();

  const createModal = useCreateModal('PAYMENT_SIMULATOR');
  const handleOpenSimulator = () => {
    createModal({
      content: (onClose) => (
        <ModalCard size="lg">
          <PaymentSimulatorModal onClose={onClose} />
        </ModalCard>
      ),
    });
    // openTestApiModal();
  };

  const actionButton = () => {
    if (
      ['WAITING_FOR_PAYMENT_CHANNEL', 'PENDING'].indexOf(values?.status) > -1
    ) {
      return (
        <div className="flex items-center space-x-2">
          <Button
            disabled={isCancel}
            className="border-red-500 bg-transparent focus:ring-danger-500"
            onClick={() => cancelInvoice(values?.id, reload)}
          >
            <span className="whitespace-nowrap text-red-500">
              Cancel Invoice
            </span>
          </Button>
        </div>
      );
    }
    if (values?.status === 'DRAFT') {
      return (
        <div className="flex flex-col items-center space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
          {dirty ? (
            <div className="space-x-2 text-right text-gray-500">
              <span>There are unsaved changes.</span>
              <a href="/" onClick={handleUndo} className="hover:underline">
                Undo
              </a>
            </div>
          ) : null}
          <div className="flex items-center space-x-2">
            <Button
              type="submit"
              disabled={isSaving}
              onClick={() => {
                setValidateOnChange(true);
                callbackAfterSubmit.current = null;
              }}
            >
              <span className="whitespace-nowrap">Save as Draft</span>
            </Button>
            <Button
              primary
              icon={IoEye}
              onClick={() => {
                setValidateOnChange(true);
                handleReview();
              }}
            >
              <span className="whitespace-nowrap">Send Invoice</span>
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(data?.payment_gateway_url);
    toastSuccess('Copied to clipboard!');
  };

  useOnToggleEnvironmentEffect(onGoBack);

  return (
    <>
      <BreadCrumb>{values?.invoice_number ?? '-'}</BreadCrumb>
      <Form onSubmit={handleSubmit} error={errors} noValidate>
        {typeof onGoBack === 'function' && (
          <button
            type="button"
            onClick={onGoBack}
            className="mb-4 flex items-center text-sm font-normal text-black hover:text-primary-500"
          >
            <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
            Go Back
          </button>
        )}
        {!values?.status ? (
          <div className="text-center text-gray-500">Loading invoice...</div>
        ) : (
          <Card className="col-span-3 px-4 pt-5 pb-8 sm:px-7">
            <div className="space-y-5">
              <div className="space-y-2">
                <div
                  className={cn(
                    'mb-4 flex flex-col justify-between space-y-2 rounded-xl border py-4 pr-2 pl-4 sm:flex-row sm:items-center sm:space-y-0 sm:py-7 sm:px-7',
                    values?.status === 'DRAFT' ? 'mx-auto max-w-4xl' : ''
                  )}
                >
                  <div className="flex justify-between">
                    <div>
                      <div className="mb-1 flex items-center space-x-1 text-base">
                        <span>Invoice No.</span>
                        <span className="font-bold">
                          {values?.invoice_number}
                        </span>
                      </div>
                      {renderStatus(values?.status, values?.status_label)}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {actionButton()}
                  </div>
                </div>
                <div className="flex flex-1 flex-col-reverse justify-center divide-x-0 sm:divide-x lg:flex-row lg:space-x-8">
                  <div
                    className={cn(
                      'rounded-xl px-2 pt-5 pb-8 sm:border sm:px-7',
                      values?.status === 'DRAFT' ? 'max-w-4xl' : 'sm:w-[45rem]'
                    )}
                  >
                    <div className="mb-4 flex flex-col items-start sm:flex-row sm:items-center">
                      <div className="title-h2 whitespace-nowrap">
                        Invoice Details
                      </div>
                      {data?.can_view_payment_link ? (
                        <div className="mt-2 ml-auto flex w-full flex-row-reverse sm:mt-0 sm:w-auto sm:flex-row sm:items-center">
                          <button
                            type="button"
                            className="w-1/4 rounded-md border p-3 sm:w-auto"
                            onClick={() =>
                              showInvoiceLinkQr(data?.payment_gateway_url || '')
                            }
                          >
                            Show QR
                          </button>
                          <button
                            type="button"
                            onClick={copyToClipboard}
                            className="mr-2 ml-0 flex w-3/4 items-center space-x-1 rounded-md border border-primary-500 bg-primary-50 p-2 sm:mr-0 sm:ml-2"
                          >
                            <HiLink className="h-8 w-8 text-gray-400 sm:h-5 sm:w-5" />
                            <div className="w-full truncate text-gray-400 sm:w-60">
                              {data?.payment_gateway_url}
                            </div>
                            <div className="rounded-md bg-primary-500 p-2 text-xs text-white">
                              Copy
                            </div>
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <InvoiceForm
                      setFieldValue={setFieldValue}
                      values={values}
                      readOnly={readOnly}
                    />
                    <div className="mt-4 flex items-end justify-end space-x-2">
                      {[
                        'DRAFT',
                        // 'PENDING',
                      ].indexOf(values?.status) > -1 ? (
                        <div className="mr-auto">
                          <Button
                            danger
                            icon={HiTrash}
                            onClick={handleRemove}
                            disabled={isRemove}
                          >
                            <span className="hidden sm:block">Delete</span>
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {values?.status !== 'DRAFT' && (
                    <div className="mt-4 flex-1 border-t pl-0 pt-4 sm:mt-0 sm:border-t-0 sm:pl-8 sm:pt-8">
                      {activeAccount.env_mode === 'TEST' ? (
                        <div className="mb-6 space-y-3 rounded-lg border border-secondary-300 bg-secondary-100 p-5">
                          <div className="text-center text-lg font-bold text-secondary-500">
                            You are in test mode
                          </div>
                          <div className="flex justify-center">
                            <Button secondary onClick={handleOpenSimulator}>
                              Open Payment Simulator
                            </Button>
                          </div>
                        </div>
                      ) : null}
                      <InvoiceLogs data={values} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
      </Form>
    </>
  );
}

SelectedInvoice.defaultProps = {
  onGoBack: undefined,
};

SelectedInvoice.propTypes = {
  onGoBack: PropTypes.func,
};

export default SelectedInvoice;
