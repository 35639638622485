import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { BiMobileAlt } from 'react-icons/bi';
import { RiComputerLine } from 'react-icons/ri';

function ToggleViewMode({ onChange, value }) {
  const handleChange = (v) => (e) => {
    e.preventDefault();
    onChange(v);
  };
  return (
    <div className="inline-flex justify-end divide-x rounded border shadow">
      <a
        href="/"
        onClick={handleChange('desktop')}
        className={cn(
          'px-2 py-2 transition hover:bg-gray-100',
          value === 'desktop' ? 'text-primary-500' : 'text-gray-500'
        )}
        aria-label="icon"
      >
        <RiComputerLine className="h-5 w-5 flex-shrink-0" />
      </a>
      <a
        href="/"
        onClick={handleChange('mobile')}
        className={cn(
          'px-2 py-2 transition hover:bg-gray-100',
          value === 'mobile' ? 'text-primary-500' : 'text-gray-500'
        )}
        aria-label="icon"
      >
        <BiMobileAlt className="h-5 w-5 flex-shrink-0" />
      </a>
    </div>
  );
}

ToggleViewMode.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ToggleViewMode;
