/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { HiCheckCircle } from 'react-icons/hi';
import { BiChevronRight } from 'react-icons/bi';

import { useDatasetOptions } from 'core-modules/dataset/hooks';
import { MAP_BUSINESS_TYPE_DATA_SET_CODE } from 'core-modules/onboarding/constants';
import AttachmentPreview from 'core-modules/merchant-onboarding/components/AttachmentPreview';

import { useGetGeneralInfo } from '../hooks';

function MerchantAttachments() {
  const [, generalInfo] = useGetGeneralInfo();
  const [selected, setSelected] = React.useState('');

  const [, requiredAttachmentFields] = useDatasetOptions(
    MAP_BUSINESS_TYPE_DATA_SET_CODE?.[generalInfo?.business_type] || '',
    true
  );

  const requirements = useMemo(
    () =>
      generalInfo?.requirements
        ?.filter((item) =>
          requiredAttachmentFields?.some(
            (field) => field?.label === item?.label
          )
        )
        ?.sort((a, b) => {
          if (a?.label > b?.label) return 1;
          if (b?.label > a?.label) return -1;
          return 0;
        }),
    [generalInfo, requiredAttachmentFields]
  );

  const selectedValues = React.useMemo(
    () =>
      (requirements?.find((item) => item?.label === selected)?.value || '')
        .split(',')
        .filter((v) => !isEmpty(v)),
    [selected, requirements]
  );

  useEffect(() => {
    setSelected(requirements?.[0]?.label || '');
  }, [requirements, setSelected]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2">
      <div>
        {requirements?.map((item) => (
          <RequirementItem
            key={item?.label}
            name={item?.label}
            label={item?.label}
            onChange={() => {}}
            value={item?.value || ''}
            isSelected={selected === item?.label}
            onSelect={() => setSelected(item?.label)}
            readOnly
          />
        ))}
      </div>
      <div className="hidden bg-primary-50 p-5 md:block">
        <AttachmentPreview
          name={selected}
          onChange={() => {}}
          values={selectedValues}
          label={selected}
          readOnly
        />
      </div>
    </div>
  );
}

function RequirementItem({
  name,
  value,
  label,
  isSelected,
  onSelect,
  readOnly,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const itemsCount = (value || '').split(',').filter((x) => !isEmpty(x)).length;

  const selectedValues = React.useMemo(
    () => value.split(',').filter((v) => !isEmpty(v)),
    [value]
  );
  return (
    <div>
      <div
        className={cn(
          'relative flex cursor-pointer items-center justify-between space-x-3 px-2 py-5 transition',
          isSelected
            ? 'hover:bg-primary-100 sm:bg-primary-50'
            : 'bg-white hover:bg-primary-50'
        )}
      >
        <button
          className="pointer-events-none absolute inset-0 h-full w-full md:pointer-events-auto"
          onClick={onSelect}
          type="button"
        />
        <button
          className="absolute inset-0 h-full w-full md:pointer-events-none"
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        />
        <div className="flex w-full flex-shrink-0 items-center justify-between space-x-1 pr-6">
          <div className="flex items-center space-x-1">
            {!isEmpty(value) ? (
              <HiCheckCircle className="h-6 w-6 flex-shrink-0 text-gray-700" />
            ) : (
              <div className="ml-px h-5 w-5 flex-shrink-0 rounded-full bg-gray-200" />
            )}
            <div
              className={cn(
                'w-full font-medium',
                isSelected || !isEmpty(value)
                  ? 'text-gray-700'
                  : 'text-gray-400'
              )}
            >
              {label}
            </div>
          </div>
          <div className="flex items-center space-x-1">
            {readOnly ? (
              <div className="text-xs text-gray-500">
                {itemsCount ? <span>{itemsCount} item(s)</span> : null}
              </div>
            ) : null}
            <BiChevronRight
              className={cn(
                'h-5 w-5 transition ease-in-out md:hidden',
                isOpen ? 'rotate-90 transform' : ''
              )}
            />
          </div>
        </div>
      </div>
      <div
        className={cn(
          'overflow-hidden transition-all md:hidden',
          isOpen ? 'h-[200px]' : 'h-0'
        )}
      >
        <div className="h-[200px]">
          <AttachmentPreview
            name={name}
            onChange={() => {}}
            values={selectedValues}
            label={label}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

RequirementItem.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  readOnly: false,
};

RequirementItem.propTypes = {
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MerchantAttachments;
