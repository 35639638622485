/* eslint-disable */
import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import { req } from 'react-reqq-lite';

import {
  useGeneralInfo,
  useSubmitApplication,
} from 'core-modules/merchant-onboarding/hooks';
import Splash from 'partial/containers/Splash';
import Button from 'partial/components/Button';
import { useCurrentAccount } from 'modules/auth/hooks';
import { useMerchantList } from 'core-modules/merchants/hooks';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { promiseGetGeneralInfo } from 'core-modules/merchant-onboarding/actions';

import ReviewForm from '../forms/ReviewForm';
import TestModeCard from '../components/TestModeCard';

const LIST_FILTER = { per_page: 1 };

const ReviewApplication = (props) => {
  const formRef = useRef();
  const [isLoading, submit] = useSubmitApplication();

  const [, orgProfileData] = useCurrentAccount();

  useMerchantList(LIST_FILTER, (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) return;
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    promiseGetGeneralInfo(firstId);
  });
  const [, data] = useGeneralInfo();
  if (isEmpty(data)) return <Splash />;
  return (
    <div>
      <ReviewForm
        {...props}
        ref={formRef}
        onSubmit={submit}
        onSuccess={props?.onNext}
      />
      <div className="mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0">
        <div className="sm:hidden">
          <TestModeCard />
        </div>
        <Button
          size="lg"
          transparent
          className="hidden w-full sm:block sm:w-auto"
          onClick={() => props?.onBack()}
        >
          <div className="hover:border-b-1 flex cursor-pointer items-center space-x-2 border-b-0 border-dashed text-gray-700 hover:border-gray-300">
            Back
          </div>
        </Button>
        {!props?.readOnly ? (
          <div className="flex w-full flex-col items-center justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onSkipped}
              >
                <span className="whitespace-nowrap text-gray-600">
                  Skip, I&apos;ll do this later
                </span>
              </Button>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              onClick={() => {
                if (typeof formRef?.current?.submit === 'function')
                  formRef?.current?.submit();
              }}
              disabled={isLoading || !orgProfileData?.is_org_all_kyc_submitted}
              className="order-2 w-full whitespace-nowrap sm:order-1 sm:w-auto"
            >
              Confirm and Submit
            </Button>
          </div>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={props?.onNext}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReviewApplication;
