/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { HiCheckCircle } from 'react-icons/hi';
import { ErrorLabel } from 'partial/form/Form';
import AttachmentPreview from './AttachmentPreview';

function RequirementItem({
  name,
  onChange,
  value,
  label,
  isSelected,
  onSelect,
  readOnly,
}) {
  const isUploaded =
    !isEmpty(value) && !((value || '').indexOf('uploading') > -1);

  const values = React.useMemo(
    () => (value || '').split(',').filter((v) => !isEmpty(v)),
    [value]
  );

  return (
    <div
      className={cn(
        'relative isolate w-full px-2 py-5 transition',
        isSelected
          ? 'z-[1] bg-gray-50 hover:bg-gray-100'
          : 'bg-white hover:z-[1] hover:bg-gray-50'
      )}
    >
      <button
        className="absolute inset-0 z-10 h-full w-full cursor-pointer"
        onClick={onSelect}
        type="button"
      />
      <div className="flex items-center">
        {isUploaded ? (
          <HiCheckCircle className="h-6 w-6 flex-shrink-0 text-gray-700" />
        ) : (
          <span className="h-5 w-5 flex-shrink-0 rounded-full bg-gray-200" />
        )}
        <p
          className={cn(
            'ml-1 flex-grow font-medium',
            isSelected || !isEmpty(value) ? 'text-gray-700' : 'text-gray-400'
          )}
        >
          {label} *
        </p>
      </div>
      {readOnly && (
        <div className="ml-auto text-xs text-gray-500">
          {values?.length ? <span>{values.length} item(s)</span> : null}
        </div>
      )}
      <div className="relative z-20 mt-2 h-60 @xl:hidden">
        <AttachmentPreview
          name={name}
          label={name}
          values={values}
          onChange={onChange}
          readOnly={readOnly}
          isMultiple
        />
      </div>
      <ErrorLabel name={name} />
    </div>
  );
}

RequirementItem.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  readOnly: false,
};

RequirementItem.propTypes = {
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RequirementItem;
