import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import noData from 'assets/images/no-data.svg';
import { useAccessControl } from 'modules/common/hooks';
import { useScrollPositionListener } from 'partial/hooks';

import NotificationItem from './NotificationItem';
import {
  useeMarkAllNotificationAsRead,
  useInlineMarkNotificationAsRead,
  useNotificationListData,
} from '../hooks';

function NotificationList({ onSelect }) {
  const { userType, decodedToken } = useAccessControl();

  const [filter, setFilter] = useState({
    page: 1,
    ...(() =>
      userType === 'sub_user'
        ? { merchant_uuid: decodedToken?.merchant_uuid }
        : {})(),
  });

  const [isLoading, paginatedData, refetchData] = useNotificationListData({
    filter,
  });
  const [, markNotificationAsRead] = useInlineMarkNotificationAsRead({
    onSuccess: refetchData,
  });
  const [
    isMarkingAllAsUnread,
    markAllNotificationAsRead,
  ] = useeMarkAllNotificationAsRead({
    onSuccess: refetchData,
  });

  const [data, setData] = useState(paginatedData || {});

  const canLoadMore = useMemo(
    () => data?.pager?.last_page > data?.pager?.current_page,
    [data]
  );

  const handleLoadMore = () =>
    setFilter((prev) => ({ ...prev, page: prev.page + 1 }));

  const { registerListener, resetListener } = useScrollPositionListener({
    listen: canLoadMore,
    listenScrollPercentage: 90,
    onHitScrollPercentage: () => {
      resetListener();
      handleLoadMore();
    },
  });

  useEffect(() => {
    if (
      !isEmpty(data) &&
      paginatedData?.pager?.current_page > data?.pager?.current_page
    ) {
      setData((prev) => ({
        list: [...prev?.list, ...paginatedData?.list],
        pager: paginatedData?.pager,
      }));
    }
    if (paginatedData?.pager?.current_page === 1) setData(paginatedData);
  }, [paginatedData, setData, data]);

  useEffect(() => {
    return () => setData({});
  }, []);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center space-x-2 justify-between p-5">
        <div className="font-semibold text-base">Notifications</div>
        <Button
          link
          onClick={markAllNotificationAsRead}
          disabled={isMarkingAllAsUnread}
        >
          Mark all as read
        </Button>
      </div>
      <div
        className="flex-grow sm:max-h-[500px] overflow-auto"
        {...registerListener}
      >
        {data?.list?.length === 0 && !isLoading && (
          <div className="text-center max-w-xl mx-auto py-10">
            <img src={noData} className="mx-auto mb-4 w-40" alt="" />
            <div className="text-gray-700 text-base">
              There is no notification yet.
            </div>
          </div>
        )}
        {data?.list?.map((notifItemData) => (
          <NotificationItem
            key={notifItemData?.id}
            data={notifItemData}
            onClick={() => {
              markNotificationAsRead(notifItemData?.id);
              if (typeof onSelect === 'function') onSelect();
            }}
          />
        ))}
        {canLoadMore && (
          <Button
            size="sm"
            className="py-1 w-full"
            disabled={isLoading}
            onClick={handleLoadMore}
          >
            {isLoading ? 'Loading...' : 'Load more'}
          </Button>
        )}
      </div>
    </div>
  );
}

NotificationList.defaultProps = {
  onSelect: null,
};

NotificationList.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
};

export default NotificationList;
