import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CgSpinner } from 'react-icons/cg';
import { FaChevronDown } from 'react-icons/fa';
// import { ExclamationCircleIcon } from '@heroicons/react/outline';

import { useOnClickOutside } from 'helper';

import { useInputId } from './hooks';

function FormSelect({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  options,
  placeholder,
  noPlaceholder,
  isLoading,
  onRetry,
  disabled,
  readOnly,
  ...props
}) {
  const [id] = useInputId(defaultId);
  const containerRef = React.useRef();
  const [isFocused, setIsFocused] = React.useState(false);
  const labelIsOnTop = React.useMemo(
    () => isFocused || value,
    [isFocused, value]
  );
  const handleChange = ({ target }) => {
    if (onRetry && target.value === '__RETRY__') {
      onRetry();
      return;
    }
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: target.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, target.value);
    }
  };
  // const showIcon = Icon || error;
  const isError = !!error;
  useOnClickOutside(containerRef, () => setIsFocused(false));
  return (
    <div ref={containerRef} className="relative">
      {!required && (
        <span className="absolute -top-7 right-0 z-[1] hidden text-sm text-gray-400 sm:block">
          Optional
        </span>
      )}
      <select
        autoComplete="none"
        className={cn(
          'no-arrow peer w-full rounded-md border bg-white px-4 pb-4 pt-[2.125rem] text-base font-bold leading-4',
          'text-gray-900 focus-within:ring-2 disabled:bg-gray-100',
          isError
            ? 'border-red-300 focus:border-red-500 focus:ring-red-300'
            : 'border-gray-200 focus:border-primary-600 focus:ring-primary-400',
          !labelIsOnTop ? '!text-transparent' : ''
        )}
        onChange={handleChange}
        value={value}
        disabled={disabled || readOnly}
        onFocus={() => setIsFocused(true)}
        {...props}
      >
        {Boolean(placeholder) && !noPlaceholder && (
          <option value="">{placeholder}</option>
        )}
        {options.map((x) => (
          <option value={x.value} key={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      {label ? (
        <label
          htmlFor={id}
          className={cn(
            'no-highlight pointer-events-none absolute left-4 -translate-y-1/2 transition-all',
            labelIsOnTop
              ? 'top-[1.625rem] text-xs text-gray-600'
              : 'top-1/2 bg-transparent text-sm text-gray-400'
          )}
        >
          <span>{label}</span>
        </label>
      ) : null}
      {isLoading && (
        <div
          className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          title={error || ''}
        >
          {isLoading ? (
            <CgSpinner
              className="h-5 w-5 animate-spin text-gray-400"
              aria-hidden="true"
            />
          ) : (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </div>
      )}
      {!readOnly && !disabled && (
        <div className="pointer-events-none absolute right-0 top-1/2 mr-1 -translate-y-1/2 pr-2">
          <FaChevronDown className="h-5 w-5 text-gray-400" />
        </div>
      )}
    </div>
  );
}

FormSelect.defaultProps = {
  id: '',
  label: '',
  icon: false,
  type: 'text',
  error: null,
  required: false,
  options: [],
  placeholder: '- Select -',
  onChange: false,
  onSetFieldValue: false,
  isLoading: false,
  onRetry: null,
  disabled: false,
  readOnly: false,
  noPlaceholder: false,
};

FormSelect.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  noPlaceholder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  options: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  onRetry: () => {},
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormSelect);
