import { withReqHookDefaultOptions } from 'helper';
import { useEffect, useCallback } from 'react';
import { useApiGet, useApiLoading } from 'react-reqq-lite';
import * as actions from './actions';
import * as c from './constants';

export const useUserListData = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.USER_LIST, 'get');
  const data = useApiGet(c.USER_LIST, {});

  const fetchData = useCallback(() => actions.listUsers(options.filter), [
    options.filter,
  ]);
  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
});

export const useSelectedUserData = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.USER_SELECT, 'get');
  const data = useApiGet(c.USER_SELECT, {});

  const fetchData = useCallback(() => {
    if (!options.id) return;
    actions.selectUser(options.id);
  }, [options.id]);
  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
});

export const useCreateUser = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.USER_CREATE, 'post');
  // eslint-disable-next-line
  const createUser = useCallback(
    actions.createUser(options.onSuccess, options.onError),
    [options.onSuccess, options.onError]
  );
  return [isLoading, createUser];
});

export const useUpdateUser = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.USER_UPDATE, 'put');
  // eslint-disable-next-line
  const submit = useCallback(
    actions.updateUser(options.id, options.onSuccess, options.on),
    [options.id, options.onSuccess, options.onError]
  );
  return [isLoading, submit];
});

export const useResendEmailSetPasswordLink = withReqHookDefaultOptions(
  (options) => {
    const isLoading = useApiLoading(c.USER_EMAIL_RESEND, 'put');
    // eslint-disable-next-line
    const submit = useCallback(
      actions.resendEmailSetPasswordLink(options.onSuccess, options.onError),
      [options.onSuccess, options.onError]
    );
    return [isLoading, submit];
  }
);

export const useRemoveUser = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.USER_DELETE, 'remove');
  // eslint-disable-next-line
  const submit = useCallback(
    actions.removeUser(options.onSuccess, options.onError),
    [options.onSuccess, options.onError]
  );
  return [isLoading, submit];
});

export const useRoleList = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.ROLE_LIST, 'get');
  const list = useApiGet(c.ROLE_LIST, []);

  // eslint-disable-next-line
  const fetchData = useCallback(actions.listRoles, []);
  useEffect(() => {
    if (!options.autoFetch) return;
    fetchData();
  }, [fetchData, options.autoFetch]);

  return [isLoading, list, fetchData];
});

export const useAttachRoleToUser = withReqHookDefaultOptions((options) => {
  const isLoading = useApiLoading(c.ROLE_ATTACHMENT, 'put');
  // eslint-disable-next-line
  const attachRoleToUser = useCallback(
    actions.attachRoleToUser(options.onSuccess, options.onError),
    [options.onSuccess, options.onError]
  );
  return [isLoading, attachRoleToUser];
});
