import cn from 'classnames';
import PropTypes from 'prop-types';
import { useResizeObserver } from 'partial/hooks';
import React, { useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { usePaymentLinkTransactionLogList } from '../hooks';
import Table from 'partial/components/Table';

const PaymentLinkLogs = ({ invoice_uuid }) => {
  const historyTableRef = useRef();
  const historyTableDimensions = useResizeObserver(historyTableRef);
  const [isHistoryContainerExpanded, setIsHistoryContainerExpanded] =
    useState(false);
  const { data: logList } = usePaymentLinkTransactionLogList({
    invoice_uuid,
  });

  return (
    <div className="rounded-[0.25rem] border px-4 pt-4">
      <button
        type="button"
        className="flex w-full justify-between"
        onClick={() => setIsHistoryContainerExpanded((prev) => !prev)}
      >
        <p>Transaction History</p>
        <div className="grid h-4 w-4 place-items-center rounded-[0.25rem] border bg-gray-50">
          <FaChevronDown
            className={cn(
              'h-3 w-3 text-gray-400 transition-transform',
              isHistoryContainerExpanded ? 'rotate-180' : ''
            )}
          />
        </div>
      </button>
      <div
        className="relative mt-4 flex flex-col overflow-hidden transition-[height] ease-bounce"
        style={{
          height: isHistoryContainerExpanded
            ? `${historyTableDimensions?.height + 30}px`
            : '120px',
        }}
      >
        <Table
          ref={historyTableRef}
          data={logList}
          format={[
            {
              label: 'DATE/TIME',
              key: 'created_at_human',
              stackKey: true,
            },
            {
              label: 'DESCRIPTION',
              key: 'remarks',
              stackKey: true,
            },
            {
              headerClassName: 'hidden',
              smRender: (log) => (
                <div className="relative -ml-5">
                  <p className="text-gray-500">{log?.remarks}</p>
                  <p className="text-xs text-gray-500">
                    {log?.created_at_human}
                  </p>
                </div>
              ),
            },
          ]}
        />
        {!isHistoryContainerExpanded && (
          <div className="absolute inset-0 h-full w-full bg-opacity-25 bg-gradient-to-b from-transparent to-white" />
        )}
      </div>
    </div>
  );
};

PaymentLinkLogs.propTypes = {
  invoice_uuid: PropTypes.string.isRequired,
};

export default PaymentLinkLogs;
