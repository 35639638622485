import React from 'react';
import PropTypes from 'prop-types';

import Table from 'partial/components/Table';
import FormInlineSelect from 'partial/form/FormInlineSelect';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';

import { useTransactionList } from '../hooks';
import { paymentStatusTableKey } from './PaymentStatus';
import { POSTING_OPTIONS, STATUS_OPTIONS } from '../constants';

function Filter({
  onSubmit,
  value,
  hideFilterPostingStatus,
  hideFilterPaymentStatus,
}) {
  return (
    <ModuleListFilters
      filter={{
        payment_status: value?.payment_status,
        posting_status: value?.posting_status,
      }}
      setFilter={onSubmit}
      renderCustomFields={
        hideFilterPaymentStatus && hideFilterPostingStatus
          ? null
          : ({ inlineFilter, setInlineFilter }) => (
              <>
                {!hideFilterPaymentStatus ? (
                  <FormInlineSelect
                    name="payment_status"
                    label={
                      <span className="text-sm text-gray-800 sm:hidden">
                        Payment Status
                      </span>
                    }
                    onChange={setInlineFilter}
                    value={inlineFilter?.payment_status}
                    options={STATUS_OPTIONS}
                  />
                ) : null}
                {!hideFilterPostingStatus ? (
                  <FormInlineSelect
                    name="posting_status"
                    label={
                      <span className="text-sm text-gray-800 sm:hidden">
                        Posting Status
                      </span>
                    }
                    onChange={setInlineFilter}
                    value={inlineFilter?.posting_status}
                    options={POSTING_OPTIONS}
                  />
                ) : null}
              </>
            )
      }
      activeFiltersFormat={[
        {
          label: 'Payment Status',
          key: 'payment_status',
          matrix: [
            ['PAID', 'Paid'],
            ['PENDING', 'Pending'],
            ['FAILED', 'Failed'],
            ['INITIAL', 'Initial'],
            ['REFUNDED', 'Refunded'],
            ['VOIDED', 'Voided'],
            ['EXPIRED', 'Expired'],
          ],
        },
        {
          label: 'Posting Status',
          key: 'posting_status',
          matrix: [
            ['POSTED', 'Posted'],
            ['UNPOSTED', 'Unposted'],
            ['IN_PROGRESS', 'In Progress'],
          ],
        },
      ]}
      hideDatePicker
    />
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  hideFilterPostingStatus: PropTypes.bool,
  hideFilterPaymentStatus: PropTypes.bool,
};

Filter.defaultProps = {
  hideFilterPostingStatus: false,
  hideFilterPaymentStatus: false,
};

function TransactionTable({
  filter: otherFilter,
  onRowClick,
  hideFilterPostingStatus,
  hideFilterPaymentStatus,
}) {
  const [raw, setFilter] = React.useState({
    merchant_uuid: '',
    refno: '',
    payment_status: '',
    posting_status: '',
    payment_channel: '',

    page: 1,
    sort: 'created_at:desc',
  });
  const filter = React.useMemo(
    () => ({
      ...raw,
      ...otherFilter,
    }),
    [raw, otherFilter]
  );
  const [isLoading, list, pager, appendList] = useTransactionList(filter);
  const tableFormat = React.useMemo(() => {
    const cols = [
      {
        label: 'Date/Time',
        id: 'created_at',
        key: 'created_at',
        // sortKey: 'created_at',
        stackKey: true,
      },
      {
        label: 'Reference No.',
        id: 'refno',
        key: 'refno',
        stackKey: true,
      },
      {
        smRender: (item) => (
          <div className="py-3">
            <div className="float-right text-right">
              <div>{item?.amount}</div>
              <div>{paymentStatusTableKey(item)}</div>
            </div>
            <div className="text-primary-500">{item?.merchant_name}</div>
            <div className="text-gray-500">{item?.channel_name}</div>
            <div className="text-gray-500">{item?.created_at}</div>
            <div className="text-gray-500">{item?.refno}</div>
          </div>
        ),
      },
    ];
    cols.push({
      label: 'Merchant',
      id: 'merchant',
      key: 'merchant_name',
      stackKey: true,
    });
    cols.push({
      label: 'Channel',
      id: 'channel',
      key: 'channel_name',
      stackKey: true,
    });

    cols.push({
      label: 'Amount',
      id: 'amount',
      key: 'amount',
      headerClassName: 'justify-center',
      className: 'text-center',
      width: 120,
      stackKey: true,
    });
    cols.push({
      label: 'Payment Status',
      id: 'payment_status_label',
      key: paymentStatusTableKey,
      headerClassName: 'justify-center',
      className: 'text-center',
      width: 146,
      stackKey: true,
    });
    return cols;
  }, []);

  return (
    <>
      <div className="pb-4">
        <Filter
          onSubmit={setFilter}
          value={raw}
          hideFilterPostingStatus={hideFilterPostingStatus}
          hideFilterPaymentStatus={hideFilterPaymentStatus}
        />
      </div>
      <div className="flex-grow rounded-lg border bg-white">
        <div className="flex h-full flex-col">
          <Table
            isLoading={isLoading}
            data={list}
            format={tableFormat}
            onSort={setFilter}
            sort={raw.sort}
            pager={pager}
            onLoadMore={appendList}
            onRowClick={onRowClick}
          />
        </div>
      </div>
    </>
  );
}

TransactionTable.defaultProps = {
  filter: {},
  onRowClick: null,
  hideFilterPostingStatus: false,
  hideFilterPaymentStatus: false,
};

TransactionTable.propTypes = {
  filter: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
  hideFilterPostingStatus: PropTypes.bool,
  hideFilterPaymentStatus: PropTypes.bool,
};

export default TransactionTable;
