import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import AuthStore from 'auth-store';
import { parseJwt } from 'utils/parseJwt';
import { useHistory, useLocation } from 'react-router-dom';
import { toastError } from 'react-geek-toast';

import { useCreateModal } from 'partial/components/Modal';

import { ACCESS_CONTROL_MODULE_CODES_URLS } from './constants';
import BetaPromotionModal from './components/BetaPromotionModal';

export const useAccessControl = () => {
  const decodedToken = useMemo(() => {
    const token = AuthStore.get('_token');
    if (!token) return null;
    return parseJwt(token);
  }, []);

  const userType = useMemo(
    () =>
      decodedToken?.type !== 'sub_merchant_user' ? 'org_user' : 'sub_user',
    [decodedToken]
  );

  const verbsModules = useMemo(() => {
    const verbs = {
      read: [],
      write: [],
      update: [],
      delete: [],
      execute: [],
    };
    if (!decodedToken) return verbs;
    const modulesWithPermissionsArray = decodedToken?.scope?.split(' ') || [];
    for (let i = 0; i < modulesWithPermissionsArray.length; i += 1) {
      const [module_code, permission_codes] =
        modulesWithPermissionsArray[i].split(':');
      const permission_codes_array = permission_codes.split('');
      if (permission_codes_array?.includes('r')) verbs.read.push(module_code);
      if (permission_codes_array?.includes('w')) verbs.write.push(module_code);
      if (permission_codes_array?.includes('u')) verbs.update.push(module_code);
      if (permission_codes_array?.includes('d')) verbs.delete.push(module_code);
      if (permission_codes_array?.includes('x'))
        verbs.execute.push(module_code);
    }
    return verbs;
  }, [decodedToken]);

  const canRead = useCallback(
    (module_codeOrCodes) => {
      if (Array.isArray(module_codeOrCodes))
        return module_codeOrCodes.every((module_code) =>
          verbsModules.read.includes(module_code)
        );
      return verbsModules.read.includes(module_codeOrCodes);
    },
    [verbsModules]
  );
  const canReadOneOf = useCallback(
    (module_codes) =>
      verbsModules.read.some((attached_module_code) =>
        module_codes.includes(attached_module_code)
      ),
    [verbsModules]
  );

  const canWrite = useCallback(
    (module_codeOrCodes) => {
      if (Array.isArray(module_codeOrCodes))
        return module_codeOrCodes.every((module_code) =>
          verbsModules.write.includes(module_code)
        );
      return verbsModules.write.includes(module_codeOrCodes);
    },
    [verbsModules]
  );
  const canWriteOneOf = useCallback(
    (module_codes) =>
      verbsModules.write.some((attached_module_code) =>
        module_codes.includes(attached_module_code)
      ),
    [verbsModules]
  );

  const canUpdate = useCallback(
    (module_codeOrCodes) => {
      if (Array.isArray(module_codeOrCodes))
        return module_codeOrCodes.every((module_code) =>
          verbsModules.update.includes(module_code)
        );
      return verbsModules.update.includes(module_codeOrCodes);
    },
    [verbsModules]
  );
  const canUpdateOneOf = useCallback(
    (module_codes) =>
      verbsModules.update.some((attached_module_code) =>
        module_codes.includes(attached_module_code)
      ),
    [verbsModules]
  );

  const canDelete = useCallback(
    (module_codeOrCodes) => {
      if (Array.isArray(module_codeOrCodes))
        return module_codeOrCodes.every((module_code) =>
          verbsModules.delete.includes(module_code)
        );
      return verbsModules.delete.includes(module_codeOrCodes);
    },
    [verbsModules]
  );
  const canDeleteOneOf = useCallback(
    (module_codes) =>
      verbsModules.delete.some((attached_module_code) =>
        module_codes.includes(attached_module_code)
      ),
    [verbsModules]
  );

  const canExecute = useCallback(
    (module_codeOrCodes) => {
      if (Array.isArray(module_codeOrCodes))
        return module_codeOrCodes.every((module_code) =>
          verbsModules.execute.includes(module_code)
        );
      return verbsModules.execute.includes(module_codeOrCodes);
    },
    [verbsModules]
  );
  const canExecuteOneOf = useCallback(
    (module_codes) =>
      verbsModules.execute.some((attached_module_code) =>
        module_codes.includes(attached_module_code)
      ),
    [verbsModules]
  );

  return {
    decodedToken,
    userType,
    verbsModules,
    canRead,
    canReadOneOf,
    canWrite,
    canWriteOneOf,
    canUpdate,
    canUpdateOneOf,
    canDelete,
    canDeleteOneOf,
    canExecute,
    canExecuteOneOf,
  };
};

// The callback is called only if you are in a "secured url" but does not have an access to it.
// secured url, are the urls that are inside the constant ACCESS_CONTROL_MODULE_CODES_URLS.
export const useOnForbbidenUrlEffect = (callback = () => {}) => {
  const location = useLocation();
  const previousUrlRef = useRef(null);
  const { verbsModules } = useAccessControl();

  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (location.pathname === previousUrlRef.current) return;
    previousUrlRef.current = location.pathname;
    let currentModuleCode = '';
    const isOnSecuredUrl = ACCESS_CONTROL_MODULE_CODES_URLS.some((module) => {
      const val = location.pathname.indexOf(module.url) === 0;
      if (val) currentModuleCode = module.code;
      return val;
    });
    if (!isOnSecuredUrl) return;
    const hasAccessOnUrl = verbsModules.read.some(
      (moduleCode) => moduleCode === currentModuleCode
    );
    if (hasAccessOnUrl) return;
    if (typeof callbackRef.current !== 'function') return;
    callbackRef.current();
  }, [location.pathname, verbsModules]);
};

export const useRedirectToFirstAccessibleModule = () => {
  const history = useHistory();
  const { verbsModules } = useAccessControl();
  const redirectToFirstAccessibleModule = useCallback(() => {
    const firstAccessibleModuleUrl =
      ACCESS_CONTROL_MODULE_CODES_URLS.find((module) =>
        verbsModules.read.includes(module.code)
      )?.url || '';
    if (!firstAccessibleModuleUrl) {
      history.push('/');
      return toastError('Your role has no accessible module.');
    }
    return history.push(firstAccessibleModuleUrl);
  }, [verbsModules, history]);

  return redirectToFirstAccessibleModule;
};

export const useOpenBetaPromotionModal = () => {
  const createModal = useCreateModal();

  const openBetaPromotionModal = useCallback(() => {
    createModal({
      content: (close) => <BetaPromotionModal onClose={close} />,
    });
  }, [createModal]);

  return openBetaPromotionModal;
};
