import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import placeholder from 'assets/images/placeholder.svg';
import { HiCheckCircle, HiChevronDown } from 'react-icons/hi';
// import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

function FormStyledSelect({
  id: defaultId,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  // required,
  options,
  // isLoading,
  onRetry,
  // ...props
}) {
  const [id] = useInputId(defaultId);
  const handleChange = (e) => {
    if (onRetry && e?.value === '__RETRY__') {
      onRetry();
      return;
    }
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: e?.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, e?.value);
    }
  };
  // const showIcon = Icon || error;
  const isError = !!error;

  return (
    <Listbox
      id={id}
      as="div"
      className="relative"
      value={value}
      onChange={handleChange}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            className={cn(
              'w-full border hover:border-gray-400 transition rounded-md justify-between p-3 flex items-center space-x-2 mx-auto',
              isError ? 'border-danger-400' : ''
            )}
          >
            <div className="flex items-center space-x-2">
              <div className="rounded overflow-hidden bg-white">
                <img
                  src={value?.thumbnail || placeholder}
                  alt=""
                  className="object-contain w-10 bg-gray-200"
                />
              </div>
              <div className="text-gray-600 font-semibold">
                {value?.label || '- No selected - '}
              </div>
            </div>
            <HiChevronDown className="w-6 h-6 text-gray-500 flex-shrink-0" />
          </Listbox.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Listbox.Options
              as="div"
              className="border divide-y rounded-md mt-1 w-full bg-gray-50 overflow-hidden"
            >
              {options.map((row) => (
                <Listbox.Option
                  className="flex items-center space-x-2 py-2 px-3 group cursor-pointer hover:bg-white"
                  key={row.value}
                  value={row}
                >
                  <div className="flex items-center space-x-2 justify-between w-full">
                    <div className="flex items-center space-x-2">
                      <div className="rounded overflow-hidden bg-white my-1">
                        <img
                          src={row?.thumbnail || placeholder}
                          alt=""
                          className="object-contain w-10 bg-gray-200"
                        />
                      </div>
                      <div className="text-gray-500 group-hover:text-gray-700 font-semibold transition">
                        {row?.label}
                      </div>
                    </div>
                    {row?.value === value?.value ? (
                      <HiCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                    ) : null}
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
}

FormStyledSelect.defaultProps = {
  id: '',
  label: '',
  error: null,
  options: [],
  onChange: false,
  onSetFieldValue: false,
  onRetry: null,
  value: null,
};

FormStyledSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object)]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  options: PropTypes.instanceOf(Array),
  onRetry: () => {},
};

export default React.memo(FormStyledSelect);
