import React from 'react';
import dayjs from 'dayjs';
import { each, isEmpty } from 'lodash';
import { req, useApiGet } from 'react-reqq-lite';
import { sortList } from 'partial/components/Table';
import { useDelayedApiLoading } from 'partial/hooks';
import { transformCompareTable, transformCompareChart } from './transformers';
import * as actions from './actions';
import * as c from './constants';

export const useCountCard = (name, filter) => {
  const isLoading = useDelayedApiLoading(`${c.COUNT_CARD}/${name}`, 'get');
  const { value } = useApiGet(`${c.COUNT_CARD}/${name}`, {});
  React.useEffect(() => {
    const action = actions.getCountCard[name];
    if (typeof action === 'function') action(filter);
  }, [name, filter]);
  return [isLoading, value];
};

export const useTransactionHistogram = (filter) => {
  const isLoading = useDelayedApiLoading(
    `${c.DASHBOARD_HISTOGRAM}/transaction`,
    'get'
  );
  const { list } = useApiGet(`${c.DASHBOARD_HISTOGRAM}/transaction`, {});
  React.useEffect(() => {
    const { type, from: dFrom, to: dTo, ...rest } = filter;
    const params = {
      date_column: 'created_at',
      payment_status: 'PAID',
    };
    if (dFrom) params.from = dFrom;
    if (dTo) params.to = dTo;
    if (type) params.type = type;
    actions.getDashboardHistogram(
      {
        ...params,
        ...rest,
      },
      'transaction'
    );
  }, [filter]);
  const { table, chart } = React.useMemo(() => {
    const tableData = (list || []).map((x) => ({
      id: x.label,
      name: x.label,
      transaction: x.transaction_human,
      amount: x.amount_human,
    }));
    const chartData = (list || []).map((x) => ({
      id: x.label,
      title: x.label,
      count: x.transaction,
    }));
    return {
      table: tableData,
      chart: chartData,
    };
  }, [list]);
  return [isLoading, table, chart, ''];
};

export const useAmountHistogram = (filter) => {
  const isLoading = useDelayedApiLoading(
    `${c.DASHBOARD_HISTOGRAM}/amount`,
    'get'
  );
  const { list } = useApiGet(`${c.DASHBOARD_HISTOGRAM}/amount`, {});
  React.useEffect(() => {
    const { type, from: datetime_from, to: datetime_to } = filter;
    actions.getDashboardHistogram(
      {
        payment_status: 'PAID',
        ...filter,
        date_column: 'created_at',
        from: datetime_from,
        to: datetime_to,
        type,
      },
      'amount'
    );
  }, [filter]);
  const { table, chart } = React.useMemo(() => {
    const tableData = (list || []).map((x) => ({
      id: x.label,
      name: x.label,
      transaction: x.transaction_human,
      amount: x.amount_human,
    }));
    const chartData = (list || []).map((x) => ({
      id: x.label,
      title: x.label,
      count: x.amount,
    }));
    return {
      table: tableData,
      chart: chartData,
    };
  }, [list]);
  return [isLoading, table, chart, ''];
};

export const useTopMerchantScheme = (filter, meta) => {
  const isLoading = useDelayedApiLoading(
    `${c.DASHBOARD_TOP}/top_merchants`,
    'get'
  );
  const { list } = useApiGet(`${c.DASHBOARD_TOP}/top_merchants`, {});
  React.useEffect(() => {
    actions.getDashboardTop(
      {
        payment_status: 'PAID',
        from: '2019-01-01',
        to: dayjs().format('YYYY-MM-DD'),
        by: 'merchant_uuid',
        ...filter,
      },
      'top_merchants'
    );
    return () => {
      req.set(`${c.DASHBOARD_TOP}/top_merchants`, {});
    };
  }, [filter]);
  const { table, chart } = React.useMemo(() => {
    const tableData = [];
    const chartData = [];
    sortList(list || [], meta.sort).forEach((x) => {
      tableData.push({
        id: x.id,
        label: x.label,
        transaction: x.transaction_human,
        amount: x.amount_human,
      });
      chartData.push({
        id: x.id,
        title: x.label,
        count: x?.[meta?.dataAttribute ?? 'amount'],
      });
    });
    return { table: tableData, chart: chartData };
  }, [list, meta.sort, meta.dataAttribute]);

  return [isLoading, table, chart, '', meta?.dataLabel ?? ''];
};

export const useTopChannelScheme = (filter, meta) => {
  const isLoading = useDelayedApiLoading(
    `${c.DASHBOARD_TOP}/top_channels`,
    'get'
  );
  const { list } = useApiGet(`${c.DASHBOARD_TOP}/top_channels`, {});
  React.useEffect(() => {
    actions.getDashboardTop(
      {
        payment_status: 'PAID',
        from: '2019-01-01',
        to: dayjs().format('YYYY-MM-DD'),
        by: 'payment_channel_uuid',
        ...filter,
      },
      'top_channels'
    );
    return () => {
      req.set(`${c.DASHBOARD_TOP}/top_channels`, {});
    };
  }, [filter]);
  const { table, chart } = React.useMemo(() => {
    const tableData = [];
    const chartData = [];
    sortList(list || [], meta.sort).forEach((x) => {
      tableData.push({
        id: x.id,
        label: x.label,
        transaction: x.transaction_human,
        amount: x.amount_human,
      });
      chartData.push({
        id: x.id,
        title: x.label,
        count: x?.[meta?.dataAttribute ?? 'amount'],
      });
    });
    return { table: tableData, chart: chartData };
  }, [list, meta.sort, meta.dataAttribute]);

  return [isLoading, table, chart, '', meta?.dataLabel ?? ''];
};

export const useTransactionScheme = (filter, meta) => {
  const isLoading = useDelayedApiLoading(
    `${c.DASHBOARD_SCHEME}/transaction`,
    'get'
  );
  const { list } = useApiGet(`${c.DASHBOARD_SCHEME}/transaction`, {});
  React.useEffect(() => {
    const { type, from: dFrom, to: dTo, ...rest } = filter;
    const params = {
      payment_status: 'PAID',
      date_column: 'created_at',
    };
    if (dFrom) params.from = dFrom;
    if (dTo) params.to = dTo;
    if (type) params.type = type;
    actions.getDashboardScheme(
      {
        ...params,
        ...rest,
      },
      'transaction'
    );
  }, [filter]);

  const { table, chart } = React.useMemo(() => {
    const tableData = [];
    const chartData = [];
    (list || []).forEach((x) => {
      tableData.push({
        id: x.id,
        label: x.label,
        transaction: x.transaction_human,
        amount: x.amount_human,
      });
      chartData.push({
        id: x.id,
        title: x.label,
        count: x.transaction,
      });
    });
    const sortedTable = sortList(tableData, meta.sort);
    return { table: sortedTable, chart: chartData };
  }, [list, meta.sort]);
  return [isLoading, table, chart, ''];
};

export const useTransactionList = (filter) => {
  const isLoading = useDelayedApiLoading(c.TRANSACTION_LIST, 'get');
  const list = useApiGet(c.TRANSACTION_LIST, []);
  const pager = useApiGet(c.TRANSACTION_PAGER, {});
  React.useEffect(() => {
    actions.listTransaction(filter);
    return () => {
      req.set(c.TRANSACTION_LIST, null);
      req.set(c.TRANSACTION_PAGER, null);
    };
  }, [filter]);
  const appendList = React.useCallback(
    (page) => {
      actions.appendListTransaction({ ...filter, page });
    },
    [filter]
  );
  return [isLoading, list, pager, appendList];
};

const MAP_GROUP = {
  day: 'hour',
  month: 'day',
  year: 'month',
};

const MAP_SERIES_FORMAT = {
  day: 'MM/DD/YYYY',
  month: 'MMM YYYY',
  year: 'YYYY',
};

export const useCompare = (mode, items, chartValueKey) => {
  const [isLoading] = React.useState(false);
  const data = useApiGet(c.TRANSACTION_COMPARE, {});
  const dataKeysRef = React.useRef([]);
  React.useEffect(() => {
    const ids = {};
    items.forEach((item) => {
      const id = c.buildId(item, mode);
      if (dataKeysRef.current.indexOf(id) < 0) {
        dataKeysRef.current.push(id);
        ids[id] = {
          from: dayjs(item).startOf(mode).format('YYYY-MM-DD'),
          to: dayjs(item).endOf(mode).format('YYYY-MM-DD'),
          type: MAP_GROUP[mode],
          payment_status: 'PAID',
        };
      }
    });
    each(ids, (params, id) => {
      req.get({
        key: `${c.TRANSACTION_COMPARE}/${id}`,
        url: '/merchant_aggs/v1/aggs/histogram/transactions',
        params,
        onSuccess: (res, state) => {
          req.set(c.TRANSACTION_COMPARE, {
            ...(state[c.TRANSACTION_COMPARE] || {}),
            [id]: {
              table: transformCompareTable(params)(res?.response),
              chart: transformCompareChart(mode, params)(res?.response),
            },
          });
        },
      });
    });
  }, [mode, items]);
  const chart = React.useMemo(() => {
    const series = [];
    const ids = {};
    items.forEach((item) => {
      ids[c.buildId(item, mode)] = item;
    });
    each(Object.keys(ids).sort(), (id) => {
      series.push({
        name: dayjs(ids[id]).format(MAP_SERIES_FORMAT[mode]),
        data: (data[id]?.chart || []).map((x) => x[chartValueKey]),
      });
    });
    return series;
  }, [data, items, mode, chartValueKey]);
  return [isLoading, data, chart];
};

export const useGetTotalAggregations = (params) => {
  const isLoading = useDelayedApiLoading(c.DASHBOARD_TOTAL_AGGREGATIONS, 'get');
  const data = useApiGet(c.DASHBOARD_TOTAL_AGGREGATIONS, {});
  const invoice = useApiGet(`${c.DASHBOARD_TOTAL_AGGREGATIONS}/invoice`, {});
  React.useEffect(() => {
    actions.getTotalAggregations(params);
    actions.getInvoiceAggregations(params);
  }, [params]);
  const newData = React.useMemo(() => {
    if (isEmpty(invoice)) return data;
    return { ...data, ...invoice };
  }, [data, invoice]);
  return [isLoading, newData];
};

export const useGetChartAggregations = (params) => {
  const isLoading = useDelayedApiLoading(c.DASHBOARD_CHART_AGGREGATIONS, 'get');
  const data = useApiGet(c.DASHBOARD_CHART_AGGREGATIONS, []);
  const invoice = useApiGet(`${c.DASHBOARD_CHART_AGGREGATIONS}/invoice`, []);
  React.useEffect(() => {
    actions.getChartAggregations(params);
    actions.getInvoiceChartAggregations(params);
  }, [params]);
  const newData = React.useMemo(() => {
    const temp =
      data.length > invoice.length
        ? { a: [...data], b: [...invoice] }
        : { a: [...invoice], b: [...data] };
    return (temp?.a || []).map((x, i) => ({
      ...x,
      ...temp?.b?.[i],
    }));
  }, [data, invoice]);
  return [isLoading, newData];
};
