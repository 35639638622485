import React from 'react';
// import PropTypes from 'prop-types';
import PageWrapper from 'partial/components/PageWrapper';
import NavTab, { NavTabItem } from 'partial/components/NavTab';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import IntegrationDashboard from './IntegrationDashboard';
import ApiKeysContainer from './ApiKeysContainer';
import ApiLogsContainer from './ApiLogsContainer';

function DeveloperRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <NavTab>
        <NavTabItem to={`${match.url}/integration`} label="Integration" />
        <NavTabItem to={`${match.url}/api-keys`} label="Api Keys" />
        <NavTabItem to={`${match.url}/logs`} label="Logs" />
      </NavTab>
      <PageWrapper>
        <Switch>
          <Route
            exact
            path={`${match.path}/integration`}
            component={IntegrationDashboard}
          />
          <Route
            exact
            path={`${match.path}/api-keys`}
            component={ApiKeysContainer}
          />
          <Route
            exact
            path={`${match.path}/logs`}
            component={ApiLogsContainer}
          />
          <Redirect to={`${match.url}/integration`} />
        </Switch>
      </PageWrapper>
    </>
  );
}

DeveloperRoutes.propTypes = {};

export default DeveloperRoutes;
