import { formatDate, formatCurrency } from 'helper';

const STATUS_LABEL = {
  SETTLED: 'Settled',
  UNSETTLED: 'Unsettled',
};

export const transformPayout = (raw) => ({
  id: raw?.uuid || '',
  created_at: raw?.created_at,
  merchant_name: raw?.merchant_name,
  settled_at: raw?.settled_at,
  report_date: formatDate(raw?.date_of_report, 'MMM DD YYYY'),
  amount: formatCurrency(raw?.total_net),
  transactions: raw?.total_transactions,
  status: raw?.status,
  status_label: STATUS_LABEL[raw?.status] || raw?.status,
  payout_fee_human: formatCurrency(raw?.payout_fee),
});

export const transformPayoutLogs = (raw) => ({
  id: raw?.uuid || '',
  paid_at: raw?.paid_at || '',
  channel: raw?.payment_channel || '',
  channel_branch: raw?.payment_channel_branch || '',
  payment_status: raw?.payment_status || '',
  refno: raw?.refno || '',
  txnid: raw?.transaction_uuid || '',
});
