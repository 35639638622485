/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
import React, { Fragment, useMemo, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiOutlineHome } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { SearchIcon } from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline';

import GenericInfo, {
  InfoDivider,
  InfoItem,
} from 'partial/components/GenericInfo';
import Card from 'partial/components/Card';
import logo from 'assets/images/brandIconLogos/colored-logo.svg';
import { useActiveAccount } from 'modules/auth/hooks';
import { promiseApiKeyOptions } from 'core-modules/developers/actions';
import FormMerchantOptions from 'core-modules/merchants/components/FormMerchantOptions';

import DocParams from './DocParams';
import CodeSnippet from './CodeSnippet';
import { GENERATE_PAYMENT } from './docs';

const navigation = [
  { id: 'introduction', name: 'Introduction', href: '#', icon: HomeIcon },
  {
    id: 'generate-payment',
    name: 'Generate Payment',
    href: '#',
    icon: RiSecurePaymentLine,
  },
];

const TitleDescription = ({ id, title, description }) => {
  return (
    <div className="relative">
      <div className="absolute" id={id} style={{ top: '-66px' }} />
      <div className="mb-3 text-xl font-bold">{title}</div>
      <div className="mb-4 text-sm font-medium text-gray-500">
        {description}
      </div>
    </div>
  );
};

TitleDescription.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Navigation = () => {
  const [active, setActive] = React.useState(navigation[0]?.id);
  const handleFocus = (target) => (e) => {
    e.preventDefault();
    const el = document.getElementById(target?.id);
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  };
  React.useEffect(() => {
    const pos = [...navigation].reverse().map((x) => ({
      ...x,
      top: document.getElementById(x.id).getBoundingClientRect().top,
    }));

    const listener = () => {
      const y = window.scrollY;
      let temp = null;
      const offset = 40;
      for (let i = 0; i < pos.length; i += 1) {
        if (y + offset > pos[i].top) {
          temp = pos[i];
          break;
        }
      }
      setActive((temp || navigation[0])?.id);
    };
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [setActive]);
  return (
    <>
      {navigation.map((item) => (
        <a
          key={item.name}
          href={item.href}
          onClick={handleFocus(item)}
          className={cn(
            active === item?.id
              ? 'bg-gray-100 text-gray-900'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
          )}
        >
          <item.icon
            className={cn(
              active === item?.id
                ? 'text-gray-500'
                : 'text-gray-400 group-hover:text-gray-500',
              'mr-3 h-6 w-6 flex-shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </a>
      ))}
    </>
  );
};

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const ENABLE_SEARCH = false;

export default function DevelopersContainer() {
  const [activeAccount] = useActiveAccount();

  const isTestMode = useMemo(
    () => activeAccount?.env_mode === 'TEST',
    [activeAccount]
  );

  const [form, setForm] = React.useState({
    merchant: null,
    api: '',
  });

  const [apiOptions, setApiOptions] = React.useState([]);
  React.useEffect(() => {
    if (!form?.merchant?.value) return;
    (async () => {
      const list = await promiseApiKeyOptions(form?.merchant?.value);
      setApiOptions(list);
      setForm((state) => ({ ...state, api: list?.[0]?.value || '' }));
    })();
  }, [form?.merchant]);

  const apiData = React.useMemo(
    () => apiOptions.find((x) => x.value === form?.api)?.data,
    [form?.api, apiOptions]
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [integration] = useState({
    host: `${process.env.REACT_APP_PGI_URL}`,
    route: '/api/v1/transaction',
  });

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setSidebarOpen}
          open={sidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Documentation" />
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="space-y-1 px-2">
                  <Navigation />
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img className="h-8 w-auto" src={logo} alt="Documentation" />
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              <Navigation />
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10 flex flex-shrink-0 bg-white shadow">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="my-2 flex flex-1 justify-between px-4">
            {ENABLE_SEARCH ? (
              <div className="flex flex-1">
                <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
              </div>
            ) : (
              <div className="flex flex-1 items-center justify-between">
                <h1 className="text-2xl font-semibold text-gray-900">
                  API Documentation
                </h1>
                <div className="flex space-x-1">
                  <FormMerchantOptions
                    name="merchant"
                    onChange={setForm}
                    value={form?.merchant}
                    clearable={false}
                    label="Select Merchant"
                    filterParams={{
                      approval_status: isTestMode ? '' : 'APPROVED',
                    }}
                  />
                  {/* <FormSelect
                      name="api"
                      onChange={setForm}
                      options={apiOptions}
                      value={form?.api}
                      label="Select Integration"
                    /> */}
                </div>
              </div>
            )}
            <div className="ml-4 flex items-center md:ml-6">
              <div className="relative ml-3">
                {!inIframe() ? (
                  <Link
                    className="flex items-center space-x-1 hover:underline"
                    to={process.env.REACT_APP_WEBSITE_URL}
                  >
                    <HiOutlineHome className="inline-block h-5 w-5" />
                    <span>Go back to website</span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <main className="flex-1">
          <div className="py-6">
            {ENABLE_SEARCH ? (
              <div className="mx-auto mb-8 max-w-7xl px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  API Documentation
                </h1>
              </div>
            ) : null}
            <div className="mx-auto max-w-full md:px-8">
              <div className="space-y-6">
                <TitleDescription
                  id="introduction"
                  title="Introduction"
                  description="The REST architecture underlies the MultiPay API. Our API employs standard HTTP response codes, authentication, and verbs, takes request bodies that have been encoded using a form, and delivers responses that have been encoded using JSON. The MultiPay API is available in test mode, which doesn't interfere with the financial networks or affect your live data. It depends on the API key you use to authenticate the request whether it is in live mode or test mode."
                />
                <hr />
                <div className="relative grid w-full grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5 md:gap-y-0">
                  <div className="col-span-2">
                    <TitleDescription
                      id="generate-payment"
                      title="Generate Payment"
                      description="This API will create a transaction based on the provided key"
                    />
                    <Card className="p-4">
                      <div className="mb-4 font-semibold text-gray-800">
                        Integration
                      </div>
                      <div className="-mx-4 -mb-4">
                        <GenericInfo data={integration}>
                          <InfoItem value="host" label="URL" />
                          <InfoItem value="route" label="Route" />
                          <InfoItem value={() => 'POST'} label="Method" />
                          <InfoDivider label="Header Parameters" />
                          <InfoItem
                            value={() => (
                              <code className="break-words">
                                {apiData?.token || '-API-TOKEN-HERE-'}
                              </code>
                            )}
                            label="TOKEN"
                          />
                          <InfoDivider label="Body Parameters" />
                          <DocParams data={GENERATE_PAYMENT.body} />
                        </GenericInfo>
                      </div>
                    </Card>
                  </div>
                  <div className="sticky top-0 col-span-3">
                    <CodeSnippet
                      data={GENERATE_PAYMENT.snippet}
                      value={apiData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
