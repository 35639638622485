import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import BreadCrumb from 'partial/components/BreadCrumb';

import PaymentLinksTable from '../components/PaymentLinksTable';
import CreatePaymentLink from '../components/CreatePaymentLink';
import SelectedPaymentLink from '../components/SelectedPaymentLink';

function PaymentLinksContainer({ filter }) {
  const match = useRouteMatch();
  const history = useHistory();
  const handleBack = () => {
    history.push(match.url);
  };
  return (
    <>
      <BreadCrumb>Payment Links</BreadCrumb>
      <Switch>
        <Route exact path={match.path}>
          <PaymentLinksTable filter={filter} />
        </Route>
        <Route path={`${match.path}/create`}>
          <CreatePaymentLink onGoBack={handleBack} />
        </Route>
        <Route path={`${match.path}/:link_uuid`}>
          <SelectedPaymentLink
            onGoBack={!filter?.merchant_uuid ? handleBack : undefined}
          />
        </Route>
      </Switch>
    </>
  );
}

PaymentLinksContainer.defaultProps = {
  filter: {},
};

PaymentLinksContainer.propTypes = {
  filter: PropTypes.instanceOf(Object),
};

export default PaymentLinksContainer;
