/* eslint-disable */
import React, { useRef, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { toastSuccess } from 'react-geek-toast';

import Button from 'partial/components/Button';
import Splash from 'partial/containers/Splash';
import ReviewForm from 'core-modules/onboarding/forms/ReviewForm';

import { promiseGetGeneralInfo } from '../actions';
import { useGeneralInfo, useSubmitApplication } from '../hooks';

function ReviewApplicationPage({ id, readOnly }) {
  const [isLoadingSubmit, submit] = useSubmitApplication();
  const formRef = useRef();

  useEffect(() => {
    promiseGetGeneralInfo(id);
  }, [id]);
  const [isLoading, data] = useGeneralInfo();

  const isAllStepDone = useMemo(
    () => !!data?.business_address && !!data?.business_type,
    [data]
  );

  if (isEmpty(data) || isLoading) return <Splash />;

  return (
    <div>
      <ReviewForm
        noBank
        noProfile
        noEdit
        ref={formRef}
        onSubmit={submit}
        onSuccess={() => {
          toastSuccess('Submitted');
          promiseGetGeneralInfo(id);
        }}
      />
      <div className="mt-4 flex flex-col justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
        {!readOnly ? (
          <Button
            primary
            size="lg"
            disabled={isLoadingSubmit || !isAllStepDone}
            className="order-2 whitespace-nowrap sm:order-1"
            onClick={() => {
              formRef?.current?.submit();
            }}
          >
            Confirm and Submit
          </Button>
        ) : null}
      </div>
    </div>
  );
}

ReviewApplicationPage.defaultProps = {
  readOnly: false,
};

ReviewApplicationPage.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default ReviewApplicationPage;
