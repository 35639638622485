import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import PaymentSimulatorModal from 'core-modules/developers/modals/PaymentSimulatorModal';
import { BiMoney } from 'react-icons/bi';

function TestPaymentTour({ onNext }) {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/merchants/profile/integration');
    // eslint-disable-next-line
  }, []);
  const createModal = useCreateModal('PAYMENT_SIMULATOR');
  const handleOpenPaySimulator = (e) => {
    e.preventDefault();
    createModal({
      content: (onClose) => (
        <ModalCard size="lg">
          <PaymentSimulatorModal onClose={onClose} />
        </ModalCard>
      ),
    });
  };
  return (
    <div>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <div className="p-3 rounded-lg relative">
            <BiMoney className="h-6 w-6 text-white relative z-20" />
            <span className="absolute inset-0 h-full w-full rounded-lg bg-primary-500 z-10" />
          </div>
        </div>
        <div className="text-white">
          <div className="font-semibold text-lg text-white">Test payment</div>
          <p>
            Test payment using{' '}
            <a
              className="hover:underline"
              href="/"
              onClick={handleOpenPaySimulator}
            >
              Payment Simulator
            </a>
          </p>
        </div>
      </div>
      <div className="flex justify-end space-x-3 border-t mt-3 pt-3  border-white/10">
        <Button size="sm" onClick={() => onNext('merchant-dashboard')}>
          Next
        </Button>
      </div>
    </div>
  );
}

TestPaymentTour.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default TestPaymentTour;
