import React from 'react';
import { useDownloadSwornDeclarationTemplate } from '../hooks';

function AttachmentGuidelines() {
  const [isLoading, download] = useDownloadSwornDeclarationTemplate();
  return (
    <div className="grid grid-cols-1 gap-3">
      <div className="col-span-1 space-y-2 p-4">
        <div>
          <div className="title-h2 mb-2">Upload Documents</div>
          <div className="font-semibold text-gray-500">
            Guidelines for uploading documents
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
          <p className="text-gray-500">
            The documents must be in clear copies and in color.
          </p>
        </div>
        <div className="flex space-x-4">
          <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
          <p className="text-gray-500">
            Make sure the documents have complete pages.
          </p>
        </div>
        <div className="flex space-x-4">
          <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
          <p className="text-gray-500">
            The documents must not be redacted (all information is visible and
            data is legible).
          </p>
        </div>
        <div className="flex space-x-4">
          <div className="mt-1.5 h-2 w-2 flex-shrink-0 rounded-full bg-primary-500" />
          <p className="text-gray-500">
            All corners of the documents are visible against the backdrop.
          </p>
        </div>
      </div>
      <div className="flex space-x-4">
        <p className="italic text-gray-500">
          Note: Please download the provided template for{' '}
          <button
            type="button"
            className="font-bold text-primary-500 hover:underline disabled:text-gray-500"
            disabled={isLoading}
            onClick={download}
          >
            Sworn Declaration of Gross Remittances
          </button>
          , fill out the required fields and re-upload it to the system.{' '}
        </p>
      </div>
    </div>
  );
}

AttachmentGuidelines.propTypes = {};

export default AttachmentGuidelines;
