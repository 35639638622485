/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'helper';
import { BiCalendarAlt } from 'react-icons/bi';
import React, { Fragment, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { useInputId } from './hooks';

const parseValue = (value, defaultValue = null) => {
  const date = dayjs(new Date(value));
  return date.isValid() ? date.toDate() : defaultValue;
};

function FormDate({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  panelClassName,
  maxDate,
  minDate,
  disabled,
  readOnly,
  hideYearDropdown,
  hideMonthDropdown,
  ...props
}) {
  const [id] = useInputId(defaultId);

  const handleChange = ({ target }) => {
    if (target.value === '__/__/____') {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: '',
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, '');
      }
      return;
    }
    const [m, d, y] = target.value.split('/');
    const standardFormat = [y, m, d].join('-');
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: standardFormat,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, standardFormat);
    }
  };

  const showIcon = Icon || error;
  const isError = !!error;
  const [showPicker, setShowPicker] = React.useState(false);
  const myRef = React.useRef();
  useOnClickOutside(myRef, setShowPicker);
  const handleToggle = () => {
    setShowPicker(true);
  };
  const defaultDate = React.useMemo(() => parseValue(value), [value]);

  const handleSetDate = (v) => {
    const newDate = dayjs(v);

    if (typeof onChange === 'function') {
      onChange?.((state) => ({
        ...state,
        [name]: newDate.format('YYYY-MM-DD'),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue?.(name, newDate.format('YYYY-MM-DD'));
    }
    setShowPicker(false);
  };

  const displayValue = useMemo(() => {
    const [y, m, d] = (value ?? '')?.split('-');
    return [m, d, y].join('-');
  }, [value]);

  return (
    <Popover className="relative">
      <div ref={myRef} className="relative z-[1]">
        {!required && (
          <span className="absolute -top-7 right-0 z-[1] text-sm text-gray-400">
            Optional
          </span>
        )}
        <InputMask
          mask="99/99/9999"
          id={id}
          name="name"
          className={cn(
            'peer relative w-full rounded-md border bg-white px-4 pb-4 pt-[32px] text-base font-bold leading-4',
            'text-gray-900 placeholder:text-transparent read-only:bg-gray-100 focus-within:ring-2 disabled:bg-gray-100',
            isError
              ? 'border-red-300 focus:border-red-500 focus:ring-red-300'
              : 'border-gray-200 focus:border-primary-600 focus:ring-primary-400',
            disabled || readOnly ? 'bg-gray-100' : 'bg-white',
            showIcon ? 'pr-10' : ''
          )}
          onChange={handleChange}
          value={displayValue}
          disabled={disabled}
          readOnly={readOnly}
          placeholder="hidden"
          {...props}
        />
        <label
          htmlFor={id}
          className={cn(
            'no-highlight pointer-events-none absolute left-4 top-[1.625rem] -translate-y-1/2 text-xs text-gray-600 transition-all',
            'peer-placeholder-shown:top-1/2 peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400',
            'peer-focus:top-[1.625rem] peer-focus:text-xs peer-focus:text-gray-600'
          )}
        >
          <span className="space-x-2">
            <span>{label}</span>
            <span className="ml-1 text-[.675rem] font-light text-gray-500">
              MM/DD/YYYY
            </span>
          </span>
        </label>
        {showIcon && (
          <div
            className="absolute inset-y-0 right-0 z-10 flex items-center pr-3"
            title={error || ''}
          >
            <button
              className="border-none bg-transparent text-gray-400 focus:text-primary-500 focus:outline-none"
              type="button"
              onClick={handleToggle}
              disabled={disabled}
              aria-label="icon"
            >
              <Icon className="mt-3 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        )}
        <Transition
          show={showPicker}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Popover.Panel
            static
            className={cn('absolute w-full sm:w-auto', panelClassName)}
          >
            <div className="overflow-hidden rounded-lg border shadow-lg">
              <DatePicker
                selected={defaultDate}
                onChange={handleSetDate}
                inline
                disabledKeyboardNavigation
                showYearDropdown={!hideYearDropdown}
                showMonthDropdown={!hideMonthDropdown}
                maxDate={maxDate}
                minDate={minDate}
                calendarClassName="w-full"
              />
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}

FormDate.defaultProps = {
  id: '',
  icon: BiCalendarAlt,
  error: null,
  required: false,
  panelClassName: 'right-0',
  onChange: false,
  onSetFieldValue: false,
  maxDate: null,
  minDate: null,
  hideYearDropdown: false,
  hideMonthDropdown: false,
  disabled: false,
  readOnly: false,
};

FormDate.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  panelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),
  hideYearDropdown: PropTypes.bool,
  hideMonthDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormDate);
