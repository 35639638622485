import {
  HiCheck,
  HiChevronDown,
  HiLockClosed,
  HiOutlineShieldCheck,
} from 'react-icons/hi';
import React from 'react';
// import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import placeholder from 'assets/images/placeholder.svg';
import Background from '../components/Background';
import { useThemePreview } from '../hooks';

function Preview() {
  const location = useLocation();
  const theme = useThemePreview(location.hash);
  return (
    <Background
      className="bg-gradient-to-tr from-theme-preview-brand-100 to-theme-preview-brand-500 p-5 sm:p-10"
      code={theme?.bg}
      color={theme?.color_bg}
    >
      <div style={{ zoom: '80%' }}>
        <div className="relative mx-auto w-full max-w-2xl overflow-hidden rounded-2xl bg-white px-5 py-5 shadow-2xl sm:px-20 sm:py-10">
          <div className="absolute inset-0" />
          <div className="grid grid-cols-3 py-2 text-center">
            <div className="flex items-center justify-center space-x-2 border-b-2 border-t-2 py-3">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-theme-preview-accent-500 text-center">
                <HiCheck className="mx-auto h-4 w-4 flex-shrink-0 text-white" />
              </div>
              <div className="truncate text-xs font-bold uppercase tracking-wider text-gray-500">
                Cart
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2 border-b-2 border-t-2 border-theme-preview-accent-500 py-3">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-theme-preview-accent-500 text-center">
                <span className="mx-auto font-bold text-white">2</span>
              </div>
              <div className="truncate text-xs font-bold uppercase tracking-wider text-gray-500">
                Cash Payments
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2 border-b-2 border-t-2 py-3">
              <div className="flex h-6 w-6 flex-shrink-0 items-center rounded-full border-2 border-theme-preview-accent-500 bg-white text-center">
                <span className="mx-auto font-bold text-theme-preview-accent-500">
                  3
                </span>
              </div>
              <div className="truncate text-xs font-bold uppercase tracking-wider text-gray-300">
                Confirmation
              </div>
            </div>
          </div>
          <div className="my-5 flex justify-center space-x-1">
            <HiOutlineShieldCheck className="h-8 w-8 flex-shrink-0 text-theme-preview-accent-500" />
            <div className="">
              <div className="text-sm font-bold text-gray-500">
                Secure Checkout Payment
              </div>
              <div className="text-xs text-gray-500">
                Transactions are secured and encrypted
              </div>
            </div>
          </div>
          <div className="mx-auto">
            <div>
              <div className="mx-auto flex max-w-sm items-center justify-between space-x-2 rounded-md border p-3">
                <div className="text-xs text-theme-preview-accent-500">
                  Select Payment Channels
                </div>
                <HiChevronDown className="h-5 w-5 flex-shrink-0 text-gray-400" />
              </div>
            </div>
            <div>
              <div className="my-3 flex justify-between border-b border-t py-3">
                <div className="text-sm font-bold text-gray-500">
                  Your Transaction
                </div>
                <div className="text-xs text-gray-500">
                  Please verify all information below
                </div>
              </div>
              <div className="mx-auto mt-5 flex max-w-sm flex-col">
                <div className="space-y-2 text-center">
                  <div className="space-y-2">
                    <div className="mx-auto flex h-16 w-16 items-center overflow-hidden rounded-lg">
                      <img
                        src={theme?.source_photo || placeholder}
                        alt=""
                        className="object-contain"
                      />
                    </div>
                    <div className="text-xs font-semibold tracking-wide text-gray-500">
                      {theme?.source || 'Merchant'}
                    </div>
                  </div>
                </div>
                <div className="my-4 space-y-3 border-b pb-4">
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Amount</div>
                    <div className="text-xs font-bold text-gray-500">
                      ₱{theme?.amount || '0.00'}
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Status</div>
                    <div className="badge badge-gray py-2 text-[8px]">
                      For Payment
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <div className="text-xs text-gray-500">Total Amount</div>
                    <div className="text-md font-bold text-theme-preview-accent-500">
                      ₱{theme?.amount || '0.00'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-auto max-w-[280px]">
                <div className="my-3">
                  <div className="text-center text-xs text-gray-500">
                    By clicking <b>Pay Now</b>, you agree with{' '}
                    <span className="underline">Terms and Conditions</span> and{' '}
                    <span className="underline">Data Privacy</span>
                  </div>
                </div>
                <div className="shadow-theme-preview-accent-md mx-auto mt-4 flex max-w-[200px] items-center justify-center space-x-2 rounded-md bg-theme-preview-accent-500 py-3 text-center text-xs font-semibold text-white">
                  <span>Pay Now</span>
                  <HiLockClosed className="h-4 w-4 flex-shrink-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-2xs mb-3 mt-4 text-center">
          <div className="text-xs text-black/40">
            Powered by [Merchant Name]
          </div>
          <div className="text-xs text-black/40">
            &copy; 2021 All rights reserved.
          </div>
        </div>
      </div>
    </Background>
  );
}

Preview.propTypes = {};

export default Preview;
