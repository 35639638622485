import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { HiOutlineSearch } from 'react-icons/hi';
import { PiSlidersHorizontalFill } from 'react-icons/pi';
import React, { useCallback, useState, useMemo } from 'react';

import Button from 'partial/components/Button';
import { showDrawer } from 'partial/components/Modal';
import FormSearchInput from 'partial/form/FormSearchInput';

import DateRangePicker from './DateRangePicker';

const useShowAdvanceFilterDrawer = ({
  filter,
  setFilter,
  renderCustomFields,
  hideDatePicker,
}) => {
  const showAdvanceFilterDrawer = useCallback(() => {
    showDrawer({
      title: 'Advanced Filter',
      size: 'sm',
      content: (close) => (
        <AdvanceFilterDrawer
          onClose={close}
          filterValue={filter}
          onSubmitFilterValue={setFilter}
          renderCustomFields={renderCustomFields}
          hideDatePicker={hideDatePicker}
        />
      ),
      zIndex: 'z-[999]',
    });
  }, [filter, setFilter, renderCustomFields, hideDatePicker]);
  return showAdvanceFilterDrawer;
};

const AdvanceFilterDrawer = ({
  onClose,
  filterValue: initFilterValue,
  onSubmitFilterValue,
  renderCustomFields,
  hideDatePicker,
}) => {
  const [filter, setFilter] = useState(initFilterValue);
  const onSubmitValue = useCallback(() => {
    onClose();
    onSubmitFilterValue(filter);
  }, [onSubmitFilterValue, filter, onClose]);
  const onClearFilter = () => {
    onSubmitFilterValue(
      Object.keys(filter).reduce((acc, curr) => {
        acc[curr] = '';
        return acc;
      }, {})
    );
    onClose();
  };

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="mt-4 flex w-full flex-col space-y-2 @4xl:hidden">
        {renderCustomFields?.({
          inlineFilter: filter,
          setInlineFilter: setFilter,
        })}
        {!hideDatePicker && (
          <DateRangePicker onSubmitValue={setFilter} value={filter} />
        )}
      </div>
      <div className="w-full space-y-2">
        <Button
          primary
          type="button"
          className="w-full py-5"
          onClick={onSubmitValue}
        >
          Apply Filter
        </Button>
        <Button
          outline
          type="button"
          className="w-full py-5"
          onClick={onClearFilter}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

AdvanceFilterDrawer.defaultProps = {
  renderCustomFields: null,
  hideDatePicker: false,
};

AdvanceFilterDrawer.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  filterValue: PropTypes.instanceOf(Object).isRequired,
  onSubmitFilterValue: PropTypes.instanceOf(Function).isRequired,
  renderCustomFields: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
  hideDatePicker: PropTypes.bool,
};

function ModuleListFilters({
  filter,
  setFilter,
  searchName,
  searchPlaceholder,
  actionElement,
  renderCustomFields,
  activeFiltersFormat,
  hideSearchFilter,
  hideDatePicker,
}) {
  const showAdvanceFilterDrawer = useShowAdvanceFilterDrawer({
    filter,
    setFilter,
    renderCustomFields,
    hideDatePicker,
  });

  const processedFilter = useMemo(() => {
    return Object.keys(filter).reduce((acc, curr) => {
      acc[curr] = filter?.[curr] ?? '';
      return acc;
    }, {});
  }, [filter]);

  const noActiveFilters = useMemo(
    () =>
      Object.keys(processedFilter).every(
        (filterKey) =>
          isEmpty(processedFilter?.[filterKey]) ||
          (processedFilter?.[filterKey] ===
            activeFiltersFormat?.find((f) => f.key === filterKey)
              ?.defaultValue ??
            '')
      ),
    [processedFilter, activeFiltersFormat]
  );

  const defaultSearchInputFilter = useMemo(
    () => (
      <FormSearchInput
        withClearBtn
        name={searchName}
        value={filter?.q}
        onChange={setFilter}
        icon={HiOutlineSearch}
        placeholder={searchPlaceholder}
      />
    ),
    [filter, setFilter, searchPlaceholder, searchName]
  );

  const defaultDateRangeFilter = useMemo(
    () => <DateRangePicker inline value={filter} onSubmitValue={setFilter} />,
    [filter, setFilter]
  );

  return (
    <div className="@container">
      <div className="flex flex-col justify-between space-y-2 @4xl:flex-row @4xl:space-y-0">
        <div className="flex w-full items-stretch space-x-2">
          {!hideSearchFilter && (
            <div className="mr-2 w-full @4xl:max-w-xs">
              {defaultSearchInputFilter}
            </div>
          )}
          {typeof renderCustomFields === 'function' && (
            <div className="!mr-2 hidden space-x-2 @4xl:flex">
              {renderCustomFields({
                inlineFilter: filter,
                setInlineFilter: setFilter,
              })}
            </div>
          )}
          <div className="!ml-auto flex space-x-2">
            {!hideDatePicker && (
              <div className="hidden @4xl:inline-flex">
                {defaultDateRangeFilter}
              </div>
            )}
            {Boolean(renderCustomFields || !hideDatePicker) && (
              <Button
                outline
                type="button"
                className="!ml-0 h-full @4xl:!ml-2 @4xl:hidden"
                onClick={showAdvanceFilterDrawer}
                icon={PiSlidersHorizontalFill}
              />
            )}
            {actionElement && (
              <div className="flex space-x-2">{actionElement}</div>
            )}
          </div>
        </div>
      </div>
      <div className="my-2 flex items-center justify-between @4xl:hidden">
        <div className="flex flex-wrap items-center gap-2">
          {noActiveFilters && (
            <span className="text-xs font-medium text-gray-500">
              No active filters
            </span>
          )}
          {activeFiltersFormat?.map((activeFilter) => (
            <div key={activeFilter?.key}>
              {(processedFilter?.[activeFilter?.key] ?? '') ===
              (activeFilter?.defaultValue ?? '') ? null : (
                <span className="flex items-center whitespace-nowrap rounded bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">
                  {activeFilter?.label} is{' '}
                  {typeof activeFilter?.matrix === 'function'
                    ? activeFilter?.matrix(processedFilter)
                    : activeFilter?.matrix?.find(
                        (row) => row[0] === processedFilter?.[activeFilter.key]
                      )?.[1] || '--'}
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => {
                      setFilter((state) => ({
                        ...state,
                        [activeFilter.key]:
                          activeFiltersFormat?.find(
                            (f) => f?.key === activeFilter?.key
                          )?.defaultValue ?? '',
                      }));
                      activeFilter?.clearFilter?.();
                    }}
                    aria-label="icon"
                  >
                    <FaTimes className="h-3 w-3" />
                  </button>
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ModuleListFilters.defaultProps = {
  searchName: 'keyword',
  searchPlaceholder: 'Search',
  actionElement: null,
  renderCustomFields: null,
  activeFiltersFormat: [],
  hideSearchFilter: false,
  hideDatePicker: false,
};

ModuleListFilters.propTypes = {
  searchName: PropTypes.string,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.instanceOf(Function).isRequired,
  actionElement: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  renderCustomFields: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
  activeFiltersFormat: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      matrix: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.array),
      ]),
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      clearFilter: PropTypes.instanceOf(Function),
    })
  ),
  hideSearchFilter: PropTypes.bool,
  hideDatePicker: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export default ModuleListFilters;
