import { omit, capitalize } from 'lodash';
import { formatCurrency, formatDate } from 'helper';
import dayjs from 'dayjs';

const MAP_STATUS = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  WAITING_FOR_PAYMENT_CHANNEL: 'Waiting for payment channel',
  WAITING_FOR_PAYMENT: 'Waiting for payment',
  PAID: 'Paid',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
  FAILED: 'Failed',
};

export const transformInvoice = (raw) => ({
  id: raw?.uuid,
  attachments: Array.isArray(raw?.attachments) ? raw?.attachments : [],
  created_at: formatDate(raw?.created_at, 'MMM DD, YYYY hh:mm A'),
  due_date: formatDate(raw?.due_date, 'YYYY-MM-DD'),
  due_date_human: formatDate(raw?.due_date, 'MMMM D, YYYY hh:mm A'),
  fee: raw?.fee || '',
  fee_human: formatCurrency(raw?.fee),
  invoice_number: raw?.invoice_number,
  transaction_uuid: raw?.transaction_uuid,
  refno: raw?.refno,
  items: Array.isArray(raw?.items)
    ? raw.items.map((item, i) => ({
        id: i,
        name: item?.name,
        qty: item?.qty,
        amount: item?.amount,
        photo_url: item?.photo_url,
        amount_human: formatCurrency(item?.amount),
      }))
    : [],
  email: raw?.recipient_details || '',
  recipient_name: raw?.recipient_name || '',
  remarks: raw?.remarks || '',
  status: raw?.status || '',
  status_label: MAP_STATUS[raw?.status] || raw?.status || 'Unknown',
  total_amount: formatCurrency(raw?.total_amount) || '',
  total_amount_human: formatCurrency(raw?.total_amount),
  merchant: raw?.merchant_uuid
    ? {
        label: raw?.merchant_name,
        value: raw?.merchant_uuid,
      }
    : null,
  payment_gateway_url: raw?.payment_gateway_url,
  contact_mobile_number: raw?.contact_mobile_number,

  can_view_payment_link:
    raw?.payment_gateway_url &&
    [
      'PENDING',
      'WAITING_FOR_PAYMENT_CHANNEL',
      'WAITING_FOR_PAYMENT',
      'PAID',
    ].indexOf(raw?.status) > -1,
});

export const payloadInvoice = (payload) => ({
  ...omit(payload, [
    'merchant',
    'email',
    'payment_url',
    'can_view_payment_link',
  ]),
  merchant_uuid: payload?.merchant?.value,
  recipient_details: payload?.email,
});

export const transformInvoiceLog = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  created_at_human: `${formatDate(
    raw?.created_at,
    'MMM DD, YYYY'
  )}\n ${formatDate(raw?.created_at, 'hh:mmA')}`,
  remarks: raw?.remarks || '',
});

// Payment Links Stuffs
export const transformPaymentLink = (raw) => ({
  id: raw?.id,
  uuid: raw?.uuid,
  name: raw?.name,
  page_url: raw?.page_url,
  status: raw?.status?.toUpperCase(),
  status_label: capitalize(raw?.status),
  total_amount: raw?.total_amount,
  total_amount_human: formatCurrency(raw?.total_amount),
  transaction_count: raw?.transaction_count,
  items: Array.isArray(raw?.items)
    ? raw.items.map((item, i) => ({
        id: i,
        name: item?.name,
        qty: item?.qty,
        amount: item?.amount,
        photo_url: item?.photo_url,
        amount_human: formatCurrency(item?.amount),
      }))
    : [],
  created_at: raw?.created_at,
  created_at_human: dayjs(raw?.created_at).format('MMM DD, YYYY'),
});

export const payloadPaymentLink = (payload, { hasExpirationDate }) => ({
  ...omit(payload, ['merchant', ...(!hasExpirationDate ? ['expires_at'] : [])]),
  merchant_uuid: payload?.merchant?.value,
});

export const transformPaymentLinkTransaction = (raw) => ({
  uuid: raw?.uuid,
  merchant_uuid: raw?.merchant_uuid,
  merchant_name: raw?.merchant_name,
  payment_gateway_url: raw?.payment_gateway_url,
  invoice_number: raw?.invoice_number,
  refno: raw?.refno,
  recipient_name: raw?.recipient_name,
  recipient_details: raw?.recipient_details,
  contact_mobile_number: raw?.contact_mobile_number,
  status: raw?.status,
  fee: raw?.fee,
  total_amount: raw?.total_amount,
  total_amount_human: formatCurrency(raw?.total_amount),
  items: Array.isArray(raw?.items)
    ? raw.items.map((item) => ({
        name: item?.name,
        qty: item?.qty,
        amount: item?.amount,
        amount_human: formatCurrency(item?.amount),
      }))
    : [],
  attachments: raw?.attachments,
  due_date: raw?.due_date,
  due_date_human: dayjs(raw?.due_date_human).format('MMM DD, YYYY'),
  remarks: raw?.remarks,
  created_at: raw?.created_at,
  created_at_human: dayjs(raw?.created_at).format('MMM DD, YYYY h:mm A'),
});

export const transformPaymentLinkTransactionLog = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  created_at_human: dayjs(raw?.created_at).format('MMM DD, YYYY, h:mm A'),
  remarks: raw?.remarks || '',
});

export const transformPaymentLinkAggregation = (raw) => ({
  current_month_total_payments: raw?.current_month_total_payments,
  payments_difference: raw?.payments_difference?.replaceAll(/[-+]/g, ''),
  payments_percentage: raw?.payments_percentage?.replaceAll(/[-+]/g, ''),
  previous_month_total_payments: raw?.previous_month_total_payments,
  total_payments: raw?.total_payments,
  total_payments_human: formatCurrency(raw?.total_payments),
  total_transactions: raw?.total_transactions,
  position: (() => {
    if (raw?.payments_percentage?.includes('+')) return 'up';
    if (raw?.payments_percentage?.includes('-')) return 'down';
    return 'middle';
  })(),
});

export const transformTransaction = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  refno: raw?.refno,
  txnid: raw?.txnid,
  merchant_name: `${raw?.merchant?.name} (${raw?.organization?.name})`,
  channel_name: raw?.payment_channel,
  amount: formatCurrency(raw?.amount),
  payment_status: raw?.payment_status,
  posting_status: raw?.posting_status,
});
