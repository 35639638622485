/* eslint-disable */
import React, { useState, useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import { toastError } from 'react-geek-toast';
import { useHistory } from 'react-router-dom';

import {
  useGeneralInfo,
  useSaveGeneralInfo,
  useSaveRequirements,
} from 'core-modules/merchant-onboarding/hooks';
import { setError422 } from 'partial/form/Form';
import Button from 'partial/components/Button';
import Splash from 'partial/containers/Splash';
import { useAccountMeta } from 'modules/auth/hooks';
import AttachmentsForm from '../forms/AttachmentsForm';
import { useMerchantList } from 'core-modules/merchants/hooks';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { promiseGetGeneralInfo } from 'core-modules/merchant-onboarding/actions';

import TestModeCard from '../components/TestModeCard';

const LIST_FILTER = { per_page: 1 };

const AttachmentsPage = (props) => {
  const [isMerchantReady, setIsMerchantReady] = useState(null);
  const formRef = useRef();

  const history = useHistory();
  const { meta, update } = useAccountMeta();
  const [, submitGeneralInfo] = useSaveGeneralInfo();
  // const [isSubmitting, submitAttachments] = useSaveAttachments();
  const [isSubmitting, submitRequirements] = useSaveRequirements();

  useMerchantList(LIST_FILTER, async (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) return setIsMerchantReady(false);
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    await promiseGetGeneralInfo(firstId);
    setIsMerchantReady(true);
  });
  const [, data] = useGeneralInfo();
  const handleBack = (e) => {
    e.preventDefault();
    const { onBack } = props;
    if (onBack) onBack();
  };

  const handleSubmit = useCallback(
    async (form, onSuccess, onError) => {
      try {
        await new Promise((res, rej) => {
          submitGeneralInfo(
            { business_type: form.business_type || '' },
            res,
            (err) => {
              rej(err);
              setError422(err, setFieldError);
            }
          );
        });
        const saveBusinessAttachments = await new Promise((res, rej) => {
          submitRequirements(form, res, (err) => {
            rej(err);
            setError422(err, setFieldError);
          });
        });
        onSuccess(saveBusinessAttachments);
      } catch (err) {
        onError(err);
        toastError('Something went wrong...');
      }
    },
    [submitGeneralInfo, submitRequirements]
  );

  if (isMerchantReady === false)
    return (
      <div>
        <div className="space-y-4">
          <div>You have not completed your merchant setup.</div>
          <div>
            Please{' '}
            <a href="/" onClick={handleBack}>
              complete merchant setup
            </a>{' '}
            to continue
          </div>
        </div>
      </div>
    );

  if (isEmpty(data) || isMerchantReady === null) return <Splash />;
  return (
    <div>
      <AttachmentsForm
        {...props}
        ref={formRef}
        onSubmit={handleSubmit}
        onSuccess={(value) => {
          props?.onNext(value);
          if (!meta?.is_first_merchant_attachment_submitted)
            update({ is_first_merchant_attachment_submitted: true });
        }}
        containerClassName="grid-cols-1 sm:grid-cols-2"
      />
      <div className="mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0">
        <div className="sm:hidden">
          <TestModeCard />
        </div>
        <Button
          size="lg"
          transparent
          className="hidden w-full sm:block sm:w-auto"
          onClick={() => props?.onBack()}
        >
          <div className="hover:border-b-1 flex cursor-pointer items-center space-x-2 border-b-0 border-dashed text-gray-700 hover:border-gray-300">
            Back
          </div>
        </Button>
        {!props?.readOnly ? (
          <div className="flex w-full flex-col items-center justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onNext}
              >
                <span className="whitespace-nowrap text-gray-600">
                  Skip, I&apos;ll do this later
                </span>
              </Button>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
              className="order-2 w-full whitespace-nowrap sm:order-1 sm:w-auto"
            >
              Save and Continue
            </Button>
          </div>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

AttachmentsPage.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default AttachmentsPage;
