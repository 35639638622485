import {
  MdReceipt,
  MdStickyNote2,
  MdSpaceDashboard,
  MdDataExploration,
  MdOutlineAdminPanelSettings,
} from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { RiPaintBrushLine } from 'react-icons/ri';
import { AiOutlineApi, AiOutlineCodeSandbox } from 'react-icons/ai';

export const USER_LIST = 'ACL/user_list';
export const USER_SELECT = 'ACL/user_select';
export const USER_CREATE = 'ACL/user_create';
export const USER_UPDATE = 'ACL/user_update';
export const USER_DELETE = 'ACL/user_delete';
export const USER_EMAIL_RESEND = 'ACL/user_resend_email';
export const ROLE_LIST = 'ACL/list_roles';
export const ROLE_ATTACHMENT = 'ACL/role_attachment';

export const MERCHANT_SUB_USER_MODULES_MAP = [
  {
    label: 'Dashboard',
    code: 'dashboard',
    url: '/dashboard',
    icon: MdSpaceDashboard,
  },
  {
    label: 'Profile',
    code: 'profile',
    url: '/info',
    icon: CgProfile,
  },
  {
    label: 'Transactions',
    code: 'transactions',
    url: '/transactions',
    icon: MdStickyNote2,
  },
  {
    label: 'Reports',
    code: 'reports',
    url: '/reports',
    icon: MdDataExploration,
  },
  {
    label: 'Channels',
    code: 'channels',
    url: '/channels',
    icon: AiOutlineCodeSandbox,
  },
  {
    label: 'Invoice',
    code: 'bills',
    url: '/get-paid/bill',
    icon: MdReceipt,
  },
  {
    label: 'API Integration',
    code: 'api_integration',
    url: '/integration',
    icon: AiOutlineApi,
  },
  {
    label: 'Themes',
    code: 'theme',
    url: '/themes',
    icon: RiPaintBrushLine,
  },
  {
    label: 'Users',
    code: 'users',
    url: '/users',
    icon: MdOutlineAdminPanelSettings,
  },
];

// const getModuleInfoFromCode = (module_code) => ({
//   label: MERCHANT_MODULES_MAP[module_code],
//   value: module_code,
// });

// export const USER_ROLES = [
//   {
//     label: 'Administrator',
//     value: 'administrator',
//     modules: Object.keys(MERCHANT_MODULES_MAP).map(getModuleInfoFromCode),
//   },
//   {
//     label: 'Business',
//     value: 'business',
//     modules: [
//       'dashboard',
//       'profile',
//       'transactions',
//       'channels',
//       'bill',
//       'themes',
//     ].map(getModuleInfoFromCode),
//   },
//   {
//     label: 'Accounting',
//     value: 'accounting',
//     modules: ['dashboard', 'transactions', 'reports'].map(
//       getModuleInfoFromCode
//     ),
//   },
//   {
//     label: 'Developer',
//     value: 'developer',
//     modules: ['transactions', 'api_integration'],
//   },
// ];
