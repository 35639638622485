/* eslint-disable */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import AuthStore from 'auth-store';
import { useHistory } from 'react-router-dom';
import Splash from 'partial/containers/Splash';
import { MyAccountContext } from '../constants';
import { useCurrentAccount } from '../hooks';

function MyAccountWrapper({ children }) {
  const history = useHistory();
  const [isLoading, activeAccount] = useCurrentAccount();
  useEffect(() => {
    if (
      activeAccount?.env_mode === 'LIVE' &&
      AuthStore.get('_skip_onboarding') !== '1' &&
      !activeAccount?.is_org_all_kyc_submitted
    ) {
      history.push('/onboarding');
    }
  }, [activeAccount, history]);

  if (isLoading && isEmpty(activeAccount)) return <Splash />;
  return (
    <MyAccountContext.Provider value={{ activeAccount }}>
      {children}
    </MyAccountContext.Provider>
  );
}

MyAccountWrapper.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default MyAccountWrapper;
