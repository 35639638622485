export const ACCESS_CONTROL_MODULE_CODES_URLS = [
  {
    code: 'dashboard',
    url: '/dashboard',
  },
  {
    code: 'profile',
    url: '/info',
  },
  {
    code: 'transactions',
    url: '/transactions',
  },
  {
    code: 'reports',
    url: '/reports',
  },
  {
    code: 'channels',
    url: '/channels',
  },
  {
    code: 'bills',
    url: '/bill',
  },
  { code: 'api_integration', url: '/integration' },
  { code: 'theme', url: '/themes' },
  {
    code: 'users',
    url: '/users',
  },
];
