import { useCallback, useRef, useState } from 'react';

const DEFAULT_OPTIONS = {
  rootMargin: '0px',
  root: null,
  threshold: 0,
};

export function useIsVisible(options = DEFAULT_OPTIONS) {
  const [entry, setEntry] = useState(null);

  const observer = useRef();

  const ref = useCallback(
    (element) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (element === null) {
        setEntry(null);
        return;
      }

      observer.current = new IntersectionObserver(([_entry]) => {
        setEntry(_entry);
      }, options);

      observer.current.observe(element);
    },
    // eslint-disable-next-line
    [options?.rootMargin, options?.root, options?.threshold]
  );

  return [ref, entry];
}
