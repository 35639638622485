import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { CgClose } from 'react-icons/cg';
import { FcGoogle } from 'react-icons/fc';
import { FaCheck } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { toastError, toastInfo } from 'react-geek-toast';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Form, {
  ErrorLabel,
  setError422,
  ErrorOrDescription,
} from 'partial/form/Form';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import { setIsLoading } from 'utils/reqq-helper';
import FormPassword from 'partial/form/FormPassword';
import { useCreateModal } from 'partial/components/Modal';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';
import { useGoogleRecaptcha } from 'partial/components/GoogleRecaptcha';

import { useRegister } from '../hooks';
import { REGISTER } from '../constants';
import TermsContainer from '../containers/TermsContainer';
import PrivacyContainer from '../containers/PrivacyContainer';
import FormSearchInput from 'partial/form/FormSearchInput';
import { validateReferralCode } from '../actions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const initialValues = {
  email_address: '',
  company_name: '',
  password: '',
  password_confirmation: '',
  terms: false,
  contact_name: '',
  contact_number: '',
};

const validationSchema = yup.object().shape({
  contact_first_name: yup.string().required('Required'),
  // .min(4, 'Must have atleast 4 characters'),
  contact_last_name: yup.string().required('Required'),
  // .min(4, 'Must have atleast 4 characters'),
  contact_number: yup
    .string()
    .required('Required')
    .test({
      name: 'must be valid',
      test: (value) => isValidPhoneNumber(value || ''),
      message: 'Invalid',
    }),
  email_address: yup.string().email('Invalid').required('Required'),
  company_name: yup.string().required('Required'),
  password: yup.string().required('Required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Does not match')
    .required('Required'),
  terms: yup
    .bool()
    .oneOf([true], 'You must agree and accept the terms and privacy policy'),
});

function SignUpForm({ onSuccess }) {
  const [isLoading, register] = useRegister();
  const location = useLocation();

  const [referralCodeValidationStatus, setReferralCodeValidationStatus] =
    useState('PENDING');
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const { executeAsync, reset } = useGoogleRecaptcha();

  const { values, setFieldValue, setFieldError, handleSubmit, errors } =
    useFormik({
      initialValues: {
        ...initialValues,
        referral_code: queryString.parse(location?.search)?.referral_code ?? '',
      },
      validationSchema,
      onSubmit: async (form) => {
        if (referralCodeValidationStatus === 'INVALID') return;
        try {
          setIsLoading('post', REGISTER, true);
          const payload = omit(form, ['password_confirmation', 'terms']);
          payload.captcha = await executeAsync();
          register(
            payload,
            () => {
              onSuccess({ email_address: payload.email_address });
            },
            (err) => {
              setError422(err, setFieldError);
              reset();
            }
          );
        } catch (err) {
          toastError('An error occurred! Please try again');
          setIsLoading('post', REGISTER, false);
          reset();
        }
      },
      validateOnChange,
    });
  const createModal = useCreateModal();

  const openPrivacyModal = React.useCallback(
    (e) => {
      e.preventDefault();
      createModal({
        content: (close) => <PrivacyContainer onClose={close} />,
      });
    },
    [createModal]
  );

  const openTermsModal = React.useCallback(
    (e) => {
      e.preventDefault();
      createModal({
        content: (close) => <TermsContainer onClose={close} />,
      });
    },
    [createModal]
  );

  // eslint-disable-next-line
  const handleReferralCode = useCallback(
    validateReferralCode({
      onError: () => setReferralCodeValidationStatus('INVALID'),
      onSuccess: () => setReferralCodeValidationStatus('VALID'),
    }),
    [setReferralCodeValidationStatus]
  );

  useEffect(() => {
    const referral_code =
      queryString.parse(location?.search)?.referral_code ?? '';
    if (isEmpty(referral_code)) return;
    handleReferralCode({ referral_code });
  }, [handleReferralCode, location]);

  return (
    <Form
      error={errors}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="space-y-3"
    >
      <div>
        <FormInput
          name="company_name"
          label="Organization Name"
          type="text"
          required
          onSetFieldValue={setFieldValue}
          value={values?.company_name}
          error={errors?.company_name}
        />
        <ErrorLabel name="company_name" />
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <div className="relative z-10">
            <FormInput
              name="contact_first_name"
              label="First Name"
              onSetFieldValue={registerOnSetFieldValue(
                'contact_first_name',
                setFieldValue
              )}
              value={values?.contact_first_name}
              error={errors?.contact_first_name}
              required
            />
          </div>
          <ErrorLabel name="contact_first_name" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="relative z-10">
            <FormInput
              name="contact_last_name"
              label="Last Name"
              onSetFieldValue={registerOnSetFieldValue(
                'contact_last_name',
                setFieldValue
              )}
              value={values?.contact_last_name}
              error={errors?.contact_last_name}
              required
            />
          </div>
          <ErrorLabel name="contact_last_name" />
        </div>
      </div>
      <div>
        <div className="relative z-10">
          <FormMobileNumber
            name="contact_number"
            label="Mobile No"
            onSetFieldValue={setFieldValue}
            value={values?.contact_number}
            error={errors?.contact_number}
            required
          />
        </div>
        <ErrorLabel name="contact_number" />
      </div>
      <p className="!mt-8 text-base text-gray-600">Login Access</p>
      <div>
        <div className="relative z-[1]">
          <FormInput
            icon={HiOutlineMail}
            name="email_address"
            label="Email Address"
            type="email"
            required
            onSetFieldValue={setFieldValue}
            value={values?.email_address}
            error={errors?.email_address}
          />
        </div>
        <ErrorOrDescription
          name="email_address"
          label="You will use this email address to sign in to your new MultiPay account."
        />
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <FormPassword
            name="password"
            label="Create Password"
            required
            onSetFieldValue={setFieldValue}
            value={values?.password}
            error={errors?.password}
          />
          <ErrorLabel name="password" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormPassword
            name="password_confirmation"
            label="Confirm Password"
            required
            onSetFieldValue={setFieldValue}
            value={values?.password_confirmation}
            error={errors?.password_confirmation}
          />
          <ErrorLabel name="password_confirmation" />
        </div>
      </div>
      <div className="!mt-8 rounded-lg border bg-gray-50/50 p-4">
        <p className="text-base text-gray-600">
          Do you have a referral code? (Optional)
          <br />
          <span className="text-xs text-gray-400">
            Our partners enjoy rewards on successful referrals.
          </span>
        </p>
        <div className="relative z-10 mt-4 space-y-2">
          <FormSearchInput
            name="referral_code"
            placeholder="Enter Code"
            onChange={(cb) => {
              const { referral_code } = cb();
              setFieldValue('referral_code', referral_code);
              if (isEmpty(referral_code)) {
                setReferralCodeValidationStatus('PENDING');
                return;
              }
              handleReferralCode({ referral_code });
            }}
            value={values?.referral_code}
            error={errors?.referral_code}
          />
          {['VALID', 'INVALID'].includes(referralCodeValidationStatus) &&
            {
              VALID: (
                <p className="flex items-center space-x-2 text-green-500">
                  <FaCheck className="inline-block h-4 w-4" />{' '}
                  <span className="text-sm font-medium leading-4">
                    Referral code is valid
                  </span>
                </p>
              ),
              INVALID: (
                <p className="flex items-center space-x-2 text-red-500">
                  <CgClose className="inline-block h-4 w-4" />{' '}
                  <span className="text-sm font-medium leading-4">
                    Referral code is invalid
                  </span>
                </p>
              ),
            }[referralCodeValidationStatus]}
        </div>
      </div>
      <div className="relative !mt-6 flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="agreement"
            type="checkbox"
            name="accept-terms"
            aria-describedby="agreement-description"
            className="form-checkbox mt-1 h-5 w-5 rounded"
            onChange={({ target }) => setFieldValue('terms', target.checked)}
            checked={values?.terms}
          />
        </div>
        <div className="ml-3 text-gray-600">
          <label htmlFor="agreement">
            By selecting this checkbox, I agree and accept the{' '}
            <a href="/" onClick={openTermsModal}>
              MultiPay Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="/" onClick={openPrivacyModal}>
              Privacy Notice
            </a>{' '}
            on behalf of my company.
          </label>
        </div>
      </div>
      <ErrorLabel name="terms" />
      <Button
        size="xxl"
        primary
        type="submit"
        className="!mt-8 w-full"
        disabled={isLoading}
        onClick={() => setValidateOnChange(true)}
      >
        Create a MultiPay Account
      </Button>
      <button
        type="button"
        className="mx-auto !mt-6 flex items-center space-x-2"
        onClick={() => toastInfo('Coming soon...')}
      >
        <FcGoogle size={20} />
        <span className="text-base">Sign up with Google</span>
      </button>
      <div className="!mt-8 space-x-1">
        <span>Already have an account?</span>
        <Link className="inline-block" to="/">
          Sign In
        </Link>
      </div>
    </Form>
  );
}

SignUpForm.defaultProps = {
  onSuccess: null,
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.instanceOf(Object),
};

export default SignUpForm;
