import bizCorp from 'assets/images/icons/biz-corporation.svg';
import bizSole from 'assets/images/icons/biz-sole.svg';
import bizIndividual from 'assets/images/icons/biz-individual.svg';
import bizPartnership from 'assets/images/icons/biz-partnership.svg';

export const GENERAL_INFO = 'ONBOARDING/general_info';
export const BUSINESS_INFO = 'ONBOARDING/business_info';
export const ATTACHMENTS = 'ONBOARDING/attachments';
export const REQUIREMENTS = 'ONBOARDING/requiremnts';
export const BANK_DETAILS = 'ONBOARDING/bank_details';
export const APPLICATION = 'ONBOARDING/application';

export const BUSINESS_TYPES = [
  {
    code: 'CORPORATION',
    name: 'Corporation',
    description:
      'Your business is owned by a corporate entity and is registered with the SEC',
    icon: bizCorp,
  },
  {
    code: 'SOLE_PROPRIETORSHIP',
    name: 'Sole proprietorship',
    description:
      'You are the sole owner of a business, and you have it registered with the DTI',
    icon: bizSole,
  },
  {
    code: 'INDIVIDUAL',
    name: 'Individual',
    description:
      'You are the only owner of a business, and you are not registered with the DTI',
    icon: bizIndividual,
  },
  {
    code: 'PARTNERSHIP',
    name: 'Partnership',
    description:
      'Your business is owned by two or more individuals or partners, and it is registered with the SEC',
    icon: bizPartnership,
  },
];

// export const REQUIREMENTS = [
//   { value: 'dti_registration', label: 'DTI Registration Certificate' },
//   { value: 'sec_certificate', label: 'SEC Certificate' },
//   { value: 'by_laws', label: 'By Laws' },
//   { value: 'articles_of_incorporation', label: 'Articles of Incorporation' },
//   { value: 'mayors_permit', label: "Mayor's Permit" },
//   { value: 'bir', label: 'BIR COR 2303' },
//   { value: 'secretarys_certificate', label: "Secretary's Certificate" },
//   {
//     value: 'audit_tax_return',
//     label: 'Latest Audited Financial Statements or Annual Income Tax Return',
//   },
//   { value: 'gis', label: 'Latest General Information Sheet (GIS)' },
// ];
