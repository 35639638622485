import React, { useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { toastSuccess } from 'react-geek-toast';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

import Button from 'partial/components/Button';
import Form, { setError422 } from 'partial/form/Form';
import BreadCrumb from 'partial/components/BreadCrumb';

import PaymentLinkForm from './PaymentLinkForm';
import { useCreatePaymentLink } from '../hooks';
import { GET_PAYMENT_LINK_VALIDATION_SCHEMA } from '../constants';

const initialValues = {
  remarks: '',
  items: [],
  attachments: [],
  merchant: null,
  status: 'NEW',
};

function CreatePaymentLink({ onGoBack }) {
  const location = useLocation();
  const [hasNoExpiration, setHasNoExpiration] = useState(false);

  const { isLoading, createPaymentLink } = useCreatePaymentLink({
    onSuccess: () => {
      toastSuccess('Payment link created');
      onGoBack?.();
    },
  });
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { values, setFieldValue, handleSubmit, errors, resetForm } = useFormik({
    initialValues: {
      ...initialValues,
      ...(location?.state || {}),
      // expires_at: "2023-12-12"
    },
    validationSchema: GET_PAYMENT_LINK_VALIDATION_SCHEMA({
      hasExpirationDate: !hasNoExpiration,
    }),
    onSubmit: async (form, { setFieldError }) => {
      createPaymentLink({
        payload: form,
        hasExpirationDate: !hasNoExpiration,
        onError: (err) => setError422(err, setFieldError),
      });
    },
    validateOnChange,
  });

  const clearForm = () => {
    resetForm({ values: null });
  };

  const actionButton = () => {
    return (
      <div className="flex w-full flex-col space-y-2 sm:w-auto sm:flex-row sm:space-x-2 sm:space-y-0">
        <Button
          className="w-full sm:w-auto"
          type="button"
          disabled={isLoading}
          onClick={clearForm}
        >
          <span className="whitespace-nowrap">Clear</span>
        </Button>
        <Button
          className="w-full sm:w-auto"
          type="submit"
          disabled={isLoading}
          primary
          onClick={() => {
            setValidateOnChange(true);
          }}
        >
          <span className="whitespace-nowrap">Create Payment Link</span>
        </Button>
      </div>
    );
  };

  if (!values?.status)
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between space-x-2">
          <Button
            transparent
            icon={BiArrowBack}
            onClick={onGoBack}
            className="flex items-center text-sm font-normal text-black hover:text-primary-500"
          >
            Go Back
          </Button>
        </div>
        <div className="text-center text-gray-500">Loading payment link...</div>
      </div>
    );

  return (
    <>
      <BreadCrumb>Create Payment Link</BreadCrumb>
      <Form
        onSubmit={handleSubmit}
        error={errors}
        noValidate
        className="space-y-4"
      >
        <div>
          <button
            type="button"
            onClick={onGoBack}
            className="flex items-center text-sm font-normal text-black hover:text-primary-500"
          >
            <HiOutlineArrowNarrowLeft className="mr-2 inline" size={15} />
            Go Back
          </button>
        </div>
        <div className="col-span-3 w-full max-w-3xl rounded-xl border bg-white px-4 pb-8 pt-5 sm:px-7">
          <div className="flex flex-col justify-center divide-x-0 sm:divide-x lg:flex-row lg:space-x-8">
            <div className="max-w-4xl flex-1 space-y-4">
              <div className="">
                <div className="title-h2">Payment Link</div>
                <p className="mt-1 text-sm leading-4 text-gray-400">
                  Collect multiple payments from existing or different customer
                </p>
              </div>
              <div>
                <PaymentLinkForm
                  setFieldValue={setFieldValue}
                  values={values}
                  canEditMerchant
                  hasNoExpiration={hasNoExpiration}
                  onToggleHasExpiration={(value) => {
                    setHasNoExpiration(value);
                  }}
                />
              </div>
              <div className="flex sm:justify-end">{actionButton()}</div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

CreatePaymentLink.propTypes = {
  onGoBack: PropTypes.func.isRequired,
};

export default CreatePaymentLink;
