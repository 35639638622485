import React from 'react';
import PropTypes from 'prop-types';
import CountCard from 'partial/dashboard/CountCard';
import { useActiveAccount } from 'modules/auth/hooks';
import {
  CashPaymentIcon,
  CardPaymentIcon,
} from 'modules/common/components/AppIconLogo';
import { useCountCard } from '../hooks';

function CountCards({ filter }) {
  const [activeAccount] = useActiveAccount();
  const params = React.useMemo(
    () => ({
      from: filter?.from,
      to: filter?.to,
      date_column: 'created_at',
      organization_uuid: activeAccount?.id,
    }),
    [filter, activeAccount]
  );
  return (
    <dl className="grid grid-cols-1 gap-3 overflow-hidden sm:grid-cols-2 sm:gap-6">
      <div className="rounded-xl border bg-white">
        <CountCard
          name="total_payout"
          label="Upcoming Payout"
          icon={CashPaymentIcon}
          hook={useCountCard}
          filter={params}
        />
      </div>
      <div className="rounded-xl border bg-white">
        <CountCard
          name="total_received"
          label="Total Received"
          icon={CardPaymentIcon}
          hook={useCountCard}
          filter={params}
        />
      </div>
    </dl>
  );
}

CountCards.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
};

export default CountCards;
