import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

const FormInput = React.forwardRef(
  (
    {
      id: defaultId,
      icon: Icon,
      label,
      name,
      onChange,
      onSetFieldValue,
      value,
      error,
      required,
      disabled,
      readOnly,
      ...props
    },
    ref
  ) => {
    const [id] = useInputId(defaultId);
    const handleChange = ({ target }) => {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: target.value,
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, target.value);
      }
    };
    const showIcon = Icon || error;
    const isError = !!error;
    return (
      <div className="relative z-[1]">
        {!required && (
          <span className="absolute -top-7 right-0 z-[1] hidden text-sm text-gray-400 sm:block">
            Optional
          </span>
        )}
        <input
          id={id}
          ref={ref}
          type="text"
          name="name"
          className={cn(
            'peer relative w-full rounded-md border bg-white px-4 pb-4 pt-[2.125rem] text-base font-bold leading-4',
            'text-gray-900 placeholder:text-transparent read-only:bg-gray-100 focus-within:ring-2 disabled:bg-gray-100',
            isError
              ? 'border-red-300 focus:border-red-500 focus:ring-red-300'
              : 'border-gray-200 focus:border-primary-600 focus:ring-primary-400',
            disabled || readOnly ? 'bg-gray-100' : 'bg-white',
            showIcon ? 'pr-10' : ''
          )}
          onChange={handleChange}
          value={value || ''}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
          placeholder="hidden"
        />
        <label
          htmlFor={id}
          className={cn(
            'no-highlight pointer-events-none absolute left-4 top-[1.625rem] -translate-y-1/2 text-xs text-gray-600 transition-all',
            'peer-placeholder-shown:top-1/2 peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400',
            'peer-focus:top-[1.625rem] peer-focus:text-xs peer-focus:text-gray-600'
          )}
        >
          <span>{label}</span>
        </label>
        {showIcon && (
          <div
            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            title={error || ''}
          >
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        )}
      </div>
    );
  }
);

FormInput.defaultProps = {
  id: '',
  icon: false,
  type: 'text',
  error: null,
  readOnly: false,
  disabled: false,
  required: false,
  onChange: false,
  onSetFieldValue: false,
};

FormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default React.memo(FormInput);
