import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import analytics from 'utils/analytics';
import Button from 'partial/components/Button';
import logo from 'assets/images/brandIconLogos/colored-logo.svg';

import SignUpForm from '../forms/SignUpForm';
import LandingWrapper from '../wrappers/LandingWrapper';

function SignUpSuccess({ data }) {
  const history = useHistory();
  const handleLogin = () => {
    history.push({
      pathname: '/',
      state: { email_address: data?.email_address || '' },
    });
  };
  return (
    <div>
      <div className="space-y-2">
        <img src={logo} className="mx-auto mb-5 h-12 sm:h-14" alt="App" />
        <div className="text-lg font-semibold">Verify your email</div>
        <div className="font-light">
          Please click the link that was sent to{' '}
          <span className="font-semibold text-gray-600">
            {data?.email_address || 'you email'}
          </span>{' '}
          to verify your account.
        </div>
      </div>
      <Button className="my-5 uppercase" default onClick={handleLogin}>
        Proceed to Login
      </Button>
    </div>
  );
}

SignUpSuccess.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

function SignUp() {
  const [isSuccess, setIsSuccess] = React.useState(null);
  return (
    <LandingWrapper
      title="Sign up for free"
      description="Let’s get started by creating your account"
    >
      {isSuccess ? (
        <SignUpSuccess data={isSuccess} />
      ) : (
        <SignUpForm
          onSuccess={(res) => {
            analytics.done('signup');
            setIsSuccess(res);
          }}
        />
      )}
    </LandingWrapper>
  );
}

SignUp.propTypes = {};

export default SignUp;
