import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'partial/components/ToolTip';
import { HiDocumentReport } from 'react-icons/hi';
import { BiInfoCircle } from 'react-icons/bi';
import SimpleLineChart from 'modules/common/chart/SimpleLineChart';
import DateRangeGroup, {
  MAP_DATE_BY_RANGE,
  MAP_TYPE_BY_RANGE,
} from 'partial/components/DateRangeGroup';
import {
  useGetTotalAggregations,
  useGetChartAggregations,
} from 'core-modules/dashboard/hooks';

function MerchantOverview({ filter: rawFilter }) {
  const [filter, setFilter] = React.useState({
    date_range: '1M',
    ...rawFilter,
  });
  const formattedFilter = React.useMemo(
    () => ({
      ...filter,
      ...MAP_DATE_BY_RANGE[filter?.date_range],
    }),
    [filter]
  );
  const chartFilter = React.useMemo(
    () => ({
      ...filter,
      ...MAP_DATE_BY_RANGE[filter?.date_range],
      type: MAP_TYPE_BY_RANGE[filter?.date_range],
      date_column: 'created_at',
    }),
    [filter]
  );
  const [, data] = useGetTotalAggregations(formattedFilter);

  const [chartLoading, chart] = useGetChartAggregations(chartFilter);
  const totalChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_amount,
      })),
    [chart]
  );
  const systemFeeChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_system_fee,
      })),
    [chart]
  );
  const partnerFeeChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_partner_fee,
      })),
    [chart]
  );
  const channelFeeChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_channel_fee,
      })),
    [chart]
  );
  const transactionChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_transaction,
      })),
    [chart]
  );
  const invoiceChart = React.useMemo(
    () =>
      chart.map((x, i) => ({
        title: i,
        count: x.total_invoice,
      })),
    [chart]
  );
  return (
    <div className="rounded-lg bg-white">
      <div className="border-b border-gray-200 px-4 py-3 sm:px-6">
        <div className="flex w-full flex-col justify-start space-y-2 space-x-0 divide-y sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:space-x-2 sm:divide-y-0">
          <div className="flex items-center space-x-4">
            <div className="rounded-lg bg-gray-100 p-2">
              <HiDocumentReport className="h-5 w-5 text-gray-500" />
            </div>
            <div className="title-h2">Reports Overview</div>
          </div>
          <div className="flex-shrink-0">
            <DateRangeGroup
              name="date_range"
              onChange={setFilter}
              value={filter?.date_range}
            />
          </div>
        </div>
      </div>
      <dl className="grid grid-cols-1 divide-y divide-x md:grid-cols-3 md:divide-y-0">
        <div className="divide-y">
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div>
                <ToolTip tip="Total income from all transactions, including convenience fee, system fee, partner fee before deducting any expenses or taxes.">
                  <div className="relative flex">
                    <div className="flex cursor-pointer items-center space-x-2 rounded border-b border-dashed border-white hover:border-gray-300 group-hover:bg-gray-100">
                      <div className="">Gross Amount</div>
                      <BiInfoCircle />
                    </div>
                  </div>
                </ToolTip>
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_amount}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart data={totalChart} isLoading={chartLoading} />
            </div>
          </div>
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-1">
                <div className="">Total Convenience Fee</div>
                {/* <BiInfoCircle /> */}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_system_fee}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart data={systemFeeChart} isLoading={chartLoading} />
            </div>
          </div>
        </div>
        <div className="divide-y">
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div>
                <ToolTip tip="Total income from all transactions, free from all charges or deductions.">
                  <div className="relative flex">
                    <div className="flex cursor-pointer items-center space-x-2 rounded border-b border-dashed border-white hover:border-gray-300 group-hover:bg-gray-100">
                      <div className="">Net Earnings</div>
                      <BiInfoCircle />
                    </div>
                  </div>
                </ToolTip>
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_partner_fee}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart
                data={partnerFeeChart}
                isLoading={chartLoading}
              />
            </div>
          </div>
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-1">
                <div className="">Total Channel Fees</div>
                {/* <BiInfoCircle /> */}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_channel_fee}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart
                data={channelFeeChart}
                isLoading={chartLoading}
              />
            </div>
          </div>
        </div>
        <div className="divide-y">
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-1">
                <div className="">Transactions Volume</div>
                {/* <BiInfoCircle /> */}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_transaction}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart
                data={transactionChart}
                isLoading={chartLoading}
              />
            </div>
          </div>
          <div className="space-y-2 p-5">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-1">
                <div className="">Invoice</div>
                {/* <BiInfoCircle /> */}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {data?.total_invoice}
              </div>
            </div>
            <div className="min-h-[150px]">
              <SimpleLineChart data={invoiceChart} isLoading={chartLoading} />
            </div>
          </div>
        </div>
      </dl>
    </div>
  );
}

MerchantOverview.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
};

export default MerchantOverview;
