import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useInputId } from './hooks';

const FormReadOnly = ({ id: defaultId, label, value }) => {
  const [id] = useInputId(defaultId);
  return (
    <div className={cn('flex flex-col rounded-lg border p-4')}>
      <label htmlFor={id} className="text-xs text-gray-600">
        {label}
      </label>
      <div className="flex-grow text-base font-medium text-gray-900">
        {value}
      </div>
    </div>
  );
};

FormReadOnly.defaultProps = {
  id: '',
};

FormReadOnly.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]).isRequired,
};

export default React.memo(FormReadOnly);
