import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { HiPlus } from 'react-icons/hi';
import ModuleListFilters from 'modules/common/components/ModuleListFilters';
import Button from 'partial/components/Button';

function RefundFilter({ onSubmit, value, action }) {
  return (
    <ModuleListFilters
      filter={value}
      setFilter={onSubmit}
      actionElement={
        <>
          <Button
            primary
            icon={HiPlus}
            onClick={action}
            className="h-full @4xl:hidden"
          />
          <Button
            primary
            icon={HiPlus}
            onClick={action}
            className="hidden h-full @4xl:inline-flex"
          >
            Create Refund Request
          </Button>
        </>
      }
      activeFiltersFormat={[
        {
          label: 'Date Range',
          key: 'from',
          matrix: (val) =>
            `${dayjs(val?.from).format('MMM DD, YYYY')} - ${dayjs(
              val?.to
            ).format('MMM DD, YYYY')}`,
          clearFilter: () =>
            onSubmit((state) => ({
              ...state,
              date_range_preset: '',
              from: '',
              to: '',
            })),
        },
      ]}
    />
  );
}

RefundFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  action: PropTypes.instanceOf(Function).isRequired,
};

export default RefundFilter;
