import React, { useMemo } from 'react';
import { TbCopy } from 'react-icons/tb';
import { TfiReload } from 'react-icons/tfi';
import { useParams } from 'react-router-dom';
import { HiOutlineQrcode } from 'react-icons/hi';
import { toastSuccess } from 'react-geek-toast';

import Button from 'partial/components/Button';

import { renderStatus } from '../constants';
import PaymentLinkLogs from './PaymentLinkLogs';
import PaymentLinkContents from './PaymentLinkContents';
import { useSelectInvoice, useTransactionData } from '../hooks';
import { useShowInvoiceLinkQr } from '../modals/InvoiceLinkQrModal';
import { useRefundTransaction } from '../modals/RefundTransactionModal';

const SelectedPaymentLinkTransaction = () => {
  const { invoice_uuid } = useParams();
  const showInvoiceLinkQr = useShowInvoiceLinkQr();

  const [, invoiceData] = useSelectInvoice(invoice_uuid);
  const { data: transactionData, isLoading: isLoadingTransaction } =
    useTransactionData({
      transaction_uuid: invoiceData.transaction_uuid,
    });
  const { openRefundTransactionModal } = useRefundTransaction({
    onSubmitSuccess: () => {
      toastSuccess('Refund request submmitted!');
    },
  });

  const status = useMemo(() => {
    const isRefunded = transactionData?.payment_status === 'REFUNDED';
    if (isRefunded) return renderStatus('REFUNDED', 'Refunded');
    return renderStatus(invoiceData?.status, invoiceData?.status_label);
  }, [transactionData, invoiceData]);

  return (
    <div className="relative flex w-full flex-col sm:h-full">
      <div className="w-full border-b px-6 py-4">
        <div className="relative">
          <p className="text-sm font-bold">
            Reference no{' '}
            <span className="text-primary-500">
              {invoiceData?.refno ?? '--'}
            </span>
          </p>
          <p className="text-xs">{invoiceData?.recipient_name}</p>
          <span className="absolute right-0 top-0">{status}</span>
        </div>
      </div>
      <div className="flex flex-col px-4 pb-4 sm:h-0 sm:flex-grow sm:pb-0">
        <div className="flex space-x-2 border-b py-0.5">
          <Button
            defaultShow
            size="xs"
            icon={HiOutlineQrcode}
            onClick={() =>
              showInvoiceLinkQr(invoiceData?.payment_gateway_url || '')
            }
          >
            Show QR
          </Button>
          <Button
            defaultShow
            size="xs"
            icon={TbCopy}
            onClick={() => {
              navigator.clipboard.writeText(invoiceData?.payment_gateway_url);
              toastSuccess('Copied to clipboard!');
            }}
          >
            Copy Transaction Link
          </Button>
          {invoiceData?.status === 'PAID' && (
            <Button
              defaultShow
              size="xs"
              icon={TfiReload}
              onClick={() => openRefundTransactionModal(transactionData)}
              className="disabled:cursor-wait"
              disabled={isLoadingTransaction}
            >
              Refund
            </Button>
          )}
        </div>
        <div className="mt-4 space-y-3 sm:h-0 sm:flex-grow sm:overflow-auto">
          <PaymentLinkLogs invoice_uuid={invoice_uuid} />
          <PaymentLinkContents paymentLinkData={invoiceData} />
        </div>
      </div>
    </div>
  );
};

export default SelectedPaymentLinkTransaction;
