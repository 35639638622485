/* eslint-disable */
function ColorLuminance(hex, lum) {
  try {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }

    return rgb;
  } catch (err) {
    return null;
  }
}
function Brightness(hex, percent) {
  try {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    var r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);

    return (
      '#' +
      (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
        .toString(16)
        .substr(1) +
      (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
        .toString(16)
        .substr(1) +
      (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
    );
  } catch (err) {
    return null;
  }
}
/* eslint-enable */

export const generateShades = (color, prefix) => {
  if (!color) return {};
  return {
    [`${prefix}-50`]: Brightness(color, 99),
    [`${prefix}-100`]: Brightness(color, 80),
    [`${prefix}-200`]: Brightness(color, 65),
    [`${prefix}-300`]: Brightness(color, 50),
    [`${prefix}-400`]: Brightness(color, 25),
    [`${prefix}-500`]: ColorLuminance(color, 0),
    [`${prefix}-600`]: ColorLuminance(color, -0.25),
    [`${prefix}-700`]: ColorLuminance(color, -0.5),
    [`${prefix}-800`]: ColorLuminance(color, -0.75),
    [`${prefix}-900`]: ColorLuminance(color, -1),
  };
};
