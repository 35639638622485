/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useActiveAccount } from 'modules/auth/hooks';
import { useAccessControl } from 'modules/common/hooks';
import Button from 'partial/components/Button';
import { useToggleLiveTestEnvironment } from '../modals/SwitchProfileModal';

const AccountModeStatus = () => {
  const [activeAccount] = useActiveAccount();
  const { userType } = useAccessControl();
  const { toggleEnvironment: promptSwitchProfile } =
    useToggleLiveTestEnvironment();

  if (userType === 'sub_user' || activeAccount?.env_mode !== 'TEST')
    return null;
  return (
    <div className="flex h-[3.875rem] bg-gray-800 text-white">
      <div className="mx-auto flex w-full max-w-7xl flex-1 items-center justify-between px-2 sm:px-6 md:px-8">
        <span className="text-sm font-normal">You&apos;re in test mode.</span>
        <Button onClick={promptSwitchProfile}>Exit Test Mode</Button>
      </div>
    </div>
  );
};

export default AccountModeStatus;
