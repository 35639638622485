import { debounce } from 'lodash';
import { req } from 'react-reqq-lite';
import { toastSuccess } from 'react-geek-toast';

import { formatSort, reqLoader } from 'helper';

import * as t from './transformers';
import * as c from './constants';

export const loadMerchantOptions = debounce(
  (keyword, callback, otherParams = {}) => {
    req.get({
      key: `${c.MERCHANT_LIST}/options`,
      url: '/profile/v1/my/merchants',
      params: {
        keyword,
        per_page: 10,
        ...otherParams,
      },
      transform: () => null,
      onSuccess: (res) => {
        callback((res?.response?.data || []).map(t.transformMerchantOptions));
      },
      onError: () => {
        callback([]);
      },
    });
  },
  500
);

export const listMerchants = (params, onSuccess) => {
  req.get({
    key: c.MERCHANT_LIST,
    url: '/profile/v1/my/merchants',
    params: formatSort(params),
    transform: (res) => (res.data || []).map(t.transformMerchant),
    onSuccess: (res) => {
      req.set(c.MERCHANT_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_FILTER, params);
      if (onSuccess)
        onSuccess((res?.response?.data || []).map(t.transformMerchant));
    },
  });
};

export const appendListMerchants = (params) => {
  req.get({
    key: c.MERCHANT_LIST,
    url: '/profile/v1/my/merchants',
    params: formatSort(params),
    transform: (res, state) =>
      (state[c.MERCHANT_LIST] || []).concat(
        (res.data || []).map(t.transformMerchant)
      ),
    onSuccess: (res) => {
      req.set(c.MERCHANT_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_FILTER, params);
    },
  });
};

export const selectMerchant = (id) => {
  req.get({
    key: c.MERCHANT_ITEM,
    url: `/profile/v1/my/merchants/${id}`,
    transform: (res) => t.transformMerchant(res.data),
  });
};

export const getOrgMerchant = () => {
  req.get({
    key: c.ORG_MERCHANT,
    url: '/profile/v1/my/merchants?is_org_merchant=1',
    transform: (res) =>
      res?.data?.[0] ? t.transformMerchant(res?.data?.[0]) : {},
  });
};

export const promiseGetMerchant = (id) =>
  new Promise((resolve) => {
    req.get({
      key: `${c.MERCHANT_ITEM}/quick`,
      url: `/profile/v1/my/merchants/${id}`,
      transform: (res) => t.transformMerchant(res?.data || {}),
      onSuccess: (res) =>
        resolve(t.transformMerchant(res?.response?.data || {})),
    });
  });

export const updateMerchantTheme = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM_THEME,
    url: `/profile/v1/my/merchants/${id}/update_theme`,
    payload: t.payloadTheme(payload),
    onSuccess: (res) => {
      toastSuccess('Updated!');
      selectMerchant(id);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const createMerchant = (payload, onSuccess, onError) => {
  req.post({
    key: c.MERCHANT_ITEM,
    url: '/profile/v1/my/merchants',
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Created!');
      setTimeout(() => {
        listMerchants({ ...state[c.MERCHANT_FILTER], page: 1 });
      }, 1000);
      const newData = t.transformMerchant(res?.response?.data);
      if (newData?.id) {
        selectMerchant(newData.id);
        req.set('NEW_DATA/merchant-profile', newData);
      }
      if (onSuccess) onSuccess(newData);
    },
    onError,
  });
};

export const updateMerchant = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM,
    url: `/admin/user_mng/v1/user/${id}/profile`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      selectMerchant(id);
      if (onSuccess) onSuccess(t.transformMerchant(res?.response?.data));
    },
  });
};

export const updateMerchantEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM,
    url: `/admin/user_mng/v1/user/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      selectMerchant(id);
      if (onSuccess) onSuccess(t.transformMerchant(res?.response?.data));
    },
  });
};

export const removeMerchant = (id, onSuccess) => {
  req.remove({
    key: c.MERCHANT_ITEM,
    url: `/profile/v1/my/merchants/${id}`,
    onSuccess: (res, state) => {
      toastSuccess('Removed!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      req.set(c.MERCHANT_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const toggleMerchantInvoiceChannelSwitchable =
  ({ merchant_id, onSuccess, onError }) =>
  ({ payload, onInlineSuccess, onInlineError }) => {
    req.put({
      key: c.MERCHANT_CHANNELS_SWITCH,
      url: `/profile/v1/my/merchants/${merchant_id}/toggle_payment_channel_switch`,
      payload,
      onSuccess: () => {
        selectMerchant(merchant_id);
        if (typeof onSuccess === 'function') onSuccess();
        if (typeof onInlineSuccess === 'function') onInlineSuccess();
        if (payload?.payment_channel_switch_allowed) {
          toastSuccess('Payment channel switching enabled!');
          return;
        }
        toastSuccess('Payment channel switching disabled!');
      },
      onError: () => {
        if (typeof onError === 'function') onError();
        if (typeof onInlineError === 'function') onInlineError();
      },
    });
  };

export const listMerchantSettlement = (params) => {
  req.get({
    key: c.MERCHANT_SETTLEMENT_LIST,
    url: '/admin/user_mng/v1/user',
    params: formatSort(params),
    transform: (res) => (res.data || []).map(t.transformMerchantSettlement),
    onSuccess: (res) => {
      req.set(c.MERCHANT_SETTLEMENT_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_SETTLEMENT_FILTER, params);
    },
  });
};

export const getCountCard = {
  total_transactions: () => {
    reqLoader(`${c.COUNT_CARD}/total_collections`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_transactions`,
      url: '/admin/game_mng/v1/game',
      per_page: 0,
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_transactions`, {
          value: res?.response?.transaction_human,
        });
        req.set(`${c.COUNT_CARD}/total_collections`, {
          value: res?.response?.collection_human,
        });
        reqLoader(`${c.COUNT_CARD}/total_collections`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_collections`, false);
      },
    });
  },
  total_settlement: () => {
    reqLoader(`${c.COUNT_CARD}/total_receivebles`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_settlement`,
      url: '/admin/game_mng/v1/game',
      per_page: 0,
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_settlement`, {
          value: res?.response?.transaction_human,
        });
        req.set(`${c.COUNT_CARD}/total_receivebles`, {
          value: res?.response?.collection_human,
        });
        reqLoader(`${c.COUNT_CARD}/total_receivebles`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_receivebles`, false);
      },
    });
  },
};

/* MERCHANT PROFILE START */
export const getGeneralInfo = (account_id) => {
  req.get({
    key: c.MERCHANT_GENERAL_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/general`,
    transform: (res) => t.transformGeneralInfo(res?.data || {}),
  });
};

/* MERCHANT PROFILE START */

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const listMerchantAttachedCategories = (id) => {
  req.get({
    key: c.LIST_PAYMENT_CATEGORIES(id),
    url: `/merchant_channel/v1/merchants/${id}/payment_categories`,
    transform: (res) =>
      (res.data || []).map(t.transformAttachedPaymentCategory),
  });
};

export const listPaymentCategoryChannels = (merchantId, paymentCategoryId) => {
  req.get({
    key: c.LIST_PAYMENT_CATEGORY_CHANNELS(paymentCategoryId),
    url: `/merchant_channel/v1/merchants/${merchantId}/payment_categories/${paymentCategoryId}/payment_channels`,
    transform: (res) => (res.data || []).map(t.transformAttachedPaymentChannel),
  });
};

export const toggleAttachedChannelStatus = (
  merchantId,
  categoryId,
  channelId,
  payload,
  successCallback,
  errorCallback
) => {
  req.put({
    key: c.TOGGLE_CHANNEL_STATUS,
    url: `/merchant_channel/v1/merchants/${merchantId}/payment_categories/${categoryId}/payment_channels/${channelId}/status`,
    payload,
    onSuccess: () => {
      if (typeof successCallback === 'function') successCallback();
    },
    onError: () => {
      if (typeof errorCallback === 'function') errorCallback();
    },
  });
};

export const updateAttachedChannelSequence =
  (merchantId, categoryId, onSuccess, onError) =>
  (channelId, payload, onInlineSuccess, onInlineError) => {
    req.put({
      key: c.UPDATE_CHANNEL_SEQUENCE,
      url: `/core_merchant_channel/v1/mng/merchants/${merchantId}/payment_categories/${categoryId}/payment_channels/${channelId}/sequence`,
      payload,
      onSuccess: () => {
        if (typeof onSuccess === 'function') onSuccess();
        if (typeof onInlineSuccess === 'function') onInlineSuccess();
      },
      onError: () => {
        if (typeof onError === 'function') onError();
        if (typeof onInlineError === 'function') onInlineError();
      },
    });
  };

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */
