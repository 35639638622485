import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
// import PropTypes from 'prop-types';

import history from 'setup/history';
import Button from 'partial/components/Button';
import PageNotFound from 'partial/containers/PageNotFound';
import Version from 'partial/components/Version';
import IconAccountVerified from 'assets/images/icons/icon-account-verified.svg';
import IconInvalidActivation from 'assets/images/icons/icon-invalid-activation.svg';
import IconInvalidExpired from 'assets/images/icons/icon-invalid-expired.svg';
import { SolidLogo } from 'modules/common/components/AppIconLogo';
import { HiOutlineHome } from 'react-icons/hi';

const ThisComponentWrapper = ({ children }) => {
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
  const handleRedirectToWebsite = () => {
    window.location.assign(websiteUrl);
  };
  return (
    <div className="bg-dotted-effect bg-login relative flex h-screen flex-col items-center justify-center bg-primary-600">
      <div className="absolute top-5 left-5 uppercase">
        <Button
          className="text-white"
          icon={HiOutlineHome}
          transparent
          onClick={handleRedirectToWebsite}
        >
          Go back to website
        </Button>
      </div>
      <SolidLogo className="h-12 sm:h-14" />
      <main className="mx-2 mt-8 flex max-w-lg flex-col items-center rounded-2xl bg-white p-4 text-center sm:p-6 lg:p-10">
        {children}
      </main>
      <div className="mt-10 text-center text-xs font-light text-gray-400">
        &copy; 2022 MultiPay Merchant <Version />
      </div>
    </div>
  );
};

const ActivateSuccess = () => (
  <ThisComponentWrapper>
    <img src={IconAccountVerified} alt="verified" className="w-36" />
    <h1 className="mt-5 text-lg font-semibold tracking-wide text-gray-800">
      Account Verified
    </h1>
    <p className="mt-2 text-sm text-gray-600">
      Congratulations! you have successfully verified you account
    </p>
    <p className="mt-2 text-sm text-gray-600">
      You will be automatically be redirected after a few seconds or click the
      button below to proceed now.
    </p>
    <Button
      className="mt-10 mb-5 uppercase"
      default
      onClick={() => history.push('/')}
    >
      Login to your account now
    </Button>
  </ThisComponentWrapper>
);

const ActivateExpired = () => (
  <ThisComponentWrapper>
    <img src={IconInvalidExpired} alt="expired" className="w-36" />
    <h1 className="mt-5 text-lg font-semibold tracking-wide text-gray-800">
      Account link has expired
    </h1>
    <p className="mt-2 text-sm text-gray-600">
      For security reasons the activation link will expire after 48 hours.
    </p>
    <p className="mt-2 text-sm text-gray-600">
      If this occurs, you can re-register, and then activate your account using
      the activation link in the new email.
    </p>
    <Button
      className="mt-10 mb-5 uppercase"
      default
      onClick={() => history.push('/signup')}
    >
      Register an account
    </Button>
  </ThisComponentWrapper>
);

const ActivateInvalid = () => (
  <ThisComponentWrapper>
    <img src={IconInvalidActivation} alt="invalid" className="w-36" />
    <h1 className="mt-5 text-lg font-semibold tracking-wide text-gray-800">
      Invalid Activation Link
    </h1>
    <p className="mt-2 text-sm text-gray-600">
      The activation link you followed is no longer valid. This is probably the
      link expired.
    </p>
    <p className="mt-2 text-sm text-gray-600">
      You can request a new activation link.
    </p>
    <Button
      className="mt-10 mb-5 uppercase"
      default
      onClick={() => history.push('/signup')}
    >
      Request a new activation link
    </Button>
  </ThisComponentWrapper>
);

function EmailActivationRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/success`} component={ActivateSuccess} />
      <Route path={`${match.path}/invalid`} component={ActivateInvalid} />
      <Route path={`${match.path}/expired-link`} component={ActivateExpired} />
      <Route component={PageNotFound} />
    </Switch>
  );
}

EmailActivationRoutes.propTypes = {};
ThisComponentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default EmailActivationRoutes;
