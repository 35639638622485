import React from 'react';
import PropTypes from 'prop-types';

const CLASS_MAPPER = {
  APPROVED: 'badge badge-success',
  PENDING: 'badge badge-warning',
  REJECTED: 'badge badge-danger',
};

const LABEL_MAPPER = {
  APPROVED: 'Approved',
  PENDING: 'For Approval',
  REJECTED: 'Rejected',
};

export const refundRequestStatusTableKey = ({ status }) => (
  <RefundRequestStatus status={status} />
);

function RefundRequestStatus({ status }) {
  return (
    <span className={CLASS_MAPPER[status]}>
      {LABEL_MAPPER[status] || status}
    </span>
  );
}

RefundRequestStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default RefundRequestStatus;
