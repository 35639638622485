/* eslint-disable */
import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import Splash from 'partial/containers/Splash';
import Button from 'partial/components/Button';
// import FormUrl from 'partial/form/FormUrl';
import {
  useGeneralInfo,
  useSaveGeneralInfo,
} from 'core-modules/merchant-onboarding/hooks';
import {
  useCreateMerchant,
  useMerchantList,
} from 'core-modules/merchants/hooks';
import { promiseGetGeneralInfo } from 'core-modules/merchant-onboarding/actions';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { req } from 'react-reqq-lite';
import { useAccountMeta } from 'modules/auth/hooks';
import MerchantForm from '../forms/MerchantForm';
import TestModeCard from '../components/TestModeCard';

const LIST_FILTER = { per_page: 1 };

const MerchantPage = (props) => {
  const history = useHistory();
  const formRef = useRef();

  const [error, setError] = React.useState(null);
  const [, create] = useCreateMerchant();

  const { meta, update } = useAccountMeta();
  const [isSubmitting, submit] = useSaveGeneralInfo();
  useMerchantList(LIST_FILTER, (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) {
      create(
        { name: 'My Merchant' },
        (newData) => {
          req.set(MERCHANT_ITEM, newData); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
          promiseGetGeneralInfo(newData?.id);
        },
        () => {
          setError('Unable to setup merchant. Please try again later.');
        }
      );
      return;
    }
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    promiseGetGeneralInfo(firstId);
  });
  const [, data] = useGeneralInfo();
  if (error) return <div>{error}</div>;
  if (isEmpty(data)) return <Splash />;
  return (
    <div>
      <MerchantForm
        {...props}
        ref={formRef}
        onSubmit={submit}
        onSuccess={(v) => {
          props?.onNext(v);
          if (!meta?.is_first_merchant_general_submitted)
            update({ is_first_merchant_general_submitted: true });
        }}
      />
      <div className="mt-4 flex flex-col space-y-3 sm:flex-row sm:space-y-0">
        <div className="sm:hidden">
          <TestModeCard />
        </div>
        <Button
          size="lg"
          transparent
          className="hidden w-full sm:block sm:w-auto"
          onClick={() => props?.onBack()}
        >
          <div className="hover:border-b-1 flex cursor-pointer items-center space-x-2 border-b-0 border-dashed text-gray-700 hover:border-gray-300">
            Back
          </div>
        </Button>
        {!props?.readOnly ? (
          <div className="flex w-full flex-col items-center justify-end space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
            <div className="order-last mt-3 sm:order-first sm:mt-0">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onNext}
              >
                <span className="whitespace-nowrap text-gray-600">
                  Skip, I&apos;ll do this later
                </span>
              </Button>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
              className="order-2 w-full whitespace-nowrap sm:order-1 sm:w-auto"
            >
              Save and Continue
            </Button>
          </div>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="order-2 whitespace-nowrap sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default MerchantPage;
