import React, { useMemo } from 'react';
import { HiBell } from 'react-icons/hi';
import { BsRecordFill } from 'react-icons/bs';
import { Popover, Transition } from '@headlessui/react';

import { useAccessControl } from 'modules/common/hooks';
import { useOnWindowFocusedEffect } from 'partial/hooks';

import NotificationList from './NotificationList';
import { useHasUnreadNotification, useNotificationListData } from '../hooks';

export const NotificationButton = () => {
  const btnRef = React.useRef();

  const { userType, decodedToken } = useAccessControl();
  const filter = useMemo(
    () =>
      userType === 'sub_user'
        ? { merchant_uuid: decodedToken?.merchant_uuid }
        : {},
    [userType, decodedToken]
  );

  const hasUnreadNotification = useHasUnreadNotification();
  const [, , refetchNotification] = useNotificationListData({
    filter,
  });

  useOnWindowFocusedEffect(refetchNotification);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={btnRef}
            // className="bg-white p-2 rounded-full text-slate-400 hover:bg-slate-100 transition hover:text-slate-700 focus:text-slate-700 focus:outline-none relative"
            className="group grid h-8 w-8 place-items-center rounded-full border bg-white text-slate-400 focus:outline-none"
            type="button"
          >
            <span className="sr-only">View Help</span>
            <HiBell
              className="h-4 w-4 text-gray-500 group-hover:text-gray-400"
              aria-hidden="true"
            />
            {hasUnreadNotification && (
              <BsRecordFill className="absolute top-1 right-1 h-3 w-3 rounded-full border-2 border-white bg-red-500 text-red-500 " />
            )}
          </Popover.Button>
          <Transition
            className="fixed top-14 right-0 bottom-0 w-full sm:absolute sm:top-10 sm:right-0 sm:bottom-auto sm:w-[400px]"
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className=" h-full overflow-hidden rounded-none border bg-white pb-20 shadow-primary-xl sm:h-auto sm:rounded-xl sm:pb-0">
              <NotificationList
                onSelect={() => {
                  if (open) btnRef?.current?.click();
                }}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
