import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  promiseGetMerchant,
  loadMerchantOptions,
} from 'core-modules/merchants/actions';
import FormDate from 'partial/form/FormDate';
import FormInput from 'partial/form/FormInput';
import FormReadOnly from 'partial/form/FormReadOnly';
import FormTextArea from 'partial/form/FormTextArea';
import { useActiveAccount } from 'modules/auth/hooks';
import FormAsyncSelect from 'partial/form/FormAsyncSelect';
import FormDescription from 'partial/form/FormDescription';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import { ErrorLabel, ErrorOrDescription } from 'partial/form/Form';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';

import InvoiceItems from './InvoiceItems';

function InvoiceForm({ setFieldValue, values, readOnly, canEditMerchant }) {
  const [activeAccount] = useActiveAccount();

  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const isTestMode = useMemo(
    () => activeAccount?.env_mode === 'TEST',
    [activeAccount]
  );

  React.useEffect(() => {
    if (!values?.merchant?.value) return;
    promiseGetMerchant(values?.merchant?.value);
  }, [values?.merchant?.value]);
  return (
    <>
      <div className="border-t pt-4">
        {!readOnly && values?.status !== 'DRAFT' && (
          <div className="title-h2">Invoice Information</div>
        )}
        <div
          className={cn(
            'grid gap-2',
            readOnly ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1'
          )}
        >
          <div>
            {!readOnly && canEditMerchant && !values?.merchantLock ? (
              <>
                <FormAsyncSelect
                  name="merchant"
                  onSetFieldValue={setFieldValue}
                  value={values?.merchant}
                  placeholder="Select Merchant"
                  label="Merchant"
                  loadOptions={loadMerchantOptions}
                  filterParams={{
                    approval_status: isTestMode ? '' : 'APPROVED',
                  }}
                  readOnly={isTestMode}
                  required
                />
                {isTestMode && (
                  <FormDescription
                    label="You can edit this field in Live mode."
                    theme="info"
                  />
                )}
              </>
            ) : (
              <FormReadOnly
                value={values?.merchant?.label || '-'}
                label="Merchant"
              />
            )}
            <ErrorLabel name="merchant" />
          </div>
          <div className="z-[2] space-y-2">
            <div>
              {!readOnly ? (
                <FormDate
                  name="due_date"
                  onSetFieldValue={setFieldValue}
                  value={values?.due_date}
                  label="Due Date"
                  minDate={new Date()}
                  required
                />
              ) : (
                <FormReadOnly
                  value={values?.due_date_human || ''}
                  label="Due Date"
                />
              )}
              <ErrorLabel name="due_date" />
            </div>
          </div>
          {/* <div className="border rounded-md p-4 flex bg-gray-50">
                    <MerchantCard merchantId={values?.merchant?.value || ''} />
                  </div> */}
          <div>
            {!readOnly ? (
              <>
                <FormInput
                  name="recipient_name"
                  onSetFieldValue={registerOnSetFieldValue(
                    'recipient_name',
                    setFieldValue
                  )}
                  value={values?.recipient_name}
                  label="Recipient's Name"
                  required
                />
                {isTestMode ? (
                  <ErrorOrDescription
                    name="recipient_name"
                    label="We autofilled your name as recipients name for testing purpose only."
                    theme="info"
                  />
                ) : (
                  <ErrorLabel name="recipient_name" />
                )}
              </>
            ) : (
              <FormReadOnly
                value={values?.recipient_name}
                label="Recipient's Name"
              />
            )}
          </div>
          {!readOnly && (
            <div>
              <div>
                <FormInput
                  name="email"
                  onSetFieldValue={setFieldValue}
                  value={values?.email}
                  label="Email Address"
                  type="email"
                  readOnly={isTestMode}
                  required
                />
                {isTestMode && (
                  <FormDescription
                    label="You can edit this field in Live mode."
                    theme="info"
                  />
                )}
              </div>
              <ErrorLabel name="email" />
            </div>
          )}
          <div>
            {!readOnly ? (
              <>
                <FormMobileNumber
                  name="contact_mobile_number"
                  onSetFieldValue={setFieldValue}
                  value={values?.contact_mobile_number}
                  label="Contact Number"
                  readOnly={isTestMode}
                  required
                />
                {isTestMode && (
                  <FormDescription
                    label="You can edit this field in Live mode."
                    theme="info"
                  />
                )}
              </>
            ) : (
              <FormReadOnly
                value={values?.contact_mobile_number || 'n/a'}
                label="Contact Number"
              />
            )}
            <ErrorLabel name="contact_mobile_number" />
          </div>
        </div>
      </div>
      <div className="mt-6 border-t pt-5">
        <InvoiceItems
          name="items"
          onSetFieldValue={setFieldValue}
          value={values?.items || []}
          readOnly={readOnly}
        />
        <ErrorLabel name="items" hideIfArray />
      </div>
      <div className="mt-6 space-y-4 border-t pt-5">
        <div className="title-h2">{!readOnly ? 'Add ' : ''}Memo/Note</div>
        <div>
          {!readOnly ? (
            <FormTextArea
              name="remarks"
              onSetFieldValue={setFieldValue}
              value={values?.remarks}
              label="Remarks"
            />
          ) : (
            <FormReadOnly value={values?.remarks} label="Remarks" />
          )}
          <ErrorLabel name="remarks" />
        </div>
      </div>
    </>
  );
}

InvoiceForm.defaultProps = {
  canEditMerchant: false,
  readOnly: false,
};

InvoiceForm.propTypes = {
  canEditMerchant: PropTypes.bool,
  readOnly: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default InvoiceForm;
